export class LabPackage {
    labPackageId: number;
    name: string; //
    amount: number;
    createdBy: number;
    noOfLabs: number;
    createdDate: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    active: boolean;
    createdByName: string;
    createdByRole: string;
    modifiedByName: string;
    modifiedByRole: string;
    totalItems: number;
    isEditing: boolean;
    isDeleting: boolean;
    isSelected: boolean;
    accountId: number;
}