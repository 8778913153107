import { Directive, HostListener } from "@angular/core";
import { KeyCodesHelper, RegExHelper } from "@shared/helpers";

@Directive({
    selector: "[textOnly]"
})
export class TextOnlyDirective {
    @HostListener("keydown", ["$event"])
    onKeyDown(e: KeyboardEvent) {
        const keys = KeyCodesHelper.default.concat(KeyCodesHelper.alphabets);
        if (keys.indexOf(e.keyCode) === -1) {
            event.preventDefault();
            return;
        }

        if (KeyCodesHelper.specialCharacters.indexOf(e.key) > -1) {
            event.preventDefault();
            return;
        }

        if (RegExHelper.textOnly.test(e.key))
            return;
        else
            event.preventDefault();
    }
}