import { DatePipe } from '@angular/common';
import { Injectable } from "@angular/core";
import { Timeline } from "@shared/entities";
import { ApiResources } from "@shared/helpers";
import { HttpService } from "@src/app/shared/services";
import { finalize } from "rxjs/operators";

@Injectable()
export class TimelineToggleService {
    showBox = true;
    encryptedAppointmentId: string;
    isAdmission: boolean;
    loading: boolean;
    records: Array<Timeline>;

    constructor(private readonly httpService: HttpService,
        private datePipe: DatePipe) {
        this.records = new Array<Timeline>();
    }

    public toggle(isTrigger: boolean = null) {
        this.showBox = isTrigger !== null ? isTrigger : !this.showBox;
        if (this.showBox) {
            this.fetch();
        }
    }

    public loadMore() {
        this.fetch(this.records.length);
    }

    public fetch(skip = 0, take = 5, timeLineActionId?: number) {
        if (!this.encryptedAppointmentId) return;

        const request = {
            appointmentEid: this.encryptedAppointmentId,
            isAdmission: this.isAdmission,
            patientEid: null,
            skip: skip,
            take: take,
            timelineActionId: timeLineActionId
        }

        this.httpService
            .post<Array<Timeline>>(ApiResources.getURI(ApiResources.timeline.base, ApiResources.timeline.fetch), request)
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<Timeline>) => {
                    if (response) {
                        response.forEach(record => {
                            if (record.data) {
                                const parsed = JSON.parse(record.data);
                                if (parsed) {
                                    switch (record.timelineActionId) {
                                        case 8:
                                        case 9:
                                            record.showCase = parsed[0]["medicineName"];
                                            break;
                                        case 10:
                                        case 11:
                                            record.showCase = parsed["notes"].replace(/<[^>]*>/g, '');
                                            break;
                                        case 12:
                                        case 13:
                                            record.showCase = this.datePipe.transform(new Date(parsed["followUpDate"]), 'MMM d, y, h:mm:ss a');
                                            break;
                                    }
                                }
                            }
                        });
                    }

                    if (skip === 0) {
                        this.records = response;
                    } else {
                        this.records.push(...response);
                    }
                },
                () => {
                    this.records = new Array<Timeline>();
                }
            );
    }
}
