export class DynamicReportParameter {
    type: string;
    value: string;
    dynamicReportId: number;
    dynamicReportParameterId: number;
}

export class DynamicReportDashboard {
    dynamicReportId: number;
    reportName: string;
    functionName: string;
    numOfParameters: number;
    createdByName: string;
    concatenatedParameters: string;
    createdDate: Date;
    parameters: Array<DynamicReportParameter>;
    parameterDataJsonString: string;
}
