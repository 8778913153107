import { LabPastReportsModel } from "./lab-past-reports-view.model";
export class LabStatusModel {
    labBookingTimeLineId: number;
    newLabBookingHeaderId: number;
    labBookingStatusId: number;
    commentedBy: number;
    comment: string;
    status: string;
    commentedByName: string;
    roleName: string;
    createdDate: Date;
    locationName: string;
    testName: string;
    newLabBookingDetailId: number;
    labsList: Array<LabPastReportsModel>;
    constructor() {
        this.labsList = new Array<LabPastReportsModel>();
    }
}