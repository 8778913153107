import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "initials"
})
export class InitialsPipe implements PipeTransform {
    transform(value: any) {
        if (!value) {
            return "";
        }

        if (value.indexOf(" ") > 0) {
            const titles = value.split(" ");
            return titles[0].substring(0, 1).toUpperCase() + titles[titles.length - 1].substring(0, 1).toUpperCase();
        } else {
            return value.substring(0, 2).toUpperCase();
        }
    }
}

@Pipe({
    name: "spaceTitle"
})
export class TitlePipe implements PipeTransform {
    transform(value: any) {
        if (!value) {
            return "";
        }

        return value.replace(/([A-Z])/g, " $1").replace("Mi Qlave", "MiQlave").trim();
    }
}