import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResources } from "@shared/helpers";

@Injectable()
export class ValidatorService {
    constructor(private readonly http: HttpClient) { }

    validateEmail(query: string, type: string, referenceId: number) {
        return this.validate(ApiResources.getURI(ApiResources.validators.base, ApiResources.validators.email), { query: query, type: type, referenceId: referenceId });
    }

    validateMobile(query: string, countryId: number, type: string, referenceId: number) {
        return this.validate(ApiResources.getURI(ApiResources.validators.base, ApiResources.validators.mobile), { query: query, countryId: countryId, type: type, referenceId: referenceId });
    }

    validateNpi(query: string, providerId: number) {
        return this.validate(ApiResources.getURI(ApiResources.validators.base, ApiResources.validators.npi), { query: query, referenceId: providerId });
    }

    validatePharmacyProduct(query: string, productId?: number) {
        return this.validate(ApiResources.getURI(ApiResources.validators.base, ApiResources.validators.pharmacyProduct), { query: query, pharmacyProductId: productId });
    }

    validateUserName(query: string, type: string, referenceId: number) {
        return this.validate(ApiResources.getURI(ApiResources.validators.base, ApiResources.validators.userName), { query: query, type: type, referenceId: referenceId });
    }

    private validate(apiEndPoint: string, request: any): Observable<boolean> {
        let headers = new HttpHeaders();
        headers = headers.append("Auth", "False");
        return this.http.post<boolean>(apiEndPoint, request, { headers: headers });
    }
}
