export class AppointmentDashboard {
    appointmentId: number;
    departmentId: number;
    patientId: number;
    encounterId?: number;
    appointmentNo?: string;
    patientAccountId: number;
    patientName: string;
    patientAge?: number;
    patientDateOfBirth?: Date;
    patientGender: string;
    patientMaritalStatus: string;
    patientMobile: string;
    patientEmail?: string;
    patientThumbnailUrl: string;
    patientCountryCode: string;
    appointmentDate: Date;
    appointmentTime: object;
    appointmentTimeString: string;
    providerName: string;
    providerId: number;
    providerLocationId: number;
    queueStatus: string;
    consultationTypeId: number;
    specializationId: number;
    providerAvailabilityId: number;
    umrNo: string;
    encryptedPatientId: string;
    roomName: string;
    emergencyTriage: string;
    queueStatusId: number;
    admTime: string;
    husbandName: string;
    encryptedAppointmentId: string;
}