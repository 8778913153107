import { AsyncValidatorFn, FormControl, ValidationErrors } from "@angular/forms";
import { Observable, timer } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ValidatorService } from "@shared/services";

export function uniqPharmacyProductValidator(validatorService: ValidatorService, key: string): AsyncValidatorFn {
    return (control: FormControl): Promise<ValidationErrors | (Object | null)> | Observable<ValidationErrors | (Object | null)> => {
        return timer(500).pipe(
            switchMap(() => {
                const productId: number = (control.parent.controls as any)[key] ? (control.parent.controls as any)[key].value : 0;
                return validatorService.validatePharmacyProduct(control.value, productId)
                    .pipe(
                        map((res: boolean) => {
                            return res ? { productExists: true } : null;
                        })
                    );
            })
        );
    };
}
