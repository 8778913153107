<style>
    @media print {
        .labreportfooter {
            position: fixed;
            bottom: 0;
            width: calc(100% - 1.50rem);
        }
    }
    th, td {
        padding: 0.5rem .5rem 0.5rem 0 ;
    }
</style>

<div class="modal-body" *ngIf="loading">
    <div class="d-flex align-items-center">
        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
        <span class="ml-2">Please wait while loading Report details....</span>
    </div>
</div>
<div *ngIf="!isExternalReport">
    <div class="modal-body" *ngIf="!loading && demographData.length === 0 && !isExternalReport">
        <div class="d-flex justify-content-center">
            <div class="no-data-container">
                <img class="mt-1" width="350" src="assets/images/no-data-2.png" alt="No Data" />
                <h4 class="mt-1">No Report Data found.Nothing to show you.</h4>
            </div>
        </div>
    </div>
    <div class="d-flex d-block justify-content-end mt-1">
        <button [useExistingCss]="true" printSectionId="invoiceId" ngxPrint class="btn btn-soft-info text-dark font-weight-semibold btn-xs mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>
    </div>
    <div  Id="invoiceId" *ngIf="!loading && demographData.length > 0" class=" d-flex mainbggrey justify-content-between pb-2 pl-2 pr-2 pt-0 w-100 flex-wrap" >
        <div class="heading2 pb-2 pl-2 pr-2 pt-1">
            <div class="w-100 justify-content-between d-flex">
                <div>
                    <div class="d-flex align-items-center" *ngIf="isPrintLogo">
                        <div class="mr-2">
                            <img src="assets/images/fernandezoriginal.svg" alt="logo" height="60">
                            <h4 class="mt4ml56">Build for Birthing</h4>
                        </div>
                        <div  class=" colorlh23 border-dark border-left font-weight-bold"><div class="ml-2">LABORATORY SERVICES</div></div>
                    </div>
                </div>

                <div class="d-flex d-block justify-content-end mt-1">
                    <div class="d-flex gap15">
                        <div class="font-weight-semibold mt-20px text-black lh20">
                            <div><i class=" color mdi mdi-heart mr-2"></i>T +91 40 4063 2414 / 18004191397 </div>
                            <div>
                                <i class=" color mdi mdi-circle-outline mr-2"></i>E appointments@fernandez.foundation
                            </div>
                            <div class="text-right color"> www.fernandezhospital.com</div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="print-content">
                <div id=" dataContainer" class="dataContainerminheight">
                    <div class="w-100 d-flex flex-wrap bb1solid">
                        <h3 class="headingText col-12 laboratory" colspan="5">
                            Laboratory Report
                        </h3>

                        <table width="100%" class="heading1">
                            <tr>
                                <th class="mainhead">
                                    <span *ngIf="demographData[0].patientName">Patient Name</span>
                                </th>
                                <td class="text-truncate white-space-nowrap data" >
                                   <span class="mr5">: </span>
                                    <span *ngIf="demographData[0].patientName" class="mb-0 mt-0 font-weight-normal text-uppercase"  [textContent]="demographData[0].patientName"></span>
                                </td>
                                <th class="mainhead">
                                    UMR No
                                </th>
                                <td class="white-space-nowrap data" >
                                    <span class="mr5" >: </span>
                                    <span [textContent]="demographData[0].umrNo || '-----'"></span>
                                </td>
                            </tr>
                            <tr>
                                <th class="agestyle">
                                    Age/Gender
                                </th>
                                <td class="data">
                                    <div>
                                        <span class="mr5">: </span>
                                        <span *ngIf="!demographData[0].dateOfBirth">{{demographData[0].age}} Year &nbsp;0 Month&nbsp;0Days </span>
                                        <span *ngIf="demographData[0].dateOfBirth">{{age.year}} Year &nbsp;{{age.month}} Month&nbsp;{{age.day}} Days </span>
                                        <span *ngIf="demographData[0].gender">&nbsp;,</span>
                                        <span *ngIf="demographData[0].gender" [textContent]="demographData[0].gender || '----'"></span>
                                    </div>
                                </td>
                                <th class="mainhead">
                                    <span>Invoice Date</span>
                                </th>
                                <td class="white-space-nowrap data" >
                                    <span class="mr5">: </span>
                                    <span [textContent]="demographData[0].bookedDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                </td>
                            </tr>
                            <tr>
                                <th class="mainhead">
                                    Requisition No
                                </th>
                                <td class="white-space-nowrap data">
                                    <span class="mr5">: </span>
                                    <span [textContent]="demographData[0].requisitionNumber"></span>
                                </td>
                                <th class="mainhead">
                                    <span>Location</span>
                                </th>
                                <td class="text-truncate white-space-nowrap data">
                                    <span class="mr5">: </span>
                                    <span [textContent]="demographData[0].fromLocation"></span>
                                </td>
                            </tr>
                            <tr>
                                <th class="mainhead">
                                    <span>Collected Date</span>
                                </th>
                                <td class="white-space-nowrap data" >
                                    <span class="mr5">: </span>
                                    <span [textContent]="demographData[0].collectionDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                </td>
                                <th class="mainhead">
                                    <span>Received Date</span>
                                </th>
                                <td class="data">
                                    <span class="mr5">: </span>
                                    <span [textContent]="demographData[0].receivedDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                </td>
                            </tr>
                            <tr>
                                <th class="mainhead">
                                    Doctor Name
                                </th>
                                <td class="text-truncate white-space-nowrap data">
                                    <span class="mr5">: </span>
                                    <span class="text-uppercase" [textContent]="demographData[0].doctorName || '------'"></span>
                                </td>
                                <th class="mainhead">
                                    <span>Patient Type</span>
                                </th>
                                <td class="data">
                                    <span class="mr5">: </span>
                                    <span [textContent]="demographData[0].bookingType"></span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="mt100" id="backgroundOfwaterMark" *ngIf="reportSignatures.length == 0">
                        <p id="bg-textOfwaterMark" class="interimReportWaterMark">Interim Report</p>
                    </div>
                    <div class="col-12 p-0 bb1solid">
                        <div *ngFor="let template of reports.templates;index as t;">
                            <div class="d-flex justify-content-center">
                                <h4 class="underline">{{template.templateName}}</h4>
                            </div>
                            <div>
                                <div class="overflow-auto textOfEveryVw">
                                    <table width="100%">
                                        <thead>
                                            <tr>
                                                <th class="pl-4">Test Name</th>
                                                <th class="pl-4">Result</th>
                                                <th class="white-space-nowrap pl-4">Biological Reference Range</th>
                                                <th class="pl-4">Unit</th>
                                                <th class="pl-4">Method</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let parameter of template.parameters;index as p;">
                                                <ng-container *ngIf="parameter.type === 'Regular'">
                                                    <td class="break-all pl-4">
                                                        <span [textContent]="parameter.parameterName || '-----'"></span>
                                                    </td>
                                                    <td class="singleline">
                                                        <input type="text" (keyup)="onChange(parameter)" [(ngModel)]="parameter.observedValue" *ngIf="parameter.referenceOutput != 'selection'" />&nbsp;
                                                        <span *ngIf="parameter.referenceOutput != 'selection'">{{parameter.showText}}</span>
                                                        <select *ngIf="parameter.referenceOutput === 'selection'" (change)="onChange(parameter)" class="form-control custom-select form-control-sm" autocomplete="nope" [(ngModel)]="parameter.observedValue">
                                                            <option [ngValue]="null">None</option>
                                                            <option *ngFor="let item of parameter.allParameterDetail" [textContent]="item.selectionName" [ngValue]="item.selectionName"></option>
                                                        </select>
                                                    </td>
                                                    <td class="break-all pl-4">
                                                        <span *ngIf="parameter.referenceOutput === 'numeric'">
                                                            {{parameter.selected.minValue}}-{{parameter.selected.maxValue}}
                                                        </span>
                                                        <span *ngIf="parameter.referenceOutput != 'numeric'">
                                                            <span [innerHTML]="parameter.text || '--'"></span>
                                                        </span>
                                                    </td>
                                                    <td class="pl-4">
                                                        <span *ngIf="parameter.selected && parameter.selected != undefined && parameter.selected.unitName != undefined && parameter.selected.unitName != null">
                                                            {{parameter.selected.unitName || ''}}
                                                        </span>
                                                    </td>
                                                    <td class="pl-4">
                                                        <span [textContent]="parameter.methodName || ' '"></span>
                                                    </td>
                                                </ng-container>
                                                <ng-container *ngIf="parameter.type != 'Regular'">
                                                    <td colspan="5" class="break-all pl-3">
                                                        <div class="d-flex mb-2">
                                                            <div class="col-4">
                                                                <label class="mb-0 text-capitalize text-primary"> {{parameter.parameterName}}</label>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class="mb-0 position-relative">
                                                                    <ng-select class="ng-select-sm" [items]="parameter.antibiotics"
                                                                               bindLabel="antibioticName"
                                                                               bindValue="antibioticName"
                                                                               typeToSearchText="Search Parameter"
                                                                               placeholder="Enter Parameter name"
                                                                               (change)="onClickMicroParameter($event ,t,p)"
                                                                               [(ngModel)]="selectParameter">
                                                                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                                            No parameter found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                                                                        </ng-template>
                                                                        <ng-template ng-option-tmp let-item="item" let-i="index">
                                                                            <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.antibioticName"></h5>
                                                                        </ng-template>
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngFor="let antibiotic of parameter.antibiotics;index as j;">
                                                            <div class="row form-group col-12" *ngIf="antibiotic.isHaveobservedValue">
                                                                <label class="mb-0 width21 pl-2"> {{antibiotic.antibioticName}}</label>
                                                                <input type="text" placeholder="Enter {{antibiotic.antibioticName}}" [(ngModel)]="antibiotic.observedValue" />
                                                                <div *ngIf="!antibiotic.labParameterobservedValueId">
                                                                    <button type="button" class="btn btn-sm waves-effect waves-light"
                                                                            (click)="removeAntibiotic(parameter,j)">
                                                                        Remove
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>


                    </div>

                </div>
            </div>
            <div class="noprint-column footerrow">
                <div class="row justify-content-lg-between">
                    <div>
                        <ng-container *ngIf="selectedLab">
                            <div class="align-content-lg-end d-flex">
                                <div class="align-items-center">
                                    <button type="button" class="btn btn-xs btn-primary" (click)="onViewPastLabReports(templateShowPastReports,selectedLab.patientId,selectedLab.labMainDetailId)">View Past Lab reports</button>
                                </div>
                                <div class="align-content-end">
                                    <button type="button" class="btn btn-xs btn-primary" *ngIf="selectedLab.status !== 'Hold' && selectedLab.status !== 'Verified' && selectedLab.status !== 'UnHold'" (click)="onOpenHoldModal('Hold',selectedLab.newLabBookingDetailId,templateHold)">Hold</button>
                                    <button type="button" class="btn btn-xs btn-primary" *ngIf="selectedLab.status === 'Hold' && selectedLab.status !== 'Verified' && selectedLab.status !== 'DoctorAssigned' && selectedLab.status !== 'DoctorRemoved' && selectedLab.status !== 'ParameterAdded'" (click)="onOpenUnHoldModal('UnHold',selectedLab.newLabBookingDetailId,selectedLab.holdComments,templateHold)">UnHold</button>
                                    <button type="button" class="btn btn-xs btn-primary" *ngIf="selectedLab.appointmentId && selectedLab.encounterType !=null" (click)="onViewPrintPrescription(selectedLab.encryptedAppointmentId,selectedLab.encounterType)">Print Prescription</button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="mr40">
                        <ng-container >
                            <button *ngIf="!viewandverify" [disabled]="disabled" class="btn btn-sm btn-outline-primary waves-effect waves-light" (click)="onSubmit()"> Submit</button>
                            <button class="btn btn-sm btn-outline-primary waves-effect waves-light" (click)="onClear()"> Clear</button>
                            <button *ngIf="viewandverify && selectedLab" class="btn btn-sm btn-outline-primary waves-effect waves-light" (click)="onVerifyAndSubmit()">
                                <span *ngIf="!submitting && disabled">
                                    <i class="mdi mdi-check-all mr-1"></i> Verify
                                </span>
                                <span *ngIf="!submitting && !disabled">
                                    <i class="mdi mdi-check-all mr-1"></i> Submit & Verify
                                </span>
                                <span *ngIf="submitting">
                                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                    Please wait..
                                </span>
                            </button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #templateShowPastReports>
    <div class="modal-header d-flex justify-content-between">
        <div>
            <h4 class="modal-title">Assign Doctor</h4>
        </div>
        <div>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModel();">×</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row p-2" *ngIf="loadingPastLabReports">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading ...</span>
                </div>
            </div>
        </div>
        <div class="row p-1" *ngIf="!loadingPastLabReports && !patientPastLabReports">
            <div class="col-12 mh-355">
                <no-data [applied]="false" [title]="'Past Tests'"></no-data>
            </div>
        </div>
        <div class="col-12">
            <div class="card-box p-0 overflow-auto">
                <table class="table table-sm table-bordered">
                    <thead class=" ">
                        <tr>
                            <th>Test Name </th>
                            <th>TypeOfLab</th>
                            <th>BookedDate</th>
                            <th>Status</th>
                            <th class="text-right">Actions</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let item of patientPastLabReports;index as i;">
                            <td>
                                <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.testName"></h5>
                                <small class="d-block" [textContent]="item.testCode || '--'"></small>
                            </td>
                            <td>
                                <span class="badge badge-success" *ngIf="item.isInternalLab">Internal</span>
                                <span class="badge badge-secondary" *ngIf="item.isExternalLab">External</span>
                            </td>
                            <td>
                                <span [textContent]="item.createdDate | date:'dd-MM-yyyy, hh:mm a'"></span>
                            </td>
                            <td>
                                <span class="badge badge-outline-secondary" [textContent]="item.status"></span>
                            </td>
                            <td>
                                <button type="button" placement="left" ngbTooltip="View Report" class="btn mt-1 btn-sm btn-outline-info" (click)="onOpenLAbReport(item)">
                                    <i class="mdi mdi-eye mr-1"></i> View Report
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="5">
                                <nav class="d-flex align-items-center justify-content-between flex-wrap">
                                    <p class="mb-0 font-13">
                                        <span class="text-dark">Page <span [textContent]="pagination1.currentPage"></span> of <span [textContent]="pagination1.totalPages"></span></span>
                                        <span class="ml-1">
                                            <span>(Showing <span [textContent]="pagination1.currentItems - patientPastLabReports.length + 1"></span> - <span [textContent]="pagination1.currentItems"></span> of <span [textContent]="pagination1.totalItems"></span> records)</span>
                                        </span>
                                    </p>
                                    <ngb-pagination class="pagination justify-content-end" [maxSize]="5" [rotate]="false" [ellipses]="false" [(page)]="pagination1.pageIndex" [pageSize]="pagination1.pageSize" (pageChange)="onNextTemplatePage()" [collectionSize]="pagination1.totalItems">
                                        <ng-template ngbPaginationPrevious><i class="fe-arrow-left"></i></ng-template>
                                        <ng-template ngbPaginationNext><i class="fe-arrow-right"></i></ng-template>
                                    </ngb-pagination>
                                </nav>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #templateHold>
    <form [formGroup]="holdForm" (ngSubmit)="onSubmitHold()">
        <div class="modal-header d-flex justify-content-between">
            <div>
                <h4 class="modal-title">Hold</h4>
            </div>
            <div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModel();">×</button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="mb-1">Hold Comments<code>*</code></label>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Enter comment here." formControlName="holdComments"
                                   [ngClass]="{ 'is-invalid': (submitted && forms.holdForm.holdComments.errors) }" />
                            <div class="invalid-feedback show-must" *ngIf="submitted && forms.holdForm.holdComments.errors">
                                <span *ngIf="forms.holdForm.holdComments.errors.required">This field is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="forms.holdForm.status.value !== 'Hold'">
                        <label class="mb-1">UnHold Comments<code>*</code></label>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Enter comment here." formControlName="unHoldComments"
                                   [ngClass]="{ 'is-invalid': (submitted && forms.holdForm.unHoldComments.errors) }" />
                            <div class="invalid-feedback show-must" *ngIf="submitted && forms.holdForm.unHoldComments.errors">
                                <span *ngIf="forms.holdForm.unHoldComments.errors.required">This field is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-light" (click)="onCloseModel()">
                <i class="mdi mdi-close mr-1"></i>Close
            </button>
            <button type="submit" class="btn btn-sm btn-primary">
                <span *ngIf="!submitting">
                    <i class="mdi mdi-check mr-1"></i>Submit
                </span>
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
            </button>
        </div>
    </form>
</ng-template>

