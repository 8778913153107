import { LabReportParameter } from "./lab-report-parameter.entity";

export class LabInPackageBooking {
    labBookingDetailId: number;
    labBookingHeaderId: number;
    labBookingPackageDetailId: number;
    labHeaderId: number;
    status: string;
    labName: string;
    labCode: string;
    isReportGenerated?: boolean;
    reportDate?: Date;
    typedBy?: number;
    verifiedBy?: number;
    isSampleCollected?: boolean;
    sampleDate?: Date;
    typedDate?: Date;
    typedByName: string;
    verifiedByName: string;
    labsParameters: Array<LabReportParameter>;
    barcode: string;
    isSelected: boolean = false;
    collectedSampleReceivedBy: string;
    verifiedByDoctorName: string;
    sampleReceivedDate: Date;

    constructor() {
        this.labsParameters = new Array<LabReportParameter>();
    }
}