import { FormControl } from "@angular/forms";
import { RegExHelper } from "@shared/helpers";

export class WhiteSpaceValidator {
    static isValid(control: FormControl): object | null {
        const value = control.value;

        if (!value) {
            return null;
        }
        
        if (RegExHelper.whiteSpace.test(value)) {
            return { 'whiteSpaces': true };
        }

        return null;
    }
}