import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { IUserAccount } from "@shared/models";

@Injectable()
export class AppData {
    private accountSource = new BehaviorSubject(null);
    userAccount: Observable<IUserAccount> = this.accountSource.asObservable();
    setAccount(userAccount: IUserAccount) {
        this.accountSource.next(userAccount);
    }
}