export class Roles {
    roleId: number;
    roleName: string;
    active: boolean;
    totalItems: number;
    createdByName: string;
    createdBy: number;
    createdDate: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    createdByRole: string;
    modifiedByName: string;
    modifiedByRole: string;
}