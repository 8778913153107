import { Component, ViewEncapsulation, OnInit, OnDestroy, TemplateRef, Input, Output, EventEmitter } from "@angular/core";
import { IUserAccount, Page } from "@shared/models";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AppData, HttpService, NotifyService } from "@shared/services";
import { PatientChatBoxModel } from "../../entities/chat-box.entity";
import { ApiResources } from "@shared/helpers";
import { takeUntil, finalize } from "rxjs/operators";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WhiteSpaceValidator } from "../../validators";



@Component({
    templateUrl: "./test-chat-box.html",
    selector: "test-chat-box",
    encapsulation: ViewEncapsulation.None
})
export class TestChatBoxWidget implements OnInit, OnDestroy {
    @Input() newLabBookingDetailId: number;
    @Output() isClose = new EventEmitter<boolean>();
    page: Page;
    chatBoxForm: FormGroup;
    loading: boolean;
    records: Array<PatientChatBoxModel>;
    submitted: boolean;
    modalRef: NgbModalRef;
    submitting: boolean;

    constructor(
        private readonly appData: AppData,
        private readonly formBuilder: FormBuilder,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
        private readonly modalService: NgbModal,

    ) {
        this.page = new Page();
        this.buildForm();
        this.records = new Array<PatientChatBoxModel>();
    }
    private buildForm() {
        this.chatBoxForm = this.formBuilder.group({
            PatientChatBoxId: 0,
            message: [null, [Validators.required, WhiteSpaceValidator.isValid]]
        });
    }
    get form() {
        return this.chatBoxForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        if (this.chatBoxForm.invalid) {
            return;
        }
        this.submitting = true;
        const request = {
            ...this.chatBoxForm.getRawValue(),
            createdBy: this.page.userAccount.accountId,
            createdByName: this.page.userAccount.fullName,
            newLabBookingDetailId: this.newLabBookingDetailId
        };
        var url = ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.addTestMessage);
        this.httpService.post(url, request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.submitted = this.submitting = false))
            .subscribe(
                (response: number) => {
                    if (response > 0) {
                        this.notifyService.successToast("Test Comments Saved Successfully");
                    }
                    this.fetchAll();
                    this.isClose.emit();
                });
    }

    private fetchAll() {
        this.loading = true;
        const request = {
            newLabBookingDetailId: this.newLabBookingDetailId
        };
        this.httpService.post<Array<PatientChatBoxModel>>(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchTestMessages), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<PatientChatBoxModel>) => {
                    this.records = response;
                });
    }

    onOpenModel(content: TemplateRef<any>, record?: PatientChatBoxModel) {
        this.buildForm();
        if (record) {
            this.chatBoxForm.patchValue({
                patientChatBoxId: record.patientChatBoxId,
                message: record.message,
            });
        }
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            windowClass: "custom-modal effect-scale"
        });
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchAll();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
}