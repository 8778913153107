export class InventoryLog {
    inventoryLogId: number;
    logDescription: string;
    logDate: string;
    inventorylogTypeId: number;
    logFrom:number;  
    logTypeName:string;
    AccountId:number;
    fullName:string;
    roleName:string;
    totalItems: number;
    roleId: number;
}
