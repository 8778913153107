import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResources } from "@shared/helpers";

@Injectable()
export class UrlService {
    constructor(private readonly http: HttpClient) {
    }

    encode(value: string) {
        return this.post(ApiResources.getURI(ApiResources.application.base, ApiResources.application.encode), { json: value });
    }

    decode(value: string) {
        return this.post(ApiResources.getURI(ApiResources.application.base, ApiResources.application.decode), { json: value });
    }

    private post(apiEndPoint: string, request?: any): Observable<string> {
        let headers = new HttpHeaders();
        headers = headers.append("Auth", "False");
        return this.http.post<string>(apiEndPoint, request, { headers: headers });
    }
}