import { Component, OnInit, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation, TemplateRef, ViewChild } from "@angular/core";
import { IUserAccount, Page, IResource, ProgressData } from "@shared/models";
import { AppData, HttpService, NotifyService, ResourceService } from "@shared/services";
import { takeUntil, finalize } from "rxjs/operators";
import { LabParameterInputModel, LabComponentHeaderForInput, LabParameterInputHeaderModel, LabTransferModel, LabStatusModel, LabParameterGroup, LabTemplateHeaderForInput, LabBillDetailModel, LabPastReportsModel, LabBillHeaderModel, LabTemplateDetail } from "@admin/labs/pages/models";
import { ApiResources, UtilHelper, LinqHelper } from "@shared/helpers";
import { forkJoin } from "rxjs";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ProviderSpecialization } from "../../entities/provider-specialization.entity";
import { LabReportSignature } from "../../../areas/admin/labs/pages/models/lab-report-signature";
import { DomSanitizer } from "@angular/platform-browser";
import { LabObservedValueModel } from "../../../areas/admin/labs/pages/models/lab-observedValue.model";
import { HttpErrorResponse } from "@angular/common/http";
import { LabDocumentsDetails } from "../../entities/laboratory/lab-document-details.entity";
import { ModalType } from "@shared/enums";
import { Pagination } from "@shared/models";
import { GraphView, LabParameter, LookUpValue } from "../../entities";
import { Router } from "@angular/router";
import { Doctors, VerifiedTestsModel } from "../../../areas/admin/labs/pages/models/verified-tests-model";
import { evaluate, pow } from "mathjs";

class paramaterResultCalculationHelper {
    parameterCode?: string;
    parameterId?: string;
    result?: string;
}

class TemplateInput {
    newLabBookingDetailId: number;
    labTemplateObservedValueId: number;
    labTemplateHeaderId: number;
    isMethod: boolean;
    methodText: string;
    isInterpretation: boolean;
    interpretationText: string;
    labMainDetailId: number;
}

class Age {
    year: number;
    month: number;
    day: number;
}
class LabObservedValue {
    labParameterHeaderId: number;
    parameterName: string;
    observedValues: Array<number>;
    constructor() {
        this.observedValues = new Array<number>();
    }
}

class TempParameter {
    labParameterObservedValueId?: number;
    labComponentHeaderId: number;
    componentName: string;
    labTemplateHeaderId: number;
    templateName: string;
    labParameterHeaderId: number;
    parameterName: string;
    displayName: string;
    referenceOutput: string;
    observedValue: string;
    templateCount: number;
    componentCount: number;
    labParameterDetailId?: number;
    labAntibioticsId?: number;
    selected: LabParameterGroup;
}
class FilterOptions {
    fromDate: string;
    toDate: string;
    transferNumber: string;
    departmentName: string;
    testName: string;
    receivedFromDate: string;
    receivedToDate: string;
    patientId: number;
    bookingType: string = null;
    labBookingStatusId?: number;
    requisitionNumber: string;
}
class Filters {
    options: FilterOptions;
    applied: boolean;

    init() {
        this.options = new FilterOptions();
        this.applied = false;
    }

    constructor() { this.init(); }
}
class LabTagMaster {
    name: string;
    lookupValueId: string;
    active: boolean;
    priority: string;
}
class Orgasms {
    templateIndex: number
    parameterIndex: number;
    componentParamIndex: number;
    selectedparameterId: number;
}
class TemplateDetailsOrderList {
    templateDetailsOrder: Array<any>;
    templateName: string;
    template: LabTemplateHeaderForInput
}

@Component({
    templateUrl: "./new-lab-schema-parameter.html",
    selector: "new-lab-schema-parameter",
    styleUrls: ['./new-lab-schema-parameter.css'],
    encapsulation: ViewEncapsulation.None
})

export class NewLabSchemaParameterWidget implements OnInit, OnDestroy {
    @ViewChild("#templateReport", { static: true }) templateReport: TemplateRef<any>;
    @Input() newLabBookingDetailId: number;
    @Input() encryptedNewLabBookingDetailId: string;
    @Output() onClose = new EventEmitter<any>();
    @Input() isPrintLogo: boolean;
    @Input() isValidation: boolean;
    @Input() showTechnitionVerify: boolean;
    @Input() showDoctorVerify: boolean;
    @Input() resultsType: string;
    page: Page;
    loading: boolean;
    parameters: LabParameterInputModel;
    reports: LabParameterInputModel;
    submitting: boolean;
    submitted: boolean;
    templateInput: Array<TemplateInput>;
    parameterInput: Array<TempParameter>;
    loadingSample: boolean;
    demographData: Array<LabTransferModel>;
    showLabDocument: boolean;
    loadingTimeline: boolean;
    timeline: Array<LabStatusModel>;
    labAntibiotics: Array<LabParameterInputHeaderModel>;

    modalRef: NgbModalRef;
    selectedParameter: LabParameterInputHeaderModel
    currentDate: Date;
    notesEditor = ClassicEditor;
    config = {
        placeholder: "Enter your content here!",
        toolbar: ["heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "blockQuote"]
    };
    age: Age;
    loadingProviders: boolean;
    providers: Array<IResource>;
    providerAssigningForm: FormGroup;
    providerSpecialization: Array<ProviderSpecialization>;
    reportSignatures: Array<LabReportSignature>;
    loadingDoctorSignatures: boolean;
    provideredit: boolean;
    providerAssigned: Array<LabReportSignature>;
    loadingproviderAssigning: boolean;
    sendpatientId: string;
    doctorDropDown: boolean;
    loadingObservedValues: boolean;
    totalObservedValues: Array<LabObservedValueModel>;
    totalObservedValues2: any;
    parameterNames: any;
    showLabReport: boolean;
    fileUploadFail: boolean;
    loadingFile: boolean;
    uploadedFile: File;
    fileName: string;
    formedUrl: any;
    documentError: boolean;
    selectedLabBookingDetail: LabBillDetailModel;
    isUploading: boolean;
    techForm: FormGroup;
    isVerify: boolean;
    doctorAssigned: boolean;

    checkExternal: LabBillDetailModel;
    isExternalDocument: boolean;
    isExternalLoading: boolean;
    reportDataWithoutAuth: string;
    reportData: any;
    loadingDocument: boolean;
    records: LabBillDetailModel;
    documentUrl: string;
    errorMessage: any;
    parametersDummy: LabParameterInputModel;
    showButton = true;
    isAbnormal: boolean;
    modalRefViewDocument: NgbModalRef;
    patientId: number;
    warningMessage: string;
    files: Array<File>;
    maxFiles = 10;
    modalRefDocument: NgbModalRef;
    documents: Array<LabDocumentsDetails>;
    labAntibioticsResultTypes: Array<LookUpValue>;
    document: LabDocumentsDetails;
    showPrevious: boolean;
    showNext: boolean;
    documentName: string;
    pagination: Pagination;
    modifyingContent: string;
    modifying: boolean;
    encryptedPatientId: string;
    modalRefView: NgbModalRef;
    uploadForm: FormGroup;
    showReport: boolean;
    selectParameter: string;
    reportsDummy: LabParameterInputModel;
    patientPastLabReports: LabPastReportsModel[];
    loadingPastLabReports: boolean;
    labMainDetailId: number;
    pagination1: Pagination;
    verifying: boolean;
    doctorDetails: Doctors;
    isHaveParameters: boolean;
    reason: string;
    modalReason: NgbModalRef;
    parametersList: any[];
    labObservedValues: LabObservedValue[];
    labsList: LabBillHeaderModel[];
    allparameters: LabParameter[];
    labs: any[];
    filters: Filters;
    isShowParameters: boolean;
    graphRef: NgbModalRef;
    sampleVerified: VerifiedTestsModel[];
    graphData: GraphView;
    isGrapgShow: boolean;
    @ViewChild("graphView", { static: true }) graphView: TemplateRef<any>;
    @ViewChild("parametersListView", { static: true }) parametersListView: TemplateRef<any>;
    reportRef: NgbModalRef;
    isFooter: boolean;
    isNoPastRecords: boolean;
    newLabBookingHeaderId: number;
    antiBioticMaster: any[];
    selectedAntibioticTagMasterId: number;
    labTagMasterList: Array<LabTagMaster>;
    regex: RegExp;
    templateDetail: LabTemplateDetail[];
    orgasmsList: Array<Orgasms>;
    parametersAll: Array<LabParameterInputModel>;
    reportsAll: Array<LabParameterInputModel>;
    parametersAllDummy: LabParameterInputModel[];
    reportsAllDummy: any[];
    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly modalService: NgbModal,
        private readonly notifyService: NotifyService,
        private readonly resourceService: ResourceService,
        private readonly formBuilder: FormBuilder,
        private readonly sanitizer: DomSanitizer,
        private readonly router: Router,

    ) {
        this.page = new Page();
        this.doctorAssigned = false;
        this.doctorDropDown = false;
        this.provideredit = false;
        this.parameters = new LabParameterInputModel();
        this.templateInput = new Array<TemplateInput>();
        this.demographData = new Array<LabTransferModel>();
        this.timeline = new Array<LabStatusModel>();
        this.selectedParameter = new LabParameterInputHeaderModel();
        this.age = new Age();
        this.providers = new Array<IResource>();
        this.buildProviderAssigningForm();
        this.providerSpecialization = new Array<ProviderSpecialization>();
        this.reportSignatures = new Array<LabReportSignature>();
        this.totalObservedValues = new Array<LabObservedValueModel>();
        this.labAntibioticsResultTypes = new Array<LookUpValue>();
        this.reports = new LabParameterInputModel();
        this.showLabReport = false;
        this.showLabDocument = false;
        this.selectedLabBookingDetail = new LabBillDetailModel();
        this.initPagination();
        this.initPagination1();
        this.reportsDummy = new LabParameterInputModel();
        this.labAntibiotics = new Array<LabParameterInputHeaderModel>();
        this.filters = new Filters();
        this.labTagMasterList = new Array<LabTagMaster>();
        this.orgasmsList = new Array<Orgasms>();
        this.reportsAll = new Array<LabParameterInputModel>();
        this.parametersAll = new Array<LabParameterInputModel>();


    }
    private initPagination() {
        this.pagination = new Pagination();
        this.pagination.pageIndex = 1;
        this.pagination.pageSize = 12;
    }
    safe = (url: any) => {
        if (url) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${url}`);
        }
    }
    onCloseModel() {
        this.onClose.emit();
    }

    private onFetchDemographData() {
        this.loadingSample = true;
        const request = {
            //  newLabBookingDetailId: this.newLabBookingDetailId
            encryptedNewLabBookingDetailId: this.encryptedNewLabBookingDetailId
        };
        this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchReceivedLabs), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingSample = false))
            .subscribe((response: Array<LabTransferModel>) => {
                this.demographData = response;
                this.fetchDocuments();
                this.sendpatientId = this.demographData[0].encryptedId;
                this.newLabBookingHeaderId = this.demographData[0].newLabBookingHeaderId;
                this.fetchPatientAllParameters(this.demographData[0].patientId);
                this.onFetchParameters(this.demographData[0].encryptedLabMainDetailId);
                this.OnFetchVerifiedLabsList(this.demographData[0].newLabBookingDetailId);
                var selectedDemographData = this.demographData ? this.demographData.find(x => x.encryptedNewLabBookingDetailId == this.encryptedNewLabBookingDetailId) : null;
                this.newLabBookingDetailId = selectedDemographData ? selectedDemographData.newLabBookingDetailId : null;
                this.callwhenAfterProvidersLoaded(this.newLabBookingDetailId);
                this.fetchNewLabBookingDetail();
                if (UtilHelper.isEmpty(this.demographData[0].dateOfBirth)) {
                    let mdob = moment(this.demographData[0].dateOfBirth, "YYYY-MM-DD");
                    let age = moment().diff(mdob, "months");
                    let yrs = (age / 12).toFixed(1);
                    let months = age % 12;
                    let days = moment().diff(mdob.add(yrs, 'years'), 'days', false);
                    this.age.day = days;
                    this.age.year = parseInt(yrs, 10);
                    this.age.month = months;
                    let date1 = moment(this.demographData[0].dateOfBirth, "YYYY-MM-DD");
                    let monthsa = moment().diff(date1, 'months');
                    date1.add(monthsa, 'months');
                    let daysa = moment().diff(date1, 'days');
                    monthsa + ' ' + 'Month(s)' + ' ' + daysa + " Day(s)"
                    this.age.day = daysa;
                } else {
                    this.age = new Age();
                }
            });
    }

    private onFetchInputParameters() {
        const request = {
            encryptedNewLabBookingDetailId: this.encryptedNewLabBookingDetailId,
            fromTech: "tech",
            schemaReport: true
        };
        this.loading = true;
        this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.labparameterforinputschema), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<LabParameterInputModel>) => {
                if (response) {
                    this.parametersAll = response;
                    this.reportsAll = [];
                    this.parametersAll.forEach((singleTest, index) => {
                        singleTest.templates.forEach((template: LabTemplateHeaderForInput) => {
                            let parameterList = new Array<LabParameterInputHeaderModel>();
                            template.components.forEach((component: LabComponentHeaderForInput) => {
                                component.isShow = false;
                                component.parameters.forEach((param: LabParameterInputHeaderModel) => {
                                    var parameterValue = 1;
                                    let observedValue;
                                    if (param.observedValue) {
                                        component.isShow = true;
                                    }
                                    if (param.observedValue && (param.observedValue[0] == ">" || param.observedValue[0] == "<") && param.observedValue?.length > 1) {
                                        component.isShow = true;
                                        var value = param.observedValue[0];
                                        observedValue = +param.observedValue.substring(1)
                                        if (value == ">") {
                                            observedValue = observedValue + 1;
                                        } else if (value == "<") {
                                            observedValue = observedValue - 1;
                                        }
                                    } else {
                                        if (param.observedValue?.includes("^")) {
                                            var values = param.observedValue?.split('^');
                                            if (values?.length > 1) {
                                                param.observedValueList = new Array<string>;
                                                param.isObservedValuePower = true;
                                                param.observedValueList.push(values[0]);
                                                param.observedValueList.push(values[1]);
                                                parameterValue = Number(pow(Number(values[0]), Number(values[1])));
                                            }
                                        }
                                        observedValue = +param.observedValue;
                                    }
                                    let range = param.selected;
                                    if (range != null) {
                                        let exactRange = range;
                                        if (param.observedValue) {
                                            if (exactRange.minValue || exactRange.maxValue) {
                                                if (exactRange.minValue > observedValue || (parameterValue == 1 ? false : exactRange.minValue > parameterValue)) {
                                                    param.showText = "L";
                                                    param.isBold = true;
                                                }
                                                else if (observedValue > +exactRange.maxValue || (parameterValue == 1 ? false : parameterValue > +exactRange.maxValue)) {
                                                    param.showText = "H";
                                                    param.isBold = true;
                                                }
                                            }
                                        }
                                    }
                                    if (param.type == "Microbiology" && param.antibiotics.length > 0) {
                                        var isNoValue = param.antibiotics.every(x => x.observedValue == null);
                                        if (isNoValue) {
                                            param.antibiotics = [];
                                        } else {
                                            var resultAddedAntibiotics = param.antibiotics.filter(x => x.observedValue != null);
                                            if (resultAddedAntibiotics.length > 0) {
                                                param.antibiotics = resultAddedAntibiotics;
                                                this.isHaveParameters = true;
                                                parameterList.push(param);
                                            } else {
                                                parameterList.push(param);
                                            }
                                        }
                                    }
                                });
                            });
                            template.parameters.forEach((param: LabParameterInputHeaderModel) => {
                                var parameterValue = 1;
                                let observedValue;
                                if (param.observedValue && (param.observedValue[0] == ">" || param.observedValue[0] == "<") && param.observedValue?.length > 1) {
                                    var value = param.observedValue[0];
                                    observedValue = +param.observedValue.substring(1)
                                    if (value == ">") {
                                        observedValue = observedValue + 1;
                                    } else if (value == "<") {
                                        observedValue = observedValue - 1;
                                    }
                                } else {
                                    if (param.observedValue?.includes("^")) {
                                        var values = param.observedValue?.split('^');
                                        if (values?.length > 1) {
                                            param.observedValueList = new Array<string>;
                                            param.isObservedValuePower = true;
                                            param.observedValueList.push(values[0]);
                                            param.observedValueList.push(values[1]);
                                            parameterValue = Number(pow(Number(values[0]), Number(values[1])));
                                        }
                                    }
                                    observedValue = +param.observedValue;
                                }
                                let range = param.selected;
                                if (range != null) {
                                    let exactRange = range;
                                    if (param.observedValue) {
                                        if (exactRange.minValue || exactRange.maxValue) {
                                            if (exactRange.minValue > observedValue || (parameterValue == 1 ? false : exactRange.minValue > parameterValue)) {
                                                param.showText = "L";
                                                param.isBold = true;
                                            }
                                            else if (observedValue > +exactRange.maxValue || (parameterValue == 1 ? false : parameterValue > +exactRange.maxValue)) {
                                                param.showText = "H";
                                                param.isBold = true;
                                            }
                                        }
                                    }
                                    
                                }
                                if (param.type == "Microbiology" && param.antibiotics.length > 0) {
                                    var isNoValue = param.antibiotics.every(x => x.observedValue == null);
                                    if (isNoValue) {
                                        param.antibiotics = [];
                                    } else {
                                        var resultAddedAntibiotics = param.antibiotics.filter(x => x.observedValue != null);
                                        if (resultAddedAntibiotics.length > 0) {
                                            param.antibiotics = resultAddedAntibiotics;
                                            this.isHaveParameters = true;
                                            parameterList.push(param);
                                        } else {
                                            parameterList.push(param);
                                        }
                                    }
                                }
                            });
                        });

                    });
                    this.reportsAll = this.parametersAll;
                    this.parametersAll.forEach((test, index) => {
                        this.fetchTemplateDetail(test?.templates[0]?.labTemplateHeaderId, index, false)
                    });

                }
                this.oncheckFormulaResults();
            });
    }

    onSubmit(isFromDoctor?: boolean) {
        let requestApi = new Array<any>();
        var parameterRequestList = new Array<any>();
        var templatesList = new Array<any>();

        const BreakError = {};
        const req2 = {
            newLabBookingDetailId: this.newLabBookingDetailId,
        };
        req2["patientId"] = this.demographData[0].patientId;
        req2["urlLink"] = window.location.href;
        req2["testName"] = this.demographData[0].testName;
        let sampleNum = 0;
        if (this.demographData[0].status != this.demographData[0].subStatus) {
            let sampleNumber = this.demographData[0] && UtilHelper.isEmpty(this.demographData[0].sampleName) ? this.demographData[0].sampleName.split(" ") : new Array<string>();
            if (sampleNumber.length > 0) {
                sampleNum = +sampleNumber[1];
            }
        }
        try {
            this.parametersAll.forEach((parameters, i) => {
                    const templates = parameters.templates;
                    this.parameterInput = new Array<TempParameter>();
                    this.templateInput = new Array<TemplateInput>();
                    templates.forEach((template) => {
                        if (this.showTechnitionVerify || this.showDoctorVerify) {
                            if (template.isMethod) {
                                if (UtilHelper.isEmpty(template.methodText) && UtilHelper.isEmpty(template.methodText.trim())) {
                                    // ignore
                                } else {
                                    this.notifyService.warning(`Please enter method text for template ${template.templateName}`);
                                    throw BreakError;
                                }
                            }
                        }
                        let param = 0;
                        template.components.forEach((component: LabComponentHeaderForInput) => {
                            var abnormalIndex = component.parameters.findIndex(param => param.showText == "H" || param.showText == "L");
                            if (abnormalIndex > -1) {
                                this.isAbnormal = true;
                            } else {
                                this.isAbnormal = false;
                            }
                            component.parameters.forEach((parameter: LabParameterInputHeaderModel) => {
                                let model = new TempParameter();
                                model.labComponentHeaderId = component.labComponentHeaderId;
                                model.labTemplateHeaderId = template.labTemplateHeaderId;
                                model.templateName = template.templateName;
                                model.labParameterHeaderId = parameter.labParameterHeaderId;
                                model.parameterName = parameter.parameterName;
                                model.referenceOutput = parameter.referenceOutput;
                                param = param + 1;
                                if (this.isValidation && parameter.validation) {
                                    if (parameter.type === "Regular") {
                                        if (this.demographData[0].status != this.demographData[0].subStatus && sampleNum > 0 && sampleNum >= param) {
                                            if (!UtilHelper.isEmpty(parameter.observedValue)) {
                                                this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> ${component.componentName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                                throw BreakError;
                                            }
                                            else if (!UtilHelper.isEmpty(parameter.observedValue.trim())) {
                                                this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> ${component.componentName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                                throw BreakError;
                                            }
                                        }
                                        else if (this.demographData[0].status === this.demographData[0].subStatus) {
                                            if (!UtilHelper.isEmpty(parameter.observedValue)) {
                                                this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> ${component.componentName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                                throw BreakError;
                                            }
                                            else if (!UtilHelper.isEmpty(parameter.observedValue.trim())) {
                                                this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> ${component.componentName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                                throw BreakError;
                                            }
                                        }
                                    }
                                }


                                if (parameter.type === "Regular") {
                                    if (this.isValidation && parameter.validation) {
                                        if (!UtilHelper.isEmpty(parameter.observedValue)) {
                                            this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> ${component.componentName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                            throw BreakError;
                                        }
                                        else if (!UtilHelper.isEmpty(parameter.observedValue.trim())) {
                                            this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> ${component.componentName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                            throw BreakError;
                                        }
                                    }
                                    model.observedValue = parameter.observedValue;
                                    model.labParameterObservedValueId = parameter.labParameterObservedValueId;
                                    model.labParameterDetailId = parameter.labParameterDetailId;
                                    model.selected = parameter.selected;
                                    this.parameterInput.push(model);
                                }
                                else {
                                    parameter?.antibiotics?.forEach((antibiotic: LabParameterInputHeaderModel) => {
                                        let tempModel = new TempParameter();
                                        tempModel.labComponentHeaderId = 0;
                                        tempModel.labTemplateHeaderId = template.labTemplateHeaderId;
                                        tempModel.templateName = template.templateName;
                                        tempModel.labParameterHeaderId = parameter.labParameterHeaderId;
                                        tempModel.parameterName = parameter.parameterName;
                                        tempModel.observedValue = antibiotic.observedValue;
                                        tempModel.labAntibioticsId = antibiotic.labAntibioticsId;
                                        tempModel.labParameterObservedValueId = antibiotic.labParameterObservedValueId;
                                        tempModel.labComponentHeaderId = component.labComponentHeaderId;
                                        tempModel.selected = antibiotic.selected;
                                        if (UtilHelper.isEmpty(antibiotic.observedValue)) {
                                            if (UtilHelper.isEmpty(antibiotic.observedValue.trim())) {
                                                this.parameterInput.push(tempModel);
                                            }
                                        }
                                    });
                                }
                            });
                        });
                        param = 0;
                        var abnormalIndex = template.parameters.findIndex(param => param.showText == "H" || param.showText == "L");
                        if (abnormalIndex > -1) {
                            this.isAbnormal = true;
                        } else {
                            this.isAbnormal = false;
                        }
                        template.parameters.forEach((parameter: LabParameterInputHeaderModel) => {
                            let model = new TempParameter();
                            model.labComponentHeaderId = 0;
                            model.labTemplateHeaderId = template.labTemplateHeaderId;
                            model.templateName = template.templateName;
                            model.labParameterHeaderId = parameter.labParameterHeaderId;
                            model.parameterName = parameter.parameterName;
                            model.referenceOutput = parameter.referenceOutput;
                            model.selected = parameter.selected;

                            param = param + 1;
                            if (this.isValidation && parameter.validation) {
                                if (parameter.type === "Regular") {
                                    if (this.demographData[0].status != this.demographData[0].subStatus && sampleNum > 0 && sampleNum >= param) {
                                        if (!UtilHelper.isEmpty(parameter.observedValue)) {
                                            this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> Direct Parameter <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                            throw BreakError;
                                        }
                                        else if (!UtilHelper.isEmpty(parameter.observedValue.trim())) {
                                            this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> Direct Parameter <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                            throw BreakError;
                                        }
                                    }
                                    else if (this.demographData[0].status === this.demographData[0].subStatus) {
                                        if (!UtilHelper.isEmpty(parameter.observedValue)) {
                                            this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> Direct Parameter <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                            throw BreakError;
                                        }
                                        else if (!UtilHelper.isEmpty(parameter.observedValue.trim())) {
                                            this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> Direct Parameter <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                            throw BreakError;
                                        }
                                    }

                                }
                            }

                            if (parameter.type === "Regular") {
                                if (this.isValidation && parameter.validation) {
                                    if (!UtilHelper.isEmpty(parameter.observedValue)) {
                                        this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> Direct Parameter <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                        throw BreakError;
                                    }
                                    else if (!UtilHelper.isEmpty(parameter.observedValue.trim())) {
                                        this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> Direct Parameter <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                        throw BreakError;
                                    }
                                }
                                model.observedValue = parameter.observedValue;
                                model.labParameterObservedValueId = parameter.labParameterObservedValueId;
                                model.labParameterDetailId = parameter.labParameterDetailId;
                                model.selected = parameter.selected;
                                this.parameterInput.push(model);
                            }
                            else {
                                parameter?.antibiotics?.forEach((antibiotic: LabParameterInputHeaderModel) => {
                                    let tempModel = new TempParameter();
                                    tempModel.labComponentHeaderId = 0;
                                    tempModel.labTemplateHeaderId = template.labTemplateHeaderId;
                                    tempModel.templateName = template.templateName;
                                    tempModel.labParameterHeaderId = parameter.labParameterHeaderId;
                                    tempModel.parameterName = parameter.parameterName;
                                    tempModel.observedValue = antibiotic.observedValue;
                                    tempModel.labAntibioticsId = antibiotic.labAntibioticsId;
                                    tempModel.labParameterObservedValueId = antibiotic.labParameterObservedValueId;
                                    tempModel.selected = antibiotic.selected;
                                    if (UtilHelper.isEmpty(antibiotic.observedValue)) {
                                        if (UtilHelper.isEmpty(antibiotic.observedValue.trim())) {
                                            this.parameterInput.push(tempModel);
                                        }
                                    }
                                });
                            }
                        });

                        const templateToPush = {
                            labTemplateObservedValueId: template.labTemplateObservedValueId || 0,
                            newLabBookingDetailId: parameters.newLabBookingDetailId,
                            labTemplateHeaderId: template.labTemplateHeaderId,
                            isMethod: template.isMethod,
                            methodText: template.methodText,
                            isInterpretation: template.isInterpretation,
                            interpretationText: template.interpretationText,
                            labMainDetailId: parameters.labMainDetailId
                        };
                        if (template.isInterpretation || template.isMethod) {
                            this.templateInput.push(templateToPush);
                        }
                    });
                    this.submitting = true;
                    if (this.parameterInput.length > 0) {
                        const parameterRequest = {
                            newLabBookingDetailId: parameters.newLabBookingDetailId,
                            parameters: this.parameterInput,
                            createdBy: this.page.userAccount.accountId,
                            locationId: this.page.userAccount.locationId,
                            isAbnormal: this.isAbnormal
                        }
                        parameterRequestList.push(parameterRequest)
                    }
                    if (this.templateInput.length > 0) {
                        const templateRequest = {
                            newLabBookingDetailId: parameters.newLabBookingDetailId,
                            templates: this.templateInput,
                            createdBy: this.page.userAccount.accountId,
                            locationId: this.page.userAccount.locationId
                        }
                        templatesList.push(templateRequest)
                    }
                    
                
            })  
            const parameterRequest = {
                InputObservedValueModels: parameterRequestList
            }
            const templateRequest = {
                TemplateInputModels: templatesList
            }
            let parameterApi = this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.addschemalabparameterinput), parameterRequest);
            requestApi.push(parameterApi);
            let templateApi = this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.addschemalabtemplateinput), templateRequest);
            requestApi.push(templateApi);
            forkJoin([...requestApi])
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => this.submitting = false))
                .subscribe((response: Array<any>) => {
                    if (response[0] as number > 0 && response[1] as number > 0) {
                        this.notifyService.successToast("Labs Parameters and Templates added successfully.");
                    }
                    else if (response[0] as number > 0) {
                        this.notifyService.successToast("Labs Parameters modified successfully.");
                    }
                    else if (response[1] as number > 0) {
                        this.notifyService.successToast("Labs Templates modified successfully.");
                    }
                    if (!this.isVerify) {
                        this.onCloseModel();
                    } else {
                        if (!isFromDoctor) {
                            this.onVerifyByTechnician();
                        }
                    }
                }, () => {
                    this.notifyService.defaultErrorToast();
                });
            if (this.page.userAccount.roleId == 3) {
                this.onDoctorVerified();
            }
        } catch (err) {
            if (err !== BreakError) throw err;
        }

    }



    onOpenForParameterSelection(content: TemplateRef<any>, data: LabParameterInputHeaderModel) {
        this.selectedParameter = data;
        this.onOpenModel(content, "xl");
    }

    onSelectParameter(selectedValue: LabParameterGroup) {
        this.selectedParameter.labParameterDetailId = selectedValue.labParameterDetailId;
        this.selectedParameter.selected = selectedValue;
        this.onCloseModal();
        this.showButton = false;
        this.changeTextColor(this.selectedParameter);
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.onFetchInputParameters();
                    this.onFetchDemographData();
                    this.fetchLabBookingDetail();
                    this.fetchNewLabBookingDetail();
                    this.findAntiBioticTagMaster();
                    this.fetchLabBookingDetail()
                    this.FetchInputParameters();
                    this.findLabAntibiotic();
                    this.fetchAntibioticResultTypes();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    onOpenModel(content: TemplateRef<any>, size: string) {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: size,
            windowClass: "custom-modal effect-scale"
        });
    }

    onCloseModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
            this.totalObservedValues2 = null;
            this.totalObservedValues = null;

        } catch (e) {
            // ignored;
        }
        this.isVerify = false;
    }

    ngOnDestroy() {
        this.onCloseExtraPopup();
        this.onCloseModal();
        this.onCloseModel();
        this.page.unsubscribeAll();
    }

    onViewReport(content: TemplateRef<any>) {
        this.reports = new LabParameterInputModel();
        this.reports = UtilHelper.clone(this.parameters);
        this.reports.templates = new Array<LabTemplateHeaderForInput>();
        this.parameters.templates.forEach((template: LabTemplateHeaderForInput) => {
            let parameterList = new Array<LabParameterInputHeaderModel>();
            template.components.forEach((component: LabComponentHeaderForInput) => {
                component.parameters.forEach((param: LabParameterInputHeaderModel) => {
                    parameterList.push(param);
                });
            });
            template.parameters.forEach((param: LabParameterInputHeaderModel) => {
                parameterList.push(param);
            });
            let templateToAssign = UtilHelper.clone(template) as LabTemplateHeaderForInput;

            templateToAssign.components = new Array<LabComponentHeaderForInput>();
            templateToAssign.parameters = new Array<LabParameterInputHeaderModel>();
            LinqHelper.sort(parameterList, "displayOrder");
            templateToAssign.parameters = parameterList;
            this.reports.templates.push(templateToAssign);
        });

        this.onOpenModel(content, "xl");
        this.currentDate = new Date();
        this.loadingDoctorSignatures = true;
        this.httpService.get(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchDoctorSignature), { encryptedNewLabBookingDetailId: this.encryptedNewLabBookingDetailId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingDoctorSignatures = false))
            .subscribe((response: Array<LabReportSignature>) => {
                response.forEach(x => {
                    x["newSignature"] = x.signature ? this.safe(x.signature) : null;
                })
                this.reportSignatures = response;
            }, () => {
                this.notifyService.warningToast("Unable to Doctor signature.");
            });

    }
    fetchProviders() {
        this.loadingProviders = true;
        this.resourceService.pathologyProviders()
            .pipe(finalize(() => { this.loadingProviders = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.providers = response;
            });
    }
    selectDoctor(content: TemplateRef<any>) {
        this.onOpenModel(content, "xl");
        this.fetchProviders();
        this.buildProviderAssigningForm();
        if (this.demographData[0].status != "Verified") {
            this.doctorDropDown = true;
        }
        else {
            this.doctorDropDown = false;
        }
        setTimeout(() => {
            this.callwhenAfterProvidersLoaded();
        }, 1000)
    }
    callwhenAfterProvidersLoaded(newLabBookingDetailId?: number) {
        if (newLabBookingDetailId) {
            this.newLabBookingDetailId = newLabBookingDetailId;
        }
        this.provideredit = true;
        this.httpService.get(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchDoctorsAssigned), { newLabBookingDetailId: this.newLabBookingDetailId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingDoctorSignatures = false))
            .subscribe((response: any) => {
                this.providerAssigned = response;
                for (let i = 0; i < response.length; i++) {
                    var result = this.providers.filter(item => item.id != response[i].approvedBy);
                    this.providers = result;
                }
            });
    }
    private buildProviderAssigningForm() {
        this.providerAssigningForm = this.formBuilder.group({
            approvedBy: [null, [Validators.required]],
            departmentId: [null],
            specializationId: [null],
            providerLocationId: [null],
            locationIds: [null],
            labReportVerificationId: 0
        })
    }

    private buildTechForm(id: number) {
        this.techForm = this.formBuilder.group({
            technicianId: this.page.userAccount.accountId,
            newLabBookingDetailId: id,
            technicianComment: [null, [Validators.required]]
        })
    }

    get forms() {
        return {
            providerSchedule: this.providerAssigningForm ? this.providerAssigningForm.controls : {},
            tech: this.techForm ? this.techForm.controls : {},
        }
    }
    customSearch(term: string, item) {
        term = term.toLocaleLowerCase();
        return item['value'].toLocaleLowerCase().indexOf(term) > -1 || item['optionalText'].toLocaleLowerCase().indexOf(term) > -1;
    }
    fetchDoctorSpecializationHelper($event: any) {
        if ($event && $event.term && $event.term.length >= 2) {
            let locationId = this.providerAssigningForm.get("locationIds").value
            this.fetchDoctorSpecialization($event.term, locationId);
        } else {
            this.providerSpecialization = new Array<ProviderSpecialization>();
        }
    }
    private fetchDoctorSpecialization(providerId?: string, locationId?: number) {
        this.loading = true;
        var request = {
            filter: providerId,
            locationId: locationId
        }

        this.httpService
            .post<Array<ProviderSpecialization>>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.fetchDoctorSpecialization), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<ProviderSpecialization>) => {
                    this.providerSpecialization = response;
                },
                () => {
                    this.providerSpecialization = new Array<ProviderSpecialization>();
                }
            );
    }
    onChangeProvider(event: any) {

        if (event.length >= 1) {
            let result = event.map(a => a.id);
            this.providerAssigningForm.get("approvedBy").setValue(result);
        }
        else {
            this.notifyService.warningToast("Please select provider");
        }
    }
    assignDoctors() {
        if (!this.providerAssigningForm.valid) {
            this.notifyService.warningToast("Please Select Doctor");
            return;
        }
        this.loadingproviderAssigning = true;
        const request = this.providerAssigningForm.getRawValue();
        request["newLabBookingDetailId"] = this.newLabBookingDetailId;
        request["Active"] = false;
        request["createdBy"] = this.page.userAccount.accountId;
        request["locationId"] = this.page.userAccount.locationId;
        this.httpService
            .post(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.assignToDoctor), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingproviderAssigning = false))
            .subscribe(
                (response: number) => {
                    if (response >= 0) {
                        this.doctorAssigned = true;
                        this.notifyService.successToast("Assigned to respective Doctor's");
                        this.onCloseModal();
                        this.onFetchDemographData();
                    }
                    else {
                        this.notifyService.warningToast("There is an error in Assigning to doctor");
                    }
                },
                () => {
                }
            );

    }

    onRemove(event: any) {
        if (this.demographData[0].status == "Verified" || this.demographData[0].status == "DoctorAssigned" || this.demographData[0].status == "DoctorVerified") {
            this.providers.push(event.value.id);
        }
    }

    onDeleteAssignedDoctor(provider: LabReportSignature) {
        const request = {
            labReportVerificationId: provider.labReportVerificationId,
            createdBy: this.page.userAccount.accountId,
            status: this.demographData[0].status,
            testName: this.demographData[0].testName,
            newLabBookingDetailId: this.newLabBookingDetailId
        };
        if (!request.labReportVerificationId || !request.createdBy || !request.status || !request.testName || !request.newLabBookingDetailId) {
            return this.notifyService.warningToast("Please Select Provider to delete The Assignment of that Lab Report");
        }
        this.httpService
            .post(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.removeDoctor), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingproviderAssigning = false))
            .subscribe(
                (response: number) => {
                    if (response >= 0) {
                        this.notifyService.successToast("Removed Doctor Assignment for this Lab Report Succesfully");
                        this.onCloseModal();
                        this.onFetchDemographData();
                    }
                    else {
                        this.notifyService.warningToast("There is an error in removing the doctor");
                    }
                },
                () => {
                }
            );

    }
    viewPreviousResultsAdded(content: TemplateRef<any>) {
        var start = 0;
        var arr2 = [];

        this.loadingObservedValues = true;
        const request = {
            newLabBookingDetailId: this.newLabBookingDetailId
        };
        this.httpService
            .post(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchallobservedvalues), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingObservedValues = false))
            .subscribe(
                (response: Array<LabObservedValueModel>) => {
                    this.totalObservedValues = response;
                    const distinctThings = this.totalObservedValues.filter(
                        (x, i, arr) => arr.findIndex(t => t.parameterName === x.parameterName) === i
                    );
                    this.parameterNames = distinctThings;
                    var end = this.totalObservedValues[0].count;
                    var count = this.totalObservedValues[0].count;
                    for (let i = 0; i < this.totalObservedValues.length / count; i++) {
                        arr2.push(this.totalObservedValues.slice(start, end))
                        start = end;
                        end = end + count;
                    }
                    this.totalObservedValues2 = arr2;
                },
                () => {
                    this.totalObservedValues = new Array<LabObservedValueModel>();
                    this.loadingObservedValues = false
                }
            );
        this.onOpenModel(content, "xl");
    }
    onViewReportNew(content: TemplateRef<any>) {
        this.showLabReport = true;
        this.isPrintLogo = true;
        this.onOpenModel(content, "xl");
        this.currentDate = new Date();
    }
    onViewDocument1(content: TemplateRef<any>) {
        this.showLabDocument = true;
        this.onOpenModel(content, "xl");
        this.currentDate = new Date();
    }
    onCloseExtraPopup() {
        this.showLabReport = false;
        this.showLabDocument = true;
        try {
            this.modalRef.close();
            this.modalRef = undefined;
            this.totalObservedValues2 = null;
            this.totalObservedValues = null;

        } catch (e) {
            // ignored;
        }
    }
    onOpenUploadFile(content: TemplateRef<any>) {
        this.onOpenModel(content, "xl");
        this.onResetFileUploader();
    }
    onResetFileUploader() {
        this.loadingFile = false;
        this.documentError = false;
        this.fileName = null;
        this.formedUrl = null;
        this.uploadedFile = null;
        this.fileUploadFail = false;
    }

    onUploadReport() {
        const request = {
            ...this.selectedLabBookingDetail,
            uploadedBy: this.page.userAccount.accountId
        };
        request["roleId"] = this.page.userAccount.roleId;
        request["createdByName"] = this.page.userAccount.fullName;
        request["requisitionNumber"] = this.demographData[0].requisitionNumber;
        request["locationId"] = this.page.userAccount.locationId;
        const formData = UtilHelper.prepareFormData(UtilHelper.clone(request));
        formData.append(`File${1}`, this.uploadedFile, this.uploadedFile.name);
        this.isUploading = true;
        this.httpService.postFile(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.uploadLabData), formData)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.isUploading = false;
                this.onCloseModal();
            }))
            .subscribe((res: string) => {
                this.notifyService.successToast(res);
                this.fetchLabBookingDetail();
                this.onResetFileUploader();
            }, (error: HttpErrorResponse) => {
                const errorMessage = UtilHelper.handleError(error);
                if (errorMessage) {
                    this.notifyService.warningToast(errorMessage);
                } else {
                    this.notifyService.defaultError();
                }
            });
    }

    private fetchNewLabBookingDetail() {
        this.loadingTimeline = true;
        this.httpService.get(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchNewLabBookingDetail), { encryptedNewLabBookingDetailId: this.encryptedNewLabBookingDetailId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingTimeline = false))
            .subscribe((response: LabBillDetailModel) => {
                this.selectedLabBookingDetail = response;
            }, () => {
                this.notifyService.warningToast("Unable to fetch lab Booking detail.");
            });
    }
    fetchLabBookingDetail() {
        const request = {
            encryptedNewLabBookingDetailId: this.encryptedNewLabBookingDetailId,
            ... this.selectedLabBookingDetail
        };
        this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchLabBookingDetail), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: LabBillDetailModel) => {
                    this.records = response;
                    this.documentUrl = this.records.reportUrl;
                    this.isExternalDocument = false;
                    this.checkExternalDocument();
                });
    }
    private checkExternalDocument() {
        if (this.records && UtilHelper.isEmpty(this.documentUrl)) {
            this.loading = true;
            this.isExternalDocument = true;
            this.httpService
                .post(ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.downloadFile), { documentUrl: this.documentUrl })
                .pipe(takeUntil(this.page.unSubscribe))
                .subscribe(
                    (response: any) => {
                        this.reportData = this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64, ${response.base64}`);
                        this.reportDataWithoutAuth = `data:application/pdf;base64, ${response.base64}`;


                    }
                );
        }
    }
    onFileUpload(event) {
        this.loadingFile = true;
        this.uploadedFile = event.target.files[0];
        this.fileUploadFail = false;
        this.fileName = this.uploadedFile.name;
        let extension = this.uploadedFile.name.split(".").pop();
        if (extension === "pdf") {
            this.fileChangeEvent();
        }
        else {
            this.loadingFile = false;
            this.uploadedFile = null;
            this.fileUploadFail = true;
        }
    }

    fileChangeEvent() {
        function getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }


        getBase64(this.uploadedFile).then((data: any) => {
            this.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data);
            this.loadingFile = false;
        }, () => {
            this.uploadedFile = null;
            this.fileUploadFail = true;
            this.loadingFile = false;
            this.formedUrl = null;
        });
    }

    onProgress(progressData: ProgressData) {
        if (progressData.loaded === progressData.total) {
            this.loadingFile = false;
        }
    }
    onProgressPfd(progressData: ProgressData) {
        this.isExternalLoading = true;
        if (progressData.loaded === progressData.total) {
            this.isExternalLoading = false;
        }

    }
    onDocumentError() {
        this.loadingFile = false;
        this.documentError = true;
    }

    onOpenTechForm(content: TemplateRef<any>) {
        this.showLabReport = true;
        const BreakError = {};
        try {
            const templates = this.parameters.templates;
            this.parameterInput = new Array<TempParameter>();
            this.templateInput = new Array<TemplateInput>();
            templates.forEach((template) => {
                if (template.isMethod) {
                    if (UtilHelper.isEmpty(template.methodText) && UtilHelper.isEmpty(template.methodText.trim())) {
                        // ignore
                    } else {
                        this.notifyService.warning(`Please enter method text for template ${template.templateName}`);
                        throw BreakError;
                    }
                }


                template.components.forEach((component: LabComponentHeaderForInput) => {
                    var abnormalIndex = component.parameters.findIndex(param => param.showText == "H" || param.showText == "L");
                    if (abnormalIndex > -1) {
                        this.isAbnormal = true;
                    } else {
                        this.isAbnormal = false;
                    }
                    component.parameters.forEach((parameter: LabParameterInputHeaderModel) => {
                        if (this.isValidation && parameter.validation) {
                            if (parameter.type === "Regular") {
                                if (!UtilHelper.isEmpty(parameter.observedValue)) {
                                    this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> ${component.componentName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                    throw BreakError;
                                }
                                else if (!UtilHelper.isEmpty(parameter.observedValue.trim())) {
                                    this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> ${component.componentName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                    throw BreakError;
                                }
                            }
                        }


                    });
                });
                var abnormalIndex = template.parameters.findIndex(param => param.showText == "H" || param.showText == "L");
                if (abnormalIndex > -1) {
                    this.isAbnormal = true;
                } else {
                    this.isAbnormal = false;
                }
                template.parameters.forEach((parameter: LabParameterInputHeaderModel) => {
                    if (this.isValidation && parameter.validation) {
                        if (parameter.type === "Regular") {
                            if (!UtilHelper.isEmpty(parameter.observedValue)) {
                                this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> Direct Parameter <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                throw BreakError;
                            }
                            else if (!UtilHelper.isEmpty(parameter.observedValue.trim())) {
                                this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> Direct Parameter <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                throw BreakError;
                            }
                        }
                    }

                });


            });
        }
        catch (err) {
            if (err !== BreakError) throw err; return;
        }


        if (this.demographData[0].assignDoctorRequired && !this.doctorAssigned && this.providerAssigned.length == 0) {
            this.notifyService.info("For this Test Assign Doctor is required", () => {

            });
        }
        else {
            this.buildTechForm(this.selectedLabBookingDetail.newLabBookingDetailId);
            this.onOpenModel(content, "lg");
        }


    }

    onVerifyByTechnician() {
        this.submitted = true;
        var data = new Array<any>;
        this.parametersAll?.forEach(parameter => {
            var req = this.techForm.getRawValue();
            req.newLabBookingDetailId = parameter.newLabBookingDetailId;
            req["roleId"] = this.page.userAccount.roleId;
            req["createdByName"] = this.page.userAccount.fullName;
            req["testName"] = this.demographData[0].testName;
            req["requisitionNumber"] = this.demographData[0].requisitionNumber;
            req["locationId"] = this.page.userAccount.locationId;
            data.push(req)
        })
        const request = {
            labTechnicianVerificationModels: data
        }
        this.submitting = true;
        this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.addschematechnicianverification), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.submitted = this.submitting = false))
            .subscribe((response: number) => {
                if (response > 0) {
                    this.notifyService.successToast("Lab technician verified successfully.");
                    this.isVerify = false;
                    this.onCloseModal();
                    this.onCloseModel();
                    this.fetchNewLabBookingDetail();
                }
            }, () => {
                this.notifyService.warningToast("Unable to verify.");
            });

    }

    onSubmitVerify() {
        this.isVerify = true;
        if (this.techForm.invalid) {
            return this.notifyService.warningToast("Please Enter Comment");
        }
        if (this.showButton) {
            this.onVerifyByTechnician();
        } else {
            this.onSubmit();
        }
    }
    changeTextColor(param: LabParameterInputHeaderModel) {
        var parameterValue = 1;
        if (param.observedValue == "" || !param.observedValue) {
            param.showText = null;
            param.isBold = false;
            return;
        }
        let observedValue;
        if (param.observedValue && (param.observedValue[0] == ">" || param.observedValue[0] == "<") && param.observedValue.length > 1) {
            var value = param.observedValue[0];
            observedValue = +param.observedValue.substring(1)
            if (value == ">") {
                observedValue = observedValue + 1;
            } else if (value == "<") {
                observedValue = observedValue - 1;
            }
        } else {
            if (param.observedValue?.includes("^")) {
                if (param.observedValue?.indexOf("^") == param.observedValue?.lastIndexOf("^")) {
                    var values = param.observedValue?.split('^');
                    if (values?.length > 1) {
                        parameterValue = Number(pow(Number(values[0]), Number(values[1])))
                    }
                } else {
                    param.observedValue = null;
                }


            }
            observedValue = param.observedValue ? +param.observedValue : null;
        }
        let range = param.selected;
        if (range != null) {
            let exactRange = range;
            param.showText = null;
            param.isBold = false;
            if ((observedValue || parameterValue != 1) && (exactRange.minValue || exactRange.maxValue)) {
                if (exactRange.minValue > observedValue || (parameterValue == 1 ? false : exactRange.minValue > parameterValue)) {
                    param.showText = "L";
                    param.isBold = true;
                }
                else if (observedValue > +exactRange.maxValue || (parameterValue == 1 ? false : parameterValue > +exactRange.maxValue)) {
                    param.showText = "H";
                    param.isBold = true;
                }
            }
        }
    }
    onPrintPdf() {
        let printIframe;
        let blobUrls = [];
        let data = this.reportDataWithoutAuth;

        async function printMethod() {
            const resp = await fetch(data);
            const blob = await resp.blob();
            const url = URL.createObjectURL(blob);
            blobUrls.push(url);
            if (!printIframe) {
                printIframe = document.createElement('iframe');
                printIframe.setAttribute("id", "myDOC");
                document.body.appendChild(printIframe);

                printIframe.style.position = 'absolute';
                printIframe.style.top = '0';
                printIframe.style.left = '-1000px';

                printIframe.onload = () => {
                    setTimeout(() => {
                        if (printIframe) {
                            printIframe.focus();
                            printIframe.contentWindow.print();
                        }
                    }, 100)
                }
            }
            printIframe.src = url;
        }

        printMethod();
    }


    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }
    private inactiveDocument() {
        const request = {
            newLabBookingDetailId: this.newLabBookingDetailId,
            reportUrl: this.documentUrl,

        };
        this.notifyService.confirm("Do you really want to delete this Document? This process can not be undone.", () => {
            this.httpService
                .post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.inactiveLabDocument), request)

                .pipe(takeUntil(this.page.unSubscribe))
                .subscribe({
                    next: () => {
                        this.notifyService.successToast("The document got deleted sucessfully");
                        this.fetchLabBookingDetail();
                        this.onCloseModal();
                    },
                    error: (error: HttpErrorResponse) => {
                        this.errorMessage = UtilHelper.handleError(error);
                        this.notifyService.warning(this.errorMessage);
                    }
                });
        });
    }
    onDeleteDocument() {
        this.inactiveDocument();
    }
    private FetchInputParameters() {
        const request = {
            encryptedNewLabBookingDetailId: this.encryptedNewLabBookingDetailId,
            fromTech: "tech",
            schemaReport: true
        };
        this.loading = true;
        this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.labparameterforinputschema), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<LabParameterInputModel>) => {
                if (response) {
                    this.parametersAllDummy = response;
                    this.reportsAllDummy = [];
                    this.parametersAllDummy.forEach((singleTest, index) => {
                        singleTest.templates.forEach((template: LabTemplateHeaderForInput) => {
                            let parameterList = new Array<LabParameterInputHeaderModel>();
                            template.components.forEach((component: LabComponentHeaderForInput) => {
                                component.isShow = false;
                                component.parameters.forEach((param: LabParameterInputHeaderModel) => {
                                    var parameterValue = 1;
                                    let observedValue;
                                    if (param.observedValue) {
                                        component.isShow = true;
                                    }
                                    if (param.observedValue && (param.observedValue[0] == ">" || param.observedValue[0] == "<") && param.observedValue?.length > 1) {
                                        component.isShow = true;
                                        var value = param.observedValue[0];
                                        observedValue = +param.observedValue.substring(1)
                                        if (value == ">") {
                                            observedValue = observedValue + 1;
                                        } else if (value == "<") {
                                            observedValue = observedValue - 1;
                                        }
                                    } else {
                                        if (param.observedValue?.includes("^")) {
                                            var values = param.observedValue?.split('^');
                                            if (values?.length > 1) {
                                                param.observedValueList = new Array<string>;
                                                param.isObservedValuePower = true;
                                                param.observedValueList.push(values[0]);
                                                param.observedValueList.push(values[1]);
                                                parameterValue = Number(pow(Number(values[0]), Number(values[1])));
                                            }
                                        }
                                        observedValue = +param.observedValue;
                                    }
                                    let range = param.selected;
                                    if (range != null) {
                                        let exactRange = range;
                                        if (exactRange.minValue || exactRange.maxValue) {
                                            if (exactRange.minValue > observedValue || (parameterValue == 1 ? false : exactRange.minValue > parameterValue)) {
                                                param.showText = "L";
                                                param.isBold = true;
                                            }
                                            else if (observedValue > +exactRange.maxValue || (parameterValue == 1 ? false : parameterValue > +exactRange.maxValue)) {
                                                param.showText = "H";
                                                param.isBold = true;
                                            }
                                        }
                                    }
                                    if (param.type == "Microbiology" && param.antibiotics.length > 0) {
                                        var isNoValue = param.antibiotics.every(x => x.observedValue == null);
                                        if (isNoValue) {
                                            param.antibiotics = [];
                                        } else {
                                            var resultAddedAntibiotics = param.antibiotics.filter(x => x.observedValue != null);
                                            if (resultAddedAntibiotics.length > 0) {
                                                param.antibiotics = resultAddedAntibiotics;
                                                this.isHaveParameters = true;
                                                parameterList.push(param);
                                            } else {
                                                parameterList.push(param);
                                            }
                                        }
                                    }
                                });
                            });
                            template.parameters.forEach((param: LabParameterInputHeaderModel) => {
                                var parameterValue = 1;
                                let observedValue;
                                if (param.observedValue && (param.observedValue[0] == ">" || param.observedValue[0] == "<") && param.observedValue?.length > 1) {
                                    var value = param.observedValue[0];
                                    observedValue = +param.observedValue.substring(1)
                                    if (value == ">") {
                                        observedValue = observedValue + 1;
                                    } else if (value == "<") {
                                        observedValue = observedValue - 1;
                                    }
                                } else {
                                    if (param.observedValue?.includes("^")) {
                                        var values = param.observedValue?.split('^');
                                        if (values?.length > 1) {
                                            param.observedValueList = new Array<string>;
                                            param.isObservedValuePower = true;
                                            param.observedValueList.push(values[0]);
                                            param.observedValueList.push(values[1]);
                                            parameterValue = Number(pow(Number(values[0]), Number(values[1])));
                                        }
                                    }
                                    observedValue = +param.observedValue;
                                }
                                let range = param.selected;
                                if (range != null) {
                                    let exactRange = range;
                                    if (exactRange.minValue || exactRange.maxValue) {
                                        if (exactRange.minValue > observedValue || (parameterValue == 1 ? false : exactRange.minValue > parameterValue)) {
                                            param.showText = "L";
                                            param.isBold = true;
                                        }
                                        else if (observedValue > +exactRange.maxValue || (parameterValue == 1 ? false : parameterValue > +exactRange.maxValue)) {
                                            param.showText = "H";
                                            param.isBold = true;
                                        }
                                    }
                                }
                                if (param.type == "Microbiology" && param.antibiotics.length > 0) {
                                    var isNoValue = param.antibiotics.every(x => x.observedValue == null);
                                    if (isNoValue) {
                                        param.antibiotics = [];
                                    } else {
                                        var resultAddedAntibiotics = param.antibiotics.filter(x => x.observedValue != null);
                                        if (resultAddedAntibiotics.length > 0) {
                                            param.antibiotics = resultAddedAntibiotics;
                                            this.isHaveParameters = true;
                                            parameterList.push(param);
                                        } else {
                                            parameterList.push(param);
                                        }
                                    }
                                }
                            });
                        });

                    });
                    this.reportsAllDummy = this.parametersAllDummy;
                    this.parametersAllDummy.forEach((test, index) => {
                        this.fetchTemplateDetail(test?.templates[0]?.labTemplateHeaderId, index, true)
                    });

                }
            });
    }
    onChange() {
        this.showButton = true;
        this.parametersAll?.forEach((parameters ,paraIndex) => {
                parameters.templates.forEach((item) => {
                    item.parameters.forEach((param) => {
                        this.parametersAllDummy?.forEach((parametersDummy) => {
                            parametersDummy.templates.forEach((m) => {
                                var index = m.parameters.findIndex(n => n.labParameterHeaderId == param.labParameterHeaderId && n.observedValue != param.observedValue && (n.observedValue ? true : (param.observedValue != "")));
                                m.parameters?.forEach((y) => {
                                    param.antibiotics?.forEach((antibio) => {
                                        var subindex1 = y.antibiotics?.findIndex(bio => bio.labAntibioticsId == antibio.labAntibioticsId && bio.labParameterHeaderId == antibio.labParameterHeaderId);
                                        if (subindex1 == -1) {
                                            this.showButton = false;
                                            return;
                                        }
                                    })
                                    if (!y.antibiotics && param.antibiotics) {
                                        this.showButton = false;
                                        return;
                                    }
                                    y.antibiotics?.forEach((anti) => {
                                        var subIndex = param.antibiotics?.findIndex(x => x.observedValue != anti.observedValue && x.labParameterHeaderId == anti.labParameterHeaderId && x.labAntibioticsId == anti.labAntibioticsId);
                                        if (subIndex > -1) {
                                            this.showButton = false;
                                            return;
                                        }
                                    })
                                })
                                if (index > -1) {
                                    this.showButton = false;
                                    return;
                                }
                            })
                        })
                })
                    item.components.forEach((component) => {
                        this.parametersAllDummy?.forEach((parametersDummy) => {
                            parametersDummy.templates.forEach((m) => {
                                m.components.forEach((data) => {
                                    if (data.componentName == component.componentName) {
                                        data.parameters.forEach((params, i) => {
                                            if (params.antibiotics?.length != component.parameters[i].antibiotics?.length) {
                                                this.showButton = false;
                                                return;
                                            }
                                            var index = component.parameters.findIndex(n => n.labParameterHeaderId == params.labParameterHeaderId && n.observedValue != params.observedValue && (params.observedValue ? true : (n.observedValue != "")))
                                            if (!params.antibiotics || params.antibiotics?.length == 0) {
                                                component?.parameters?.forEach((param, j) => {
                                                    if (param.antibiotics?.length > 0 && i == j) {
                                                        this.showButton = false;
                                                    }
                                                })
                                            }
                                            params.antibiotics?.forEach((anti) => {
                                                component?.parameters?.forEach((param) => {
                                                    var subIndex = param.antibiotics?.findIndex((x => x.observedValue != anti.observedValue && x.labParameterHeaderId == anti.labParameterHeaderId && x.labAntibioticsId == anti.labAntibioticsId));
                                                    if (subIndex > -1) {
                                                        this.showButton = false;
                                                        return;
                                                    }
                                                    param.antibiotics?.forEach(antibio => {
                                                        var index = params.antibiotics?.findIndex(y => y.labAntibioticsId == antibio.labAntibioticsId && y.labParameterHeaderId == antibio.labParameterHeaderId);
                                                        if (index == -1) {
                                                            this.showButton = false;
                                                            return;
                                                        }
                                                    })
                                                })
                                            })
                                            if (index > -1) {
                                                this.showButton = false;
                                                return;
                                            }
                                        })
                                    }
                                })
                            })
                        })
                    })
                    var index = this.parametersAllDummy[paraIndex].templates.findIndex(Y => Y.methodText != item.methodText || Y.interpretationText != item.interpretationText);
                    if (index > -1) {
                        this.showButton = false;
                        return;
                    }
            });
        });
        
        this.oncheckFormulaResults();
    }

    private buildForm(document?: LabDocumentsDetails) {
        this.uploadForm = this.formBuilder.group({
            documentsDetailId: [document && document.documentDetailsId ? document.documentDetailsId : 0, [Validators.required]],
            documentName: [document && document.documentName ? document.documentName : "", [Validators.required]],
            description: [document && document.description ? document.description : null]
        });
    }
    onCloseModalViewDocument() {
        if (this.modalRefViewDocument) {
            this.modalRefViewDocument.close();
            this.modalRefViewDocument = undefined;
        }
    }
    private fetchDocuments() {
        this.loading = true;
        const request = Object.assign(UtilHelper.clone(this.pagination));
        this.patientId = this.demographData[0]?.patientId;
        request["PatientId"] = this.demographData[0].patientId;
        request["encryptedPatientId"] = this.encryptedPatientId ? this.encryptedPatientId : null;
        request["labMainDetailId"] = this.demographData[0].labMainDetailId;
        this.httpService.post(ApiResources.getURI(ApiResources.labDocumentDetails.base, ApiResources.labDocumentDetails.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false }))
            .subscribe((response: Array<LabDocumentsDetails>) => {
                this.documents = response.filter(doc =>
                    doc.patientId === this.demographData[0].patientId &&
                    doc.newLabBookingDetailId === this.newLabBookingDetailId &&
                    doc.labMainDetailId === this.demographData[0].labMainDetailId
                );
                if (this.documents.length == 0) {
                    this.showReport = false;
                } else {
                    this.showReport = true;
                    this.patientId = this.documents[0]?.patientId;
                }
            });
    }
    onViewMultiplDocument(content: TemplateRef<any>) {
        this.onOpenLabMultipleReportModelForDoc(content, "xl")
    }
    onOpenLabMultipleReportModelForDoc(content: TemplateRef<any>, size: string) {
        this.modalRefViewDocument = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: size,
            windowClass: "custom-modal effect-scale"
        });
    }
    onUploadDocument() {
        this.submitted = true;
        if (!this.uploadForm.valid || this.warningMessage) {
            this.notifyService.info("Please Enter the document name ");
            return;
        }
        this.errorMessage = undefined;
        this.submitting = true;
        const model = UtilHelper.clone(this.uploadForm.value);

        if (model) {
            const formData = new FormData();

            formData.append("PatientId", this.demographData[0].patientId.toString());
            formData.append("UploadedBy", this.page.userAccount.accountId.toString());
            formData.append("DocumentName", model["documentName"]);
            formData.append("NewLabBookingDetailId", this.demographData[0].newLabBookingDetailId.toString());
            formData.append("Description", model["description"]);
            formData.append("LabMainDetailId", this.demographData[0].labMainDetailId.toString());

            const files = this.files;

            if (!files || !files.length) {
                this.submitting = false;
                this.notifyService.info("Please select at least one file");
                return;
            }

            const n = files.length;
            if (n > this.maxFiles) {
                files.splice(this.maxFiles, files.length);
            }

            files.forEach((file: File, index: number) => {
                formData.append(`File${index + 1}`, file, file.name);
            });

            this.httpService
                .postFile(ApiResources.getURI(ApiResources.labDocumentDetails.base, ApiResources.labDocumentDetails.upload), formData)
                .pipe(finalize(() => { this.submitted = false; this.submitting = false; }))
                .pipe(takeUntil(this.page.unSubscribe))
                .subscribe(
                    () => {
                        this.files = [];
                        this.notifyService.successToast("Document uploaded successfully.");
                        this.onCloseModal();
                        this.onCloseModalDocument();
                        this.onMutipleDocumetCloseModel();
                        this.fetchDocuments();
                    },
                    (error: HttpErrorResponse) => {
                        this.errorMessage = UtilHelper.handleError(error);
                        this.notifyService.warning(this.errorMessage);
                    }
                );
        }
    }
    onMutipleDocumetCloseModel() {
        if (this.modalRefViewDocument) {
            this.closeModelFunction(this.modalRefViewDocument);
        }
    }
    closeModelFunction(model) {
        model.close();
        model = undefined;
    }
    onFileSelect(files: Array<File>) {
        this.files = files;
    }
    onCloseModalDocument() {
        if (this.modalRefDocument) {
            this.modalRefDocument.close();
            this.modalRefDocument = undefined;
        }
    }
    onPrevious() {
        let index = this.documents.findIndex((m => m.documentDetailId === this.document.documentDetailsId) as any);
        index = index - 1;
        this.showPrevious = index !== 0;
        this.showNext = true;
        this.document = undefined;
        this.prepareDocument(this.documents[index]);
    }
    onNext() {
        let index = this.documents.findIndex((m => m.documentDetailsId === this.document.documentDetailsId) as any);
        index = index + 1;
        this.showNext = index !== this.documents.length - 1;
        this.showPrevious = true;
        this.document = undefined;
        this.prepareDocument(this.documents[index]);
    }
    private prepareDocument(document: LabDocumentsDetails) {
        this.loadingDocument = true;
        this.document = document;
        this.documentError = false;
        this.document.isImage = this.document.contentType.indexOf("pdf") < 0 && this.document.contentType.indexOf("video") < 0;
        this.document.isVideo = this.document.contentType.indexOf("video") >= 0;
        if (this.document.isImage || this.document.isVideo) {
            this.loadingDocument = true;
            this.document.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${ApiResources.getURI(ApiResources.labDocumentDetails.base, ApiResources.labDocumentDetails.downloadFileGet)}?id=${this.document.documentDetailsId}&url=${this.document.documentUrl}`);
            if (this.document.formedUrl) {
                this.loadingDocument = true;
            }
        }
        if (!this.document.isImage && !this.document.isVideo) {
            this.httpService
                .post(ApiResources.getURI(ApiResources.labDocumentDetails.base, ApiResources.labDocumentDetails.downloadFile), { ...document })
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => { this.loadingDocument = false; }))
                .subscribe(
                    (response: LabDocumentsDetails) => {
                        this.document.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64, ${response.base64}`);
                    }
                );

        } else {
            this.loadingDocument = false;
        }
    }

    onDocumentLoad() {
        this.loadingDocument = false;
    }
    onOpenModalView(content: TemplateRef<any>, document?: LabDocumentsDetails) {

        this.prepareDocument(document);
        const index = this.documents.findIndex((m => m.documentDetailsId === this.document.documentDetailsId) as any);
        if (index === 0 && this.documents.length === 1) {
            this.showPrevious = false;
            this.showNext = false;
        } else if (index === 0) {
            this.showPrevious = false;
            this.showNext = true;
        } else if (index === this.documents.length - 1) {
            this.showPrevious = true;
            this.showNext = false;
        } else {
            this.showPrevious = true;
            this.showNext = true;
        }
        this.modalRef = this.modalService.open(content, {
            keyboard: false,
            centered: true,
            windowClass: "document-view-modal custom-modal effect-scale"
        });


    }
    onDelete(document: LabDocumentsDetails) {
        const request =
        {
            documentDetailsId: document.documentDetailsId,
            patientId: document.patientId,
            documentUrl: document.documentUrl,
            newLabBookingDetailId: document.newLabBookingDetailId,
            uploadedBy: this.page.userAccount.accountId,
            modifiedBy: this.page.userAccount.accountId,
            loginRoleId: this.page.userAccount.roleId,
            loginAccountId: this.page.userAccount.accountId,
        }
        this.document = document;
        this.modifyingContent = " Deleting the selected document";
        this.notifyService.delete(() => {
            this.modifying = true;
            this.httpService
                .post(ApiResources.getURI(ApiResources.labDocumentDetails.base, ApiResources.labDocumentDetails.delete), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => { this.modifying = false; this.document = undefined }))
                .subscribe(
                    () => {
                        this.notifyService.successToast("Document deleted successfully.");
                        this.fetchDocuments();
                    }
                );
        });
    }

    onCloseModalView() {
        if (this.modalRefView) {
            this.modalRefView.close();
            this.modalRefView = undefined;
        }
    }

    OpenView(content, size) {
        this.modalRefView = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            windowClass: "custom-modal effect-scale",
            size: size
        });
    }

    onViewDocument(content: TemplateRef<any>) {
        this.onOpenLabReportModelForDoc(content, "xl");
    }

    onOpenLabReportModelForDoc(content: TemplateRef<any>, size: string) {
        this.modalRefView = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: size ? size : "lg",
            windowClass: "custom-modal effect-scale"
        });
    }

    onOpenModalMultiple(content: TemplateRef<any>, size?: string, document?: LabDocumentsDetails) {
        if (!content) {
            console.error('Template content is undefined');
            return;
        }

        this.modalRefViewDocument = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            windowClass: "custom-modal effect-scale",
            size: size
        });

        this.buildForm(document);
    }
    removeAntibiotic(parameter: LabParameterInputHeaderModel, index: number) {
        if (parameter == null || parameter == undefined) {
            return this.notifyService.warningToast('unable to remove this Antibiotic');
        }
        if (parameter.labParameterObservedValueId != 0 && parameter.labParameterObservedValueId != null) {
            parameter.antibiotics.splice(index, 1);
            this.notifyService.warningToast('Antibiotic Deleted Succesfully.');
        } else {
            parameter.antibiotics.splice(index, 1);
            this.notifyService.warningToast('Antibiotic Removed Succesfully.');
        }
        this.onChange();
    }
    onClickMicroParameter(data: any, recordIndex :number , index: number, paramIndex: number, componentParamIndex?: number, isFromOrgasms?: boolean) {
        if (isFromOrgasms) {
            if (data) {
                var testIndex = this.orgasmsList?.findIndex(x => x.templateIndex == index && x.parameterIndex == paramIndex && x.componentParamIndex == componentParamIndex);
                if (testIndex > -1) {
                    this.orgasmsList[testIndex].selectedparameterId = data?.antibioticTagMasterId;
                } else {
                    var orgasm = new Orgasms();
                    orgasm.templateIndex = index;
                    orgasm.parameterIndex = paramIndex;
                    orgasm.componentParamIndex = componentParamIndex;
                    orgasm.selectedparameterId = data?.antibioticTagMasterId;
                    this.orgasmsList?.push(orgasm);
                }
            } else {
                var orgasmIndex = this.orgasmsList?.findIndex(x => x.templateIndex == index && x.parameterIndex == paramIndex && x.componentParamIndex == componentParamIndex);
                if (orgasmIndex > -1) {
                    this.selectedAntibioticTagMasterId = this.orgasmsList[orgasmIndex].selectedparameterId;
                    this.orgasmsList.splice(orgasmIndex, 1);
                } else {
                    this.selectedAntibioticTagMasterId = null;
                }
            }
            if (!this.selectedAntibioticTagMasterId) {
                this.selectedAntibioticTagMasterId = data?.antibioticTagMasterId;
            } else {
                var antiBioticMaster = this.antiBioticMaster.find(x => x.antibioticTagMasterId == this.selectedAntibioticTagMasterId)
                antiBioticMaster?.lookups?.forEach((master) => {
                    var newIndex;
                    var oldIndex;
                    var newIndexData;
                    var oldIndexData;
                    if (componentParamIndex == undefined) {
                        newIndex = this.reportsAll[recordIndex].templates[index].parameters[paramIndex].antibiotics?.findIndex(x => x.antibioticName == master.name);
                        newIndexData = this.reportsAll[recordIndex].templates[index].parameters[paramIndex].antibiotics?.find(x => x.antibioticName == master.name);
                    } else {
                        newIndex = this.reportsAll[recordIndex].templates[index].components[componentParamIndex].parameters[paramIndex].antibiotics?.findIndex(x => x.antibioticName == master.name);
                        newIndexData = this.reportsAll[recordIndex].templates[index].components[componentParamIndex].parameters[paramIndex].antibiotics?.find(x => x.antibioticName == master.name);

                    }
                    if (componentParamIndex == undefined) {
                        oldIndex = this.parametersAllDummy[recordIndex].templates[index].parameters[paramIndex].antibiotics?.findIndex(x => x.labAntibioticsId == master.lookupValueId);
                        oldIndexData = this.parametersAllDummy[recordIndex].templates[index].parameters[paramIndex].antibiotics?.find(x => x.labAntibioticsId == master.lookupValueId);

                    } else {
                        oldIndex = this.parametersAllDummy[recordIndex].templates[index].components[componentParamIndex].parameters[paramIndex].antibiotics?.findIndex(x => x.labAntibioticsId == master.lookupValueId);
                        oldIndexData = this.parametersAllDummy[recordIndex].templates[index].components[componentParamIndex].parameters[paramIndex].antibiotics?.find(x => x.labAntibioticsId == master.lookupValueId);

                    }
                    if (((oldIndex == -1 || oldIndex == undefined) && newIndex > -1) || (newIndexData?.observedValue != oldIndexData?.observedValue)) {
                        if (componentParamIndex == undefined) {
                            newIndex = this.reportsAll[recordIndex].templates[index].parameters[paramIndex].antibiotics.splice(newIndex, 1);
                        } else {
                            newIndex = this.reportsAll[recordIndex].templates[index].components[componentParamIndex].parameters[paramIndex].antibiotics.splice(newIndex, 1);
                        }
                    }
                })
                this.selectedAntibioticTagMasterId = data?.antibioticTagMasterId ? data?.antibioticTagMasterId : null;
            }
            if (data?.tagsAntibioticDetails) {
                this.labTagMasterList = JSON.parse(data?.tagsAntibioticDetails)
            }

            data?.lookups?.forEach(x => {
                var antibiotic = this.labTagMasterList?.find(m => m.lookupValueId == x.lookupValueId);
                if (antibiotic) {
                    x.priority = Number(antibiotic.priority);
                }
            })
            data?.lookups?.sort((a, b) => (a.priority > b.priority ? 1 : -1));

            data?.lookups?.forEach((lab) => {
                var indexvalue;
                if (componentParamIndex == undefined) {
                    indexvalue = this.reportsAll[recordIndex].templates[index].parameters[paramIndex].antibiotics?.findIndex(x => x.antibioticName == lab.name);
                } else {
                    indexvalue = this.reportsAll[recordIndex].templates[index].components[componentParamIndex].parameters[paramIndex].antibiotics?.findIndex(x => x.antibioticName == lab.name);
                }
                if (indexvalue == -1 || indexvalue == undefined) {
                    var labData = new LabParameterInputHeaderModel();
                    labData.antibioticName = lab.name;
                    labData.observedValue = null;
                    labData.labAntibioticsId = lab.lookupValueId;
                    if (componentParamIndex == undefined) {
                        if (!this.reportsAll[recordIndex].templates[index].parameters[paramIndex].antibiotics) {
                            this.reportsAll[recordIndex].templates[index].parameters[paramIndex].antibiotics = []
                        }
                        this.reportsAll[recordIndex].templates[index].parameters[paramIndex].antibiotics.push(labData);
                    } else {
                        if (!this.reportsAll[recordIndex].templates[index].components[componentParamIndex].parameters[paramIndex].antibiotics) {
                            this.reportsAll[recordIndex].templates[index].components[componentParamIndex].parameters[paramIndex].antibiotics = []
                        }
                        this.reportsAll[recordIndex].templates[index].components[componentParamIndex].parameters[paramIndex].antibiotics.push(labData);
                    }
                }
            })

        }
        if (!isFromOrgasms && data) {
            this.selectParameter = data.antibioticName;
            var indexvalue;
            if (componentParamIndex == undefined) {
                indexvalue = this.reportsAll[recordIndex].templates[index].parameters[paramIndex].antibiotics?.findIndex(x => x.antibioticName == data.antibioticName);
            } else {
                indexvalue = this.reportsAll[recordIndex].templates[index].components[componentParamIndex].parameters[paramIndex].antibiotics?.findIndex(x => x.antibioticName == data.antibioticName);
            }
            if (indexvalue > -1) {
                this.notifyService.warningToast('Antibiotic Already Exist.');
            } else {
                data.observedValue = null;
                if (componentParamIndex == undefined) {
                    if (!this.reportsAll[recordIndex].templates[index].parameters[paramIndex].antibiotics) {
                        this.reportsAll[recordIndex].templates[index].parameters[paramIndex].antibiotics = []
                    }
                    this.reportsAll[recordIndex].templates[index].parameters[paramIndex].antibiotics.push(data);
                } else {
                    if (!this.reportsAll[recordIndex].templates[index].components[componentParamIndex].parameters[paramIndex].antibiotics) {
                        this.reportsAll[recordIndex].templates[index].components[componentParamIndex].parameters[paramIndex].antibiotics = []
                    }
                    this.reportsAll[recordIndex].templates[index].components[componentParamIndex].parameters[paramIndex].antibiotics.push(data);
                }
                this.notifyService.successToast('Antibiotic Added Succesfully.');
            }
        }
        this.onChange();
    }

    private findLabAntibiotic() {
        const request = {};
        this.httpService.post<any>(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchLabAntiBiotic), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Array<LookUpValue>) => {
                if (response.length > 0) {
                    this.labAntibiotics = new Array<LabParameterInputHeaderModel>();
                    response.forEach((antibiotic) => {
                        var loadAntibiotic = new LabParameterInputHeaderModel();
                        loadAntibiotic.antibioticName = antibiotic.name;
                        loadAntibiotic.labAntibioticsId = antibiotic.lookupValueId;
                        this.labAntibiotics.push(loadAntibiotic);
                    });
                }

            });
    }
    onChangeAntibiotics(templateIndex: number, parameterIndex: number, antibioticsIndex: number, componentParamIndex?: number) {
        var newValue;
        var oldValue
        if (componentParamIndex == undefined) {
            newValue = this.reports.templates[templateIndex].parameters[parameterIndex].antibiotics[antibioticsIndex].observedValue;
            oldValue = this.reportsDummy.templates[templateIndex].parameters[parameterIndex].antibiotics[antibioticsIndex].observedValue;
        } else {
            newValue = this.reports.templates[templateIndex].components[componentParamIndex].parameters[parameterIndex].antibiotics[antibioticsIndex].observedValue;
            oldValue = this.reportsDummy.templates[templateIndex].components[componentParamIndex].parameters[parameterIndex].antibiotics[antibioticsIndex].observedValue;
        }
        if (newValue != oldValue && newValue != "") {
            this.showButton = false;
        } else {
            this.showButton = true;
        }
    }
    onViewPastLabReports(content: TemplateRef<any>) {
        this.patientPastLabReports = new Array<LabPastReportsModel>();
        this.onOpenModel(content, "xxl");
        this.initPagination1();
        this.fetchPatientPastlabReports(this.patientId, this.labMainDetailId, this.newLabBookingDetailId);

    }
    private fetchPatientPastlabReports(patientId: number, labMainDetailId: number, newLabBookingDetailId: number) {
        this.loadingPastLabReports = true;
        const request = {
            ...this.pagination1
        };
        request["patientId"] = patientId;
        request["labMainDetailId"] = labMainDetailId;
        request["newLabBookingDetailId"] = newLabBookingDetailId;
        this.httpService.post(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchPatientPastLabReports), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingPastLabReports = false))
            .subscribe((response: Array<LabPastReportsModel>) => {
                this.patientPastLabReports = response;
                UtilHelper.applyPagination(this.patientPastLabReports, this.pagination1);
            }, () => {
                this.notifyService.warningToast("Unable to Past lab reports");
            });

    }
    onOpenLAbReport(lab: LabPastReportsModel) {
        this.router.navigate([]).then(() => { window.open(`${location.origin}${location.pathname}#/new-lab-reports/${lab.encryptedNewLabBookingDetailId}`, "_blank"); });
    }
    private initPagination1() {
        this.pagination1 = new Pagination();
        this.pagination.pageIndex = 1;
        this.pagination.pageSize = 20;
    }
    private fetchAntibioticResultTypes() {
        const request = {};
        this.httpService.post<any>(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchLabAntiBioticResultTypes), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Array<LookUpValue>) => {
                this.labAntibioticsResultTypes = new Array<LookUpValue>();
                this.labAntibioticsResultTypes = response;
                if (response.length == 0) {
                    return this.notifyService.warningToast('Antibiotic Result Types not Found');
                }

            });
    }
    onDoctorVerified() {
        this.verifying = true;
        var data = new Array<any>();
        this.parametersAll?.forEach((param) => {
            const request = {
                newLabBookingDetailId: param.newLabBookingDetailId
            };
            if (this.page.userAccount.roleId == 3) {
                request["doctorId"] = this.page.userAccount.referenceId;
                request["accountId"] = this.page.userAccount.accountId;
            }
            request["urlLink"] = window.location.href;
            request["patientId"] = this.demographData[0].patientId;
            request["testName"] = this.demographData[0].testName;
            request["roleId"] = this.page.userAccount.roleId;
            request["createdByName"] = this.page.userAccount.fullName;
            request["requisitionNumber"] = this.demographData[0].requisitionNumber;
            request["paymentStatus"] = this.demographData[0].paymentStatus;
            request["paymentType"] = this.demographData[0].paymentType;
            request["locationId"] = this.page.userAccount.locationId;
            data.push(request);
        })
        const req = {
            labDoctorVerificationModels: data
        }

        this.httpService.post(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.schemadoctorverifyreport), req)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.verifying = false))
            .subscribe((response: number) => {
                if (response > 0) {
                    this.notifyService.successToast(`Test Report Verified Successfully.`);
                    this.onClosePopup();
                }
                if (response < 0) {
                    this.notifyService.successToast(`error in Test Verification`);
                }

            }, () => {
                this.notifyService.warningToast("Unable to Test Verification.");
            });

    }

    onClosePopup() {
        this.onClose.emit();
    }
    OpenReasonView(content) {
        this.reason = null;
        this.modalReason = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            windowClass: "custom-modal effect-scale",
            size: 'm'
        });
    }
    onCloseReasonView() {
        if (this.modalReason) {
            this.modalReason.close();
            this.modalReason = undefined;
        }
    }
    onRemoveParameters() {
        if (!UtilHelper.isEmpty(this.reason)) {
            return this.notifyService.warning(`Please enter Reason`);
        }
        else if (!UtilHelper.isEmpty(this.reason.trim())) {
            return this.notifyService.warning(`Please enter Reason`);
        }
        var reqList = new Array<any>();
        
        this.parametersAll?.forEach((param) => {
            const request = {
                encryptedNewLabBookingDetailId: param.encryptedNewLabBookingDetailId
            };
            request["reason"] = this.reason;
            reqList.push(request);
        })
        const requestData = {
            newLabBookingDetailModels : reqList
        }
        this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.removeschemaparametervalues), requestData)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: number) => {
                if (response > 0) {
                    this.notifyService.successToast("The Parameters Removed sucessfully");
                    this.onCloseReasonView();
                    this.onClosePopup();
                }

            })
    }
    onGetParameters(item: LabTransferModel, content: TemplateRef<any>) {
        this.parametersList = [];
        this.onOpenModel(content, "xl");
        this.onFetchParameters(item.encryptedLabMainDetailId);
        this.OnFetchVerifiedLabsList(item.newLabBookingDetailId);
        this.fetchPatientAllParameters(item.patientId);
    }
    private onFetchParameters(encryptedLabMainDetailId: string) {
        this.httpService.get(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchlabparameters), { encryptedLabMainDetailId: encryptedLabMainDetailId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe((response: LabParameter[]) => {
                this.parametersList = response.filter((item) => {
                    if (item.referenceOutput == 'numeric') {
                        return item;
                    }
                });
                this.labObservedValues = new Array<LabObservedValue>();
                this.allparameters?.forEach((param) => {
                    var index = this.labObservedValues.findIndex(x => x.labParameterHeaderId == param.labParameterHeaderId);
                    if (index == -1) {
                        var data = this.parametersList.find(x => x.labParameterHeaderId == param.labParameterHeaderId)
                        if (data) {
                            var labObservedValue = new LabObservedValue();
                            labObservedValue.labParameterHeaderId = param.labParameterHeaderId;
                            labObservedValue.parameterName = data.parameterName;
                            labObservedValue.observedValues.push(param.observedValue);
                            this.labObservedValues.push(labObservedValue);
                        }
                    } else {
                        this.labObservedValues[index].observedValues.push(param.observedValue);
                    }
                })
            });
    }
    OnFetchVerifiedLabsList(newLabBookingDetailId: number) {
        this.httpService.get(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchpatientverifiedlabs), { newLabBookingDetailId: newLabBookingDetailId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe((response: Array<LabBillHeaderModel>) => {
                this.labsList = response;
            });
    }
    private fetchPatientAllParameters(patientId: number) {
        this.httpService.get(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchpatientalllabparameters), { patientId: patientId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe((response: Array<LabParameter>) => {
                this.allparameters = response;
                this.allparameters = this.allparameters.reverse();
            });
    }
    onGetAllPatientLabs(item: LabTransferModel, content: TemplateRef<any>) {
        this.parametersList = [];
        this.onOpenModel(content, "xl");
        this.fetchinvestigationchecklist(item.patientId);
    }
    fetchinvestigationchecklist(patientId: number) {
        this.labs = [];
        this.filters.options.patientId = patientId;
        const request = {
            ...this.filters.options
        };
        request["IsFromInvestigation"] = true;
        request["IsfromGraphs"] = true;
        this.httpService.post(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchDoctorVerifiedReports), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingSample = false))
            .subscribe((response: Array<VerifiedTestsModel>) => {
                this.labs = response as undefined as Array<LabBillDetailModel>;
                this.labs = this.labs.filter(x => x.status == "Verified");
                const data = [...new Set(this.labs.map(item => (item.testCode)))];
                var duplabs = this.labs;
                this.labs = new Array<LabBillDetailModel>();
                data.forEach((item) => {
                    var lab = duplabs.find(x => x.testCode == item);
                    this.labs.push(lab);
                })
            });
    }
    onClickLab(lab: LabBillDetailModel) {
        this.isShowParameters = true;
        this.labObservedValues = [];
        this.OnFetchVerifiedLabsList(lab.newLabBookingDetailId);
        this.fetchPatientAllParameters(lab.patientId);
        setTimeout(() => {
            this.onFetchParameters(lab.encryptedLabMainDetailId);
        }, 300)
    }
    onOpenGraph(content: TemplateRef<any>, size: string) {
        this.graphRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: size,
            windowClass: "custom-modal effect-scale"
        });
    }

    onCloseGraph() {
        try {
            this.graphRef.close();
            this.graphRef = undefined;
        } catch (e) {
            // ignored;
        }
    }
    onClickParameter(parameter: LabParameter | LabObservedValue) {
        this.graphData = new GraphView();
        this.isNoPastRecords = true;
        this.allparameters.forEach((lab) => {
            var item = this.labsList?.find(X => lab.labParameterHeaderId == parameter.labParameterHeaderId && X.newLabBookingDetailId == lab.newLabBookingDetailId)
            if (item) {
                this.isNoPastRecords = false
                item.totalItems = lab.observedValue;
                this.graphData.xValues.push(moment(item.verifiedDate).format('YYYY-MM-DD'));
                this.graphData.yValues.push(Number(lab.observedValue));
                this.graphData.titleName = parameter.parameterName;
                this.graphData.yparamName = parameter.parameterName;
                this.graphData.width = "100%";
                this.graphData.height = "400px";
                this.graphData.xparamName = "Verified Date"
                this.isGrapgShow = true;
            }
        })
        if (this.isNoPastRecords && parameter.constructor.name != "Object") {
            this.notifyService.warningToast("This Parameter Has No Previous Values");
        }
        if (parameter.constructor.name == "LabObservedValue") {
            this.onOpenGraph(this.graphView, "xl")
        }
        if (parameter.constructor.name == "Object") {
            if (this.isNoPastRecords) {
                this.notifyService.warningToast("This Parameter Has No Previous Values");
            } else {
                this.onOpenGraph(this.graphView, "xl")
            }
        }
    }
    onVerifyDoctor() {
        if (this.showButton) {
            this.onDoctorVerified();
        } else {
            this.onSubmit(true);
        }
    }
    onViewLabReport(item: LabBillDetailModel, content: TemplateRef<any>) {
        this.selectedLabBookingDetail = item;
        this.showLabReport = true;
        this.isPrintLogo = true;
        this.onOpenLabReportModel(content, "xl");
    }

    onOpenLabReportModel(content: TemplateRef<any>, size: string) {
        this.reportRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: size,
            windowClass: "custom-modal effect-scale"
        });
    }
    onLabReportModal() {
        this.reportRef.close();
        this.reportRef = undefined;
    }

    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
    oncheckFormulaResults() {
        let tempParameters = Array<LabParameterInputHeaderModel>();
        this.reports.templates.forEach((template) => {
            template.components.forEach((component) => {
                component.parameters.forEach((parameter) => {
                    tempParameters.push(parameter);
                });
            });
            template.parameters.forEach((parameter) => {
                tempParameters.push(parameter);

            });
        });
        this.reports.templates.forEach((template) => {
            template.components.forEach((component) => {
                component.parameters.forEach((parameter) => {
                    var parameterValue = 1;
                    if (parameter.formula != '' && parameter.formula != null && parameter.formula != undefined) {
                        let input = parameter.formula;
                        let formula = parameter.formula;
                        this.regex = /#(.*?)#/g;
                        let match: RegExpExecArray | null;
                        const result: paramaterResultCalculationHelper[] = [];
                        while ((match = this.regex.exec(input)) !== null) {
                            let param = new paramaterResultCalculationHelper();
                            param.parameterCode = match[0];
                            param.parameterId = match[1];
                            param.result = tempParameters.find(x => x.parameterId == match[1].toString())?.observedValue ? tempParameters.find(x => x.parameterId == match[1].toString())?.observedValue : null;
                            result.push(param);
                        }
                        let resultsNotAdded = result.filter(y => y.result == null);
                        if (resultsNotAdded.length == 0) {
                            result.forEach((item) => {
                                formula = formula.replaceAll(item.parameterCode, item.result);

                            });
                            parameter.observedValue = evaluate(formula);
                            if (parameter.observedValue == "" || !parameter.observedValue) {
                                parameter.showText = null;
                                parameter.isBold = false;
                            } else {
                                if (parameter.observedValue?.includes("^")) {
                                    var values = parameter.observedValue?.split('^');
                                    if (values?.length > 1) {
                                        parameterValue = Number(pow(Number(values[0]), Number(values[1])))
                                    }
                                }
                                let observedValue = +parameter.observedValue;
                                let range = parameter.selected;
                                if (range != null) {
                                    let exactRange = range;
                                    if (exactRange.minValue > observedValue || (parameterValue == 1 ? false : exactRange.minValue > parameterValue)) {
                                        parameter.showText = "L";
                                        parameter.isBold = true;
                                        this.isAbnormal = true;
                                    }
                                    else if (observedValue > +exactRange.maxValue || (parameterValue == 1 ? false : parameterValue > +exactRange.maxValue)) {
                                        parameter.showText = "H";
                                        parameter.isBold = true;
                                        this.isAbnormal = true;
                                    }
                                }
                            }
                        }
                    }
                });
            });
            template.parameters.forEach((parameter) => {
                var parameterValue = 1
                if (parameter.formula != '' && parameter.formula != null && parameter.formula != undefined) {
                    let input = parameter.formula;
                    let formula = parameter.formula;
                    this.regex = /#(.*?)#/g;
                    let match: RegExpExecArray | null;
                    const result: paramaterResultCalculationHelper[] = [];
                    while ((match = this.regex.exec(input)) !== null) {
                        let param = new paramaterResultCalculationHelper();
                        param.parameterCode = match[0];
                        param.parameterId = match[1];
                        param.result = tempParameters.find(x => x.parameterId == match[1].toString())?.observedValue ? tempParameters.find(x => x.parameterId == match[1].toString())?.observedValue : null;
                        result.push(param);
                    }
                    let resultsNotAdded = result.filter(y => y.result == null);
                    if (resultsNotAdded.length == 0) {
                        result.forEach((item) => {
                            formula = formula.replace(item.parameterCode, item.result);

                        });
                        parameter.observedValue = evaluate(formula);
                        if (parameter.observedValue == "" || !parameter.observedValue) {
                            parameter.showText = null;
                            parameter.isBold = false;
                        } else {
                            if (parameter.observedValue?.includes("^")) {
                                var values = parameter.observedValue?.split('^');
                                if (values?.length > 1) {
                                    parameterValue = Number(pow(Number(values[0]), Number(values[1])))
                                }
                            }
                            let observedValue = +parameter.observedValue;
                            let range = parameter.selected;
                            if (range != null) {
                                let exactRange = range;
                                if (exactRange.minValue > observedValue || (parameterValue == 1 ? false : exactRange.minValue > parameterValue)) {
                                    parameter.showText = "L";
                                    parameter.isBold = true;
                                    this.isAbnormal = true;
                                }
                                else if (observedValue > +exactRange.maxValue || (parameterValue == 1 ? false : parameterValue > +exactRange.maxValue)) {
                                    parameter.showText = "H";
                                    parameter.isBold = true;
                                    this.isAbnormal = true;
                                }
                            }
                        }
                    }

                }
            });
        });


    }
    private findAntiBioticTagMaster() {
        const request = {};
        this.httpService.post<any>(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchAntiBioticMaster), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Array<any>) => {
                this.antiBioticMaster = response;
            });
    }
    onSetClickMicroParameter(data: any,recordIndex:number, paramIndex: number, componentParamIndex?: number, isFromOrgasms?: boolean) {
        var parameterIndex = -1;
        var componentIndex = -1;
        if (componentParamIndex > -1) {
            var selectedComponentId = this.reportsAll[recordIndex]?.templates[0].templateDetailsOrder[paramIndex]?.componentId;
            if (selectedComponentId) {
                this.reportsAll[recordIndex]?.templates?.forEach((item) => {
                    componentIndex = item.components.findIndex(y => y.componentId == selectedComponentId);
                    var selectedParameterId = this.reportsAll[recordIndex]?.templates[0]?.templateDetailsOrder[paramIndex]?.parameters[componentParamIndex]?.parameterId;
                    parameterIndex = item.components[componentIndex].parameters?.findIndex(x => x.parameterId == selectedParameterId);
                })
            }
        } else {
            var selectedParameterId = this.reportsAll[recordIndex]?.templates[0]?.templateDetailsOrder[paramIndex]?.parameterId;
            if (selectedParameterId)
                this.reportsAll[recordIndex]?.templates?.forEach((param) => {
                    parameterIndex = param.parameters?.findIndex(x => x.parameterId == selectedParameterId);

                })
        }
        if (componentIndex == -1) {
            componentIndex = undefined;
        }
        this.onClickMicroParameter(data, recordIndex, 0, parameterIndex, componentIndex, isFromOrgasms)
    }
    private fetchTemplateDetail(id: number, index: number, isDummy: boolean) {
        const request = {
            headerId: id
        };
        this.httpService.get(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchLabTemplateDetail), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe(
                (response: Array<LabTemplateDetail>) => {
                    this.templateDetail = response;
                    if (isDummy) {
                        this.templateDetail?.forEach((item) => {
                            if (item.parameterName) {
                                this.parametersAllDummy[index]?.templates?.forEach((template, i) => {
                                    var parameter = template?.parameters.find(x => x.labParameterHeaderId == item.labParameterHeaderId);
                                    if (parameter) {
                                        if (!template.templateDetailsOrder) {
                                            template.templateDetailsOrder = [];
                                        }
                                        template.templateDetailsOrder.push(parameter);
                                    }
                                })
                            } else if (item.componentName) {
                                this.parametersAllDummy[index]?.templates?.forEach((template, i) => {
                                    var component = template?.components.find(x => x.labComponentHeaderId == item.labComponentHeaderId);
                                    if (component) {
                                        if (!template.templateDetailsOrder) {
                                            template.templateDetailsOrder = [];
                                        }
                                        template.templateDetailsOrder.push(component);
                                    }
                                })
                            }
                        })
                        this.reportsAllDummy[index] = this.parametersAllDummy[index];
                    } else {
                        this.templateDetail?.forEach((item) => {
                            if (item.parameterName) {
                                this.parametersAll[index]?.templates?.forEach((template, i) => {
                                    var parameter = template?.parameters.find(x => x.labParameterHeaderId == item.labParameterHeaderId);
                                    if (parameter) {
                                        if (!template.templateDetailsOrder) {
                                            template.templateDetailsOrder = [];
                                        }
                                        template.templateDetailsOrder.push(parameter);
                                    }
                                })
                            } else if (item.componentName) {
                                this.parametersAll[index]?.templates?.forEach((template, i) => {
                                    var component = template?.components.find(x => x.labComponentHeaderId == item.labComponentHeaderId);
                                    if (component) {
                                        if (!template.templateDetailsOrder) {
                                            template.templateDetailsOrder = [];
                                        }
                                        template.templateDetailsOrder.push(component);
                                    }
                                })
                            }
                        })
                        this.reportsAll[index] = this.parametersAll[index];
                    }
                }
            );
    }
}