import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { HttpService, AppData, EncounterCommunication, TimelineToggleService} from '../../services';
import { takeUntil, finalize } from 'rxjs/operators';
import { Page, IUserAccount, PatientProfile } from '../../models';
import { Appointment} from '../../entities';
import { ApiResources } from '../../helpers';
import { Router } from '@angular/router';
import { GYN } from '../../../areas/admin/gyn-encounter/gyn.namespace';
import { GynEncounter } from '../../models/gyn-encounter.model';

@Component({
    selector: 'emergency-header',
    templateUrl: './emergency-header.html',
    styleUrls: ['./emergency-header.css']
})
export class EmergencyHeaderWidget implements OnDestroy, OnInit {
    @Input() patientId: string;
    @Input() appointmentId: string;
    ancCards: GYN.NewGynHistory;
    partnerDetail: GYN.GyneacPartner;//
    page: Page;
    isAdmission: any;  
    appointments: any;
    oldAppointment: any;
    vitals: any;
    visitNo: number;
  //  encounterCommunicationSubscription: Subscription;
    appointment: Appointment;
    patient: PatientProfile;
    husbandName: string; 
    roleId: number;
    wifeName: string;
    sideNav: boolean;
    constructor(
        private readonly httpService: HttpService,
        private readonly appData: AppData,
        private readonly encounterCommunication: EncounterCommunication,
        private readonly router: Router,
        public timelineService: TimelineToggleService

    ) {
        this.page = new Page();

    }

    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: this.appointmentId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
                .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointment = response;
             
            });
    }

   
    private findPatientDetails(id: string) {
        this.page.loading = true;
        this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profile), { encryptedPatientId: id })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PatientProfile) => {
                this.patient = response;
                if (this.appointment.patientGender=="F")
                    this.husbandName = this.patient.relativeDetails[0] ? this.patient.relativeDetails[0].fullName : null;

                this.wifeName = this.patient.relativeDetails[0] ? this.patient.relativeDetails[0].fullName : null;
            });
    }


    private findPatient() {
        let intervalHit = setInterval(() => {
            let setting = localStorage.getItem("settings");
            if (setting) {
                clearInterval(intervalHit);
                this.page.loading = true;
                const request = { encryptedAppointmentId: this.appointmentId };
                request["isAdmission"] = this.isAdmission;
                this.httpService.post<GynEncounter>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
                    .pipe(takeUntil(this.page.unSubscribe))
                    .subscribe((response: GynEncounter) => {
                        this.patientId = response.encryptedPatientId;

                        this.findPatientDetails(response.encryptedPatientId);
                    });
            }
        }, 10);
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.appointmentId = decodeURIComponent(url.split("/")[url.split("/").length - 3]);
                     this.roleId = this.page.userAccount.roleId;
                    this.isAdmission = url.split("/")[4] === "a";
                   // this.encounterCommunicationSubscription = this.encounterCommunication.gynEncounter.subscribe(() => {
                        this.findPatient();
                        this.findAppointment(this.appointmentId);
                       // this.findDashboard();

                   // });
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
    toggleIt() {
        this.timelineService.toggle();
        if (this.timelineService.showBox == false) {
            $("body")[0].setAttribute("data-timeline-size", "timelineabsent");
        } else {
            $("body")[0].setAttribute("data-timeline-size", "timeline");
        }
    }
    toggleSideNav() {
        $(".sidebar-main-menu")[0].classList.toggle("removeLeft");
        if ($("body")[0].getAttribute('data-sidebar-size') === "default") {
            $("body")[0].setAttribute("data-sidebar-size", "condensed");
            this.sideNav = true;
        } else if ($("body")[0].getAttribute('data-sidebar-size') === "condensed") {
            $("body")[0].setAttribute("data-sidebar-size", "default");
            this.sideNav = false;
        }
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }
}