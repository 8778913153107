export class DynamicTemplate {
    dynamicTemplateId: number;
    templateName: string;
    value: string;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
    createdByName: string;
    modifiedByName: string;
    createdByRole: string;
    modifiedByRole: string;
}