export class UserImportHistory {
    excelUploadHistoryId: number;
    uploadedBy: number;
    createdDate: Date;
    sheetName: string;
    uploadedByName: string;
    typeOf: string;
    addedProducts: string;
    sheetType: string;
    productStatus: string;
    locationName: string;
    totalItems: number;
    uploadedByRole: string;
}