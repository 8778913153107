export class LabOrderValue {
    labOrderValueId: number;
    labOrderId: number;
    code: string;
    name: string;
    labOrderName: string;
    orderCode: string;
    test: string;
    createdDate: Date;
    createdBy: number;
    modifiedBy?: number;
    isAssignedLabOrderValue?: boolean;
    modifiedDate?: Date;
    totalItems?: number;
} 