
export class pediatricItem {
    id: number;
    pharmacyProductId: number;
    genericName: string;
    active: boolean;
    productName: string;
    drugName: string;
    drugGroup: string;
    companyName: string;
    availableAs: string;
    oralNonoralValue: string;
    dosageBased: number;
    ageOrWeight: string;
    strengthMg: number;
    per: number;
    dosage: number;
    perDayPerDose: string;
    fixedDose: string;
    frequency: number;
    tOTDay: string;
    ageBasedItems: string;
    weightBasedItems: string;
    type: string;
    duration: string;
    frequencyDays: string;
    qtyInOneBottle: number;
    fixedQuantity: number;
    instructions: string;

}