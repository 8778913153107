<div class="d-flex justify-content-center align-items-center mt-3">
    <div class="text-right" *ngIf="appointment &&!appointment.readyforDischarge">
        <button (click)="fetchPendingPharmacyIndentRecords(templateDischarge)" class="btn btn-primary btn-sm rounded-pill"><i class="mdi mdi-information mr-1"></i>Ready To Discharge</button>
    </div>
    <div class="text-right" *ngIf="appointment && appointment.readyforDischarge">
        <div class="alert alert-info mb-0 px-5 py-1">
            <i class="mdi mdi-information mr-1"></i> Discharge is in process.
        </div>
    </div>
    <div class="text-right" *ngIf="appointment && appointment.readyforDischarge">

        <button (click)="onCancelReadyToDischarge()" class="btn btn-primary btn-sm rounded-pill">Cancel</button>
    </div>
</div>
<div>
    <table *ngIf="appointment" class="table table-sm mt-1 table-bordered mb-0">
        <tr>
            <th class="bg-soft-warning">Patient Name</th>
            <th [textContent]="appointment.patientName"></th>
            <th class="bg-soft-warning">UMR No</th>
            <th [textContent]="appointment.umrNo"></th>
            <th class="bg-soft-warning">Age / Sex</th>
            <th>
                <span [textContent]="appointment.patientAge"></span>
                <span> / </span>
                <span [textContent]="appointment.patientGender"></span>
            </th>
            <th class="bg-soft-warning">IP No</th>
            <th [textContent]="appointment.appointmentNo"></th>
            <th class="text-right">
                <a href="javascript:;" (click)="onback()">
                    <span><i class="fe-arrow-left mr-1"></i> Back</span>
                </a>
            </th>
        </tr>
        <tr>
            <th class="bg-soft-warning">Room No</th>
            <th [textContent]="appointment.roomName"></th>
            <th class="bg-soft-warning">Bed No</th>
            <th [textContent]="appointment.bedId"></th>
            <th class="bg-soft-warning">Admission Date</th>
            <td>
                <span [textContent]="appointment.appointmentDate |date:'dd/MM/yyyy'"></span>
            </td>
            <th class="bg-soft-warning">Admission Time</th>
            <td colspan="2">
                <span class="ml-1 text-uppercase" [textContent]="appointment.admTime"></span>
            </td>
        </tr>
    </table>
</div>

<ng-template #templateDischarge>
    <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group mb-3">
                    <label class="mb-1">Comments </label>
                    <div class="form-group">
                        <input tabindex="1" type="text" maxlength="150" [(ngModel)]="comments" autocomplete="nope" class="form-control"  placeholder="comments" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
        <button type="button" [disabled]="loading" class="btn btn-primary btn-sm" (click)="onSubmitReadyToDischarge()">
            <span *ngIf="loading">
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                Please wait..
            </span>
            <span *ngIf="!loading">Submit</span>
        </button>
    </div>
</ng-template>