import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlightFilter',
})
export class HighlighFilterPipe implements PipeTransform {
    transform(value: string, searchText: string): string {
        if (!searchText || !value) {
            return value;
        }
        searchText = searchText.toLowerCase();
        // Create a regex with global and case insensitive flags
        const re = new RegExp(searchText, 'gi');
        // Replace occurrences of searchText with highlighted span
        return value.replace(re, `<mark ">$&</mark>`);
    }
}