export class Encounter {
    encounterId: number;
    encryptedEncounterId?: string;
    appointmentId: number;
    encounterDate: Date;
    cheifComplaints: string;
    vitals: string;
    problemList: string;
    ros: string;
    heent: string;
    skin: string;
    respiratory: string;
    cardiovascular: string;
    abdomen: string;
    extremities: string;
    ekg: string;
    musculoskeletal: string;
    neurology: string;
    diagnosis: string;
    soapNotes: string;
    dischargeAdvice: string;
    allergies: string;
    socialHistory: string;
    radiology: string;
    hospitalizationSurgery: string;
    familyMedicalHistory: string;
    preventiveCare: string;
    immunization: string;
    icDcodes: string;
    medications: string;
    labOrders: string;
    followUp: string;
    cpTcodes: string;
    stressTest: string;
    historyRadiology: string;
    hpo: string;
    pastandCurrentMedications: string;
    historyLaborder: string;
    angiogram: string;
    handouts: string;
    recordsRequest: string;
    consultations: string;
    too: string;
    notes: string;
    active: true;
    createdBy: number;
    createdDate: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
}