export class IUIReport {
    pno: number
    referralDoctorName: string
    date: Date
    inseminationTime: string
    husbandName: string
    dayOfIUI: string
    husbandAge: number
    toc: string
    tol: string
    wifeName: string
    wifeage: number
    liquefactionTime: string
    appearance: string
    volume: string
    viscosity: string
    ph: string
    countBeforeProcessing: string
    countAfterProcessing: string
    motilityBeforeProcessing: string
    motilityAfterProcessing: string
    granularDebrisBeforeProcessing: string
    granularDebrisAfterProcessing: string
    volumeUsedBeforeProcessing: string
    volumeUsedAfterProcessing: string
    embryologist: string
}