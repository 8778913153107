import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgIdleModule } from "@ng-idle/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { DateParserFormatter, NgbUTCStringAdapter } from "@shared/formatters";
import { MomentModule } from "ngx-moment";
import { ToastrModule } from "ngx-toastr";
import { NgxPrintModule } from "ngx-print";
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxDropzoneModule } from "ngx-dropzone";
import { NgxBarcode6Module } from "ngx-barcode6";
import { QRCodeModule } from 'angularx-qrcode';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ImageCropperComponent } from "ngx-image-cropper";
import { CountdownModule } from 'ngx-countdown';
import { WebcamModule } from 'ngx-webcam';
import { NgxGaugeModule } from 'ngx-gauge';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        LoadingBarRouterModule,
        LoadingBarModule,
        NgbModule,
        NgSelectModule,
        NgIdleModule.forRoot(),
        MomentModule.forRoot(),
        NgxPrintModule,
        CKEditorModule,
        PdfViewerModule,
        ImageCropperComponent,
       // ImageDrawingModule,
        WebcamModule,
        NgxGaugeModule,
        ToastrModule.forRoot({
            timeOut: 2000,
            positionClass: "toast-bottom-right",
            preventDuplicates: true,
            closeButton: true,
            autoDismiss: true,
            progressBar: true,
            progressAnimation: "increasing",
        }),
        DragDropModule,
        NgxDropzoneModule,
        NgxBarcode6Module,
        QRCodeModule,
     
    ],
    providers: [
        { provide: NgbDateAdapter, useClass: NgbUTCStringAdapter },
        { provide: NgbDateParserFormatter, useClass: DateParserFormatter }
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        LoadingBarRouterModule,
        LoadingBarModule,
        NgbModule,
        NgSelectModule,
        NgIdleModule,
        MomentModule,
        ToastrModule,
        DragDropModule,
        NgxPrintModule,
        NgxDropzoneModule,
        PdfViewerModule,
        NgxBarcode6Module,
        QRCodeModule,
        CKEditorModule,
        ImageCropperComponent,
        CountdownModule,
        WebcamModule,
        NgxGaugeModule,
       // ImageDrawingModule
    ]
})
export class SharedCommonModule {
}