export enum LookupType {
    ProblemList = 24,
    PlStatus = 43,
    Ppd = 52,
    Relation = 44,
    Health = 34,
    Allergies = 9,
    Reactions = 46,
    Hospitalization = 10,
    PCare = 41,
    Initial = 51,
    Site = 5,
    Vaccine = 22,
    gynLabs=33
}