export enum PhysiotherapyEncounterType {
    specialFeature = 1,
    PhysioTherapyTemplate,
    Reminder,
    OutSideTests,
    referralForm,
    orderPrescription,
    refferalOrder,
    confidential,
    FutureAppointments,
    addendumForm,
    TelePhoneTriageAssessment
}