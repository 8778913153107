export enum HomeopathyType {
    Confidential = 1,
    CCAndOC,
    AdditionalComplaints,
    PastIllness,
    FamilyIllness,
    SpouseAndChildren,
    PersonalHistory,
    PersonalHabits,
    AppetiteAndThirst,
    BowelMovements,
    Urination,
    SweatFeverChill,
    ChestHeartColdCough,
    SexualSphere,
    GeneralComplaints,
    AffectedFactors,
    EmotionalNature,
    SleepAndDreams,
    Children,
    ChiefComplaints,
    Medications,
    MedicationComments,
    FollowUp,
    GeneralAdvice
}