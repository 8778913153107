<!--<div (click)="toggle()" *ngIf="showBox === 1" class="dial-box cursor-pointer">
    <h3>Appointments</h3>
</div>-->

<div *ngIf="showBox === 2" class="appointments-box card">
  <div class="card-header p-2">
    Today Appointments
    <div class="float-right">
      <i (click)="toggle()" class="fe-x cursor-pointer"></i>
    </div>
  </div>
  <div class="card-body p-2 overflow-auto">
    <div *ngIf="!appointments">
      <h5 class="mb-0 d-inline-block mt-0 font-weight-normal">No appointments exists.</h5>
    </div>
    <div class="mb-3" [ngClass]="{'bg-appointment-active' : item.isActive, 'bg-light': !item.isActive}" *ngFor="let item of appointments; let i = index;">
      <div class="media p-1">
        <div class="avatar-sm mr-2">
          <avatar-img *ngIf="item.patientThumbnailUrl" [src]="item.patientThumbnailUrl" cssClass="img-fluid rounded-circle" [alt]="item.patientName"></avatar-img>
          <span *ngIf="!item.patientThumbnailUrl" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="i" [textContent]="item.patientName | initials"></span>
        </div>
        <div class="media-body">
          <a href="javascript:;">
            <h5 class="mb-0 d-inline-block mt-0 font-weight-normal three-dot text-dark" [textContent]="item.patientName"></h5>
            <div class="d-inline-block text-muted font-weight-normal font-12 float-right" [textContent]="item.appointmentDateTime | date:'shortTime'"></div>
          </a>
          <div>
            <div class="float-left">
              <span *ngIf="item.status !== null">
                <ng-container [ngSwitch]="item.status">
                  <span [ngClass]="item.encounterType ? 'badge badge-soft-info' : 'badge badge-soft-success'" *ngSwitchCase="'B'" [textContent]="item.encounterType ? 'Saved' : 'Booked'"></span>
                  <span class="badge badge-soft-primary" *ngSwitchCase="'R'">Rescheduled</span>
                  <span class="badge badge-soft-danger" *ngSwitchCase="'C'">Canceled</span>
                  <span class="badge badge-soft-warning" *ngSwitchCase="'CP'">Closed</span>
                  <span *ngSwitchDefault>-</span>
                </ng-container>
              </span>
              <span *ngIf="item.status === null">-</span>
            </div>
            <div class="d-inline-block float-right align-items-center justify-content-end">
              <div *ngIf="item.active" class="dropdown show">
                <a href="javascript:;" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="true" aria-haspopup="true" placement="left" ngbTooltip="Actions"><i class="fe-more-horizontal"></i></a>
                <div class="dropdown-menu actions-dropdown dropdown-menu-right transform-none" x-placement="bottom-end">
                  <a class="dropdown-item" *ngIf="item.encounterType === null || item.encounterType === 'Encounter'" href="javascript:;" (click)="onEncounter(item.encryptedAppointmentId, item)"><i class="mdi mdi-clipboard-pulse mr-1"></i> Encounter</a>
                  <a class="dropdown-item" *ngIf="item.encounterType === null || item.encounterType === 'InternalMedicine'" href="javascript:;" (click)="onInternalMedicine(item.encryptedAppointmentId, item)"><i class="mdi mdi-clipboard-pulse-outline mr-1"></i> Internal Medicine</a>
                  <a class="dropdown-item" *ngIf="item.encounterType === null || item.encounterType === 'BehavioralHealth'" href="javascript:;" (click)="onBehavioralHealth(item.encryptedAppointmentId, item)"><i class="mdi mdi-file-table-outline mr-1"></i> Behavioral Health</a>
                  <a class="dropdown-item" *ngIf="item.encounterType === null || item.encounterType === 'Homeopathy'" href="javascript:;" (click)="onHomeopathy(item.encryptedAppointmentId, item)"><i class="mdi mdi-file-table-outline mr-1"></i> Homeopathy</a>
                  <a class="dropdown-item" *ngIf="item.encounterType === null || item.encounterType === 'DentalEncounter'" href="javascript:;" (click)="onDentalEncounter(item.encryptedAppointmentId, item)"><i class="mdi mdi-file-table-outline mr-1"></i> Dental Encounter</a>
                  <a class="dropdown-item" *ngIf="item.encounterType !== null && item.isFullTranscript" href="javascript:;" (click)="onReport(item.encryptedAppointmentId,item.encounterType)"><i class="mdi mdi-file-table-outline mr-1"></i> Full Transcript</a>
                  <a class="dropdown-item" *ngIf="item.encounterType !== null && item.isPrescription" href="javascript:;" (click)="onPrescription(item.encryptedAppointmentId)"><i class="mdi mdi-file-table-outline mr-1"></i> Prescription</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="m-0" />
      <div>
        <div class="pb-2 pl-2 pt-2 justify-content-end">
          <ng-container *ngIf="!item.callStatus">
            <span class="badge badge-secondary mr-2 p-1"><i class="fe-phone mr-1"></i> Call not started</span>
          </ng-container>
          <ng-container *ngIf="item.callStatus">
            <ng-container [ngSwitch]="item.callStatus">
              <ng-container *ngSwitchCase="1">
                <span class="badge badge-danger mr-2 p-1"><i class="fe-phone-missed mr-1"></i><span [textContent]="item.callStatusName"></span> (<span [textContent]="item.callTypeName"></span>)</span>
              </ng-container>
              <ng-container *ngSwitchCase="2">
                <span class="badge badge-warning mr-2 p-1 join-btn"><i class="fe-phone-missed mr-1"></i><span [textContent]="item.callStatusName"></span> (<span [textContent]="item.callTypeName"></span>)</span>
              </ng-container>
              <ng-container *ngSwitchCase="3">
                <span class="badge badge-success mr-2 p-1"><i class="fe-phone-missed mr-1"></i><span [textContent]="item.callStatusName"></span> (<span [textContent]="item.callTypeName"></span>)</span>
              </ng-container>
              <ng-container *ngSwitchCase="4">
                <span class="badge badge-outline-danger mr-2 p-1"><i class="fe-phone-missed mr-1"></i><span [textContent]="item.callStatusName"></span> (<span [textContent]="item.callTypeName"></span>)</span>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>