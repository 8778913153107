export class DepartmentalStock {
    pharmacyDepartmentId: number;
    pharmacyDepartmentalStockId: number;
    inventoryDepartmentId: number;
    inventoryDepartmentalStockId: number;
    pharmacyProductId: number;
    inventoryProductId: number;
    taxId: number;
    quantityIn: number;
    quantityOut: number;
    purchaseRate: number;
    mrp: number;
    roq: number;
    rol: number;
    availableQuantity: number;
    taxPercentage: number;
    totalItems: number;
    pharmacyStockId?: number;
    pharmacyRetailStockId?: number;
    pharmacyProductDetailId?: number;
    inventoryProductDetailId?: number;
    barcode: string;
    batchNumber: string;
    productName: string;
    categoryName: string;
    genericName: string;
    rackName: string;
    departmentName: string;
    createdByName: string;
    createdByRole: string;
    modifiedByName: string;
    modifiedByRole: string;
    createdBy: number;
    modifiedBy?: number
    createdDate: Date;
    modifiedDate?: Date;
    expiryDate?: Date;
    companyName: string;
    reqQuantity: number;
    pharmacyIndentDetailId: number;
    pharmacyIndentHeaderId: number;
    quantity: number;
}
