
export class ScanClassification {
    scanClassificationId: number;
    scanClassificationName: string;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    totalItems: number;
    modifiedByName: string;
    modifiedDate: Date;
    modifiedBy: number;
    createdbyName: string;
    createdbyRole: string;
    modifiedByRole: string;
    remarks: string;
}
