export class Nurses {
    nurseName: string;
    nurseShiftMapId: number;
    dates: Array<Dates>;
    patientName: string;
    umrNo: string;
    admissionDate: Date;
    admissionId: number;
}

export class Dates {
    date: string;
    shifts: Array<Shifts>;
}

export class Shifts {
    shiftName: string;
    beds: Array<Beds>;
}

export class Beds {
    bedId: number;
    gender: string;
    bedNumber: string;
    patientId: number;
    admissionId: number;
    admissionNo: string;
    patientName: string;
    providerName: string;
    bedStatusName: string;
    expectedDichargeDate: string;
}