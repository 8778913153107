import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AppData, HttpService, NotifyService } from "../../services";
import { IUserAccount, Page } from "../../models";
import { takeUntil } from "rxjs";
import { ApiResources } from "../../helpers";

@Component({
    selector: "billcomments-modal-comp",
    templateUrl: "./bill-notification-comments.component.html"
})
export class BillNotificationCommentModalComponent implements OnInit, OnDestroy {
    @Input() patientId: number;
    page: Page;
    comments: string;
    masterBill: any;
    billComments: any;
    submitting: boolean;

    constructor(
        public activeModal: NgbActiveModal,
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
    ) {
        this.page = new Page();
    }

    onSubmit() {
        this.submitting = true;
        const request = {};
        request["patientId"] = this.patientId;
        request["comments"] = this.comments;
        request["createdBy"] = this.page.userAccount.accountId;
        if (this.comments == null || this.comments == undefined) {
            this.notifyService.infoToast("Please enter the comments.");
            return;
        }
        this.httpService
            .post(ApiResources.getURI(ApiResources.masterbill.base, ApiResources.masterbill.modifyBillingComments), request)
            .subscribe(
                (response: any) => {
                    this.masterBill = response;
                    if (response > 1) {
                        this.activeModal.close();
                        this.submitting = false;
                    }
                });
    }
    inputComments(value: any) {
        this.comments = value && value.target && value.target.value ? value.target.value : value;
    }
    fetchComments(patientId: number) {

        this.httpService
            .get(ApiResources.getURI(ApiResources.masterbill.base, ApiResources.masterbill.fetchBillingComments), { patientId: patientId })
            .subscribe(
                (response: any) => {
                    this.billComments = response;
                });
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchComments(this.patientId);
                }
            });
    }
    onCloseModal() {
        this.activeModal.close();
    }
    ngOnDestroy() {

        this.page.unsubscribeAll();
    }
}

