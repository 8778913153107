import { Directive, HostListener } from "@angular/core";
//import { KeyCodesHelper, RegExHelper } from "@shared/helpers";

@Directive({
    selector: "[pasteOnly]"
})
export class PasteOnlyDirective {
    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
        const dataToPaste = event.clipboardData.getData('text');
        // Validate 'dataToPaste' against the regex
    }
    //@HostListener("keydown", ["$event"])
    //handleKeyboardEvent(event: KeyboardEvent) {
    //    let x = event.keyCode;
    //    if (x === 65) {
    //    }
    //}
    //onKeyDown(e: KeyboardEvent) {
    //    
    //    let x = e.keyCode;
    //    if (x === 17) {
    //        return;
    //    }
    //    const keys = KeyCodesHelper.default.concat(KeyCodesHelper.numbers);
    //    if (keys.indexOf(e.keyCode) === -1) {
    //        event.preventDefault();
    //        return;
    //    }

    //    if (KeyCodesHelper.specialCharacters.indexOf(e.key) > -1) {
    //        event.preventDefault();
    //        return;
    //    }

    //    if (KeyCodesHelper.defaultNames.indexOf(e.key) === -1) {
    //        return;
    //    }

    //    if (RegExHelper.numbersOnly.test(e.key))
    //        return;

    //    event.preventDefault();
    //}
}