export class ExternalLabTransferModel {
    newLabBookingHeaderId: number;
    patientId?: number;
    doctorId?: number;
    employeeId?: number;
    locationId: number;
    age?: number;
    requisitionNumber: string;
    patientName: string;
    mobile: string;
    doctorName: string;
    employeeName: string;
    umrNo: string;
    gender: string;
    createdDate: Date;
    approvedDate: Date;
    bookedDate: Date;
    totalItems: number;
    testName: string;
    testCode: string;
    externalLabTransferId: number;
    transferNumber: string;
    transferredBy: number;
    transferredDate: Date;
    collectionDate?: Date;
    barcodeDate?: Date;
    labMainDetailId: number;
    transferByName: string;
    recievedByName: string;
    status: string;
    sampleCollectedByName: string;
    barcodeGeneratedByName: string;
    fromLocation: string;
    isBarcodeGenerated: boolean;
    encryptedId: string;
    newLabBookingDetailId?: number;
    dateOfBirth?: Date;
    rowColor: string;
    approved?: boolean;
    assignedDoctor: string;
    createdByName: string;
    isSelected: boolean;
    createdBy: number;
    comments: string;

    departmentName: string;
    sampleCollectionLocation: string;
    technicianComment: string;
    technicianName: string;
    technicianVerificationDate?: Date;
    emergency: boolean;
    bookingType: string;

    labSampleCollectionDetailId?: number;
    subSampleCollectedBy?: number;
    subCollectionDate?: Date;
    subBarcodeDate?: Date;
    subIsBarcodeGenerated: boolean;
    subBarcodeGeneratedBy?: number;
    sampleName: string;
    subSampleCollectedByName: string;
    subBarcodeGeneratedByName: string;
    subSampleCollectionLocation: string;
    noOfSamplesCollectText: string;
    noOfSamplesCollect: number;
    subStatus: string;
    appointmentId: number;
    encounterType: string;
    encryptedAppointmentId: string;
    paymentType: string;
    sampleUsage: string;
    active: boolean;
    roleId?: number;
    externalLabAgencyId: number;
    agencyName: string;
    isInternalLab: boolean;
    isExternalLab: boolean;
    agencyCode: string;
    amount: number;
    total: number;
    paymentStatus: string;
}
export class LabexcalRows {
    totalAmount: number
    amount: any
    receivedDate: any
}

export class AgencyBill {
    serialNo: string;
    agencyName: string;
    labAgencyBillingId: number;
    totalAmount: number;
    createdByName: string;
    externalLabTransferIds: string;
    createdDate: Date;
    modifiedByName: string;
    modifiedDate: Date;
    paymentStatus: boolean;
    active: boolean;
}