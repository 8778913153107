export class ProviderAvailability {
    providerAvailabilityId: number;
    providerId: number;
    date: Date;
    dayName: string;
    dateNumber: number;
    status?: string;
    from24HrsTime?: string;
    to24HrsTime?: string;
    from12HrsTime?: string;
    to12HrsTime?: string;
    availabilityEndDateTime: string;
    availabilityStartDateTime: string;
    availableDate: string;
    availableDay: string;
    consultationTypeId: number;
    sessionTypeId: number;
    consultationTypeName: string;
    duration: number;
    encryptedProviderId: number;
    endDate: string;
    endTime: string;
    freeFollowUpDays: number;
    freeFollowUpDaysLimit: number;
    locationId: number;
    optionalText: string;
    optionalText2: string;
    providerAvailabilitySlotId: number;
    providerAvailabilityVisitTypeId: number;
    providerName: string;
    specializationId: number;
    specializationName: string;
    startDate: string;
    startTime: string;
    value: string;
    visitTypeId: number;
    visitorName: string;
    leaveStartDate: string;
    leaveEndDate: string;
    leave: string;
    providerLeaveId: number;
    reason: string;
    noOfNewPatient: number;
    noOfOfflinePatient: number;
    locationName: string;
    breakTypeName: string;
    breakBlockName: string;
    breakTypeId: number;
    breakBlockId: number;
    specializationDuration: number;
    totalSlots: number;
    generalSlots: number;
    newPatientSlots: number;
    offlineSlots: number;
    providerBreakId: number;
    doctorWeekId: number;
    isOnline: boolean;
}
