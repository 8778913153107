import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { MenuService } from "../services";

@Directive({
    selector: "[menuButton]"
})
export class MenuButtonDirective implements OnInit {

    @Input() menuButton: string;
    constructor(
        private view: ViewContainerRef,
        private template: TemplateRef<any>,
        private menuService: MenuService
    ) {

    }

    ngOnInit(): void {
        if(this.menuService.isMenuButtonAllowed(this.menuButton)) {
            this.view.createEmbeddedView(this.template);
        } else {
            this.view.clear();
        }
    }
}