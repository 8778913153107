
export class ScanTest {
    scanTestMasterId: number;
    scanTestName: string;
    scanTestCode: string;
    description: string;
    duration: Date;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    totalItems: number;
    modifiedByName?: string;
    modifiedDate?: Date;
    locationIds: string;
    locationId: number;
    amount: number;
    type: string;
    chargeCategoryId: number;
    chargeCategoryName: string;
    LocationId: number;
    locationNames: string;
    modifiedBy: number;
    createdbyName: string;
    isAdded: boolean;
    id: number;
    value: string;
    isSelected: boolean;
    scanClassificationId: number;
    scanClassificationName: string;
    scanSubClassificationId: number;
    scanSubClassificationName: string;
    locationScanTestMasterMapId: number;
    charges: Array<ChargeModel>;
    createdByRole: string;
    modifiedByRole: string;
    isConsentFormRequired: boolean;
}
export class ChargeModel {
    chargeCategoryId: number;
    chargeCategoryName: string;
    amount: number;
    
}