import { LabTemplateDetail } from "./lab-template-detail.model";


export class LabTemplateHeader {
    labTemplateHeaderId: number;
    templateName: string;
    templateId: string;
    totalItems: number;
    createdBy: number;
    modifiedBy?: number;    
    createdByName: string;
    modifiedByName: string;
    createdDate: Date;
    modifiedDate?: Date;
    active: boolean;
    isLoading: boolean;
    componentCount: number;
    isMethod: boolean;
    methodText: string;
    isInterpretation: boolean;
    interpretationText: string;
    joined: string;

    components: Array<LabTemplateDetail>;
    createdByRole: string;
    modifiedByRole: string;
    constructor() {
        this.components = new Array<LabTemplateDetail>();
    }
}