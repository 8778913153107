export class DoctorRevenueReports {
    accountId: number;
    roleName: string;
    doctorName: string;
    appointmentAmount?: number;
    admissionAmount?: number;
    labAmount?: number;
    scanAmount?: number;
    pharmacyAmount?: number;
    total: number;
    providerId: number;

    appointmentCashTotal: number;
    appointmentCardTotal: number;
    appointmentUPITotal: number;
    appointmentOnlineTotal: number;
    appointmentChequeTotal: number;
    appointmentPaytmTotal: number;
    appointmentNotPaidTotal: number;
    appointmentOtherTotal: number;
    appointmentCardSwipeTotal: number;
    appointmentCardStandAloneTotal: number;
    appointmentCardUPITotal: number;
    appointmentCardGpayTotal: number;
    appointmentCashDrawerTotal: number;
    appointmentCashChequeTotal: number;
    appointmentCashDDTotal: number;
    appointmentWalletPaytmOfflineTotal: number;
    appointmentWalletPhonePeOfflineTotal: number;
    appointmentCashRemoteDepositTotal: number;
    appointmentWalletPaytmDQRTotal: number;


    admissionCashTotal: number;
    admissionCardTotal: number;
    admissionUPITotal: number;
    admissionOnlineTotal: number;
    admissionChequeTotal: number;
    admissionPaytmTotal: number;
    admissionNotPaidTotal: number;
    admissionOtherTotal: number;
    admissionCardSwipeTotal: number;
    admissionCardStandAloneTotal: number;
    admissionCardUPITotal: number;
    admissionCardGpayTotal: number;
    admissionCashDrawerTotal: number;
    admissionCashChequeTotal: number;
    admissionCashDDTotal: number;
    admissionWalletPaytmOfflineTotal: number;
    admissionWalletPhonePeOfflineTotal: number;
    admissionCashRemoteDepositTotal: number;
    admissionWalletPaytmDQRTotal: number;

    labCash: number;
    labCard: number;
    labUPI: number;
    labOnline: number;
    labCheque: number;
    labPaytm: number;
    labNotPaidTotal: number;
    labOtherTotal: number;
    labCardSwipeTotal: number;
    labCardStandAloneTotal: number;
    labCardUPITotal: number;
    labCardGpayTotal: number;
    labCashDrawerTotal: number;
    labCashChequeTotal: number;
    labCashDDTotal: number;
    labWalletPaytmOfflineTotal: number;
    labWalletPhonePeOfflineTotal: number;
    labCashRemoteDepositTotal: number;
    labWalletPaytmDQRTotal: number;

    scanCash: number;
    scanCard: number;
    scanUPI: number;
    scanOnline: number;
    scanCheque: number;
    scanPaytm: number;
    scanNotPaidTotal: number;
    scanOtherTotal: number;
    scanCardSwipeTotal: number;
    scanCardStandAloneTotal: number;
    scanCardUPITotal: number;
    scanCardGpayTotal: number;
    scanCashDrawerTotal: number;
    scanCashChequeTotal: number;
    scanCashDDTotal: number;
    scanWalletPaytmOfflineTotal: number;
    scanWalletPhonePeOfflineTotal: number;
    scanCashRemoteDepositTotal: number;
    scanWalletPaytmDQRTotal: number;

    pharmacySaleCash: number;
    pharmacySaleCard: number;
    pharmacySaleUPI: number;
    pharmacySaleOnline: number;
    pharmacySaleCheque: number;
    pharmacySalePaytm: number;
    pharmacySaleNotPaidTotal: number;
    pharmacySaleOtherTotal: number;
    pharmacySaleCardSwipeTotal: number;
    pharmacySaleCardStandAloneTotal: number;
    pharmacySaleUPITotal: number;
    pharmacySaleCardGpayTotal: number;
    pharmacySaleCashDrawerTotal: number;
    pharmacySaleCashChequeTotal: number;
    pharmacySaleCashDDTotal: number;
    pharmacySaleWalletPaytmOfflineTotal: number;
    pharmacySaleWalletPhonePeOfflineTotal: number;
    pharmacySaleCashRemoteDepositTotal: number;
    pharmacySaleWalletPaytmDQRTotal: number;

    totalCash: number;
    totalCard: number;
    totalUPI: number;
    totalOnline: number;
    totalCheque: number;
    totalPaytm: number;
    notPaidTotal: number;
    otherTotal: number;
    cardSwipeTotal: number;
    cardStandAloneTotal: number;
    uPITotal: number;
    cardGpayTotal: number;
    cashDrawerTotal: number;
    cashChequeTotal: number;
    cashDDTotal: number;
    walletPaytmOfflineTotal: number;
    walletPhonePeOfflineTotal: number;
    cashRemoteDepositTotal: number;
    walletPaytmDQRTotal: number;
}