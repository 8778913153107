import { Component, Input, ElementRef, ViewChild, AfterViewInit, Output, EventEmitter  } from "@angular/core";
import SignaturePad from 'signature_pad';

@Component({
  selector: "signature-widget",
  templateUrl: "./signature.widget.html",
  styleUrls: ["./signature.widget.css"]
})
export class SignatureWidget implements  AfterViewInit {
  
  signaturePad!: SignaturePad;
  @ViewChild('canvas', {static: true}) canvasEl!: ElementRef;
  isSaved!: boolean;

  @Input() width = 500;
  @Input() height = 200;
  @Output() done = new EventEmitter<string>();

  constructor() { }

  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    this.signaturePad.addEventListener("afterUpdateStroke", () => {
      this.isSaved = false;
    })
  }

  clearPad() {
    this.signaturePad.clear();
    this.done.emit(null);
    this.isSaved = false;
  }

  donePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.done.emit(base64Data);
    this.isSaved = true;
  }
}