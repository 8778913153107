import { FormControl } from "@angular/forms";
import { RegExHelper } from "@shared/helpers";

export class ZipcodeValidator {
    static isValid(control: FormControl): object | null {
        const value = control.value;
        if (!value || !control.parent) {
            return null;
        }

        const countryControl = control.parent.get("countryId");
        if (countryControl) {
            const countryId = countryControl.value;
            if (countryId) {
                if (!RegExHelper.numbersOnly.test(value)) {
                    return { 'invalidZipcode': true };
                }

                if(countryId === 1 &&  !/^\b\d{6}?\b$/.test(value)) {
                    return { 'invalidZipcode': true };
                }

                if(countryId === 2 &&  !/^\b\d{5}?\b$/.test(value)) {
                    return { 'invalidZipcode': true };
                }

                if(countryId === 3 &&  !/^\b\d{4}?\b$/.test(value)) {
                    return { 'invalidZipcode': true };
                }
            }

            return null;
        } else {
            if (!RegExHelper.numbersOnly.test(value) || !/^\b\d{6}(-\d{4})?\b$/.test(value)) {
                return { 'invalidZipcode': true };
            }

            return null;
        }
    }
}