export class AppointmentSupportLog {
    appointmentId: number;
    providerName: string;
    patientName: string;
    appointmentNo: string;
    appointmentDate: Date;
    appointmentTime: object;
    appointmentTimeString: string;
    appointmentSupportLogId: number;
    createdDate: Date;
    assigneeId: number;
    audio: string;
    video: string;
    document: string;
    invoice: string;
    comments: string;
    active: boolean;
    patientMobile: string;
    totalItems: number;
    assigneeName: string;
    roleName: string;
    patientAccountId: number;
    patientThumbnailUrl: string;
    expired: boolean;
    expiredAlt: boolean;
    callStatus: string;
    isOnline: boolean;
    loading: boolean;
}