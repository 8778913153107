import { Component, ViewEncapsulation, Input, Output, EventEmitter } from "@angular/core";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
    templateUrl: "./img-crop.html",
    styleUrls: ["./img-crop.css"],
    selector: "img-crop",
    encapsulation: ViewEncapsulation.None
})
export class ImgCropWidget {
    @Input() aspectRatio: number;
    @Input() resizeToWidth: number;
    @Input() image: string;

    @Output() croppedImageEmitter = new EventEmitter<string>();

    imageLoading: boolean;
    imageLoaded: boolean;
    imageLoadingFailed: boolean;
    imageChangedEvent: Event;
    croppedImage: string;

    constructor() {
        this.init();
    }

    private init() {
        this.imageLoading = false;
        this.imageLoadingFailed = false;
        this.imageLoaded = false;
        this.croppedImage = undefined;
        this.imageChangedEvent = undefined;
    }

    onFileChange(event: Event) {
        this.imageLoading = true;
        this.imageLoadingFailed = false;
        this.imageLoaded = false;
        this.imageChangedEvent = event;
    }

    onImageCropped(event: ImageCroppedEvent) {
        function blobToBase64(blob) {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(blob);
            });
        }
        blobToBase64(event.blob).then((base64) => {
            this.croppedImage = base64 as any;
            this.croppedImageEmitter.emit(base64 as any);
        });        
    }

    onImageLoaded() {
        this.imageLoading = false;
        this.imageLoadingFailed = false;
        this.imageLoaded = true;
    }

    onLoadImageFailed() {
        this.imageLoading = false;
        this.imageLoadingFailed = true;
        this.imageLoaded = false;
    }

    onRemoveImage() {
        this.image = undefined;
        this.init();
    }
}