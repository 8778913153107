export class LabLocationCharges {
    locationLabHeaderMapId: number;
    locationId: number;
    locationName: string;
    testCharge: number;
    inPatientCharge: number; 
    active: boolean;
    labHeaderId: number;
    charge: number;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: number;  
}