export class CancelAdmissionReport {
    admissionNo: string;
    providerName: string;
    patientId: number;
    patientName: string;
    admissionDate: Date; 
    admissionTime: string;
    age: string;
    gender: string;
    doctorUnits: string;
    cancellDate: Date;
    cancellBy: string;
    umrNo: number;
    mobile: number;
    cancelledDate?: Date;
    createdByName: string;
    admissionId: number;
    unitName: string;
    accountId?: number;
    name: string;
}

