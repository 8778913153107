import { FormControl } from "@angular/forms";
import { RegExHelper } from "@shared/helpers";

export class AgeValidator {
    static isValid(control: FormControl): object | null {
        const value = control.value;
        if (!value || !control.parent) {
            return null;
        }

        if (!RegExHelper.numbersOnly.test(value)) {
            return { 'invalidAge': true };
        }

        if (parseInt(value) < 18) {
            return { 'invalid': true };
        }

        return null;
    }
}