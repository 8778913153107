export class Coupon {
    couponId: number;
    couponCode: string;
    couponType: string;
    couponValue: number;
    validFrom: Date;
    validTo: Date;
    active: boolean;
    createdBy: number;
    createdDate?: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    allowedPerUser?: number;
    notAllowedProviderIds?: string;
    notAllowedPatientIds?: string;
    allowedForFirstAppointment: boolean;
    totalItems?: number;
    couponDiscountValue?: number;
    amountAfterDiscount?: number;
    description: string;
}
