export class InventoryPurchaseBill {
    inventoryPurchaseHeaderId: number;
    productName: string;
    batchNumber: string;
    expiryDate?: Date;
    quantity: number;
    free?: number;
    total: number;
    discountPerItem?: number;
    discount?: number;
    overallDiscount?: number;
    taxPerItem: number;
    taxAmount: number;
    purchaseRate: number;
    netAmount: number;
    billAmount: number;
    taxes: number;
    overallAmount: number;
    supplierName: string;
    createdByName: string;
    roleName: string;
    billNumber: string;
    billType: string;
    billDate: Date;
    supplierId: number;
}