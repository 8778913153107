export class BedBoardListView {
    admissionId: number;
    encryptedAdmissionId: string;
    departmentId: number;
    providerId: number;
    providerName: string;
    providerGender: string;
    providerAge?: number;
    providerThumbnailUrl: string;
    practiceName: string;
    patientId: number;
    encryptedPatientId: string;
    patientFamilyId?: number;
    patientAccountId: number;
    patientName: string;
    patientAge?: number;
    patientGender: string;
    patientMobile: string;
    patientEmail: string;
    patientThumbnailUrl: string;
    patientCountryId: number;
    patientCountryCode: string;
    visitType: string;
    roomId: number;
    bedId: number;
    wardId: number;
    bedNumber: string;
    roomName: string;
    wardName: string;
    surgeryTypeId: number;
    isMaternity: string;
    attendantName: string;
    attendantRelationWithPatient: string;
    attendantContactNo: string;
    admissionDate: Date;
    admissionTime: object;
    admissionTimeString: string;
    patientType: string;
    admissionNo: string;
    isDischarged: boolean;
    dischargeDate: Date;
    dischargeTime: object;
    dischargeTimeString: string;
    admissionNotes: string;
    couponId: number;
    amount: number;
    discount: number;
    currencySymbol: string;
    total: number;
    status: string;
    active: boolean;
    expired: boolean;
    createdBy: number;
    createdDate: Date;
    totalItems: number;
    encounterType: string;
    reason: string;
    paidAmount: number;
    finalAmount: number;
    paymentStatus: string;
    isFinalBill: boolean;
    umrNo: string;
    cancelling: boolean;
    pendingMedicines: number;
    progressReportId: number;
    //medicationInfo: MedicationInfo;
    followUpDaysForIp?: number;
    editExpectedDischargeDate: boolean;
    expectedDischargeDate: Date;
    expectedDischargeDateView: any;
    isUpdatingDischargeDate: boolean;
    // dischargedBy: number;
    dischargedByRole: string;
    dischargedByName: string;
    visitTypeId: number;
    patientPriorityId: number;
    locationId: number;
    locationName: string;
    bedStatusId: number;
    bedStatusName: string;
    swap: boolean;
}

