<div class="bg-white margin-left-14px margin-right-14px">
    <div class="border-soft-secondary border-left-0 border-right-0 border  justify-content-between bg-white">
        <div class="align-items-center d-flex gap5px headerHeight textColorEncounter white-space-nowrap">

            <div class="gap5px align-items-center d-flex">
                <div class="align-self-start avatar-sm">
                    <img *ngIf="appointment && appointment.patientThumbnailUrl" [src]="safe(appointment.patientThumbnailUrl)" class="rounded-circle avatar-sm" [alt]="appointment.patientName">
                    <span *ngIf="appointment && !appointment.patientThumbnailUrl" class="avatar-title rounded-circle bg-primary text-white" [textContent]="appointment.patientName | initials"></span>
                </div>
                <h4 class="font-16 white-space-nowrap"><a href="javascript:;" class="mb-0 mt-0  text-uppercase fontAt1366px text-primary" *ngIf="appointment && appointment.patientName">{{appointment.patientName}}</a></h4>
                <h4 class="font-16 white-space-nowrap">UMRNO:<a href="javascript:;" class="mb-0 mt-0  text-uppercase text-primary fontAt1366px" *ngIf="appointment && appointment.umrNo">{{appointment.umrNo}}</a></h4><br />

            </div>
            <div class="heading-color1 font-weight-bold" *ngIf="visit">
                <span class="font-17">Visit No:{{visit}}</span>
            </div>
            <div class="heading-color1 font-weight-bold">
                <span class="font-16" *ngIf="appointment && appointment.patientDateOfBirth">{{this.yearsfordisplay}} <sub>Y</sub> {{this.monthsfordisplay}} <sub>M</sub> {{this.daysfordisplay}} <sub>D</sub> </span>
            </div>

            <div class="heading-color1 font-weight-bold">
                <span class="font-17" *ngIf="measurements && measurements.lengthInCms != null">Height:</span>&nbsp;
                <span class="font-17" *ngIf="measurements && measurements.lengthInCms!=null" [textContent]="measurements.lengthInCms +' cms'"></span>
            </div>
            <div class="heading-color1 font-weight-bold">
                <span class="font-17" *ngIf="measurements && measurements.weightInKgs != null">Weight:</span>
                <span class="font-17" *ngIf="measurements && measurements.weightInKgs != null" [textContent]="measurements.weightInKgs +' kg'"> </span>
            </div>
            <div class="heading-color1 font-weight-bold" *ngIf="!timelineService.showBox">
                <span class="font-17" *ngIf="measurements && measurements.headCircumference!=null"> HC:</span>&nbsp;
                <span class="font-17" *ngIf="measurements && measurements.headCircumference!=null" [textContent]="measurements.headCircumference +' cms'"></span>
            </div>
            <div class="heading-color1 font-weight-bold" *ngIf="!timelineService.showBox">
                <span class="font-17" *ngIf="measurements && measurements.temparature!=null"> Temp:</span>&nbsp;
                <span class="font-17" *ngIf="measurements && measurements.temparature!=null" [textContent]="measurements.temparature +' F'"></span>
            </div>
            <div class="heading-color1 font-weight-bold" *ngIf="!timelineService.showBox">
                <span class="font-17" *ngIf="measurements && measurements.tcb!=null">TCB:</span>&nbsp;
                <span class="font-17" *ngIf="measurements && measurements.tcb!=null" [textContent]="measurements.tcb"></span>
            </div>

            <div class="d-flex gap5px h-100 p-1 align-items-center" *ngIf="appointment && appointment.providerName!==null">
                <div class="width30px">
                    <img class="w-100" src="assets/images/timelineSvg/encounter.svg" />
                </div>
                <div >
                    <h4 class="font-16 white-space-nowrap">Encounter by <a href="javascript:;" class="mb-0 mt-0  text-uppercase text-primary fontAt1366px">{{appointment.providerName}}</a> on &nbsp;<span class="text-primary fontAt1366px" [textContent]="appointment.appointmentDate|date:'dd/MM/yyyy'"></span><span class="text-primary fontAt1366px">&nbsp;{{appointment.appointmentTimeString}}</span></h4>
                </div>
            </div>

        </div>

        <div class="d-flex pt-1 pb-1">
            <div class="border d-flex encounterWedgets flex-grow-1 mx-1" >
                <div class="align-items-center justify-content-center d-flex w-100-px w-75p" *ngIf="!neonatalRisk">
                    <img class="w-75" src="../../../../assets/images/timelineSvg/riskfactor-c1.svg" />
                </div>
                <div class="flex-grow-1 m-1" *ngIf="!neonatalRisk">
                    <h5 class="heading-color1 mb-0" *ngIf="!neonatalRisk">Neonatal Risk Factor:</h5>
                    <div class="p-0 d-flex font-weight-medium w-100" *ngIf="roleId != 63 && roleId != 82">
                        <div *ngIf="!neonatalRisk">
                            <span class="line-height-20px white-space-nowrap heading-color1" style="font-size: medium;"><i class="rotatingExclamation mdi mdi-exclamation text-danger mr-1"></i>Section is not reviewed yet</span>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1 overflow-auto" *ngIf="neonatalRisk && neonatalRisk !== null">
                    <div class="d-flex justify-content-between align-items-center p-2px pl-0">
                        <h5 class="d-inline font-14 heading-color2 line-height-1 m-0 mx-1 white-space-nowrap">Neonatal Risk Factors</h5>

                    </div>
                    <div class="encounterWedgetsContent pr-1">
                        <div>
                            <div class="d-flex align-items-baseline font-weight-medium w-100">
                                <h6 class="white-space-nowrap heading-color3 m-0 mb-1 d-inline line-height-1 mx-1">Growth Status:</h6>
                                <div class=" dashboard-break font-14" *ngIf="neonatalRisk.growthStatus">
                                    <div>
                                        <span  [textContent]="neonatalRisk.growthStatus"></span>
                                    </div>
                                </div>
                                <span *ngIf="neonatalRisk.growthStatus && neonatalRisk.growthStatus1.length>0">-</span>
                                <div class="w-100 mb-0 dashboard-break font-14" *ngIf="neonatalRisk &&( neonatalRisk.growthStatus1.length > 0)">
                                    <div>
                                        <span *ngFor="let item of  neonatalRisk.growthStatus1; let i=index;">
                                            <span *ngIf="item" [textContent]="item"></span>
                                            <span *ngIf="i != neonatalRisk.growthStatus1.length - 1">, </span>
                                        </span>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div *ngIf="neonatalRisk && neonatalRisk.anySurgicalProcedure1.length>0">
                            <div class="d-flex align-items-baseline font-weight-medium w-100">
                                <h6 class="white-space-nowrap heading-color3 m-0 mb-1 d-inline line-height-1 mx-1">Any Surgical Procedure:</h6>
                                <div class=" dashboard-break font-14" *ngIf="neonatalRisk.anySurgicalProcedure">
                                    <div>
                                        <span  [textContent]="neonatalRisk.anySurgicalProcedure"></span>
                                    </div>
                                </div>
                                <span class="pr-1" *ngIf="neonatalRisk.anySurgicalProcedure && neonatalRisk.anySurgicalProcedure1.length>0">,</span>
                                <div>
                                    <span *ngFor="let item of  neonatalRisk.anySurgicalProcedure1; let i=index;">
                                        <span *ngIf="item" [textContent]="item"></span>
                                        <span class="pr-1" *ngIf="i != neonatalRisk.anySurgicalProcedure1.length - 1">,</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="neonatalRisk && (neonatalRisk.malFormations1 || neonatalRisk.malFormations)">
                            <div class="d-flex align-items-baseline font-weight-medium w-100">
                                <h6 class="white-space-nowrap heading-color3 m-0 mb-1 d-inline line-height-1 mx-1">MalFormations:</h6>
                                <div class="dashboard-break font-14" *ngIf="neonatalRisk.malFormations">
                                    <div>
                                        <span  [textContent]="neonatalRisk.malFormations"></span>
                                    </div>
                                </div>
                                <span class="pl-1" *ngIf="neonatalRisk.malFormations1 && neonatalRisk.malFormations">,</span>
                                <div class=" dashboard-break font-14" *ngIf="neonatalRisk.malFormations1">
                                    <div>
                                        <span class="pl-1" [textContent]="neonatalRisk.malFormations1"></span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 min-fit-width" *ngIf="!allergies ">
                <div class="d-flex border encounterWedgets">
                    <div class="align-items-center justify-content-center d-flex w-100-px w-75p">
                        <img class="w-75" src="../../../../assets/images/timelineSvg/allergies-c2.svg" />
                    </div>
                    <div class="flex-grow-1 m-1">
                        <h5 class="heading-color1 mb-0" *ngIf="!allergies">Allergies Sections:</h5>
                        <div class="p-0 d-flex font-weight-medium w-100" >
                            <div *ngIf="!allergies">
                                <span class="line-height-20px heading-color1" style="font-size: medium;"><i class="rotatingExclamation mdi mdi-exclamation text-danger mr-1"></i>Section is not reviewed yet</span><br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4" *ngIf="specialFeature && specialFeature.specialFeature">
                <div class="d-flex border encounterWedgets pr-1">
                    <div class="align-items-center justify-content-center d-flex w-100-px w-75p">
                        <img class="w-75" src="../../../../assets/images/timelineSvg/special-features-c1.svg" />
                    </div>
                    <div class="flex-grow-1" *ngIf="!specialFeature.specialFeature">
                        <h5 class="heading-color2 mb-0">Special Features:</h5>
                    </div>
                    <div class="flex-grow-1" *ngIf="specialFeature.specialFeature">
                        <h5 class="d-inline font-14 heading-color2 line-height-1 m-0 white-space-nowrap">Special Features:</h5>
                        <div class="encounterWedgetsContent pr-1 text-black font-14" [textContent]="specialFeature.specialFeature"></div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
