import { Role } from "@shared/enums";

export class PermissionHelper {
    constructor(private readonly roleId: number) { }

    get superAdmin() {
        return [Role.SuperAdmin].indexOf(this.roleId) > -1;
    }

    get admin() {
        return [Role.Administrator].indexOf(this.roleId) > -1;
    }

    get support() {
        return [Role.Support].indexOf(this.roleId) > -1;
    }

    get accountant() {
        return [Role.Accountant].indexOf(this.roleId) > -1;
    } 
    
    get symptomCreator() {
        return [Role.SymptomCreator].indexOf(this.roleId) > -1;
    }

    get symptomManager() {
        return [Role.SymptomManager].indexOf(this.roleId) > -1;
    }

    get all() {
        return [Role.Administrator, Role.Support].indexOf(this.roleId) > -1;
    }
}