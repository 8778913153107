export class ScanMachineAvailability {
    scanMachineAvailabilityId: number;
    scanMachineMasterId: number;
    locationId: number;
    roomName: string;
    practiceName: string;
    createdBy: number;
    createdByName: string;
    modifiedBy: number;
    ModifiedByName: string;
    availableDays: string;
    availability: string;
    machineName: string;
    displayName: string;
    durations: Array<duration>;
    duration: string;
    fromDate: string;
    toDate: string;
    endDate: string;
    startDate: string;
    fromTime: any;
    toTime: any;
    scanAvailabilityStatus: number;
    scanAvailabilityReason: number;
    scanAvailabilityStatusName: string;
    scanAvailabilityReasonName: string;
    scanWeekId: number;
}
class duration {
    typeId: number;
    value: string;
}