import { Patient } from "./patient.entity"

export class HealthCard {
    healthCardId: number;
    healthCardName: string;
    amount: number;
    allowMembers: number;
    validityType: any;
    duration: number;
    familyMembers: Array<Patient>;
    issueHealthCardId: number;
    issuedDate: Date;
    cardNumber: string;
    patientName: string;
    mobile: string;
    validTill: Date;
    isSelected: boolean = false;
    patientId: number;
    payTypeId: number;
    paymentNumber: string;
    modifiedByRole: string;
    createdByRole: string;
    constructor() {
        this.familyMembers = new Array<Patient>();
    }
}