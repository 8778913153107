export class InsuranceAdmission {
    insuranceForAdmissionId: number;
    insuranceApprovalId: number;
    patientInsuranceId: number;
    insuranceCompanyId: number;
    admissionId: number;
    expectedAmount: number;
    createdBy: number;
    modifiedBy?: number;
    expectedSettlementDate?: Date;
    createdDate: Date;
    modifiedDate?: Date;

    insuranceCompanyName: string;
    admissionNo: string;
    patientName: string;
    insuranceTypeName: string;
    gender: string;
    umrNo: string;
    thumbnailUrl: string;
    createdByName: string;
    createdByRole: string;
    modifiedByName: string;
    modifiedByRole: string;
    age: number;
    admissionDate: Date;
    totalItems: number;
    comment: string;
    attachments: string;
    files: Array<any>;
}