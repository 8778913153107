import { Component, Input } from "@angular/core";

@Component({
    selector: "symptoms-view",
    template: '<ul>' +
        '<li *ngFor="let node of symptomQuestions" (click)="checkFunction(node)">{{node.ChatBotMsg[0]}}' +
        '<ul>' +
        '<li *ngFor="let option of node.UserResponseOptions">{{option.Option}}' +
        '<symptoms-view [symptomQuestions]="option.IfSelected"></symptoms-view>' +
        '</li>' +
        '</ul>' +
        '</li>' +
        '</ul>'
})
export class SymptomsViewComponent {
    @Input() symptomQuestions: any;
    @Input() checkFunction: any;
}