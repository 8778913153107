export interface IViewModel {
    admissionId: number;
    fullName: string;
    age: string;
    gender: string;
    medicationsCount?: number;
    labsCount?: number;
    nurseNotesCount?: number;
    notesCount?: number;
    vitalsCount?: number;
    totalCount: number;
    nurseAssessment: string;
    bedNumber: string;
    roomName: string;
    wardName: string;
    floorName: string;
    locationId: number;
    encryptedAdmissionId: string;
    patientId: number;
    nurseAssessmentCount: number;
}

export enum MenuList {
    medications,
    vitals,
    labs,
    doctorNotes,
    nurseNotes,
    nurseAssessment
}

export enum ViewType {
    MyPatients,
    AllPatients
}