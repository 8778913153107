import { InitPaymentModel } from "../models/init-payment.model";
const INIT_PAYMENT_KEY = "initPayment";

export class InitPaymentHelper {
    static Get(): InitPaymentModel | null | undefined {
        let res = JSON.parse(localStorage.getItem(INIT_PAYMENT_KEY)) as InitPaymentModel | null | undefined;
        return res;
    }

    static Set(value: InitPaymentModel) {
        localStorage.setItem(INIT_PAYMENT_KEY, JSON.stringify(value));
    }

    static UpdateMultiPayments(value: boolean) {
        const data = this.Get();
        if (data) {
            data.isMultiPayments = value;
            this.Set(data)
        }
    }

    static UpdateModel(value: object) {
        let data = this.Get();
        if (data) {
            if (!data.data.finalModel) {
                data.data = {
                    finalModel: value
                };
            }
            else {
                data.data.finalModel = value;
            }
            this.Set(data)
        }
    }

    static UpdateIsFromNotification(value: boolean) {
        const data = this.Get();
        if (data) {
            data.isFromNotification = value;
            this.Set(data)
        }
    }

    static serializeFormData(formData: FormData): string {
        let data = {};
        for (var item of formData.entries()) {
            const [key, value] = item;
            try {
                const parsedData = JSON.parse(value.toString());
                data[key] = parsedData;
            } catch (error) {
                data[key] = value;
            }
        }
        return JSON.stringify(data);
    }
}