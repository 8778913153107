import { Component, OnInit, OnDestroy, Input, ViewChild, TemplateRef } from "@angular/core";
import { IUserAccount, Page, IResource } from "@shared/models";
import { HttpService, PrintOptionService, BannerService, ResourceService, AppData, NotifyService  } from "@shared/services";
import { ImageReports, Appointment } from "@shared/entities";
import { ApiResources } from "@shared/helpers";
import { finalize, takeUntil } from "rxjs/operators";
import { ScanBooking, PayTypes } from "@shared/entities";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

class TrackBy {
    resource(item: IResource) {
        return item.id;
    }
}

@Component({
    templateUrl: "./scan-invoice.html",
    styleUrls: ['./scan-invoice.widget.css'],
    selector: "scan-invoice",
})
   
export class SacnInvoiceWidget implements OnInit, OnDestroy { 
    @Input() appointmentId: number;
    @Input() isFooter: boolean;
    @Input() isPrintLogo: boolean;
    page: Page;
    loading: boolean;
    bannerBasics: ImageReports; 
    appointments: Array<Appointment>;
    ancNumber: string;
    currentDate: Date;
    loadingInvoice: boolean;
    selectedAppointment: ScanBooking;
    constructor(
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly bannerService: BannerService,
        private readonly appData: AppData,
        private readonly resourceService: ResourceService,
        private readonly modalService: NgbModal,
        private readonly notifyService: NotifyService,
    ) {
        this.page = new Page();
        this.appointments = new Array<Appointment>();
      
    }


    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                                    
                    this.currentDate = new Date();
                } else {
                    this.page.userAccount = undefined;
                }
              
            });    
        this.fetchInvoice(this.appointmentId);
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
    private fetchInvoice(bookScanAppointmentId?: number) {
        this.loadingInvoice = true;
        this.httpService.get<Array<ScanBooking>>(ApiResources.getURI(ApiResources.bookScanAppointment.base, ApiResources.bookScanAppointment.fetchSelectedAppointment), { bookScanAppointmentId: bookScanAppointmentId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingInvoice = false))
            .subscribe(
                (response: Array<ScanBooking>) => {
                    this.ancNumber = null;
                    this.fetchgetAncNumber(response[0].patientId);
                    this.selectedAppointment = new ScanBooking();
                    this.selectedAppointment.payTypes = new Array<PayTypes>();
                    this.selectedAppointment = response[0];
                    this.selectedAppointment.payTypes = response[0].payTypes;
                });

    }
    private fetchgetAncNumber(patientId) {
        this.resourceService.getAncNumber(patientId)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                if (response && response.length > 0) {
                    this.ancNumber = response[0].value;
                }
            });
    }
    
    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
}
