export class PatientServicesLog {
    patientServicesLogId: number;
    auditLogId: number;
    labLogId: number;
    scanLogId: number;
    logDescription: string;
    logDate: string;
    logTypeId: number;
    logFrom: number;
    logTypeName: string;
    AccountId: number;
    fullName: string;
    roleName: string;
    totalItems: number;
    roleId: number;
    locationId: number;

}
