export class LabParameterGroup {
    labParameterDetailId: number;
    labParameterHeaderId: number;
    parameterName: string;
    gender: string;
    fromAge?: number;
    fromAgeType: string;
    toAge?: number;
    toAgeType: string;
    minValue: number;
    maxValue: number;
    minCriticalValue: number;
    maxCriticalValue: number;
    unitId?: number;
    unitName: string;
    rangeText: string;
    selectionName: string;
    lisValue: string;
    priority?: number;
}