import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "avatar-img",
    encapsulation: ViewEncapsulation.None,
    template: `
    <img [hidden]="loading" (load)="onImageLoad()" (error)="onImageError()" [alt]="alt" [class]="cssClass" [src]="src" />
    <div *ngIf="loading" class="ph-item ph-loading m-0 ph-avatar-loading">
        <div class="ph-avatar mb-0 h-100"></div>
    </div>
    `
})
export class AvatarImageComponent {
    @Input() src: string;
    @Input() alt: string;
    @Input() cssClass: string;
    bindingSrc: string;

    loading: boolean;

    constructor() {
        this.loading = true;
    }

    onImageLoad() {
        this.loading = false;
    }

    onImageError() {
        this.loading = false;
        this.src = location.origin + location.pathname + "assets/images/broken.png";
    }
}