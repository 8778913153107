import { Pipe, PipeTransform } from "@angular/core";

function formatBytes(a: number, b: number) { if (0 === a) return "0 Bytes"; const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024)); return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d] }

@Pipe({
    name: "formatBytes"
})
export class FormatBytesPipe implements PipeTransform {
    transform(size: number, length = 2) {
        if (!size) {
            return "";
        }

        return formatBytes(size, length);
    }
}