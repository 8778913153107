export class IPProvisionReport {
    admissionNo: string;
    providerName: string;
    patientId: number;
    patientName: string;
    admissionDate: Date; 
    admissionTime: string;
    patientAge: string;
    patientGender: string;
    doctorUnits: string;
    umrNo: number;
    patientMobile: number;
    admissionId: number;
    unitName: string;
    accountId?: number;
    name: string;
    admissionVisitType: string;
    admissionType: string;
    referralDoctor: string;
    department: string;
    locationId: number;
    cashType: string;
    createdByName: string;
    departmentName: string;
}

