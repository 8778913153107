export class LabReportDaily {
    labName: string;
    patientName: string;
    umrNo: string;
    mobile: string;
    amount: number;
    grandTotal: number;
    doctorName: string;
    labBookingHeaderId: number;
    patientId: number;
    createdBy: number;
    billNumber: string;
    createdByName: string;
    createdDate: Date | string;
    bookingDate: Date;
    discountPercentage?: number;
    netAmount: number;
    discountAmount?: number;
    overallDiscountAmount?: number;
    overallNetAmount: number;
    labPackageId?: number | string;
    age?: number | string;
    gender?: string;
    paidVia: string;
    accountId: number;
    payTypeName: string;
    paymentNumber: string;
    providerId: number;
    requisitionNumber: string;
    overallTotalAmount: number;
    totalAmount: number;
}