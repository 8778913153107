export class PharmacyBillReport {
    pharmacySaleHeaderId: number
    billNumber: string
    saleDate: Date | string;
    patientName: number | string;
    providerName: number | string;
    totalAmount: number;
    totalItems: number;
    patientMobile: string;
    umrNo: string;
    saleType: string;
    saleReturnHeaderId?: number;
    paidVia: string;
    patientId?: number;
    overallTaxes: number;
    dueDate: Date;
    retailName: string;
    paymentNumber: string;
    typeOfPayment: string;
    paidAmount: number;
    receiptDate: Date | string;
    appointmentId: number;
}