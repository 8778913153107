export class AppointmentTransaction {
    appointmentTransactionId: number;
    appointmentId: number;
    encryptedAppointmentId: string;
    appointmentNo: string;
    couponId?: number;
    amount: number;
    discount: number;
    total: number;
    transactionId: string;
    transaction: string;
    transactionDate: Date;
    bankReference: string;
    bankCode: string;
    currencySymbol: string;
    patientCountryId: number;
    patientCountryName: string;
    patientCountryCode: string;
    patientName: string;
    providerName: string;
    active?: boolean;
    totalItems: number;
    settledAmount: number;
    tdsAmount: number;
    paymentGateway: string;
    paymentStatus: string;
    appCharges: number;
    paymentId: number;
    email: string;
    year: number;
    week: number;
    weekTotal: number;
    appointmentDate: Date;
    appointmentTime: string;
    providerPercentage: number;
    payout: string;
    payoutId: number;
    gst: number;
    tds: number;
    sumFinalAmount: number;
    finalAmount: number;
    payUFees: number;
    afterAppCharges: number;
    sumTotal: number;
    minTotal: number;
    maxTotal: number;
    sumAfterAppCharges: number;
}