import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, NavigationStart, Router, Event as RouterEvent } from "@angular/router";
import { NgbDatepickerConfig, NgbTooltipConfig, } from "@ng-bootstrap/ng-bootstrap";
import { ApiResources } from "@shared/helpers";
import { Page } from "@shared/models";
import { AppData, HttpService, IdentityService, TimelineToggleService } from "@shared/services";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-root",
    template: `
        <ngx-loading-bar [color]="'#3283f6'"></ngx-loading-bar>
        <router-outlet></router-outlet>
        <div *ngIf="showServerInfo && serverInfo" class="server-info-modal">
            <div class="server-info-modal-container">
                <span class="server-info-modal-close" (click)="closeServerInfoModal()">&times;</span>
                <div class="server-info-modal-content">
                    <p><span>Server</span><span [textContent]="serverInfo?.serverName || 'N/A'"></span>
                    <p><span>Version</span><span [textContent]="serverInfo?.version || 'N/A'"></span>
                </div>
            </div>
        </div>
        <div *ngIf="showServerInfo" class="server-info-modal-background" (click)="closeServerInfoModal()"></div>
    `
})
export class AppComponent implements OnInit, OnDestroy {
    page: Page;
    showServerInfo: boolean = false;
    serverInfo: { serverName: string, version: string };

    constructor(
        datePickerConfig: NgbDatepickerConfig,
        toolTipConfig: NgbTooltipConfig,
        private readonly router: Router,
        private readonly titleService: Title,
        private readonly identityService: IdentityService,
        private readonly httpService: HttpService,
        private readonly appData: AppData,
        public timelineService: TimelineToggleService
    ) {
        this.page = new Page();
        datePickerConfig.outsideDays = "collapsed";
        datePickerConfig.navigation = "select";
        toolTipConfig.container = "body";
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.altKey && event.key === 'i') {
            this.showServerInfo = true;
        }
    }

    ngOnInit() {
        this.getServerInfo();
        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationStart) {
                $("body,html").animate({ scrollTop: 0 });
            }

            if (event instanceof NavigationEnd) {
                let root = this.router.routerState.snapshot.root;
                if (this.router.url.indexOf("/encounter/") > -1) {
                    $("#titleEncounter").show();
                    $("#titleInternalMedicine").hide();
                    $("#titlePatient").hide();
                    $("#navAppointments").addClass("selected");
                    $("#navPatients").removeClass("selected");
                }
                else if (this.router.url.indexOf("/internal-medicine/") > -1 || this.router.url.indexOf("/behavioral-health/") > -1) {
                    $("#titleEncounter").hide();
                    $("#titlePatient").hide();
                    $("#titleInternalMedicine").show();
                    $("#navAppointments").addClass("selected");
                    $("#navPatients").removeClass("selected");
                }
                else if (this.router.url.indexOf("/patient/") > -1) {
                    $("#titleEncounter").hide();
                    $("#titleInternalMedicine").hide();
                    $("#titlePatient").show();
                    $("#navAppointments").removeClass("selected");
                    $("#navPatients").addClass("selected");
                } else {
                    $("#titleEncounter").hide();
                    $("#titleInternalMedicine").hide();
                    $("#titlePatient").hide();
                    $("#navAppointments").removeClass("selected");
                    $("#navPatients").removeClass("selected");
                }

                while (root) {
                    if (root.children && root.children.length) {
                        root = root.children[0];
                    } else if (root.data && root.data["title"]) {

                        let showSidebar: boolean;
                        let showTimeline: boolean;
                        try {
                            showSidebar = root.parent.data["showSidebar"] || this.router.url.indexOf("/internal-medicine/") > -1;
                            showTimeline = root.parent.data["showTimeline"] || this.router.url.indexOf("/internal-medicine/") > -1;
                        } catch (e) {
                            showSidebar = false;
                            showTimeline = false;
                        }

                        $("body").attr("data-sidebar-size", (showSidebar || this.router.url.indexOf("app") === -1) ? "default" : "condensed");
                        $("body").attr("data-timeline-size", ((showTimeline && this.timelineService.showBox == true) || this.router.url.indexOf("app") === -1) ? "timeline" : "timelineabsent");

                        this.titleService.setTitle(root.data["title"] + " | Careaxes");
                        return;
                    } else {
                        return;
                    }
                }
            }
        });

        this.identityService.get().subscribe(userAccount => {
            if (userAccount) {
                this.appData.setAccount(userAccount);
            }
        });
    }

    closeServerInfoModal(): void {
        this.showServerInfo = false;
    }

    ngOnDestroy(): void {
        this.page.unSubscribe.next(null);
        this.page.unSubscribe.complete();
    }

    private getServerInfo(): void {
        this.httpService
            .get(ApiResources.getURI(ApiResources.application.base, ApiResources.application.version))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: { serverName: string, version: string }) => {
                this.serverInfo = response;
            });
    }
}