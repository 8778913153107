export class ReportConfig {
    reportConfigId:number
    name: string;
    active: boolean;
    settingsName: string;
    header: string;
    footer: string;
    locationId: string;
    lactionName: string;
    settingsId: number
    modifyBy: string;
    modifyDate: string;
    locationMapIds: string;
    locationNames: string;
} 
export class ReportConfigName {
    reportName: string
    reportLookUp:number
}


