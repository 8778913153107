export class IndentInventoryPurchaseBillReports {
    billDate?: Date | string;
    wareHouseName: string; //inventory warehousename
    purchaseBillNo: string; //billnumber
    supplierName: string;
    billType: string;  //paytype
    billAmount: number;

    quantity: number;
    free: number;
    purchaseRate: number;
    discount: number;
    taxes: number;
    netAmount: number;
    total: number;
    discountPerItem?: number;
    overallDiscount?: number;
    taxPerItem: number;
    taxAmount: number;
    overallAmount: number;
    productName: string;
    billNumber: string;
    createdDate: Date;
    createdByName: string;

    returnDate: string;
    ReturnAmount: number;
    dueDate?: Date | string;

}