import { LabInPackageBooking } from "./lab-in-package-booking.entity";

export class BookingLabDetail {
    labBookingDetailId: number;
    labBookingHeaderId: number;
    labHeaderId?: number;
    labPackageId?: number;
    discountAmount: number;
    amount: number;
    status: string;
    labName: string;
    packageName: string;
    isReportGenerated?: boolean;
    reportDate?: Date;
    netAmount: number;
    discountPercentage?: number;
    labsInPackage: Array<LabInPackageBooking>;
    isSampleCollected?: boolean;
    sampleDate?: Date;
    typedByName: string;
    verifiedByName: string;
    billNumber: string;
    createdByName: string;
    isSendingSMS: boolean;
    isDeleteEligible: boolean;
    patientId:number;
    patientName: string;
    doctorName: string;
     totalItems: number;
    umrNo: string;

    barcode: string;
    departmentName: string;
    isSelected: boolean;
    
  

    collectedSampleReceivedBy: string;
    sampleReceivedDate: Date;
    verifiedByDoctorName: string

    constructor() {
        this.labsInPackage = new Array<LabInPackageBooking>();
    }
}