<div *ngIf="image">
    <div class="row">
        <div class="col-md-5">
            <figure class="position-relative">
                <avatar-img *ngIf="image" [src]="image" alt="Profile Image" cssClass="rounded w-100"></avatar-img>
                <figcaption class="position-absolute t-0 r-0 d-flex justify-content-end">
                    <a href="javascript:;" class="btn btn-danger btn-icon" (click)="onRemoveImage()"><i class="fe-x"></i></a>
                </figcaption>
            </figure>
        </div>
    </div>
</div>
<div *ngIf="!image">
    <ng-container *ngIf="!imageLoading && !imageLoaded">
        <div class="form-group img-crop-upload">
            <i class="fe-upload-cloud"></i>
            <p>Choose a profile image below</p>
            <div class="custom-file">
                <input type="file" class="custom-file-input" id="choose-file" [accept]="'image/jpeg, image/pjpeg, image/png, image/gif'" [ngClass]="{ 'is-invalid': !imageLoading && imageLoadingFailed }" (change)="onFileChange($event)">
                <label class="custom-file-label" data-browse="Browse" for="choose-file">Choose your file</label>
            </div>
            <div class="invalid-feedback show-must" *ngIf="!imageLoading && imageLoadingFailed">Invalid file chosen or there is a problem with file.<br />Please select another image and try again.</div>
        </div>
    </ng-container>
    <div class="row" *ngIf="imageLoading">
        <div class="col-sm-12">
            <div class="d-flex py-2 align-items-center">
                <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                <span class="ml-2">Please wait while loading Image ...</span>
            </div>
        </div>
    </div>
    <div class="row" [class.image-cropper-no-visible]="!croppedImage">
        <div class="col-md-9">
            <image-cropper class="image-cropper"
                           [maintainAspectRatio]="true"
                           [aspectRatio]="aspectRatio"
                           [resizeToWidth]="resizeToWidth"
                           [cropperMinWidth]="resizeToWidth"
                           [cropperMinHeight]="resizeToWidth"
                           [initialStepSize]="50"
                           format="jpg"
                           [imageChangedEvent]="imageChangedEvent"
                           (imageCropped)="onImageCropped($event)"
                           (cropperReady)="onImageLoaded()"
                           (loadImageFailed)="onLoadImageFailed()"></image-cropper>
        </div>
        <div class="col-md-3" *ngIf="croppedImage">
            <figure class="position-relative">
                <img alt="Profile Image" class="rounded" style="width: 100%" [src]="croppedImage" />
                <figcaption class="position-absolute t-0 r-0 d-flex justify-content-end">
                    <a href="javascript:;" class="btn btn-danger btn-icon" (click)="onRemoveImage()"><i class="fe-x"></i></a>
                </figcaption>
            </figure>
        </div>
    </div>
</div>
