export interface ILabAsync {
    id: number;
    name: string;
    code: string;
    cost: number;
    notes: string;
    createdByName: string;
    createdDate: Date;
}

export class Lab {
    labBookingHeaderId: number;
    id: number;  //LabHeaderId
    name: string;
    code: string;
    instructions: string;
    cost: number;

    status: LabStatusType;
    date: Date;
    createdDate: Date;
    createdByName: string;
    verifiedByName: string;
    isSampleCollected: boolean;
    isReportGenerated: boolean;
    verifiedBy: number;

    when?: any;

    labBookingDetailId: number;
    isParameterAdded: boolean;

    salutation: string;
    fullName: string;
    age: number;
    gender: string;
    Umrno: string;
    patientImage: string;
    notes: string;
}

export enum LabStatusType {
    NotCollected = 1,
    Collected = 2,
    NotVerified = 3,
    Verified = 4
}


export interface INewLabAsync {
    labMainDetailId: number;
    testName: string;
    testCode: string;
    amount: number;
    notes: string;
    createdByName: string;
    createdDate: Date;
}

export class NewLab {
    newLabBookingHeaderId: number;
    labMainDetailId: number;  //labMainDetailId
    testName: string;
    testCode: string;
    instructions: string;
    amount: number;

    status: LabStatusType;
    date: Date;
    createdDate: Date;
    createdByName: string;
    verifiedByName: string;
    isSampleCollected: boolean;
    isReportGenerated: boolean;
    verifiedBy: number;

    when?: any;

    newLabBookingDetailId: number;
    isParameterAdded: boolean;

    salutation: string;
    fullName: string;
    age: number;
    gender: string;
    Umrno: string;
    patientImage: string;
    notes: string;
    admissionId: number;
    patientLabDetailId: number;
    isAccepted: boolean;
    patientLabHeaderId: number;
    patientId: number;
    isAbnormal: boolean;
    encryptedNewLabBookingDetailId: string;
}
