export class RegExHelper {
    static email = /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,10}$/;
    static emailFormat = /^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$/;
    static length10 = /^[A-Za-z\d$@$!%*?&]{10,}/;
    static mobile = /^\b\d{10}?\b$/;
    static npi = /^\b\d{10}?\b$/;
    static oneUpperCase = /^(?=.*[A-Z])/;
    static oneLowerCase = /^(?=.*[a-z])/;
    static oneNumber = /^(?=.*\d)/;
    static oneSpecialChar = /^(?=.*[$@$!%*?&])/;
    static numbersOnly = /^[0-9]+$/;
    static textOnly = /^[a-zA-Z ]+$/;
    static titleOnly = /^[a-zA-Z .,'-/]+$/;
    static alphaNumeric = /^[a-zA-Z0-9 ]+$/;
    static decimalsOnly = /^(100|([0-9][0-9]?(\.\d?\d)?))$/;
    static twoDecimalsOnly = /^[0-9]{0,3}(\.{0,1})([0-9]{1,2})?$/;
    static whiteSpace = /^[\s]{1,}|[\s]{2,}|[\s]{1,}$/;    
    static sp = /^[a-zA-z"]{1,}\((([a-zA-z"]{1,} (bigint|int8|boolean|bool|varchar|integer|int|int4|smallint|int2|float8|float4|text|date){1,}){0,}(,){0,}( ){0,}([a-zA-z"]{1,} (bigint|int8|boolean|bool|varchar|integer|int|int4|smallint|int2|float8|float4|text|date){1,})){0,}\)$/;
}