export class ProviderLocation {
    providerLocationId: number;
    providerId: number;
    practiceLocationId: number;
    practiceLocationName: string;
    practiceId: number;
    practiceName: number;
    practiceTIN: string;
    currencySymbol: string;
    consultationDuration?: number;
    consultationCharges?: number;
    telemedicineDuration?: number;
    telemedicineCharges?: number;
    availableDays: string;
    availability: string;
}