import { Component, ViewEncapsulation, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { UtilHelper } from "@shared/helpers";

@Component({
    templateUrl: "./file-dropper.html",
    selector: "file-dropper",
    styleUrls: ["./file-dropper.css"],
    encapsulation: ViewEncapsulation.None
})
export class FileDropperWidget implements OnInit {
    @Input() maxFiles: number;
    @Input() maxFileSize: number;
    @Input() accept: string;
    @Input() expandable: boolean;

    @Output() onSelectEmitter = new EventEmitter<Array<File>>();

    files: Array<File>;
    rejectedFiles: Array<File>;
    rejectedReason: string;

    ngOnInit() {
        this.files = [];
        this.rejectedFiles = [];
        this.rejectedReason = null;
    }

    onSelect(event) {
        this.files.push(...event.addedFiles);
        this.onSelectEmitter.emit(this.files);

        this.rejectedFiles.push(...event.rejectedFiles);
        if (this.rejectedFiles.length > 0) {
            this.rejectedReason = this.rejectedFiles.map(m => m["reason"]).filter(UtilHelper.unique).join(" and ");
        } else {
            this.rejectedReason = null;
        }
    }

    onRemove(file) {
        this.rejectedReason = null;
        const index = this.files.indexOf(file);
        this.files.splice(index, 1);
    }
}