<div class="sidebar-main-menu bg-pattern lgSubMenu   addedLeft pt-1" style="background-size: 200%; background-repeat: no-repeat;">
  <div id="two-col-menu" class="h-100" data-simplebar>
    <div class="twocolumn-menu-item d-block">
      <ng-container *ngIf="record">
        <div class="d-flex align-items-center justify-content-center flex-column">
          <div class="avatar-md">
            <avatar-img *ngIf="record.thumbnailUrl" [src]="record.thumbnailUrl" [alt]="record.fullName" cssClass="img-fluid rounded-circle"></avatar-img>
            <span *ngIf="!record.thumbnailUrl" class="avatar-title rounded-circle bg-primary font-24 text-white" [textContent]="record.fullName | initials"></span>
          </div>
          <h5 class="mb-0">
            <a href="javascript:;" class="text-dark">
              <span class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="record.fullName"></span>
            </a>
          </h5>
          <small class="d-block font-13 mt-1x" [textContent]="record.umrNo"></small>
          <h6 class="mb-0">
            <a href="javascript:;" class="text-dark">
              <span [textContent]="'(' + record.gender+ ') '"></span>
              <span [textContent]="record.age + 'yrs'"></span>
            </a>
          </h6>
          <h6 class="mb-0">
            <a href="javascript:;" class="text-dark">
              <span class="text-uppercase" [textContent]="'Dr. ' + record.providerName + ', '"></span>
              <span [textContent]="'(' + record.providerGender+ ')'"></span>
            </a>
          </h6>
          <h6 class="mt-2 mb-0 text-muted" [textContent]="record.departmentName"></h6>
          <div class="mb-2">
            <span class="badge badge-success" *ngIf="isAdmission">In Patient</span>
            <span class="badge badge-warning" *ngIf="!isAdmission">Out Patient</span>
          </div>
        </div>
        <div>
          <div class="overflow-auto">
            <table class="table table-centered table-bordered table-sm mb-0">
              <tbody>
                <tr>
                  <th class="small">
                    <span *ngIf="isAdmission">Admission No</span>
                    <span *ngIf="!isAdmission">Appointment No</span>
                  </th>
                  <td class="small" [textContent]="record.admissionNo"></td>
                </tr>
                <tr>
                  <th class="small">Date</th>
                  <td class="small" [textContent]="record.admissionDate | date: 'dd-MM-yyyy'"></td>
                </tr>
                <tr>
                  <th class="small">Time</th>
                  <td class="small">
                    <span *ngIf="isAdmission" [textContent]="record.admissionDate | date: 'h:mm:ss a'"></span>
                    <span *ngIf="!isAdmission" [textContent]="record.timeString"></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
      <h5 class="menu-title mb-2 text-primary text-center">Navigation</h5>
      <ul class="nav flex-column encounter-menu">
        <li class="nav-item" *ngFor="let menu of menus">
          <a class="dropdown-item" [ngClass]="{'flex-space-between' : menu.subPage !== 'timeline'}"
             [routerLink]="menu.subPage"
             [class.active]="activeRoute === menu.subPage"
             href="javascript:;">

            <ng-container *ngIf="menu.subPage === 'timeline'">
              <i *ngIf="menu.iconClasses" class="{{menu.iconClasses}} mr-1"></i>
              <span class="text-capitalize" [textContent]="menu.displayName"></span>
            </ng-container>
            <ng-container *ngIf="menu.subPage !== 'timeline'">
              <span><i [class]="'mr-1 ' + menu.iconClasses"></i> <span class="text-capitalize" [textContent]="menu.displayName"></span></span>
              <span *ngIf="!countsLoading" [ngClass]="{'badge-light' : activeRoute === menu.subPage, 'badge-primary': activeRoute !== menu.subPage}" class="badge mr-3">
                <span *ngIf="(menu.displayName | lowercase) == 'medication'" [textContent]="counts.medicationCount"></span>
                <span *ngIf="(menu.displayName | lowercase) == 'labs'" [textContent]="counts.labCount"></span>
                <span *ngIf="(menu.displayName | lowercase) == 'doctor notes'" [textContent]="counts.notesCount"></span>
              </span>
              <span *ngIf="countsLoading" class="spinner-grow spinner-grow-sm mr-3"></span>
            </ng-container>

          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<router-outlet></router-outlet>