import { Component, OnDestroy, OnInit, Input } from "@angular/core";
import { UtilHelper, ApiResources } from "@shared/helpers";
import { Setting } from "../../entities";
import { HttpService, PrintOptionService } from "../../services";
import { Page } from "../../models";


@Component({
    template: `<div *ngIf ="isPrintLogo" class="text-center pt-2">
<img  [src]="url != null ? url :''" alt="Careaxes" style="max-height:70px; max-width:100%;" />
</div>`,
    selector: "footer-setting"   
})

//can be use in these two ways .
    //<banner-setting> </banner-setting>
//<banner-setting [settingName]=" 'nameofsetting'" > </banner-setting>

export class FooterSettingWidget implements OnInit, OnDestroy{

    @Input() settingName: any;
    page: Page;
    myImgUrl: string = "assets/images/careaxesLogo.png";
    url: string;
    isPrintLogo: boolean;
    constructor(
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService
    ) {
        this.page = new Page();
    }
    getFooterImage() {
        const request = {
            active: true
        };
        if (this.settingName!=null){
            request["name"] = this.settingName //specific fetch with name .("this type of Setting Record must have IMAGE to fetch")
        }
        else {
            request["type"]= "Footer";// default fetch for banner setting whoes 'Type is Footer ,in Setting '
        }
        this.httpService
            .get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), request, true)
            .subscribe(
                (response: Array<Setting>) => {
                    if (response && response.length > 0) {
                        if (UtilHelper.isEmpty(response[0].imageUrl)) {
                            response[0].imageUrl = `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${response[0].imageUrl}`;
                            this.url = response[0].imageUrl;
                        }
                    }
                },
                () => {
                    this.url = null;
                }
            );
    }

    ngOnInit() {      
        this.printOptionService.get((is) => { this.isPrintLogo = is; });
        this.getFooterImage();
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
}