export class VideoCallHistory {
    videoCallHistoryId: number;
    appointmentId: number;
    startDate: Date;
    endDate: Date;
    duration: string;
    callStatusTypeId: number;
    reason: string;
    callerDeviceId: string;
    receiverDeviceId: string;
    doctorFullName: string;
    patientFullName: string;
    appointDate: string;
    appointmentTime: string;
}