export class PharmacyStockReport {
    categoryName: string;
    productName: string;
    batchNumber: string;
    expiryDate: Date;
    purchaseQuantity: number;
    avialableQuantity: number;
    saleQuantity: number;
    returnQuantity: number;
    currentStock: number;
    totalItems: number;
    categoryId: number;
}