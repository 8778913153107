export class PharmacyRawPurchase {
    productName: string;
    barcode: string;
    supplierName: string;
    billNumber: string;
    purchaseRate: string;
    mrp: string;
    quantity: string;
    free: string;
    batchNumber: string;
    expiryDate: string;
    sgst: string;
    igst: string;
}