import { TagDoctors } from "@shared/entities"


export class DoctorUnits {
    doctorUnitMasterId: number;
    unitName: string;
    code: string;
    locationId: number;
    departmentId: number;
    location: string;
    department: string;
    createdByName: string;
    createdDate: string;
    modifiedByName: string;
    modifiedDate: string;
    active: boolean;
    tagDoctor: Array<TagDoctors>;
    createdByRole: string;
    modifiedByRole: string;
}
