export class Vitals {
    appointmentId: number;
    appointmentDate: string;
    appointmentTime: string;
    vital: Vital;
    bp: BloodPressure;
    vitals: string;
}

class Vital {
    height: string;
    weight: string;
    weightMsrmtMob: string;
    heightMsrmtMob: string;
    bmi: string;
    bmiMsrmt: string;
    weightMsrmt: string;
    temperature: string;
    hr: string;
    hrMsrmt: string;
    respiratory: string;
    respirationMsrmt: string;
    pulseOximetry: string;
    pulseMsrmt: string;
    systolicBp: string;
    systolicBpMsrmt: string;
    diastolicBp: string;
    diastolicBpMsrmt: string;
    randomGlucose: string;
    hba1c: string;
    fastingGlucose: string;
    t4: string;
    t3: string;
    tsh: string;
}

class BloodPressure {
    systolicBp: string;
    systolicBpMsrmt: string;
    diastolicBp: string;
    diastolicBpMsrmt: string;
}