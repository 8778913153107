export class PharmacyLog {
    pharmacyLogId: number;
    logDescription: string;
    logDate: string;
    pharmacyLogTypeId: number;
    logFrom:number;  
    logTypeName:string;
    AccountId:number;
    fullName:string;
    roleName:string;
    totalItems: number;
    previousData: string;
    currentData: string;
}
