import { Data } from "@angular/router";

export class Timeline {
    timelineId: string;
    appointmentNo: string;
    appointmentId: number;
    admissionId: number;
    timelineActionName: string;
    timelineActionId: number;
    description: string;
    createdByName: string;
    fullName: string;
    data: string;
    showCase: string;
    createdDate: Date;
    timelineDataId: number;
    viewLoading: boolean;
    totalItems: number;
    providerName: string;
    specializationName: string;
    visitorName: string;
    documentName: string;
    documentType: string;
    appointmentDate: Date;
    encounterName: string;
    uploadedByName: string;
    uploadedDate: Data;
    encryptedAppointmentId: string;
    encryptedAdmissionId: string;
    eventID: number;
    newLabBookingDetailId: number;
    encryptedNewLabBookingDetailId: string;
    scanDocumentId: number;
    patientId: number;
    encryptedPatientId: string;
    otRegisterId: number;
    encounterType: string;
}