export class PayTypes {
    payTypeId: number;
    payTypeName: string;
    isActive: boolean;
    payTypeValue: string;
    salucroPayType: boolean;
    createdBy: number;
    loginRoleId: number;
    createdByName: string;
    modifiedBy: number;
    modifiedByName: string;
    createdDate: Date;
    amountPaid: number;
    receiptTypeId: number;
    receiptNo: string;
    receiptId?: number;
    paymentDetails: string;
    paymentNumber: string;
}