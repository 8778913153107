<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <ng-container *ngIf="admission">
                            <button *ngIf="!admission?.isDischarged" (click)="openModifyModel(templateAdd)" class="btn btn-primary btn-sm">
                                <i class="mdi mdi-note-plus-outline mr-1"></i>Add New Note
                            </button>

                        </ng-container>
                        <button *ngIf="swap == true" (click)="openModifyModel(templateAdd)" class="btn btn-primary btn-sm">
                            <i class="mdi mdi-note-plus-outline mr-1"></i>Add New Note
                        </button>
                        <!--<a *ngIf=" swap != true" href="javascript:;" class="btn btn-secondary btn-sm ml-1" (click)="onBack()">
                            <span><i class="fe-arrow-left mr-1"></i> Back</span>
                        </a>-->
                        <button *ngIf="swap" type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
                    </div>
                    <h4 class="page-title">Doctor Notes</h4>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 mb-4">
                <div class="overflow-auto">
                    <table class="table table-centered table-sm table-striped table-bordered mb-0">
                        <thead class="table-primary">
                            <tr>
                                <th style="width:50px;">#</th>
                                <th>Note</th>
                                <th width="110">Date</th>
                                <th [ngStyle]="{'width.px': widths.createdBy}">Created By</th>
                                <th class="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="loading">
                                <tr>
                                    <td colspan="8">
                                        <span class="mr-1 spinner-grow spinner-grow-sm"></span> Please wait while loading doctor notes ...
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!loading && !records.length">
                                <tr>
                                    <td colspan="8">
                                        <i class="mdi mdi-alert text-warning mr-1"></i> Doctor Notes does not exists.
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!loading && records.length">
                                <ng-container *ngFor="let item of records; let i = index;">
                                    <tr>
                                        <td [textContent]="(i + 1)"></td>
                                        <td>
                                            <div class="d-flex">
                                                <div class="mr-2"><img alt="lab service" [src]="imgOrigin + 'assets/images/days/note.png'" width="28" /></div>
                                                <div [textContent]="item.note"></div>
                                            </div>
                                        </td>
                                        <td [textContent]="(item.date || item.createdDate) | date:'dd-MM-yyyy'"></td>
                                        <td>
                                            <div>
                                                <div class="media">
                                                    <div class="media-body">
                                                        <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.createdByName"></h5>
                                                        <span class="d-block font-12 mt-1">
                                                            <small [textContent]="item.createdDate | date:'dd-MM-yyyy, hh:mm a'"></small>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex align-items-center justify-content-end">
                                                <a class="action-icon text-primary mr-1" href="javascript:;" (click)="openModifyModel(templateAdd, item)" ngbtooltip="Edit Package"><i class="mdi mdi-pencil"></i></a>
                                                <a class="action-icon text-danger" href="javascript:;" (click)="delete(item)" placement="left" ngbTooltip="Delete">
                                                    <i *ngIf="!item.deleting" class="fe-trash-2"></i>
                                                    <span *ngIf="item.deleting" class="spinner-grow spinner-grow-sm"></span>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</div>

<ng-template #templateAdd>
    <form [formGroup]="addForm" (submit)="modify()">
        <div class="modal-header">
            <h4 class="modal-title">
                <i class="mdi mdi-note-plus-outline mr-1"></i>
                <span *ngIf="!selected">Add</span>
                <span *ngIf="selected">Update</span>
                Note
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group position-relative mb-0">
                                <label>Date <span class="text-danger">* <span *ngIf="selected" class="font-italic">(read-only)</span></span></label>
                                <input class="form-control date-picker" type="text" block [minDate]="minDate"
                                       formControlName="date" autocomplete="nope" placeholder="Select date"
                                       [ngClass]="{ 'is-invalid': submitted && form.date.errors }"
                                       ngbDatepicker #date="ngbDatepicker"
                                       (click)="date.toggle(); $event.stopPropagation();"
                                       [value]="form.date.value | date: 'dd/MM/yyyy'" />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group position-relative mb-0">
                                <span class="px-2"><input type="checkbox" formControlName="isUnitDoctor" />Unit Doctor</span>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group position-relative mb-0">
                                <label>Doctor</label>
                                <ng-select class="ng-select-sm text-uppercase w-100" [items]="providers"
                                           [loading]="loadProvider"
                                           bindLabel="value"
                                           bindValue="id"
                                           autocomplete="nope"
                                           formControlName="providerId"
                                           (change)="changeProvider($event)"
                                           [ngClass]="{ 'is-invalid': submitted && form.providerId.errors }"
                                           [hideSelected]="true"
                                           placeholder="Select Doctor">
                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                        <div class="ng-option disabled">
                                            No Doctor found <span *ngIf="searchTerm">for '{{searchTerm}}'</span>
                                        </div>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-i="index">
                                        <div class="media">
                                            <div class="avatar-xs mr-1">
                                                <span class="avatar-title rounded-circle font-11 font-weight-bold bg-soft-warning text-primary">
                                                    <i class="fe-map-pin"></i>
                                                </span>
                                            </div>
                                            <div class="media-body">
                                                <h5 class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="item.value"></h5>
                                                <span class="d-block font-13" [textContent]="item.optionalText4 || '-----'"></span>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Note<code>*</code></label>
                        <textarea placeholder="Doctor Notes" rows="6" formControlName="note" class="form-control"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
            <button type="submit" class="btn btn-primary btn-sm">
                <span *ngIf="submitting"><span class="spinner-grow spinner-grow-sm mr-1"></span> please wait ...</span>
                <span *ngIf="!submitting"><i class="fe-plus mr-1" placement="right"></i>Submit</span>
            </button>
        </div>
    </form>
</ng-template>
