import { Directive, HostListener, ElementRef } from "@angular/core";

@Directive({
    selector: "[password]"
})
export class PasswordDirective {
    @HostListener("click") onClick(){
        if (!$(this.element.nativeElement).hasClass("show-password")) {
            $(this.element.nativeElement).siblings("input").attr("type", "text");
            $(this.element.nativeElement).addClass("show-password");
        } else {
            $(this.element.nativeElement).siblings("input").attr("type", "password");
            $(this.element.nativeElement).removeClass("show-password");
        }
    }

    constructor(public element: ElementRef) {
    }
}