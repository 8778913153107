import { Counselling, CounsellingPackage } from "../models";

export enum CounsellingViewType {
    ViewMode = 1,
    AddMode = 2,
    ReadOnlyMode = 3,
}

export enum AdmitType {
    All = 1,
    Appointment = 2,
    Admission = 2,
}

export interface CounsellingWithPackages {
    counselling: Counselling;
    packages: Array<CounsellingPackage>;
}