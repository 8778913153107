import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class RedirectAppointmentService {
    private source = new BehaviorSubject(null);
    account: Observable<string> = this.source.asObservable();
    set(accountId: string) {
        this.source.next(accountId);
    }
}
