
export class ScanSubClassification {
    scanSubClassificationId: number;
    scanClassificationId: number;
    scanClassificationName: string;
    scanSubClassificationName: string;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    totalItems: number;
    modifiedByName: string;
    modifiedDate: Date;
    modifiedBy: number;
    createdbyName: string;
    modifiedByRole: string;
    createdByRole: string;
}
