import { ActiveStatus } from "../../shared/helper";

export class ProgressReportDiet {
    progressReportDietId: number;
    duration: number;
    active: boolean;
    createdDate: Date;
    createdByName: string;
    modifiedDate: Date;
    modifiedByName: string;
    progressReportId: number;
    instructions: string;
    stopReason: string;
    startDate: Date;
    endDate: Date;
    mealTypeId: number;
    medicationDurationTypeId: number;
    mealType: string;
    activeStatus: ActiveStatus;
    mealInstruction: string;
    dietInstructions: string;
    isMorning: boolean;
    isAfternoon: boolean;
    isNight: boolean;
    dietFrequency: Array<DietFrequency>;
}
export class DietFrequency {
    dietInstructionTypeId: number;
    dietInstructionDeepTypeId: number;
    dietInstructionName: string;
    progressReportdietFrequencyId: string;
    dietDate: Date;
    status: boolean

}
