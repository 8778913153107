
import { Component,Input,Output, ViewEncapsulation, TemplateRef, EventEmitter } from "@angular/core";
import { AdmissionFetchHelper } from "../progress-report/shared/helper";
import { IUserAccount, Page, IMenuModel, ICategoryMenuModel, IResource } from "../../../shared/models";
import { Router, Params, RouterEvent, NavigationEnd, ActivatedRoute } from "@angular/router";
import { IAdmissionModel } from "../services/models/admission.model";
import { AppData } from "../../../app.data";
import { MenuService, ResourceService } from "../../../shared/services";
import { MenuType } from "../../../shared/enums";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { finalize, takeUntil } from "rxjs/operators";
import { OnInit } from "@angular/core";
import { HttpService, NotifyService } from "@shared/services";
import { ApiResources } from "@shared/helpers";
import { SaveConsentForms } from "../../../shared/entities/save-consent-forms.entity";


@Component({
    templateUrl: "./consent-forms.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls:["./consent-forms.css"]
})
export class ConsentFromPage implements OnInit {
    @Output() onClose = new EventEmitter<any>();
    page: Page;
    admissionId: string;
    isAdmission: boolean;
    record: IAdmissionModel;
    activeRoute: string;
    menus: Array<IMenuModel>;
    menuType = MenuType;
    patientId: any;
    loadingConsentForms: boolean;
    consentForms: Array<IResource>;
    modalRef: NgbModalRef;
    consentId: number;
    loading: boolean;
    saveConsents: Array<SaveConsentForms>;
    selectedConsentFormId: number;

    constructor(
        private readonly admissionFetchHelper: AdmissionFetchHelper,
        private readonly router: Router,
        private readonly appData: AppData,
        private readonly route: ActivatedRoute,
        private readonly menuService: MenuService,
        private readonly modalService: NgbModal,
        private readonly resourceService: ResourceService,
        private readonly httpService: HttpService,

    ) {
        let allSubMenus = this.menuService.menus(MenuType.SubMenu, "Consent Form");
        var onlySubMenus = allSubMenus.filter(x => x.menuTypeId === MenuType.SubMenu ||
            (x.menuTypeId === MenuType.CategoryMenu && x.category && x.category === x.displayName));
        onlySubMenus.filter(x => x.menuTypeId === MenuType.CategoryMenu).forEach(x => {
            x.categoryMenus = allSubMenus.filter(y => y.menuTypeId === MenuType.CategoryMenu && y.category === x.category && y.displayName !== x.displayName)
                .map((y, i) => ({
                    id: i,
                    subPage: y.subPage,
                    displayName: y.displayName,
                    iconClasses: y.iconClasses,
                    url: y.url,
                    priority: y.priority
                } as ICategoryMenuModel));
        });

        this.menus = onlySubMenus;
        this.page = new Page();
    }

    change(menuSubPage?: string, subPage?: string) { }

    fetch = () => {
        this.admissionFetchHelper.admissionFetch(+this.admissionId, this.isAdmission, (data: IAdmissionModel) => {
            this.record = data;
        });
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            this.admissionId = params["id"];
                            this.patientId = params["pid"];
                            this.isAdmission = params["type"] === "a";
                            const url = this.router.url;
                            this.activeRoute = url.split("/")[url.split("/").length - 1];
                            this.fetch();
                        });

                    this.router.events
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((event:any= RouterEvent) => {
                            if (event instanceof NavigationEnd) {
                                const url = this.router.url;
                                this.activeRoute = url.split("/")[url.split("/").length - 1];
                            }
                        });
                } else {
                    this.page.userAccount = undefined;
                }
                this.fetchSaveConsents()
            });
    }

    onOpenModal(content: TemplateRef<any>): void {
        this.consentId = null
        this.modalRef = this.modalService.open(content, {
            backdrop: "static", keyboard: false, centered: true, size: "xl", windowClass: "custom-modal effect-scale"
        });
        this.fetchConsentForms();
    }
    private fetchConsentForms(): void {
        this.loadingConsentForms = true;
        this.resourceService.consentForms()
            .pipe(finalize(() => this.loadingConsentForms = false), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (response: Array<IResource>) => {
                    if (response.length > 0) {
                        this.consentForms = response;
                        let consents = new Array<number>();
                        const modules = this.consentForms.filter(m => consents.includes(m.id));
                        this.consentForms = modules;
                    }
                },
                error: () => this.consentForms = []
            });
    }

    fetchSaveConsents() {
        this.loading = true;
        const request = {
        };
        this.httpService.post(ApiResources.getURI(ApiResources.saveConsentForms.base, ApiResources.saveConsentForms.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<SaveConsentForms>) => {
                    if (response.length > 0) {
                        this.saveConsents = response;
                    }
                },
            );
    }

    onSelect(event: Event): void {
        const selectElement = event.target as HTMLSelectElement;
        this.selectedConsentFormId = Number(selectElement.value);
        console.log(this.selectedConsentFormId);
    }
}