export class ModuleMaster {
    modulesMasterId: number;
    moduleName: string;
    moduleIcon: string;
    moduleDescription: string;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
    createdByName: string;
    modifiedByName: string;
    packageType: string;
    isChargeCategoryApplicable: boolean;
    isDoctorChargeCategoryApplicable: boolean;
    isPackageApplicable: boolean;
    loginRoleId: number;
    locationId: number;
    createdByRole: string;
    modifiedByRole: string;
}
