export class OTRoomBooking {
    otRegisterId?: number;
    surgeryId?: number;
    providerLocationId: number;
    providerName: string;
    providerId: number;
    availableDays: string;
    day: string;
    duration: string;
    fromTime: string;
    toTime: string;
    fromDate: string;
    toDate: string;
    patientId: number;
    patient: string;
    checkedIn: string;
    checkedOut: string;
    departmentId: number;
    departmentName: string;
    slotTat: string;
    slotDuration: string;
    availability: string;
    otRoomId: string;
    roomName: string;
    appointmentStartDateTime: string;
    appointmentEndDateTime: string;
    status: string;
    umrno: string;
    gender: string;
    doctorName: string;
    mobile: string;
    fullAddress: string;
    paymentNumber: string;
    fatherOrHusband: string;
    age: string;
    appointmentDate: string;
    payTypeName: string;
    paymentType: string;
    appointmentTime: string;
    surgeryName: string;
    createdDate: string;
    createdByName: string;
    displayName: string;
    signInDate: string;    
    chargeCategoryName: string;
    amount: number;
    paymentStatus: boolean;
    locationId: number;
}