import { RetailStore } from "./retail-store.entity";

export class WareHouseStore {
    pharmacyWareHouseId: number;
    wareHouseName: string;
    createdByName: string;
    createdByRole: string;
    modifiedByName: string;
    modifiedByRole: string;
    createdBy: number;
    modifiedBy?: number
    createdDate: Date;
    modifiedDate?: Date;
    allowedAccountId: string;
    allowedUsers: Array<AllowedAccount>;
    active: boolean;
    retailStoreCount: number;
    locationId?: number;
    centralWarehouseLocationId?: number;
    centralWarehouseLocation: string;
    retailStore: Array<RetailStore>;

    inventoryWareHouseId: number;
    name: string;
    locationName: string;
    status: boolean;
    isUploaded: boolean;

    constructor() {
        this.allowedUsers = new Array<AllowedAccount>();
        this.retailStore = new Array<RetailStore>();
    }
}

export class AllowedAccount {
    moduleDepartmentUserId: number;
    accountId: number;
    pharmacyWareHouseId: number;
    fullName: string;
    roleName: string;
    pharmacyDepartmentUserId: number;
    pharmacyDepartmentId: number;
}
