export class PatientFeedback {    
    patientFeedbackId?: number;
    appointmentId?: number;
    careQuality?: number;
    treatmentEffectiveness?: number;
    diagnosticEfficiency?: number;
    doctorPatientInteraction: boolean;
    medicationAndTreatmentClarity: boolean;
    advice: string;
    createdBy?: number;
    createdDate: Date;
    patientName: string;
    provider: string;
    appointmentDate?: Date;
    totalItems: number;
    monthCount: number;
    last24HoursCount: number;
    profileImageUrl: string;
    thumbnailUrl: string;

}