import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { takeUntil, finalize } from "rxjs/operators";
import { IUserAccount, Page, GenericResponse, GenericStatus, IResource } from "@shared/models";
import { AppData, HttpService, NotifyService, ResourceService } from "@shared/services";
import { ApiResources, LinqHelper, } from "../../../../../shared/helpers";
import { INurseModel, DateHolder, Bed } from "../../helpers/helper";
import { NgbCalendar, NgbDate, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { IAvailableNurse, IDateDetails, IDetails, ISelected, IShiftBasics, IView } from "./local.helper";

export enum ViewType {
    Day = 1,
    Week = 2
}

@Component({
    templateUrl: "./nurse-bed-view.html",
    styleUrls: ['../../nurse-module.css']
})
export class NurseBedViewPage implements OnInit, OnDestroy {
    page: Page;
    loading: boolean;
    dates: Array<DateHolder>;
    records: Array<INurseModel>;

    contentLoading: boolean;
    viewType = ViewType;
    currentViewType: ViewType;
    availableSpace: number;
    noOfDatesToDisplay = 6;
    mainForm: FormGroup;
    availableForm: FormGroup;
    locations: Array<IResource>;
    locationSubscription: Subscription;
    dataRecords: Array<IView>;

    floorsFilter: Array<string>;
    wardsFilter: Array<string>;
    roomsFilter: Array<string>;
    originalBeds: Array<Bed>;
    filterToday: NgbDate;
    formattedFilterToday: Date;
    shifts: Array<IDetails>;
    shiftsOnly: Array<IDetails>;
    shiftMocks: Array<number>;
    modalRef: NgbModalRef;
    selected: ISelected;
    availableNurses: Array<IAvailableNurse>;
    loadingNurse: boolean;
    globalLoading: boolean;
    convertedFDate: Date;
    selectedNurse: IAvailableNurse;
    selectedShift: IDetails;
    submitting: boolean;
    dateModels: any;
    dateDisplay: string;
    
    constructor(
        private readonly httpService: HttpService,
        private readonly appData: AppData,
        private readonly calendar: NgbCalendar,
        private readonly notifyService: NotifyService,
        private readonly formBuilder: FormBuilder,
        private readonly resourceService: ResourceService,
        private readonly modalService: NgbModal,
    ) {
        this.availableSpace = window.innerHeight - 589;
        this.records = new Array<INurseModel>();
        this.dataRecords = new Array<IView>();
        this.locations = new Array<IResource>();
        this.availableNurses = new Array<IAvailableNurse>();
        this.loading = true;
        this.page = new Page();
        
        this.shifts = new Array<IDetails>();
        this.shiftMocks = new Array<number>();
        this.currentViewType = ViewType.Week;
        this.setDayTodayDates()
        this.setWeekTodayDates()
        this.buildForm()
        this.buildAvailableForm()
    }

    setDayTodayDates = () => {
        this.filterToday = this.calendar.getToday();
        this.formattedFilterToday = new Date(this.filterToday.year, this.filterToday.month - 1, this.filterToday.day, 0, 0, 0);
    }

    setWeekTodayDates = () => {
        this.dates = new Array<DateHolder>();

        let initialDate = new Date();
        initialDate.setDate(initialDate.getDate() + (1 - (initialDate.getDay() || 7)));
        var date = {year: initialDate.getFullYear(), month: initialDate.getMonth() + 1, day: initialDate.getDate()} as NgbDate;
        
        this.dates.push({
            date: date,
            eDate: window.btoa(JSON.stringify({ year: date.year, month: date.month, day: date.day })),
            fDate: new Date(date.year, date.month -1, date.day)
        } as DateHolder);

        Array.from(Array(this.noOfDatesToDisplay)).forEach(() => {
            var date = this.calendar.getNext(this.dates[this.dates.length - 1].date);
            this.dates.push({
                date: date,
                eDate: window.btoa(JSON.stringify({ year: date.year, month: date.month, day: date.day })),
                fDate: new Date(date.year, date.month - 1, date.day),
                isToday: date.equals(this.calendar.getToday())
            } as DateHolder);
        });
    }

    buildAvailableForm = () => {
        this.availableForm = this.formBuilder.group({
            accounts: new FormArray([])
        });
    }

    buildForm = () => {
        var floorName = new FormControl(null);
        var wardName = new FormControl(null);
        var roomName = new FormControl(null);
        this.mainForm = this.formBuilder.group({
            locationId: [],
            floorName, wardName, roomName
        });

        this.locationSubscription = this.mainForm.get("locationId").valueChanges.subscribe((id: number) => {
            this.fetch(id);
        });

        floorName.valueChanges.subscribe(z => {
            if (!z) {
                this.mainForm.patchValue({
                    wardName: null, roomName: null
                }, { emitEvent: false });
                return;
            }
            this.wardsFilter = this.originalBeds.filter(x => x.floorName === z).map(x => x.wardName).filter(LinqHelper.uniqueOnly);
            this.roomsFilter = this.originalBeds.filter(x => x.floorName === z).map(x => x.roomName).filter(LinqHelper.uniqueOnly);
        });

        wardName.valueChanges.subscribe(z => {
            if (!z) {
                this.mainForm.patchValue({
                    roomName: null,
                }, { emitEvent: false });
                return;
            }
            this.floorsFilter = this.originalBeds.filter(x => x.wardName === z).map(x => x.floorName).filter(LinqHelper.uniqueOnly);
            this.roomsFilter = this.originalBeds.filter(x => x.wardName === z).map(x => x.roomName).filter(LinqHelper.uniqueOnly);
        });

        roomName.valueChanges.subscribe(z => {
            this.floorsFilter = this.originalBeds.filter(x => x.roomName === z).map(x => x.floorName).filter(LinqHelper.uniqueOnly);
            this.wardsFilter = this.originalBeds.filter(x => x.roomName === z).map(x => x.wardName).filter(LinqHelper.uniqueOnly);
        });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }

    fetchShifts = (callback?: Function) => {
        this.loading = true;
        this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.nurseModule.base, ApiResources.nurseModule.fetchShiftsBasics),
            {}
        )
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false; }))
            .subscribe((response: GenericResponse) => {
                if (response.status === GenericStatus[GenericStatus.Success]) {
                    let shifts = (response.data as Array<IShiftBasics>).map(x => {
                        let startTime = +x.startTime.split(":")[0];
                        let endTime = +x.endTime.split(":")[0];
                        return {
                            nurseName: null,
                            shiftName: x.name,
                            startTime,
                            endTime,
                            shiftCode: x.name[0].toUpperCase(),
                            colspan: endTime - startTime,
                            shiftId: x.shiftId
                        };
                    }) as Array<IDetails>;

                    shifts.sort((a,b) => a.startTime - b.startTime);
                    let tempShifts = [...shifts];
                    if(tempShifts.length) {
                        let counter = -1;
                        let start = tempShifts[0].startTime;
                        for(;;) {
                            ++counter;
                            this.shiftMocks.push(start + counter);
                            if(counter >= 23) {
                                break;
                            }
                        }
                    }
                    for(let i = 0; i < tempShifts.length; i++) {
                        let a = tempShifts[i];
                        if(a.shiftName) {
                            let shiftRef = shifts.find(x => x.shiftName === a.shiftName);
                            if(shiftRef) {
                                let endTime = shiftRef.endTime < shiftRef.startTime ? (24 + shiftRef.endTime): shiftRef.endTime;
                                shiftRef.colspan = endTime - shiftRef.startTime;
                            }
                        }
                        if(!tempShifts[i + 1]) {
                            shifts.push({
                                nurseName: null,
                                shiftName: null,
                                startTime: -1,
                                endTime: -1,
                                colspan: this.shiftMocks[this.shiftMocks.length - 1] - a.endTime,
                                shiftId: a.shiftId
                            });
                            break;
                        }
                        let b = tempShifts[i + 1];
                        let aIndex = shifts.findIndex(aa => aa.shiftName === a.shiftName);
                        if(a.endTime != b.startTime) {
                            shifts.splice(aIndex + 1, 0, {
                                nurseName: null,
                                shiftName: null,
                                startTime: -1,
                                endTime: -1,
                                colspan: b.startTime - a.endTime,
                                shiftId: b.shiftId
                            });
                        }
                    }
                    this.shifts = shifts;
                    this.shiftsOnly = [...shifts.filter(x => !!x.shiftName)];
                    callback && callback();
                }
            });
    }

    fetchAll = (locationId: number) => {
        this.fetchBeds(locationId);
        this.fetch(locationId);
    }

    fetchBeds = (locationId: number) => {
        this.loading = true;
        this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.inpatientsView.base, ApiResources.inpatientsView.fetchBeds),
            { locationId: locationId || this.mainForm.get("locationId").value }
        )
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false; }))
            .subscribe((response: GenericResponse) => {
                if (response.status === GenericStatus[GenericStatus.Success]) {
                    var data = response.data as Array<Bed>;
                    //data.forEach((x: Bed) => {
                    //    x.floorName = LinqHelper.stuffIn(x.floorName, WordHelper.floorName);
                    //    x.wardName = LinqHelper.stuffIn(x.wardName, WordHelper.wardName);
                    //    x.roomName = LinqHelper.stuffIn(x.roomName, WordHelper.roomName);
                    //})
                    this.floorsFilter = data.map(x => x.floorName).filter(LinqHelper.uniqueOnly);
                    this.wardsFilter = data.map(x => x.wardName).filter(LinqHelper.uniqueOnly);
                    this.roomsFilter = data.map(x => x.roomName).filter(LinqHelper.uniqueOnly);
                    this.originalBeds = data;
                } else {
                    this.notifyService.defaultError();
                }
            });
    }

    resetFilter = () => {
        this.floorsFilter = this.originalBeds.map(x => x.floorName).filter(LinqHelper.uniqueOnly);
        this.wardsFilter = this.originalBeds.map(x => x.wardName).filter(LinqHelper.uniqueOnly);
        this.roomsFilter = this.originalBeds.map(x => x.roomName).filter(LinqHelper.uniqueOnly);
        this.mainForm.patchValue({
            floorName: null,
            wardName: null,
            roomName: null
        }, {emitEvent: false});
        this.fetch();
    }

    ngOnInit() {
        this.fetchLocations();
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.globalLoading = true;
                    this.mainForm.patchValue({
                        locationId: this.page.userAccount.locationId
                    }, { emitEvent: false });
                    this.fetchShifts(() => {
                        this.fetchAll(this.page.userAccount.locationId);
                    });
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    changeViewType = (type: ViewType) => {
        this.currentViewType = type;
        this.globalLoading = true;
        this.fetch();
    }

    private fetchLocations() {
        this.resourceService.locations()
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.locations = response;
            });
    }

    get isTodayExists() {
        return !!this.dates.find(x => x.isToday)
    }

    next = () => {
        if(this.currentViewType === ViewType.Day) {
            this.filterToday = this.calendar.getNext(this.filterToday);
            this.formattedFilterToday = new Date(this.filterToday.year, this.filterToday.month - 1, this.filterToday.day, 0, 0, 0);
        } else {
            var lastDate = this.dates[this.dates.length - 1].date;
            this.dates = new Array<DateHolder>();
            Array.from(Array(this.noOfDatesToDisplay + 1)).forEach(() => {
                var date = this.dates.length > 0
                    ? this.calendar.getNext(this.dates[this.dates.length - 1].date)
                    : this.calendar.getNext(lastDate);
                this.dates.push({
                    date: date,
                    eDate: window.btoa(JSON.stringify({ year: date.year, month: date.month, day: date.day })),
                    fDate: new Date(date.year, date.month - 1, date.day),
                    isToday: date.equals(this.calendar.getToday())
                } as DateHolder);
            });
        }
       
        this.fetch();
    }

    goToToday = () => {
        if(this.currentViewType === ViewType.Day) {
            this.setDayTodayDates()
        } else {
            this.setWeekTodayDates()
        }
       this.fetch();
    }

    previous = () => {
        if(this.currentViewType === ViewType.Day) {
            this.filterToday = this.calendar.getPrev(this.filterToday);
            this.formattedFilterToday = new Date(this.filterToday.year, this.filterToday.month - 1, this.filterToday.day, 0, 0, 0);
        } else {
            var firstDate = this.dates[0].date;
            this.dates = new Array<DateHolder>();
            Array.from(Array(this.noOfDatesToDisplay + 1)).forEach(() => {
                var date = this.dates.length > 0
                    ? this.calendar.getNext(this.dates[this.dates.length - 1].date)
                    : this.calendar.getPrev(firstDate, 'd', this.noOfDatesToDisplay + 1);
                this.dates.push({
                    date: date,
                    eDate: window.btoa(JSON.stringify({ year: date.year, month: date.month, day: date.day })),
                    fDate: new Date(date.year, date.month - 1, date.day),
                    isToday: date.equals(this.calendar.getToday())
                } as DateHolder);
            });
        }
        this.fetch();
    }

    convertNgbDateToDate(ngbDate: NgbDate): string {
        const jsDate = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);

        return jsDate.toString();
    }
  

    assignNurse = (content: TemplateRef<any>, dataIndex: number, dateIndex: number, detailIndex: number) => {
        let dataRecord = this.dataRecords[dataIndex];
        let dateModel = dataRecord.dates[dateIndex];
        this.dateModels = dateModel.date.date ? dateModel.date.date : dateModel.date;
        let dateDisplay = dateModel.date.date ? dateModel.date.date : dateModel.date;
        this.dateDisplay = this.convertNgbDateToDate(dateDisplay);
        let detailModel = dateModel.details[detailIndex] as IDetails;
        let shiftName = this.shiftsOnly.find(x => x.shiftCode[0].toLowerCase() === detailModel.shiftCode[0].toLowerCase()).shiftName;
        this. selectedShift = this.shiftsOnly.find(x => x.shiftCode[0].toLowerCase() === detailModel.shiftCode[0].toLowerCase());

        this.selected = {
            date: dateModel.date.date, shiftName, bedId: dataRecord.bedId, bedName: dataRecord.bedName, fDate: dateModel.date
        }
        
        setTimeout(() => {
            this.modalRef = this.modalService.open(content, {
                backdrop: "static",
                keyboard: false,
                centered: true,
                size: "xl",
                windowClass: "custom-modal slots-modal effect-scale"
            });
        });
        this.fetchNurse();
    }

    close = () => {
        this.modalRef.close()
        this.selected = null;
    }

    fetchNurse = () => {
      
        let data = {
            ...this.selected,
            locationId: this.mainForm.value.locationId
        };
        this.loadingNurse = true;
        this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.nurseModule.base, ApiResources.nurseModule.fetchAvailableNurse), data)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingNurse = false))
            .subscribe((response: GenericResponse) => {
                if(response.status === GenericStatus[GenericStatus.Success]) {
                    var formArray = this.availableForm.get("accounts") as FormArray;
                    formArray.clear();
                    let availableNurses = response.data as Array<IAvailableNurse>;
                    availableNurses.sort((a,b) => a.fullName.localeCompare(b.fullName));
                    availableNurses.forEach(x => {
                        formArray.push(new FormGroup({
                            id: new FormControl(x.accountId),
                            is: new FormControl(false),
                        }));
                    });
                    this.availableNurses = availableNurses;
                }
            });
    }

    fetch(locationId: number = null) {
        var data = {
            ...this.mainForm.value,
            fromDate: this.currentViewType === ViewType.Day ? this.filterToday :  this.dates[0].date,
            toDate:  this.currentViewType === ViewType.Day ? null :  this.dates[this.dates.length - 1].date,
            locationId: locationId || this.mainForm.value.locationId
        };
        
        this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.nurseModule.base, ApiResources.nurseModule.fetchBedWise), data)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
                this.globalLoading = false;
            }))
            .subscribe((response: GenericResponse) => {
                var records = response.data as Array<IView>;
                records.forEach(x => {
                    x.dates = new Array<IDateDetails>();
                    if(x.shiftDetails) {
                        let currentViewDates = this.currentViewType === ViewType.Day ? [this.filterToday] : [...this.dates.map(dd => dd.date)];
                        var days = x.shiftDetails.split("^");
                        currentViewDates.forEach(date => {
                            let dateModel = {
                                date,
                                details: new Array<IDetails>()
                            } as IDateDetails;
                            let tokens = new Array<string>();
                            if(this.currentViewType == ViewType.Day) {
                                tokens = days.map(x => x.split("%")[0]);
                            } else {
                                days.forEach(d => {
                                    let percentileSplit = d.split("%");
                                    let currentTokens = percentileSplit[0]
                                    let allDates = percentileSplit[1];
                                    let pipeSplit = allDates.split("|")
                                    let startDateTemp = new Date(pipeSplit[0]);
                                    let startDate = new Date(startDateTemp.getFullYear(), startDateTemp.getMonth(), startDateTemp.getDate(), 0, 0, 0);
                                    let toDateTemp = new Date(pipeSplit[1]);
                                    let toDate = new Date(toDateTemp.getFullYear(), toDateTemp.getMonth(), toDateTemp.getDate(), 0, 0, 0);
                                    let currentDate = new Date(date.year, date.month - 1, date.day, 0, 0, 0);
                                    if(currentDate.getTime() >= startDate.getTime() && currentDate.getTime() <= toDate.getTime()) {
                                        tokens = currentTokens.split(",");
                                        if(tokens.length && tokens.length < this.shiftsOnly.length) {
                                            let newTokens = [];
                                            this.shiftsOnly.forEach(o => {
                                                let found = tokens.find(x => x.split("|")[1].toLowerCase() === o.shiftName.toLowerCase());
                                                if(found) {
                                                    newTokens.push(found);
                                                }else {
                                                    newTokens.push(`NA|${o.shiftName}|${o.startTime}:00:00|${o.endTime}:00:00`);
                                                }
                                            });
                                            tokens = newTokens
                                        }
                                    }
                                })
                            }
                            if(tokens.length) {
                                tokens.forEach(y => {
                                    var maintokens = y.split(",");
                                    maintokens.forEach((data) => {
                                        var subTokens = data.split("|");
                                        let shiftName = subTokens[1];
                                        let startTime = +subTokens[2].split(":")[0];
                                        let endTime = +subTokens[3].split(":")[0];
                                        dateModel.details.push({
                                            nurseName: subTokens[0].toLowerCase(),
                                            shiftName,
                                            startTime,
                                            endTime,
                                            shiftCode: shiftName[0].toUpperCase()
                                        } as IDetails);
                                    })
                                })
                            } else {
                                dateModel.details.push(...this.shiftsOnly.map(x => {
                                    return {
                                        nurseName: null,
                                        shiftName: "NA",
                                        startTime: -1,
                                        endTime: -1,
                                        colspan: 24,
                                        shiftId: x.shiftId,
                                        shiftCode: x.shiftName[0].toUpperCase(),
                                        
                                    }
                                }))
                            }
                            x.dates.push(dateModel);
                        })

                    }
                    this.alignShifts(x);
                })
                this.dataRecords = records;
            },
                (e) => {
                   
                    this.records = new Array<INurseModel>();
                }
            );
    }

    private alignShifts = (record: IView) => {
        if(!record.dates || !record.dates.length) {
            record.dates = new Array<IDateDetails>();
            if(this.currentViewType == ViewType.Day) {
                record.dates.push({
                    date: this.filterToday,
                    details: [{
                        nurseName: null,
                        shiftName: "NA",
                        startTime: -1,
                        endTime: -1,
                        colspan: 24
                    }]
                } as IDateDetails);
            } else {
                this.dates.forEach(date => {
                    record.dates.push({
                        date,
                        details: [...this.shiftsOnly.map(x => {
                            return {
                                nurseName: null,
                                shiftName: "NA",
                                startTime: -1,
                                endTime: -1,
                                colspan: 24,
                                shiftCode: x.shiftName[0].toUpperCase()
                            }
                        })]
                    } as IDateDetails);
                })
            }
        } else if(this.currentViewType === ViewType.Day) {
            record.dates.forEach(date => {
                var newShifts = new Array<IDetails>();
                for(var i = this.shiftMocks[0]; i < this.shiftMocks[this.shiftMocks.length - 1]; i++) {
                    let found = date.details.find(x => i >= x.startTime && i <= (x.endTime < x.startTime ? (24 + x.endTime) : x.endTime));
                    if(found) {
                        if(!newShifts.find(x => i >= x.startTime && i <= (x.endTime < x.startTime ? (24 + x.endTime) : x.endTime))) {
                            newShifts.push({...found,
                                colspan: (found.endTime < found.startTime ? (24 + found.endTime) : found.endTime) - found.startTime
                            });
                        }
                    } else {
                        if(newShifts.length && newShifts[newShifts.length - 1].shiftName === "NA") {
                            let incrementer = (i + 1) === this.shiftMocks[this.shiftMocks.length - 1] ? 2 : 1;
                            newShifts[newShifts.length - 1].colspan = newShifts[newShifts.length - 1].colspan + incrementer;
                        } else {
                            newShifts.push({
                                nurseName: null,
                                shiftName: "NA",
                                startTime: -1,
                                endTime: -1,
                                shiftId:null,
                                colspan: newShifts.length && newShifts[newShifts.length - 1].endTime === (i - 1) ?  2 : 1
                            });
                        }
                    }
                }
                date.details = newShifts; 
            })
        }
    }
    onNurseSelected(nurse: IAvailableNurse): void {
        this.selectedNurse = nurse;
    }

    onSubmit() {
        if (this.availableForm.invalid) {
            return;
        }
        const request = {
            nurseId: this.selectedNurse.accountId,
            bedId: this.selected.bedId,
            createdBy: this.page.userAccount.accountId,
            shiftId: this.selectedShift.shiftId,
            fromDate: this.dateModels,
            toDate: this.dateModels
        }
        var url = ApiResources.nurseShift.insertShiftAsync;
        this.submitting = true;
        this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.nurseShift.base, url), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.submitting = false }))
            .subscribe((response: GenericResponse) => {
                if (response.status === GenericStatus[GenericStatus.Success]) {
                    this.close();
                    this.fetch();
                    this.notifyService.info("Shift has been saved successfully");

                  
                } else {
                    this.notifyService.defaultError();
                }
            });
    }
}