<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <button [useExistingCss]="true"
                                printSectionId="progress-report"
                                ngxPrint
                                class="btn btn-light btn-xs mr-1 d-print-none">
                            <i class="fe-printer mr-1"></i>Print
                        </button>

                   </div>
            </div>
        </div>
            </div>
        <div class="row">
            <div class="col-12">
                <h4 class="page-title" style="text-align:center">Patient Order Action Sheet</h4>
            </div>
        </div>

        <div class="row" *ngIf="admission">
            <div class="col-12" id="progress-report">
                <div class="noprint">
                    <div *ngIf="isPrintLogo">
                        <banner-setting></banner-setting>
                    </div>
                </div>
                <ng-container *ngIf="admission">
                    <div class="overflow-auto">
                        <table width="100%" border="0" cellspacing="0" cellpadding="4" style="
                        border-bottom: 1px solid #333; font-family: 'Open Sans'; font-size: 14px;">
                            <tbody>
                                <tr>
                                    <th>
                                        <span *ngIf="isAdmission">Day Of Admission </span>
                                        <span *ngIf="!isAdmission">Appointment Date</span>
                                    </th>
                                    <td>
                                        <span class="mr-1">:</span>
                                        <span *ngIf="isAdmission" [textContent]="admission.admissionDate | date: 'dd-MM-yyyy, h:mm:ss a'"></span>
                                        <span *ngIf="!isAdmission">
                                            <span [textContent]="admission.admissionDate | date: 'dd-MM-yyyy'"></span>
                                            <span [textContent]="' ' + admission.timeString"></span>
                                        </span>
                                    </td>
                                    <th>Doctor Name</th>
                                    <td>
                                        <span class="mr-1">:</span>
                                        <span [textContent]="admission.providerName"></span>
                                    </td>
                                    <th>Department</th>
                                    <td>
                                        <span class="mr-1">:</span>
                                        <span [textContent]="admission.departmentName"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Patient Name</th>
                                    <td>
                                        <span class="mr-1">:</span>
                                        <span>
                                            <span [textContent]="admission.fullName + ', '"></span>
                                            <span [textContent]="(admission.gender === 'F' ? 'Female' : 'Male') + ', '"></span>
                                            <span [textContent]="admission.age + 'yrs'"></span>
                                        </span>
                                    </td>
                                    <th>UMR No</th>
                                    <td>
                                        <span class="mr-1">:</span>
                                        <span [textContent]="admission.umrNo"></span>
                                    </td>


                                    <th>
                                        <span *ngIf="isAdmission">IP No</span>
                                        <span *ngIf="!isAdmission">Appointment No</span>
                                    </th>
                                    <td>
                                        <span class="mr-1">:</span>
                                        <span [textContent]="admission.admissionNo"></span>
                                    </td>

                                </tr>
                                <tr *ngIf="isAdmission">
                                    <th>Date of Ordering</th>
                                    <td>
                                        <span class="mr-1">:</span>
                                        <span [textContent]="admission.isDischarged ? (admission.dischargeDate | date:'dd-MM-yyyy, hh:mm a') : 'Not Discharged Yet'"></span>

                                    <th>Bed no./Ward no.</th>
                                    <td>
                                        <span class="mr-1">:</span>
                                        <span [textContent]="!admission.bedNumber ? 'N/A' : (admission.wardName + ' / ' + admission.roomName + ' / ' + admission.bedNumber)"></span>
                                    </td>


                                    <th>Date of Printing</th>
                                    <td colspan="3">
                                        <span class="mr-1">:</span>
                                        <span [textContent]="currentDate | date:'dd/MM/yyyy, hh:mm a'"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                    <div class="row mt-2">
                        <div class="col-12">
                            <div class="card mb-0">
                                <div class="card-body p-0">
                                    <div class="overflow-auto">
                                        <table class="table table-centered table-sm table-striped table-bordered mb-0">
                                            <thead class="table-primary">
                                                <tr>

                                                    <th style="width:20%;">Name</th>
                                                    <th>Start Date</th>
                                                    <th>Start Date Day </th>
                                                    <th>Frequency</th>
                                                    <th>Remarks</th>
                                                    <th>Route</th>
                                                    <th>Order Time</th>
                                                    <th>Start Time</th>
                                                    <th>Ordered By</th>

                                                </tr>
                                            </thead>




                                            <tbody>
                                                <ng-container *ngIf="!loading && medications.admissionDates.length">
                                                    <!--<h5>Entry Date:<span [textContent]="items"></span></h5>-->
                                                    <ng-container *ngFor="let items of medications.admissionDates">
                                                        <tr>
                                                            <td>
                                                                <h5 [textContent]="items | date:'dd/MM/yyyy'"></h5>
                                                            </td>
                                                        </tr>

                                                        <ng-container *ngIf="!loading && medications.medicines.length">
                                                            <h5>Medicines</h5>
                                                            <ng-container *ngFor="let item of medications.medicines; let i = index;">
                                                                <ng-container *ngIf="item.startDate ===items ">

                                                                    <tr [ngClass]="{'table-danger' : item.activeStatus === activeStatus.Stopped}">

                                                                        <td [textContent]="item.productName">
                                                                        </td>
                                                                        <td>
                                                                            <div>
                                                                                <div class="media">
                                                                                    <div class="media-body">
                                                                                        <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.startDate | date:'dd/MM/yyyy'"></h5>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                        </td>
                                                                        <td [ngClass]="{'text-line-through' : item.activeStatus === activeStatus.Stopped}">
                                                                            <div class="d-flex align-content-center">
                                                                                <ng-container *ngFor="let frequency of item.medicationFrequency">
                                                                                    <div class="l-chip pr-1 mr-1">
                                                                                        <div>

                                                                                            <small>
                                                                                                <ng-container *ngIf="frequency.medicationInstructionDeepTypeId === 1">
                                                                                                    Morning
                                                                                                </ng-container>
                                                                                                <ng-container>
                                                                                                    <span [textContent]="frequency.medicationInstructionName"></span>
                                                                                                </ng-container>
                                                                                                <ng-container *ngIf="frequency.medicationInstructionDeepTypeId === 2">
                                                                                                    Afternoon
                                                                                                </ng-container>
                                                                                                <ng-container *ngIf="frequency.medicationInstructionDeepTypeId === 3">
                                                                                                    Night
                                                                                                </ng-container>
                                                                                                <ng-container *ngIf="frequency.medicationInstructionDeepTypeId === 4">
                                                                                                    Timeline
                                                                                                </ng-container>
                                                                                            </small>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </div>
                                                                        </td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td [ngClass]="{'text-line-through' : item.activeStatus === activeStatus.Stopped}">
                                                                            <div class="d-flex align-content-center">
                                                                                <ng-container *ngFor="let frequency of item.medicationFrequency">
                                                                                    <div class="l-chip pr-1 mr-1">
                                                                                        <div>


                                                                                        </div>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <ng-template #cancelledBy>
                                                                                <div class="media">
                                                                                    <div class="avatar-sm mr-2">
                                                                                        <span class="avatar-title rounded-circle font-12 font-weight-bold text-white"
                                                                                              avatar-bg
                                                                                              [index]="i"
                                                                                              [textContent]="item.modifiedByName | initials"></span>
                                                                                    </div>
                                                                                    <div class="media-body">
                                                                                        <span class="d-block font-12">
                                                                                            <span [textContent]="item.modifiedByName"></span>
                                                                                            <br />
                                                                                            <span [textContent]="item.modifiedDate | date: 'dd-MM-yyyy, h:mm:ss a'"></span>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </ng-template>
                                                                            <div *ngIf="item.progressReportMedicationId" class="font-weight-bold">
                                                                                <span *ngIf="item.activeStatus === activeStatus.Active" class="text-success">
                                                                                    <span class="font-weight-normal">Active</span>
                                                                                </span>
                                                                                <span *ngIf="item.activeStatus === activeStatus.Completed" class="text-primary">
                                                                                    <span class="font-weight-normal">Completed</span>
                                                                                </span>
                                                                                <span *ngIf="item.activeStatus === activeStatus.NotStarted" class="text-info">
                                                                                    <span class="font-weight-normal">Not Started</span>
                                                                                </span>
                                                                                <div *ngIf="item.activeStatus === activeStatus.Stopped" class="text-danger">
                                                                                    <div class="d-flex align-items-center">
                                                                                        <i *ngIf="item.modifiedByName" class="mdi mdi-information mr-1" placement="left" [ngbPopover]="cancelledBy" triggers="mouseenter:mouseleave" popoverTitle="Stopped By"></i>
                                                                                        <span class="font-weight-normal">Stopped</span>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </td>
                                                                    </tr>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>

                                                        <ng-container *ngIf="!loading && labs.length ">
                                                            <h5>Tests</h5>
                                                            <ng-container *ngFor="let item of labs; let i = index;">
                                                                <tr *ngIf="item.date ===items">

                                                                    <td>
                                                                        <div>
                                                                            <div class="media">
                                                                                <div>
                                                                                    <img alt="lab service" [src]="imgOrigin + 'assets/images/days/lab.png'" width="28" />
                                                                                </div>
                                                                                <div class="media-body ml-1">
                                                                                    <h5 class="mb-0 mt-0 font-weight-normal">
                                                                                        <span [textContent]="item.testName || '---'"></span>
                                                                                    </h5>
                                                                                    <span class="d-block font-12 text-capitalize">
                                                                                        <small [textContent]="item.testCode"></small>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <!--<td [textContent]="item.notes"></td>-->

                                                                    <td [textContent]="(item.date || item.createdDate) | utcToLocal:false:'DD/MM/YYYY'"></td>
                                                                    <td></td>

                                                                    <td> </td>
                                                                    <td> </td>
                                                                    <td> </td>

                                                                    <td></td>
                                                                    <td [textContent]="(item.date || item.createdDate) | utcToLocal:false:'hh:mm a'"></td>
                                                                    <td>
                                                                        <div>
                                                                            <div class="media">
                                                                                <div class="media-body">
                                                                                    <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.createdByName"></h5>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        </ng-container>


                                                        <ng-container *ngIf="!loading && vitalsData.length">
                                                            <h5>Vitals</h5>
                                                            <ng-container *ngFor="let item of vitalsData; let i = index;">
                                                                <ng-container *ngIf="item.date ===items ">
                                                                    <tr *ngFor="let subItem of item.vitals">

                                                                        <td [textContent]="subItem.name"></td>
                                                                        <!--<td [textContent]="item.notes"></td>-->

                                                                        <td [textContent]="(item.date || item.createdDate) | utcToLocal:false:'DD/MM/YYYY'"></td>
                                                                        <td></td>

                                                                        <td> </td>
                                                                        <td> </td>
                                                                        <td> </td>

                                                                        <td></td>
                                                                        <td [textContent]="(item.date || item.createdDate) | utcToLocal:false:'hh:mm a'"></td>
                                                                        <td>
                                                                            <div>
                                                                                <div class="media">
                                                                                    <div class="media-body">
                                                                                        <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.createdByName"></h5>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </tbody>
                                        </table>

                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    </div>
            
                    </div>
        </div>
</div>














