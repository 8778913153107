export class ProviderMedications {
    providerMedicationId: number;
    providerId: number;
    drugId: number;
    medicineName: string;
    providerName: string;
    genericName: string;
    medicineSearchType: string;
    medicineType: string;
    dosage: number;
    duration: string;
    durationType: string;
    isMorning?: boolean;
    morningDosage: string;
    isAfternoon?: boolean;
    afternoonDosage: string;
    isNight?: boolean;
    nightDosage: string;
    instructions: string;
    active: boolean;
    createdBy: number;
    createdByName: string;
    createdDate: Date;
    modifiedBy: number;
    modifiedByName: string;
    modifiedDate?: Date;
    totalItems: number;
    isSelected: boolean;
}