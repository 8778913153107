export class IndentRequest {
    indentHeaderId: number;
    departmentId: number;
    departmentName: string;
    reasonForRequirement: string;
    indentTo: string;
    productName: string;
    createdByName: string;
    approvedByName: string;
    requiredDate: Date;
    approvedBy?: number;
    createdBy: number;
    indentDetailId: number;
    productId: number;
    quantity: number;
    createdDate: Date;
    approvedDate: Date;
    totalItems: number;
    retailName: string;
    approvedByRoleName: string;
    createdByRoleName: string;
}