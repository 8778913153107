export class PharmacyIndentHeader {
    pharmacyIndentHeaderId: number;
    indentDate?: Date;
    departmentId: number;
    departmentName: string;
    requiredDate: Date;
    reason: string;
    admissionId: number;
    approvedBy?: number;
    approvedDate?: Date;
    approvedByName: string;
    createdBy: number;
    createdDate: Date;
    status: string;
    createdByName: string;
    patientId: number;
    patientName: string;
    totalItems: number;
    isShow: boolean; //
    issueQuantity: number;
    issueNetAmount: number;
    issueProductName: string;
    umrNo: string;
    mobile: string;
    genericName: string;
    age: string;
    fullName: string;
    otIndentHeaderId: number;
    numRequestedItems: any;
    createdByRole: string;
    approvedByRole: string;
    isDeleting: boolean;
    issueCategoryName: string;
    pharmacyProductId: number;
    quantity: number;
    pharmacyIndentDetailId: number;
    isRejectedProductsExists?: boolean;
    isReceiptGenerated?: boolean;
    indentStatusTypeId?: IndentStatusType;
    receiptId?: number;
    approvedByRoleName: string;
    createdByRoleName: string;
    retailName: string;
    requestedDate: Date
    pharmacyIssueDetailId: number;
    returnQuantity: number;
    admissionNo: string;
    pharmacyIssueHeaderId: number;
    issuedDate: string;
}

export enum IndentStatusType {
    Complete = 1,
    NotComplete = 2
}

export enum ReceiptAreaType {
    Pharmacy = 1,
    General = 2
}