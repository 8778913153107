export class RoleDepartment {
    roleDepartmentId: number;
    roleDepartmentName: string;
    role: any;
    active: boolean;
    createdByName: string;
    createdBy: number;
    createdDate: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    createdByRole: string;
    modifiedByName: string;
    modifiedByRole: string;
    allowedRoles: Array<any>;
}