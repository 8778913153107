import { Directive, HostListener, ElementRef } from "@angular/core";

@Directive({
    selector: "[autoResize]"
})
export class AutoResizeDirective {
    @HostListener("input", ["$event.target"])
    onInput() {
        this.adjust();
    }

    constructor(public element: ElementRef) {
    }

    ngAfterContentChecked(): void {
        this.adjust();
    }

    adjust(): void {
        this.element.nativeElement.style.overflow = "hidden";
        this.element.nativeElement.style.height = "auto";
        this.element.nativeElement.style.height = this.element.nativeElement.scrollHeight + "px";
    }
}