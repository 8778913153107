export class PatientRawImport {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    gender: string;
    dateOfBirth: string;
    age: string;
    address: string;
    pincode: string;
    relation: string;
    relativeName: string;
    relativeMobile: string;
    salutation: string;
   
}
