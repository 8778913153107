
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { HttpService, AppData, EncounterCommunication, TimelineToggleService } from '../../services';
import { takeUntil, finalize } from 'rxjs/operators';
import { Page, IUserAccount, PatientProfile, ObEncounter} from '../../models';
import { Appointment, PreviousAppointment } from '../../entities';
import { ApiResources } from '../../helpers';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { OB } from '../../../areas/admin/full-transcript/pages/ob-report/ob.namespace';
import { GynEncounter } from '../../models/gyn-encounter.model';
import { NeoIPEncounter } from '../../models/neonatal-ip-encounter.model';
@Component({
    selector: 'neonatal-header',
    templateUrl: './neonatal-header.html',
    styleUrls: ['./neonatal-header.css'],
})
export class NeonatalHeaderWidget implements OnDestroy, OnInit {
    @Input() patientId: string;
    @Input() appointmentId: string;  
    page: Page;
    isAdmission: any;
    appointments: any;
    oldAppointment: any;
    visitNo: number;
    encounterCommunicationSubscription: Subscription;
    appointment: Appointment;
    patient: PatientProfile;
    husbandName: string;
    roleId: number;
    sideNav: boolean;
    obEncounter: ObEncounter;
    neonatalIPEncounter: NeoIPEncounter
    specialFeature: OB.SpecialFeature;
    antinantalRisk: OB.AntinantalRisk;
 
    constructor(
        private readonly httpService: HttpService,
        private readonly appData: AppData,
        private readonly encounterCommunication: EncounterCommunication,
        private readonly router: Router,
        public timelineService: TimelineToggleService

    ) {
        this.page = new Page();     
    }

    private findPatientDetails() {
        this.page.loading = true;
        this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profile), { encryptedPatientId: this.appointment.encryptedPatientId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: PatientProfile) => {
                if (response) {
                    this.patient = response;
                   
                    }
                
            });
    }
    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointment = response;
                this.findPatientDetails();
                this.findCommonData();
                this.paddingTop();
            });
    }
    private findCommonData() {
        
        const request = {
           
            encryptedAppointmentId: this.appointmentId,
            encryptedProviderId: this.page.userAccount && this.page.userAccount.encryptedReferenceId ? this.page.userAccount.encryptedReferenceId : null,
            encryptedPatientId: this.patientId,
            isAdmission: this.isAdmission,
            patientId: this.appointment.patientId,
            appointmentId: this.appointment.appointmentId,
            
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<NeoIPEncounter>(ApiResources.getURI(ApiResources.neonatalEncounter.base, ApiResources.neonatalEncounter.findCommonData), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: NeoIPEncounter) => {
                this.neonatalIPEncounter = response;
                if (this.neonatalIPEncounter) {
                this.antinantalRisk = this.neonatalIPEncounter.antinantalRisk ? JSON.parse(this.neonatalIPEncounter.antinantalRisk) : null;
                 this.specialFeature = this.neonatalIPEncounter.specialFeature ? JSON.parse(this.neonatalIPEncounter.specialFeature) : null;
                     }
             
                this.paddingTop();
            });
    }

    private findDashboard() {

        const request = {

            encryptedAppointmentId: this.appointmentId,
            encryptedProviderId: this.page.userAccount && this.page.userAccount.encryptedReferenceId ? this.page.userAccount.encryptedReferenceId : null,
            encryptedPatientId: this.patientId,
            isAdmission: this.isAdmission,
            patientId: this.appointment.patientId,
            appointmentId: this.appointment.appointmentId,

        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<[NeoIPEncounter, Appointment, PreviousAppointment]>(ApiResources.getURI(ApiResources.neonatalEncounter.base, ApiResources.neonatalEncounter.findData), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: [NeoIPEncounter, Appointment, PreviousAppointment]) => {
                this.neonatalIPEncounter = response["dashboard"];
                this.appointments = response["appointments"];
                this.oldAppointment = response["oldAppointment"];
                if (this.neonatalIPEncounter) {
                    this.antinantalRisk = this.neonatalIPEncounter.antinantalRisk ? JSON.parse(this.neonatalIPEncounter.antinantalRisk) : null;
                    this.specialFeature = this.neonatalIPEncounter.specialFeature ? JSON.parse(this.neonatalIPEncounter.specialFeature) : null;
                }

                this.paddingTop();
            });
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    const appointmentId = decodeURIComponent(url.split("/")[url.split("/").length - 3]);
                    this.roleId = this.page.userAccount.roleId;
                    this.isAdmission = url.split("/")[4] === "a";
                               
                    this.findAppointment(appointmentId);
                   
                   
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
      
    }
    toggleIt() {
        this.timelineService.toggle();
        if (this.timelineService.showBox == false) {
            $("body")[0].setAttribute("data-timeline-size", "timelineabsent");
        } else {
            $("body")[0].setAttribute("data-timeline-size", "timeline");
        }
    }
    toggleSideNav() {
        $(".sidebar-main-menu")[0].classList.toggle("removeLeft");
        if ($("body")[0].getAttribute('data-sidebar-size') === "default") {
            $("body")[0].setAttribute("data-sidebar-size", "condensed");
            this.sideNav = true;
        } else if ($("body")[0].getAttribute('data-sidebar-size') === "condensed") {
            $("body")[0].setAttribute("data-sidebar-size", "default");
            this.sideNav = false;
        }
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }
    paddingTop() {
        if (document.getElementById("header") != null && document.getElementsByClassName("content")[0] != null) {
            var paddingTopValue = document.getElementById("header").offsetHeight;
            var content = document.getElementsByClassName("content")[0];
            content.setAttribute('style', `padding-top:${paddingTopValue}px`);
        }
    }
}