import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppData, appUrls, IdentityService } from "@shared/services";
import { interval, Subscription } from "rxjs";

@Component({
    selector: "session-timeout",
    template: ""
})
export class SessionTimeoutComponent implements OnInit, OnDestroy {
    session: Subscription;
    constructor(
        private readonly router: Router,
        private readonly identityService: IdentityService,
        private readonly appData: AppData
    ) {
    }

    checkSession() {
        this.identityService.get().subscribe(account => {
            if (!account) {
                this.router.navigateByUrl(appUrls.login);
                this.appData.setAccount(null);
            }
        });
    }

    ngOnInit() {
        this.session = interval(90000).subscribe(() => {
            this.checkSession();
        });
    }

    ngOnDestroy() {
        this.session.unsubscribe();
    }
}