
import { HttpService } from '../services/http.service';
import { NotifyService } from '../services/notify.service';


export class WhatsAppService {

    constructor() {

    }

    sendWhatsAppMessageAsync(body: Record<any, any>, httpService: HttpService, notifyService: NotifyService, endPoint: string ="SendMessage") {
        const settings = JSON.parse(localStorage.getItem("settings"));
        var url = settings.whatsAppService.base + endPoint;
        var contactNumber = settings.whatsAppService.contactNumber;
        var foundationName = settings.whatsAppService.practiceName;

        body['FoundationName'] = foundationName;
        body['PracticeName'] = foundationName;
        body["ContactNumber"] = contactNumber;
        
        httpService.post(url, body).subscribe((response: any) => {
            notifyService.successToast("The whatsapp message has sent !!")
        },
            (error: any) => {
                notifyService.errorToast("Could not send whatsapp message" + error); console.log(error)
            });
    }
}