import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

export function padNumber(value: number) {
    if (isNumber(value)) {
        return (`0${value}`).slice(-2);
    } else {
        return "";
    }
}

export function isNumber(value: any): boolean {
    return !isNaN(toInteger(value));
}

export function toInteger(value: any): number {
    return parseInt(value, 10);
}

export class DateHelper {    

    static get today() {
        return moment().format("dddd, Do MMMM YYYY, h:mm:ss a");

    }
    static get todayInFormat() {
        return moment().format("YYYY-MM-DD");

    }


    static get ngbToday() {
        return { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    }

    static get minDate() {
        return { year: new Date().getFullYear() - 160, month: new Date().getMonth() + 1, day: new Date().getDate() };
    }

    static get minAge() {
        return { year: new Date().getFullYear() - 18, month: new Date().getMonth() + 1, day: new Date().getDate() };
    }

    static get currentTimeZone() {
        const split = new Date().toString().split(" ");
        const timeZone = split[split.length - 3].substring(1) + " " + split[split.length - 2] + " " + split[split.length - 1];
        return timeZone.substring(0, timeZone.length - 1);
    }

    static get currentTimeOffset() {
        const split = new Date().toString().split(" ");
        const offset = split[5].split("GMT")[1];
        return offset.slice(0, 3) + ":" + offset.slice(3);
    }
    static toNgbDateStruct(date: string) {

        let dateString: NgbDateStruct = null;
        if ((date &&
            Number(date.toString().substring(0, 4)) &&
            Number(date.toString().substring(5, 7) + 1) &&
            Number(date.toString().substring(8, 10)))) {
            dateString = {
                year: Number(date.toString().substring(0, 4)),
                month: Number(date.toString().substring(5, 7)),
                day: Number(date.toString().substring(8, 10))
            };
        }

        return dateString;
    }

    static getAge(value: string) {
        const dateOfBirth = this.toNgbDateStruct(value);
        const timeDiff = Math.abs(new Date().getTime() - new Date(dateOfBirth.year, dateOfBirth.month, dateOfBirth.day).getTime());
        return Math.round(timeDiff / (1000 * 3600 * 24) / 365.25);
    }

    static toString(date: NgbDateStruct) {
        return date ? date.year.toString() + "-" + String(`00${date.month}`).slice(-2) + "-" + String(`00${date.day}`).slice(-2) : null;
    }

    static isExpired(date: Date, ticks: number) {
        const hours = Math.round((ticks / (60 * 60 * 10000000)) % 24);
        const mins = Math.round((ticks / (60 * 10000000)) % 60);
        const timestamp = moment(moment(date).format("YYYY-MM-DD") + "T" + padNumber(hours) + ":" + padNumber(mins) + ":00");
        return timestamp.diff(moment(), "m") <= 0;
    }

    static getTimeFromTicks(ticks: number) {
        const hours = Math.round((ticks / (60 * 60 * 10000000)) % 24);
        const mins = Math.round((ticks / (60 * 10000000)) % 60);
        const time = "T" + padNumber(hours) + ":" + padNumber(mins) + ":00";
        return time;
    }

    static getDays(date: Date) {
        return moment.duration(moment(Date.now()).diff(moment(new Date(date)))).asDays();
    }
}