import { Component, OnInit, OnDestroy, ViewEncapsulation, TemplateRef, Output, EventEmitter, Input } from "@angular/core";
import { Location } from '@angular/common';
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { finalize, takeUntil } from "rxjs/operators";
import { GenericResponse, GenericStatus, Page, IUserAccount } from "../../../../../shared/models";
import { HttpService, AppData, NotifyService } from "../../../../../shared/services";
import { ApiResources, DateHelper } from "../../../../../shared/helpers";
import { ActivatedRoute, Params } from "@angular/router";
import { IAdmissionModel } from "../../../services/models/admission.model";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { AdmissionFetchHelper, PIXEL_PER_LETTER, BUFFER_WIDTH } from "../../shared/helper";
import { INote } from "./helpers/helper";
import { WhiteSpaceValidator } from "../../../../../shared/validators";

@Component({
    selector: "nurse-notes",
    templateUrl: "./nurse-notes.html",
    styleUrls: ["./nurse-notes.css"],
    encapsulation: ViewEncapsulation.None
})
export class NurseNotesPage implements OnInit, OnDestroy {

    @Input() inputAdmissionId?: number;
    @Output() onClose = new EventEmitter<any>();
    admissionId: number;
    isAdmission: boolean;
    page: Page;
    modalRef: NgbModalRef;
    loading: boolean;
    swap: boolean;
    admissiondischarge: boolean;
    dateControl: FormControl;
    addForm: FormGroup;

    records: Array<INote>;
    admission: IAdmissionModel;
    imgOrigin: string;

    submitted: boolean;
    submitting: boolean;
    widths: { createdBy: number };
    minDate = DateHelper.ngbToday;

    constructor(
        private readonly httpService: HttpService,
        private readonly modalService: NgbModal,
        private readonly notifyService: NotifyService,
        private readonly appData: AppData,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly locationService: Location,
        private readonly admissionFetchHelper: AdmissionFetchHelper,
    ) {
        this.page = new Page();
        this.buildAddForm();

        this.widths = {
            createdBy: 130
        }
        this.imgOrigin = location.origin + location.pathname;
        this.records = new Array<any>();
    }


    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;

                    this.route.parent.paramMap
                        .subscribe((params: Params) => {
                            var id = params["params"]["id"];
                            this.inputAdmissionId = parseInt(window.atob(id));
                            this.admissionId = this.inputAdmissionId || +params["params"]["id"];
                            this.isAdmission = this.inputAdmissionId ? true : params["params"]["type"] === "a";
                            this.loading = true;
                            this.admissionFetchHelper.admissionFetch(this.admissionId, this.isAdmission, (data: IAdmissionModel) => {
                                this.admission = data;
                            });
                            this.fetch();
                        });
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {

    }

    // FORM
    get form() {
        return this.addForm.controls;
    }

    private buildAddForm() {
        this.addForm = this.formBuilder.group({
            note: [null, [Validators.required, WhiteSpaceValidator.isValid]]
        });
    }
    resetAddNoteForm = () => {
        this.addForm.reset();
    }
    
    openModifyModel = (content: TemplateRef<any>) => {
        this.resetAddNoteForm();
        this.openModelHelper(content);
    }

    openModelHelper = (content: TemplateRef<any>) => {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: "lg",
            windowClass: "custom-modal effect-scale"
        });
    }

    onCloseModal() {
        try {
            this.onClose.emit();
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            // ignored;
        }
    }

    fetch = () => {
        this.loading = true;
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.nurseNote.base, ApiResources.nurseNote.fetch),
                { id: this.admissionId })
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        var data = response.data;
                        var createdByWidth = Math.max(...data.map(x => x.createdByName.length)) * PIXEL_PER_LETTER;
                        this.widths.createdBy = Math.max(createdByWidth, this.widths.createdBy) + BUFFER_WIDTH - 10;
                        this.records = data;
                    } else {
                        this.notifyService.warning(response.message);
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }

    modify = () => {
        var abc = ApiResources.getURI(ApiResources.nurseNote.base, ApiResources.nurseNote.insert);
        this.submitted = true;
        if (this.addForm.invalid) {
            this.notifyService.infoToast("Please fill the form.");
            return;
        }

        var data = {
            admissionId: +this.admissionId,
            note: this.addForm.value.note,
            createdBy: this.page.userAccount.accountId
        }

        this.submitting = true;
        this.httpService
            .post<GenericResponse>(abc, data)
            .pipe(finalize(() => this.submitting = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.fetch();
                        this.onCloseModal();
                    } else {
                        this.notifyService.warning(response.message);
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }

    onBack = () => {
        this.locationService.back();
    }

}
