export class Authority {
    authorityMasterId: number;
    authority: string;
    createdByName: string;
    createdDate: Date;
    modifiedByName: string;
    modifiedDate: Date;
    active: boolean;
    createdByRole: string;
    modifiedByRole: string;
}