import { FormControl } from "@angular/forms";
import { RegExHelper } from "@shared/helpers";

export class NPIValidator {
    static isValid(control: FormControl): object | null {
        const value = control.value;

        // Empty Value
        if (!value) {
            return null;
        }

        // Min Length
        if (!RegExHelper.npi.test(value) || !RegExHelper.numbersOnly.test(value)) {
            return { 'invalidNpi': true };
        }

        return null;
    }
}