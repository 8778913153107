export class DentalFullTranscript {
    appointmentId: number;
    patientId: number;
    homeopathyId?: number;
    patientName: string;
    patientAge?: number;
    patientDateOfBirth?: Date;
    patientGender: string;
    patientMobile: string;
    patientThumbnailUrl: string;
    patientCountryCode: string;
    appointmentDate: Date;
    appointmentTime: object;
    appointmentTimeString: string;
    dentalEncounterId?: number;
    chiefComplaints:string;
    medicalHistory:string;
    dentalHistory:string;
    familyHistory:string;
    personalHabits:string;
    oralHygiene:string;
    clinicalFindings:string;
    diagnosis:string;
    medications:string;
}