export class TemplateConfig {
    dynamicTemplateConfigId: number;
    reportName: string;
    dynamicReportId: number;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
    templateName: string;
    modifiedByName: string;
    createdByName: string;
    modifiedByRole: string;
    createdByRole: string;
}