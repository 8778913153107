export class ProviderScheduleVisits {
    providerId: number;
    visitTypeId: number;
    locationId: number;
    specializationId: number;
    specializationName: string;
    locationName: string;
    chargeTypesId: number;
    ProviderName: string;
    visitTypeDuration: string;
    active: boolean;
    charge: number;
    chargeName: string;
    specializationCharge: number;
    providerScheduleChargeId: number;
    totalItems: number;
    providerAvailabilityId: number;
    availableDay: string;
    startTime: string;
    endTime: string;
    startDate: Date;
    endDate: Date;
    createdByName: string;
    modifiedByName: string;
    createdDate: string;
    modifiedDate: string;
    providerAvailabilityVisitTypeId: number;
    chargeNames: string;
    schedulerTypeId: string;
    visitorName: string;
    name: string;
    value: string;
    id: number;
    createdByRole: string;
    modifiedByRole: string;
}