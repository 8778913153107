export class PaymentStatus {
    status: number;
    message: string;
    result: Array<Result>;
    errorCode: any;
    responseCode: any;
    constructor() {
        this.result = [];
    }
}
export class Result {
    merchantTransactionId: string;
    paymentId: number;
    status: string;
    amount: number;
}

export class Refund {
    status: number;
    rows: number;
    message: string;
    result: number;
    guid: string;
    sessionId: string;
    errorCode: any;
}
