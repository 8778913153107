import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    readonly DELIMITER = '/';
    private localesWithMMDDYYYYFormat = [
        'en-US', 'en-CA', 'fr-CA', 'en-PH', 'en-BZ', 'es-MX', 'en-JM',
        'en-MY', 'en-PR', 'en-TT', 'en-AG', 'en-BS', 'en-BB', 'en-HK',
        'en-KY', 'en-GY', 'en-LR', 'en-MT', 'en-NG', 'en-PK', 'en-SG',
        'en-TZ', 'en-VI', 'es-US', 'fil-PH', 'haw-US', 'chr-US', 'nl-AW',
        'nl-CW', 'es-AR', 'es-BO', 'es-CL', 'es-CO', 'es-CR', 'es-DO',
        'es-EC', 'es-GT', 'es-HN', 'es-NI', 'es-PA', 'es-PY', 'es-PE',
        'es-SV', 'es-VE', 'es-GQ'
    ];

    private localesWithYYYYMMDDFormat = [
        'zh-CN', 'zh-TW', 'zh-HK', 'zh-SG', 'ja-JP', 'ko-KR', 'mn-MN',
        'lt-LT', 'fi-FI', 'sv-SE', 'no-NO', 'da-DK', 'is-IS', 'et-EE',
        'lv-LV', 'ru-RU', 'uk-UA', 'bg-BG', 'hr-HR', 'sr-RS', 'sl-SI',
        'ro-RO', 'hu-HU', 'pl-PL', 'cs-CZ', 'sk-SK', 'ka-GE', 'hy-AM',
        'az-AZ', 'he-IL', 'ar-SA', 'ar-IQ', 'ar-JO', 'ar-KW', 'ar-LB',
        'ar-OM', 'ar-QA', 'ar-SY', 'ar-AE', 'ar-YE', 'fa-IR', 'ps-AF',
        'ur-PK', 'ur-IN', 'hi-IN', 'bn-BD', 'bn-IN', 'ta-IN', 'ta-LK',
        'th-TH', 'lo-LA', 'km-KH', 'my-MM', 'bo-CN', 'ne-NP', 'si-LK',
        'ko-KP', 'vi-VN', 'km-KH', 'bo-IN', 'tg-TJ', 'kk-KZ', 'uz-UZ',
        'af-ZA', 'am-ET', 'sw-KE', 'sw-TZ', 'xh-ZA', 'zu-ZA'
    ];


    parse(value: string): NgbDateStruct | null {
        if (value) {
            const userLocale = navigator.language;
            const parts = value.trim().split(this.DELIMITER);

            if (this.localesWithMMDDYYYYFormat.includes(userLocale)) {
                
                if (parts.length === 3) {
                    const month = parseInt(parts[0], 10);
                    const day = parseInt(parts[1], 10);
                    const year = parseInt(parts[2], 10);

                    if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
                        return { day, month, year };
                    }
                }
            } else if (this.localesWithYYYYMMDDFormat.includes(userLocale)) {
                    if (parts.length === 3) {
                    const year = parseInt(parts[0], 10);
                    const month = parseInt(parts[1], 10);
                    const day = parseInt(parts[2], 10);

                    if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
                        return { day, month, year };
                    }
                }
            } else {
                
                if (parts.length === 3) {
                    const day = parseInt(parts[0], 10);
                    const month = parseInt(parts[1], 10);
                    const year = parseInt(parts[2], 10);

                    if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
                        return { day, month, year };
                    }
                }
            }
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        if (date) {
            const dayStr = this.pad(date.day);
            const monthStr = this.pad(date.month);
            const yearStr = date.year.toString();
            return `${dayStr}${this.DELIMITER}${monthStr}${this.DELIMITER}${yearStr}`;
        }
        return '';
    }

    private pad(n: number): string {
        return n < 10 ? `0${n}` : `${n}`;
    }
}
