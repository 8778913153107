export class SemenAnalysisReport {
    husbandName: string
    abstinencePeriod: string
    id: number
    date: Date
    collectionTime: string
    liquefactionTime: Number
    appearance: string
    volume: string
    viscosity: string
    ph: string
    spermCount: string
    agglutination: string
    clumping: string
    granularDebris: string
    totalMotility: string
    rapidProgressive: string
    progressiveMotility: string
    nonProgressive: string
    immotile: string
    normalSperms: string
    headDefects: string
    neckMidDefects: string
    tailDefects: string
    cytoplasmicDroplet: string
    epithelialCells: string
    pusCells: string
    rbc: string
    fructoseTest: string
    hosTest: string
    impression: string
    embryologist: string
    dineBy: string
}