export * from "./compare.validator";
export * from "./email.validator";
export * from "./password.validator";
export * from "./mobile.validator";
export * from "./npi.validator";
export * from "./zipcode.validator";
export * from "./uniq-email.validator";
export * from "./uniq-mobile.validator";
export * from "./uniq-npi.validator";
export * from "./age.validator";
export * from "./whitespace.validator";
export * from "./sp.validator";
export * from "./uniq-pharmacy-product.validator";
export * from "./weight.validator"
 
