export class TemplateDocument {
    templateDocumentId: number;
    appointmentId: number;
    encounterTypeId: number;
    uploadedBy: number;
    uploadedDate: Date;
    imageUrl: string;
    modifiedBy: number;
    modifiedDate: Date;
    encryptedAppointmentId: string;
    patientId: number;
    formedUrl:any;
}