export class BedManagement {
    wardId: number;
    otRoomId: number;
    value: string;
    wardName: string;
    active: boolean;
    isShow: boolean;
    roomId: number;
    roomName: string;
    roomRent: number;
    bedsCount: number;
    bedId: number;
    bedType: string;
    bedNumber: string;
    bedStatusId: number;
    noOfRooms: number;
    noOfBeds: number;
    availableBeds: number;
    bedStatusName: string;
    createdDate: Date;
    totalItems: number;
    floorId: number;
    floorName: string;
    fullName: string;
    thumbnailUrl: string;
    gender: string;
    age: number;
    admissionNo: string;
    departmentName: string;
    admissionDate: Date;
    providerName: string;
    providerGender: string;
    modifiedDate: Date;
    createdByName: string;
    modifiedByName: string;
    createdByRole: string;
    modifiedByRole: string;
    locationIds: string;
    BookedBed: number;
    chargeCategoryId: number;
    chargeCategoryName: string;

    providerId?: number;
    providerConsultationRoomId: number;
    

    locationName: string;
    locationId: number;
    surgeryName: string;
    surgeryId: string;
    performedTestId: string;
    isNonTransit: boolean;
    utcValues: Date;
    utcValuesCreated: string;
    localDate: Date;
    date; Date;
    cost: number;
}