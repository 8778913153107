import { BookingLabDetail } from "./booking-lab-detail.entity";

export class BookingLabHeader {
    labBookingHeaderId: number;
    patientType: string;
    billNumber: string;
    patientId?: number;
    patientName: string;
    mobile: string;
    doctorName: string;
    netAmount: number;
    paidVia: string;
    createdBy: number;
    createdDate: Date;
    bookingDate: Date;
    createdByName: string;
    isShow: boolean;
    overallDiscount: number;
    labDetail: Array<BookingLabDetail>;
    noOfTest: number;
    totalItems: number;
    umrNo: string;
    gender: string;
    age: number;
    totalAmount: number;
    limit?: number;
    totalElements?: number;
    isLabPackage: boolean;
    isLabService: boolean;
    thumbnailUrl: string;

    admissionId?: number;
    appointmentId?: number;
    otBookingDetailId?: number;
    appointmentNo: string;
    admissionNo: string;

    locationName: string;
    isSelected: boolean;
    collectedFlag: boolean = true;
    barcodeGeneratedName: string;
    barcodeGeneratedDate: Date;

    constructor() {
        this.labDetail = new Array<BookingLabDetail>();
    }
}