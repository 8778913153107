export * from "./lab-header.entity";
export * from "./lab-detail.entity";
export * from "./boking-lab-header.entity";
export * from "./booking-lab-detail.entity";
export * from "./lab-report-main.entity";
export * from "./lab-report-parameter.entity";
export * from "./lab-report-daily.entity";
export * from "./lab-package.entity";
export * from "./lab-in-package-booking.entity";
export * from "./lab-location-charges.entity";
export * from "./lab-department.entity";