export class ScanMachine {
    scanMachineMasterId: number;
    machineName: string;
    displayName: string;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
    scanTestName: string;
    performedTestId: string;
    scanTestMasterId: string;
    ScanTestId: number;
    isSelected: boolean;
    locationNames: string;
    locationId: number;
    locationIds: string;
    modifiedByRole: string;
    createdByRole: string;
}