export class LabReportParameter {
    labPatientParameterId: number;
    labHeaderId: number;
    labDetailId: number;
    refrenceRangeUnit: number;
    testParameter: string;
    referenceRange: string;
    refrenceRangeUnitName: string;
    testParamResult: string;
    typedBy?: number;
    typedDate?: Date;
    typedByName: string;
    verifiedByName: string;
    labBookingPackageDetailId?: number;
    verifiedByDoctorId?: number;
    verifiedByDoctorName: string;
    verifiedByDoctorSignature: string;
}