export class BehavioralFullTranscript {
    //appointmentId: number;
    //patientId: number;
    //patientName: string;
    //encryptedPatientId: string;
    //patientAge?: number;
    //patientDateOfBirth?: Date;
    //patientGender: string;
    //patientMobile: string;
    //patientThumbnailUrl: string;
    //patientCountryCode: string;
    //appointmentDate: Date;
    //appointmentTime: object;
    //appointmentTimeString: string;
    //behavioralHealthId?: number;
    //problemHistory: string;
    //medicalHistory: string;
    //currentSupport: string;
    //currentLiving: string;
    //educationalHistory: string;
    //clientStrengths: string;
    //outComeMeasures: string;
    //substanceHistory: string;
    //workHistory: string;
    //abuseHistory: string;
    //religionSpirituality: string;
    //mentalStatus: string;
    //familyRelationships: string;
    //relapsePredictors: string;
    //patientQuestionnaire: string;
    //generalisedAnxietyDisorder: string;
    //burnsDepression: string;
    //aims: string;
    //genogram: string;
    //notes: string;
    //vitals: string;
    //referralProvider: string;
    //followUp: string;
    //medications: string;
    //treatmentPlan: string;
    //symptoms: string;
    //medicationComment: string;
    //generalAdvice: string;

    appointmentId: number;
    appointmentNo: string;
    patientId: number;
    patientName: string;
    encryptedPatientId: string;
    patientAge?: number;
    patientDateOfBirth?: Date;
    patientGender: string;
    providerName: string;
    patientMobile: string;
    patientThumbnailUrl: string;
    patientCountryCode: string;
    appointmentDate: Date;
    appointmentTime: object;
    appointmentTimeString: string;
    behavioralHealthId?: number;
    problemHistory: string;
    medicalHistory: string;
    currentSupport: string;
    currentLiving: string;
    educationalHistory: string;
    clientStrengths: string;
    outComeMeasures: string;
    substanceHistory: string;
    workHistory: string;
    abuseHistory: string;
    religionSpirituality: string;
    mentalStatus: string;
    familyRelationships: string;
    relapsePredictors: string;
    patientQuestionnaire: string;
    generalisedAnxietyDisorder: string;
    burnsDepression: string;
    aims: string;
    genogram: string;
    notes: string;
    vitals: string;
    referralProvider: string;
    followUp: string;
    medications: string;
    treatmentPlan: string;
    symptoms: string;
    medicationComment: string;
    signature: string;
    clinicPicture: string;
    practiceLocation: string;
    practiceName: string;
    specializationName: string;
    providerNo: string;
    educations: string;
    generalAdvice: string;
    umrNo: string;

}