<style>
    #popoverOfcolor {
        position: absolute;
        z-index: 9;        
        border: 1px solid #d3d3d3;
        width: 220px;
        max-width: 300px;
    }

    #popover-header {
        padding: 10px;
        cursor: move;
        z-index: 10;
        background-color: #2196F3;
        color: #fff;
    }
</style>
<div role="tooltip" id="popoverOfcolor" #popoverOfcolor class="d-none bg-white">
    <div id="popover-header" class="font-weight-bold">Color Identificaton<button (click)="closePopover(popoverOfcolor)" aria-hidden="true" style="color:white;" class="closeBtn" data-dismiss="modal" type="button">×</button></div>
    <div class="popover-body">
        <ul class="p-0">
            <li *ngFor="let item of arrayOfColor" style="list-style: none;">

                <span class="d-flex" style="gap:5px;">
                    <span class="d-inline-block color-option m-0" [ngStyle]="{'background':item.colorPalate}" style="height: 15px; width: 15px; border: 1px solid; border-radius: 0px;min-width:15px;"></span>
                    <span [textContent]="item.description || '---'"> </span>
                </span>
            </li>
        </ul>
    </div>
</div>
