<div class="modal-header bg-primary">
    <h4 class="modal-title text-white"><i class="mdi mdi-file-multiple mr-1"></i>Feedback</h4>
</div>
<div class="modal-body form-group">
    <div>
        <banner-setting></banner-setting>
    </div>
    <form [formGroup]="feedbackForm" (ngSubmit)="onSubmitFeedback()">
        <div>
            <table class="w-100 text-center">
                <tr>
                    <td class="col-4 col-sm-2 pt-2">

                        <div class="form-group mb-0  ">
                            <label class="mb-1">Locations </label>
                           

                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <select class="form-control" formControlName="location">
                            <option class="text-center" value="" disabled selected>Select Location</option>
                            <option class="text-center" *ngFor="let location of locations" [value]="location.id">{{ location.name }}</option>
                        </select>
                    </td>
                </tr>
                <tr><th colspan="2">Please Enter Your Details</th></tr>
                <tr>
                    <td colspan="2">
                        <input type="text" class="form-control text-center text-uppercase" maxlength="50" autocomplete="off"
                               placeholder="MRN" formControlName="mrn">
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <input type="text" class="form-control text-uppercase text-center" maxlength="50" autocomplete="off"
                               placeholder="Name" formControlName="name">
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <input type="text" class="form-control  text-center" numbersOnly maxlength="10" autocomplete="off"
                               placeholder="Mobile Number" formControlName="mobileNumber">
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <label class="mb-1 text-uppercase">Please Choose Type <code>*</code></label>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <div class="form-group position-relative d-inline-flex ">
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" formControlName="patientType" [value]="true"> Inpatient
                                </label>
                            </div>
                            <div class="form-check pl-md-5">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" formControlName="patientType" [value]="false"> Outpatient
                                </label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>How likely is that you would recommend FERNANDEZ Hospital to a friend or family?</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class=" mt-1">
                            <div>
                                <label>Select Rating:</label><br>
                                <div class="btn-group btn-group-toggle" formControlName="rating">
                                    <label *ngFor="let num of [1, 2, 3, 4, 5]" class="btn btn-outline-primary">
                                        <input type="radio" [value]="num" (change)="setRating(num)"> {{ num }}
                                    </label>

                                </div>
                                <div class="btn-group btn-group-toggle" formControlName="rating">
                                    <label *ngFor="let num of [6, 7, 8, 9, 10]" class="btn btn-outline-primary">
                                        <input type="radio" [value]="num" (change)="setRating(num)"> {{ num }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>
                            <textarea class="text-center w-100 border-light" placeholder="Please Comment Your Overall Experience"
                                      formControlName="comment"></textarea>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-sm" (click)="onClearModel();">Clear</button>
            <button type="submit" class="btn btn-primary btn-sm" [disabled]="feedbackForm.invalid">Submit</button>
        </div>
    </form>
</div>