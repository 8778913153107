export class PediatricChartAuthorityModel {
    pediatricChartAuthorityId: number;
    authorityName: string;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number | null;
    modifiedDate: Date | null;
    active: boolean | null;
    createdByName: string;
    modifiedByName: string;
}