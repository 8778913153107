export class EmbryologySummary {
    patientName: string
    patientId: number
    husbandName: string
    dateOfOpu: Date
    age: number
    noOfOocytesRetrieved: string
    maturation: string
    table: Array<EmbryologyTable>
    semenDetails: string
    husbandsamples: Array<HusbandSample>
    consultant: string
    embryologist: string
}
export class EmbryologyTable {
    noOfOocyte: number
    mii: string
    fertilization: string
    clevage: string
    embryoStatus: string
    oocyteComment: string
}
export class HusbandSample {
    husbandSamplePreWash: string
    husbandSamplePostWash: string
}