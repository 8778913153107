import { Injectable } from '@angular/core';
import { CanActivateChild, CanDeactivate, UrlTree } from "@angular/router";
import {JitsiService}  from "../services";
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;
}

@Injectable()
export class TelemedicineGuard implements CanActivateChild, CanDeactivate<CanComponentDeactivate> {

    constructor(
        private readonly jitsiService: JitsiService
    ) {

    }
    canActivateChild() {
        return true;
    }

    canDeactivate(_component: CanComponentDeactivate): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.jitsiService.isRoomActive) {
            this.jitsiService.closeCall(true);
            return false;
        } else {
            return true;
        }
    }

}