import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class FinalBillService {
    private source = new Subject<boolean>();
    get: Observable<boolean> = this.source.asObservable();
    set(isRefresh: boolean) {
        this.source.next(isRefresh);
    }
}
