/* eslint-disable no-debugger */
import { Component, ViewEncapsulation, Output, EventEmitter, OnDestroy } from "@angular/core";
import { UtilHelper } from "@shared/helpers";
declare var onScan: any;

@Component({
    template: `<input type="hidden" id="dummyId" [(ngModel)]="barcodeValue"/>
                <button type="button" hidden id="dummyButtonId" (click)="bindValueToTextBoxOut()"></button>`,
    selector: "barcode-scanner",
    encapsulation: ViewEncapsulation.None
})

export class BarcodeScannerWidget implements OnDestroy {
    barcodeValue: string;
    @Output() scannedBarcode = new EventEmitter<string>();

    constructor() {
        setTimeout(() => {
            if (onScan) {
                onScan.attachTo(document, {
                    suffixKeyCodes: [13],
                    reactToPaste: false,
                    onScan: function (sCode) {
                        setTimeout(() => {
                            let getActiveElement = document.activeElement as any;
                            let x = getActiveElement.id;
                            if (UtilHelper.isEmpty(x)) {
                                let getElement = document.getElementById(x) as any;
                                let contains = x.includes("barCode");
                                if (!contains) {
                                    getElement.value = null;
                                }
                            } else {
                                getActiveElement.value = null;
                            }
                        }, 10);
                        let getElementForBarcode = document.getElementById("dummyId") as any;
                        getElementForBarcode.value = sCode;
                        let getElementForBarcodeButton = document.getElementById("dummyButtonId");
                        getElementForBarcodeButton.click();
                    }
                });
            }
        }, 100);
    }

    bindValueToTextBoxOut() {
        let getElementForBarcode = document.getElementById("dummyId") as any;
        let value = getElementForBarcode.value;
        this.scannedBarcode.emit(value);
    }

    ngOnDestroy() {
        if (onScan && onScan.attachTo) {
            onScan.detachFrom(document);
        }
    }
}