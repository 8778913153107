import { Component, OnInit, OnDestroy, ViewEncapsulation, TemplateRef, Output, EventEmitter, Input } from "@angular/core";
import { Location, DatePipe } from '@angular/common';
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { finalize, takeUntil } from "rxjs/operators";
import { GenericResponse, GenericStatus, Page, IUserAccount, IResource } from "../../../../../shared/models";
import { HttpService, AppData, NotifyService, ResourceService } from "../../../../../shared/services";
import { ApiResources, DateHelper } from "../../../../../shared/helpers";
import { ActivatedRoute, Params } from "@angular/router";
import { IAdmissionModel } from "../../../services/models/admission.model";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { AdmissionFetchHelper, TimelineCommunicationService, PIXEL_PER_LETTER, BUFFER_WIDTH } from "../../shared/helper";
import { INote } from "./helpers/helper";
import { WhiteSpaceValidator } from "../../../../../shared/validators";
import { BedBoardListView } from "../../../../../shared/entities";
import { error } from "highcharts";

@Component({
    selector: "bed-notes",
    templateUrl: "./notes.html",
    styleUrls: ["./notes.css"],
    encapsulation: ViewEncapsulation.None
})
export class ProgressReportNotesPage implements OnInit, OnDestroy {
    @Input() inputAdmissionId?: number;
    @Output() onClose = new EventEmitter<any>();
    @Input() selectedAdmissionnotes: BedBoardListView;
    admissionId: number;
    isAdmission: boolean;
    page: Page;
    modalRef: NgbModalRef;
    loading: boolean;
    swap: boolean;
    admissiondischarge: boolean;
    dateControl: FormControl;
    addForm: FormGroup;

    records: Array<INote>;
    admission: IAdmissionModel;
    imgOrigin: string;

    selected: INote;
    submitted: boolean;
    submitting: boolean;
    widths: { createdBy: number };
    protected datePipe: DatePipe = new DatePipe('en-US');
    minDate = DateHelper.ngbToday;
    loadProvider: boolean;
    providers: IResource[];
    doctorUnitMaster: IResource;

    constructor(
        private readonly httpService: HttpService,
        private readonly modalService: NgbModal,
        private readonly notifyService: NotifyService,
        private readonly appData: AppData,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly locationService: Location,
        private readonly admissionFetchHelper: AdmissionFetchHelper,
        private readonly timelineCommunicationService: TimelineCommunicationService,
        private readonly resourceService: ResourceService
    ) {
        this.page = new Page();
        this.buildAddForm();

        this.widths = {
            createdBy: 130
        }
        this.imgOrigin = location.origin + location.pathname;
        this.records = new Array<any>();
    }


    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;

                    this.route.parent.paramMap
                        .subscribe((params: Params) => {
                            var id = params["params"]["id"];
                            this.inputAdmissionId = parseInt(window.atob(id));
                            this.admissionId = this.inputAdmissionId || +params["params"]["id"];
                            this.isAdmission = this.inputAdmissionId ? true : params["params"]["type"] === "a";
                            this.findDoctorUnitMasterId(this.admissionId);
                            this.loading = true;
                            this.admissionFetchHelper.admissionFetch(this.admissionId, this.isAdmission, (data: IAdmissionModel) => {
                                this.admission = data;
                            });
                            this.fetch();
                        });
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }

    // FORM
    get form() {
        return this.addForm.controls;
    }

    private buildAddForm() {
        //this.dateControl = new FormControl(DateHelper.todayInFormat, [Validators.required]);
        this.addForm = this.formBuilder.group({
            date: [DateHelper.todayInFormat, [Validators.required]],
            note: [null, [Validators.required, WhiteSpaceValidator.isValid]],
            isUnitDoctor: [false],
            providerId: [null]
        });

        this.addForm.get("isUnitDoctor").valueChanges.subscribe((value: boolean) => {
            var provider = this.addForm.get("providerId");
            if (!value) {
                provider.setValidators([Validators.required]);
                provider.enable();
                provider.setValue(null);
            } else {
                provider.clearValidators();
                provider.setErrors(null);
                provider.setValidators(null);
                provider.setValue(null);
                provider.disable();
                this.findDoctorUnitMasterId(+this.admissionId, null);
            }
        });      

    }
    resetAddNoteForm = () => {
        this.addForm.reset();
        this.addForm.patchValue({
            date: this.datePipe.transform(new Date(), 'yyyy-MM-dd')
        });
    }
    // MODEL
    openModifyModel = (content: TemplateRef<any>, record?: INote) => {
        //this.dateControl.clearValidators();
        if (record) {
            this.selected = record;
            //this.dateControl.patchValue(this.selected.date);
            this.addForm.patchValue({
                note: this.selected.note
            })
            //this.dateControl.disable();
        }
        else {
            this.resetAddNoteForm();
        }
        //this.dateControl.updateValueAndValidity();
        this.openModelHelper(content);
    }

    openModelHelper = (content: TemplateRef<any>) => {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: "lg",
            windowClass: "custom-modal effect-scale"
        });
        this.fetchProviders(this.page.userAccount.locationId);
        //this.findDoctorUnitMasterId(+this.admissionId);
    }

    onCloseModal() {
        try {
            this.onClose.emit();
            this.modalRef.close();
            this.modalRef = undefined;
            this.selected = null;
            this.selectedAdmissionnotes = null;
        } catch (e) {
            // ignored;
        }
    }

    fetch = () => {
        this.loading = true;
        if (Number.isNaN(this.admissionId)) {
            this.admissionId = this.selectedAdmissionnotes.admissionId
            this.swap = this.selectedAdmissionnotes.swap;
        }
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReportNote.base, ApiResources.progressReportNote.fetch),
                { admissionId: this.admissionId })
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        var data = response.data;
                        var createdByWidth = Math.max(...data.map(x => x.createdByName.length)) * PIXEL_PER_LETTER;
                        this.widths.createdBy = Math.max(createdByWidth, this.widths.createdBy) + BUFFER_WIDTH - 10;
                        this.records = data;
                    } else {
                        this.notifyService.warning(response.message);
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }

    modify = () => {
        this.submitted = true;
        if (this.addForm.invalid) {
            this.notifyService.infoToast("Please fill the form.");
            return;
        }

        var url = this.selected
            ? ApiResources.progressReportNote.update
            : ApiResources.progressReportNote.insert;

        var data = this.selected
            ? {
                progressReportNoteId: this.selected.progressReportNoteId,
                note: this.addForm.value.note,
                modifiedBy: this.page.userAccount.accountId,
                providerId: this.doctorUnitMaster ? this.doctorUnitMaster.optionalId : null,
                cost: this.doctorUnitMaster ? this.doctorUnitMaster.cost : 0
            }
            : {
                admissionId: +this.admissionId,
                date: this.addForm.value.date,
                note: this.addForm.value.note,
                createdBy: this.page.userAccount.accountId,
                providerId: this.doctorUnitMaster ? this.doctorUnitMaster.optionalId : null,
                cost: this.doctorUnitMaster ? this.doctorUnitMaster.cost : 0

            }

        this.submitting = true;
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReportNote.base, url), data)
            .pipe(finalize(() => this.submitting = false))
            .subscribe(
                {
                    next: (response: GenericResponse) => {
                        if (response.status === GenericStatus[GenericStatus.Success]) {
                            this.fetch();
                            this.timelineCommunicationService.set(true);
                            this.onCloseModal();
                        } else {
                            this.notifyService.warning(response.message);
                        }
                    },
                    error: (error:any) => {
                           this.notifyService.defaultError();
                    }
                }
            );
    }

    delete = (item: INote) => {
        item.deleting = true;
        this.notifyService.confirm('Are you sure you want to delete this note?', () => {
            this.httpService
                .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReportNote.base, ApiResources.progressReportNote.delete), { ProgressReportNoteId: item.progressReportNoteId })
                .pipe(finalize(() => item.deleting = false))
                .subscribe(
                    (response: GenericResponse) => {
                        if (response.status === GenericStatus[GenericStatus.Success]) {
                            this.fetch();
                            this.timelineCommunicationService.set(true);
                        } else {
                            this.notifyService.warning(response.message);
                        }
                    },
                    () => {
                        this.notifyService.defaultError();
                    }
                );
        }, () => {
            item.deleting = false;
        });
    }

    private fetchProviders(id?: number) {
        this.loadProvider = true;
        this.resourceService.providerAccountLocationMap(id)
            .pipe(finalize(() => { this.loadProvider = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.providers = response;
            });
    }


    private findDoctorUnitMasterId(admissionId: number, providerId?: number,specializationId?: number) {
        this.loadProvider = true;
        this.resourceService.doctorUnitMasterId(admissionId, providerId, specializationId)
            .pipe(finalize(() => { this.loadProvider = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: any) => {
                this.doctorUnitMaster = response;
            });
    }

    onBack = () => {
        this.locationService.back();
    }

    changeProvider(event: any) {
        if (event) {
            this.addForm.get("providerId").setValue(event.id);
            this.findDoctorUnitMasterId(+this.admissionId, event.id, event.optionalId3);
        }
    }
}
