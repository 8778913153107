import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "toArray"
})
export class ToArrayPipe implements PipeTransform {
    transform(value: any) {
        if (!value) {
            return [];
        }

        if (value.indexOf(",") < 0) {
            return [value];
        }

        return value.split(",");
    }
}