export class PayCategory {
    payCategoryId: number;
    payCategoryName: string;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    createdByName: string;
    modifiedByName: string;
    createdByRole: string;
    modifiedByRole: string;
}