export class SelectionInfo {
    start: Date;
    end: Date;
    startStr: string;
    endStr: string;
    allDay: boolean;
}

export class FetchInfo {
    start: Date;
    end: Date;
    startStr: string;
    endStr: string;
}