import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "fromArray"
})
export class FromArrayPipe implements PipeTransform {
    transform(value: Array<string>) {
        if (!value && !value.length) {
            return "";
        }

        return value.join(", ");
    }
}