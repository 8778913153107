export class Setting {
    settingsId: number;
    name: string;
    active: boolean;
    type: string;
    description: string;
    value: any;
    ///
    imageUrl: string;
} 

export class PatientSetting {
    name: string;
    active: boolean;
} 