export class Components {
    componentId: number;
    componentTypeId: number;
    createdBy: number;
    modifiedBy: number;
    createdDate: string;
    modifiedDate: string;
    active: boolean;
    name: string;
    subName: string;
    totalItems: number;
    createdByName: string;
    modifiedbyName: string;
}