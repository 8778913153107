export class GraphView{
    xValues: Array<string>;
    yValues: Array<number>;
    titleName: string;
    yparamName: string;
    xparamName: string;
    height: string;
    width: string;
    constructor() {
        this.xValues = new Array<string>();
        this.yValues = new Array<number>();
    }
}