import { FormControl } from "@angular/forms";
import { RegExHelper } from "../helpers/regex.helper";

export class WeightValidator {
    static isValid(control: FormControl): object | null {
        const value = control.value;
        if (!value || !control.parent) {
            return null;
        }
        if (!RegExHelper.twoDecimalsOnly.test(value)) {
            return { 'invalid': true };
        }
            let num: number = 0;
            let dotbefore: number = 0;
            let dotafter: number = 0;
            if (value) {
                var replaced = value.replace(/[^\d.]/g, '');
                for (let i = 0; i < replaced.length; i++) {

                    if (replaced[i] == '.') {
                        num++;
                        if (num == 2 || i == 0) {
                            return { 'invalid': true };
                        } 
                    }
                    if (num == 0) {
                        dotbefore++;
                        if (dotbefore > 3) {
                            return { 'invalid': true };
                        } 
                    }
                    if (num == 1) {
                        dotafter++;
                        if (dotafter > 3) {
                            return { 'invalid': true };
                        } 
                    }
                }
            }
    }
}