import { Injectable } from "@angular/core";

@Injectable()
export class QueueService {
    selectedProvider: BasicProvider;
    isInterval: any;
}

export interface BasicProvider {
    name: string;
    id: number;
}
