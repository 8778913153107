import { LookUpValue } from "../../../../../shared/entities";

export class LabParameterMethod {
    labParameterMethodId: number;
    methodName: string;
    active: boolean;
    createdDate: Date;
    createdBy: number;
    modifiedDate: Date;
    modifiedBy: number;
    createdByName: string;
    createdByRole: string;
    modifiedByName: string;
    modifiedByRole: string;
}
export class AntibioticTagMaster {
    name: string;
    antibioticTagMasterId: number;
    antiBioticOne: string;
    tagsAntibiotic: any;
    lookups: any;
    tagsAntibioticDetails: any;

}