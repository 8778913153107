export class PharmacyRack {
    pharmacyProductRackId: number;
    inventoryProductRackId: number;
    createdBy: number;
    retailPharmacyId: number;
    pharmacyDepartmentId: number;
    inventoryDepartmentId: number;
    inventoryWareHouseId: number;
    rackName: string;
}
