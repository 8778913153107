export class Settlement {
    providerId: number;
    providerName: string;
    email: string;
    noOfAppointments: number;
    amount: number;
    discount: number;
    providerPercentage: number;
    tDSAmount: number;
    appCharges: number;
    setteledAmount: number;
    payout: number;
    setteledByPayout: number;
    refund: number;
    moneySettled: number;
    totallItems: number;
    moneyWithPayU: number;
}