<form [formGroup]="mainForm">
    <div class="content">
        <div class="container-fluid">
            <div class="d-flex justify-content-between align-items-center">
                <div class="top-menu">
                    <div class="form-group mb-2 mt-2 w-250-px">
                        <ng-select class="ng-select-sm text-uppercase" [items]="locations"
                                   bindLabel="name"
                                   bindValue="id"
                                   autocomplete="nope"
                                   placeholder="Select location"
                                   formControlName="locationId">
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                <div class="ng-option disabled">
                                    No location found for '{{searchTerm}}'
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <div class="legend-holder">
                        <div *ngFor="let shift of shifts" class="legend-container">
                            <div class="legend-block" [ngStyle]="{'background-color': shift.optionalText}"></div>
                            <div [textContent]="shift.value"></div>
                        </div>
                    </div>
                    <ng-container *ngIf="selectedCount">
                        <div class="form-group mb-0 mr-2">
                            <select class="form-control-sm" formControlName="type">
                                <option selected [value]="'last_week'">Last Week</option>
                                <option [value]="'last_day'">Last Day</option>
                                <option [value]="'2_week_back'">2 Week Back</option>
                                <option [value]="'3_week_back'">3 Week Back</option>
                            </select>
                        </div>
                        <button (click)="onCopyShifts()" type="button" [disabled]="loadingCopyShifts" class="btn btn-sm btn-primary">
                            <ng-container *ngIf="loadingCopyShifts">please wait</ng-container>
                            <ng-container *ngIf="!loadingCopyShifts"><i class="mdi mdi-content-copy mr-2" *ngIf="!loadingCopyShifts"></i> Copy Shifts</ng-container>
                        </button>
                    </ng-container>
                </div>
            </div>
            <div class="row" *ngIf="loading">
                <div class="col-12">
                    <div class="d-flex align-items-center">
                        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                        <span class="ml-2">Please wait while loading Nurse ...</span>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!loading && !records.length">
                <div class="col-12 mh-300">
                    <no-data [title]="'Nurses'"></no-data>
                </div>
            </div>
            <div class="row" *ngIf="!loading && records.length">
                <div class="col-12">
                    <div class="card mb-0">
                        <div class="card-body p-0">
                            <div class="table-responsive-sm table-responsive-md table-responsive-lg">
                                <table class="table table-centered table-sm table-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th rowspan="2">Name</th>
                                            <th [attr.colspan]="dates.length">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <div class="d-flex align-items-center">
                                                        <div (click)="previous()" class="d-flex align-items-center next-btn mr-2"><span class="mr-1">Previous Week</span><i class="mdi mdi-24px mdi-arrow-left-bold"></i></div>
                                                        <button class="btn btn-sm btn-light mx-3" (click)="goToToday()"><i class="mdi mdi-calendar-month mb-0 mr-1"></i> Today</button>
                                                        <div (click)="next()" class="d-flex align-items-center next-btn ml-2"><i class="mdi mdi-arrow-right-bold mdi-24px"></i><span class="ml-1">Next Week</span></div>
                                                    </div>
                                                </div>
                                            </th>
                                            <th rowspan="2" class="text-center vertical-align-middle" style="vertical-align: middle !important">Working Hours</th>
                                        </tr>
                                        <tr>
                                            <th *ngFor="let date of dates" [class.table-warning]="date.isToday" [textContent]="date.fDate | date:'MMM d, y, EEE'"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr formArrayName="selected" *ngFor="let item of records; let i = index">
                                            <ng-container [formGroupName]="i">
                                                <td>
                                                    <div class="position-relative">
                                                        <div class="media cursor-pointer" (click)="check(i)">
                                                            <div class="avatar-sm mr-2">
                                                                <avatar-img *ngIf="item.thumbnailUrl" [src]="item.thumbnailUrl" [alt]="item.fullName" cssClass="img-fluid rounded-circle"></avatar-img>
                                                                <span *ngIf="!item.thumbnailUrl" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="i">
                                                                    <span [textContent]="item.fullName | initials"></span>
                                                                </span>
                                                            </div>
                                                            <div class="media-body">
                                                                <h5 class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="item.fullName | titlecase"></h5>
                                                                <span class="d-block font-13">
                                                                    <span class="text-muted" [textContent]="item.roleName"></span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="ml-4 mt-1">
                                                            <div class="d-flex align-items-center">
                                                                <a ngbTooltip="View" placement="left" href="javascript:;" class="action-btn mr-2" [routerLink]="['/app/nurse-module/view', item.accountEncryptId]"><i class="mdi mdi-eye"></i></a>
                                                                <a ngbTooltip="Edit" placement="left" href="javascript:;" class="action-btn mr-2" [routerLink]="['/app/nurse-module/edit', item.accountEncryptId]"><i class="mdi mdi-pencil"></i></a>
                                                                <a ngbTooltip="View Actions" placement="left" href="javascript:;" class="action-btn mr-2" [routerLink]="['/app/nurse-module/actions', item.accountEncryptId]"><i class="mdi mdi-gesture-tap"></i></a>
                                                                <!--<a (click)="openModal(templateCopyShifts, item)" ngbTooltip="Copy Shifts" placement="left" href="javascript:;" class="action-btn"><i class="mdi mdi-content-copy"></i></a>-->
                                                            </div>
                                                        </div>
                                                        <div class="select-div">
                                                            <input type="checkbox" class="form-control-sm" formControlName="is" />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td [class.table-warning]="subItem.isToday" *ngFor="let subItem of item.dates">
                                                    <ng-container *ngIf="subItem.isExists">
                                                        <div class="shift-holder">

                                                            <ng-container *ngFor="let bedDetail of subItem.shiftBeds">
                                                                <ng-template #bedViewTemplate>
                                                                    <div>
                                                                        <ng-container *ngTemplateOutlet="bedViewHelperTemplate; context: {subItem: subItem, mainItem: item, bedDetail: bedDetail, accountEncryptId: item.accountEncryptId}"></ng-container>
                                                                    </div>
                                                                </ng-template>
                                                                <div [placement]="subItem.placement"
                                                                     [ngbPopover]="bedViewTemplate"
                                                                     #popoverEl="ngbPopover"
                                                                     container="body"
                                                                     (mouseenter)="openPopover(item, subItem, bedDetail, popoverEl)"
                                                                     popoverTitle="Assigned Beds"
                                                                     class="shift-block p-2 d-flex align-items-flex-start justify-content-between"
                                                                     [ngStyle]="{'background-color': bedDetail.rowColor}">
                                                                    <div class="d-flex flex-column">
                                                                        <div class="d-flex align-items-baseline">
                                                                            <h2 class="m-0" [textContent]="bedDetail.bedIds.length"></h2>
                                                                            <span class="font-12 ml-1">Beds</span>
                                                                        </div>
                                                                        <div [textContent]="bedDetail.shiftName"></div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="dropdown show">
                                                                            <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown">
                                                                                <ng-container *ngIf="!subItem.isDeleting">
                                                                                    <i class="mdi mdi-dots-vertical"></i>
                                                                                </ng-container>
                                                                                <ng-container *ngIf="subItem.isDeleting">
                                                                                    <i class="mdi mdi-spin mdi-loading"></i>
                                                                                </ng-container>
                                                                            </a>
                                                                            <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end">
                                                                                <a [routerLink]="['/app/nurse-module/view', item.accountEncryptId, subItem.eDate]" class="dropdown-item py-0 px-1" href="javascript:;"><i class="mdi mdi-eye mr-1"></i> View</a>
                                                                                <a [routerLink]="['/app/nurse-module/edit', item.accountEncryptId, subItem.eDate]" class="dropdown-item py-0 px-1" href="javascript:;"><i class="mdi mdi-pencil mr-1"></i> Edit</a>
                                                                                <a (click)="deleteSlot(item, subItem)" class="dropdown-item py-0 px-1" href="javascript:;"><i class="mdi mdi-trash-can mr-1"></i> Delete</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <div *ngIf="!subItem.isExists" class="unshift-block p-2 d-flex flex-column align-items-center justify-content-between" (mouseenter)="openPopover(item, subItem)">
                                                        <a href="javascript:;" [routerLink]="['/app/nurse-module/edit', item.accountEncryptId, subItem.eDate]"><i class="mdi mdi-plus-thick mdi-24px mdi-24px"></i></a>
                                                        <h4 class="text-muted m-0">Unassigned</h4>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h4 [textContent]="(item.totalHours || 0) + ' Hours'"></h4>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<ng-template #bedViewHelperTemplate let-subItem="subItem" let-mainItem="mainItem" let-bedDetail="bedDetail" let-accountEncryptId="accountEncryptId">
    <ng-container *ngIf="subItem.loadingBeds">
        <div class="d-flex align-items-center justify-content-center">
            <span class="mdi mdi-36px mdi-spin mdi-loading"></span>
        </div>
    </ng-container>
    <div *ngIf="!subItem.loadingBeds" class="d-flex mb-2 align-items-center justify-content-center">
        <button class="btn btn-sm m-0 btn-primary" (click)="openSelectBed(subItem, mainItem)"><i class="mdi mdi-plus mr-1"></i>Add Bed</button>
    </div>
    <ng-container *ngIf="subItem.beds && !subItem.loadingBeds">
        <div class="overflow-auto pr-2" [ngStyle]="{'max-height.px': availableSpace}">
            <ng-container *ngFor="let item of subItem.beds">
                <div class="basic-bed-block px-2 py-1 mb-1">
                    <div>
                        <div class="d-flex align-items-center justify-content-between">
                            <h6 class="mb-0 text-capitalize">
                                <i class="mdi mdi-bed mr-1"></i><span [textContent]="item.bedName"></span>
                            </h6>
                            <div *ngIf="item.priorityName">
                                <i [class]="'mdi mr-1 mdi-' + item.priorityIcon + ' ' + item.priorityColor"></i>
                                <span class="text-muted" [textContent]="item.priorityName"></span>
                            </div>
                        </div>
                        <div>
                            <span class="text-black-50 small">
                                <ng-container *ngIf="item.floorName">
                                    <span [textContent]="item.floorName" class="mr-1"></span>
                                    <span class="mdi mdi-circle-medium mr-1"></span>
                                </ng-container>
                                <span [textContent]="item.wardName" class="mr-1"></span>
                                <span class="mdi mdi-circle-medium mr-1"></span>
                                <span [textContent]="item.roomName" class="mr-1"></span>
                            </span>
                        </div>
                    </div>
                    <hr class="m-0" />
                    <div class="d-flex align-items-center justify-content-center" *ngIf="!item.admissionNo">
                        <h5 class="text-muted empty-bed">Empty Bed</h5>
                    </div>
                    <div class="mt-1 d-flex" *ngIf="item.admissionNo">
                        <div class="media cursor-pointer">
                            <div class="avatar-xs mr-2">
                                <avatar-img *ngIf="item.patientImage" [src]="item.patientImage" [alt]="item.patientName" cssClass="img-fluid rounded-circle"></avatar-img>
                                <span *ngIf="!item.patientImage" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="2">
                                    <span [textContent]="item.patientName | initials"></span>
                                </span>
                            </div>
                            <div class="media-body">
                                <h6 class="mb-0 mt-0 font-weight-normal text-capitalize" [textContent]="item.patientName | titlecase"></h6>
                                <span class="d-block font-12">
                                    <span [textContent]="item.patientGender | gender"></span>
                                    <span class="ml-1" [textContent]="item.patientAge + ' yrs'"></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <div class="d-flex m-2 align-items-center justify-content-center">
        <button class="btn btn-sm m-0 btn-danger" (click)="closePopover(subItem)"><i class="mdi mdi-close mr-1"></i>close</button>
    </div>
</ng-template>
<ng-template #templateCopyShifts>
    <form [formGroup]="copyForm">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-bed mr-1"></i>Copy Shifts</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="close();">×</button>
        </div>
        <div class="modal-body">
            <div class="d-flex">
                <h6><span [textContent]="selectedNurses.length"></span> Nurse selected</h6>
            </div>
            <div class="form-group mt-2">
                <label>
                    Copy From
                </label>
                <select class="form-control" formControlName="fromType">
                    <option selected hidden [ngValue]="null">Select Type</option>
                    <option value="1">Last Week</option>
                    <option value="2">last 2 Weeks</option>
                    <option value="3">Custom</option>
                </select>
            </div>
            <div *ngIf="copyForm.value.fromType === '3'" class="mt-2">
                <ngb-datepicker (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden"></ngb-datepicker>
                <ng-template #t let-date let-focused="focused">
                    <span class="custom-day"
                          [class.focused]="focused"
                          [class.range]="isRange(date)"
                          [class.faded]="isHovered(date) || isInside(date)"
                          (mouseenter)="hoveredDate = date"
                          (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                                                        </span>
                                                        </ng-template>
                                                    </div>
            <div class="form-group mt-2">
                <label>
                    Until
                </label>
                <div></div>
                <ngb-datepicker formControlName="until"></ngb-datepicker>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary btn-sm mr-1"> Submit</button>
            <button type="button" class="btn btn-light btn-sm mr-1" (click)="close();">Close</button>
        </div>
    </form>
</ng-template>

<ng-template #templateSelectBed>
    <nurse-select-bed [modalRef]=modalRef [nurseAccountEncryptId]=nurseAccountEncryptId></nurse-select-bed>
</ng-template>

<shift-assign [showOnlyModal]="true" (bedAddComplete)="onBedAddComplete($event)"></shift-assign>