import { Directive, Input, forwardRef, HostListener, ElementRef, Renderer2 } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Directive({
    selector: "input[type=checkbox][trueFalseValue]",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TrueFalseValueDirective),
            multi: true
        }
    ]
})

export class TrueFalseValueDirective implements ControlValueAccessor {
    private propagateChange(_: any) { }

    @Input() trueValue = true;
    @Input() falseValue = false;
    functions: any;
    disable: boolean;

    constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2) { }

    @HostListener("change", ["$event"])

    onHostChange(ev) {
        this.propagateChange(ev.target.checked ? this.trueValue : this.falseValue);
    }

    writeValue(obj: any): void {
        if (obj === this.trueValue) {
            this.renderer.setProperty(this.elementRef.nativeElement, "checked", true);
        } else {
            this.renderer.setProperty(this.elementRef.nativeElement, "checked", false);
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void { this.functions = fn; }

    setDisabledState?(isDisabled: boolean): void { this.disable = isDisabled; }
}