import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct, NgbDateAdapter } from "@ng-bootstrap/ng-bootstrap";
import { isNumber, padNumber, toInteger, DateHelper } from "@shared/helpers/date.helper";

@Injectable()
export class DateParserFormatter extends NgbDateParserFormatter {
    parse(value: string): NgbDateStruct {
        if (value) {
            const dateParts = value.trim().split("/");
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return { year: toInteger(dateParts[0]), month: null, day: null };
            } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return { year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null };
            } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                return { year: toInteger(dateParts[2]), month: toInteger(dateParts[1]), day: toInteger(dateParts[0]) };
            }
        }
        return null;
    }

    format(date: NgbDateStruct): string {
        let stringDate = "";
        if (date) {
            stringDate += isNumber(date.month) ? padNumber(date.month) + "/" : "";
            stringDate += isNumber(date.day) ? padNumber(date.day) + "/" : "";
            stringDate += date.year;
        }
        return stringDate;
    }
}

@Injectable()
export class NgbUTCStringAdapter extends NgbDateAdapter<string> {
    fromModel(date: string): NgbDateStruct {
        return DateHelper.toNgbDateStruct(date);
    }

    toModel(date: NgbDateStruct): string {
        return DateHelper.toString(date);
    }
}