import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from "@angular/router";
import { Observable } from 'rxjs';

export interface ICanComponentDeactivate {
  canDeactivate: () => Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;
}

@Injectable()
export class DeactivateGuard implements CanDeactivate<ICanComponentDeactivate> {
    
    canDeactivate(component: ICanComponentDeactivate): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return component.canDeactivate();
    }

}