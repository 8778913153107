import { Injectable } from "@angular/core";
import { HttpService } from ".";
import { Setting } from "../entities";
import { ApiResources, UtilHelper } from "../helpers";

@Injectable()
export class IconService {
    constructor(private readonly httpService: HttpService) { }

    getIconImage(callback?: Function) {
        this.httpService

            .get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), { type: "Icon", active: true }, true)
            .subscribe({
                next: (response: Array<Setting>) => {
                    if (response && response.length > 0) {
                        if (response[0].active) {
                            if (UtilHelper.isEmpty(response[0].imageUrl)) {
                                response[0].imageUrl = `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${response[0].imageUrl}`;
                                callback(response[0].imageUrl);
                            }
                        }
                    }
                },
                error: () => {
                    callback();
                }
            });
    }
}
