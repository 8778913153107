import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { NgbModal, NgbModalRef, NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectComponent } from "@ng-select/ng-select";
import { AppointmentDashboard, AuditLog, ICubicle, Notification, Setting, UserAlerts, WebAlerts } from "@shared/entities";
import { MenuType, Role } from "@shared/enums";
import { ApiResources, DateHelper, LinqHelper, UtilHelper } from "@shared/helpers";
import { GenericResponse, GenericStatus, HttpErrorType, ICategoryMenuModel, IMenuModel, IMenuPatientModel, IResource, IUser, IUserAccount, Page, Pagination } from "@shared/models";
import { AppData, CommunicationService, HttpErrorService, HttpService, IdentityService, JitsiService, MenuService, NotifyService, RealTimeNodeService, ResourceService, SettingService, TimelineToggleService, appUrls } from "@shared/services";
import { CompareValidator } from "@shared/validators";
import { Observable, Subject, Subscription, combineLatest, of } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, finalize, switchMap, takeUntil, tap } from "rxjs/operators";
const CUBICLE_PAGE_SIZE = 5;

class Settings {
    providerId: number;
    theme = "light";
    enableEmailAlerts: boolean;
    enableSMSAlerts: boolean;
    enableDesktopNotifications: boolean;
    enableMobileNotifications: boolean;
}

class MenuHolder {
    menuName: string;
    menuUrl: string;
    mainPage: string;
    subMenus: Array<IMenuModel>;
}

@Component({
    templateUrl: "./layout.html",
    styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, OnDestroy {
    @ViewChild("templateModify", { static: true }) templateModify: TemplateRef<any>;
    @ViewChild("templateCubicle", { static: false }) templateCubicle: TemplateRef<any>;
    @ViewChild("templateWebAlerts", { static: false }) templateWebalerts: TemplateRef<any>;
    today = DateHelper.ngbToday;
    minDate = DateHelper.minDate;
    page: Page;
    role = Role;
    menuType = MenuType;
    httpErrorType = HttpErrorType;
    selectedPatient: IResource;
    searching = false;
    menuSearching = false;
    searchFailed = false;
    auditlog: AuditLog;
    modalRef: NgbModalRef;
    changePasswordForm: FormGroup;
    submitting: boolean;
    submitted: boolean;
    settings: Settings;
    isAdmission: boolean;
    userForm: FormGroup;
    user: IUser;
    userLoading: boolean;
    appointment: AppointmentDashboard;
    showTimeline = false;
    encrypPatId: any;
    appointmentId: number;
    loading: boolean;

    menus: Array<IMenuModel>;
    allMenus: Array<IMenuModel>;
    allMenuAndSubMenu: Array<IMenuPatientModel>;
    menuHolder: MenuHolder;
    isHovering: boolean;
    defaultMenu: string;
    logCount = 0;
    isHitMenu: boolean;
    env: string;
    envTitle: string;
    activeRoute: string;
    logoSubscription: Subscription;
    logoBasics: Setting;//
    imageType: string;
    showTicket: boolean;
    notifications: Array<Notification>;
    assignedCubicles: Array<ICubicle>;
    unassignedCubicles: Array<ICubicle>;
    notificationCount: number;
    publicIp: string;
    privateIp: string;
    cubiclesLoading: boolean;
    defaultImgUrl = 'assets/images/broken.png';
    url: string;
    loadingSettings: boolean;
    isTheme: boolean;
    themeColor: any;

    providerId: number;
    providersInput = new Subject<string>();
    providers: Observable<Array<IResource>>;
    loadingProviders: boolean;
    isAssigningDoctor: boolean;
    currentAssignedCubicles: Array<ICubicle>;
    currentUnAssignedCubicles: Array<ICubicle>;
    unAssignedCubiclespagination: Pagination;
    assignedCubiclespagination: Pagination;
    unAssignedCubiclespaginationFlag = true;
    sideSubMenu: any = document.getElementsByClassName("sidebar-main-menu");
    loadingWebAlerts: boolean;
    webAlerts: Array<WebAlerts>;
    userAlerts: Array<UserAlerts>;
    index: number = null;
    unreadCount = 0;
    userCount = 0;
    webAlertCount = 0;
    admissionAlerts = false;
    dischargeAlerts = false;
    refresh = true;
    labHodAlerts = false;
    paymentAlerts = false;

    timelineData: any[] = [];
    isAll = false;
    showData = false;
    dropdownValue = false;
    subMenuValue = false;
    eventId: any;
    specialityId: any;
    parentId: any;
    message = null;
    constructor(
        private readonly identityService: IdentityService,
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly modalService: NgbModal,
        private readonly notifyService: NotifyService,
        private readonly resourceService: ResourceService,
        private readonly httpService: HttpService,
        private readonly menuService: MenuService,
        private readonly formBuilder: FormBuilder,
        public httpErrorService: HttpErrorService,
        public settingService: SettingService,
        public communicationService: CommunicationService,
        public readonly jitsiService: JitsiService,
        public readonly realTimeNodeService: RealTimeNodeService,
        public timelineService: TimelineToggleService,
        //private sanitizer: DomSanitizer
    ) {
        this.setUpMenu();
        this.page = new Page();
        this.auditlog = new AuditLog();
        this.settings = new Settings();//
        this.logoBasics = new Setting();
        this.assignedCubicles = new Array<ICubicle>();
        this.unassignedCubicles = new Array<ICubicle>();
        this.communicationService.createConnection(() => {
            this.communicationService.startConnection("QueueManagement");
        });
        this.unAssignedCubiclesinitPagination();
        this.assignedCubiclesinitPagination();
        this.webAlerts = new Array<WebAlerts>();
        this.userAlerts = new Array<UserAlerts>();

    }
    private unAssignedCubiclesinitPagination() {
        this.unAssignedCubiclespagination = new Pagination();
        this.unAssignedCubiclespagination.pageIndex = 1;
        this.unAssignedCubiclespagination.pageSize = 10;
    }
    private assignedCubiclesinitPagination() {
        this.assignedCubiclespagination = new Pagination();
        this.assignedCubiclespagination.pageIndex = 1;
        this.assignedCubiclespagination.pageSize = 10;
    }

    onMenuClear = (input: HTMLInputElement) => {
        input.value = '';
    }

    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`;
        }
        return this.defaultImgUrl;
    }

    private onFetchPublicIp() {
        fetch('https://jsonip.com', { mode: 'cors' })
            .then((resp) => resp.json())
            .then((ip) => {
                this.publicIp = ip.ip;
                this.jitsiService.ipAddress = ip.ip;
            });
    }

    private onReadPrivateIp() {
        // this.privateIp = document.getElementById("ip").innerHTML;
    }

    onNotificationToggle() {
        if (!this.showTicket) {
            this.showTicket = true;
            this.fetchNotifications();
        } else {
            this.showTicket = false;
        }

    }

    onNavigate(item: Notification) {
        this.showTicket = false;
        if (UtilHelper.isEmpty(item.redirectionLink) && UtilHelper.isEmpty(item.referenceId)) {
            this.router.navigateByUrl(`${item.redirectionLink}/${item.encryptedReferenceId}`);
        }
    }

    setUpMenu = () => {
        this.defaultMenu = this.menuService.getDefaultRoute;
        this.menus = this.menuService.menus(MenuType.MainMenu);
        this.allMenus = this.menuService.menus().filter(x => x.mainPage);
        this.allMenus = this.menuService.menus().filter(x => (x.url || '').toLowerCase().indexOf(':id'.toLowerCase()) === -1 && x.mainPage !== null && (x.menuTypeId !== 4))
        this.allMenuAndSubMenu = this.menuService.menus();
        this.menuHolder = new MenuHolder();
        this.menuHolder.subMenus = new Array<IMenuModel>();
        this.setMenuMarkers();
    }

    @HostListener("window:scroll", ["$event"])
    onScroll(event) {
        const $toTop = $(".btn-scroll-top");
        if ($(event.currentTarget).scrollTop() !== 0) {
            $toTop.fadeIn();
        } else {
            $toTop.fadeOut();
        }
    }
    scrollToTop() {
        $("body,html").animate(({ scrollTop: 0 }) as any, 500);
    }
    @HostListener('window:resize')
    onresize() {
        if (window.innerWidth >= 993 && document.getElementsByClassName("sidebar-main-menu").length !== 0) {
            document.getElementsByClassName("sidebar-main-menu")[0].classList.remove("removeLeft");
            //$("body")[0].setAttribute("data-sidebar-size", "default");
        }
    }
    @HostListener('window:load')
    onload() {
        if (window.innerWidth >= 993 && document.getElementsByClassName("sidebar-main-menu").length !== 0) {
            let documentClassList = document.getElementsByClassName("sidebar-main-menu");
            if (documentClassList) {
                documentClassList[0]?.classList?.remove("removeLeft");
            }
            //$("body")[0].setAttribute("data-sidebar-size", "default");
        }
    }

    onToggleMenu(event: Event) {
        event.preventDefault();

        const sidebarSize = $("body").attr("data-sidebar-size");
        if ($("window").width() >= 993) {
            if (sidebarSize === "condensed") {
                //self.changeSize(defaultSidebarSize);
            }
            //else {
            //    //self.changeSize('condensed');
            //}
        } else {
            //self.changeSize(defaultSidebarSize);
            $("body").toggleClass("sidebar-enable");
        }
    }


    formatMatches = (item: IResource) => item.value = "";

    formatMenuMatches = (item: IMenuModel) => item.displayName || "";

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(100),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap((term: string) =>
                term.length < 2 ? of([]) : this.resourceService
                    .patients(term)
                    .pipe(
                        tap(() => this.searchFailed = false),
                        catchError(() => {
                            this.searchFailed = true;
                            return of([]);
                        })
                    )
            ),
            tap(() => this.searching = false)
        );

    menuSearch = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.menuSearching = true),
            switchMap((term: string) => {
                var tokens = term.split('/');
                var dashTerm = (tokens.length > 1 ? tokens[1] : term).replace(/\s+/g, '-').toLowerCase();
                return tokens.length > 1
                    ? of((this.allMenus.filter(x =>
                        (x.mainPage || '').toLowerCase().indexOf(tokens[0].trim().toLowerCase()) !== -1 &&
                        ((x.subPage || '').toLowerCase().indexOf(tokens[1].trim().toLowerCase()) !== -1 ||
                            (x.subPage || '').toLowerCase().indexOf(dashTerm.trim().toLowerCase()) !== -1 ||
                            (x.displayName || '').toLowerCase().indexOf(tokens[1].trim().toLowerCase()) !== -1
                        ))) || [])
                    : of((this.allMenus.filter(x =>
                        (x.mainPage || '').toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
                        (x.subPage || '').toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
                        (x.subPage || '').toLowerCase().indexOf(dashTerm.trim().toLowerCase()) !== -1 ||
                        (x.displayName || '').toLowerCase().indexOf(term.toLowerCase()) !== -1)) || []);
            }),
            tap(() => this.menuSearching = false)
        );

    onSelectPatient(event: NgbTypeaheadSelectItemEvent) {
        if (event.item && (event.item as IResource).encryptedId) {
            this.encrypPatId = event.item.id;

            this.router.navigate([]).then(() => { location.assign(`${location.pathname}#/app/patient/${event.item.encryptedId}`); });


        } else {
            event.preventDefault();
        }
    }



    toggleIt(x?) {
        if (x) { this.timelineService.toggle(true); }
        else {
            this.timelineService.toggle();
        }
        if (this.timelineService.showBox == false) {
            $("body")[0].setAttribute("data-timeline-size", "timelineabsent");
            $('.encounterBtn .mdi-chevron-right').toggleClass('d-none');
            $('.encounterBtn .mdi-chevron-left').toggleClass('d-none');
        } else {
            $("body")[0].setAttribute("data-timeline-size", "timeline");
            $('.encounterBtn .mdi-chevron-right').toggleClass('d-none');
            $('.encounterBtn .mdi-chevron-left').toggleClass('d-none');
        }
    }
    onSelectMenu(event: NgbTypeaheadSelectItemEvent, input: HTMLInputElement) {

        event.preventDefault();
        if (event.item && (event.item as IMenuModel).url) {
            this.router.navigate([event.item.url]);
            input.value = '';
        }
    }
    private buildUserForm() {
        this.userForm = this.formBuilder.group({
            userId: 0,
            firstName: [null, [Validators.required]],
            lastName: [null, [Validators.required]],
            middleName: [null],
            gender: [null],
            roleId: [null, [Validators.required]],
            dateOfBirth: [null],
            email: [null, [Validators.required]],
            mobile: [null, [Validators.required]],
            countryName: [null, [Validators.required]],
            base64ProfileImage: [null],
            profileImageUrl: [null],
            providerLocationId: [{ value: null, disabled: true }, [Validators.required]],
            provider: [{ value: null, disabled: true }, [Validators.required]]
        });
    }

    private updateForm() {
        this.userForm.patchValue({
            userId: this.user.userId,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            middleName: this.user.middleName,
            email: this.user.email,
            mobile: this.user.mobile,
            countryName: this.user.countryName,
            roleId: this.user.roleId,
            providerLocationId: this.user.providerLocationId,
            gender: this.user.gender,
            dateOfBirth: this.user.dateOfBirth,
            profileImageUrl: this.user.profileImageUrl
        });
    }

    get profileForm() { return this.userForm.controls; }
    //For Removing Profile filed from the LayOut
    //onProfile() {
    //    if (this.page.userAccount.roleId === Role.Provider) {
    //        this.router.navigateByUrl(`app/providers/${this.page.userAccount.encryptedReferenceId}`);
    //    } else {
    //        this.buildUserForm();
    //        this.fetchUsersProfile();
    //        this.modalRef = this.modalService.open(this.templateModify, {
    //            backdrop: "static",
    //            keyboard: false,
    //            centered: true,
    //            size: "lg",
    //            windowClass: "custom-modal effect-scale"
    //        });
    //    }
    //}

    private fetchUsersProfile() {
        this.userLoading = true;
        const request = {
            encryptedUserId: this.page.userAccount.encryptedReferenceId
        };
        this.httpService.post(ApiResources.getURI(ApiResources.users.base, ApiResources.users.find), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.userLoading = false;
            }))
            .subscribe((response: IUser) => {
                this.user = response;
                this.updateForm();
            }, () => {
                this.user = null;
                this.onCloseModal();
                this.notifyService.error("Error Occurred while getting your Profile.");
            });
    }

    //onProfileSubmit() {
    //    this.submitted = true;
    //    if (!this.userForm.valid) {
    //        return;
    //    }

    //    this.submitting = true;
    //    const request = Object.assign(UtilHelper.clone(this.userForm.getRawValue()));
    //    request.modifiedBy = this.page.userAccount.accountId;
    //    this.httpService.put(ApiResources.getURI(ApiResources.users.base, ApiResources.users.update), request)
    //        .pipe(takeUntil(this.page.unSubscribe))
    //        .pipe(finalize(() => {
    //            this.submitting = false;
    //            this.submitted = false;
    //            this.onCloseModal();
    //        }))
    //        .subscribe((response: string) => {
    //            this.notifyService.success(response);
    //        },
    //            () => {
    //                this.notifyService.error("Error occurred while updating profile.");
    //            });
    //}

    onOpenModal(content: TemplateRef<any>) {
        this.buildForm();
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            windowClass: "custom-modal change-password-modal effect-scale"
        });
    }
    onOpenWebAlert(content: TemplateRef<any>) {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: "xl",
            windowClass: "custom-modal effect-scale"
        });
    }
    onCloseModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            // ignored;
        }

        this.submitted = undefined;
        this.submitting = undefined;
        this.unAssignedCubiclespaginationFlag = true;
    }

    onShowSettings() {
        $("body").addClass("right-bar-enabled");
    }

    onCloseSettings() {
        $("body").removeClass("right-bar-enabled");
    }

    onApplySettings() {
        this.httpService.post(ApiResources.getURI(ApiResources.providers.base, ApiResources.providers.modifySettings), this.settings)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (response: Settings) => {
                    this.settings = response;
                    $("body").removeClass("right-bar-enabled");
                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }

    onCancelSettings() {
        $("body").removeClass("right-bar-enabled");
    }

    onSubmit() {
        this.submitted = true;
        if (!this.changePasswordForm.valid || (this.changePasswordForm.controls.password.errors || this.changePasswordForm.controls.confirmPassword.errors)) {
            return;
        }

        this.submitting = true;
        this.httpService
            .put<string>(ApiResources.getURI(ApiResources.account.base, ApiResources.account.updatePassword), this.changePasswordForm.value)
            .pipe(
                finalize(() => {
                    this.submitted = false;
                    this.submitting = false;
                })
            )
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (message: string) => {
                    this.notifyService.success(message);
                    this.onCloseModal();
                    this.onLogout();
                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }

    private buildForm() {
        this.changePasswordForm = this.formBuilder.group({
            accountId: [this.page.userAccount.accountId],
            password: ["", [Validators.required, Validators.minLength(4)]],
            confirmPassword: ["", [Validators.required, Validators.minLength(4)]],
            locationId: this.page.userAccount.locationId
        }, { validator: CompareValidator.compare("password", "confirmPassword") });
    }

    get form() { return this.changePasswordForm.controls; }

    onLogout(isHardReload = false) {
        this.auditlog.AccountId = this.page.userAccount.accountId;
        this.auditlog.roleName = this.page.userAccount.roleName;
        this.auditlog.fullName = this.page.userAccount.fullName;
        this.auditlog.logDescription = "<b>" + this.page.userAccount.fullName + "</b>" + "(" + this.page.userAccount.roleName + ")" + " has been logout.";
        this.auditlog.logTypeName = "Account";
        this.auditlog.logTypeId = 8;
        this.auditlog.logFrom = this.page.userAccount.roleId;

        try {
            this.communicationService.stopConnection();
        } catch (e) {
            console.error(e);
        }

        this.identityService.signOut()
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(() => {
                this.auditlogService(this.auditlog);
                //LocalStorageHelper.DeleteAll();
                this.appData.setAccount(null);
                delete localStorage["ProviderVideoCallingSession"];
                this.menuService.removeFromLocal();
                if (isHardReload) {
                    this.router.navigateByUrl(appUrls.login).then(() => {
                        location.reload();
                    });
                } else {
                    this.router.navigateByUrl(appUrls.login);
                }
            });
    }

    auditlogService(request: AuditLog) {
        this.httpService.post(ApiResources.getURI(ApiResources.auditlog.base, ApiResources.auditlog.insert), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response) => {
            }, () => {
            });
    }

    ngOnInit() {
        let x = sessionStorage.getItem("istimeline")
        this.showTimeline = x === "true";
        sessionStorage.removeItem("patientid");
        this.logoSubscription = this.settingService.get.subscribe((value: boolean) => {
            if (value) {
                this.getLogoImage();
            }
        });

        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {

                if (!userAccount)
                    return;
                this.page.userAccount = userAccount ? userAccount : undefined;
                if (this.page.userAccount?.thumbnailUrl) {
                    this.page.userAccount.thumbnailUrl = `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${this.page.userAccount.thumbnailUrl}`;
                }
                this.onFetchPublicIp();
                this.fetchProviders();
                // this.onReadPrivateIp();
                this.getLogoImage();
                if (this.menuService.isHitMenu) {
                    this.menuService.fetch(this.page.userAccount.accountId, this.page.userAccount.roleId, () => {
                        this.setUpMenu();
                    });
                }

                this.onAssignConsultantDoctor({
                    accountId: this.page.userAccount.accountId,
                    providerId: this.page.userAccount.roleId == 3 ? this.page.userAccount.referenceId : undefined
                } as ICubicle, () => {
                    this.fetchCubicles(true);
                })

                const userAlertObservable = new Observable<void>((observer) => {
                    this.realTimeNodeService.subscribeUserAlerts(this.page.userAccount.accountId, (_: any) => {
                        this.admissionAlerts = true;
                        if (this.refresh === false) {
                            this.fetchWebAlerts(this.page.userAccount.roleId);
                        }
                        observer.complete();
                    }, () => {
                        observer.complete();
                    });
                });

                const departmentObservables = this.page.userAccount.roleDepartments?.map((department) => {
                    return new Observable<void>((observer) => {
                        this.realTimeNodeService.subscribe(department, (_: any) => {
                            this.dischargeAlerts = true;
                            if (this.refresh === false) {
                                this.fetchWebAlerts(this.page.userAccount.roleId);
                            }
                            observer.complete();
                        }, () => {
                            observer.complete();
                        });
                    });
                }) || [];

                const labHodObservable = new Observable<void>((observer) => {
                    this.realTimeNodeService.subscribeToLabHodAlerts(this.page.userAccount.roleName, (_: any) => {
                        this.labHodAlerts = true;
                        if (!this.refresh) {
                            this.fetchWebAlerts(this.page.userAccount.roleId);
                        }
                        observer.complete();
                    }, () => {
                        observer.complete();
                    });
                });

                const roleObservable = new Observable<void>((observer) => {
                    this.realTimeNodeService.subscribeRoleAlerts(this.page.userAccount.roleName, (_: any) => {
                        this.paymentAlerts = true;
                        if (this.refresh === false) {
                            this.fetchWebAlerts(this.page.userAccount.roleId);
                        }
                        observer.complete();
                    }, () => {
                        observer.complete();
                    });
                });

                // Combine all observables into one using combineLatest
                combineLatest([userAlertObservable, ...departmentObservables, labHodObservable, roleObservable]).pipe(
                    catchError(() => of(null)),
                    finalize(() => {
                        if (this.admissionAlerts || this.dischargeAlerts || this.labHodAlerts || this.paymentAlerts) {
                            this.fetchWebAlerts(this.page.userAccount.roleId);
                        }
                    })
                ).subscribe();
            });

        this.router.events.pipe(takeUntil(this.page.unSubscribe)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.activeRoute = event.url;
            }
        });

        // let interval = setInterval(() => {
        //     let appConfigUrl = { ...AppConfig.settings };
        //     if (UtilHelper.isEmpty(appConfigUrl["hostingUrl"])) {
        //         this.url = appConfigUrl["hostingUrl"];
        //         clearInterval(interval);
        //     }
        // }, 10);
    }

    fetchWebAlerts(roleId: number) {
        this.loadingWebAlerts = true
        const request = {
            roleId: roleId,
            readBy: this.page.userAccount.accountId,
            admissionAlerts: this.admissionAlerts,
            dischargeAlerts: this.dischargeAlerts,
            labHodAlerts: this.labHodAlerts,
            roleDepartmentName: this.page.userAccount.roleDepartments[0],
            paymentAlerts: this.paymentAlerts

        };
        this.httpService.post(ApiResources.getURI(ApiResources.webAlert.base, ApiResources.webAlert.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loadingWebAlerts = false;
                this.refresh = false;
            }))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.unreadCount = 0;
                        this.webAlerts = response.data["webAlerts"];
                        this.unreadCount += this.webAlerts.length ? this.webAlerts.filter(x => {
                            if (x.acceptedBy && x.acceptedBy === this.page.userAccount.accountId)
                                return x;
                            if (x.acceptedBy == null)
                                return x;
                        }).length : 0;
                        this.userAlerts = response.data["userAlerts"];
                        this.unreadCount += this.userAlerts.length ? this.userAlerts.filter(x => x.acknowledgeBy === null).length : 0;
                    }
                },
            );
    }


    onProviderChange(event: any) {
        if (event) {
            this.onAssignConsultantDoctor({
                accountId: this.page.userAccount.accountId,
                providerId: event.id
            } as ICubicle)
        }
    }

    private fetchProviders() {
        this.providers = this.providersInput.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((term: string) =>
                term && term.length >= 2
                    ? this.resourceService.consultatDoctors(term).pipe(
                        tap(() => this.loadingProviders = true),
                        catchError(() => { return of([]) }),
                        finalize(() => this.loadingProviders = false)
                    )
                    : of([])
            )
        );
    }

    openCubicleModal() {
        this.modalRef = this.modalService.open(this.templateCubicle, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal effect-scale"
        });
    }

    fetchCubicles = (isBypass = false) => {
        if (!isBypass) {
            this.openCubicleModal();
        }

        setTimeout(() => {
            this.cubiclesLoading = true
            const request = {
                pageIndex: 1,
                pageSize: 100,
                locationId: this.page.userAccount.locationId
            };
            this.httpService.post(ApiResources.getURI(ApiResources.cubicles.base, ApiResources.cubicles.fetch), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => this.cubiclesLoading = false))
                .subscribe(
                    (response: GenericResponse) => {
                        if (response.status === GenericStatus[GenericStatus.Success]) {
                            this.refineCubicles(response.data);
                        }
                    },
                );
        })
    }

    refineCubicles = (cubiclesData: any) => {
        const records = (cubiclesData as Array<ICubicle>).map(x => ({ ...x, providerName: x.providerName ? x.providerName.toLowerCase() : null }));
        this.assignedCubicles = records.filter(x => x.providerId);
        this.currentAssignedCubicles = records.filter(x => x.providerId).slice(0, CUBICLE_PAGE_SIZE);
        if (this.currentAssignedCubicles.length > 0) {
            UtilHelper.applyPagination(this.currentAssignedCubicles, this.assignedCubiclespagination);
        }
        this.unassignedCubicles = records.filter(x => !x.providerId);
        this.currentUnAssignedCubicles = records.filter(x => !x.providerId).slice(0, CUBICLE_PAGE_SIZE);
        if (this.currentUnAssignedCubicles.length > 0) {
            UtilHelper.applyPagination(this.currentUnAssignedCubicles, this.unAssignedCubiclespagination);
        }
        this.settingService.cubicles = this.assignedCubicles.filter(x => x.accountId == this.page.userAccount.accountId);
    }
    onAssignedPageChange = (index: number) => {
        const startIndex = index * CUBICLE_PAGE_SIZE;
        this.currentAssignedCubicles = LinqHelper.cloneDeepArray(this.assignedCubicles.slice(startIndex, startIndex + CUBICLE_PAGE_SIZE));
    }
    onUnAssignedPageChange = (index: number) => {
        const startIndex = index * CUBICLE_PAGE_SIZE;
        this.currentUnAssignedCubicles = LinqHelper.cloneDeepArray(this.unassignedCubicles.slice(startIndex, startIndex + CUBICLE_PAGE_SIZE))
    }
    onUnAssignedCubicleSearch = (id: any) => {
        if (id) {
            this.unAssignedCubiclespaginationFlag = false;
            this.currentUnAssignedCubicles = LinqHelper.cloneDeepArray(this.unassignedCubicles.filter(x => x.cubicleId === id.cubicleId));
        }
        if (id === undefined) {
            this.unAssignedCubiclespaginationFlag = true;
            this.currentUnAssignedCubicles = this.unassignedCubicles.filter(x => !x.providerId).slice(0, CUBICLE_PAGE_SIZE);
        }
    }

    onAssignConsultantDoctor = (item: ICubicle, callback?: Function) => {
        this.isAssigningDoctor = true;
        const request = {
            accountId: item.accountId,
            providerId: item.providerId
        }
        this.httpService.post(ApiResources.getURI(ApiResources.cubicles.base, ApiResources.cubicles.assignConsultantDoctor), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (response: GenericResponse) => {
                    this.isAssigningDoctor = false;
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.settingService.selectedConsultantDoctor = response.data;
                        this.settingService.setConsultantDoctor(response.data);
                        if (this.settingService.selectedConsultantDoctor && this.settingService.selectedConsultantDoctor.value) {
                            this.settingService.selectedConsultantDoctor.value = this.settingService.selectedConsultantDoctor.value.toLowerCase();
                        }
                        if (callback) {
                            callback();
                        }
                    } else {
                        this.settingService.setConsultantDoctor(null);
                    }
                },
            );
    }

    clearConsultantDoctor = (component: NgSelectComponent) => {
        this.isAssigningDoctor = true;
        const request = {
            accountId: this.page.userAccount.accountId
        }
        this.httpService.post(ApiResources.getURI(ApiResources.cubicles.base, ApiResources.cubicles.unassignConsultantDoctor), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (response: GenericResponse) => {
                    this.isAssigningDoctor = false;
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        component && component.clearModel();
                        this.settingService.selectedConsultantDoctor = null;
                        this.settingService.setConsultantDoctor(null);
                    }
                },
            );
    }

    onAssign = (item: ICubicle) => {
        if (item.loading) return;
        if (!this.settingService.selectedConsultantDoctor && this.page.userAccount.roleId !== 3) {
            this.notifyService.warning("Please select Consultant doctor.");
            return;
        }

        if (this.settingService.cubicles.length) {
            this.notifyService.warning("Only 1 room can be assigned");
            return;
        }

        item.loading = true;
        const request = {
            cubicleId: item.cubicleId,
            accountId: this.page.userAccount.accountId,
        }
        this.httpService.post(ApiResources.getURI(ApiResources.cubicles.base, ApiResources.cubicles.assign), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (response: GenericResponse) => {
                    item.loading = false;
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        item.providerId = this.settingService.selectedConsultantDoctor.id;
                        item.accountId = this.page.userAccount.accountId;
                        item.assignedName = this.page.userAccount.fullName.toLowerCase();
                        item.providerName = this.settingService.selectedConsultantDoctor.value.toLowerCase();
                        this.refineCubicles([...this.assignedCubicles, ...this.unassignedCubicles])
                    }

                    if (response.status === GenericStatus[GenericStatus.Info]) {
                        this.notifyService.warning("Please select consultant doctor");
                    }

                    if (response.status === GenericStatus[GenericStatus.Warning]) {
                        this.notifyService.warning("Room already assigned, Only 1 room can be assigned");
                    }
                },
            );
    }

    onUnassign = (item: ICubicle) => {
        if (item.loading) return;
        item.loading = true;
        const request = {
            cubicleId: item.cubicleId,
            providerId: null,
            accountId: item.accountId
        }
        this.httpService.post(ApiResources.getURI(ApiResources.cubicles.base, ApiResources.cubicles.unassign), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (response: GenericResponse) => {
                    item.loading = false;
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        item.providerId = null;
                        item.accountId = null;
                        item.assignedName = null;
                        this.refineCubicles([...this.assignedCubicles, ...this.unassignedCubicles])
                    }
                },
            );
    }

    fetchNotifications() {
        const request = {
            pageSize: 5,
            pageIndex: 1
        };
        this.loading = true;
        this.httpService.post<Array<Notification>>(ApiResources.getURI(ApiResources.webNotification.base, ApiResources.webNotification.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<Notification>) => {
                //response.forEach(x => {
                //    x["newThumbnailUrl"] = x.patientThumbnailUrl ? this.safe(x.patientThumbnailUrl) : null;
                //})
                this.notifications = response;
                this.notificationCount = this.notifications.length > 0 ? this.notifications[0].totalItems : 0;
            }, () => {
                this.notifications = new Array<Notification>();
            });
    }

    getLogoImage = () => {
        this.loading = true;

        this.httpService
            .get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), { type: "Logo", active: true })

            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false }))
            .subscribe(
                (response: Array<Setting>) => {
                    if (response && response.length > 0) {
                        this.logoBasics = response[0];
                        if (UtilHelper.isEmpty(response[0].imageUrl)) {
                            response[0].imageUrl = `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${response[0].imageUrl}`;
                        }
                    }

                },
                () => {
                    this.logoBasics = new Setting();
                }
            );
    }

    ngOnDestroy() {
        try {
            this.modalService.dismissAll();
        } catch (e) {
            // ignored;
        }
        this.showTicket = false;
        this.onCloseSettings();
        this.page.unSubscribe.next(null);
        this.page.unSubscribe.complete();
    }

    setMenuMarkers = () => {
        this.menus.forEach(m => {
            m.hasMarker = this.menuService.menus(MenuType.SubMenu, m.mainPage).length > 0;
        })
    }
    removeWidth() {
        this.menuHolder.subMenus.length = null;
        this.isHovering = false;
    }
    enter = (menu: IMenuModel) => {
        this.showTicket = false;
        this.menus.forEach(x => { x.isHovering = false; });
        if (menu.hasMarker) {
            menu.isHovering = true;
        }

        this.isHovering = true;
        this.menuHolder.menuName = menu.displayName + " Navigation";
        this.menuHolder.mainPage = menu.mainPage;
        this.menuHolder.menuUrl = menu.url;
        var allSubMenus = this.menuService.menus(MenuType.SubMenu, menu.mainPage);
        var onlySubMenus = allSubMenus.filter(x => x.menuTypeId === MenuType.SubMenu ||
            (x.menuTypeId === MenuType.CategoryMenu && x.category && x.category === x.displayName));
        onlySubMenus.filter(x => x.menuTypeId === MenuType.CategoryMenu).forEach(x => {
            x.categoryMenus = allSubMenus.filter(y => y.menuTypeId === MenuType.CategoryMenu && y.category === x.category && y.displayName !== x.displayName)
                .map((y, i) => ({
                    id: i,
                    subPage: y.subPage,
                    displayName: y.displayName,
                    iconClasses: y.iconClasses,
                    url: y.url,
                    priority: y.priority
                } as ICategoryMenuModel));
        });

        this.menuHolder.subMenus = onlySubMenus;
    }


    leaveMenu = () => {
        if (!this.menuHolder.subMenus.length) {
            this.isHovering = false;
            this.menus.forEach(x => { x.isHovering = false; });
        }
    }

    leave = () => {
        this.isHovering = false;
        this.menuHolder.subMenus = new Array<IMenuModel>();
        this.menus.forEach(x => { x.isHovering = false; });
    }

    onToggleSubMenu(event: Event, threeDot: HTMLElement) {

        event.preventDefault();
        const sideSubMenu = document.getElementsByClassName("sidebar-main-menu")[0].classList;

        if ($("window").width() >= 99) {
            // ignore
        } else {
            if (threeDot.classList.contains("d-none")) {
                threeDot.classList.toggle("text-success");
            }
            threeDot.classList.toggle("text-danger");
            sideSubMenu.toggle("removeLeft");
        }
    }
    onUserAccept(item: UserAlerts) {
        item.loadingApprove = true;
        const request = {
            AcknowledgeBy: this.page.userAccount.accountId,
            UserAlertId: item.userAlertId,
            roleId: this.page.userAccount.roleId,
            referenceId: item.referenceId,
            alertTypeCode: item.alertTypeCode
        }
        this.httpService.post(ApiResources.getURI(ApiResources.userAlert.base, ApiResources.userAlert.acceptUserAlert), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                {
                    next: (response: GenericResponse) => {
                        item.loadingApprove = false;
                        if (GenericStatus[GenericStatus.Success] === response.status) {
                            this.fetchWebAlerts(this.page.userAccount.roleId);
                        }

                        if (GenericStatus[GenericStatus.Info] === response.status) {
                            this.notifyService.warning("This Task is Accepted by " + item.acknowledgeByName);
                        }

                        if (GenericStatus[GenericStatus.Error] === response.status) {
                            this.notifyService.defaultError();
                        }
                    },
                    error: () => {
                    }
                }
            );
    }
    onUserReject(item: UserAlerts) {
        item.loadingReject = true;
        const request = {
            acknowledgeBy: this.page.userAccount.accountId,
            userAlertId: item.userAlertId,
            roleId: this.page.userAccount.roleId,
            referenceId: item.referenceId,
            alertTypeCode: item.alertTypeCode
        }
        this.httpService.post(ApiResources.getURI(ApiResources.userAlert.base, ApiResources.userAlert.rejectUserAlert), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                {
                    next: (response: GenericResponse) => {
                        item.loadingReject = false;
                        if (GenericStatus[GenericStatus.Success] === response.status) {
                            this.fetchWebAlerts(this.page.userAccount.roleId);
                        }

                        if (GenericStatus[GenericStatus.Info] === response.status) {
                            this.notifyService.warning("This Task is Rejected by " + item.acknowledgeByName);
                        }

                        if (GenericStatus[GenericStatus.Error] === response.status) {
                            this.notifyService.defaultError();
                        }
                    },
                    error: () => {
                    }
                }
            );
    }

    onAccept(item: WebAlerts) {
        item.loading = true;
        const request = {
            acceptedBy: this.page.userAccount.accountId,
            webAlertId: item.webAlertId,
            roleDepartmentName: item.roleDepartmentName
        }
        this.httpService.post(ApiResources.getURI(ApiResources.webAlert.base, ApiResources.webAlert.acceptWebAlert), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                {
                    next: (response: GenericResponse) => {
                        item.loading = false;
                        if (GenericStatus[GenericStatus.Success] === response.status) {
                            this.fetchWebAlerts(this.page.userAccount.roleId);
                        }


                        if (GenericStatus[GenericStatus.Error] === response.status) {
                            this.notifyService.defaultError();
                        }
                    },
                    error: () => {
                    }
                }
            );
    }




    onClear(item: WebAlerts) {
        item.loading = true;
        const request = {
            webAlertId: item.webAlertId,
            acceptedBy: this.page.userAccount.accountId,
            referenceId: item.referenceId,
            admissionId: item.referenceId,
            alertTypeCode: item.alertTypeCode,
            roleDepartmentName: this.page.userAccount?.roleDepartments
                ? this.page.userAccount?.roleDepartments[0] : "",
            createdBy: item.createdBy,
            message: this.message

        }
        this.httpService.post(ApiResources.getURI(ApiResources.webAlert.base, ApiResources.webAlert.clearWebAlert), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                {
                    next: (response: GenericResponse) => {
                        this.message = null;
                        item.loading = false;
                        if (GenericStatus[GenericStatus.Success] === response.status) {
                            this.fetchWebAlerts(this.page.userAccount.roleId);
                        } else if (GenericStatus[GenericStatus.Error] === response.status) {
                            this.notifyService.defaultError();
                        }
                    },
                    error: () => {
                    }
                }
            );
    }

}