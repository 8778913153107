export class InternalMedicineFullTranscript {
    appointmentId: number;
    appointmentNo: string;
    patientId: number;
    encryptedPatientId: string;
    encounterId?: number;
    patientName: string;
    patientAge?: number;
    patientDateOfBirth?: Date;
    patientGender: string;
    patientMobile: string;
    patientThumbnailUrl: string;
    patientCountryCode: string;
    appointmentDate: Date;
    appointmentTime: object;
    providerName: string;
    appointmentTimeString: string;
    problemList: string;
    cheifComplaints: string;
    followUp: string;
    medications: string;
    notes: string;
    vitals: string;
    familyMedicalHistory: string;
    socialHistory: string;
    diagnosis: string;
    generalAdvice: string;
    symptoms: string;
    heent: string;
    extremities: string;
    respiratory: string; skin: string;
    abdomen: string;
    ekg: string;
    musculoskeletal: string;
    neurology: string;
    cardiovascular: string;
    allergies: string;
    preventiveCare: string;
    hospitalizationSurgery: string;
    immunization: string;
    pastandCurrentMedications: string;
    currentMedications: string;
    ros: string;
    medicationComment: string;
    signature: string;
    clinicPicture: string;
    practiceLocation: string;
    practiceName: string;
    specializationName: string;
    providerNo: string;
    educations: string;
}