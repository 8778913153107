export class LabParameter {
    labParameterHeaderId: number;
    parameterName: string;
    observedValue: number;
    labParameterObservedValueId: number;
    newLabBookingDetailId: number;
    labTemplateHeaderId: number;
    labComponentHeaderId: number;
    labParameterDetailId: number;
    referenceOutput: string;
}