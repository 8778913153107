export class LookUpValue {
    lookupValueId: number;
    lookupId: number;
    name: string;
    isAssignedAllergy: boolean;
    isAssignedReactions: boolean;
    isAssignedHospitalization: boolean;
    isAssignedPcare: boolean;
    isAssignedInitial: boolean;
    isAssignedSite: boolean;
    isAssignedVaccine: boolean;
    totalItems: number;
    createdByRole: string;
}