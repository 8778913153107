import { Component, ViewEncapsulation, OnInit, OnDestroy, Input, EventEmitter, Output } from "@angular/core";
import { HttpService, PrintOptionService, BannerService, AppData } from "@shared/services";
import { ImageReports } from "@shared/entities";
import { ApiResources, customBase64Decode } from "@shared/helpers";
import { takeUntil, finalize } from "rxjs/operators";
import { Page, GenericResponse, GenericStatus, IUserAccount } from "@shared/models";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FinalBillBasicModel } from "../../../areas/admin/services/models/final-bill-basic.model";
import { GatepassModel } from "../../../areas/admin/services/models/gatepass.model";
class IAdmissionModel {
    fullName: string;
    fatherOrHusband: string;
    gender: string;
    age: number;
    thumbnailUrl: string;
    admissionNo: string;
    departmentName: string;
    admissionDate: Date;
    admissionTime: Date;
    attendantName: string;
    timeString: string;
    attendantContactNo: string;
    attendantRelationWithPatient: string;
    isDischarged: boolean;
    providerName: string;
    providerGender: string;
    umrNo: string;
    bedNumber: string;
    roomName: string;
    wardName: string;
    mobile: string;
    status: string;
    dischargeDate: Date;
    dischargeTime: Date;
    dischargeTimeString: string;
    expectedDischargeDate: Date;
    encryptedProviderId: string;
    providerId: string;
    patientId: number;
    chargeCategoryName: string;
    payType: string;
    streetAddress: string;
    addressLine2: string;
    city: string;
    state: string;
    zipcode: string;
    fullAddress: string;
    floorName: string;
    locationName: string;
    chargeCategoryId: number;
    admissionId: number;
    readyForDischarge: boolean;
    caseTypeName: string;
    unitName: string;
    fatherOrHusbandAge: Number;
    insuranceCompanyName: string;
    tpaName: string;
    patientDateOfBirth: Date;
    admissionTimeInveralHour: string;
}

@Component({
    templateUrl: "./gate-pass-report.html",
    selector: "gate-pass-report",
    styleUrls: ["./gate-pass-report.css"],
    encapsulation: ViewEncapsulation.None
})
export class GatePassReportWidget implements OnInit, OnDestroy {
    @Input() admissionId: string;
    @Input() isAdmission: boolean;
    @Input() encrptId: any;
    @Output() emitOnCloseModel = new EventEmitter();

    page: Page;
    loading: boolean;
    bannerBasics: ImageReports;
    myImgUrl = "assets/images/careaxesLogo.png";
    @Input() isPrintLogo: boolean;
    QrCode: string = null;
    admission: IAdmissionModel;
    record: any;
    currentDate: Date;
    maxDischargeDate: string;

    finalBillBasics: any;
    isFooter: boolean;
    constructor(
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly bannerService: BannerService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly appData: AppData,

    ) {
        this.page = new Page();
        this.currentDate = new Date();

    }
    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }
    onCloseModal() {
        this.emitOnCloseModel.emit();
    }
    admissionFetch = () => {
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.serviceOrder.base, ApiResources.serviceOrder.getAdmissionBasic),
                { id: this.admissionId, isAdmission: this.isAdmission })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.admission = response.data as IAdmissionModel;
                    }
                },
                () => {
                    this.admission = null;
                }
            );
    }
    fetch = () => {
        const data = {
            id: this.admissionId,
            typeId: 2
        };
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.gatePass.base, ApiResources.gatePass.gatePassfetch),
                data)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {

                        const record = response.data as GatepassModel;


                        this.record = record;
                    }
                },
                () => {
                    this.record = null;
                }
            );

    }
    getFinalBillBasics = (callback = () => { }) => {
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.finalBill.base, ApiResources.finalBill.getBasics),
                {
                    id: this.admissionId,
                    isAdmission: this.isAdmission
                })
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (response: GenericResponse) => {
                    this.finalBillBasics = response.data as FinalBillBasicModel;
                    callback();
                },
                () => {
                    this.finalBillBasics = new FinalBillBasicModel();
                }
            );
        this.QrCode = this.admissionId.toString();

    }
    onDate() {
        function adjust(val) {
            if (val > 9) {
                return val.toString();
            } else {
                return '0' + val.toString();
            }
        }
        var today = new Date();
        var date = today.getFullYear() + '-' + adjust(today.getMonth() + 1) + '-' + adjust(today.getDate());
        var time = adjust(today.getHours()) + ":" + adjust(today.getMinutes());
        this.maxDischargeDate = `${date}T${time}`;
        var dateTimeNow = `${date}T${time}`;

    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;

                    this.route.parent.paramMap
                        .subscribe((params: Params) => {
                            this.admissionId = customBase64Decode(params["params"]["id"]);
                            this.isAdmission = params["params"]["type"] === "a";
                            this.getFinalBillBasics(() => {
                                this.admissionFetch();
                                this.fetch();
                                this.getFinalBillBasics();
                                this.onDate();

                            });
                        });

                } else {
                    this.page.userAccount = undefined;
                }
            });

        this.printOptionService.get((is) => { this.isPrintLogo = is; });
        this.bannerService.getBannerImage((is) => { this.bannerBasics = is; });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
}
