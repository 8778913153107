﻿export class PharmacyStore {
    pharmacyStoreId: number;
    createdBy: number;
    name: string;
    hospitalName: string;
    location: string;
    pinCode: string;
    gstNumber: string;
    createdByName: string;
     createdDate: Date;
     createdByRole: string;
}