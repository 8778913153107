export class InternalMedicineEncounter {
    appointmentId: number;
    encounterId?: number;
    problemList: string;
    encryptedPatientId: string;
    cheifComplaints: string;
    followUp: string;
    medications: string;
    notes: string;
    vitals: string;
    familyMedicalHistory: string;
    socialHistory: string;
    diagnosis: string;
    generalAdvice: string;
    symptoms: string;
    medicationComment: string;
    heent: string;
    extermities: string;
    respiratory: string;
    ekg: string;
    skin: string;
    abdomen: string;
    musculoskeletal: string;
    neurology: string;
    cardiovascular: string;
    allergies: string;
    immunization: string;
    preventiveCare: string;
    hospitalizationSurgery: string;
    pastandCurrentMedications: string;
    currentMedications: string;
    ros: string;
    emergencyTriage: string;
    nursingERForm: string
    cimsAllergy: string;
}