export class ConsentForms {
    consentFormsId: number;
    name: string;
    createdByName: string;
    createdDate: Date;
    modifiedBy: number;
    modifiedByName: string;
    modifiedDate: Date;
    view: string;
    active: boolean;
}