<div class="content">
    <div class="container-fluid">
        <div class="d-flex align-items-center mb-2 mt-2">
            <div *ngIf="isNotUserAccount === true" class="mr-1 w-200p">
                <div class="form-group">
                    <label class="mb-1">Location <code>*</code></label>
                    <ng-select tabindex="0"
                               class="ng-select-sm text-uppercase"
                               [loading]="loadingLocation"
                               [items]="locations"
                               bindLabel="name"
                               bindValue="id"
                               autocomplete="nope"
                               placeholder="Select location"
                               (change)="onLocationSelect($event)"
                               [(ngModel)]="locationId">
                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                            <div class="ng-option disabled">
                                No locations found for '{{searchTerm}}'
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div>
                <div class="form-group" [ngClass]="{'width-400-px' : !isInitialMode}">
                    <label class="mb-1 font-13">Doctor</label>
                    <ng-container *ngIf="isInitialMode">
                        <div>
                            <span [textContent]="queueService.selectedProvider.name"
                                  class="mr-2"></span><i class="mdi mdi-close text-danger cursor-pointer"
                                                         (click)="removeInitialMode()"></i>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!isInitialMode">
                        <ng-select class="ng-select-sm text-uppercase"
                                   [items]="provider | async"
                                   [loading]="loadingProviders"
                                   bindLabel="value"
                                   bindValue="id"
                                   [typeahead]="providerInput"
                                   typeToSearchText="Search doctor"
                                   [virtualScroll]="true"
                                   placeholder="Type here to search"
                                   (change)="onDoctorSelect($event)"
                                   [(ngModel)]="providerId">
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                <div class="ng-option disabled"
                                     *ngIf="searchTerm && searchTerm.length >= 1">
                                    No doctor found for '{{searchTerm}}'
                                </div>
                                <div class="ng-option disabled"
                                     *ngIf="(!searchTerm || searchTerm.length < 1)">
                                    Search doctor
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-i="index">
                                <div class="media">
                                    <div class="avatar-xs mr-1">
                                        <avatar-img *ngIf="item.doctorThumbnailUrl"
                                                    [src]="item.doctorThumbnailUrl"
                                                    [alt]="item.doctorName"
                                                    class="img-fluid rounded-circle"></avatar-img>
                                        <span *ngIf="!item.doctorThumbnailUrl"
                                              class="avatar-title rounded-circle font-11 font-weight-bold text-white"
                                              avatar-bg
                                              [index]="i"
                                              [textContent]="item.value | initials"></span>
                                    </div>
                                    <div class="media-body">
                                        <h5 class="mb-0 mt-0 font-13 font-weight-normal"
                                            [textContent]="item.value"></h5>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                                <div class="media">
                                    <div class="media-body">
                                        <h5 class="mb-0 mt-0 font-13 font-weight-normal"
                                            [textContent]="item.value"></h5>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-select>
                    </ng-container>
                </div>
            </div>
            <div class="ml-2 d-flex align-items-center">
                <ng-container *ngIf="showVoice">
                    <div class="form-group">
                        <label class="mb-1 font-13">Select Voice</label>
                        <select (change)="changeVoice()"
                                [(ngModel)]="selectedVoiceName"
                                class="form-control">
                            <ng-container *ngFor="let voice of voices">
                                <option [value]="voice.name"
                                        [textContent]="voice.name + '-' + voice.lang + (voice.default ? ' (default)' : '')"></option>
                            </ng-container>
                        </select>
                    </div>
                </ng-container>
                <ng-container *ngIf="!showVoice">
                    <div class="form-group mb-0" *ngIf="selectedVoiceName">
                        <span [textContent]="selectedVoiceName" class="mr-2"></span>
                    </div>
                </ng-container>
                <div class="ml-2">
                    <a (click)="setVoiceList()"
                       ngbTooltip="Change Voice"
                       placement="right"
                       href="javascript:;"
                       class="action-btn bg-soft-primary mt-2">
                        <i class="mdi mdi-account-voice"></i>
                    </a>
                </div>
            </div>
            <div class="ml-2">
                <a (click)="toggleVolume()"
                   [ngbTooltip]="isMute ? 'Volume On' : 'Volume Off'"
                   placement="right"
                   href="javascript:;"
                   class="action-btn bg-soft-primary mt-2">
                    <i *ngIf="!isMute" class="mdi mdi-volume-high"></i>
                    <i *ngIf="isMute" class="mdi mdi-volume-off"></i>
                </a>
            </div>
            <div class="ml-2">
                <a (click)="onRefresh()"
                   ngbTooltip="Refresh"
                   placement="right"
                   href="javascript:;"
                   class="action-btn bg-soft-primary mt-2">
                    <i class="mdi mdi-refresh"></i>
                </a>
            </div>
        </div>
        <div class="row" *ngIf="loading">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading...</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!loading && (assigned && assigned.length == 0) && (unassigned && unassigned.length == 0)">
            <div class="col-12 mh-300">
                <div class="no-data">
                    <img src="assets/images/no-data.png" alt="No data" />
                    <h4 class="title">Oops! No Data found.</h4>
                    <p class="sub-title">There is no data to show you right now.</p>
                </div>
            </div>
        </div>
        <div>
            <div *ngIf="(assigned && assigned.length > 0) || (unassigned && unassigned.length > 0)" class="d-flex justify-content-md-between">
                <div class="table-responsive queue-parent mx-2">
                    <table class="table table-bordered table-striped mb-0">
                        <thead>
                            <tr>
                                <th class="text-center">Doctor</th>
                                <th class="text-center">Token/UMR</th>
                                <th class="text-center">Cubicle</th>
                                <th class="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of assigned; let j = index">
                                <td class="text-center">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <h3 [textContent]="item.doctorName"
                                            class="mb-0 mt-0 font-weight-normal text-uppercase"></h3>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <h2 [textContent]="item.tokenNumber"
                                            class="m-0 font-weight-bold text-primary text-capitalize"></h2>
                                        <span class="mr-1 ml-1 font-weight-bold">/</span>
                                        <h3 [textContent]="item.umrNo"
                                            class="m-0 font-weight-bold text-dark text-capitalize"></h3>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center"
                                         *ngIf="item.waitingCount">
                                        <i class="mdi mdi-star mr-1 text-dark"
                                           *ngFor="let start of [].constructor(item.waitingCount)"></i>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <h3 class="m-0" [textContent]="item.cubicleName"></h3>
                                </td>
                                <td>
                                    <div>
                                        <div class="text-right">
                                            <h2 class="m-0 text-primary"
                                                *ngIf="item.queueStatus === 'In Room'"
                                                [textContent]="item.queueStatus"></h2>
                                            <h2 class="m-0 text-primary"
                                                *ngIf="item.queueStatus === 'Calling'">
                                                <i class="mdi mdi-volume-high mr-1"></i><span [textContent]="item.queueStatus"></span>
                                            </h2>
                                            <h2 class="m-0 text-primary"
                                                *ngIf="item.queueStatus === 'Waiting'">
                                                <i class="mdi mdi-clock-time-two mr-1"></i><span [textContent]="item.queueStatus"></span>
                                            </h2>
                                            <h2 class="m-0 text-primary"
                                                *ngIf="item.queueStatus === 'Next'">
                                                <i class="mdi mdi-run-fast"></i><span [textContent]="item.queueStatus"></span>
                                            </h2>
                                        </div>
                                        <div class="text-right">
                                            <span class="badge badge-soft-dark"
                                                  [textContent]="item.appointmentTimeString"></span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-responsive queue-parent mx-2">
                    <table class="table table-bordered table-striped mb-0">
                        <thead>
                            <tr>
                                <th class="text-center">Doctor</th>
                                <th class="text-center">Token/UMR</th>
                                <th class="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of unassigned; let j = index">
                                <td class="text-center">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <h3 [textContent]="item.doctorName"
                                            class="mb-0 mt-0 font-weight-normal text-uppercase"></h3>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <h2 [textContent]="item.tokenNumber"
                                            class="m-0 font-weight-bold text-primary text-capitalize"></h2>
                                        <span class="mr-1 ml-1 font-weight-bold">/</span>
                                        <h3 [textContent]="item.umrNo"
                                            class="m-0 font-weight-bold text-dark text-capitalize"></h3>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center"
                                         *ngIf="item.waitingCount">
                                        <i class="mdi mdi-star mr-1 text-dark"
                                           *ngFor="let start of [].constructor(item.waitingCount)"></i>
                                    </div>
                                </td>
                                <td>
                                    <div class="text-right">
                                        <h2 class="m-0 text-primary"
                                            *ngIf="item.queueStatus === 'In Room'"
                                            [textContent]="item.queueStatus"></h2>
                                        <h2 class="m-0 text-primary"
                                            *ngIf="item.queueStatus === 'Calling'">
                                            <i class="mdi mdi-volume-high mr-1"></i><span [textContent]="item.queueStatus"></span>
                                        </h2>
                                        <h2 class="m-0 text-primary"
                                            *ngIf="item.queueStatus === 'Waiting'">
                                            <i class="mdi mdi-clock-time-two mr-1"></i><span [textContent]="item.queueStatus"></span>
                                        </h2>
                                        <h2 class="m-0 text-primary"
                                            *ngIf="item.queueStatus === 'Next'">
                                            <i class="mdi mdi-run-fast"></i><span [textContent]="item.queueStatus"></span>
                                        </h2>
                                    </div>
                                    <div class="text-right">
                                        <span class="badge badge-soft-dark"
                                              [textContent]="item.appointmentTimeString"></span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="QrCode != null" class="align-text-bottom a4-footer" style="position: absolute;right: 0px;">
            <qrcode [qrdata]="QrCode" [width]="200" [errorCorrectionLevel]="'M'" [elementType]="'img'"></qrcode>
        </div>
    </div>
</div>
