 
<div>
    <!--switch-->
    <!--<div class="col-12 d-flex-end" >
        <button class="btn btn-primary" [class.btn-light]="!isShowInvoice" [class.btn-outline-light]="isShowInvoice" type="button" (click)="onChangeInvoiceType(false)">
            <i class="mdi mdi-check mr-1" *ngIf="!isShowInvoice"></i>App.
        </button>
        <button class="btn btn-primary" [class.btn-light]="isShowInvoice" [class.btn-outline-light]="!isShowInvoice" type="button" (click)="onChangeInvoiceType(true)">
            <i class="mdi mdi-check mr-1" *ngIf="isShowInvoice"></i>Reg.
        </button>
    </div>-->
    <!--registration-->
    <div class="modal-body" Id="invoiceId" style="font-size:20px;" *ngIf=false>
        <div class="row" *ngIf="loading">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading registration reports...</span>
                </div>
            </div>
        </div>
        <div align="center" *ngIf="isPrintLogo">
            <banner-setting></banner-setting>
        </div>
        <div class="row">
            <div class="col-12  overflow-auto">
                <table width="100%">
                    <tr>
                        <th style="color: #000;padding: 0;text-align: center; vertical-align: middle; font-size: 26px;">
                            <u>Final Bill of Supply - Registration Receipt</u>
                        </th>
                    </tr>
                    <tr>
                        <th style="color: #000;padding: 0;text-align: center; vertical-align: middle; font-size: 26px; text-decoration:underline">
                            Health Care Service - SAC: 999312
                        </th>
                    </tr>
                    <tr>
                        <th style="color: #000;padding: 0;text-align: center; vertical-align: middle; font-size: 26px;">
                            Patient Registration Charge
                        </th>
                    </tr>
                </table>
            </div>
            <div class="col-12 overflow-auto" style="margin-bottom: 20px">
                <table width="100%" style="font-size: 22px;">
                    <tr>
                        <!--<th colspan="4" style="color: #000;padding: 0;text-align: center; vertical-align: middle; font-size: 22px;">
                        <b>Invoice</b>
                    </th>-->
                        <th></th>
                    </tr>
                    <tr>
                        <th style="padding: 0; text-align: left;color: #000;">
                            UMRNo
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="registrationBill.umrNo"></span>
                        </th>
                    </tr>
                    <tr>
                        <th style="padding: 0; text-align: left;color: #000;">
                            Patient Name
                        </th>
                        <th class="w-350-px" style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="registrationBill.patientName"></span>

                        </th>
                        <th style="padding: 0; text-align: left;color: #000;">
                            Receipt No
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="'RN-'+ registrationBill.receiptNo || '-----'"></span>
                        </th>
                    </tr>
                    <tr>
                        <th style="padding: 0; text-align: left;color: #000;">
                            Age/Gender
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <!--<span [textContent]="registrationBill.age || 0"></span>/-->
                            <span [textContent]="registrationBill.dateOfBirth ? (registrationBill.dateOfBirth | age:'months' ): registrationBill.age ? (registrationBill.age +' Yrs '): 'N/A'"></span>/
                            <span [textContent]="registrationBill.gender || '-----'"></span>
                        </th>
                        <th style="padding: 0; text-align: left;color: #000;">
                            <span>Date</span>
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="registrationBill.createdDate | date:'dd/MM/yyyy'"></span>
                        </th>
                    </tr>
                    <tr>
                        <th style="padding: 0; text-align: left;color: #000;">
                            Unit Name
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="'---'"></span>
                        </th>
                        <th style="padding: 0; text-align: left;color: #000;">
                            <span>Time</span>
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="registrationBill.createdDate | date:' h:mm a'"></span>
                        </th>
                    </tr>
                    <tr>
                        <th style="padding: 0; text-align: left;color: #000;">
                            <span>Received Towards</span>
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span></span>
                        </th>
                        <th style="padding: 0; text-align: left;color: #000;">
                            <span>Print Date</span>
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="todayDate | date :'d/MM/yyyy, h:mm a'"></span>
                        </th>
                    </tr>
                    <tr>
                        <th style="padding: 0; text-align: left;color: #000;">
                            <span>Registration Fee</span>
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px"> </span>
                            <span></span>
                        </th>
                        <th style="padding: 0; text-align: left;color: #000;">
                            <span></span>
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="registrationBill.charge | currency: 'INR'"></span>
                        </th>
                    </tr>
                    <!--<tr>
                    <th style="padding: 0; text-align: left;color: #000;">
                        <span>Consultation Fee</span>
                    </th>
                    <th style="padding: 0;color: #000;">
                        <span style="margin-right: 5px"></span>
                        <span></span>
                    </th>
                    <th style="padding: 0; text-align: left;color: #000;">
                        <span></span>
                    </th>
                    <th style="padding: 0;color: #000;">
                        <span style="margin-right: 5px">: </span>
                        <span [textContent]="registrationBill.charge | currency: 'INR'"></span>
                    </th>
                </tr>-->
                    <tr>
                        <th style="padding: 0; text-align: left;color: #000;" colspan="2"><span class="text-capitalize font-weight-bold" [textContent]="'Rupees ' + registrationBill.chargeInWords +' Only'"></span></th>
                        <th style="padding: 0; text-align: left;color: #000;">
                            <span>Total Amount</span>
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="registrationBill.charge | currency: 'INR'"></span>
                        </th>
                    </tr>
                    <tr>
                        <th style="padding: 0; text-align: left;color: #000;">
                            <span>Pay Mode</span>
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="registrationBill.payTypeName"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div class="col-12 overflow-auto">
                <table border="0" cellspacing="0" cellpadding="5" style="margin-top: 10px; font-size: 22px;">
                    <tr>
                        <th colspan="2" style="padding: 0; text-align: center;color: #000;" width="200">
                            Authorised Signatory
                        </th>
                    </tr>
                    <tr>
                        <th style="padding: 0; text-align: right;color: #000;" width="200">
                            Printed By
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="page.userAccount.fullName"></span>
                        </th>
                    </tr>
                    <tr>
                        <th style="padding: 0; text-align: right;color: #000;" width="200">
                            Printed Date
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="todayDate | date :'d/MM/yyyy, h:mm a'"></span>
                        </th>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <!--new format invoice-->
    <div class="modal-body" Id="invoiceId" style="font-size:20px;">
        <div align="center" *ngIf="isPrintLogo">
            <banner-setting [reportName]='"Registration Invoice Header"'></banner-setting>
        </div>
        <div class="row">
            <div class="col-12  overflow-auto">
                <table width="100%">
                    <tr>
                        <th style="color: #000;padding: 0;text-align: center; vertical-align: middle; font-size: 26px;">
                            <u>Final Bill of Supply - Consultation Receipt</u>
                        </th>
                    </tr>
                    <tr>
                        <th style="color: #000;padding: 0;text-align: center; vertical-align: middle; font-size: 26px; text-decoration:underline">
                            Health Care Service - SAC: 999312
                        </th>
                    </tr>
                    <tr>
                        <th style="color: #000;padding: 0;text-align: center; vertical-align: middle; font-size: 26px;">
                            Registration for {{selectedAppointment.specializationName}}
                        </th>
                    </tr>
                </table>
            </div>
            <div class="col-12 overflow-auto" style="margin-bottom: 20px">
                <table width="100%" style="font-size: 22px;">
                    <tr>
                        <!--<th colspan="4" style="color: #000;padding: 0;text-align: center; vertical-align: middle; font-size: 22px;">
                        <b>Invoice</b>
                    </th>-->
                        <th></th>
                    </tr>
                    <tr>
                        <th style="padding: 0; text-align: left;color: #000;">
                            UMRNo
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="selectedAppointment.umrNo"></span>
                        </th>
                    </tr>
                    <tr>
                        <th style="padding: 0; text-align: left;color: #000;">
                            Patient Name
                        </th>
                        <th class="w-350-px" style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span class="text-uppercase" [textContent]="selectedAppointment.patientName"></span>

                        </th>
                        <th style="padding: 0; text-align: left;color: #000;">
                            Receipt No
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="'RN-'+ selectedAppointment.receiptNo || '-----'"></span>

                        </th>
                    </tr>
                    <tr>
                        <th style="padding: 0; text-align: left;color: #000;">
                            Age/Gender
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <!--<span [textContent]="registrationBill.age || 0"></span>/-->
                            <span [textContent]="selectedAppointment.patientDateOfBirth ? (selectedAppointment.patientDateOfBirth | age:'months' ): selectedAppointment.patientAge ? (selectedAppointment.patientAge +' Yrs '): 'N/A'"></span>/
                            <span [textContent]="selectedAppointment.patientGender || '-----'"></span>
                        </th>
                        <th style="padding: 0; text-align: left;color: #000;">
                            <span>Date</span>
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="selectedAppointment.receiptDate | date:'dd/MM/yyyy'"></span>
                        </th>
                    </tr>
                    <tr>
                        <th style="padding: 0; text-align: left;color: #000;">
                            Unit Name
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="'---'"></span>
                        </th>
                        <th style="padding: 0; text-align: left;color: #000;">
                            <span>Time</span>
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="selectedAppointment.receiptDate | date:' h:mm a'"></span>
                        </th>
                    </tr>
                    <tr>
                        <th style="padding: 0; text-align: left;color: #000;">
                            <span>Received Towards</span>
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span></span>
                        </th>
                        <th style="padding: 0; text-align: left;color: #000;">
                            <span>Print Date</span>
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="todayDate | date :'d/MM/yyyy'"></span>
                        </th>
                    </tr>
                    <tr *ngIf="selectedAppointment.referenceId"> <!--  -->
                        <th colspan="3" style="padding: 0; text-align: left;color: #000;">
                            <span>Registration Fee</span>
                        </th>
                        <!--<th style="padding: 0;color: #000;">
                        <span style="margin-right: 5px"> </span>
                        <span></span>
                    </th>
                    <th style="padding: 0; text-align: left;color: #000;">
                        <span></span>
                    </th>-->
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="selectedAppointment.patAmount | currency: 'INR'"></span>
                        </th>
                    </tr>
                    <tr>
                        <th colspan="2" style="padding: 0; text-align: left;color: #000;"><span class="text-capitalize font-weight-bold" [textContent]="'Rupees ' + selectedAppointment.amountInWord +' Only'"></span></th>
                        <th style="padding: 0; text-align: left;color: #000;">
                            <span>Total Amount</span>
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="selectedAppointment.amountFinal | currency: 'INR'"></span>
                        </th>
                    </tr>
                    <tr>
                        <th style="padding: 0; text-align: left;color: #000;">
                            <span>Pay Mode</span>
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="selectedAppointment.payTypeName"></span>
                        </th>



                    </tr>

                    <!--<tr style="border-bottom: 1px solid" *ngFor="let payType of selectedAppointment.payTypes">
                    <td style="font-weight: bold" [textContent]="payType.payTypeName">
                    </td>
                    <td style="font-weight: bold">
                        <span>:</span>
                    </td>
                    <td style="font-weight: bold">

                        <span [textContent]="payType.amountPaid  || 0 | currency: 'INR'"></span>
                        <span>&nbsp; &nbsp;</span>
                        <span [textContent]="payType.createdDate | date:'dd-MM-yyyy, hh:mm a'"></span>
                    </td>

                </tr>-->
                </table>
            </div>
            <div class="col-12 overflow-auto">
                <table border="0" cellspacing="0" cellpadding="5" style="margin-top: 10px; font-size: 22px;">
                    <tr>
                        <th colspan="2" style="padding: 0; text-align: center;color: #000;" width="200">
                            Authorised Signatory
                        </th>
                    </tr>
                    <tr>
                        <th style="padding: 0; text-align: right;color: #000;" width="200">
                            Printed By
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="page.userAccount.fullName"></span>
                        </th>
                    </tr>
                    <tr>
                        <th style="padding: 0; text-align: right;color: #000;" width="200">
                            Printed Date
                        </th>
                        <th style="padding: 0;color: #000;">
                            <span style="margin-right: 5px">: </span>
                            <span [textContent]="todayDate | date :'d/MM/yyyy, h:mm a'"></span>
                        </th>
                    </tr>
                </table>
            </div>
        </div>
        <div *ngIf="isFooter">
            <banner-setting [reportName]='"Registration Invoice Footer"'> </banner-setting>
        </div>
    </div>



    <!--old invoice-->
    <div class="modal-body" Id="invoiceId" *ngIf="false">
        <div class="table-responsive-lg table-responsive-sm table-responsive-md text-black">
            <table width="894px" border="0" cellspacing="0" cellpadding="0" style="margin: 0px auto;
            border: 1px solid #333; border-bottom: 1px solid #333; border-collapse: unset;width: 100%">
                <tbody>
                    <tr style="background: #f3f3f3;">
                        <td colspan="2">
                            <banner-setting> </banner-setting>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <h4 style="font-family: 'Open Sans'; font-size: 15px; color: #06C; margin: 0px; padding: 5px; text-align: center; border-top: 1px solid #000; font-weight: bolder">
                                {{ selectedAppointment.paymentType === 'P' ? 'RECEIPT' : 'INVOICE' }}
                            </h4>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <table width="100%" border="0" cellspacing="0" cellpadding="5" style="border-top: 1px solid #333;
                        border-bottom: 1px solid #333; font-family: 'Open Sans'; font-size: 14px;">
                                <tbody>
                                    <tr>
                                        <td style="font-weight: bold">
                                            Patient Name
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td class="text-uppercase">
                                            {{selectedAppointment.patientName}}
                                        </td>
                                        <td style="width: 10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold">
                                            Father/Husband Name
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {{selectedAppointment.fatherOrHusband}}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold">
                                            Age / Gender
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            <!--{{selectedAppointment.patientAge}} (Yrs) / {{selectedAppointment.patientGender | gender}}-->
                                            {{selectedAppointment.patientDateOfBirth ? (selectedAppointment.patientDateOfBirth | age:'days' ): selectedAppointment.patientAge ? (selectedAppointment.patientAge +' yrs '): 'N/A'}}  / {{selectedAppointment.patientGender | gender}}
                                        </td>

                                        <td style="width: 10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold">
                                            UMR No
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {{selectedAppointment.umrNo}}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold">
                                            Mobile No
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {{selectedAppointment.patientMobile}}
                                        </td>

                                        <td style="width: 10%">
                                            &nbsp;
                                        </td>
                                        <td *ngIf="selectedAppointment.fullAddress" style="font-weight: bold">
                                            Address
                                        </td>
                                        <td *ngIf="selectedAppointment.fullAddress">
                                            :
                                        </td>
                                        <td *ngIf="selectedAppointment.fullAddress">
                                            {{selectedAppointment.fullAddress}}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td *ngIf="selectedAppointment.idProofName" style="font-weight: bold">
                                            {{selectedAppointment.idProofName}}
                                        </td>
                                        <td *ngIf="selectedAppointment.idProofName">
                                            :
                                        </td>
                                        <td *ngIf="selectedAppointment.idProofName">
                                            {{selectedAppointment.idProofValue}}
                                        </td>

                                        <td *ngIf="selectedAppointment.idProofName" style="width: 10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold">
                                            Referred By
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {{selectedAppointment.referredByName || 'Self'}}
                                        </td>


                                    </tr>
                                    <!--<tr>
                                        <td style="font-weight: bold" *ngIf="selectedAppointment.referredBy !== null && selectedAppointment.referredBy !== 'Self'">
                                            Referred {{selectedAppointment.referredBy}} Name
                                        </td>
                                        <td *ngIf="selectedAppointment.referredBy !== null && selectedAppointment.referredBy !== 'Self'">
                                            :
                                        </td>
                                        <td *ngIf="selectedAppointment.referredBy !== null && selectedAppointment.referredBy !== 'Self'">
                                            {{selectedAppointment.referredByName}}
                                        </td>
                                    </tr>-->
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <table border="0" cellpadding="5" cellspacing="0" style="border-bottom: 1px solid #333;font-family: 'Open Sans';font-size: 16px;font-weight: bolder;" width="100%">

                                <!--<tbody><tr><td style="text-align: center;padding: 0px;font-size: 14px;text-transform: uppercase;">{{(selectedAppointment.followUpForAppointmentId || selectedAppointment.followUpForAdmissionId) ? 'Follow up': 'New'}} Appointment</td></tr></tbody>-->
                                <tbody><tr><td style="text-align: center;padding: 0px;font-size: 14px;text-transform: uppercase;">{{selectedAppointment.appointmentTypeName}}</td></tr></tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="8">
                            <table width="100%" cellpadding="5" style="font-family: 'Open sans'; font-size: 14px;
                        border-collapse: collapse;">
                                <tbody>
                                    <tr style="border-bottom: 1px solid">
                                        <td style="font-weight: bold; width: 13%">
                                            Appointment No
                                        </td>
                                        <td style="font-weight: bold">
                                            :
                                        </td>
                                        <td style="font-weight: bold">
                                            {{selectedAppointment.appointmentNo}}
                                        </td>
                                        <td width="10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold; width: 13%">
                                            Appointment Date
                                        </td>
                                        <td style="font-weight: bold">
                                            :
                                        </td>
                                        <td style="font-weight: bold">
                                            {{selectedAppointment.appointmentDate | amDateFormat:'DD-MMM-YYYY'}} {{selectedAppointment.appointmentTimeString}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold; vertical-align: top">
                                            Doctor Name
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            :
                                        </td>
                                        <td style="font-weight: bold">

                                            <span class="text-uppercase" [textContent]="'Dr. '+ selectedAppointment.providerName"></span>&nbsp;
                                            <span [textContent]="' ('+ selectedAppointment.specializationName +')'"></span>
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            Department
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            :
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            {{selectedAppointment.departmentName}}
                                        </td>
                                    </tr>
                                    <!--<tr>
                                        <td *ngIf="duplicateInvoiceId" class="justify-content-center d-flex">
                                            <div id="backgroundOfwaterMark"> <span class=" text-danger">Duplicate Invoice</span></div>

                                        </td>
                                    </tr>-->
                                    <!--<tr>
                                    <td style="font-weight: bold">
                                        Type
                                    </td>
                                    <td>
                                        :
                                    </td>
                                    <td>-->
                                    <!--<span class="d-block font-13" *ngIf="selectedAppointment.visitType === 'T'">Telemedicine</span>
                                    <span class="d-block font-13" *ngIf="selectedAppointment.visitType === 'P'">Personal Visit</span>-->
                                    <!--<span class="d-block font-13" *ngIf="selectedAppointment.visitType === 'O'">Out Patient</span>
                                        <span class="d-block font-13" *ngIf="selectedAppointment.visitType === 'C'">Casualty</span>
                                    </td>
                                    <td style="width: 10%">
                                        &nbsp;
                                    </td>-->
                                    <!--<td style="font-weight: bold">
                                        Reason
                                    </td>
                                    <td>
                                        :
                                    </td>
                                    <td *ngIf="selectedAppointment.status === 'C'" [textContent]="selectedAppointment.reason"></td>-->
                                    <!--</tr>-->
                                    <tr>
                                        <td style="font-weight: bold">
                                            Payment Modes

                                        </td>
                                        <td>
                                            <!--:-->
                                        </td>
                                        <td style="font-weight: bold">
                                            <!--<div *ngIf="selectedAppointment.isHealthCard && selectedAppointment.isHealthCard===true">
                                                <span [textContent]="selectedAppointment.isHealthCard ? 'HC' : '--'"></span><br />
                                            </div>
                                            <div *ngIf="!selectedAppointment.isHealthCard">
                                                <span [textContent]="selectedAppointment.payTypeName || '--'"></span><br />
                                                <span *ngIf="selectedAppointment.payTypeId > 1" [textContent]="selectedAppointment.paymentNumber"></span>
                                            </div>-->

                                        </td>
                                        <td style="width: 10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold">
                                            Status
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            <span *ngIf="selectedAppointment.status === 'B'">Booked</span>
                                            <span *ngIf="selectedAppointment.status === 'R'">Rescheduled</span>
                                            <span *ngIf="selectedAppointment.status === 'C'">Cancelled</span>
                                            <span *ngIf="selectedAppointment.status === 'CP'">Closed</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td *ngIf="selectedAppointment.paymentType === 'P'" class="justify-content-center d-flex">

                                            <div id="backgroundOfwaterMark" style=" -webkit-transform: rotate(-65deg);"> <span>Partial Paid</span></div>

                                        </td>
                                    </tr>
                                    <tr *ngIf="selectedAppointment.payTypes[0]">
                                        <td style="font-weight: bold">
                                            <span *ngIf="selectedAppointment.paymentType === 'F'">Full Paid</span>
                                        </td>
                                    </tr>

                                    <tr style="border-bottom: 1px solid" *ngFor="let payType of selectedAppointment.payTypes">
                                        <td style="font-weight: bold" [textContent]="payType.payTypeName">
                                        </td>
                                        <td style="font-weight: bold">
                                            <span>:</span>
                                        </td>
                                        <td style="font-weight: bold">

                                            <span [textContent]="payType.amountPaid  || 0 | currency: 'INR'"></span>
                                            <span>&nbsp; &nbsp;</span>
                                            <span [textContent]="payType.createdDate | date:'dd-MM-yyyy, hh:mm a'"></span>
                                        </td>

                                    </tr>


                                    <tr>
                                        <!--<td style="font-weight: bold; width: 13%">
                                        </td>
                                        <td style="font-weight: bold">
                                        </td>
                                        <td style="font-weight: bold">
                                        </td>-->
                                        <td style="font-weight: bold">
                                            Charge Type
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <!--<td>
                                            <span [textContent]="selectedAppointment.chargeType === 'G' ? 'General':selectedAppointment.chargeType === 'C'?'Covid':selectedAppointment.chargeType === 'S'?'Special':'General' "></span><br />
                                        </td>-->
                                        <td>
                                            {{selectedAppointment.chargeTypesName}}
                                        </td>
                                        <td width="10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top" *ngIf="selectedAppointment.referenceId != null">
                                            Patient Registration Charges
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top" *ngIf="selectedAppointment.referenceId != null">
                                            :
                                        </td>
                                        <td style="font-weight: bold" *ngIf="selectedAppointment.referenceId != null">
                                            <span *ngIf="selectedAppointment.patientPaymentStatus" [textContent]="selectedAppointment.patAmount | currency: 'INR'"></span>
                                            <span *ngIf="selectedAppointment.patientPaymentStatus!=true" [textContent]="selectedAppointment.patientPaymentStatus== false? 'Not Paid' : 'No Registration Charges' "></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold; width: 13%">
                                            Appointment Booking Charges
                                        </td>
                                        <td style="font-weight: bold">
                                            :
                                        </td>
                                        <td style="font-weight: bold">
                                            <span [textContent]="selectedAppointment.amount || 0 | currency: 'INR'"></span>
                                        </td>
                                        <td width="10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold; width: 13%">
                                            Discount
                                        </td>
                                        <td style="font-weight: bold">
                                            :
                                        </td>
                                        <td style="font-weight: bold">
                                            <span *ngIf="selectedAppointment.referenceId != null" [textContent]="selectedAppointment.discount+selectedAppointment.patDiscount | currency: 'INR'"></span>
                                            <span *ngIf="selectedAppointment.referenceId == null" [textContent]="selectedAppointment.discount | currency: 'INR'"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold; vertical-align: top">
                                            Gross Total
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            :
                                        </td>
                                        <td style="font-weight: bold">
                                            <div *ngIf="selectedAppointment.referenceId != null">
                                                <span [textContent]="selectedAppointment.amount+selectedAppointment.patAmount | currency: 'INR'"></span>
                                            </div>
                                            <div *ngIf="selectedAppointment.referenceId == null">
                                                <span [textContent]="selectedAppointment.amount | currency: 'INR'"></span>
                                            </div>
                                        </td>
                                        <td width="10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            {{ selectedAppointment.paymentType === 'P' ? 'Paid Amount' : 'Net Total' }}
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            :
                                        </td>
                                        <td style="font-weight: bold">
                                            <div *ngIf="selectedAppointment.referenceId != null">
                                                <span [textContent]="selectedAppointment.total+selectedAppointment.patientRegistrationAmount | currency: 'INR'"></span>
                                            </div>
                                            <div *ngIf="selectedAppointment.referenceId == null">
                                                <span [textContent]="selectedAppointment.total | currency: 'INR'"></span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="selectedAppointment.paymentType === 'P'">
                                        <td style="font-weight: bold; vertical-align: top">
                                            Net Total
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            :
                                        </td>
                                        <td style="font-weight: bold">
                                            <div *ngIf="selectedAppointment.referenceId != null">
                                                <span [textContent]="(selectedAppointment.amount+selectedAppointment.patAmount)-(selectedAppointment.discount+selectedAppointment.patDiscount) | currency: 'INR'"></span>
                                            </div>
                                            <div *ngIf="selectedAppointment.referenceId == null">
                                                <span [textContent]="(selectedAppointment.amount)-(selectedAppointment.discount) | currency: 'INR'"></span>
                                            </div>
                                        </td>
                                        <td width="10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            Due Amount
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            :
                                        </td>
                                        <td style="font-weight: bold">
                                            <div *ngIf="selectedAppointment.referenceId != null">
                                                <span [textContent]="(selectedAppointment.amount+selectedAppointment.patAmount)-(selectedAppointment.total+selectedAppointment.patientRegistrationAmount) | currency: 'INR'"></span>
                                            </div>
                                            <div *ngIf="selectedAppointment.referenceId == null">
                                                <span [textContent]="(selectedAppointment.amount)-(selectedAppointment.total) | currency: 'INR'"></span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold; width: 19%">
                                            For Appointment Contact
                                        </td>
                                        <td style="font-weight: bold">
                                            :
                                        </td>
                                        <td>
                                            _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold; width: 19%">
                                            For Emergency Contact
                                        </td>
                                        <td style="font-weight: bold">
                                            :
                                        </td>
                                        <td width="20%">
                                            _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
                                        </td>
                                    </tr>
                                    <tr style="border-bottom: 1px solid #000; border-top: 1px solid #000;">
                                        <td colspan="8">
                                            <strong>Note:</strong> If there are any side effects with the medicine, stop the
                                            medicine and report immediately to the hospital.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="8" style="text-align: center">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="11" style="font-family: 'Open Sans'; font-size: 12px; color: #000;">
                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td style="text-align: left; font-size: 12px;">
                                            Suggest Clinical Correlation * If neccessary, Please discuss<br>
                                            Verified By : <br>
                                            Test results related only to the item tested<br>
                                            No part of the report can be reproduced without written permission of the laboratory.
                                        </td>
                                        <!--<td style="font-weight: bold">
                                            <ngx-barcode [bc-value]="selectedAppointment.umrNo" [bc-height]="30" [bc-width]="2"></ngx-barcode>
                                        </td>-->
                                        <td></td>
                                        <td style="text-align: right; text-transform: uppercase; font-size: 12px; font-weight:bolder">
                                            Dr. {{selectedAppointment.providerName}}<br>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>