export class MoneyHelper {

    
    static table_0_19 = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    static table_20_90 = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    static table_Scale = ["", "Thousand", "Lakh", "Crore", "Arab", "Kharab", "Neel", "Padma", "Shankh", "Samudra", "Antya", "Madhyam", "Paraardh", "***", "***"];


    static integerToWordsInd = (givenNumber = 0, official = 0) => {
        if (givenNumber === 0) return "Zero";
        if (official) return MoneyHelper.siptlets(givenNumber);                   // Return Official Numbering System text
        let numWords = "";
        const num = ("0".repeat(6 * ((givenNumber).toString().length) % 7) + givenNumber).match(/.{7}/g); // Create Siptlets Array
        return num.forEach((siptlet, scalePos) => {               // Return Commmon-Use Numbering System text
            const [scale, sWords] = [(MoneyHelper.table_Scale[3] + " ").repeat(num.length - scalePos - 1).trimRight(), MoneyHelper.siptlets(siptlet)];
            numWords += (numWords && sWords ? ", " : "") + sWords + (scale ? " " : "") + scale;
        }), numWords;
    }

    static siptlets = (Num, NumWords = "") => {
        (Num += "").length - 3 & 1 && (Num = "0" + Num);
        Num = Num > 999 ? [...Num.slice(0, -3).match(/.{2}/g).map(e => "0" + e), (Num.slice(-3))] : [("00" + Num).substr(-3)];
        return Num.forEach((Duplet, ScalePos) => {
            if (+Duplet) {
                const [Hyphen, Hundreds, Tens, Scale] = [+Duplet[2] ? "-" : "", +Duplet[0], +Duplet.substr(1), MoneyHelper.table_Scale[Num.length - ScalePos - 1]];
                NumWords += (NumWords ? " " : "") + (Hundreds ? MoneyHelper.table_0_19[Hundreds] + " Hundred" : "") +
                    (Hundreds && Tens ? " " : "") + (Tens < 20 ? MoneyHelper.table_0_19[Tens] :
                        MoneyHelper.table_20_90[+(Duplet[1])] + Hyphen + MoneyHelper.table_0_19[+Duplet[2]]);
                NumWords += (NumWords && Scale ? " " : "") + Scale;
            }
        }), NumWords;
    }

    static numberCurrencyIn = (Num = 0, Official = 0) => {
        const n = (Num + "").split(0.1.toLocaleString().substr(1, 1)); // Number and Fraction parts
        n.length !== 2 && (n[1] = ""); // No fraction
        Num = +n[0];
        let Nw = "", Fw = "", Frc = (n[1] + "00").substring(0, 2); // Limit to 2 Decimal Places
        Num && (Nw = MoneyHelper.integerToWordsInd(Num, Official));       // Convert the Whole Number
        Frc && (Fw = MoneyHelper.integerToWordsInd(+Frc, Official));       // Convert the Fractional Part
        const final = (Nw ? Nw : "") + (Nw ? " Rupees" : "") + (Nw && Fw && Fw !== "Zero" ? " and " : "") + (Fw && Fw !== "Zero" ? Fw + " Paisa" : "");
        return Nw ? final : "Zero Paisa"; // Join together
    }

}