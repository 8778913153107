export class ProviderMedicationMaster {
    providerMedicationMasterId: number;
    providerMedicationGroupId: number;
    pharmacyProductId: number;
    duration: number;
    dosage: number;
    categoryId: number;
    companyId: number;
    durationType: string;
    morningDosage: string;
    afternoonDosage: string;
    nightDosage: string;
    instruction: string;
    productName: string;
    genericName: string;
    companyName: string;
    categoryName: string;
    isMorning: boolean;
    isAfternoon: boolean;
    isNight: boolean;
    tagName: string;
    specializationName: string;
    specializationId?: number;
    providers: Array<ProviderList>;
    isAdded: boolean;
    isSaved: boolean;

    constructor() {
        this.providers = new Array<ProviderList>();
    }
}

export class ProviderList {
    providerId: number;
    fullName: string;
}
