export class InsuranceCompanies {
    insuranceCompanyId: number;
    fullName: string;
    contactPersonName: string;
    contactPersonMobile: string;
    countryId: number;
    countryCode: string;
    countryName: string;
    contactPeriodFrom: Date;
    contactPeriodTo: Date;
    createdDate: Date;
    createdBy: number;
}