
export class BirthCertificate {
    birthCertificateId: number;
    birthCertificateNo: string;
    childName: string;
    dateOfBirth: Date;
    timeOfBirth: object;
    sex: string;
    placeOfBirth: string;
    ageOfMotherAtMarriage: number;
    informantsName: string;
    informantsAddress: string;
    patientId: number;
    admissionId: number;
    active: boolean;
    createdBy: number;
    modifiedBy: number;
    modifiedDate: Date;
    createdDate: Date;
    patientName: string;
    createdByName: string;
    ModifiedByName: string;
    mobile: string;
    babySex: string;
    babyWeigth: number;
    fatherName: string;
    babyTime: string;
    babyDateOfBirth: string;
    fatherEducation: string;
    fatherOccupation: string;
    motherName: string;
    presentMotherAge: number;
    state: string;
    motherResidence: string;
    village: string;
    occupationId: number;
    occupationDetail: number;
    religion: string;
    babysex: string;
    occupationName: string;
    educationName: string;
    streetAddress: string;
    addressLine2: string;
    city: string;
    fatherOccupationDetails: string;
    patientAge: string;
    umrNo: string;
    admissionNo: string;
    babyWeight: number;
    typeOfDelivery: string;
    methodOfDelivery: string;
    durationOfPregnancy: string;
    noOfChildBorn: string;
    zipcode: string;
    createdByRole: string;
    ModifiedByRole: string;
}