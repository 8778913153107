import { Component, OnInit, OnDestroy, ViewEncapsulation, TemplateRef, Input } from "@angular/core";
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";
import { Location, DatePipe } from '@angular/common';
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject, of, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap, tap, catchError, finalize, takeUntil } from "rxjs/operators";
import { IMedicationAsync, GenericResponse, GenericStatus, Page, IUserAccount } from "../../../../../shared/models";
import { HttpService, ResourceService, NotifyService, AppData, BannerService } from "../../../../../shared/services";
import { ApiResources, LinqHelper, DateHelper, RegExHelper } from "../../../../../shared/helpers";
import { IAdmissionModel } from "../../../services/models/admission.model";
import { ActiveStatus, Dates, GlobalProgressReportMedication, AdmissionFetchHelper, TimelineCommunicationService } from "../../shared/helper";
import { TakeMode, RunningMode, TimelineStatus, DisplayMode, ProgressReportMedication, MedicationTimelineMain, MedicationTimelineGroup, IInstruction, IndentMedicine, MedicationFrequency, MedicationTimeline, slotArrayValue } from "./local.helper";
import { ImageReports, Setting } from "../../../../../shared/entities";
import { HourHolder } from "../../../nurse-module/helpers/helper";
import { MarkDetails } from "../timeline/local.helper";

@Component({
    selector: 'progress-report-medications',
    templateUrl: "./medication.html",
    styleUrls: ["./medication.css"],
    encapsulation: ViewEncapsulation.None
})
export class ProgressReportMedicationPage implements OnInit, OnDestroy {
    @Input() inputAdmissionId?: number;
    hours: Array<HourHolder>
    limit = [1, 2, 3, 4, 5, 6]
    markDetails: MarkDetails;
    admissionId: number;
    isAdmission: boolean;
    page: Page;
    takeMode = TakeMode;
    runningMode = RunningMode;
    timelineStatus = TimelineStatus;
    activeStatus = ActiveStatus;
    displayMode = DisplayMode;
    currentTakeMode: TakeMode;
    modalRef: NgbModalRef;
    loading: boolean;
    priviousValues = [];
    checked: any = 1;
    timeslotschecked = [];
    addMedicationForm: FormGroup;
    stopMedicationForm: FormGroup;
    takeMedicationForm: FormGroup;
    indentForm: FormGroup;

    medicationsAsync: Observable<Array<IMedicationAsync>>;
    medicationsInput = new Subject<string>();
    loadingMedicationsAsync: boolean;

    instructionTypeNames: Array<number>;
    medicines: Array<ProgressReportMedication>;
    activeMedicines: Array<ProgressReportMedication>;
    medicationTimeline: Array<MedicationTimelineMain>;
    timelineGroup: Array<MedicationTimelineGroup>;
    selectedMedication: IMedicationAsync;
    medicationSubmitted: boolean;

    loadingInstructions: boolean;
    morningInstructions: Array<IInstruction>;
    afternoonInstructions: Array<IInstruction>;
    nightInstructions: Array<IInstruction>;
    timeLineSlots: Array<IInstruction>;
    subscriptions: Array<Subscription>;
    imgOrigin: string;
    submitting: boolean;
    takeMedicationSubmitted: boolean;
    stopping: boolean;
    loadingTimeline: boolean;
    indentSubmitting: boolean;
    taking: boolean;
    selectedMedicine: ProgressReportMedication;
    selectedUpdateMedicine: ProgressReportMedication;
    dates: Dates;
    protected datePipe: DatePipe = new DatePipe('en-US');
    admission: IAdmissionModel;
    indentMedicines: Array<IndentMedicine>;
    autoSelected: boolean;
    currentDisplayMode: DisplayMode;
    currentRunningMode: RunningMode;
    morningInstructionId: any;
    afternoonInstructionId: any;
    nightInstructionId: any;
    expiry = DateHelper.ngbToday;
    bannerBasics: ImageReports;
    myImgUrl: string = "assets/images/careaxesLogo.png";
    isDisabled: boolean;
    IsEdit = false;
    compactMode: boolean;
    loadingSettings: boolean;
    settings: Array<Setting>;
    isPharmacyIndent: boolean;
    isFooter: boolean;
    isPrintLogo: boolean;
    constructor(
        private readonly httpService: HttpService,
        private readonly resourceService: ResourceService,
        private readonly modalService: NgbModal,
        private readonly formBuilder: FormBuilder,
        private readonly notifyService: NotifyService,
        private readonly appData: AppData,
        private readonly route: ActivatedRoute,
        private readonly locationService: Location,
        private readonly admissionFetchHelper: AdmissionFetchHelper,
        private readonly timelineCommunicationService: TimelineCommunicationService,
        private readonly bannerService: BannerService,
    ) {
        this.markDetails = new MarkDetails();
        this.currentDisplayMode = DisplayMode.Concept;
        this.autoSelected = false;
        this.indentMedicines = new Array<IndentMedicine>();
        this.dates = new Dates();
        this.dates.current = new Date();
        this.dates.min = new Date(new Date().setDate(new Date().getDate() - 1));
        this.dates.max = new Date(new Date().setDate(new Date().getDate() + 1));

        this.page = new Page();
        this.imgOrigin = location.origin + location.pathname;
        this.instructionTypeNames = new Array<number>();
        this.medicines = new Array<ProgressReportMedication>();
        this.activeMedicines = new Array<ProgressReportMedication>();
        this.medicationTimeline = new Array<MedicationTimelineMain>();
        this.timelineGroup = new Array<MedicationTimelineGroup>();
        this.subscriptions = new Array<Subscription>();
        this.morningInstructions = new Array<IInstruction>();
        this.afternoonInstructions = new Array<IInstruction>();
        this.nightInstructions = new Array<IInstruction>();

        this.buildAddMedicationForm();
        this.buildStopMedicationForm();
        this.buildTakeMedicationForm();
        this.buildIndentForm();
        this.hours = Array.from(Array(24)).map((_x, i) => {
            let hour = 1 + i;
            var icon = i >= 4 && i <= 10
                ? 'weather-sunset-up'
                : i > 10 && i <= 15
                    ? 'white-balance-sunny'
                    : i > 15 && i <= 19
                        ? 'weather-sunset-down'
                        : 'weather-night';
            var name = i >= 4 && i <= 10
                ? 'Morning'
                : i > 10 && i <= 15
                    ? 'Afternoon'
                    : i > 15 && i <= 19
                        ? 'Evening'
                        : 'Night';
            var isAm = hour <= 12;
            var meridiemHour = isAm ? hour : hour - 12;
            return {
                hour: hour,
                meridiemHour: meridiemHour,
                meridiem: isAm ? "AM" : "PM",
                icon: icon,
                name: name
            } as HourHolder;
        });
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;

                    this.route.parent.paramMap
                        .subscribe((params: Params) => {
                            var id = params["params"]["id"];
                            this.inputAdmissionId = parseInt(window.atob(id));
                            this.admissionId = this.inputAdmissionId || +params["params"]["id"];
                            this.isAdmission = this.inputAdmissionId ? true : params["params"]["type"] === "a";
                            this.compactMode = !!this.inputAdmissionId;
                            this.loading = true;
                            this.fetchMedicationsAsync();
                            this.fetch();
                            this.admissionFetchHelper.admissionFetch(this.admissionId, this.isAdmission, (data: IAdmissionModel) => {
                                this.admission = data;
                            });
                            this.getSettingsData();
                        });
                    this.fetchInstructions(() =>
                        this.fetchInstructions
                    );
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((item: Subscription) => {
            item.unsubscribe();
        });
    }

    get isAnyChange() {
        return this.medicines.map(x => x.progressReportMedicationId).filter(x => !x).length;
    }

    // FORM
    get medicationForm() {
        return this.addMedicationForm.controls;
    }

    resetAddMedicationForm = () => {
        this.addMedicationForm.reset();
        this.addMedicationForm.patchValue({
            medicationDurationTypeId: 1,
            startFrom: 1,
            startDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
            takeDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd')
        });
    }

    private buildAddMedicationForm() {
        this.addMedicationForm = this.formBuilder.group({
            pharmacyProductId: [null, [Validators.required]],
            unit: [null, [Validators.required, Validators.pattern(RegExHelper.twoDecimalsOnly), Validators.min(1)]],
            duration: [null, [Validators.required]],
            startFrom: [1, [Validators.required]],
            startDate: [DateHelper.todayInFormat, [Validators.required]],
            medicationDurationTypeId: [1],
            isMorning: [false],
            morningInstructionTypeId: [null],
            isAfternoon: [false],
            afternoonInstructionTypeId: [null],
            nightInstructionTypeId: [null],
            isNight: [false],
            isTimeLine: [false],
            timeLineId: this.formBuilder.array([]),
            instructions: [null],
            takeDate: [DateHelper.todayInFormat, [Validators.required]],
        });

        this.subscriptions.push(new Subscription());
        this.subscriptions[0] = this.addMedicationForm.get("isMorning").valueChanges.subscribe((is: boolean) => {
            const instruction = this.addMedicationForm.get("morningInstructionTypeId");
            instruction.clearValidators();
            is && (instruction.setValidators([Validators.required]), true) && instruction.enable();
            !is && (instruction.setValue(null), true) && instruction.disable();
            instruction.updateValueAndValidity();
        });

        this.subscriptions.push(new Subscription());
        this.subscriptions[1] = this.addMedicationForm.get("isAfternoon").valueChanges.subscribe((is: boolean) => {
            const instruction = this.addMedicationForm.get("afternoonInstructionTypeId");
            instruction.clearValidators();
            is && (instruction.setValidators([Validators.required]), true) && instruction.enable();
            !is && (instruction.setValue(null), true) && instruction.disable();
            instruction.updateValueAndValidity();
        });

        this.subscriptions.push(new Subscription());
        this.subscriptions[2] = this.addMedicationForm.get("isNight").valueChanges.subscribe((is: boolean) => {
            const instruction = this.addMedicationForm.get("nightInstructionTypeId");
            instruction.clearValidators();
            is && (instruction.setValidators([Validators.required]), true) && instruction.enable();
            !is && (instruction.setValue(null), true) && instruction.disable();
            instruction.updateValueAndValidity();
        });
        this.subscriptions.push(new Subscription());
        this.subscriptions[3] = this.addMedicationForm.get("isTimeLine").valueChanges.subscribe((is: boolean) => {
            const instruction = this.addMedicationForm.get("timeLineId");

            const instruction1 = this.addMedicationForm.get("isMorning");
            const instruction2 = this.addMedicationForm.get("isAfternoon");
            const instruction3 = this.addMedicationForm.get("isNight");

            instruction.clearValidators();
            is && (instruction.setValidators([Validators.required]), true) && instruction.enable();
            if (is) {
                this.isDisabled = false;
                instruction1.setValue(false);
                instruction1.disable();
                instruction2.setValue(false);
                instruction2.disable();
                instruction3.setValue(false);
                instruction3.disable();
            }
            else if (!is) {
                this.isDisabled = true
                instruction1.enable();
                instruction2.enable();
                instruction3.enable();
                this.timeslotschecked.splice(0, this.timeslotschecked.length);
                this.priviousValues.splice(0, this.priviousValues.length);

            }
            instruction.updateValueAndValidity();
        });

        //this.subscriptions[3] = this.addMedicationForm.get("startFrom").valueChanges.subscribe((from: number) => {
        //    const startDate = this.addMedicationForm.get("startDate");
        //    startDate.clearValidators();
        //    from === 3 && (startDate.setValidators([Validators.required]), true) && startDate.enable();

        //    const tomorrow = new Date();
        //    tomorrow.setDate(tomorrow.getDate() + 1);
        //    from === 1 && (startDate.setValue(this.datePipe.transform(new Date(), 'yyyy-MM-dd')), true) && startDate.disable();
        //    from === 2 && (startDate.setValue(this.datePipe.transform(tomorrow, 'yyyy-MM-dd')), true) && startDate.disable();
        //    startDate.updateValueAndValidity();
        //});
    }

    buildUpdateMedicationForm() {
        this.addMedicationForm.patchValue({
            ProgressReportMedicationId: this.selectedMedicine.progressReportMedicationId,
            pharmacyProductId: this.selectedMedicine.pharmacyProductId,
            unit: this.selectedMedicine.unit,
            duration: this.selectedMedicine.duration,
            //startFrom: this.selectedMedicine.startFrom,
            startDate: this.selectedMedicine.startDate,
            medicationDurationTypeId: this.selectedMedicine.medicationDurationTypeId,
            isMorning: this.selectedMedicine.isMorningTaking,
            morningInstructionTypeId: this.selectedMedicine.morningInstructionTypeId,
            isAfternoon: this.selectedMedicine.isAfternoonTaking,
            afternoonInstructionTypeId: this.selectedMedicine.afternoonInstructionTypeId,
            nightInstructionTypeId: this.selectedMedicine.nightInstructionTypeId,
            isNight: this.selectedMedicine.isNightTaking,
            instructions: this.selectedMedicine.instructions,
            takeDate: [DateHelper.todayInFormat, [Validators.required]],
        });

        this.subscriptions.push(new Subscription());
        this.subscriptions[0] = this.addMedicationForm.get("isMorning").valueChanges.subscribe((is: boolean) => {
            const instruction = this.addMedicationForm.get("morningInstructionTypeId");
            instruction.clearValidators();
            is && (instruction.setValidators([Validators.required]), true) && instruction.enable();
            !is && (instruction.setValue(null), true) && instruction.disable();
            instruction.updateValueAndValidity();
        });

        this.subscriptions.push(new Subscription());
        this.subscriptions[1] = this.addMedicationForm.get("isAfternoon").valueChanges.subscribe((is: boolean) => {
            const instruction = this.addMedicationForm.get("afternoonInstructionTypeId");
            instruction.clearValidators();
            is && (instruction.setValidators([Validators.required]), true) && instruction.enable();
            !is && (instruction.setValue(null), true) && instruction.disable();
            instruction.updateValueAndValidity();
        });

        this.subscriptions.push(new Subscription());
        this.subscriptions[2] = this.addMedicationForm.get("isNight").valueChanges.subscribe((is: boolean) => {
            const instruction = this.addMedicationForm.get("nightInstructionTypeId");
            instruction.clearValidators();
            is && (instruction.setValidators([Validators.required]), true) && instruction.enable();
            !is && (instruction.setValue(null), true) && instruction.disable();
            instruction.updateValueAndValidity();
        });
    }

    private buildStopMedicationForm() {
        this.stopMedicationForm = this.formBuilder.group({
            reason: [null]
        });
    }

    get takeForm() {
        return this.takeMedicationForm.controls;
    }

    private buildTakeMedicationForm() {
        this.takeMedicationForm = this.formBuilder.group({
            isMorning: [false],
            isAfternoon: [false],
            isNight: [false]
        });
    }

    private buildIndentForm() {
        this.indentForm = this.formBuilder.group({
            records: this.formBuilder.array([])
        });
    }

    onUpdateMedication() {
        this.medicationSubmitted = true;
        if (this.addMedicationForm.invalid) return;
        const request = this.addMedicationForm.getRawValue();
        request.duration = Number(request.duration);
        request.timeLineId = (request.timeLineId).map(i => Number(i)).filter(Boolean);
        request.timeLineId = request.timeLineId.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
        request["modifiedByName"] = this.page.userAccount.fullName;
        request["modifiedBy"] = this.page.userAccount.accountId;
        request["admissionId"] = this.admissionId;
        request["progressReportMedicationId"] = this.selectedMedicine.progressReportMedicationId,
            this.httpService
                .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReport.base, ApiResources.progressReport.updateMedication), request)
                .pipe(finalize(() => this.submitting = false))
                .subscribe(
                    (response: GenericResponse) => {
                        if (response.status === GenericStatus[GenericStatus.Success]) {
                            this.priviousValues.splice(0, this.priviousValues.length);
                            this.fetch();
                            this.onCloseModal();
                        } else {
                            this.notifyService.defaultErrorToast();
                        }
                    },
                    () => {
                        this.notifyService.defaultErrorToast();
                    }
                );
    }
    // MODEL
    modelOpenHelper = (content: TemplateRef<any>) => {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: "xl",
            windowClass: "custom-modal effect-scale"
        });
    }

    modelOpenHelperLarge = (content: TemplateRef<any>) => {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: "sm",
            windowClass: "custom-modal extra-large-modal effect-scale"
        });
    }

    addInstructions = (item: IndentMedicine, content: TemplateRef<any>) => {
        this.addMedicationForm.patchValue({
            pharmacyProductId: item.pharmacyProductId
        });
        this.selectedMedication = {
            name: item.productName,
            typeName: item.productTypeName
        } as IMedicationAsync;
        this.addMedicationForm.patchValue({
            unit: item.quantity
        }) 

        this.autoSelected = true;
        this.openWithCondition(content);
    }

    onOpenModel(content: TemplateRef<any>) {
        this.bannerService.getBannerImage((is) => { this.bannerBasics = is; });
        this.resetAddMedicationForm();
        this.openWithCondition(content);
    }

    openWithCondition = (content: TemplateRef<any>) => {
        if (!this.morningInstructions.length) {
            this.loadingInstructions = true;
            this.fetchInstructions(() => {
                this.modelOpenHelper(content);
            });
        } else {
            this.modelOpenHelper(content);
        }
    }

    onOpenMultipleTakeModel(content: TemplateRef<any>) {
        this.currentTakeMode = TakeMode.Multiple;
        this.takeMedicationForm.reset();
        this.modelOpenHelperLarge(content);
    }

    onOpenTakeModel(content: TemplateRef<any>, item: ProgressReportMedication) {
        this.currentTakeMode = TakeMode.Single;
        this.selectedMedicine = item;
        this.takeMedicationForm.reset();
        this.modelOpenHelperLarge(content);
    }

    onOpenViewModel(content: TemplateRef<any>, item: ProgressReportMedication) {
        this.selectedMedicine = item;
        this.modelOpenHelperLarge(content);
        setTimeout(() => {
            this.fetchTimeline();
        });
    }


    isSelectedCharacteristics(value: string) {
        const selectedCharacteristics = this.addMedicationForm.get("timeLineId").value || [];
        return selectedCharacteristics.indexOf(value) >= 0;
    }
    onChangeCharacteristics(value: number) {
        const selectedCharacteristics = this.addMedicationForm.get("timeLineId").value || [];
        const index = selectedCharacteristics.indexOf(value);

        if (index === -1) {
            selectedCharacteristics.push(value);
        } else {
            selectedCharacteristics.splice(index, 1);
        }

        this.addMedicationForm.patchValue({
            characteristics: selectedCharacteristics
        });
    }

    onOpenStopModel(content: TemplateRef<any>, item: ProgressReportMedication) {
        this.selectedMedicine = item;
        this.stopMedicationForm.reset();
        this.modelOpenHelper(content);
    }

    onOpenUpdateModel(content: TemplateRef<any>, item: ProgressReportMedication) {
        this.IsEdit = true;
        this.buildAddMedicationForm();
        this.timeslotschecked = []
        this.selectedMedicine = item;
         //if (this.selectedMedicine.medicationFrequency.length > 1) {
        //    this.afternoonInstructionId = this.selectedMedicine.medicationFrequency.find(x => x.medicationInstructionDeepTypeId === 2).medicationInstructionTypeId;
        //}
        //if (this.selectedMedicine.medicationFrequency.length > 2) {
        //    this.nightInstructionId = this.selectedMedicine.medicationFrequency.find(x => x.medicationInstructionDeepTypeId === 3).medicationInstructionTypeId;
        //}

        if (this.selectedMedicine.medicationFrequency.length > 0) {
            for (let i = 0; i < this.selectedMedicine.medicationFrequency.length; ++i) {
                if (this.selectedMedicine.medicationFrequency[i].medicationInstructionDeepTypeId == 1) {
                    this.addMedicationForm.value.isMorning = this.selectedMedicine.medicationFrequency[i].medicationInstructionName;
                    this.selectedMedicine.morningInstructionTypeId = this.selectedMedicine.medicationFrequency[i].medicationInstructionTypeId;
                    this.selectedMedicine.isMorningTaking = true;

                }
                else if (this.selectedMedicine.medicationFrequency[i].medicationInstructionDeepTypeId == 2) {
                    if (this.selectedMedicine.medicationFrequency[i].medicationInstructionName != null && this.selectedMedicine.medicationFrequency[i].medicationInstructionName != undefined) {
                        this.selectedMedicine.isAfternoonTaking = true;
                    }
                    this.selectedMedicine.afternoonInstructionTypeId = this.selectedMedicine.medicationFrequency[i].medicationInstructionTypeId;
                }
                else if (this.selectedMedicine.medicationFrequency[i].medicationInstructionDeepTypeId == 3) {
                    if (this.selectedMedicine.medicationFrequency[i].medicationInstructionName != null && this.selectedMedicine.medicationFrequency[i].medicationInstructionName != undefined) {
                        this.selectedMedicine.isNightTaking = true;
                    }
                }
                else if (this.selectedMedicine.medicationFrequency[i].medicationInstructionDeepTypeId == 4) {
                    if (this.selectedMedicine.medicationFrequency[i].medicationInstructionName != null && this.selectedMedicine.medicationFrequency[i].medicationInstructionName != undefined) {
                        this.timeslotschecked.push(this.selectedMedicine.medicationFrequency[i].medicationInstructionName);
                        this.priviousValues.push(this.selectedMedicine.medicationFrequency[i].medicationInstructionTypeId);
                        this.addMedicationForm.value.isTimeLine = true;
                    }
                }
                this.selectedMedicine.nightInstructionTypeId = this.selectedMedicine.medicationFrequency[i].medicationInstructionTypeId;
            }
        }


        this.addMedicationForm.patchValue({
            pharmacyProductId: this.selectedMedicine.pharmacyProductId,
            unit: this.selectedMedicine.unit,
            duration: Number(this.selectedMedicine.duration),
            startDate: this.selectedMedicine.startDate,
            medicationDurationTypeId: this.selectedMedicine.medicationDurationTypeId,
            instructions: this.selectedMedicine.instructions,
            isMorning: this.selectedMedicine.isMorningTaking,
            morningInstructionTypeId: this.selectedMedicine.morningInstructionTypeId,
            isAfternoon: this.selectedMedicine.isAfternoonTaking,
            afternoonInstructionTypeId: this.selectedMedicine.afternoonInstructionTypeId,
            nightInstructionTypeId: this.selectedMedicine.nightInstructionTypeId,
            isNight: this.selectedMedicine.isNightTaking,
            isTimeLine: this.addMedicationForm.value.isTimeLine,
            timeLineId: this.timeslotschecked,

        })

        //if (this.afternoonInstructionId) {
        //    record.push({
        //        medicationInstructionName: this.afternoonInstructions.find(x => x.id === this.afternoonInstructionId).name,
        //        medicationInstructionDeepTypeId: 2,
        //        medicationInstructionTypeId: this.afternoonInstructionId,
        //        status: null
        //    } as MedicationFrequency)
        //}

        //if (this.nightInstructionId) {
        //    record.push({
        //        medicationInstructionName: this.nightInstructions.find(x => x.id === this.nightInstructionId).name,
        //        medicationInstructionDeepTypeId: 3,
        //        medicationInstructionTypeId: this.nightInstructionId,
        //        status: null
        //    } as MedicationFrequency)
        //}
        this.fetchInstructions(() => {
            this.modelOpenHelper(content);
        });
    }

    onCloseModal() {
        try {
            this.medicationSubmitted = false;
            this.autoSelected = false;
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            // ignored;
        }
    }

    // Medication
    onMedicationSelect = ($event) => {
        this.selectedMedication = $event as IMedicationAsync;
    }

    onRemoveMedicine = (medicine: ProgressReportMedication) => {

        this.selectedMedicine = medicine

        //  fetching the details of medicine dispenced to the patient from Medication Starting date to Current date.
        setTimeout(() => {
            this.fetchTimeline();
        });


        this.notifyService.delete(() => {
            let medicineTaken = 1; //Medication Taken status is 1

            // filtering array to find objects containing meicineTaken in any key-value pair
            let filteredArray = this.medicationTimeline.filter(obj => {

                // this works at time slots are given
                if (obj.slotArray.length > 0) {
                    return obj.slotArray.find(a => {
                        if (a.timeslotStatus == medicineTaken) {
                            return true;
                        }
                    });
                }
                // this works at Morning,Afternoon,Night
                return Object.values(obj).some(val => {
                    if (typeof val === 'number' && val === medicineTaken) {
                        return true;
                    }
                });
            });


            if (filteredArray.length > 0) {
                this.notifyService.info("This Medicine Alreay Dispenced To Patient so we can't Delete.");
            }
            else {
                const id = medicine.progressReportMedicationId
                const data = {
                    id,
                    by: this.page.userAccount.accountId,
                    admissionId: this.admissionId,
                    createdBy: this.page.userAccount.fullName,
                    stopReason: this.stopMedicationForm.value.reason
                }
                this.httpService
                    .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReport.base, ApiResources.progressReport.deletemedication), data)
                    //.pipe(finalize(() => this.stopping = false))
                    .subscribe(
                        (response: GenericResponse) => {
                            if (response.status === GenericStatus[GenericStatus.Success]) {
                                this.fetch();
                                this.onCloseModal();
                            } else {
                                this.notifyService.warning(response.message);
                            }
                        },
                        () => {
                            this.notifyService.defaultError();
                        }
                    );
            }
        });
    }

    onStopMedication = () => {
        this.stopping = true;
        const id = this.selectedMedicine.progressReportMedicationId;
        const data = {
            id,
            by: this.page.userAccount.accountId,
            admissionId: this.admissionId,
            createdBy: this.page.userAccount.fullName,
            stopReason: this.stopMedicationForm.value.reason
        }

        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReport.base, ApiResources.progressReport.stopMedication), data)
            .pipe(finalize(() => this.stopping = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.fetch();
                        this.onCloseModal();
                    } else {
                        this.notifyService.warning(response.message);
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }

    takeQuickMedicine = (item: ProgressReportMedication, type: number, id: number) => {
        if (!this.admission.isDischarged && item.activeStatus === ActiveStatus.Active) {

            item.isMorningTaking = type === 1;
            item.isAfternoonTaking = type === 2;
            item.isNightTaking = type === 3;
            item.isTimeslotsTaking = type === 4;
            this.selectedMedicine = item;

            const obj = {
                id: this.selectedMedicine.progressReportMedicationId,
                by: this.page.userAccount.accountId,
                admissionId: this.admissionId,
                createdBy: this.page.userAccount.fullName,
                medicationDate: { year: this.dates.current.getFullYear(), month: this.dates.current.getMonth() + 1, day: this.dates.current.getDate() },
                instructionIds: [id]
            }

            this.httpService
                .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReport.base, ApiResources.progressReport.takeMedication), [obj])
                .pipe(finalize(() => this.taking = false))
                .subscribe(
                    (response: GenericResponse) => {
                        if (response.status === GenericStatus[GenericStatus.Success]) {
                            this.fetch(false, false);
                            this.takeMedicationSubmitted = false;
                            this.selectedMedicine = null;
                            this.takeMedicationForm.reset();
                            this.onCloseModal();
                        } else {
                            this.notifyService.warning(response.message);
                        }
                    },
                    () => {
                        this.notifyService.defaultError();
                    }
                );
        }
    }

    onTakeMedication = () => {
        this.takeMedicationSubmitted = true;
        if (!this.takeMedicationForm.value.isMorning && !this.takeMedicationForm.value.isAfternoon && !this.takeMedicationForm.value.isNight) return;

        this.taking = true;
        let data = [];
        if (this.currentTakeMode === TakeMode.Single) {
            const obj = {
                id: this.selectedMedicine.progressReportMedicationId,
                by: this.page.userAccount.accountId,
                admissionId: this.admissionId,
                createdBy: this.page.userAccount.fullName,
                medicationDate: this.dates.current,
                instructionIds: []
            }

            this.takeMedicationForm.value.isMorning && obj.instructionIds.push(this.selectedMedicine.medicationFrequency.find(x => x.medicationInstructionDeepTypeId === 1).medicationInstructionTypeId);
            this.takeMedicationForm.value.isAfternoon && obj.instructionIds.push(this.selectedMedicine.medicationFrequency.find(x => x.medicationInstructionDeepTypeId === 2).medicationInstructionTypeId);
            this.takeMedicationForm.value.isNight && obj.instructionIds.push(this.selectedMedicine.medicationFrequency.find(x => x.medicationInstructionDeepTypeId === 3).medicationInstructionTypeId);
            this.takeMedicationForm.value.isTimeLine && obj.instructionIds.push(this.selectedMedicine.medicationFrequency.find(x => x.medicationInstructionDeepTypeId === 4).medicationInstructionTypeId);
            data.push(obj);
        } else {
            this.activeMedicines.forEach(item => {
                const obj = {
                    id: item.progressReportMedicationId,
                    by: this.page.userAccount.accountId,
                    medicationDate: this.dates.current,
                    instructionIds: []
                }
                this.takeMedicationForm.value.isMorning && obj.instructionIds.push(item.medicationFrequency.find(x => x.medicationInstructionDeepTypeId === 1).medicationInstructionTypeId);
                this.takeMedicationForm.value.isAfternoon && obj.instructionIds.push(item.medicationFrequency.find(x => x.medicationInstructionDeepTypeId === 2).medicationInstructionTypeId);
                this.takeMedicationForm.value.isNight && obj.instructionIds.push(item.medicationFrequency.find(x => x.medicationInstructionDeepTypeId === 3).medicationInstructionTypeId);
                this.takeMedicationForm.value.isTimeLine && obj.instructionIds.push(this.selectedMedicine.medicationFrequency.find(x => x.medicationInstructionDeepTypeId === 4).medicationInstructionTypeId);
                data.push(obj);
            });
        }

        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReport.base, ApiResources.progressReport.takeMedication), data)
            .pipe(finalize(() => this.taking = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.fetch();
                        this.takeMedicationSubmitted = false;
                        this.selectedMedicine = null;
                        this.takeMedicationForm.reset();
                        this.onCloseModal();
                    } else {
                        this.notifyService.warning(response.message);
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }

    // Checkbox Values binding to Form Array 
    onCheckboxChange(e) {
        let timeLineId: FormArray = this.addMedicationForm.get('timeLineId') as FormArray;

        if (e.target.checked) {
            timeLineId.push(new FormControl(e.target.value));
        } else {
            const index = timeLineId.controls.findIndex(x => x.value === e.target.value);
            timeLineId.removeAt(index);
        }
    }
    //Editing Medication Form
    onCheckboxChangeEdit(e) {
        let timeLineId: FormArray = this.addMedicationForm.get('timeLineId') as FormArray;

        // Here PreviousValues are accessed at the time of form Edit
        if (timeLineId.controls.length == 0) {
            this.priviousValues = this.priviousValues.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
            if (this.priviousValues.length > 0) {
                for (let i = 0; i < this.priviousValues.length; i++) {
                    timeLineId.push(new FormControl(this.priviousValues[i]));
                }
            }
        }
        if (e.target.checked) {
            timeLineId.push(new FormControl(e.target.value));
        }
        else {
            const index = timeLineId.controls.findIndex(x => x.value === Number(e.target.value));
            timeLineId.removeAt(index);
            //this.priviousValues = this.priviousValues.filter(item => item != e.target.value);
            let indexq = this.priviousValues.indexOf(Number(e.target.value));
            if (indexq > -1) {
                this.priviousValues.splice(indexq, 1);
            }
        }
    }

    onAddMedication = () => {
        this.medicationSubmitted = true;
        if (this.addMedicationForm.invalid || (!this.addMedicationForm.value.isMorning && !this.addMedicationForm.value.isAfternoon && !this.addMedicationForm.value.isNight && !this.addMedicationForm.value.isTimeLine)) return;

        const record = new ProgressReportMedication();
        record.pharmacyProductId = this.addMedicationForm.value.pharmacyProductId;
        if (this.addMedicationForm.value.unit === 0) {
            this.notifyService.warning("Quantity can't be zero.");
            return;
        }
        if (this.addMedicationForm.value.duration == 0) {
            this.notifyService.warning("Duration can't be zero.");
            return;
        }
        record.unit = this.addMedicationForm.value.unit;
        record.duration = this.addMedicationForm.value.duration;
        record.medicationDurationTypeId = this.addMedicationForm.value.medicationDurationTypeId;
        record.morningInstructionTypeId = this.addMedicationForm.value.morningInstructionTypeId;
        record.afternoonInstructionTypeId = this.addMedicationForm.value.afternoonInstructionTypeId;
        record.nightInstructionTypeId = this.addMedicationForm.value.nightInstructionTypeId;
        record.timeLineId = this.addMedicationForm.value.timeLineId.filter(x => x !== null);
        record.instructions = this.addMedicationForm.value.instructions;

        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        record.takeDate = this.addMedicationForm.value.takeFrom;
        //record.startDate = this.addMedicationForm.value.startFrom === 1
        //    ? new Date(this.datePipe.transform(new Date(), 'yyyy-MM-dd'))
        //    : this.addMedicationForm.value.startFrom === 2 ? new Date(this.datePipe.transform(tomorrow, 'yyyy-MM-dd'))
        //        : new Date(this.addMedicationForm.value.startDate);
        record.startDate = this.addMedicationForm.value.startDate;

        record.active = true;
        record.activeStatus = ActiveStatus.Active;
        record.createdDate = new Date();
        record.createdByName = this.page.userAccount.fullName;

        record.productName = this.selectedMedication.name;
        record.productIcon = this.selectedMedication.icon;
        record.productTypeName = this.selectedMedication.typeName;
        record.productGenericName = this.selectedMedication.genericName;
        record.productCompanyName = this.selectedMedication.companyName;
        record.productSupplierName = this.selectedMedication.supplierName;

        var dummyStartDate = new Date(record.startDate);
        record.medicationDurationTypeId === 1 && dummyStartDate.setDate(dummyStartDate.getDate() + (record.duration - 1));
        record.medicationDurationTypeId === 2 && dummyStartDate.setDate(dummyStartDate.getDate() + ((record.duration * 7) - 1));
        record.medicationDurationTypeId === 3 && dummyStartDate.setDate(dummyStartDate.getDate() + ((record.duration * 30) - 1));
        record.endDate = new Date(dummyStartDate);

        record.medicationFrequency = new Array<MedicationFrequency>();
        if (record.morningInstructionTypeId) {
            record.medicationFrequency.push({
                medicationInstructionName: this.morningInstructions.find(x => x.id === record.morningInstructionTypeId).name,
                medicationInstructionDeepTypeId: 1,
                medicationInstructionTypeId: record.morningInstructionTypeId,
                status: null
            } as MedicationFrequency)
        }

        if (record.afternoonInstructionTypeId) {
            record.medicationFrequency.push({
                medicationInstructionName: this.afternoonInstructions.find(x => x.id === record.afternoonInstructionTypeId).name,
                medicationInstructionDeepTypeId: 2,
                medicationInstructionTypeId: record.afternoonInstructionTypeId,
                status: null
            } as MedicationFrequency)
        }

        if (record.nightInstructionTypeId) {
            record.medicationFrequency.push({
                medicationInstructionName: this.nightInstructions.find(x => x.id === record.nightInstructionTypeId).name,
                medicationInstructionDeepTypeId: 3,
                medicationInstructionTypeId: record.nightInstructionTypeId,
                status: null
            } as MedicationFrequency)
        }
        // Pushing Time Slot Values.
        if (record.timeLineId.length > 0) {
            for (let i = 0; record.timeLineId.length > i; i++) {
                record.medicationFrequency.push({
                    medicationInstructionName: this.timeLineSlots.find(x => x.id === Number(record.timeLineId[i])).name,
                    medicationInstructionDeepTypeId: 4,
                    medicationInstructionTypeId: Number(record.timeLineId[i]),
                    status: null
                } as MedicationFrequency)
            }
        }

        //record.morningInstructionTypeName = record.morningInstructionTypeId
        //    ? this.morningInstructions.find(x => x.id === record.morningInstructionTypeId).name
        //    : null;
        //record.afternoonInstructionTypeName = record.afternoonInstructionTypeId
        //    ? this.afternoonInstructions.find(x => x.id === record.afternoonInstructionTypeId).name
        //    : null;
        //record.nightInstructionTypeName = record.nightInstructionTypeId
        //    ? this.nightInstructions.find(x => x.id === record.nightInstructionTypeId).name
        //    : null;

        this.medicines.unshift(record);
        this.medicationSubmitted = false;
        this.selectedMedication = null;
        this.resetAddMedicationForm();

        // if (this.autoSelected) {
        //this.submit();
        this.submitting = true;

        var data = {
            admissionId: this.admissionId,
            createdBy: this.page.userAccount.accountId,
            records: []
        };

        this.medicines.filter(x => !x.progressReportMedicationId).forEach(item => {
            data.records.push({
                pharmacyProductId: item.pharmacyProductId,
                unit: +item.unit,
                duration: +item.duration,
                medicationDurationTypeId: item.medicationDurationTypeId,
                medicationInstructions: [item.morningInstructionTypeId,
                item.afternoonInstructionTypeId,
                item.nightInstructionTypeId].filter(Boolean),
                instructions: item.instructions,
                startDate: new Date(item.startDate),
                endDate: new Date(item.endDate),
                takeDate: new Date(item.takeDate),
                timeLineId: (record.timeLineId).map(i => Number(i)).filter(Boolean)
            });
        });

        if (!data.records.length) {
            this.submitting = false;
            return;
        }
        data["modifiedByName"] = this.page.userAccount.fullName;
        data["createdBy"] = this.page.userAccount.accountId;

        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReport.base, ApiResources.progressReport.insertMedication), data)
            .pipe(finalize(() => this.submitting = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.dates.current = new Date();
                        this.timelineCommunicationService.set(true);
                        this.fetch();
                    } else {
                        this.notifyService.defaultErrorToast();
                    }
                },
                () => {
                    this.notifyService.defaultErrorToast();
                }
            );
        this.onCloseModal();
    }

    private fetchMedicationsAsync() {
        this.medicationsAsync = this.medicationsInput.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((term: string) =>
                term && term.length >= 2
                    ? this.resourceService.medicationAsync(term).pipe(
                        tap(() => this.loadingMedicationsAsync = true),
                        catchError(() => { return of([]) }),
                        finalize(() => this.loadingMedicationsAsync = false)
                    )
                    : of([])
            )
        );
    }

    // Early Start
    fetchInstructions = (callback: Function) => {
        this.httpService
            .post<Array<IInstruction>>(ApiResources.getURI(ApiResources.progressReport.base, ApiResources.progressReport.instructions), {})
            .pipe(finalize(() => this.loadingInstructions = false))
            .subscribe(
                (response: Array<IInstruction>) => {
                    this.morningInstructions = response.filter(x => x.type === 1);
                    this.afternoonInstructions = response.filter(x => x.type === 2);
                    this.nightInstructions = response.filter(x => x.type === 3);
                    this.timeLineSlots = response.filter(x => x.type === 4);
                    callback();
                },
                () => {
                    this.morningInstructions = new Array<IInstruction>();
                    this.afternoonInstructions = new Array<IInstruction>();
                    this.nightInstructions = new Array<IInstruction>();
                    this.timeLineSlots = new Array<IInstruction>();
                    callback();
                }
            );
    }

    // Concept Methods
    onPrevious = () => {
        if (!this.dates.showMin) return;
        this.dates.current = new Date(new Date(this.dates.current).setDate(new Date(this.dates.current).getDate() - 1));
        this.fetch(true);
    }

    checkMin = () => {
        this.dates.showMin = !(this.dates.current.getFullYear() === this.dates.min.getFullYear() &&
            this.dates.current.getMonth() === this.dates.min.getMonth() &&
            this.dates.current.getDate() === this.dates.min.getDate());
    }

    onNext = () => {
        if (!this.dates.showMax) return;
        this.dates.current = new Date(new Date(this.dates.current).setDate(new Date(this.dates.current).getDate() + 1));
        this.fetch(true);
    }

    checkMax = () => {
        this.dates.showMax = !(this.dates.current.getFullYear() === this.dates.max.getFullYear() &&
            this.dates.current.getMonth() === this.dates.max.getMonth() &&
            this.dates.current.getDate() === this.dates.max.getDate());
    }

    isMaxGtThanToday = () => {
        var now = new Date();
        var max = this.dates.max;
        return now.getFullYear() > max.getFullYear() ||
            (now.getMonth() > max.getMonth() &&
                now.getFullYear() === max.getFullYear()) ||
            (now.getDate() > max.getDate() &&
                now.getMonth() === max.getMonth() &&
                now.getFullYear() === max.getFullYear());
    }

    fetch = (isPaging: boolean = false, showLoading: boolean = true) => {
        if (showLoading) {
            this.loading = true;
        }

        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReport.base, ApiResources.progressReport.fetchMedication),
                { admissionId: this.admissionId, dateModel: { year: this.dates.current.getFullYear(), month: this.dates.current.getMonth() + 1, day: this.dates.current.getDate() } })
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        const data = response.data as GlobalProgressReportMedication<ProgressReportMedication, IndentMedicine>;
                        const records = data.records as Array<ProgressReportMedication>;
                        this.indentMedicines = data.indentMedicines as Array<IndentMedicine>;

                        const dates = data.dates as Dates;
                        this.dates.min = new Date(dates.min || new Date());
                        this.dates.max = new Date(dates.max || new Date());

                        this.currentRunningMode = this.isGreaterDate(new Date(), this.dates.max)
                            ? RunningMode.Past
                            : RunningMode.Current;

                        if (!isPaging && this.isMaxGtThanToday()) {
                            this.dates.current = new Date(this.dates.max);
                        }

                        this.checkMin();
                        this.checkMax();

                        const instructionNames = new Array<number>();
                        records.forEach(record => {
                            if (record.medicationFrequency.length) {
                                record.medicationFrequency.forEach(token => {

                                    // MORNING
                                    if ((token.medicationInstructionDeepTypeId === 1 && token.status === null)) {
                                        token.status = data.currentDayId > 1 && data.currentDayId !== 5 ? false : null;
                                    }

                                    // AFTERNOON
                                    if ((token.medicationInstructionDeepTypeId === 2 && token.status === null)) {
                                        token.status = data.currentDayId !== 5 && (data.currentDayId === 12 || data.currentDayId === 3 || data.currentDayId === 4) ? false : null;
                                    }

                                    // NIGHT
                                    if ((token.medicationInstructionDeepTypeId === 3 && token.status === null)) {
                                        token.status = data.currentDayId !== 5 && data.currentDayId === 4 ? false : null;
                                    }

                                    //TimeSlots
                                    if (token.medicationInstructionDeepTypeId === 4 && token.status === null) {
                                        const currentTime = new Date();
                                        let currentHour = currentTime.getHours();
                                        let givenTimeHours = Number(token.medicationInstructionName.replace(/\D/g, ''));
                                        if (token.medicationInstructionName.includes('PM')) {
                                            givenTimeHours = givenTimeHours + 12
                                            if (Number(givenTimeHours) < currentHour) {
                                                token.status = false;
                                            }
                                            if (Number(givenTimeHours) >= currentHour) {
                                                token.status = null;
                                            }
                                        }
                                        if (token.medicationInstructionName.includes('AM')) {
                                            if (givenTimeHours < currentHour) {
                                                token.status = false;
                                            }
                                            if (givenTimeHours >= currentHour) {
                                                token.status = null;
                                            }
                                        }
                                    }
                                });
                            }

                            LinqHelper.sort(record.medicationFrequency, "medicationInstructionDeepTypeId");

                            //if (record.activeStatus === ActiveStatus.Active) {
                            //    record.morningInstructionTypeName && !record.morningInstructionStatus && instructionNames.push(1);
                            //    record.afternoonInstructionTypeName && !record.afternoonInstructionStatus && instructionNames.push(2);
                            //    record.nightInstructionTypeName && !record.nightInstructionStatus && instructionNames.push(3);
                            //}

                            //var dummyStartDate = new Date(record.startDate);
                            //record.medicationDurationTypeId === 1 && dummyStartDate.setDate(dummyStartDate.getDate() + (record.duration - 1));
                            //record.medicationDurationTypeId === 2 && dummyStartDate.setDate(dummyStartDate.getDate() + ((record.duration * 7) - 1));
                            //record.medicationDurationTypeId === 3 && dummyStartDate.setDate(dummyStartDate.getDate() + ((record.duration * 30) - 1));
                            //record.endDate = new Date(dummyStartDate);

                            var remainingDays = 0;
                            if (record.medicationDurationTypeId == 3) {
                                remainingDays = record.duration * 30;
                            } else if (record.medicationDurationTypeId == 2) {
                                remainingDays = record.duration * 7;
                            } else {
                                remainingDays = record.duration;
                            }

                            const diffTime = Math.abs(new Date(record.startDate).getTime() - this.dates.current.getTime());
                            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                            // record.leftDays = record.duration - diffDays;
                            record.leftDays = remainingDays - diffDays;

                            record.activeStatus = !record.active
                                ? ActiveStatus.Stopped
                                : diffDays > remainingDays
                                    ? ActiveStatus.Completed
                                    : ActiveStatus.Active;

                            if (new Date(record.startDate).getTime() > this.dates.current.getTime()) {
                                record.activeStatus = ActiveStatus.NotStarted;
                            }
                        });
                        this.instructionTypeNames = instructionNames.filter(LinqHelper.uniqueOnly);
                        this.activeMedicines = records.filter(x => x.activeStatus === ActiveStatus.Active);
                        this.medicines = records.sort((a, b) => LinqHelper.compare(a, b, "activeStatus"));
                    } else {
                        this.notifyService.warning(response.message);
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }

    chekingTime = (token) => {
        const currentTime = new Date();
        let currentHour = currentTime.getHours();
        let givenTimeHours = Number(token.medicationInstructionName.replace(/\D/g, ''));
        if (token.medicationInstructionName.includes('PM')) {
            givenTimeHours = givenTimeHours + 12
            if (Number(givenTimeHours) < currentHour) {
                return false
            }
            if (Number(givenTimeHours) >= currentHour) {
                return true
            }
        }
        if (token.medicationInstructionName.includes('AM')) {
            if (givenTimeHours < currentHour) {
                return false
            }
            if (givenTimeHours >= currentHour) {
                return true
            }
        }
    }

    fetchTimeline = () => {
        this.medicationTimeline = new Array<MedicationTimelineMain>();
        this.timelineGroup = new Array<MedicationTimelineGroup>();
        this.loadingTimeline = true;
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReport.base, ApiResources.progressReport.fetchMedicationTimeline),
                { admissionId: this.admissionId, medicineId: this.selectedMedicine.progressReportMedicationId })
            .pipe(finalize(() => this.loadingTimeline = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        var medicineTimeline = new Array<MedicationTimelineMain>();
                        if (this.selectedMedicine.activeStatus === ActiveStatus.NotStarted) {
                            this.medicationTimeline = medicineTimeline;
                            return;
                        }

                        const records = LinqHelper.groupBy((response.data as Array<MedicationTimeline>), x => x.medicationDate);
                        records.forEach(items => {
                            this.timelineGroup.push({
                                headerDate: new Date(items[0].medicationDate),
                                records: items
                            });
                        });

                        var startDate = new Date(this.selectedMedicine.startDate);

                        //var dummyStartDate = new Date(this.selectedMedicine.startDate);
                        //this.selectedMedicine.medicationDurationTypeId === 1 && dummyStartDate.setDate(dummyStartDate.getDate() + (this.selectedMedicine.duration - 1));
                        //this.selectedMedicine.medicationDurationTypeId === 2 && dummyStartDate.setDate(dummyStartDate.getDate() + ((this.selectedMedicine.duration * 7) - 1));
                        //this.selectedMedicine.medicationDurationTypeId === 3 && dummyStartDate.setDate(dummyStartDate.getDate() + ((this.selectedMedicine.duration * 30) - 1));
                        //var calculatedEndDate = dummyStartDate;

                        var endDate = this.selectedMedicine.activeStatus === ActiveStatus.Active
                            ? this.dates.current
                            : this.selectedMedicine.activeStatus === ActiveStatus.Stopped
                                ? new Date(this.selectedMedicine.modifiedDate)
                                : this.selectedMedicine.endDate;

                        var counter = -1;
                        while (true) {
                            ++counter;
                            if (counter > 0) {
                                startDate.setDate(startDate.getDate() + 1);
                            }

                            if (this.isGreaterDate(startDate, endDate)) break;
                            var isPresent = this.timelineGroup.find(x => this.isSameDate(x.headerDate, startDate));

                            if (isPresent) {
                                var items = isPresent.records;
                                var obj = new MedicationTimelineMain();
                                obj.slotArray = new Array<slotArrayValue>();
                                obj.date = new Date(items[0].medicationDate);


                                var morningFound = items.find(x => x.type === 1);
                                obj.morningStatus = morningFound
                                    ? morningFound.status
                                        ? TimelineStatus.Taken
                                        : TimelineStatus.Missed
                                    : this.isSameDate(startDate, this.dates.current) &&
                                        this.selectedMedicine.activeStatus !== ActiveStatus.Stopped
                                        ? TimelineStatus.Pending
                                        : TimelineStatus.Missed;

                                var afternoonFound = items.find(x => x.type === 2);
                                obj.afternoonStatus = afternoonFound
                                    ? afternoonFound.status
                                        ? TimelineStatus.Taken
                                        : TimelineStatus.Missed
                                    : this.isSameDate(startDate, this.dates.current) &&
                                        this.selectedMedicine.activeStatus !== ActiveStatus.Stopped
                                        ? TimelineStatus.Pending
                                        : TimelineStatus.Missed;

                                var nightFound = items.find(x => x.type === 3);
                                obj.nightStatus = nightFound
                                    ? nightFound.status
                                        ? TimelineStatus.Taken
                                        : TimelineStatus.Missed
                                    : this.isSameDate(startDate, this.dates.current) &&
                                        this.selectedMedicine.activeStatus !== ActiveStatus.Stopped
                                        ? TimelineStatus.Pending
                                        : TimelineStatus.Missed;
                                var timeslotFound = items.find(x => x.type === 4);
                                if (timeslotFound) {
                                    this.selectedMedicine.medicationFrequency.forEach(token => {
                                        var jb = new slotArrayValue()
                                        var result = items.find(item => item.name == token.medicationInstructionName)

                                        if (result) {
                                            jb.timeslotStatus = timeslotFound
                                                ? timeslotFound.status
                                                    ? TimelineStatus.Taken
                                                    : TimelineStatus.Missed
                                                : this.isSameDate(startDate, this.dates.current) &&
                                                    this.selectedMedicine.activeStatus !== ActiveStatus.Stopped
                                                    ? TimelineStatus.Missed
                                                    : TimelineStatus.Pending;

                                        } else {

                                            jb.timeslotStatus = this.isSameDate(startDate, this.dates.current) && this.chekingTime(token)
                                                ? TimelineStatus.Pending
                                                : TimelineStatus.Missed;
                                        }
                                        obj.slotArray.push(jb)
                                    });
                                }
                                medicineTimeline.push(obj);
                            } else {
                                var newObj = new MedicationTimelineMain();
                                newObj.date = new Date(startDate);
                                newObj.slotArray = new Array<slotArrayValue>();

                                newObj.morningStatus = this.isSameDate(startDate, this.dates.current)
                                    ? TimelineStatus.Pending
                                    : TimelineStatus.Missed;

                                newObj.afternoonStatus = this.isSameDate(startDate, this.dates.current)
                                    ? TimelineStatus.Pending
                                    : TimelineStatus.Missed;


                                this.selectedMedicine.medicationFrequency.forEach(token => {
                                    var jb = new slotArrayValue()
                                    jb.timeslotStatus = this.isSameDate(startDate, this.dates.current) && this.chekingTime(token)
                                        ? TimelineStatus.Pending
                                        : TimelineStatus.Missed;

                                    newObj.slotArray.push(jb)
                                });

                                newObj.nightStatus = this.isSameDate(startDate, this.dates.current)
                                    ? TimelineStatus.Pending
                                    : TimelineStatus.Missed;
                                medicineTimeline.push(newObj);
                            }
                        }
                        this.medicationTimeline = medicineTimeline;
                    } else {
                        this.notifyService.warning(response.message);
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }

    isSameDate = (date: Date, today: Date) => {
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    }

    isGreaterDate = (date: Date, today: Date) => {
        return date.getFullYear() > today.getFullYear() ||
            (date.getMonth() > today.getMonth() &&
                date.getFullYear() === today.getFullYear()) ||
            (date.getDate() > today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear());
    }

    isLesserDate = (date: Date, today: Date) => {
        return date.getFullYear() < today.getFullYear() ||
            (date.getMonth() < today.getMonth() &&
                date.getFullYear() === today.getFullYear()) ||
            (date.getDate() < today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear());
    }

    //submit = () => {
    //    this.submitting = true;

    //    var data = {
    //        admissionId: this.admissionId,
    //        createdBy: this.page.userAccount.accountId,
    //        records: []
    //    };

    //    this.medicines.filter(x => !x.progressReportMedicationId).forEach(item => {
    //        data.records.push({
    //            pharmacyProductId: item.pharmacyProductId,
    //            unit: +item.unit,
    //            duration: +item.duration,
    //            medicationDurationTypeId: item.medicationDurationTypeId,
    //            medicationInstructions: [item.morningInstructionTypeId,
    //            item.afternoonInstructionTypeId,
    //            item.nightInstructionTypeId].filter(Boolean),
    //            instructions: item.instructions,
    //            startDate: new Date(item.startDate),
    //            endDate: new Date(item.endDate),
    //            takeDate: new Date(item.takeDate)
    //        });
    //    });

    //    if (!data.records.length) {
    //        this.submitting = false;
    //        return;
    //    }
    //    data["modifiedByName"] = this.page.userAccount.fullName;
    //    data["createdBy"] = this.page.userAccount.accountId;

    //    this.httpService
    //        .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReport.base, ApiResources.progressReport.insertMedication), data)
    //        .pipe(finalize(() => this.submitting = false))
    //        .subscribe(
    //            (response: GenericResponse) => {
    //                if (response.status === GenericStatus[GenericStatus.Success]) {
    //                    this.dates.current = new Date();
    //                    this.timelineCommunicationService.set(true);
    //                    this.fetch();
    //                } else {
    //                    this.notifyService.defaultErrorToast();
    //                }
    //            },
    //            () => {
    //                this.notifyService.defaultErrorToast();
    //            }
    //        );

    //}

    viewStopReason = (reason: string) => {
        this.notifyService.infoToast(reason);
    }

    onBack = () => {
        this.locationService.back();
    }

    // INDENT
    raiseIndent = () => {
        var recordForm = this.indentForm.get("records") as FormArray;
        recordForm.clear();

        this.medicines.forEach(x => {
            recordForm.push(
                this.formBuilder.group({
                    pharmacyProductId: [x.pharmacyProductId],
                    value: [0, [Validators.min(0)]]
                }));
        })
        this.currentDisplayMode = DisplayMode.Indent;
    }

    cancelIndent = () => {
        var recordForm = this.indentForm.get("records") as FormArray;
        recordForm.clear();
        this.currentDisplayMode = DisplayMode.Concept;
    }

    submitIndent = () => {
        if (this.indentSubmitting) return;

        var records = (this.indentForm.value.records as Array<any>).filter(x => x.value > 0);
        if (!records.length) {
            this.notifyService.infoToast("At least one medicine quantity should be greater than 0.")
            return;
        }

        this.indentSubmitting = true;
        var data = records.map(x => {
            return {
                admissionId: this.admissionId,
                pharmacyProductId: x.pharmacyProductId,
                quantity: x.value,
                createdBy: this.page.userAccount.accountId,
                roleId: this.page.userAccount.roleId,
                createdByName: this.page.userAccount.fullName
            }
        })

        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.pharmacyRequest.base, ApiResources.pharmacyRequest.addIndentBulk), data)
            .pipe(finalize(() => this.indentSubmitting = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.notifyService.infoToast("Indent has been raised successfully");
                        this.currentDisplayMode = DisplayMode.Concept;
                        this.fetch();
                    } else {
                        this.notifyService.defaultError();
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }
            );

    }


    getSettingsData() {
        this.loadingSettings = true;
        this.httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch))
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingSettings = false))
            .subscribe((response: Array<Setting>) => {
                if (response.length > 0) {
                    this.settings = response;
                    var pharmacyIndent = this.settings.find(s => s.name == "PharmacyIndentIP")
                    this.isPharmacyIndent = pharmacyIndent ? pharmacyIndent.active : false;
                }
            });
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
    onChangePrintType(isShow: boolean) {
        this.isPrintLogo = isShow;
    }
}
