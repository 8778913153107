export class InventoryStock {
    inventoryStockId: number;
    inventoryProductId: number;
    productName: string;
    batchNumber: string;
    categoryName: string;
    availableQty: number;
    purchaseRate: number;
    expiryDate?: Date;
    discountPercentage?: number;
    taxPercentage: string;
    taxId: number;
    categoryId: number;
    quantity: number;
    totalItems: number;

    inventoryProductDetailId: number;
    roq: number;
    rol: number;
    rackName: string;
}