export class FontTypes {
    fontTypeId: number;
    fontName: string;
    createdBy: number;
    createdByName: string;
    createdDate: Date;
    modifiedBy: number;
    modifiedByName: string;
    modifiedDate?: Date;
    active: boolean;
}