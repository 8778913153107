import { Component, OnInit, OnDestroy, ViewEncapsulation,  Input } from "@angular/core";
import { FormGroup   } from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";
import {  DatePipe } from '@angular/common';
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject, of, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap, tap, catchError, finalize, takeUntil } from "rxjs/operators";
import { IMedicationAsync, GenericResponse, GenericStatus, Page, IUserAccount } from "../../../../../shared/models";
import { HttpService, ResourceService, NotifyService, AppData, PrintOptionService} from "../../../../../shared/services";
import { ApiResources, LinqHelper, DateHelper } from "../../../../../shared/helpers";
import { IAdmissionModel } from "../../../services/models/admission.model";
import { ActiveStatus, Dates, GlobalProgressReportMedication, AdmissionFetchHelper } from "../../shared/helper";
import { TakeMode, RunningMode, TimelineStatus, DisplayMode, ProgressReportMedication, MedicationTimelineMain, MedicationTimelineGroup, IndentMedicine } from "./local.helper";
import { ImageReports } from "../../../../../shared/entities";
import { HourHolder } from "../../../nurse-module/helpers/helper";
import { MarkDetails } from "../timeline/local.helper";
import {  NewLab } from "../labs/helpers/helper";
import * as moment from "moment";

import {   PIXEL_PER_LETTER, BUFFER_WIDTH } from "../../shared/helper";
import { IVitals } from "../vitals/helpers/helper"

class medications {
    admissionDates: Array<any>;

    medicines: Array<ProgressReportMedication>;
    constructor() {
        this.admissionDates = new Array<any>();
        this.medicines = new Array<ProgressReportMedication>();
    }
}
@Component({
    templateUrl: "./patient-orders-sheet.html",
    styleUrls: ["./medication.css"],
    encapsulation: ViewEncapsulation.None
})
export class PatientOrdersActionSheetPage implements OnInit, OnDestroy {
    medications: medications;
    @Input() inputAdmissionId?: number;
    @Input() isPrintLogo: boolean;
    hours: Array<HourHolder>
    limit = [1, 2, 3, 4, 5, 6]
    markDetails: MarkDetails;
    admissionId: number;
    isAdmission: boolean;
    page: Page;
    takeMode = TakeMode;
    runningMode = RunningMode;
    timelineStatus = TimelineStatus;
    activeStatus = ActiveStatus;
    displayMode = DisplayMode;
    currentTakeMode: TakeMode;
    modalRef: NgbModalRef;
    loading: boolean;
    priviousValues = [];
    checked: any = 1;
    timeslotschecked = [];
    addMedicationForm: FormGroup;
    stopMedicationForm: FormGroup;
    takeMedicationForm: FormGroup;
    indentForm: FormGroup;

    widths: { createdBy: number };
    medicationsAsync: Observable<Array<IMedicationAsync>>;
    medicationsInput = new Subject<string>();
    loadingMedicationsAsync: boolean;
   
    instructionTypeNames: Array<number>;
    activeMedicines: Array<ProgressReportMedication>;
    medicationTimeline: Array<MedicationTimelineMain>;
    timelineGroup: Array<MedicationTimelineGroup>;
    selectedMedication: IMedicationAsync;
    medicationSubmitted: boolean;
    vitalsData: Array<IVitals>;
    loadingInstructions: boolean;
    subscriptions: Array<Subscription>;
    imgOrigin: string;
    submitting: boolean;
    takeMedicationSubmitted: boolean;
    stopping: boolean;
    loadingTimeline: boolean;
    indentSubmitting: boolean;
    taking: boolean;
    selectedMedicine: ProgressReportMedication;
    selectedUpdateMedicine: ProgressReportMedication;
    dates: Dates;
    protected datePipe: DatePipe = new DatePipe('en-US');
    admission: IAdmissionModel;
    indentMedicines: Array<IndentMedicine>;
    autoSelected: boolean;
    currentDisplayMode: DisplayMode;
    currentRunningMode: RunningMode;
    morningInstructionId: any;
    afternoonInstructionId: any;
    nightInstructionId: any;
    expiry = DateHelper.ngbToday;
    bannerBasics: ImageReports;
    myImgUrl: string = "assets/images/careaxesLogo.png";
    isDisabled: boolean;
    IsEdit = false;
    compactMode: boolean;
    labs: Array<NewLab>;
    currentDate: string;
    arr: Array<string>;
    constructor(
        private readonly httpService: HttpService,
        private readonly resourceService: ResourceService,
        private readonly notifyService: NotifyService,
        private readonly appData: AppData,
        private readonly route: ActivatedRoute,
        private readonly admissionFetchHelper: AdmissionFetchHelper,
        private readonly printOptionService: PrintOptionService
    ) {
        this.widths = {
            createdBy: 130
        }

        this.vitalsData = new Array<any>();
        this.arr = new Array<string>();
            this.medications = new medications();
        this.markDetails = new MarkDetails();
        this.currentDisplayMode = DisplayMode.Concept;
        this.autoSelected = false;
        this.indentMedicines = new Array<IndentMedicine>();
        this.dates = new Dates();
        this.dates.current = new Date();
        this.dates.min = new Date(new Date().setDate(new Date().getDate() - 1));
        this.dates.max = new Date(new Date().setDate(new Date().getDate() + 1));
        this.labs = new Array<any>();

        this.page = new Page();
        this.imgOrigin = location.origin + location.pathname;
        this.instructionTypeNames = new Array<number>();
       
        this.activeMedicines = new Array<ProgressReportMedication>();
        this.medicationTimeline = new Array<MedicationTimelineMain>();
        this.timelineGroup = new Array<MedicationTimelineGroup>();
        this.subscriptions = new Array<Subscription>();
        this.currentDate = this.datePipe.transform((new Date), 'MM/dd/yyyy h:mm:ss a');
        this.hours = Array.from(Array(24)).map((_x, i) => {
            let hour = 1 + i;
            var icon = i >= 4 && i <= 10
                ? 'weather-sunset-up'
                : i > 10 && i <= 15
                    ? 'white-balance-sunny'
                    : i > 15 && i <= 19
                        ? 'weather-sunset-down'
                        : 'weather-night';
            var name = i >= 4 && i <= 10
                ? 'Morning'
                : i > 10 && i <= 15
                    ? 'Afternoon'
                    : i > 15 && i <= 19
                        ? 'Evening'
                        : 'Night';
            var isAm = hour <= 12;
            var meridiemHour = isAm ? hour : hour - 12;
            return {
                hour: hour,
                meridiemHour: meridiemHour,
                meridiem: isAm ? "AM" : "PM",
                icon: icon,
                name: name
            } as HourHolder;
        });
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;

                    this.route.parent.paramMap
                        .subscribe((params: Params) => {
                            this.admissionId = this.inputAdmissionId || +params["params"]["id"];
                            this.isAdmission = this.inputAdmissionId ? true : params["params"]["type"] === "a";
                            this.compactMode = !!this.inputAdmissionId;
                            this.loading = true;
                            this.fetchMedicationsAsync();
                            this.fetch();
                            this.fetchLabs();
                           
                            this.fetchVitals();
                            //this.medications.admissionDates.push(loop.forma("YYYY-MM-DD"));
                                //while (loop <= end) {
                                //    // alert(loop);                                   
                                //    var newDate = loop.setDate(loop.getDate() + 1);
                                //    loop = new Date(newDate);                                    
                                //    this.medications.admissionDates.push(loop);
                                //}
                                
                          //  });
                        });
                    //this.fetchInstructions(() =>
                    //    this.fetchInstructions
                    //);
                } else {
                    this.page.userAccount = undefined;
                }
                this.printOptionService.get((is) => { this.isPrintLogo = is; });
            });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((item: Subscription) => {
            item.unsubscribe();
        });
    }


    private fetchMedicationsAsync() {
        this.medicationsAsync = this.medicationsInput.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((term: string) =>
                term && term.length >= 2
                    ? this.resourceService.medicationAsync(term).pipe(
                        tap(() => this.loadingMedicationsAsync = true),
                        catchError(() => { return of([]) }),
                        finalize(() => this.loadingMedicationsAsync = false)
                    )
                    : of([])
            )
        );
    }

    


    fetch = (showLoading: boolean = true) => {
        if (showLoading) {
            this.loading = true;
        }

        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReport.base, ApiResources.progressReport.fetchMedication),
                { admissionId: this.admissionId, dateModel: { year: this.dates.current.getFullYear(), month: this.dates.current.getMonth() + 1, day: this.dates.current.getDate() } })
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        const data = response.data as GlobalProgressReportMedication<ProgressReportMedication, IndentMedicine>;
                        const records = data.records as Array<ProgressReportMedication>;


                        const instructionNames = new Array<number>();
                        records.forEach(record => {

                            LinqHelper.sort(record.medicationFrequency, "medicationInstructionDeepTypeId");

                            this.instructionTypeNames = instructionNames.filter(LinqHelper.uniqueOnly);
                            this.activeMedicines = records.filter(x => x.activeStatus === ActiveStatus.Active);
                            this.medications.medicines = records.sort((a, b) => LinqHelper.compare(a, b, "activeStatus"));
                            this.admissionFetchHelper.admissionFetch(this.admissionId, this.isAdmission, (data: IAdmissionModel) => {
                                this.admission = data;
                                /*var start = new Date(this.admission.admissionDate);*/
                                //var end = new Date(this.currentDate);
                                //var loop = new Date(start);
                                //var currentDate = moment();
                                //  moment(currentDate).format("YYYY-MM-DD")
                                var start = moment(this.admission.admissionDate).format("YYYY-MM-DDT00:00:00");
                                var endDate = moment(this.currentDate).format("YYYY-MM-DDT00:00:00");
                               // moment(date).format("YYYY-MM-DDTHH:mm:ss");
                                while (start <= endDate) {
                                    this.medications.admissionDates.push(start);
                                    start = moment(start).add(1, 'days').format("YYYY-MM-DDT00:00:00");
                                }
                                this.medications.admissionDates.forEach(x => {
                                    this.medications.medicines.forEach(y => {
                                        
                                        var x1 = y.startDate;
                                    })
                                  
                                })
                            });
                        },
                            () => {
                                this.notifyService.defaultError();
                            }
                        );
                    }
                }

            );
    }

    fetchVitals = () => {
        this.loading = true;
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReportVitals.base, ApiResources.progressReportVitals.fetch),
                { admissionId: this.admissionId })
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        var data = response.data as Array<IVitals>;
                        data.forEach(item => {
                            item.vitals = JSON.parse(item.vitals.toString());
                        })

                        var createdByWidth = Math.max(...data.map(x => x.createdByName.length)) * PIXEL_PER_LETTER;
                        this.widths.createdBy = Math.max(createdByWidth, this.widths.createdBy) + BUFFER_WIDTH - 10;
                        this.vitalsData = data;
                    } else {
                        this.notifyService.warning(response.message);
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }


    fetchLabs = () => {
        this.loading = true;
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReportLab.base, ApiResources.progressReportLab.fetch),
                { admissionId: this.admissionId })
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        var data = response.data as Array<NewLab>;                        
                        this.labs = data;
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }

}
