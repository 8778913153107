import { OnInit, OnDestroy, Component, ViewEncapsulation, Input } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import * as Highcharts from 'highcharts';
import { GraphView } from "../../entities";
import { AppData } from "../../services";
import { Page } from "../../models/page.model";
import { IUserAccount } from "../../models/user-account.model";


@Component({
    templateUrl: "./graph-view.html",
    selector: "graph-view",
    encapsulation: ViewEncapsulation.None
})
export class GraphViewPage implements OnInit, OnDestroy {

    @Input() graphData: GraphView;
    page: Page;
    isAdmission: boolean;
    routingValue: string;
    encryptedAppointmentId: string;
    encryptedLabMainDetailId: string;
    graphHeading: string; 

    constructor(
        private readonly appData: AppData,
        private readonly router: Router,
    ) {
        this.page = new Page();
        this.graphHeading = "";
        this.graphData = new GraphView();
    }


    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.isAdmission = url.split("/")[5] === "a";
                    const appointmentId = url.split("/")[url.split("/").length - 4];
                    this.routingValue = url.split("/")[url.split("/").length - 5];
                    this.encryptedLabMainDetailId = decodeURIComponent(url.split("/")[url.split("/").length - 1]);
                    this.encryptedAppointmentId = decodeURIComponent(appointmentId);
                    this.generateGraph();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }
    ngOnChanges() {
        this.generateGraph();
    }
    generateGraph() {
 
        const options: Highcharts.Options = {
            chart: {
                type: 'line',
                style: {
                    fontFamily: 'Roboto, sans-serif'
                },
                animation: {
                    duration: 1500,
                    easing: 'easeInOutQuint'
                }
            },
            title: {
                text: this.graphData.titleName,
                style: {
                    fontSize: '36px',
                    fontWeight: 'bold',
                    color: '#1976D2'
                }
            },
            xAxis: {
                categories: this.graphData.xValues,
                title: {
                    text: this.graphData.xparamName, 
                    style: {
                        fontSize: '24px',
                        color: '#1976D2'
                    }
                },
                labels: {
                    rotation: -45,
                    style: {
                        fontSize: '14px',
                        color: '#455A64'
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'Values',
                    style: {
                        fontSize: '24px',
                        color: '#1976D2'
                    }
                },
                gridLineDashStyle: 'LongDash',
                labels: {
                    style: {
                        color: '#455A64'
                    }
                }
            },
            tooltip: {
                shared: true,
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                borderColor: '#1976D2',
                borderRadius: 20,
                style: {
                    color: '#212121',
                    fontSize: '18px',
                    fontFamily: 'Roboto, sans-serif'
                }
            },
            legend: {
                enabled: true,
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                borderWidth: 2,
                itemStyle: {
                    color: '#1976D2',
                    fontSize: '24px'
                }
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true,
                        format: '{y}',
                        style: {
                            fontSize: '24px',
                            fontWeight: 'bold',
                            color: '#1976D2'
                        }
                    },
                    enableMouseTracking: true,
                    marker: {
                        enabled: true,
                        symbol: 'circle',
                        radius: 8,
                        fillColor: '#64B5F6',
                        lineWidth: 4,
                        lineColor: '#1976D2'
                    }
                }
            },
            series: [{
                name: this.graphData.yparamName,
                data: this.graphData.yValues,
                color: '#1976D2',
                dashStyle: 'ShortDot',
                marker: {
                    fillColor: '#FFFFFF',
                    lineWidth: 3,
                    lineColor: '#1976D2'
                }
            }] as any,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 600
                    },
                    chartOptions: {
                        legend: {
                            layout: 'vertical',
                            align: 'right',
                            verticalAlign: 'middle',
                            itemStyle: {
                                fontSize: '18px'
                            }
                        },
                        xAxis: {
                            labels: {
                                rotation: 0,
                                style: {
                                    fontSize: '14px'
                                }
                            }
                        }
                    }
                }]
            }
        };
        Highcharts.chart('container', options);
    }
}