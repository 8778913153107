export class RadiologyValue {
    radiologyValueId: number;
    radiologyId: number;
    name?: string;
    orderCode?: string;
    code?: string;
    test?: string;
    createdDate: Date;
    createdBy: number;
    modifiedBy?: number;
    modifiedDate?: Date;
    isAssignedRadiologyValue?: boolean;
    totalItems?: number;
} 