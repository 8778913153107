import { enableProdMode } from "@angular/core";
import { Environment } from "./model";

enableProdMode();

export const environment: Environment = {
    production: true,
    showDevModule: false,  
    firebase: {
        apiKey: "AIzaSyDALPeuMVkIYvDuXs_DWQaIDtWeWvap53s",
        authDomain: "careaxes-24ee0.firebaseapp.com",
        databaseURL: "https://careaxes-24ee0-default-rtdb.asia-southeast1.firebasedatabase.app/",
        projectId: "careaxes-24ee0",
        storageBucket: "careaxes-24ee0.appspot.com",
        messagingSenderId: "418029376310",
        appId: "1:418029376310:web:b1227ab0ea0bb463df9997",
        measurementId: "G-9R8MRG3854"
    }
};