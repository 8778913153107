import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";


@Pipe({
    name: "age"
})
export class AgePipe implements PipeTransform {

    //transform(value: Date): string {
    //    let today = moment();
    //    let birthdate = moment(value);
    //    let years = today.diff(birthdate, 'years');       
    //    let html: string = years + " yr "; 

    //    html += today.subtract(years, 'years').diff(birthdate, 'months') + " mo";

    //    return html; // only yr ,m
    //}

    transform(value: Date | string, precision: "years" | "months" | "days" = "years") {
        if (!value) {
            return "";
        }
        const today = moment();
       const birthdate = moment(value);

        let age = '';
        
        switch (precision) {
            case "days":
                
                age += today.diff(birthdate, 'years') + 'Y '; //years

                const y = today.diff(birthdate, 'years'); //yr
                age += today.subtract(y, 'years').diff(birthdate, 'months') + 'M '; // months
                            
                var m = today.diff(birthdate, 'months'); //m
                birthdate.add(m, 'months');            
                age += today.diff(birthdate, 'days') + 'D'; //days
                break;
            case "months":
               
                age += today.diff(birthdate, 'years') + 'Y '; //years

               const yr = today.diff(birthdate, 'years'); //yr
                age += today.subtract(yr, 'years').diff(birthdate, 'months') + 'M'; // months

                break;
            default:               
                age += today.diff(birthdate, 'years') + 'Y '; //years
                break;
        }
        return age;

    }
   

}