import { ViewEncapsulation, Component, OnInit, OnDestroy, EventEmitter, Input, Output,} from "@angular/core";
import { IUserAccount, Page } from "@shared/models";
import { AppData, HttpService, NotifyService } from "@shared/services";
import { LabStatusModel } from "../../../areas/admin/labs/pages/models";
import { takeUntil, finalize } from "rxjs/operators";
import { ApiResources} from "@shared/helpers";
import { test } from "node:test";
class Test {
    id: number;
    value: string;
}
@Component({
    templateUrl: "./new-lab-timeline.html",
    selector: "new-lab-timeline",
    encapsulation: ViewEncapsulation.None
})



export class NewLabTimelineWidget implements OnInit, OnDestroy {
    page: Page;
    @Input() newLabBookingHeaderId: number;
    @Input() newLabBookingDetailId: number;
    @Output() onClose = new EventEmitter<any>();
    @Input() isCloseHeader: boolean;
    loadingTimeline: boolean;
    timeline: Array<LabStatusModel>;
    dummyNewLabBookingDetailId: number;
    testMaster: Array<Test>;
    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
    ) {
        this.page = new Page();
        this.timeline = new Array<LabStatusModel>();
        this.testMaster = new Array<Test>();
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchTimeline();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
    onCloseModel() {
        this.onClose.emit();
    }
    private fetchTimeline() {
        const request = {
            newLabBookingHeaderId: this.newLabBookingHeaderId,
            newLabBookingDetailId: this.dummyNewLabBookingDetailId
        };
        this.loadingTimeline = true;
        this.httpService.get(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchLabTimeline), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingTimeline = false))
            .subscribe((response: Array<LabStatusModel>) => {
                this.timeline = response;
                this.testMaster = [];
                if (this.timeline?.length > 0) {
                    this.timeline[0].labsList?.forEach((lab) => {
                        var test = new Test();
                        test.id = lab.newLabBookingDetailId;
                        test.value = lab.testName;
                        this.testMaster?.push(test);
                    })
                }
            }, () => {
                this.notifyService.warningToast("Unable to fetch lab timeline.");
            });
    }


}