export class InventoryReturnBill {
    inventoryPurchaseReturnHeaderId: number;
    inventoryPurchaseReturnDetailId: number;
    productName: string;
    billNumber: string;
    returnDate: Date;
    purchaseRate: number;
    total: number;
    quantity: number;
    discountPercentage?: number;
    discountPerItem?: number;
    taxAmount: number;
    netAmount: number;
    overallReturnAmount: number;
    overallDiscountRs?: number;
    overallTaxesRs: number;
    overallNetAmount: number;
    inventoryStockId: number;
    inventoryProductId: number;
    taxId: number;
}