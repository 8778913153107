<div id="two-col-menu" class="text-mute" data-simplebar>
    <div class="twocolumn-menu-item d-block">
        <ng-container class="cursor">
            <div>
                <div class="d-flex align-items-center justify-content-center flex-column w-100 text-center">
                    <div class="avatar-lg overflow-hidden">
                        <img *ngIf="patient.patient.thumbnailUrl" [src]="safe(patient.patient.thumbnailUrl)" class="rounded-circle avatar-lg" [alt]="patient.patient.fullName">
                        <span *ngIf="!patient.patient.thumbnailUrl" class="avatar-title rounded-circle bg-primary font-24 text-white" [textContent]="patient.patient.fullName | initials"></span>
                    </div>
                </div>

                <div class="encounter-profile-list">
                    <h5 class="mb-0 text-center">
                        <a href="javascript:;" class="text-dark">
                            <span (click)="onViewPatientProfile(commonid)" class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="patient.patient.salutation +' '"></span> 
                            <span (click)="onViewPatientProfile(commonid)" class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="patient.patient.fullName"></span>
                            <span class="d-block font-13 my-1"
                                  [textContent]=" (patient.patient.dateOfBirth != null ? (patient.patient.dateOfBirth | age:'days') : patient.patient.age == null? 'null' : patient.patient.age + ' yrs')+'/'+( patient.patient.gender !=null ? patient.patient.gender:null )"></span>
                            <small class="d-block font-13 my-1 align-center"
                                   [textContent]="patient.patient.umrNo || '--'"></small>
                            <small class="d-block font-13 my-1 align-center">
                                <i class="mdi mdi-phone text-primary"></i><sup class="text-primary mr-1">1</sup>
                                <span [textContent]="patient.patient.mobile ? ('(+'+ patient.patient.countryCode +') '+ patient.patient.mobile) : 'No mobile recorded'"></span>
                            </small>
                            <small class="d-block font-13 my-1 align-center">
                                <i class="mdi mdi-phone text-primary"></i><sup class="text-primary mr-1">2</sup>
                                <span [textContent]="relativeMobile ? ('(+'+ patient.patient.countryCode +') '+relativeMobile) : 'No mobile recorded'"></span>
                            </small>
                            <small class="d-block font-13 my-1 align-center">
                                <i class="mdi mdi-at text-primary mr-1"></i>
                                <span [textContent]="patient.patient.email || 'No email recorded'"></span>
                            </small>
                            <small *ngIf="!isFromCallCentre && admissions && admissions.length > 0 && !admissions[0].isDischarged" class="d-block font-13 my-1 align-center">
                                <i class="mdi mdi-account text-primary mr-1"></i>
                                <span [textContent]="admissions[0].admissionNo"></span>
                            </small>
                            <small *ngIf="!isFromCallCentre && admissions && admissions.length > 0 && !admissions[0].isDischarged" class="d-block font-13 my-1 align-center">
                                <i class="mdi mdi-bed text-primary mr-1"></i>
                                <span [textContent]="admissions[0].bedNumber"></span>
                            </small>
                            <small *ngIf="!isFromCallCentre && admissions && admissions.length == 0" class="d-block font-13 my-1 align-center">
                                <i class="mdi mdi-account text-primary mr-1"></i> OP
                            </small>
                        </a>
                    </h5>
                </div>
                <div class="row" *ngIf="isSettingHealthCard && routingValue==='bookApp'">
                    <div class="col-12">
                        <div class="card-header">Health card</div>
                        <div class="table-responsive ng-star-inserted">
                            <table class="table table-bordered table-sm">
                                <tr>
                                    <th class="small">S.No</th>
                                    <th class="small">CardNo</th>
                                </tr>
                                <tr class="pointer"  *ngFor="let item of validCardDetails; let i = index" (click)="onSelectCard(item)" 
                                             [ngClass]="(item.isSelected == true) ?'bg-soft-blue':  'bg-soft-warning'"
                                            >
                                    <td class="small">{{i+1}}</td>

                                    <td>
                                        <div class="media-body" >
                                            <h5 class="mb-0 mt-0 font-weight-normal text-uppercase"
                                                [textContent]="item.cardNumber"></h5>
                                            <span class="d-block font-13">
                                                <span [textContent]="item.validTill | date:'dd/MM/yyyy'"></span>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
