import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class AppointmentToggleService {
    private source = new Subject<boolean>();
    subscriber: Observable<boolean> = this.source.asObservable();
    toggle(is: boolean) {
        this.source.next(is);
    }
}
