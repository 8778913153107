<div class="modal-header">
    <h5 class="modal-title">Bill Delay Comments </h5>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <h4>
                Please enter the reason why you are delaying?
            </h4>
        </div>
        <div class="col-12">
            <div class="text-danger">
                <input class="form-control" type="text" [(ngModel)]="comments" (ngModelChange)="inputComments($event)" placeholder="Enter Comments" />
            </div>
        </div>
        <div class="col-12">
            <div class="d-flex">
                <div class="table-responsive mt-2" *ngIf="billComments && billComments.length>0">
                    <table class="table table-sm table-striped table-bordered mb-0">
                        <thead>
                            <tr>
                                <th width="50">S.No</th>
                                <th>Commented By</th>
                                <th>Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of billComments; index as i;">
                                <td>#{{i+1}}</td>
                                <td [textContent]="item.createdByName">---</td>
                                <td [textContent]="item.comments"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="onSubmit()">
        <i class="mdi mdi-check-bold mr-1"></i>
        <span *ngIf="!submitting">Submit</span>
        <span *ngIf="submitting" class="spinner-grow mr-1"> Submitting</span>
    </button>
</div>