export class ChargeModuleCategoryCostModel {
    chargeCategoryId: number;
    chargeModuleTemplateId: number;
    packageModuleId: number;
    locationId: number;
    chargeModules: Array<ChargeModuleCostModel>;
    total: number;

    constructor() {
        this.chargeModules = new Array();
    }
}

export class ChargeModuleCostModel {
    modulesMasterId: number;
    chargeModuleCategoryId: number;
    chargeReferenceIds: string;
    amount: number;
    isLoading: boolean;
}