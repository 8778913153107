import { Provider } from "@shared/entities";
import { ProviderLocation } from "@shared/models";

class ProviderExperience {
    id: number;
    name: string;
    fromYear: string;
    toYear: string;
    location: string;
}

class Education {
    id: number;
    name: string;
    fromYear: number;
    toYear: number;
    location: string;
}

class Award {
    id: number;
    name: string;
    year: number;
}

class Registration {
    id: number;
    name: string;
    year: number;
}

class Membership {
    id: number;
    name: string;
}

export class BankDetails {
    providerBankAccountId: number;
    fullName: string;
    accountNumber: string;
    ifscCode: string;
    percentage: number;
    mobile: string;
    email: string;
}

export class ProviderProfile {
    provider: Provider;
    bankDetails: BankDetails;
    locations: Array<ProviderLocation>;
    experiences: Array<ProviderExperience>;
    educations: Array<Education>;
    awardsHonors: Array<Award>;
    registrations: Array<Registration>;
    memberships: Array<Membership>;
    constructor() {
        this.provider = new Provider();
        this.experiences = [];
        this.educations = [];
        this.awardsHonors = [];
        this.registrations = [];
        this.memberships = [];
    }
}