import { DiscountType } from "./discount-type.model";

export class FinalBillBasicModel {
    totalAmountPaid: number;
    totalAmount: number;
    discountTypeId: DiscountType;
    discountDetails: number;
    finalAmount: number;
    pendingAmount: number;
    isFinalBillGenerated: boolean;
    isDischarged: boolean;
    dischargeDate: Date;
    totalAmountPaidInWords: string;
    totalDueAmountInWords: string;
    totalRefundPendingAmountInWords: string;
    totalAmountInWords: string;
    netAmountInWords: string;
    finalBillId: number;
    finalBillInWords: string;

    isServiceTaken: boolean;
    isFinalGatePass: boolean;
    isProvisionalGatePass: boolean;
    postDiscount: number;
    insuranceCovered: number;
    copayAmount: number;
    copayType: string;
    copayPercentage: number;
}