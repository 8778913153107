import { RoleBasedDiscount } from "./role-based-discounts.entity";

export class DiscountModule {
    discountModuleId: number;
    createdBy: number;
    moduleName: string;
    icon: string;
    roles: string;
    createdByName: string;
    modifiedByName: string;
    modifiedByRole: string;
    roleDiscounts: Array<RoleBasedDiscount>;
    allowedRoles: Array<any>;
    createdDate:Date;
    modifiedDate: Date;
    active: boolean;

    constructor() {
        this.roleDiscounts = new Array<RoleBasedDiscount>();
        this.allowedRoles = new Array<any>();
    }
}