export class Refund {
    appointmentTransactionId: number;
    appointmentNo: string;
    total: number;
    transactionId: string;
    patientName: string;
    providerName: string;
    totalItems: number;
    refundAmount: number;
    refundTransactionId: string;
    refundCreatedDate: Date;
    refundCompletedDate: Date;
    refundStatus: string;
    paymentId:number;

}