import { Directive, HostListener } from "@angular/core";
import { KeyCodesHelper, RegExHelper } from "@shared/helpers";

@Directive({
    selector: "[alphaNumeric]"
})
export class AlphaNumericOnlyDirective {
    @HostListener("keydown", ["$event"])
    onKeyDown(e: KeyboardEvent) {
        const keys = KeyCodesHelper.default.concat(KeyCodesHelper.alphabets).concat(KeyCodesHelper.numbers);
        if (keys.indexOf(e.keyCode) === -1) {
            event.preventDefault();
        }

        if (KeyCodesHelper.specialCharacters.indexOf(e.key) > -1) {
            event.preventDefault();
            return;
        }

        if (KeyCodesHelper.defaultNames.indexOf(e.key) === -1) {
            return;
        }

        if (RegExHelper.alphaNumeric.test(e.key))
            return;
        else
            event.preventDefault();
    }
}