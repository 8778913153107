export enum MenuType {
    MainMenu = 1,
    SubMenu = 2,
    NestedMenu = 3,
    HiddenMenu = 4,
    InPageMenu = 5,
    CategoryMenu = 6
}

export enum AllowType
{
    Allowed = 1,
    NotAllowed = 2,
    BreakIt = 3,
    Redirect = 4
}