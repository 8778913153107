export class HomeopathyFullTranscript {
    appointmentId: number;
    patientId: number;
    homeopathyId?: number;
    patientName: string;
    encryptedPatientId: string;
    patientAge?: number;
    patientDateOfBirth?: Date;
    patientGender: string;
    patientMobile: string;
    patientThumbnailUrl: string;
    patientCountryCode: string;
    appointmentDate: Date;
    appointmentTime: object;
    appointmentTimeString: string;
    additionalComplaints: string;
    appetiteAndThirst: string;
    bowelMovements: string;
    chestHeartColdCough: string;
    ccAndOC: string;
    children: string;
    medicalHistory:string;
    discharges: string;
    dreams: string;
    familyIllness: string;
    personalHistory:string;
    personalHabits: string;
    sexualSphere: string;
    sleepAndDreams: string;
    spouseAndChildren: string;
    sweatFeverChill: string;
    generalComplaints: string;
    pastIllness: string;
    urination: string;
    affectedFactors: string;
    symptoms: string;
    emotionalNature: string;
}