<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <!--<button *ngIf="!loading && medicines.length" [useExistingCss]="true" printSectionId="progressReportReport" ngxPrint class="btn btn-primary btn-sm mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>-->
                        <!--<button *ngIf="!loading && records.length" type="button" class="btn btn-primary btn-sm mr-1">
                          <span><i class="fe-eye mr-1"></i> View Report</span>
                        </button>-->
                       
                        <!--<a href="javascript:;" class="btn btn-secondary btn-sm ml-1" (click)="onBack()">
                            <span><i class="fe-arrow-left mr-1"></i> Back</span>
                        </a>-->
                    </div>
                    <h4 class="page-title">Labs</h4>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 mb-4">
                <div class="overflow-auto">
                    <table class="table table-centered table-sm table-striped table-bordered mb-0">
                        <thead class="table-primary">
                            <tr>
                                <th style="width:50px;">#</th>
                                <th style="width:35%;">Name</th>
                                <th> Instructions</th>
                                <th>Lab Date</th>
                                <th>Created By</th>
                                <!--<th>Unit</th>-->
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="loading">
                                <tr>
                                    <td colspan="8">
                                        <span class="mr-1 spinner-grow spinner-grow-sm"></span> Please wait while loading progress report ...
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!loading && !records.length">
                                <tr>
                                    <td colspan="8">
                                        <i class="mdi mdi-alert text-warning mr-1"></i> Labs does not exists.
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!loading && records.length">
                                <ng-container *ngFor="let item of records; let i = index;">
                                    <tr>
                                        <td [textContent]="(i + 1)"></td>
                                        <td>
                                            <div>
                                                <div class="media">
                                                    <div>
                                                        <img alt="lab service" [src]="imgOrigin + 'assets/images/days/lab.png'" width="28" />
                                                    </div>
                                                    <div class="media-body ml-1">
                                                        <h5 class="mb-0 mt-0 font-weight-normal">
                                                            <span [textContent]="item.testName || '---'"></span>
                                                        </h5>
                                                        <span class="d-block font-12 text-capitalize">
                                                            <small [textContent]="item.testCode"></small>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="item.status == 'ParameterAdded' || item.status == 'TechnicianVerified' || item.status == 'Verified' " >
                                                <h5 class="redcolor" *ngIf="item.isAbnormal" ngbTooltip="Abnormal Values Recorded" placement="right"><i class="mdi mdi-information-box-outline border-danger"></i></h5>

                                            </div>
                                        </td>
                                        <td [textContent]="item.notes"></td>
                                        <!--<td [textContent]="(item.date || item.createdDate) | date:'dd-MM-yyyy'"></td>-->
                                        <td [textContent]="(item.date || item.createdDate) | utcToLocal:false:'DD/MM/YYYY'">
                                        <td>
                                            <div>
                                                <div class="media">
                                                    <div class="media-body">
                                                        <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.createdByName"></h5>
                                                        <span class="d-block font-12 mt-1">
                                                            <small [textContent]="item.createdDate | date:'dd-MM-yyyy, hh:mm a'"></small>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td [textContent]="item.status"></td>
                                        <!--<td>1</td>-->
                                        <!--<td>

                                            <ng-container [ngSwitch]="item.status">
                                                <span *ngSwitchCase="labStatusType.Verified" class="badge badge-success">Verified</span>
                                                <span *ngSwitchCase="labStatusType.NotVerified" class="badge badge-warning">Not Verified</span>
                                                <span *ngSwitchCase="labStatusType.Collected" class="badge badge-soft-success">Sample Collected</span>
                                                <span *ngSwitchCase="labStatusType.NotCollected" class="badge badge-danger">Sample Not Collected</span>
                                                <span *ngSwitchDefault>---</span>
                                            </ng-container>
                                        </td>-->
                                        <td class="noprint-column">
                                            <div class="d-flex align-items-center justify-content-end">
                                                <a *ngIf="item.labMainDetailId" href="javascript:;" (click)="onOpenViewModel(templateViewLabInstructions, item)" class="action-icon text-primary font-20" placement="left" ngbTooltip="View">
                                                    <i class="mdi mdi-eye"></i>
                                                </a>


                                                <!--<a *ngIf="item.status == labStatusType.NotCollected" (click)="onChangeStatusForSingleLab(item)" href="javascript:;" class="action-icon text-danger mr-1" placement="left" ngbTooltip="Collect Sample">
        <i class="mdi mdi-flask-plus font-20"></i>
    </a>
    <a *ngIf="item.status == labStatusType.Collected" (click)="onParameterEdit(templateLabParameters, item)" href="javascript:;" class="action-icon text-primary mr-1" placement="left" ngbTooltip="Add Parameters">
        <i class="mdi mdi-flask-empty-plus-outline font-20"></i>
    </a>

    <a *ngIf="(item.status == labStatusType.NotVerified) || item.isReportGenerated || item.isParameterAdded" (click)="onParameterEdit(templateLabParameters, item)" href="javascript:;" class="action-icon text-secondary mr-1" placement="left" ngbTooltip="Edit Parameters">
        <i class="mdi mdi-flask-empty-plus-outline font-20"></i>
    </a>

    <a *ngIf="item.status == labStatusType.NotVerified" (click)="onVerifyingReport(item)" href="javascript:;" class="action-icon text-success mr-1" placement="left" ngbTooltip="Verify Report">
        <i class="mdi mdi-check-all font-20"></i>
    </a>-->
                                                <a *ngIf="item.status == 'Verified'" (click)="onViewLabReport(templateLabReport, item)" href="javascript:;" class="action-icon text-secondary" placement="left" ngbTooltip="View Lab Report">
                                                    <i class="mdi mdi-file-chart-outline font-20"></i>
                                                </a>
                                                <a *ngIf="item.status == 'ParameterAdded'  || item.status == 'TechnicianVerified' " (click)="onViewLabReport(templateLabparameterReport, item)" href="javascript:;" class="action-icon text-secondary" placement="left" ngbTooltip="View Lab Report">
                                                    <i class="mdi mdi-file-chart-outline font-20"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--<div class="row">
            <div class="col-sm-12">
                <div class="modal-footer" *ngIf="isAnyChange">
                    <button type="button" (click)="submit()" [disabled]="submitting" class="btn btn-primary btn-sm">
                        <span *ngIf="submitting">
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            Please wait..
                        </span>
                        <span *ngIf="!submitting"><i class="fe-check mr-1"></i>Submit</span>
                    </button>
                </div>
            </div>
        </div>-->

    </div>
</div>

<ng-template #templateViewLabInstructions>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-offer mr-1"></i>View Lab Details</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12">
                <div class="overflow-auto">
                    <table class="table table-sm table-bordered table-centered table-striped">
                        <thead>
                            <tr class="table-primary">
                                <th colspan="4">
                                    <i class="mdi mdi-information mr-1"></i>Lab Instructions
                                </th>
                            </tr>
                            <tr>
                                <th class="table-active">Lab Name</th>
                                <td>
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedLab.testName"></span>
                                </td>
                                <th class="table-active">Lab Code</th>
                                <td>
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedLab.testCode"></span>
                                </td>
                            </tr>
                            <tr>
                                <th class="table-active">Created By</th>
                                <td>
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedLab.createdByName"></span>
                                </td>
                                <th class="table-active">Created Date</th>
                                <td>
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedLab.createdDate | date:'dd-MM-yyyy, hh:mm a'"></span>
                                </td>
                            </tr>

                            <tr *ngIf="selectedLab.notes">
                                <th class="table-active">Instructions</th>
                                <td colspan="3">
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedLab.notes"></span>
                                </td>
                            </tr>

                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
    </div>
</ng-template>

<ng-template #templateAdd>
    <form [formGroup]="addLabForm" (submit)="onAddLab()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-flask-plus-outline mr-1"></i>Add Lab</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm-12 mb-2">
                    <div class="row">
                        <div class="col-sm-12 d-flex align-items-center justify-content-center">
                            <div class="form-group mb-0 mr-2">
                                <label class="mb-1">Date <span class="text-danger">*</span></label>
                                <input class="form-control date-picker" type="text" block [minDate]="minDate"
                                       formControlName="date" autocomplete="nope" placeholder="Select date"
                                       [ngClass]="{ 'is-invalid': labSubmitted && labForm.date.errors }"
                                       ngbDatepicker #date="ngbDatepicker"
                                       (click)="date.toggle(); $event.stopPropagation();"
                                       [value]="labForm.date.value | date: 'dd/MM/yyyy'" />
                            </div>
                            <div class="form-group mb-0 mr-2">
                                <label class="mb-1">Search Labs <code>*</code></label>
                                <ng-select class="ng-select-sm w-500p" [items]="labsAsync | async"
                                           [loading]="loadingLabsAsync"
                                           bindLabel="testName"
                                           bindValue="labMainDetailId"
                                           [typeahead]="labsInput"
                                           [minTermLength]="2"
                                           typeToSearchText="Search lab"
                                           [virtualScroll]="true"
                                           (change)="onLabSelect($event)"
                                           placeholder="Enter 2 or more characters"
                                           formControlName="id">
                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                        No labs found<span *ngIf="searchTerm"> for '<span [textContent]="searchTerm"></span>'</span>.
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-i="index">
                                        <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.testName"></h5>
                                        <span class="text-muted d-block font-13">
                                            <span class="mr-1"><span>Code: </span><span [textContent]="item.testCode"></span></span>
                                            <!--<span class="mr-1">|</span>
                                            <span><span>Cost: </span><span [textContent]="(item.cost || 0) | currency:'INR'"></span></span>-->
                                        </span>
                                    </ng-template>
                                </ng-select>
                                <div *ngIf="labSubmitted && labForm.id.errors" class="invalid-feedback show-must">
                                    <div *ngIf="labForm.id.errors.required">Lab field is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 mb-2" *ngIf="selectedLab">
                    <div class="overflow-auto">
                        <table class="table table-centered table-sm table-striped table-bordered mb-0">
                            <thead>
                                <tr class="table-primary">
                                    <th>Name</th>
                                    <th>Code</th>
                                    <!--<th>Cost</th>-->
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <span class="mr-1">
                                                <i class="mdi mdi-flask-outline mdi-36px mdi-rotate-45"></i>
                                            </span>
                                            <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="selectedLab.testName"></h5>
                                        </div>
                                    </td>
                                    <td [textContent]="selectedLab.testCode"></td>
                                    <!--<td [textContent]="(selectedLab.cost || 0) | currency:'INR'"></td>-->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!--<div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group position-relative mb-0">
                                <label>Date <span class="text-danger">*</span></label>
                                <input class="form-control date-picker" type="text" block
                                       formControlName="date" autocomplete="nope" placeholder="Select date"
                                       [ngClass]="{ 'is-invalid': labSubmitted && labForm.date.errors }"
                                       ngbDatepicker #date="ngbDatepicker"
                                       (click)="date.toggle(); $event.stopPropagation();" />
                            </div>
                        </div>
                    </div>
                </div>-->
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Instructions</label>
                        <textarea placeholder="Medicine Instructions (optional)" rows="3" formControlName="instructions" class="form-control"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
            <button type="submit" class="btn btn-primary btn-sm">
                <span><i class="fe-plus mr-1" placement="right"></i>Add</span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #templateLabParameters>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-medical-bag mr-1"></i> {{selectedLabBookingDetail.testName}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">X</button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="row" *ngIf="loadingDetail">
                    <div class="col-12">
                        <div class="d-flex align-items-center">
                            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                            <span class="ml-2">Please wait while loading labs parameters....</span>
                        </div>
                    </div>
                </div>
                <div class="overflow-auto" *ngIf="!loadingDetail && inputParams.length > 0">
                    <table class="table table-sm table-centered table-borderless">
                        <tr>
                            <th>Test Parameter</th>
                            <th>Observed Value</th>
                            <th>Reference Range</th>
                        </tr>
                        <tr *ngFor="let input of inputParams">
                            <td>
                                <div class="alert alert-primary">
                                    <span [textContent]="input.testParameter"></span>
                                </div>
                            </td>
                            <td>
                                <div class="input-group input-group-merge">
                                    <input type="text" class="form-control" [(ngModel)]="input.testParamResult" placeholder="Enter observed value.">
                                    <div class="input-group-text" data-password="false">
                                        <span [textContent]="input.name"></span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="alert alert-secondary">
                                    <span [textContent]="input.referenceRange"></span>&nbsp;
                                    <span [textContent]="input.name"></span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-danger mr-1" (click)="onCloseModal();">Close</button>
        <button type="submit" [disabled]="submitting" class="btn btn-sm btn-primary" (click)="onSubmitLabInput()">
            <span *ngIf="submitting">
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                Please wait..
            </span>
            <span *ngIf="!submitting">Submit</span>
        </button>
    </div>
</ng-template>

<ng-template #templateViewLabReport>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-cash mr-1"></i>Lab Report</h4>
        <div class="d-flex">
            <div>
                <button class="btn btn-sm" [class.btn-light]="!isPrintLogo" [class.btn-outline-light]="isPrintLogo" type="button" (click)="onChangePrintType(false)"><i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo</button>
                <button class="btn btn-sm" [class.btn-light]="isPrintLogo" [class.btn-outline-light]="!isPrintLogo" type="button" (click)="onChangePrintType(true)"><i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo</button>
            </div>
            <div>
                <button *ngIf="!isExternal" [useExistingCss]="true" printSectionId="invoiceId" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>
                <button *ngIf="isExternal" [useExistingCss]="true" (click)="onPrintPdf()" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>
                <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
            </div>
        </div>
    </div>
    <div Id="invoiceId">
        <new-lab-report [isPrintLogo]="isPrintLogo" [encryptedNewLabBookingDetailId]="selectedLabBookingDetail.encryptedNewLabBookingDetailId" (isExternal)="OnCheckIsExternal($event)" (uplodpfd)="OnPdf($event)" (onClose)="onCloseExtraPopup()"></new-lab-report>
        <div *ngIf="isExternal">
            <div *ngIf="isPrintLogo">
                <banner-setting [reportName]='"Lab Report Header"'></banner-setting>
            </div>
            <div class=" pl-1 pr-1" *ngIf="reportData">
                <pdf-viewer [src]="reportData['changingThisBreaksApplicationSecurity']"
                            [render-text]="true"
                            [autoresize]="true"
                            [original-size]="false"
                            [fit-to-page]="false"
                            (on-progress)="onProgress($event)"
                            class="pdfstyle">
                </pdf-viewer>
            </div>
        </div>
</div>
</ng-template>
<ng-template #templateLabReport>
    <div>
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-cash mr-1"></i>Lab Report</h4>
            <div class="d-flex">
                <div>
                    <button class="btn btn-sm" [class.btn-light]="!isPrintLogo" [class.btn-outline-light]="isPrintLogo" type="button" (click)="onChangePrintType(false)"><i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo</button>
                    <button class="btn btn-sm" [class.btn-light]="isPrintLogo" [class.btn-outline-light]="!isPrintLogo" type="button" (click)="onChangePrintType(true)"><i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo</button>
                </div>
                <div>
                    <button *ngIf="!isExternal" [useExistingCss]="true" printSectionId="invoiceId" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>
                    <button *ngIf="isExternal" [useExistingCss]="true" (click)="onPrintPdf()" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>
                    <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
                </div>
            </div>
        </div>
        <div Id="invoiceId">
            <new-lab-report [isPrintLogo]="isPrintLogo" [encryptedNewLabBookingDetailId]="selectedLabBookingDetail.encryptedNewLabBookingDetailId" (isExternal)="OnCheckIsExternal($event)" (uplodpfd)="OnPdf($event)" (onClose)="onCloseExtraPopup()"></new-lab-report>
            <div *ngIf="isExternal">
                <div *ngIf="isPrintLogo">
                    <banner-setting [reportName]='"Lab Report Header"'></banner-setting>
                </div>
                <div class=" pl-1 pr-1" *ngIf="reportData">
                    <pdf-viewer [src]="reportData['changingThisBreaksApplicationSecurity']"
                                [render-text]="true"
                                [autoresize]="true"
                                [original-size]="false"
                                [fit-to-page]="false"
                                (on-progress)="onProgress($event)"
                                class="pdfstyle">
                    </pdf-viewer>
                </div>
            </div>
        </div>
     </div>
</ng-template>
<ng-template #templateLabparameterReport>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-cash mr-1"></i>Lab Report</h4>
        <div>
            <button [useExistingCss]="true" printSectionId="invoiceId" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>
            <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal()">×</button>
        </div>
    </div>
    <div class="modal-body">
        <a href="javascript:;" (click)="onPrevious()" class="previous-document" placement="left" ngbTooltip="Previous document" *ngIf="showPrevious"><i class="mdi mdi-arrow-left-bold"></i></a>
        <a href="javascript:;" (click)="onNext()" class="next-document" placement="left" ngbTooltip="Next document" *ngIf="showNext"><i class="mdi mdi-arrow-right-bold"></i></a>
        <interim-report [encryptedNewLabBookingDetailId]="selectedLabBookingDetail.encryptedNewLabBookingDetailId" (onClose)="onCloseExtraPopup()"></interim-report>
    </div>
</ng-template>
