import { Component, ViewEncapsulation, OnInit, TemplateRef, ViewChild, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { IUserAccount, Page } from "@shared/models";
import { HttpService, AppData, PrintOptionService } from "@shared/services";
import { ApiResources } from "@shared/helpers";
import { finalize, takeUntil } from "rxjs";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { LabTransferModel } from "../../../areas/admin/labs/pages/models/lab-transfer-model";

class TransferedRecords {
    departmentName: string;
    departmentId: number;
    transferedSeparateRecords: Array<LabTransferModel>;
    constructor() {
        this.transferedSeparateRecords = new Array<LabTransferModel>();
    }
}
@Component({
    templateUrl: "./transfered-labs.html",
    selector: "transfered-labs",
    styleUrls: ["./transfered-labs.css"],
    encapsulation: ViewEncapsulation.None
})
export class TransferedLabsWidget implements OnInit, OnDestroy {
    @ViewChild("templateTransferedLabs", { static: true }) templateTransferedLabs: TemplateRef<any>;
    @Input() transferId: number;
    @Output() emitOnCloseModel = new EventEmitter();
    page: Page;
    modalRef: NgbModalRef;
    isPrintLogo: boolean = true;
    transferedSeparateRecords: Array<LabTransferModel>;
    loadingTransfer: boolean;
    isFooter: boolean;
    records: Array<TransferedRecords>;
    constructor(
        private readonly appData: AppData,
        private readonly printOptionService: PrintOptionService,
        private readonly httpService: HttpService,
        private readonly modalService: NgbModal,
    ) {
        this.page = new Page();
        this.records = new Array<TransferedRecords>();
       }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.onViewTransferrredParticularLabs(this.transferId, this.templateTransferedLabs)
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }
    onCloseModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            // ignored;
        }
        this.printOptionService.get((is) => { this.isPrintLogo = is; });
        this.isPrintLogo = false;
        this.emitOnCloseModel.emit();
    }
    onViewTransferrredParticularLabs(transferId: number, content: TemplateRef<any>) {
        this.transferedSeparateRecords = new Array<LabTransferModel>();
        this.loadingTransfer = true;
        this.httpService.get(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchTransferedLabs), { labTransferHeaderId: transferId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingTransfer = false))
            .subscribe((response: Array<LabTransferModel>) => {
                if (response.length > 0) {
                    this.transferedSeparateRecords = response;
                    this.transferedSeparateRecords.forEach((item) => {
                        var index = this.records.findIndex(x => x.departmentId == item.labDepartmentId);
                        if (index > -1) {
                            this.records[index].transferedSeparateRecords.push(item);
                        } else {
                            var transferedRecord = new TransferedRecords();
                            transferedRecord.departmentName = item.departmentName;
                            transferedRecord.departmentId = item.labDepartmentId;
                            transferedRecord.transferedSeparateRecords.push(item);
                            this.records.push(transferedRecord);
                        }
                    })
                }
            });
        this.onOpenModel(content, "xl");
    }
    onOpenModel(content: TemplateRef<any>, size: string) {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: size,
            windowClass: "custom-modal effect-scale"
        });
    }
    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
}