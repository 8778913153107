export class Ambulance {
    ambulanceId: number;
    ambulanceNo: string;
    assignedNo: string;
    locationId: number;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    createdByName: string;
    createdByRole: string;
    modifiedByName: string;
    modifiedByRole: string;
}