export class ChargeCategory {
    chargeCategoryId: number;
    chargeCategoryName: string;
    locationId: number;
    createdBy: number;
    createdDate: Date;
    active: boolean;
    modifiedBy?: number;
    modifiedDate?: Date;
    totalItems: number;
    modifiedByName?: string;
    createdByName: string;
    locationIds: string;
    amount?: number;
    isAdded: boolean;
    id: number;
    value: string;
    default: boolean;
    charges: Array<any>;

    newAmount?: number;
    isNew?: boolean = null;
    isIncluded: boolean = true;
    chargeModuleDetailsId?: number;
    chargeModuleCategoryId?: number;
    modifiedByRole: string;
    createdByRole: string;
}