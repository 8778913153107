export class UserRawImport {
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    mobile: string;
    gender: string;
    dateOfBirth: string;


}
