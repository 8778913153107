<div
  class="parent"
  [ngStyle]="{'max-width.px': width, 'max-height.px': height}"
>
  <div class="clear" tabindex="0" (click)="clearPad()">
    <i class="mdi mdi-close"></i>
  </div>
  <canvas #canvas [width]="width" [height]="height"></canvas>
  <div
    class="done"
    (click)="donePad()"
    [ngClass]="{'done-primary': !isSaved, 'done-success': isSaved}"
  >
    <i class="mr-1 mdi mdi-check"></i
    ><span [textContent]="isSaved ? 'Saved' : 'done'"></span>
  </div>
</div>
