export class LocalStorageHelper {
  static GetValue (key: string) {
    return localStorage.getItem(key);
  }

  static SetValue(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  static DeleteAll() {
    localStorage.clear()
    }

    static Delete(key: string) {
        localStorage.removeItem(key);
    }
}