export class GrossReports {
    admissionNo: string;
    providerName: string;
    patientName: string;
    insuranceCompanyName: string;
    refundAmount: number;
    balanceAmount: number;

    admissionDate: Date;
    appointmentDate: Date;
    appointmentTime: string;
    admissionTime: string;
    appointmentNo: string;
    specializationName: string;
    patientRegistration: number;
    opConsultation: number;
    pharmacyAmt: number;
    labAmt: number;
    scanAmt: number;
    umrNo: string;
    departmentName: string;
    areaType: string;
    netTotal: number;

}