export class BehavioralHealthEncounter {
    appointmentId: number;
    behavioralHealthId?: number;
    problemHistory: string;
    medicalHistory: string;
    currentSupport: string;
    currentLiving: string;
    educationalHistory: string;
    clientStrengths: string;
    outComeMeasures: string;
    substanceHistory: string;
    workHistory: string;
    abuseHistory: string;
    religionSpirituality: string;
    mentalStatus: string;
    familyRelationships: string;
    relapsePredictors: string;
    patientQuestionnaire: string;
    generalisedAnxietyDisorder: string;
    burnsDepression: string;
    aims: string;
    genogram: string;
    notes: string;
    vitals: string;
    referralProvider: string;
    followUp: string;
    medications: string;
    treatmentPlan: string;
    encryptedPatientId: string;
    medicationComment: string;
    generalAdvice: string;
    symptoms: string;
}