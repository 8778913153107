export class InventoryRawProduct {
    productName: string;
    company: string;
    category: string;
    rack: string;
    purchaseQuantity: string;
    purchaseUnit: string;
    saleQuantity: string;
    saleUnit: string;
    gst: string;
    minQuantity: string;
    maxQuantity: string;
    reorderQuantity: string;
    batchNumberRequired: string;
    productExpire: string;
    billNumber: string;
}