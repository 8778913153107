export enum CommunicationType {
    Call = 1,
    Notify = 2,
    Reject = 3,
    CloseAll = 4,
    Join = 5,
    Close = 6,
    AppointmentRefresh = 7,
    AppointmentAddRefresh = 8,
    MessageRefresh = 9,
    Cancel = 10,
    Initiate = 11
}
