import { Injectable } from "@angular/core";
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { MenuService } from "../services";
import { AllowType } from "../enums";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private readonly menuService: MenuService,
        private readonly router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean> | Promise<boolean> | boolean {
        return this.activateHelper(route, state);

    }

    activateHelper = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const allowType = this.menuService.isAllowed(state.url, true);
        if (typeof allowType === 'string') {
            this.router.navigateByUrl(allowType);
            return false;
        } else {
            switch (allowType as AllowType) {
                case AllowType.Allowed:
                    return true;
                case AllowType.NotAllowed:
                    return false;
                case AllowType.BreakIt:
                    this.router.navigateByUrl("login");
                    return false;
                default:
                    return false;
            }
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean> | Promise<boolean> | boolean {
        return this.activateHelper(route, state);
    }
}



////import { Injectable } from "@angular/core";
////import { CanActivate, CanActivateChild, Router } from "@angular/router";
////import { Observable, of } from "rxjs";
////import { catchError, map } from "rxjs/operators";
////import { IdentityService, appUrls } from "@shared/services";

////@Injectable()
////export class AuthGuard implements CanActivate, CanActivateChild {
////    constructor(
////        private readonly identityService: IdentityService,
////        private readonly router: Router
////    ) {
////    }

////    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
////        const userAccountReq = this.identityService.status();
////        return userAccountReq
////            .pipe(
////                map((roleId: number) => {
////                    if (roleId) {
////                        return true;
////                    }

////                    this.router.navigateByUrl(appUrls.login);
////                    return false;
////                }),
////                catchError(() => {
////                    return of(false);
////                })
////            );
////    }

////    canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
////        return this.canActivate();
////    }
////}