import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { supportFilter } from "../../shared/models/support-filter"
import { ITicket } from "../models";

@Injectable({
    providedIn: 'root'
})
export class SupportService {
    private supportFilterSubject = new BehaviorSubject<supportFilter | null>(null);
    getFilters$ = this.supportFilterSubject.asObservable();
    setFilters(item: supportFilter): void {
        this.supportFilterSubject.next(item);
    }


    private trackInfoSubject = new BehaviorSubject<any | null>(null)
    gettrackInfo$ = this.trackInfoSubject.asObservable()

    setHistory(item: any): void {
        this.trackInfoSubject.next(item)
    }

    private escalateSubject = new BehaviorSubject<ITicket | null>(null);
    getTicket$ = this.escalateSubject.asObservable()
    setTicketData(item: ITicket) {
        this.escalateSubject.next(item);
    }

    private TicketNumberSubject = new BehaviorSubject<string | null>(null);
    getTicketNo$ = this.TicketNumberSubject.asObservable();

    setTicketNumber(item: string): void {
        this.TicketNumberSubject.next(item);
    }

}