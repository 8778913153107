export class NurseShiftMap {
    nurseShiftMapId: number;
    date: Date;
    shiftId: number;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
    nurseAccountId: number;
    nurseShiftStatusTypeId: number;
}