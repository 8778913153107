import { Component, Input, AfterViewChecked, ChangeDetectorRef } from "@angular/core";
import * as moment from "moment";

@Component({
    selector: "available-days",
    template: `&nbsp;<span [innerHtml]="value"></span>`
})
export class AvailableDaysComponent implements AfterViewChecked {
    @Input()
    value: string;

    constructor(private readonly cdRef: ChangeDetectorRef) { }

    ngAfterViewChecked() {
        if (this.value) {
            const dayName = moment().format("d");
            let value = this.value;

            value = value.replace(/,/g, ", ");
            value = value.replace(dayName, `<b class='text-primary'>${dayName}</b>`);
            value = value.replace("1", "Mo");
            value = value.replace("2", "Tu");
            value = value.replace("3", "We");
            value = value.replace("4", "Th");
            value = value.replace("5", "Fr");
            value = value.replace("6", "Sa");
            value = value.replace("7", "Su");

            this.value = value;
            this.cdRef.detectChanges();
        }
    }
}