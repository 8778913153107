export class DischargeConfiguration {
    readyToDischargeAlertOrderId: number;
    roleDepartmentId: number;
    priority: number;
    isBase: boolean;
    isGetNotifiedOnRecords: boolean;
    isSkip: boolean;
    active: boolean;
    createdDate: Date;
    createdBy: number;
    modifiedBy: number;
    modifiedDate: Date;
    roleDepartmentName: string;
    createdByName: string;
    modifiedByName: string;

}