
export class ObEncounterFullTranscript {
    appointmentId: number;
    appointmentNo: string;
    patientId: number;
    obEncounterId: number;
    encryptedPatientId: string;
    encounterId?: number;
    patientName: string;
    patientAge?: number;
    patientDateOfBirth?: Date;
    patientGender: string;
    patientMobile: string;
    patientThumbnailUrl: string;
    patientCountryCode: string;
    appointmentDate: Date;
    appointmentTime: object;
    providerName: string;
    appointmentTimeString: string;
    problemList: string;
    briefRos: string;
    geneticScreening: string;
    infectionHistory: string;
    followUp: string;
    medicationcomment: string;
    notes: string;
    vitals: string;
    socialHistory: string;
    breastExamination: string;
    vaginalExamination: string;
    ancCaredClosure: string;
    ancCardGeneration: string;
   
    medicationComment: string;
    planOfManagement: string;
    allergies: string;
    umrNo: string;
    patientNo: string;
    medications: string;
    signature: string;
    clinicPicture: string;
    practiceLocation: string;
    practiceName: string;
    specializationName: string;
    providerNo: string;
    educations: string;
    complaints: string;
    obstetricExamination: string;
    vteRisk: string;
    sgaScanning: string;
    surgery: string;
    orderPrescription: string;
    drugHistory: string;
    menstrualHistory: string;
    procedureForm: string;
    antinantalRisk: string;
    otherDetail: string;
    obBirthHistory: string;
    measure: string;
    generalExamination: string;
    breech: string;
    ancCard: string;
    covid: string;
    postnantalVisit: string;
    vaccine: string;
    anaesthesiaRecord: string;
    prepregnancyCounseling: string;
    partner: string;
    email: string;
    friendlyName: string;
    isAppointmentClosed: boolean;
    familyHistory: string;
    outPatientManagement: string;
    specialFeature: string;
    prePregnancyPlan: string;
    referralForm: string;
    refferalOrder: string;
    admissionSlip: string;
    syndromes: string;
    opManagement: string;
    motherWithEpilepsy: string;
    externalCephalicVersion: string;
    dischargeSummary: string;
    nurseAssessment: string;
    scanAppointmentDetails: string;
    outSideTests: string;
    birthHistory: string;
    surgeries: string;
    reminder: string;
    admTime: string;
    roomName: string;
    admissionDetails: string;
    addendumForm: string;
    nursingERForm: string;
    emergencyTriage: string;
    admissionDate: Date;
    normalDeliveryForm: string;
    lscs: string;
    iufd: string;
    doctorsReview: string
    providerMobileNo: string;
    telePhoneTriageAssessment: string;
    bedNumber: string;
    admissionNo: string;
    admissionId: number;
    cimsAllergy: string;
    aldreteScore: string;
    anaesthesiaRecordPostOP: string;
    scanTests: any;
}