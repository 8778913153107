import { Component, ViewEncapsulation, Output, EventEmitter, OnInit } from "@angular/core";
import { WebcamImage, WebcamInitError, WebcamUtil } from "ngx-webcam";
import { Subject, Observable } from "rxjs";

@Component({
    templateUrl: "./img-capture.html",
    styleUrls: ["./img-capture.css"],
    selector: "img-capture",
    encapsulation: ViewEncapsulation.None
})
export class ImgCaptureWidget implements OnInit {
    @Output() captureImageEmitter = new EventEmitter<string>();

    showWebcam: boolean;
    webcamImage: WebcamImage;
    webcamError: WebcamInitError;
    videoOptions: MediaTrackConstraints;
    trigger: Subject<void>;

    constructor() {
        this.init();
    }

    private init() {
        this.showWebcam = undefined;
        this.webcamImage = undefined;
        this.webcamError = undefined;

        this.videoOptions = {
            width: { ideal: 640 },
            height: { ideal: 480 }
        };

        this.trigger = new Subject<void>();
    }

    onCapture(webcamImage: WebcamImage) {
        this.webcamImage = webcamImage;
        this.captureImageEmitter.emit(`data:image/jpeg;base64,${this.webcamImage.imageAsBase64}`);
    }

    get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    triggerSnapshot() {
        this.trigger.next();
    }

    handleInitError(error: WebcamInitError): void {
        this.showWebcam = false;
        this.webcamError = error;
    }

    ngOnInit() {
        WebcamUtil.getAvailableVideoInputs()
            .then((mediaDevices: MediaDeviceInfo[]) => {
                this.showWebcam = mediaDevices && mediaDevices.length > 0;
            });
    }
}