import { LabComponentDetail } from "./lab-component-detail.model";

export class LabComponentHeader {       
    labComponentHeaderId: number;
    componentId: string;
    totalItems: number;
    createdBy: number;
    modifiedBy?: number;
    componentName: string;        
    createdByName: string;
    modifiedByName: string;
    createdDate: Date;
    modifiedDate?: Date;
    active: boolean;
    isLoading: boolean;
    parameterCount: number;
    joined: string;
    parameters: Array<LabComponentDetail>;
    createdByRole: number;
    modifiedByRole?: number;
    constructor() {
        this.parameters = new Array<LabComponentDetail>();
    }
}