import { AllowedAccount } from "./warehouse-store.entity";

export class ModuleDepartment {
    pharmacyDepartmentId: number;
    inventoryDepartmentId: number;
    name: string;
    createdByName: string;
    createdByRole: string;
    modifiedByName: string;
    modifiedByRole: string;
    createdBy: number;
    modifiedBy?: number
    createdDate: Date;
    modifiedDate?: Date;
    users: Array<AllowedAccount>;
    active: boolean;
    locationId: number;
    isUploaded: boolean;
    locationName: string;
    status: boolean;
    constructor() {
        this.users = new Array<AllowedAccount>();
    }
}
