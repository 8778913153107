import { Component, Input } from "@angular/core";

@Component({
    selector: "no-data",
    templateUrl: "./no-data.component.html",
})
export class NoDataComponent {
    @Input()
    title: string;

    @Input()
    applied: boolean;

    @Input()
    free: boolean;
}