export class SupplierNewProductModel {
    supplierProductHeaderId: number;
    supplierId: number;
    startDate: Date;
    endDate: Date;
    createdBy: number;
    createdByName: string;
    createdDate: Date;
    modifiedBy: number | null;
    modifiedByName: string;
    modifiedDate: Date | null;
    products: SupplierProductDetailModel[];
    isStarted: boolean;
    daysToExpire: number;
    rateCardUrl: string;
    base64Doc: any;
    formedUrl: any;
    active: boolean;

    constructor() {
        this.products = new Array<SupplierProductDetailModel>();
    }
}

export class SupplierProductDetailModel {
    supplierProductDetailId: number;
    supplierProductHeaderId: number;
    pharmacyProductId: number;
    purchaseRate: number;
    mrp: number;
    productName: string;
    genericName: string;
    inventoryItem: boolean;
    formulations: SupplierProductFormulationModel[];
    purchaseUnitName: string;
    constructor() {
        this.formulations = new Array<SupplierProductFormulationModel>();
    }
}

export class SupplierProductFormulationModel {
    supplierProductFormulationId: number;
    supplierProductDetailId: number;
    operator: string;
    condition: string;
    focQuantity: number;
}