export enum DentalEncounterType {
    ChiefComplaints=1,
    MedicalHistory,
    DentalHistory,
    FamilyHistory,
    PersonalHabits,
    OralHygiene,
    ClinicalFindings,
    Diagnosis,
    Medications,
    MedicationComments,
    GeneralAdvice
}