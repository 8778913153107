import { Injectable } from "@angular/core";
import { ApiResources, UtilHelper } from "../helpers";
import { HttpService } from ".";
import { Setting } from "../entities";

@Injectable()
export class BannerService {

    constructor(private readonly httpService: HttpService) { }

    //get(callback?: Function) {
    //    const request = {
    //        imageType: "Banner"
    //    };
    //    this.httpService
    //        .get(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.applyLogo), request)
    //        .subscribe(
    //            (response: ImageReports) => {
    //                callback(response);
    //            },
    //            () => {
    //                callback();
    //            }
    //        );
    //}
    getBannerImage(callback?: Function) {
       
        this.httpService           
            .get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), { type: "Banner", active: true }, true)
            .subscribe(
                (response: Array<Setting>) => {
                    if (response && response.length > 0) {
                        if (UtilHelper.isEmpty(response[0].imageUrl)) {
                            response[0].imageUrl = `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${response[0].imageUrl}`;
                            callback(response[0].imageUrl);
                        }
                    }
                },
                () => {
                    callback();
                }
            );
    }
}
