<div class="error-container bg-pattern bg-error-light">
    <div class="error-box">
        <img src="assets/images/logo.png" alt="Careaxes" height="25">
        <div class="error-text-box">
            <svg viewBox="0 0 600 200">
                <symbol id="s-text">
                    <text text-anchor="middle" x="50%" y="50%" dy=".35em">500!</text>
                </symbol>
                <use class="text" xlink:href="#s-text"></use>
                <use class="text" xlink:href="#s-text"></use>
                <use class="text" xlink:href="#s-text"></use>
                <use class="text" xlink:href="#s-text"></use>
                <use class="text" xlink:href="#s-text"></use>
            </svg>
        </div>
        <div class="text-center">
            <h3 class="mt-0 mb-2">Oh no! Internal server error </h3>
            <p class="font-15 mb-3">
                We're sorry, there was an error please try again later. The server encountered an internal server error and was unable to complete your request.
            </p>
            <a href="javascript:;" routerLink="/app/dashboard" class="btn btn-danger waves-effect waves-light">Back to Dashboard</a>
        </div>
    </div>
</div>