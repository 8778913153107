import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Setting } from "../entities";
import { ApiResources } from "../helpers";

@Injectable()
export class EmergencyContact {
    constructor(private readonly httpService: HttpService) {
    }

    getContact(callback?: Function) {
        const request = {
            active: true,
            type: 'EmergencyContact'
        };
        this.httpService
            .get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), request, true)
            .subscribe(
                (response: Array<Setting>) => {
                    if (response && response.length > 0) {
                        callback(response);
                    }
                },
                () => {
                    callback();
                }
        );
    }
}
