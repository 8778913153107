import { Component, OnInit, OnDestroy, ViewEncapsulation, TemplateRef } from "@angular/core";
import { Location } from '@angular/common';
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { finalize, takeUntil } from "rxjs/operators";
import { GenericResponse, GenericStatus, Page, IUserAccount } from "../../../../../shared/models";
import { HttpService, AppData, NotifyService } from "../../../../../shared/services";
import { ApiResources, DateHelper } from "../../../../../shared/helpers";
import { ActivatedRoute, Params } from "@angular/router";
import { IAdmissionModel } from "../../../services/models/admission.model";
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from "@angular/forms";
import { AdmissionFetchHelper, TimelineCommunicationService, PIXEL_PER_LETTER, BUFFER_WIDTH } from "../../shared/helper";
import { IAssessments } from "./helpers/helper";
import { LookUpModel } from "../../../services/models/look-up.model";

@Component({
    templateUrl: "./assessments.html",
    styleUrls: ["./assessments.css"],
    encapsulation: ViewEncapsulation.None
})
export class ProgressReportAssessmentsPage implements OnInit, OnDestroy {

    admissionId: number;
    isAdmission: boolean;
    page: Page;
    modalRef: NgbModalRef;
    loading: boolean;

    dateControl: FormControl;
    addForm: FormGroup;

    assessments: Array<LookUpModel>;

    records: Array<IAssessments>;
    admission: IAdmissionModel;
    imgOrigin: string;

    selected: IAssessments;
    submitted: boolean;
    submitting: boolean;
    widths: { createdBy: number };
    minDate = DateHelper.ngbToday;

    constructor(
        private readonly httpService: HttpService,
        private readonly modalService: NgbModal,
        private readonly notifyService: NotifyService,
        private readonly appData: AppData,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly locationService: Location,
        private readonly admissionFetchHelper: AdmissionFetchHelper,
        private readonly timelineCommunicationService: TimelineCommunicationService,
    ) {
        this.page = new Page();

        this.widths = {
            createdBy: 130
        }
        this.imgOrigin = location.origin + location.pathname;
        this.records = new Array<any>();
    }


    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;

                    this.route.parent.paramMap
                        .subscribe((params: Params) => {
                            this.admissionId = +params["params"]["id"];
                            this.isAdmission = params["params"]["type"] === "a";
                            this.loading = true;
                            this.admissionFetchHelper.admissionFetch(this.admissionId, this.isAdmission, (data: IAdmissionModel) => {
                                this.admission = data;
                            });
                            this.fetch();
                            this.fetchAssessmentTypes();
                        });
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {

    }

    get getAssessmentForm() { return this.addForm.get("assessments") as FormArray };

    private buildAddForm() {
        this.dateControl = new FormControl(DateHelper.todayInFormat, [Validators.required]);
        this.addForm = this.formBuilder.group({
            admissionId: +this.admissionId,
            date: this.dateControl,
            assessments: this.formBuilder.array([]),
            createdBy: this.page.userAccount.accountId
        })
    }

    private buildAssessmentForm(item: any) {
        return this.formBuilder.group({
            id: item.id,
            name: item.name,
            value: item.value,
            progressReportAssessmentsId: item.progressReportAssessmentsId,
            progressReportId: item.progressReportId,
            modifiedBy: this.page.userAccount.accountId
        });
    }

    // FORM
    get form() {
        return this.addForm.controls;
    }



    // MODEL
    openModifyModel = (content: TemplateRef<any>, record?: IAssessments) => {
        this.buildAddForm();
        this.dateControl.clearValidators();

        if (record) {
            this.selected = record;
            this.dateControl.patchValue(this.selected.date);
            this.getAssessmentForm.push(this.buildAssessmentForm({
                id: record.assessmentTypeId,
                name: record.name,
                value: record.value,
                progressReportAssessmentsId: record.progressReportAssessmentsId,
                progressReportId: record.progressReportId,
                modifiedBy: this.page.userAccount.accountId

            }));
        } else {
            this.assessments.forEach(x => {
                this.getAssessmentForm.push(this.buildAssessmentForm(x));
            });
        }
        this.dateControl.updateValueAndValidity();
        this.openModelHelper(content);
    }

    openModelHelper = (content: TemplateRef<any>) => {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: "lg",
            windowClass: "custom-modal effect-scale"
        });
    }

    onCloseModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
            this.selected = null;
        } catch (e) {
            // ignored;
        }
    }

    fetch = () => {
        this.loading = true;
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReportAssessments.base, ApiResources.progressReportAssessments.fetch),
                { admissionId: this.admissionId })
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        var data = response.data;
                        var createdByWidth = Math.max(...data.map(x => x.createdByName.length)) * PIXEL_PER_LETTER;
                        this.widths.createdBy = Math.max(createdByWidth, this.widths.createdBy) + BUFFER_WIDTH - 10;
                        this.records = data;
                    } else {
                        this.notifyService.warning(response.message);
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }

    fetchAssessmentTypes() {
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReportAssessments.base, ApiResources.progressReportAssessments.fetchAssessmentType), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: GenericResponse) => {
                    var records = response.data as Array<LookUpModel>;
                    this.assessments = records;
                },
                () => {
                    this.assessments = new Array<LookUpModel>();
                }
            );
    }

    modify = () => {

        this.submitted = true;
        if (this.addForm.invalid) return;

        var url = this.selected
            ? ApiResources.progressReportAssessments.update
            : ApiResources.progressReportAssessments.insert;

        var data = this.addForm.getRawValue();
        this.submitting = true;
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReportAssessments.base, url), data)
            .pipe(finalize(() => this.submitting = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.fetch();
                        this.timelineCommunicationService.set(true);
                        this.onCloseModal();
                    } else {
                        this.notifyService.warning(response.message);
                    }
                },
                () => {
                    this.notifyService.defaultError();
                }
            );
    }

    delete = (item: IAssessments) => {
        item.deleting = true;
        this.notifyService.confirm('Are you sure you want to delete this assessment?', () => {
            this.httpService
                .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReportAssessments.base, ApiResources.progressReportAssessments.delete), { ProgressReportAssessmentsId: item.progressReportAssessmentsId })
                .pipe(finalize(() => item.deleting = false))
                .subscribe(
                    (response: GenericResponse) => {
                        if (response.status === GenericStatus[GenericStatus.Success]) {
                            this.fetch();
                            this.timelineCommunicationService.set(true);
                        } else {
                            this.notifyService.warning(response.message);
                        }
                    },
                    () => {
                        this.notifyService.defaultError();
                    }
                );
        }, () => {
            item.deleting = false;
        });
    }

    onBack = () => {
        this.locationService.back();
    }

}
