import { Component, OnInit, Input } from '@angular/core';
import { ColorTemplate } from '../../../areas/admin/labs/pages/models/lab-color-template-model';
import { LinqHelper } from '../../helpers';

@Component({
    templateUrl: "./color-identification.html",
    selector: "color-identification",
})
export class ColorIdentification implements OnInit {
    arrayOfColor: Array<ColorTemplate>;    
    @Input() detailOfColor: Array<ColorTemplate>;

    constructor() {
        this.arrayOfColor = new Array<ColorTemplate>();//meaning of this
    }

    closePopover(popOver: any) {
        popOver.classList.toggle('d-none');
    }
    
    dragElement(elmnt: HTMLElement) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        if (document.getElementById("popover-header")) {
        /* if present, the header is where you move the DIV from:*/
            document.getElementById("popover-header").onmousedown = dragMouseDown;
    } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        elmnt.onmousedown = dragMouseDown;
    }

        function dragMouseDown(e: MouseEvent) {
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
    }

        function elementDrag(e: MouseEvent) {
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        }

    function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
    }
}
    
    private buildColorPalates() {        
        this.arrayOfColor = LinqHelper.cloneDeepArray(this.detailOfColor);
    }

    ngOnInit() {
        this.buildColorPalates();
        this.dragElement(document.getElementById("popoverOfcolor"));
    }


}