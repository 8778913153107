export class PharmacyRetailDetail {
    pharmacyStockId: number;
    pharmacyRetailStockId: number;
    pharmacyProductId: number;
    pharmacyIndentHeaderId: number;
    pharmacyIndentDetailId: number;
    pharmacyIssueHeaderId: number;
    pharmacyIssueDetailId: number;
    retailPharmacyId: number;

    //quantityIn: number;
    //quantityOut: number;
    //availableQuantity: number;
    batchNumber: string;
    expiryDate?: Date;
    total: number;
    netAmount: number;

    patientName: string;
    admissionId: number;
    indentDate: Date;
    indentQty: number;
    issueDate: Date;
    issueQty: number;
    requiredDate: Date;
    retailName: string;
    receiptNo: string;
    receiptId: number;
}