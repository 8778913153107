import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
    name: "utcToLocal"
})
export class UTCToLocalPipe implements PipeTransform {
    transform(date: string | Date, isTimestamp?: boolean, format?: string) {
        if (!date) {
            return "";
        }

        isTimestamp = isTimestamp === null || isTimestamp === undefined ? true : isTimestamp;

        const dateFormat = isTimestamp ? "MM/DD/YYYY hh:mm a" : "MM/DD/YYYY";
        const utcTime = date;
        const offset = moment().utcOffset();
        return moment.utc(utcTime).utcOffset(offset).format(format ? format : dateFormat);
    }
}