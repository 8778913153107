import { ActiveStatus } from "../../shared/helper";

export interface IInstruction {
    id: number;
    type: number;
    name: string;
}

export enum TakeMode {
    Single = 1,
    Multiple = 2
};

export enum DisplayMode {
    Concept = 1,
    Indent = 2
};

export enum RunningMode {
    Current = 1,
    Past = 2
};

export enum TimelineStatus {
    Taken = 1,
    Missed = 2,
    Pending = 3
};

export class IndentMedicine {
    pharmacyProductId: number;
    productName: string;
    productTypeName: string;
    quantity: number;
}

export class MedicationTimeline {
    medicationDate: Date;
    type: number;
    status: boolean;
    name: string;
}

export class MedicationTimelineGroup {
    headerDate: Date;
    records: Array<MedicationTimeline>;
}

export class MedicationTimelineMain {
    date: Date;
    morningStatus: TimelineStatus;
    afternoonStatus: TimelineStatus;
    nightStatus: TimelineStatus;
    slotArray: Array<slotArrayValue>;
}

export class slotArrayValue {
    timeslotStatus: TimelineStatus;
}
export class ProgressReportMedication {
    progressReportMedicationId: number;
    pharmacyProductId: number;
    unit: number;
    duration: number;
    medicationDurationTypeId: number;
    morningInstructionTypeId: number;
    afternoonInstructionTypeId: number;
    nightInstructionTypeId: number;
    timeLineId: Array<number>;
    active: boolean;
    activeStatus: ActiveStatus;
    createdDate: Date;
    createdByName: string;
    modifiedDate: Date;
    modifiedByName: string;
    progressReportId: number;
    instructions: string;
    stopReason: string;
    startDate: Date;
    endDate: Date;
    leftDays: number;
    takeDate: Date;
    productName: string;
    productIcon: string;
    productTypeName: string;
    productGenericName: string;
    productCompanyName: string;
    productSupplierName: string;
    pharmacyIndentDetailId: number;

    //morningInstructionTypeName: string;
    //afternoonInstructionTypeName: string;
    //nightInstructionTypeName: string;

    //medicationUsage: string;
    //morningInstructionStatus: boolean;
    //afternoonInstructionStatus: boolean;
    //nightInstructionStatus: boolean;

    isMorningTaking: boolean;
    isAfternoonTaking: boolean;
    isNightTaking: boolean;
    isTimeslotsTaking: boolean;
    medicationFrequency: Array<MedicationFrequency>;
}

export class MedicationFrequency {
    medicationInstructionTypeId: number;
    medicationInstructionDeepTypeId: number;
    medicationInstructionName: string;
    progressReportMedicationFrequencyId: string;
    medicationDate: Date;
    status: boolean
}