export class FetFormate {
    patientName: string
    patientId: number
    husbandName: string
    fetDate: Date
    age: number
    table: Array<FetFormatetable>
    consultant: string
    embryologist: string
}
export class FetFormatetable {
    noOfEnbryoThawed: number
    recovered: string
    overNightCulture: string
    embryoStatus: string
    discarded: string
    remaining: string
}