export class ScanLog {
    scanLogId: number;
    logDescription: string;
    logDate: string;
    scanLogTypeId: number;
    logFrom:number;  
    logTypeName:string;
    AccountId:number;
    fullName:string;
    roleName:string;
    totalItems: number;
}
