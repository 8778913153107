import { HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApiResources, UtilHelper } from "@shared/helpers";
import { IResource, IUserAccount, Page, Pagination, ProgressData } from "@shared/models";
import { AppData, HttpService, NotifyService, ResourceService } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";
import Swal from "sweetalert2";
import { WhiteSpaceValidator } from "../../../../../shared/validators";
import { PackageDocument, PackageModuleModel } from "./models";

enum ModalType {
    View
}

class TrackBy {
    package(_: number, item: PackageModuleModel): number {
        return item.packageModuleId;
    }
}

class FilterOptions {
    locationId: number = null;
    packageName: string = null;
    packageType: string = null;
    payType: string = null;
}

class Filters {
    hidden: boolean;
    options: FilterOptions;
    applied: boolean;

    constructor() {
        this.init();
        this.hidden = true;
    }

    init() {
        this.options = new FilterOptions();
        this.applied = false;
    }
}

@Component({
    templateUrl: "./package-modules.html",
    encapsulation: ViewEncapsulation.None
})
export class PackageModulesPage implements OnInit, OnDestroy {
    packageTypes = [{ value: "O", name: "Out-patient" }, { value: "I", name: "In-patient" }, { value: "OI", name: "OP & IP" }] as Array<IResource>;
    payTypes = [{ value: "C", name: "Cash" }, { value: "I", name: "Insurance" }] as Array<IResource>;
    @ViewChild("videoPlayer", { static: false }) videoPlayer: ElementRef;

    page: Page;
    trackBy: TrackBy;
    filters: Filters;
    pagination: Pagination;
    packages: Array<PackageModuleModel>;

    selectedPackage: PackageModuleModel;
    loadingLocations: boolean;
    locations: Array<IResource>;

    uploadDocumentForm: FormGroup;
    maxFiles = 10;
    files: Array<File>;
    submitting: boolean;
    submitted: boolean;
    errorMessage: string;
    packageModuleId: number;

    documents: Array<PackageDocument>;
    document: PackageDocument;
    loadingDocument: boolean;
    documentError: boolean;
    showPrevious: boolean;
    showNext: boolean;

    private modalRef: NgbModalRef;

    constructor(
        private readonly router: Router,
        private readonly appData: AppData,
        private readonly modalService: NgbModal,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
        private readonly resourceService: ResourceService,
        private readonly formBuilder: FormBuilder,
        private readonly sanitizer: DomSanitizer
    ) {
        this.page = new Page();
        this.packages = [];
        this.filters = new Filters();
        this.trackBy = new TrackBy();
        this.initPagination();
        this.document = new PackageDocument();
        this.selectedPackage = new PackageModuleModel();
    }

    ngOnInit(): void {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchPackages();
                    this.fetchLocations();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    onApplyFilters(): void {
        this.filters.applied = UtilHelper.isFiltersApplied(this.filters.options);
        this.initPagination();
        this.fetchPackages();
        this.onCloseModal();
    }

    onResetFilters(): void {
        this.filters.init();
        this.filters.options.locationId = this.page.userAccount.locationId;
        this.initPagination();
        this.fetchPackages();
        this.onCloseModal();
    }

    onChangePageSize(pageSize: number): void {
        if (pageSize === this.pagination.pageSize) return;
        this.initPagination();
        this.fetchPackages();
    }

    onNextPage(): void {
        this.fetchPackages();
    }

    onAddPackage(): void {
        this.router.navigateByUrl("/app/masters/add-package-module");
    }

    onEditPackage(packageModuleId: string): void {
        this.router.navigate(["/app/masters/modify-package-module", packageModuleId]);
    }  

    onOpenModal(content: TemplateRef<any>, type: ModalType, model?: PackageModuleModel): void {
        switch (type) {
            case ModalType.View:
                this.selectedPackage = model;
                break;
        }

        this.modalRef = this.modalService.open(content, { size: "lg", windowClass: "custom-modal effect-scale" });
    }

    onCloseModal(): void {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = undefined;
        }

        this.submitted = undefined;
        this.submitting = undefined;
        this.document = undefined;
        this.loadingDocument = undefined;
        this.documentError = undefined;
        this.showNext = undefined;
        this.showPrevious = undefined;
        this.selectedPackage = undefined;
    }

    onModifyStatus(packageModule: PackageModuleModel, status: boolean) {
        if (packageModule.isModifying) return;
        this.selectedPackage = packageModule;
        let url = ApiResources.getURI(ApiResources.packageModule.base, status ? ApiResources.packageModule.enable : ApiResources.packageModule.disable)
        this.notifyService.confirm(`Are you sure to ${status ? 'enable' : 'disable'} the selected package module?`, () => {
            packageModule.isModifying = true;
            this.httpService
                .post(url, { packageModuleId: this.selectedPackage.packageModuleId, PackageName: this.selectedPackage.packageName, counselingId: this.selectedPackage.packageModuleId, disabledBy: this.page.userAccount.accountId, createdBy: this.page.userAccount.accountId, createdByName: this.page.userAccount.fullName, locationId: this.page.userAccount.locationId, loginRoleId: this.page.userAccount.roleId })
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => { packageModule.isModifying = false; this.selectedPackage = undefined }))
                .subscribe(
                    (message: string) => {
                        this.notifyService.successToast(message);
                        this.fetchPackages();
                    }
                );
        }, () => { packageModule.isModifying = false; });
    }

    onDelete(packageModule: PackageModuleModel) {
        if (packageModule.isDeleting) return;
        this.selectedPackage = packageModule;
        this.notifyService.delete(() => {
            packageModule.isDeleting = true;
            this.httpService
                .post(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.delete), { packageModuleId: this.selectedPackage.packageModuleId, PackageName: this.selectedPackage.packageName, counselingId: this.selectedPackage.packageModuleId, disabledBy: this.page.userAccount.accountId, createdBy: this.page.userAccount.accountId, createdByName: this.page.userAccount.fullName, locationId: this.page.userAccount.locationId, loginRoleId: this.page.userAccount.roleId })
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => { packageModule.isDeleting = false; }))
                .subscribe(
                    (message: string) => {
                        this.notifyService.successToast(message);
                        this.fetchPackages();
                    }
                );
        }, () => { packageModule.isDeleting = false; });
    }

    onOpenDocumentModal(content: TemplateRef<any>, record: PackageModuleModel): void {
        this.packageModuleId = record.packageModuleId;
        this.buildForm();
        this.modalRef = this.modalService.open(content, {
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal effect-scale",
            backdrop: "static"
        });
    }

    onOpenViewDocumentModal(content: TemplateRef<any>, record: PackageModuleModel): void {
        this.fetchDocuments(record.packageModuleId);
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            windowClass: "document-view-modal custom-modal effect-scale"
        });
    }

    showDocuments(document: PackageDocument): void {
        this.prepareDocument(document);
        const index = this.documents.findIndex((m => m.packageDocumentId === this.document.packageDocumentId) as any);
        if (index === 0 && this.documents.length === 1) {
            this.showPrevious = false;
            this.showNext = false;
        } else if (index === 0) {
            this.showPrevious = false;
            this.showNext = true;
        } else if (index === this.documents.length - 1) {
            this.showPrevious = true;
            this.showNext = false;
        } else {
            this.showPrevious = true;
            this.showNext = true;
        }
    }

    onPrevious(): void {
        let index = this.documents.findIndex((m => m.packageDocumentId === this.document.packageDocumentId) as any);
        index = index - 1;
        this.showPrevious = index !== 0;
        this.showNext = true;
        this.document = undefined;
        this.prepareDocument(this.documents[index]);
    }

    onNext(): void {
        let index = this.documents.findIndex((m => m.packageDocumentId === this.document.packageDocumentId) as any);
        index = index + 1;
        this.showNext = index !== this.documents.length - 1;
        this.showPrevious = true;
        this.document = undefined;
        this.prepareDocument(this.documents[index]);
    }

    get form() { return this.uploadDocumentForm.controls }

    onFileSelect(files: Array<File>): void {
        this.files = files;
    }

    onSubmitDocument(): void {
        this.submitted = true;
        if (!this.uploadDocumentForm.valid) {
            return;
        }
        this.submitting = true;
        this.errorMessage = undefined;
        const model = UtilHelper.clone(this.uploadDocumentForm.value);
        const formData = new FormData();
        formData.append("PackageModuleId", this.packageModuleId.toString());

        formData.append("UploadedBy", this.page.userAccount.accountId.toString());
        formData.append("DocumentName", model["documentName"]);
        formData.append("loginRoleId", this.page.userAccount.roleId.toString());
        formData.append("createdByName", this.page.userAccount.fullName);
        formData.append("locationId", this.page.userAccount.locationId.toString());

        const files = this.files;
        if (!files || !files.length) {
            this.submitting = false;
            this.notifyService.info("Please select at least one file");
            return;
        }
        const n = files.length;
        if (n > this.maxFiles) {
            files.splice(this.maxFiles, files.length);
        }
        files.forEach((file: File, index: number) => {
            formData.append(`File${index + 1}`, file, file.name);
        });
        this.httpService
            .postFile(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.uploadDocument), formData)
            .pipe(finalize(() => { this.submitted = false; this.submitting = false; }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                () => {
                    this.files = [];
                    this.notifyService.successToast("Document uploaded successfully.");
                    this.onCloseModal();
                    this.fetchPackages();

                },
                (error: HttpErrorResponse) => {
                    this.errorMessage = UtilHelper.handleError(error);
                    this.notifyService.warning(this.errorMessage);
                }
            );
    }

    onDocumentLoad(): void {
        this.loadingDocument = false;
    }

    onDocumentError(): void {
        this.loadingDocument = false;
        this.documentError = true;
    }

    toggleVideo(): void {
        this.videoPlayer.nativeElement.play();
    }

    onProgress(progressData: ProgressData): void {
        if (progressData.loaded === progressData.total) {
            this.loadingDocument = false;
        }
    }

    ngOnDestroy(): void {
        this.onCloseModal();
        this.page.unsubscribeAll();
        Swal.close();
    }

    private initPagination(): void {
        this.pagination = new Pagination();
        this.pagination.pageIndex = 1;
        this.pagination.pageSize = 10;
    }

    private createRequestBody(): any {
        const requestBody = Object.assign(UtilHelper.clone(this.filters.options), this.pagination);
        return requestBody;
    }

    private fetchPackages(): void {
        this.page.loading = true;
        this.httpService
            .post(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.fetch), this.createRequestBody())
            .pipe(
                finalize(() => { this.page.loading = false, this.page.loaded = true }),
                takeUntil(this.page.unSubscribe)
            )
            .subscribe({
                next: (response: Array<PackageModuleModel>) => {
                    this.packages = response;
                    UtilHelper.applyPagination(this.packages, this.pagination);
                },
                error: error => {
                    this.page.markError();
                    const errorMessage = UtilHelper.handleError(error);
                    if (errorMessage)
                        this.notifyService.error(errorMessage);
                    else
                        this.notifyService.defaultError();
                }
            });
    }

    private fetchLocations(): void {
        this.loadingLocations = true;
        this.resourceService.locations(true)
            .pipe(finalize(() => this.loadingLocations = false), takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => this.locations = response);
    }

    private fetchDocuments(packageModuleId: number): void {
        this.httpService.post(ApiResources.getURI(ApiResources.packageModule.base, ApiResources.packageModule.fetchPackageDocuments), packageModuleId)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<PackageDocument>) => {
                this.documents = response;
                if (response && response.length > 0) { this.showDocuments(response[0]); }
            });
    }

    private prepareDocument(document: PackageDocument): void {
        this.loadingDocument = true;
        this.document = document;
        this.documentError = false;
        this.document.isImage = this.document.contentType.indexOf("pdf") < 0 && this.document.contentType.indexOf("video") < 0;
        this.document.isVideo = this.document.contentType.indexOf("video") >= 0;

        if (this.document.isImage || this.document.isVideo) {
            this.document.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.downloadFileGet)}?id=${this.document.packageDocumentId}&url=${this.document.documentUrl}&type=${'PackageDocuments'}`);
        }

        if (!this.document.isImage && !this.document.isVideo) {
            this.httpService
                .post(ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.downloadFile), { ...document })
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => { this.loadingDocument = false; }))
                .subscribe(
                    (response: PackageDocument) => {
                        this.document.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64, ${response.base64}`);
                    }
                );

        } else {
            this.loadingDocument = false;
        }
    }

    private buildForm(): void {
        this.uploadDocumentForm = this.formBuilder.group({
            documentName: [null, [Validators.required, WhiteSpaceValidator.isValid]],
            description: [null]
        });
    }

    onCopyPackage(packageModuleId: string): void {
        this.router.navigate(["/app/masters/modify-package-module","true", packageModuleId]);
    }
}