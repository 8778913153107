import { Component, OnInit, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from "@angular/core";
import { Page, IUserAccount } from "../../models";
import { takeUntil, finalize } from "rxjs/operators";
import { LabBillHeaderModel } from "../../../areas/admin/labs/pages/models";
import { ApiResources, MoneyHelper } from "../../helpers";
import { AppData, HttpService, PrintOptionService } from "../../services";
import { PracticeLocation } from "../../entities";

@Component({
    templateUrl: "./investigation-exclusions.html",
    selector: "investigation-invoice",
    encapsulation: ViewEncapsulation.None
})

export class InvestigationWidget implements OnInit, OnDestroy {
    @Input() newLabBookingHeaderId: number;
    @Input() encryptedNewLabBookingHeaderId: string;
    @Output() onClose = new EventEmitter<any>();
    page: Page;
    loading: boolean;
    QrCode: string = null;
    currentDate: Date;
    bill: Array<LabBillHeaderModel>;
    isPrintLogo: boolean;
    isFooter: boolean;
    practiceName: string;
    locationName: string;
    practice: PracticeLocation[];
    hospitalName: string;
    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
    ) {
        this.page = new Page();
        //this.age = new Age();
        this.bill = new Array<LabBillHeaderModel>();
        this.currentDate = new Date();

    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
              
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchInvoice();
                    this.fetchPractices();
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                } else {
                    this.page.userAccount = undefined;
                    this.fetchInvoice();
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                }
                this.practiceName = this.page.userAccount.practiceName;
                this.locationName = this.page.userAccount.locationName;
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }

    onCloseModal() {
        this.onClose.emit();
    }

    private fetchInvoice() {
        const request = {
            encryptedNewLabBookingHeaderId: this.encryptedNewLabBookingHeaderId
        };
        this.loading = true;
        this.httpService.post(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchInvestigationreport), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<LabBillHeaderModel>) => {
                    this.bill = response;
                    this.moneyHelper();
                   
                });
        this.currentDate = new Date();
       
    }
    moneyHelper = () => {
        this.bill[0].overallTotalAmountInWord = MoneyHelper.numberCurrencyIn(this.bill[0].overallNetAmount);
 }
     
    overallTotalAmount
    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
    fetchPractices() {
        const practiceRequest = {
            locationIds: this.page.userAccount.locationId,
        };
        this.httpService.post(ApiResources.getURI(ApiResources.practices.base, ApiResources.practices.fetchPracticeInfo), practiceRequest)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe((response: Array<PracticeLocation>) => {
                this.practice = response;
                if (this.practice[0]?.fullName.toLowerCase().includes('a4')) {
                    this.hospitalName = 'A4 HOSPITAL';
                } else if (this.practice[0]?.fullName.toLowerCase().includes('fernandez')) {
                    this.hospitalName = 'FERNANDEZ HOSPITAL';
                } else if (this.practice[0]?.fullName.toLowerCase().includes('maa')) {
                    this.hospitalName = 'MAA SHARADA HOSPITAL';
                } 
            }, () => {
                this.practice = Array<PracticeLocation>();
            });

    }
}