export class PatientDietPlan {
    patientDietId: number;
    admissionId: number;
    orderDate: Date;
    mealTypeId: number;
    dietPlanTypeId: number;
    notes: string;
    locationId: number;
    approvedBy: number;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
    floorId: number;
    requestType: number;
    patientId: number;
    patientName: string;
    umrNo: string;
    patientMobile: string;
    isSelected: boolean;
    indentBy: number;
    bedNumber: string;
    bedId: number;
    roomId: number;
    currentRoomName: string;
    wardId: number;
    floorName: string;
    wardName: string;
    admissionNo: string; 
    indententName: string;
    indentRoleName: string;
    approvedName: string;
    approvedRoleName: string;
    approvedDate: any;
    indentDate: any;
    mealType: string;
    dietPlanCount: number;
    count: number;
    dietStatusId: number;
    patientDietIds: string;
    dietPlaneType: string;
    dietPlanTypeName: string;
    locationName: string;
    mobile: string;
    approvedByName: string;
    sentToSupervisor: string;
    deliverBySupervisor: string;
    dietAcknowledgement: string;
    CreatedDateDietician: string;
}
