export class Account {
    accountId: number;
    referenceId: number;
    roleId: number;
    roleName: string;
    fullName: string;
    email: string;
    mobile: string;
    countryId: number;
    countryName: string;
    countryCode: string;
    lastLoginDate?: Date;
    createdDate?: Date;
    active?: boolean;
    isLocked?: boolean;
    thumbnailUrl: string;
    totalItems: number;
    status: string;
    locationIdentifiers: string;
    userName: string;
}