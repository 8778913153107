export class ModuleModel {
    modulesMasterId: number;
    moduleName: string;
    moduleIcon: string;
    packageType: string;
    isChargeCategoryApplicable: boolean;
    modelValue: number;
    modelValue1: string;
    isPackageApplicable: boolean;
    total?: number;
    packageModuleId: number;
    includedLimit: number;
    isAllInclude: boolean;
}