export class PharmacyRawProduct{
    productName: string;
    genericName: string;
    company: string;
    category: string;
    rack: string;
    purchaseQuantity: string;
    purchaseUnit: string;
    saleQuantity: string;
    saleUnit: string;
    gst: string;
    hsnCode: string;
    minQuantity: string;
    maxQuantity: string;
    reorderQuantity: string;
    barCode: string;
    productExpire: string;
    billNumber: string;
    batchNumberRequired: string;
    rolQuantity: string;
    purchaseQty: string;
    categoryName: string;
   
}