export class SymptomsData {
    request: string;
    response: string;
}

export class Symptoms {
    type: string;
    data: Array<SymptomsData>;

    constructor() {
        this.data = [];
    }
}

export class AppointmentSymptoms {
    symptoms: Symptoms;
    user: string;

    constructor() {
        this.symptoms = new Symptoms();
    }
}