import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { finalize, switchMapTo, takeUntil } from "rxjs/operators";
import { IUserAccount, Page,IResource, GenericResponse, GenericStatus } from "@shared/models";
import { AppData, HttpService, NotifyService, ResourceService } from "@shared/services";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { ApiResources, LinqHelper } from "@src/app/shared/helpers";
import { IViewModel, MenuList, ViewType } from "./local-helper";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Bed } from "../../helpers/helper";
import {Router } from "@angular/router";
import { be } from "@fullcalendar/core/internal-common";

@Component({
    selector: "inpatients-view",
    templateUrl: "./in-patients-view.html",
    styleUrls: ['../../nurse-module.css']
})
export class InPatientsViewPage implements OnInit, OnDestroy {
    page: Page;
    mainForm: FormGroup;
    locations: Array<IResource>;
    locationSubscription: Subscription;
    loading: boolean;
    records: Array<IViewModel>;
    modalRef: NgbModalRef;
    selected: IViewModel;
    menuList = MenuList;
    currentMenuList: MenuList;
    currentViewType: ViewType;
    viewType = ViewType;

    floorsFilter: Array<string>;
    wardsFilter: Array<string>;
    roomsFilter: Array<string>;
    originalBeds: Array<Bed>;
    isNurse: boolean;
    viaBarcode: string;
    constructor(
        private readonly appData: AppData,
        private readonly formBuilder: FormBuilder,
        private readonly resourceService: ResourceService,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
        private readonly modalService: NgbModal,
        private readonly router: Router,
    ) {
        this.locations = new Array<IResource>();
        this.records = new Array<IViewModel>();
        this.currentMenuList = MenuList.medications;
        this.currentViewType = ViewType.AllPatients;
        this.page = new Page();
        this.buildForm()
    }

    buildForm = () => {
        var floorName = new FormControl(null);
        var wardName = new FormControl(null);
        var roomName = new FormControl(null);
        var patientId = new FormControl(null);
        this.mainForm = this.formBuilder.group({
            locationId: [],
            floorName, wardName, roomName,patientId
        });

        this.locationSubscription = this.mainForm.get("locationId").valueChanges.subscribe((_: number) => {
            this.fetchAll(_);
        });

        floorName.valueChanges.subscribe(z => {
            if (!z) {
                this.mainForm.patchValue({
                    wardName: null, roomName: null
                }, { emitEvent: false });
                return;
            }
            this.wardsFilter = this.originalBeds.filter(x => x.floorName === z).map(x => x.wardName).filter(LinqHelper.uniqueOnly);
            this.roomsFilter = this.originalBeds.filter(x => x.floorName === z).map(x => x.roomName).filter(LinqHelper.uniqueOnly);
        });

        wardName.valueChanges.subscribe(z => {
            if (!z) {
                this.mainForm.patchValue({
                    roomName: null,
                }, { emitEvent: false });
                return;
            }
            this.floorsFilter = this.originalBeds.filter(x => x.wardName === z).map(x => x.floorName).filter(LinqHelper.uniqueOnly);
            this.roomsFilter = this.originalBeds.filter(x => x.wardName === z).map(x => x.roomName).filter(LinqHelper.uniqueOnly);
        });

        roomName.valueChanges.subscribe(z => {
            this.floorsFilter = this.originalBeds.filter(x => x.roomName === z).map(x => x.floorName).filter(LinqHelper.uniqueOnly);
            this.wardsFilter = this.originalBeds.filter(x => x.roomName === z).map(x => x.wardName).filter(LinqHelper.uniqueOnly);
        });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.close();
    }

    ngOnInit() {
        this.fetchLocations();
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.setIsNurse();
                    this.mainForm.patchValue({
                        locationId: this.page.userAccount.locationId
                    }, { emitEvent: false });
                    
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    fetchAll = (locationId: number) => {
        this.fetchBeds(locationId);
        this.currentViewType = this.isNurse ? ViewType.MyPatients : ViewType.AllPatients;
        this.fetch(locationId);
    }

    setIsNurse = () => {
        this.httpService.post<boolean>(ApiResources.getURI(ApiResources.account.base, ApiResources.account.isNurse),
            { id: this.page.userAccount.accountId }
        )
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: boolean) => {
                this.isNurse = response;
                this.fetchAll(this.page.userAccount.locationId)
            });
    }

    fetchBeds = (locationId: number) => {
        this.loading = true;
        this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.inpatientsView.base, ApiResources.inpatientsView.fetchBeds),
            { locationId: locationId || this.mainForm.get("locationId").value }
        )
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false; }))
            .subscribe((response: GenericResponse) => {
                if (response.status === GenericStatus[GenericStatus.Success]) {
                    var data = response.data as Array<Bed>;
                    //data.forEach((x: Bed) => {
                    //    x.floorName = LinqHelper.stuffIn(x.floorName, WordHelper.floorName);
                    //    x.wardName = LinqHelper.stuffIn(x.wardName, WordHelper.wardName);
                    //    x.roomName = LinqHelper.stuffIn(x.roomName, WordHelper.roomName);
                    //})

                    this.floorsFilter = data.map(x => x.floorName).filter(LinqHelper.uniqueOnly);
                    this.wardsFilter = data.map(x => x.wardName).filter(LinqHelper.uniqueOnly);
                    this.roomsFilter = data.map(x => x.roomName).filter(LinqHelper.uniqueOnly);
                    this.originalBeds = data;
                } else {
                    this.notifyService.defaultError();
                }
            });
    }

    resetFilter = () => {
        this.floorsFilter = this.originalBeds.map(x => x.floorName).filter(LinqHelper.uniqueOnly);
        this.wardsFilter = this.originalBeds.map(x => x.wardName).filter(LinqHelper.uniqueOnly);
        this.roomsFilter = this.originalBeds.map(x => x.roomName).filter(LinqHelper.uniqueOnly);
        this.mainForm.patchValue({
            floorName: null,
            wardName: null,
            roomName: null,
            patientId: null
        }, {emitEvent: false});
        this.fetch();
    }

    private fetchLocations() {
        this.resourceService.locations()
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.locations = response;
            });
    }

    fetch = (locationId?: number) => {
        this.loading = true;
        this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.inpatientsView.base, ApiResources.inpatientsView.fetch),
            { 
                ...this.mainForm.value,
                locationId: locationId || this.mainForm.value["locationId"],
                isNurse: this.currentViewType == ViewType.MyPatients ? this.isNurse : false,
                accountId: this.page.userAccount.accountId
            }
        )
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false }))
            .subscribe((response: GenericResponse) => {
                if (response.status === GenericStatus[GenericStatus.Success]) {
                    let records = response.data as Array<IViewModel>;
                    records.forEach(x => {
                        x.totalCount = (x.medicationsCount || 0) + (x.notesCount || 0) + (x.vitalsCount || 1); 
                    })
                    this.records = records;
                } else {
                    this.notifyService.defaultError();
                }
            });
    }

    open = (record: IViewModel, content: TemplateRef<any>) => {
        this.selected = record;
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "xl",
            windowClass: "custom-modal vl-modal effect-scale"
        });
    }

    close = () => {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            // ignored;
        }
    }

    menuOpen = (menuList: MenuList) => {
        this.currentMenuList = menuList;
    }

    changeViewType = (type: ViewType) => {
        this.currentViewType = type;
        this.fetch();
    }
    onBarcodeEventEmitter(value: string) {
        this.notifyService.successToast("Scanned via barcode " + value);
        this.viaBarcode = "Barcode";
        this.onFetchOnlyViaBarcodeUMRNumber(value);
    }

    onFetchOnlyViaBarcodeUMRNumber(umrNumber: string) {
        this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.inpatientsView.base, ApiResources.inpatientsView.fetch),
            {
                locationId: this.mainForm.value["locationId"],
                uMRNo: umrNumber ,
                //...this.mainForm.value,
                isNurse: this.currentViewType == ViewType.MyPatients ? this.isNurse : false,
                accountId: this.page.userAccount.accountId
            }
        )
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false }))
            .subscribe((response: GenericResponse) => {
                if (response.status === GenericStatus[GenericStatus.Success]) {
                    let records = response.data as Array<IViewModel>;
                    records.forEach(x => {
                        x.totalCount = (x.medicationsCount || 0) + (x.notesCount || 0) + (x.vitalsCount || 1);
                    })
                    this.records = records;
                    if (this.records.length === 0 && this.viaBarcode === "Barcode") {
                        this.notifyService.warningToast("Invalid barcode scanned");
                    }
                    if (this.records.length === 1 && this.viaBarcode === "Barcode") {
                        this.mainForm.patchValue({
                            floorName: this.records[0].floorName,
                            wardName: this.records[0].wardName,
                            roomName: this.records[0].roomName,
                        })
                    }
                } else {
                    this.notifyService.defaultError();
                }
            });
    }
    onClickNurseAssessment() {
        this.close();
        this.router.navigate(['app/ob-ip-encounter', this.selected.encryptedAdmissionId, 'a', 'nurse-assesment'])
    }

    onClickNurseForms(record: any, type: string) {
        var encryptedAdmissionId = window.btoa(record.admissionId);
        switch (type) {
            case "Tablets":
                this.router.navigate(['app/nurse-assessment', encryptedAdmissionId, 'a', 'medication'])
                break;
            case "Notes":
                this.router.navigate(['app/nurse-assessment', encryptedAdmissionId, 'a', 'nurse-notes'])
                break;
            case "Vitals":
                this.router.navigate(['app/nurse-assessment', encryptedAdmissionId, 'a', 'vitals'])
                break;
            case "Labs":
                this.router.navigate(['app/nurse-assessment', encryptedAdmissionId, 'a', 'labs'])
                break;
            case "Nurse Assessment":
                this.router.navigate(['app/nurse-assessment', encryptedAdmissionId, 'a', 'nurse-initial'])
                break;
            default:
                this.router.navigate(['app/nurse-assessment', encryptedAdmissionId, 'a']);
                break;

        }
    }
    onClickAlert(record: any) {
        this.router.navigate(['app/nurse-module/actions', record.encryptedAdmissionId ,'P'])
    }
}