import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ViewChildren, ElementRef, QueryList } from "@angular/core";
import { Page, IUserAccount, CommunicationMessageModel } from "../../models";
import { Message, Appointment } from "@shared/entities";
import { takeUntil, finalize } from "rxjs/operators";
import { HttpService, CommunicationService, AppData } from "@shared/services";
import { ApiResources } from "../../helpers";

interface IMessages {
    accountId: number;
    message: string;
    date: Date;
    messagedBy: string;
}

@Component({
    selector: "messaging-widget",
    styleUrls: ["./messaging.css"],
    templateUrl: "./messaging.widget.html"
})

export class MessagingWidget implements OnDestroy, OnInit, AfterViewInit {
    @Input() appointment: Appointment;
    @Output() close = new EventEmitter<boolean>();
    @ViewChild('chatContainer', { static: false }) chatContainer: ElementRef;
    @ViewChildren('chatMessage') chatMsgs: QueryList<any>;
    messages: Message;
    page: Page;
    loading: boolean;
    loadingMessages: boolean;
    isMessageSending: boolean;
    messageBox: string;
    allMessages: Array<IMessages>;
    private scrollContainer: any;
    disableMessageBox: boolean;

    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly communicationService: CommunicationService
    ) {
        this.page = new Page();
        this.messages = new Message();
        this.allMessages = new Array<IMessages>();
        this.disableMessageBox = false;
    }

    private onFetchMessages(num: number) {
        if (num === 0) {
            this.loadingMessages = true;
        }
        const request = {
            appointmentId: this.appointment.appointmentId,
            requestFrom: "Provider"
        }
        this.httpService
            .post<Message>(ApiResources.getURI(ApiResources.message.base, ApiResources.message.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingMessages = false))
            .subscribe(
                (response: Message) => {
                    this.messages = response;
                    if (this.messages !== null && this.messages.messageId !== 0) {
                        this.allMessages = JSON.parse(this.messages.messages);
                        setTimeout(() => { this.scrollToBottom(); }, 500);
                    }
                },
                () => {
                    this.messages = new Message();
                }
            );
    }

    onMessaging() {
        if (this.messageBox === undefined || this.messageBox === null || this.messageBox === "" || this.messageBox.trim() === "") {
            this.messageBox = null;
            return;
        }
        const model = {
            accountId: this.page.userAccount.accountId,
            message: this.messageBox,
            date: new Date(),
            messagedBy: "Doctor"
        };
        this.allMessages.push(model);
        //this.messages.doctorMessageCount += 1;
        this.messageBox = null;
        this.scrollToBottom();
        this.onSendMessage();
    }

    onSendMessage() {
        this.isMessageSending = true;
        let messageCount = 1;
        if (this.messages) {
            messageCount = this.messages.patientMessageCount === null
                ? this.messages.patientMessageCount = 1
                : this.messages.patientMessageCount + 1;
        }
        const request = {
            messageId: 0,
            appointmentId: this.appointment.appointmentId,
            providerId: this.appointment.providerId,
            patientId: this.appointment.patientId,
            messages: JSON.stringify(this.allMessages),
            doctorMessageCount: null,
            patientMessageCount: messageCount,
            requestFrom: "Provider"
        };
        let url = ApiResources.getURI(ApiResources.message.base, ApiResources.message.add);
        if (this.messages && this.messages.messageId !== 0) {
            request["messageId"] = this.messages.messageId;
            url = ApiResources.getURI(ApiResources.message.base, ApiResources.message.update);
        }
        this.httpService.post(url, request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.isMessageSending = false))
            .subscribe(
                (response: number) => {
                    if (response > 0) {
                        this.onFetchMessages(1);
                    }
                }
            );
    }

    onCloseClick() {
        this.close.emit(true);
    }

    private checkAppointmentDate() {
        const date = new Date(this.appointment.appointmentDate);
        date.setDate(date.getDate() + 3);
        const currentDate = new Date();
        if (currentDate > date) {
            this.disableMessageBox = true;
        }
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    if (this.appointment !== null) {
                        this.onFetchMessages(0);
                        this.checkAppointmentDate();
                    }
                } else {
                    this.page.userAccount = undefined;
                }
            });

        this.communicationService.hubConnection.on("Communication", (data: any) => {
            var communication = data as CommunicationMessageModel;
            if (communication.type === 9) {
                if (this.messages !== null) {
                    if (this.messages.messageId === parseInt(communication.uniqueId)) {
                        this.onFetchMessages(1);
                    }
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.appointment = null;
    }

    ngAfterViewInit() {
        if (this.chatContainer != undefined) {
            this.scrollContainer = this.chatContainer.nativeElement;
            this.chatMsgs.changes.subscribe(_ => this.scrollToBottom());
        }
    }

    private scrollToBottom(): void {
        this.scrollContainer.scroll({
            top: this.scrollContainer.scrollHeight,
            left: 0,
            behavior: 'smooth'
        });
    }



    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`;
        }
    }
}
