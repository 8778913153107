export class EncounterTemplate {
    templateHeaderId?: number;
    templateDetailId?: number;
    modulesMasterId?: number;
    templateName: string;
    createdBy?: number;
    createdDate: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    active: boolean;
    jsonValue: string;
    createdByName: string;
    modifiedByName: string;
    moduleName: string;
    moduleIcon: string;
    totalItems: number;
    pageSize?: number ;
    pageIndex?: number;
    createdByRole?: string;
    modifiedByRole?: string;
}