export enum GeneticSpecialityEncounter {

    SpecialFeaturesPage = 1,
    GeneticVisit, 
    OutSideTests,
    Reminder,
    Orders,
    ReferralForm,
    Confidential,
    DemoGraphicData,
    FutureAppointments,
    AddendumForm,
    IOLList,
    OtAppointmentDetails,
    RefferalOrder,
    GeneticSummary,
    TelePhoneTriageAssessment
}