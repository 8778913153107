export class GeneralAdvice {
    total?: number;
    generalAdviceId: number;
    fullName?: string;
    isAssignedGeneralAdvice?: boolean = false;
    description?: string;
    createdBy: number;
    createdDate: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    totalItems?: number;
}