export class IcdCode {
    icdCodeId: number;
    code: string;
    description: string;
    createdDate: Date;
    createdBy: number;
    accountId: number;
    active: boolean;
    modifiedBy?: number;
    modifiedDate?: Date;
    isAssignedIcdCode?: boolean = false;
    totalItems: number;
} 