<form [formGroup]="form" (ngSubmit)="onValidate()">
    <div class="d-block text-center">
        <img width="200" class="mb-3" src="assets/images/otp.png" alt="OTP" />
    </div>
    <p class="text-center" *ngIf="model.countryId !== 0"><b>Verification code</b> has been sent to your mobile number (+{{model.countryCode}}) {{model.username}}, please enter the same here.</p>
    <p class="text-center" *ngIf="model.countryId === 0"><b>Verification code</b> has been sent to your email address {{model.username}}, please enter the same here.</p>
    <p class="text-center" *ngIf="otpExpiresIn">Verification code will expires in <b>{{otpExpiresIn | minuteSeconds}}</b></p>
    <p class="text-center" *ngIf="!otpExpiresIn">
        <ng-container *ngIf="!submitting">Verification code is expired. <a href="javascript:;" (click)="onSendOTP()">Resend new</a></ng-container>
        <ng-container *ngIf="submitting"><span class="spinner-border-sm spinner-border text-warning mr-2"></span>Please wait while sending new Verification code...</ng-container>
    </p>
    <div class="row justify-content-center">
        <div class="form-group mb-4 col-sm-2" *ngFor="let input of formInput; index as i">
            <input #formRow type="text" formControlName="{{input}}" block autocomplete="nope" numbersOnly class="form-control" [ngClass]="{ 'is-invalid': submitted && (form.get(input).errors || !otpValid) }" maxlength="1" (keyup)="onKeyUpEvent($event, i)">
        </div>
    </div>
    <button type="submit" [disabled]="submitting" class="btn btn-primary btn-block">Submit</button>
</form>