export class ImageReports {
    imageId: number;
    imagePath: string;
    createdBy: number;
    createdByName: string;
    createdDate: Date;
    modifiedBy: number;
    modifiedByName: string;
    modifiedDate?: Date;
    isSelected: boolean;
    active: boolean;
    imageType: string;
}