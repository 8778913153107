import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import {  IUserAccount, Page } from "@shared/models";
import { HttpService, AppData } from "@shared/services";
import { ApiResources } from "@shared/helpers";

import { finalize, takeUntil } from "rxjs";

class PracticeLocation {
    streetAddress
    locationName
    cityName
    zipcode
    stateName
    countryName
}
@Component({
    templateUrl: "./reports-footer-location.html",
    selector: "reports-footer-location",
    encapsulation: ViewEncapsulation.None
})
export class ReportFooterLocationWidget implements OnInit {

    locationId: number;
    Location: PracticeLocation;
    page: Page;
    constructor(
        private readonly httpService: HttpService,
        private readonly appData: AppData,
    ) {
        this.page = new Page();
        this.Location = new PracticeLocation();
    }

    fetch = () => {
        this.httpService
            .get<PracticeLocation>(ApiResources.getURI(ApiResources.practices.base, ApiResources.practices.practicelocationinfo), { locationId: this.locationId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe(
                (response: PracticeLocation) => {
                    if (response) {
                        this.Location = response
                    }
                },
                () => {
                    this.Location = new PracticeLocation();
                }
            );
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.locationId = this.page.userAccount.locationId;
                    this.fetch();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }
}