import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class HttpService {
    constructor(
        private readonly http: HttpClient
    ) {
    }

    get<TResponse>(apiEndPoint: string, request?: any, auth = true): Observable<TResponse> {
        let headers = new HttpHeaders();
        headers.append("Auth", auth ? "True" : "False");
        return this.http.get<TResponse>(apiEndPoint, { headers: headers, params: request });
    }

    post<TResponse>(apiEndPoint: string, request: any, auth = true): Observable<TResponse> {
        let headers = new HttpHeaders();
        headers.append("Auth", auth ? "True" : "False");
        return this.http.post<TResponse>(apiEndPoint, request, { headers: headers });
    }

    put<TResponse>(apiEndPoint: string, request: any, auth = true): Observable<TResponse> {
        let headers = new HttpHeaders();
        headers.append("Auth", auth ? "True" : "False");
        return this.http.put<TResponse>(apiEndPoint, request, { headers: headers });
    }

    delete<TResponse>(apiEndPoint: string, request?: any, auth = true): Observable<TResponse> {
        let headers = new HttpHeaders();
        headers.append("Auth", auth ? "True" : "False");
        return this.http.delete<TResponse>(apiEndPoint, { headers: headers, params: request });
    }

    postFile<TResponse>(apiEndPoint: string, request: any, auth = true): Observable<TResponse> {
        let headers = new HttpHeaders();
        headers.append("Auth", auth ? "True" : "False");
        return this.http.post<TResponse>(apiEndPoint, request, { headers: headers, reportProgress: true });
    }
}