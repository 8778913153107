export class LabReportMain {
    labBookingDetailId: number;
    labBookingHeaderId: number;
    labHeaderId: number;
    patientId: number;
    amount: number;
    status: string;
    isReportGenerated?: boolean;
    reportDate?: Date;
    labCode: string;
    signature: string;
    labName: string;
    patientType: string;
    billNumber: string;
    patientName: string;
    mobile: string;
    doctorName: string;
    paidVia: string;
    createdBy: number;
    createdByName: string;
    netAmount: number;
    createdDate: Date;
    umrNo: string;
    age: number;
    gender: string;
    isSampleCollected?: boolean;
    sampleDate?: Date;
}