import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiResources } from "@shared/helpers";
import {  Page, IResource } from "@shared/models";
import { HttpService, NotifyService, ResourceService } from "@shared/services";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { MobileValidator} from "@shared/validators";
import { finalize, takeUntil } from "rxjs";



@Component({
    templateUrl: "./patient-whatsapp-feedback.html",    
    encapsulation: ViewEncapsulation.None,
    
})
export class PatientWhatsappFeedback implements OnInit {
    page: Page;
    loading: boolean;
    modalRef: NgbModalRef;
    feedbackForm: FormGroup;
    locations: Array<IResource>;
    lodingLocation: boolean;

    constructor(
        private readonly httpService: HttpService,
        private readonly router: Router,
        private readonly formBuilder: FormBuilder,       
        private readonly modalService: NgbModal,
        public readonly notifyService: NotifyService,   
        private readonly resourceService : ResourceService
    ) {
        this.lodingLocation = false;
    }
    get form() { return this.feedbackForm.controls; }

    private fetchLocation() {
        this.lodingLocation = true;
        this.resourceService.locations()
            .pipe(finalize(() => { this.lodingLocation = false }))
            //.pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.locations = response;
            });
    }

    setRating(ratingValue: number) {
        this.feedbackForm.get('rating').setValue(ratingValue);
    }
    onSubmitFeedback() {
        if (this.feedbackForm.valid) {            
            const request = {
                advice: this.feedbackForm.value.comment,
                UmrNo: this.feedbackForm.value.mrn,
                rating: this.feedbackForm.value.rating,
                locationId: parseInt(this.feedbackForm.value.location),
            }

            this.httpService.post(
                ApiResources.getURI(ApiResources.patientWhatsappFeedback.base, ApiResources.patientWhatsappFeedback.add),
                request
            ).subscribe(
                    (response: any) => {
                    this.notifyService.successToast("Response Added Successfully");
                    this.feedbackForm.reset();
                    },
                    (error: any) => {
                        this.notifyService.errorToast("User does not Exist")
                    }
            );

        }
    }
    onClearModel() {
        this.feedbackForm.reset();
        this.notifyService.success("Form Cleared");
    }
   
   
    addFeedbackForm() {
        this.feedbackForm = this.formBuilder.group({
            mrn: ['', Validators.required],
            name: ['', Validators.required],
            mobileNumber: ['', [Validators.required, MobileValidator.isValid]],
            patientType: [null, Validators.required],
            rating: [null],
            comment: [''],
            location: ['']
        });
    }

    ngOnInit() {
        this.addFeedbackForm();
        this.fetchLocation();
    }
   
    
}