export class PharmacyProfitReport {

    supplierName: string;
    retailName: string;
    productName: string;
    batchNumber: string;
    quantityOut: number;
    purchaseRate: number;
    mrp: number;

    calPurchaseTotal: number;
    calMrpTotal: number;
    calProfitLoss: number;
   
}