export class ProviderBankDetails {
    amountPaid: number;
    accountNumber: number;
    email: string;
    fullName: string;
    percentage: number;
    transferType: string;
    totalItems: number;
    pendingAmount: string;
    providerId: number;
    expanded: boolean;

}