import { Patient, PatientEmergency, PatientInsurance, PatientFamily } from "@shared/entities"

export class PatientProfile {
    patient: Patient;
    emergencyContacts: Array<PatientEmergency>;
    insurances: Array<PatientInsurance>;
    relativeDetails: Array<PatientFamily>;
    patientFamily: PatientFamily;
    constructor() {
        this.patient = new Patient();
        this.emergencyContacts = [];
        this.insurances = [];
        this.patientFamily = new PatientFamily();
        this.relativeDetails = new Array<PatientFamily>();
    }
}