import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ICubicle } from '../entities';
import { IResource } from '../models';

@Injectable()
export class SettingService {

    private source = new Subject<boolean>();
    get: Observable<boolean> = this.source.asObservable();
    set(isRefresh: boolean) {
        this.source.next(isRefresh);
    }

    private consultantDoctor = new BehaviorSubject<IResource>(null);
    getConsultantDoctor: Observable<IResource> = this.consultantDoctor.asObservable();
    setConsultantDoctor(doctor: IResource) {
        this.selectedConsultantDoctor = doctor;
        this.consultantDoctor.next(doctor);
    }

    cubicles: Array<ICubicle> = new Array<ICubicle>();
    selectedCubicleId?: number = null;
    selectedConsultantDoctor?: IResource;
}
