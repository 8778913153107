export class CounsellingDetail {
    counsellingDetailId: number;
    counsellingId: number;
    packageModuleDetailId: number;
    quantity: number;
    amount?: number;
    isFree: boolean;
    active: boolean;
    createdBy: number;
    createdByName: string;
    createdDate: string;
    modifiedBy?: Date;
    modifiedByName: string;
    modifiedDate?: Date;
}