export class Settlements {
    providerId: number;
    providerName: number;
    email: number;
    noOfAppointments: number;
    amount: number;
    discount: number;
    providerPercentage: number;
    tDSAmount: number;
    appCharges: number;
    setteledAmount: number;
    totallItems: number;
}