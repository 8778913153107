import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "progressbar-modal-comp",
    template: `
                 <div class="modal-header">
    <h5 class="modal-title">Idle timeout</h5>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <h4>
                You're being timed out due to inactivity.<br />
                Please choose to stay logged in or to log off.<br />
                Otherwise you will be logged off automatically.
            </h4>
        </div>
        <div class="col-12">
            <div class="text-danger">
                <p> Your session expires in </p>
            </div>
        </div>
        <div class="col-12">
            <div class="d-flex justify-content-center">
                <div class="">                    
                    <div class="rounded-circle" style="width: 100px; height: 100px; background-color: #ff5959; color: white; text-align: center;">
                        <span style="margin-top: 29px !important;position: absolute; margin-left: -20px;font-size: 26px;">
                            <span [textContent]="countdown + 's'"></span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-light btn-xs mr-1" (click)="logout()">Logout</button>
    <button type="button" class="btn btn-primary btn-xs" (click)="stayLoggedIn()">Stay Logged In</button>
</div>
    `
})
export class ProgressBarModalComponent {
    @Input() countdown: number;

    constructor(public activeModal: NgbActiveModal) {
    }

    stayLoggedIn() {
        this.activeModal.close("stay");
    }

    logout() {
        this.activeModal.close("logout");
    }
}

