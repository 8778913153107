export class ProviderEncounter {
    providerGeneralAdviceId?: number;
    fullName?: string;
    providerId: number;
    generalAdviceIds?: string;
    icdIds?: string;
    labOrderValueIds?: string;
    radiologyValueIds?: string;
    allergyIds?: string;
    reactionIds?: string;
    hospitalizationIds?: string;
    pCareIds?: string;
    initialIds?: string;
    siteIds?: string;
    vaccineIds?: string;
    createdBy: number;
    createdDate: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    totalItems?: number;
}
