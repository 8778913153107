import { Injectable } from "@angular/core";
import { ApiResources, UtilHelper } from "../helpers";
//import { PrintSetting } from "../entities/print-setting.entity";
import { HttpService } from ".";
import { Setting } from "../entities";

@Injectable()
export class PrintOptionService {

    constructor(private readonly httpService: HttpService,) { }

    get(callback?: Function) {  

        this.httpService
            .get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), { name: "IsPrintLogo", active: true }, true)
            .subscribe(              
                (response:Array<Setting>) => {

                    if (response && response.length > 0 ) {
                        if (response[0].active) {
                            UtilHelper.removeWithOutLogoStyleFile();
                        } else {
                            //UtilHelper.addWithOutLogoStyleFile();
                        }
                        callback(response[0].active);
                    }
                },
                () => {
                    callback(true);
                }
            );
    }
}
