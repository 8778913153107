import { Component, OnDestroy, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";

import { Appointment } from "@shared/entities";
import { ApiResources } from "@shared/helpers";
import { IUserAccount } from "@shared/models";
import { HttpService, NotifyService, JitsiService, AppointmentToggleService, AppConfig } from "@shared/services";
import * as moment from "moment";
import { finalize } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
    selector: "aside-appointments-widget",
    styleUrls: ["./aside-appointments.widget.css"],
    templateUrl: "./aside-appointments.widget.html"
})
export class AsideAppointmentsWidget implements OnDestroy, OnInit {
    @Input() account: IUserAccount;

    showBox: number;
    loading: boolean;
    appointments: Array<Appointment>;
    toggleSubscriber: Subscription;
    encounters = ["encounter", "internal-medicine", "behavioral-health", "homeopathy", "dental-encounter"];
    providerId: number;
    practiceId: number;

    constructor(
        private readonly httpService: HttpService,
        private readonly router: Router,
        private readonly jitsiService: JitsiService,
        private readonly toggleService: AppointmentToggleService,
        private readonly notifyService: NotifyService
    ) {
        this.showBox = 1;
    }

    ngOnInit() {

        this.fetchAppointments();

        this.toggleSubscriber = this.toggleService.subscriber.subscribe(() => {
            this.toggle();
        });

    }

    toggle() {
        this.showBox = this.showBox === 1 ? 2 : 1;
        if (this.showBox === 2) {
            this.fetchAppointments();
        }
    }

    ngOnDestroy(): void {
        this.toggleSubscriber.unsubscribe();
    }

    private fetchAppointments() {

        const request = {
            fromDate: moment().format("YYYY-MM-DD"),
            toDate: moment().format("YYYY-MM-DD"),
            accountId: this.account ? this.account.accountId : 0,
            providerId: this.providerId,
            pageSize: 100,
            pageIndex: 1
        }

        this.httpService
            .post<Array<Appointment>>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.fetch), request)
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<Appointment>) => {
                    const records = response;
                    const urlTokens = this.router.url.split('/');
                    records.forEach((record) => {
                        if (urlTokens.length >= 4 && this.encounters.indexOf(urlTokens[2]) !== -1) {
                            const id = decodeURIComponent(urlTokens[3]);
                            const id2 = decodeURIComponent(record.encryptedAppointmentId);
                            record.active = id === id2;
                        }
                    });
                    this.appointments = records;
                },
                () => {
                    this.appointments = [];
                }
            );
    }

    onEncounter(encryptedAppointmentId: string, record: Appointment) {
        const urlTokens = this.router.url.split('/');
        if (decodeURIComponent(encryptedAppointmentId) !== decodeURIComponent(urlTokens[3]) &&
            this.jitsiService.isRoomActive) {
            this.notifyService.info("Please End/Close your video call with the patient.");
        } else {
            this.appointments.forEach(record => { record.isActive = false; });
            record.isActive = true;
            this.router.navigate([]).then(() => {
                location.assign(`${location.pathname}#/app/encounter/${encryptedAppointmentId}`);
                document.location.reload();
            });
        }
    }

    onBehavioralHealth(encryptedAppointmentId: string, record: Appointment) {
        const urlTokens = this.router.url.split('/');
        if (decodeURIComponent(encryptedAppointmentId) !== decodeURIComponent(urlTokens[3]) &&
            this.jitsiService.isRoomActive) {
            this.notifyService.info("Please End/Close your video call with the patient.");
        } else {
            this.appointments.forEach(record => { record.isActive = false; });
            record.isActive = true;
            this.router.navigate([]).then(() => {
                location.assign(`${location.pathname}#/app/behavioral-health/${encryptedAppointmentId}`);
                document.location.reload();
            });
        }
    }

    onHomeopathy(encryptedAppointmentId: string, record: Appointment) {
        const urlTokens = this.router.url.split('/');
        if (decodeURIComponent(encryptedAppointmentId) !== decodeURIComponent(urlTokens[3]) &&
            this.jitsiService.isRoomActive) {
            this.notifyService.info("Please End/Close your video call with the patient.");
        } else {
            this.appointments.forEach(record => { record.isActive = false; });
            record.isActive = true;
            this.router.navigate([]).then(() => {
                location.assign(`${location.pathname}#/app/homeopathy/${encryptedAppointmentId}`);
                document.location.reload();
            });
        }
    }

    onDentalEncounter(encryptedAppointmentId: string, record: Appointment) {
        const urlTokens = this.router.url.split('/');
        if (decodeURIComponent(encryptedAppointmentId) !== decodeURIComponent(urlTokens[3]) &&
            this.jitsiService.isRoomActive) {
            this.notifyService.info("Please End/Close your video call with the patient.");
        } else {
            this.appointments.forEach(record => { record.isActive = false; });
            record.isActive = true;
            this.router.navigate([]).then(() => {
                location.assign(`${location.pathname}#/app/dental-encounter/${encryptedAppointmentId}`);
                document.location.reload();
            });
        }
    }

    onInternalMedicine(encryptedAppointmentId: string, record: Appointment) {
        const urlTokens = this.router.url.split('/');
        if (decodeURIComponent(encryptedAppointmentId) !== decodeURIComponent(urlTokens[3]) &&
            this.jitsiService.isRoomActive) {
            this.notifyService.info("Please End/Close your video call with the patient.");
        } else {
            this.appointments.forEach(record => { record.isActive = false; });
            record.isActive = true;
            this.router.navigate([]).then(() => {
                location.assign(`${location.pathname}#/app/internal-medicine/${encryptedAppointmentId}`);
                document.location.reload();
            });
        }
    }

    onReport(encryptedAppointmentId: string, encounterType: string) {
        this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/full-transcript/${encounterType}/${encryptedAppointmentId}`, "_blank"); });
    }
    onPrescription(encryptedAppointmentId: string) {
        this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/prescription/${encryptedAppointmentId}`, "_blank"); });
    }

    joinCall(appointment: Appointment) {
        this.jitsiService.joinCall({
            roomName: appointment.appointmentNo
        });
    }
}