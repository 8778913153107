<div class="align-center d-flex">
    <div class="d-flex align-items-center justify-content-center flex-column text-center">
        <div class="avatar-sm overflow-hidden">
            <img *ngIf="patient.patient.thumbnailUrl" [src]="safe(patient.patient.thumbnailUrl)" class="rounded-circle avatar-sm" [alt]="patient.patient.fullName">
            <span *ngIf="!patient.patient.thumbnailUrl" class="avatar-title rounded-circle bg-primary text-white" [textContent]="patient.patient.fullName | initials"></span>
        </div>
    </div>

    <div class="encounter-profile-list p-0">
        <h5 class="mb-0 text-center">
            <a href="javascript:;" class="text-dark align-items-center d-flex gap10px text-dark white-space-nowrap">
                <span (click)="onViewPatientProfile(commonid)" class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="patient.patient.salutation +' '"></span>
                <span (click)="onViewPatientProfile(commonid)" class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="patient.patient.fullName"></span>
                <span class="d-block font-13 my-1"
                      [textContent]=" (patient.patient.dateOfBirth != null ? (patient.patient.dateOfBirth | age:'days') : patient.patient.age == null? 'null' : patient.patient.age + ' yrs')+'/'+( patient.patient.gender !=null ? patient.patient.gender:null )"></span>
                <small class="d-block font-13 my-1 align-center"
                       [textContent]="patient.patient.umrNo || '--'"></small>
                <small class="d-block font-13 my-1 align-center">
                    <i class="mdi mdi-phone text-primary"></i><sup class="text-primary mr-1">1</sup>
                    <span [textContent]="patient.patient.mobile ? ('(+'+ patient.patient.countryCode +') '+ patient.patient.mobile) : 'No mobile recorded'"></span>
                </small>
                <small class="d-block font-13 my-1 align-center">
                    <i class="mdi mdi-phone text-primary"></i><sup class="text-primary mr-1">2</sup>
                    <span [textContent]="relativeMobile ? ('(+'+ patient.patient.countryCode +') '+relativeMobile) : 'No mobile recorded'"></span>
                </small>
                <small class="d-block font-13 my-1 align-center">
                    <i class="mdi mdi-at text-primary mr-1"></i>
                    <span [textContent]="patient.patient.email || 'No email recorded'"></span>
                </small>
                <small *ngIf="!isFromCallCentre && admissions && admissions.length > 0 && !admissions[0].isDischarged" class="d-block font-13 my-1 align-center">
                    <i class="mdi mdi-account text-primary mr-1"></i>
                    <span [textContent]="admissions[0].admissionNo"></span>
                </small>
                <small *ngIf="!isFromCallCentre && admissions && admissions.length > 0 && !admissions[0].isDischarged" class="d-block font-13 my-1 align-center">
                    <i class="mdi mdi-bed text-primary mr-1"></i>
                    <span [textContent]="admissions[0].bedNumber"></span>
                </small>
                <small *ngIf="!isFromCallCentre && admissions && admissions.length == 0" class="d-block font-13 my-1 align-center">
                    <i class="mdi mdi-account text-primary mr-1"></i> OP
                </small>
            </a>
        </h5>
    </div>
</div>