export class ChargeManagement { 
    departmentId: number;
    chargeGroupId: number;
    chargeId: number;
    cost: number;
    departmentName: string;
    chargeGroupName: string;
    chargeName: string;
    isShow: boolean;
    totalItems: number;
    repeatTypeName: string;
    repeatTypeId: number;
    fromDate: Date;
    toDate: Date;
    locationIds: string;
    deptType: string;
    isAutomaticAdd: boolean;
    createdBy: number;
    createdDate: Date;  
    modifiedBy?: number;
    modifiedDate?: Date;
    createdByName: string;
    modifiedByName?: number;
    createdByRole: string;
    modifiedByRole: string;
}