
import { Component, OnDestroy, OnInit, ViewEncapsulation, TemplateRef } from "@angular/core";
import { AppData, HttpService, NotifyService } from "@shared/services";
import { takeUntil, finalize } from "rxjs/operators";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Page, IUserAccount, Pagination, IResource } from "@shared/models";
import Swal from "sweetalert2";
import { ResourceService } from "@shared/services";
import { ApiResources } from "@shared/helpers";
import { ConsentForms } from "../../../../shared/entities/consent-forms.entity";
import { DomSanitizer } from "@angular/platform-browser";
import { AdmissionFetchHelper } from "../../progress-report/shared/helper";
import { IAdmissionModel } from "../../services/models/admission.model";
import { ActivatedRoute, Params } from "@angular/router";
import { Patient } from "../../../../shared/entities/";
class TrackBy {
    role(item: ConsentForms) {
        return item.consentFormsId;
    }
}
@Component({
    templateUrl: "./consent-forms-main.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["./consent-forms-main.css"]

})
export class ConsentFormsMainPage implements OnInit, OnDestroy {

    page: Page;
    trackBy: TrackBy;
    modalRef: NgbModalRef;
    submitting: boolean;
    submitted: boolean;
    loading: boolean;
    consentForm: FormGroup;
    request: any;
    consents: Array<ConsentForms>;
    pagination: Pagination;
    consentId: number
    loadingConsentForms: boolean
    consentForms: Array<IResource>;
    formName: any;
    htmlViewLoaded = false;
    htmlView: any
    FormsData: Array<ConsentForms>
    view: any;
    admissionId: string;
    isAdmission: boolean;
    record: IAdmissionModel;
    encPatientId: string;
    isPrintLogo: boolean;
    patient: Patient;
    isFooter: boolean;
    constructor(
        private readonly admissionFetchHelper: AdmissionFetchHelper,
        private readonly appData: AppData,
        private readonly formBuilder: FormBuilder,
        private readonly httpService: HttpService,
        private readonly modalService: NgbModal,
        private readonly notifyService: NotifyService,
        private readonly resourceService: ResourceService,
        private sanitizer: DomSanitizer,
        private readonly route: ActivatedRoute,

    ) {
        this.loading = true;
        this.page = new Page();
        this.trackBy = new TrackBy();
        this.loading = true;
        this.consents = new Array<ConsentForms>();

    }

    private initPagination() {
        this.pagination = new Pagination();
        this.pagination.pageIndex = 1;
        this.pagination.pageSize = 10;

    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchConsents();
                    this.findPatientId();
                    this.findPatientDetails();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
        Swal.close()
    }
    onOpenModal(content: TemplateRef<any>): void {
        this.consentId = null
        this.modalRef = this.modalService.open(content, {
            backdrop: "static", keyboard: false, centered: true, size: "xl", windowClass: "custom-modal effect-scale"
        });
        this.fetchConsents();
    }
    onCloseModal() {
        this.modalRef.close();
        this.modalRef = undefined;
    }
    getcounsellingForms() {
        const request = { Active: true };
        this.httpService.post<ConsentForms>(ApiResources.getURI(ApiResources.consentForms.base, ApiResources.consentForms.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: any) => {
                this.FormsData = response;
                let consents = new Array<number>();
                const modules = this.FormsData.filter(m => consents.includes(m.consentFormsId));
                this.FormsData = modules;
            });
    }
    fetchConsents() {
        this.loading = true;
        const request = {
        };
        this.httpService.post(ApiResources.getURI(ApiResources.consentForms.base, ApiResources.consentForms.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<ConsentForms>) => {
                    if (response.length > 0) {
                        this.consents = response;
                    }
                },
          );
    }
    selectForm(item) {
        this.htmlView = null
        this.consentId = item;
        let data = this.consents.filter(x => x.consentFormsId == this.consentId);
        this.view = data[0].view;
        this.htmlView = this.sanitizer.bypassSecurityTrustHtml(this.view);

        this.loadHtmlContent().then(() => {

            (window as any).patientdata();
        });
    }
    loadHtmlContent(): Promise<void> {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve();
            }, 1000);
        });
    }

    print() {
        if (this.htmlView) {
            const printContent = this.htmlView.changingThisBreaksApplicationSecurity;
            const printWindow = window.open('', '_blank');
            if (printWindow) {
                printWindow.document.open();
                printWindow.document.write(`
                <html>
                <head>
                    <title>Print</title>
                </head>
                <body>
                    ${printContent}
                </body>
                </html>
            `);
                printWindow.document.close();
                printWindow.print();
                printWindow.close();
            } else {
                console.error('Failed to open print window.');
            }
        }
    }
    parseHtml(htmlContent: string): string {
        return htmlContent;
    }
    private findPatientId(): void {
        this.route.parent.paramMap
            .subscribe((params: Params) => {
                this.encPatientId = params["params"]["pid"];
                this.fetchConsents();
            });
    }
    private findPatientDetails() {
        const request = { encryptedPatientId: this.encPatientId };
        this.httpService.post<Patient>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.find), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Patient) => {
                this.patient = response;
                window["RawPatientData"] = this.patient

            });
    }
    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
}