import { Component, ViewEncapsulation, OnInit, OnDestroy, Input } from "@angular/core";
import { IUserAccount, Page } from "@shared/models";
import { HttpService, PrintOptionService, BannerService, AppData } from "@shared/services";
import { ImageReports, Appointment, PayTypes } from "@shared/entities";
import { ApiResources } from "@shared/helpers";
import { finalize, takeUntil } from "rxjs/operators";
import * as converter from "number-to-words";

export class RegistrationBill {
    patientRegistrationDetailId: number;
    charge: number;
    patientId?: number;
    createdBy: number;
    createdDate: Date;
    createdByName: string;
    patientName?: string;
    gender: string;
    age?: number;
    payTypeId?: number;
    payTypeName: string;
    umrNo?: string;
    mobile?: string;
    receiptId?: any;
    receiptNo?: any;
    chargeInWords?: string;
    paymentDetails?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    dateOfBirth?: Date;
    ageAndGender: string;
    amount: number;
    aptAmount: number;
    aptDiscount: number;
    aptTotal: number;
    patmount: number;
    patTotal: number;
    patDiscount: number;
    referenceId: number;
}

@Component({
    templateUrl: "./registration-invoice.html",
    selector: "registration-invoice",
    encapsulation: ViewEncapsulation.None
})
export class RegistrationInvoiceWidget implements OnInit, OnDestroy {
   
    @Input() appointmentId: number;
   
    page: Page;
    loading: boolean;
    bannerBasics: ImageReports;
    myImgUrl = "assets/images/careaxesLogo.png";
    @Input() isPrintLogo: boolean;
    @Input() isFooter: boolean;
    isShowInvoice = true;

    registrationBill: RegistrationBill;
    noReceipt = false;
    todayDate = new Date();

    selectedAppointment: Appointment;

    constructor(
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly bannerService: BannerService,
        private readonly appData: AppData,

    ) {
        this.page = new Page();
        this.registrationBill = new RegistrationBill();
        this.selectedAppointment = new Appointment();
    }

    onChangeInvoiceType(type: boolean) {
        this.isShowInvoice = type;
    }
    //reg
    private fetchReg() {
        this.loading = true;
        const request = {
            appointmentId: this.appointmentId
        };
       
        this.httpService.get(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.fetchRegistrationBill), { id: this.appointmentId ,isAppointmentId : true })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: RegistrationBill) => {
                                
                if (response) {
                   
                    this.registrationBill = response;
                   this.todayDate = new Date();
                    this.registrationBill.chargeInWords = converter.toWords(this.registrationBill.charge);
                    this.registrationBill.receiptNo = String(this.registrationBill.receiptId).padStart(6, '0');
                }
              
            });
    }
    //appwithall
    private fetch() {
        this.loading = true;
        const request = {
            appointmentId: this.appointmentId
        };



        this.httpService
            .post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.fetchForInvoice), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (response: Appointment) => {                   
                    this.selectedAppointment = new Appointment();
                    this.selectedAppointment.payTypes = new Array<PayTypes>();
                    this.selectedAppointment = response;
                    this.selectedAppointment.payTypes = response.payTypes;
                    if (response == null) {
                        this.noReceipt = true;
                    }
                    var cloneAppointment = JSON.parse(JSON.stringify(this.selectedAppointment));
                    this.selectedAppointment.fullAddress = [cloneAppointment.addressLine2, cloneAppointment.streetAddress, cloneAppointment.city, cloneAppointment.state, cloneAppointment.zipcode].filter(Boolean).join(", ");
                    this.selectedAppointment.aadharNo = [cloneAppointment.aadharNo].filter(Boolean).join(",")

                    if (response.receiptId) {
                        response.receiptNo = String(response.receiptId).padStart(6, '0');

                    }
                    this.selectedAppointment.amountInWord = converter.toWords(this.selectedAppointment.total);

                    if (response.referenceId) {

                        response.amountFinal = response.amount + response.patAmount
                        response.amountInWord = converter.toWords(response.amountFinal);
                    } else {
                        response.amountInWord
                        response.amountFinal = response.amount;
                        response.amountInWord = converter.toWords(response.amountFinal);
                    }

                },
                () => {
                    this.selectedAppointment = new Appointment();
                }
            );
    }

    ngOnInit() {

        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;

                    this.fetch();
                    this.fetchReg();
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                    this.bannerService.getBannerImage((is) => { this.bannerBasics = is; });

                } else {
                    this.page.userAccount = undefined;
                }
            })
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
}

