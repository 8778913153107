export class InventoryWareHouse {
    inventoryWareHouseId: number;
    name: string;
    createdByName: string;
    createdByRole: string;
    modifiedByName: string;
    modifiedByRole: string;
    createdBy: number;
    modifiedBy?: number
    createdDate: Date;
    modifiedDate?: Date;
    allowedAccountId: string;
    users: Array<AllowedAccounts>;
    active: boolean;
    locationId?: number;

    constructor() {
        this.users = new Array<AllowedAccounts>();
    }
}

export class AllowedAccounts {
    moduleDepartmentUserId: number;
    accountId: number;
    inventoryWareHouseId: number;
    fullName: string;
    roleName: string;
}
