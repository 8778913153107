
import { HttpService } from '../services/http.service';
import { NotifyService } from '../services/notify.service';


export class SmsService {

    constructor() {

    }

    sendMessageAsync(body: Record<any, any>, httpService: HttpService, notifyService: NotifyService) {
        const settings = JSON.parse(localStorage.getItem("settings"));
        var url = settings.whatsAppService.base + "sendSms";
        var contactNumber = settings.whatsAppService.contactNumber;
        var foundationName = settings.whatsAppService.practiceName;

        body['FoundationName'] = foundationName;
        body['PracticeName'] = foundationName;
        body["ContactNumber"] = contactNumber;
        
        httpService.post(url, body).subscribe((response: any) => {
            notifyService.successToast("The  message has sent !!")
        },
            (error: any) => {
                notifyService.errorToast("Could not send  message" + error); console.log(error)
            });
    }


    sendEmailAsync(body: Record<any, any>, httpService: HttpService, notifyService: NotifyService) {
        const settings = JSON.parse(localStorage.getItem("settings"));
        var url = settings.whatsAppService.base + "SendEmail";
        var contactNumber = settings.whatsAppService.contactNumber;
        var foundationName = settings.whatsAppService.practiceName;
        var Source = settings.EmailService.AppointmentEmail;
        var Passcode = settings.EmailService.AppointmentCode;
        

        body['FoundationName'] = foundationName;
        body['PracticeName'] = foundationName;
        body["ContactNumber"] = contactNumber;
        body["Source"] = Source;
        body["Passcode"] = Passcode;

        httpService.post(url, body).subscribe((response: any) => {
            notifyService.successToast("The  message has sent !!")
        },
            (error: any) => {
                notifyService.errorToast("Could not send  message" + error); console.log(error)
            });
    }
}