import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { takeUntil, finalize } from "rxjs/operators";
import { IUserAccount, Page, GenericResponse, GenericStatus, IResource } from "@shared/models";
import { AppData, HttpService, NotifyService, ResourceService } from "@shared/services";
import { ApiResources, LinqHelper } from "../../../../../shared/helpers";
import { INurseModel, DateHolder, IBasicBed } from "../../helpers/helper";
import { NgbCalendar, NgbDate, NgbModal, NgbModalRef, NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { NurseShiftAssignPage } from "../shift-assign/shift-assign.page";

@Component({
    templateUrl: "./nurse.html",
    styleUrls: ['./nurse.css', '../../nurse-module.css']
})
export class NursePage implements OnInit, OnDestroy {
    page: Page;
    loading: boolean;
    dates: Array<DateHolder>;
    records: Array<INurseModel>;
    availableSpace: number;
    noOfDatesToDisplay = 6;
    mainForm: FormGroup;
    copyForm: FormGroup;
    locations: Array<IResource>;
    locationSubscription: Subscription;
    selectedNurses: Array<INurseModel>;
    modalRef: NgbModalRef;
    hoveredDate: NgbDate | null = null;
    loadingCopyShifts: boolean;
    shiftLoading: boolean;
    shifts: Array<IResource>;
    nurseAccountEncryptId: string;
    @ViewChild(NurseShiftAssignPage) assignShiftPage: NurseShiftAssignPage;

    constructor(
        private readonly httpService: HttpService,
        private readonly appData: AppData,
        private readonly calendar: NgbCalendar,
        private readonly notifyService: NotifyService,
        private readonly formBuilder: FormBuilder,
        private readonly resourceService: ResourceService,
        private readonly modalService: NgbModal,
    ) {
        this.availableSpace = window.innerHeight - 589;
        this.records = new Array<INurseModel>();
        this.selectedNurses = new Array<INurseModel>();
        this.locations = new Array<IResource>();
        this.loading = true;
        this.page = new Page();

        this.setTodayDates();

        this.buildForm()
        this.buildCopyForm()
    }

    setTodayDates = () => {
        this.dates = new Array<DateHolder>();
        let initialDate = new Date();
        initialDate.setDate(initialDate.getDate() + (1 - (initialDate.getDay() || 7)));
        var date = { year: initialDate.getFullYear(), month: initialDate.getMonth() + 1, day: initialDate.getDate() } as NgbDate;
        this.dates.push({
            date: date,
            eDate: window.btoa(JSON.stringify({ year: date.year, month: date.month, day: date.day })),
            fDate: new Date(date.year, date.month - 1, date.day)
        } as DateHolder);

        Array.from(Array(this.noOfDatesToDisplay)).forEach(() => {
            var date = this.calendar.getNext(this.dates[this.dates.length - 1].date);
            this.dates.push({
                date: date,
                eDate: window.btoa(JSON.stringify({ year: date.year, month: date.month, day: date.day })),
                fDate: new Date(date.year, date.month - 1, date.day),
                isToday: date.equals(this.calendar.getToday())
            } as DateHolder);
        });
    }

    buildForm = () => {
        this.mainForm = this.formBuilder.group({
            locationId: [],
            type: ['last_week'],
            selected: new FormArray([]),
        });

        this.locationSubscription = this.mainForm.get("locationId").valueChanges.subscribe((id: number) => {
            this.fetch(id);
        });
    }

    buildCopyForm = () => {
        this.copyForm = this.formBuilder.group({
            fromType: [null],
            customFrom: [null],
            customTo: [null],
            until: [null],
            type: ['last_week']
        });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }

    ngOnInit() {
        this.fetchLocations();
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.mainForm.patchValue({
                        locationId: this.page.userAccount.locationId
                    }, { emitEvent: false });
                    this.fetchShifts(() => {
                        this.fetch();
                    });
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    onDateSelection(date: NgbDate) {
        let customFrom = this.copyForm.get("customFrom");
        let customTo = this.copyForm.get("customTo");
        if (!customFrom.value && !customTo.value) {
            customFrom.setValue(date);
        } else if (customFrom.value && !customTo.value && date.after(customFrom.value) || date.equals(customFrom.value)) {
            customTo.setValue(date);
        } else {
            customFrom.setValue(null);
            customTo.setValue(date);
        }
    }

    isHovered(date: NgbDate) {
        let customFrom = this.copyForm.get("customFrom");
        let customTo = this.copyForm.get("customTo");
        return (
            customFrom.value && !customTo.value && this.hoveredDate && date.after(customFrom.value) && date.before(this.hoveredDate)
        );
    }

    isInside(date: NgbDate) {
        let customFrom = this.copyForm.get("customFrom");
        let customTo = this.copyForm.get("customTo");
        return customTo.value && date.after(customFrom.value) && date.before(customTo.value);
    }

    isRange(date: NgbDate) {
        let customFrom = this.copyForm.get("customFrom");
        let customTo = this.copyForm.get("customTo");
        return (
            date.equals(customFrom.value) ||
            (customTo.value && date.equals(customTo.value)) ||
            this.isInside(date) ||
            this.isHovered(date)
        );
    }

    openModal = (content: TemplateRef<any>, record?: INurseModel) => {
        if (record) {
            this.selectedNurses.push(record);
        } else {
            let ids = ((this.mainForm.get("selected") as FormArray).value as Array<any>).filter(x => !!x.is).map(x => x.id);
            let records = this.records.filter(x => !!ids.includes(x.accountId)).slice();
            this.selectedNurses = records;
        }
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal effect-scale"
        });
    }

    close = () => {
        this.modalRef.close()
        this.selectedNurses = [];
    }

    private fetchLocations() {
        this.resourceService.locations()
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.locations = response;
            });
    }

    get isTodayExists() {
        return !!this.dates.find(x => x.isToday)
    }

    next = () => {
        var lastDate = this.dates[this.dates.length - 1].date;
        this.dates = new Array<DateHolder>();
        Array.from(Array(this.noOfDatesToDisplay + 1)).forEach(() => {
            var date = this.dates.length > 0
                ? this.calendar.getNext(this.dates[this.dates.length - 1].date)
                : this.calendar.getNext(lastDate);
            this.dates.push({
                date: date,
                eDate: window.btoa(JSON.stringify({ year: date.year, month: date.month, day: date.day })),
                fDate: new Date(date.year, date.month - 1, date.day),
                isToday: date.equals(this.calendar.getToday())
            } as DateHolder);
        });

        this.fetch();
    }

    goToToday = () => {
        this.setTodayDates();
        this.fetch();
    }

    previous = () => {
        var firstDate = this.dates[0].date;
        this.dates = new Array<DateHolder>();
        Array.from(Array(this.noOfDatesToDisplay + 1)).forEach(() => {
            var date = this.dates.length > 0
                ? this.calendar.getNext(this.dates[this.dates.length - 1].date)
                : this.calendar.getPrev(firstDate, 'd', this.noOfDatesToDisplay + 1);
            this.dates.push({
                date: date,
                eDate: window.btoa(JSON.stringify({ year: date.year, month: date.month, day: date.day })),
                fDate: new Date(date.year, date.month - 1, date.day),
                isToday: date.equals(this.calendar.getToday())
            } as DateHolder);
        });

        this.fetch();
    }

    onCopyShifts = () => {
        if (this.loadingCopyShifts) return;
        this.loadingCopyShifts = true;
        var data = {
            nurseAccountIds: ((this.mainForm.get("selected") as FormArray).value as Array<any>).filter(x => !!x.is).map(x => x.id),
            type: this.mainForm.value.type
        }

        this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.nurseShift.base, ApiResources.nurseShift.copy), data)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loadingCopyShifts = false; }))
            .subscribe((response: GenericResponse) => {
                if (response.status === GenericStatus[GenericStatus.Success]) {
                    this.fetch();
                } else {
                    this.notifyService.defaultError();
                }
            });
    }

    deleteSlot = (item: INurseModel, subItem: DateHolder) => {



        if (subItem.isDeleting) return;
        subItem.isDeleting = true;

        var deleteHelper = () => {

            var data = {
                id: item.accountEncryptId,
                createdBy: this.page.userAccount.accountId,
                isSingle: true,
                isRemove: true,
                shifts: [
                    {
                        nurseShiftMapId: subItem.nurseShiftMapId,
                        isRemove: true,
                        fromDate: subItem.date,
                        toDate: subItem.date,
                        shiftId: subItem.shiftId,
                        removedBedIds: [],
                        bedIds: [],
                        addedBedIds: []
                    }
                ]
            }

            this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.nurseShift.base, ApiResources.nurseShift.update), data)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => { subItem.isDeleting = false; }))
                .subscribe((response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.fetch();
                    } else {
                        this.notifyService.defaultError();
                    }
                });
        }

        this.notifyService.confirm("Are you sure, you want to delete?", () => {
            deleteHelper();
        }, () => {
            subItem.isDeleting = false;
        })
    }

    get selectedCount() {
        return ((this.mainForm.get("selected") as FormArray).value as Array<any>).filter(x => !!x.is).length > 0
    }

    check = (index: number) => {
        let control = (this.mainForm.get("selected") as FormArray).controls[index]
        control.patchValue({ is: !control.value["is"] });
    }

    private fetch(locationId: number = null) {
        var data = {
            fromDate: this.dates[0].date,
            toDate: this.dates[this.dates.length - 1].date,
            nurseId: this.page.userAccount.accountId,
            locationId: locationId || this.mainForm.value.locationId
        };

        this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.nurseModule.base, ApiResources.nurseModule.fetch), data)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: GenericResponse) => {
                var formArray = this.mainForm.get("selected") as FormArray;
                formArray.clear();
                var records = response.data as Array<INurseModel>;
                records.forEach(x => {
                    x.generalDate = new Date(x.generalDate);
                    x.date = new NgbDate(x.generalDate.getFullYear(), x.generalDate.getMonth() + 1, x.generalDate.getDate());
                })
                var accountIds = records.map(x => x.accountId).filter(LinqHelper.uniqueOnly);
                var mappedRecords = accountIds.map((x, i) => {
                    var list = records.filter(y => y.accountId == x);
                    var parentPercentage = Math.ceil((100 / accountIds.length) * (1 + i));
                    var totalHours = 0;
                    var record = {
                        accountId: x,
                        bedIds: list[0].bedIds,
                        fullName: list[0].fullName,
                        rowColor: list[0].rowColor,
                        roleName: list[0].roleName,
                        accountEncryptId: list[0].accountEncryptId,
                        thumbnailUrl: list[0].thumbnailUrl,
                        dates: LinqHelper.cloneDeepArray<DateHolder>(this.dates.map((y, j) => {
                            var found = list.find(z => z.date.equals(y.date));
                            var childPercentage = Math.ceil((100 / this.dates.length) * (1 + j));

                            var first = "";
                            var second = "";

                            if (parentPercentage <= 33) {
                                second = "top";
                                if (childPercentage <= 33) {
                                    first = "right";
                                } else if (childPercentage >= 66) {
                                    first = "left"
                                } else {
                                    first = "right";
                                }
                            }

                            if (parentPercentage >= 66) {
                                second = "bottom";
                                if (childPercentage <= 33) {
                                    first = "right";
                                } else if (childPercentage >= 66) {
                                    first = "left"
                                } else {
                                    first = "right";
                                }
                            }

                            if (parentPercentage > 33 && parentPercentage < 66) {
                                if (childPercentage <= 33) {
                                    first = "right";
                                } else if (childPercentage >= 66) {
                                    first = "left"
                                } else {
                                    first = "right";
                                }
                            }

                            var all = list.filter(z => z.date.equals(y.date));
                            var allBedDetails = [];
                            all.forEach(q => {
                                if (q.bedDetails) {
                                    allBedDetails.push(...JSON.parse(q.bedDetails))
                                }
                            })
                            var shiftBeds = LinqHelper.groupBy(allBedDetails, (xx) => xx.shiftId);
                            var shifts = [];
                            shiftBeds.forEach(x => {
                                shifts.push({
                                    shiftId: x[0].shiftId,
                                    shiftName: x[0].shiftName,
                                    bedIds: x.map(y => y.bedId).filter(LinqHelper.uniqueOnly),
                                    rowColor: x[0].rowColor
                                })
                                totalHours += this.shifts.find(r => r.id == x[0].shiftId)?.duration || 0
                            })

                            return {
                                shiftBeds: shifts,
                                placement: first ? first + (second ? "-" + second : "") : "auto",
                                date: y.date,
                                fDate: y.fDate,
                                eDate: y.eDate,
                                isToday: y.isToday,
                                isExists: !!shifts.length,
                                bedCount: (found && found.bedIds.length) || 0,
                                bedIds: list[0].bedIds,
                                shiftName: (found && found.shiftName) || null,
                                shiftId: (found && found.shiftId) || null,
                                nurseShiftMapId: (found && found.nurseShiftMapId) || null
                            } as DateHolder
                        }))
                    } as INurseModel;
                    record.totalHours = totalHours;
                    formArray.push(new FormGroup({
                        id: new FormControl(record.accountId),
                        is: new FormControl(false)
                    }));
                    return record;
                });

                this.records = mappedRecords;
            },
                () => {
                    this.records = new Array<INurseModel>();
                }
            );
    }

    private fetchBeds(value: number, item: DateHolder, bedDetail: Array<number>) {
        this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.nurseShift.base, ApiResources.nurseShift.fetchBasicBeds), { value: value, date: item.date, locationId: this.mainForm.value.locationId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => item.loadingBeds = false))
            .subscribe((response: GenericResponse) => {
                item.beds = new Array<IBasicBed>();
                if (GenericStatus[GenericStatus.Success] === response.status) {
                    let beds = [] as Array<IBasicBed>;
                    response.data.forEach(x => {
                        if (bedDetail.includes(x.bedId) && !beds.find(y => y.bedId === x.bedId)) {
                            beds.push({ ...x });
                        }
                    });
                    item.beds = beds;
                }
            },
                () => {
                    this.notifyService.defaultError()
                }
            );
    }

    openPopover = (item: INurseModel, subItem: DateHolder, shiftBeds: any, popover: NgbPopover = null) => {
        this.records.forEach(x => x.dates.forEach(this.closePopover));
        if (!subItem.isExists) return;

        subItem.popover = popover;
        popover.open();

        // if (subItem.beds && subItem.beds.length === shiftBeds.length && subItem.beds.every(x => shiftBeds.bedIds.incluse(x.bedId))) return;
        subItem.loadingBeds = true;

        setTimeout(() => {
            this.fetchBeds(item.accountId, subItem, shiftBeds.bedIds);
        })
    }

    closePopover = (item: DateHolder) => {
        item.popover && item.popover.isOpen() && item.popover.close();
    }

    onOpenModel(content: TemplateRef<any>, accountEncryptId: string) {
        this.nurseAccountEncryptId = accountEncryptId;
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "xl",
            windowClass: "custom-modal slots-modal effect-scale"
        });
    }

    private fetchShifts(callback?: Function) {
        this.shiftLoading = true;
        this.resourceService.fetchShifts(true)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.shiftLoading = false))
            .subscribe((response: Array<IResource>) => {
                this.shifts = response.filter(x => !!x.optionalText);
                callback && callback()
            });
    }

    openSelectBed(subItem: DateHolder, mainItem: INurseModel) {
        this.assignShiftPage.triggerModal(subItem.date, mainItem.accountEncryptId);
    }

    onBedAddComplete(isAdded: any) {
        if (isAdded) {
            this.fetch();
        }
    }
}