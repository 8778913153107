export class LabHeaders {
    labHeaderId: number;
    labCode: string;
    signature: string;
    labName: string;
    charge: number;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    noOfParameter: number;
    createdByName: string;
    totalItems: number
    inPatientCharge: number;
    modifiedBy?: number;
    modifiedDate?: Date;
    modifiedByName: string;
    isSelected: boolean;
    discountPercentage?: number;
    discountAmount?: number;
    netAmount: number;
    //Modified 
    labPackageDetailId: number;
    labPackageId: number;
    testParameter: string;
    labData: Array<string>;
    status: boolean;
    deleting: boolean;
    unit: number;
    departmentName: string;
    locationName: string;
    labDepartmentId: number;
    patientLabDetailId?: number;
}