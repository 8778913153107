export enum Role {
    SuperAdmin = 1,
    Administrator,
    Provider,
    Patient,
    Admin,   
    Support,
    Nurse,
    Receptionist,
    Accountant,
    SymptomCreator,
    SymptomManager
}
