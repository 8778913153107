export class ExternalLabAgency {
    externalLabAgencyId: number;
    totalItems: number;
    createdBy: number;
    modifiedBy?: number;
    agencyName: string;
    agencyCode: string;
    address: string;
    url: string;
    createdByName: string;
    modifiedByName: string;
    createdDate: Date;
    modifiedDate?: Date;
    active: boolean;
    statusChanging?: boolean;
    locationId: number;
    labs: Array<ExternalLabAgencyDetail>;
    createdByRole: string;
    modifiedByRole: string;
    contactNumber: string;
    emailAddress: string;
    constructor() {
        this.labs = new Array<ExternalLabAgencyDetail>();
    }
}

export class ExternalLabAgencyDetail {
    externalLabAgencyDetailId: number;
    externalLabAgencyId: number;
    locationId: number;
    createdBy: number;
    modifiedBy?: number;
    labMainDetailId: number;
    testName: string;
    amount: number;
    createdByName: string;
    modifiedByName: string;
    createdDate: Date;
    modifiedDate?: Date;
    active: boolean;
    statusChanging?: boolean;
}