export enum BehavioralHealthType {
    ProblemHistory,
    MedicalHistory,
    CurrentSupport,
    CurrentLiving,
    EducationalHistory,
    ClientStrengths,
    OutComeMeasures,
    SubstanceHistory,
    WorkHistory,
    AbuseHistory,
    ReligionSpirituality,
    MentalStatus,
    FamilyRelationships,
    RelapsePredictors,
    PatientQuestionnaire,
    GeneralizedAnxietyDisorder,
    BurnsDepression,
    Aims,
    Genogram,
    Notes,
    Vitals,
    ReferralProvider,
    FollowUp,
    Medications,
    TreatmentPlan,
    MedicationComments,
    GeneralAdvice
}