export class TicketDetail {
    ticketId: number;
    ticketTimelineId: number;
    supportCategoryId: number;
    raisedBy: number;
    countryId: number;
    ticketNumber: string;
    priority: string;
    issueWith: string;
    description: string;
    attachment: string;
    raisedByName: string;
    supportConversation: string;
    mobile: string;
    status: string;
    startDate: Date;
    endDate: Date;
    dueDate: Date;
    dueTime: Date;
    dueDateTime: string;
    assigneeStartDate: Date;
    assigneeEndDate: Date;
    createdDate: Date;
    assignedDate: Date;
    assignedTo: number;
    createdBy: number;
    assigneeDescription: number;
    conversation: string;
    assignedById: number;
    assigneeStatus: number;
    assigneeName: string;
    assigneeThumbnailUrl: string;
    comments: string;
    instruction: string;
    ticketOwner: string;
    ticketOwnerId: number;
    raisedByRole: string;
    newAssigneeName: string;
    assigneeAccountId: number;
}