export class Feedback {
    feedbackId: number;
    ratingStatus: string;
    ratingId: number;
    mobile: number;
    patientId: number;
    fullName: string;
    totalItems: number;
    createdDate: Date;
    questionFeedbackId: number;
    question: string;
    active: boolean;
}
