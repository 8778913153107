export class SurgeonChargeMastermodel {
    surgeonChargeMasterId: number;
    name: string;
    active: boolean;
    createdBy: number;
    createdByName: string;
    createdDate: Date;
    modifiedBy: number;
    modifiedByName: string;
    modifiedDate: Date;
    modulesMasterId: number;
}