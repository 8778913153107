export class DynamicReport {

    dynamicReportId: number;
    name: string;
    data: string;
    query: string; 
    status: string;
    active: boolean;

    createdBy: number;
    modifiedBy: number;

    createdByName: string;
    modifiedByName: string;
    createdByRole: string;
    modifiedByRole: string;

    totalItems: number;
    encryptedDynamicReportId: string;
}
