import { Component, Input, OnDestroy, OnInit, } from '@angular/core';

import { Appointment, PreviousAppointment, } from '../../entities';
import { ApiResources, UtilHelper } from '../../helpers';
import { IEncounterResource, IUserAccount, Page, GenericResponse, GenericStatus } from '../../models';
import { AppData, EncounterCommunication, HttpService, TimelineToggleService, NotifyService } from '../../services';
import { Subscription, finalize, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { OB } from '../../../areas/admin/full-transcript/pages/ob-report/ob.namespace';
import { OBEncounterType } from '../../enums';
import { IvfEncounter } from '../../models/ivf-encounter.model';
import moment from "moment";
import { HttpErrorResponse } from "@angular/common/http";
class LookUpModel {
    id: number;
    name: string;
    value: any;
    followUpDays: any;
    followUpDaysLimit: any;
}
@Component({
    selector: 'ivf-header',
    templateUrl: './ivf-header.html',
    styleUrls: ['./ivf-header.css']
})
export class IVFHeaderComponent implements OnDestroy, OnInit {
    @Input() patientId: string;
    @Input() appointmentId: string;
    appointment: Appointment;
    page: Page;
    isAdmission: any;
    roleId: number;
    specialFeature: OB.SpecialFeature;
    encounterCommunicationSubscription: Subscription;
    obEncounter: IEncounterResource;
    appointments: any;
    sideNav: boolean;
    allergies: OB.Allergies;
    ivfEncounter: IvfEncounter;
    encryptedPatientId: string;
    antinantalRisk: OB.AntinantalRisk;
    submitting: boolean;
    date: Date;
    patient: any;
    submitted: boolean;
    isVisitTypeloading: boolean;
    visitors: Array<LookUpModel>;
    chargeType: Array<LookUpModel>;
    findOPVisitType: any;
    findCharges: any;
    routingValue: string;
    constructor(
        private readonly httpService: HttpService,
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly encounterCommunication: EncounterCommunication,
        public timelineService: TimelineToggleService,
        private readonly notifyService: NotifyService,
    ) {

        this.page = new Page();
        this.date = new Date();
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.isAdmission = url.split("/")[4] === "a";
                    this.appointmentId = decodeURIComponent(url.split("/")[3]);
                    this.routingValue = decodeURIComponent(url.split("/")[2]);
                    this.roleId = this.page.userAccount.roleId;
                    this.encounterCommunicationSubscription = this.encounterCommunication.encounter.subscribe(() => {
                        if (this.appointmentId == "b" || this.appointmentId == "a") {
                            var currentUrl = this.router.url;
                            this.appointmentId = currentUrl.split("/")[currentUrl.split("/").length - 4];
                        }
                        this.findAppointment(this.appointmentId);
                    });
                } else {
                    this.page.userAccount = undefined;
                }
                this.findAppointment(this.appointmentId);
                this.findDashboard();
                this.fetchVisitTypes();
                this.fetchChargesType();
            });
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.encounterCommunicationSubscription.unsubscribe();
    }
    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointment = response;
                this.encryptedPatientId = this.appointment.encryptedPatientId;
                this.fetchDashboard();
                if (this.routingValue == "ivf-new-encounter" || this.routingValue == "ivf-encounter" ) {
                    this.findPatient(this.appointment.patientId);
                }
               

            });
        this.findDashboard();

    }

    private findDashboard() {
        const request = { encryptedAppointmentId: this.appointmentId, isAdmission: this.isAdmission, type: OBEncounterType.SpeacialFeature };

        request["isAdmission"] = this.isAdmission;
        this.httpService.post<IEncounterResource>(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.fetchCommonEncounter), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: IEncounterResource) => {
                this.obEncounter = response;
                this.specialFeature = JSON.parse(this.obEncounter.jsonString);

            });
    }
    toggleIt() {
        this.timelineService.toggle();
        if (this.timelineService.showBox == false) {
            $("body")[0].setAttribute("data-timeline-size", "timelineabsent");
        } else {
            $("body")[0].setAttribute("data-timeline-size", "timeline");
        }
    }
    toggleSideNav() {
        $(".sidebar-main-menu")[0].classList.toggle("removeLeft");
        if ($("body")[0].getAttribute('data-sidebar-size') === "default") {
            $("body")[0].setAttribute("data-sidebar-size", "condensed");
            this.sideNav = true;
        } else if ($("body")[0].getAttribute('data-sidebar-size') === "condensed") {
            $("body")[0].setAttribute("data-sidebar-size", "default");
            this.sideNav = false;
        }
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }
    private fetchDashboard() {

        const request = { encryptedAppointmentId: this.appointmentId, encryptedProviderId: this.page.userAccount.encryptedReferenceId, encryptedPatientId: this.encryptedPatientId };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<[IvfEncounter, Appointment, PreviousAppointment]>(ApiResources.getURI(ApiResources.ivfEncounter.base, ApiResources.ivfEncounter.findAll), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: [IvfEncounter, Appointment, PreviousAppointment]) => {
                this.ivfEncounter = response["dashboard"];
                if (this.ivfEncounter && this.ivfEncounter.ivfEncounterId) {
                    this.antinantalRisk = this.ivfEncounter.antinantalRisk ? JSON.parse(this.ivfEncounter.antinantalRisk) : null;
                }
            });

    }

    private findPatient(id: any) {

        const request = { patientId: id };
        this.httpService.post<any>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.fetchPatientRelativeDetail), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: any) => {
                this.patient = response;
            });


    }
    onClickWifeEncoounter() {
        var enacyptedAppointmentId = window.btoa(this.appointment.relativeAppointmentId.toString());
        this.router.navigate(["/app/ivf-encounter", enacyptedAppointmentId, 'b'])
    }

    onAddEncounter() {
        if (this.appointment.relativeAppointmentId != null) {
            var enacyptedAppointmentId = window.btoa(this.appointment.relativeAppointmentId.toString());
            this.router.navigate(["/app/ivf-encounter-sp", enacyptedAppointmentId, 'b'])
        }
        else {
            this.submitting = true;
            var duration = 5
            var time = moment(this.date).format('HH:mm:ss')
            var date = moment(this.date).format('YYYY-MM-DD')
            let abc = moment(date + 'T' + time)
            let future = abc.add(duration, 'minutes');
            var times = future.format("HH:mm:ss")

            let request = { isUnidentified: false, appointmentTypeId: 2 };
            request["patientId"] = this.patient && this.patient.patientId ? this.patient.patientId : this.appointment.patientId
            request["gender"] = this.patient && this.patient.gender ? this.patient.gender : this.appointment.patientGender
            request["firstName"] = this.patient && this.patient.firstName ? this.patient.firstName : this.appointment.patientName
            request["lastName"] = this.patient && this.patient.lastName ? this.patient.lastName : null
            request["mobile"] = this.patient && this.patient.mobile ? this.patient.mobile : null
            request["payTypeId"] = 1
            request["visitTypeId"] = this.findOPVisitType && this.findOPVisitType.id ? this.findOPVisitType.id : null;
            request["gender"] = this.patient && this.patient.gender ? this.patient.gender : null;
            request["createdByName"] = this.page.userAccount.fullName;
            request["LocationId"] = this.page.userAccount.locationId;
            request["appointmentDate"] = date,
                // request["charges"] = +this.charges;
                request["specializationId"] = this.appointment.specializationId,
                request["consultationTypeId"] = 1,
                request["createdBy"] = this.page.userAccount.accountId;
            request["providerAvailabilityId"] = 0
            request["providerId"] = this.appointment.providerId
            request["updatePatient"] = false;
            request["departmentId"] = this.appointment.departmentId
            request["appointmentEndTime"] = times,
                request["appointmentTime"] = time,
                request["isEncounter"] = true,
                request["relativeAppointmentId"] = this.appointment.appointmentId,
                request["chargeTypesId"] = this.findCharges && this.findCharges.id ? this.findCharges.id : null;
            const formData = UtilHelper.prepareFormData(UtilHelper.clone(request));
            this.httpService
                .postFile(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.quickSchedule), formData)
                .pipe(finalize(() => { this.submitting = false; }))
                .pipe(takeUntil(this.page.unSubscribe))
                .subscribe((response: GenericResponse) => {

                    if (response.status === GenericStatus[GenericStatus.Error]) {
                        this.notifyService.warning(response.message)
                    }
                    else if (response.status === GenericStatus[GenericStatus.Success]) {
                        const data = response.data;
                        if (typeof (data) === "number") {
                            this.submitting = false;
                            var enacyptedAppointmentId = window.btoa(data.toString());
                            this.router.navigate(["/app/ivf-encounter-sp", enacyptedAppointmentId, 'b'])

                        }
                    }


                }, (error: HttpErrorResponse) => {
                    this.submitting = false;
                    const errorMessage = UtilHelper.handleError(error);
                    if (errorMessage) {
                        this.notifyService.warning(errorMessage);
                    } else {
                        this.notifyService.defaultError();
                    }
                });

        }

        
    }

    fetchVisitTypes = () => {
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.providerLocations.base, ApiResources.providerLocations.fetchVisitTypes), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.isVisitTypeloading = false))
            .subscribe(
                (response: GenericResponse) => {
                    this.visitors = response.data as Array<LookUpModel>;
                    this.findOPVisitType = response.data.find(m => m.name.toLowerCase().trim() === "Out Patient".toLowerCase().trim());
                },
                () => {
                    this.visitors = new Array<LookUpModel>();
                }
            );
    }

    fetchChargesType = () => {
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.providerLocations.base, ApiResources.providerLocations.fetchChargeType), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.isVisitTypeloading = false))
            .subscribe(
                (response: GenericResponse) => {
                    this.chargeType = response.data as Array<LookUpModel>;
                    this.findCharges = response.data.find(m => m.name.toLowerCase().trim() === "WALK IN".toLowerCase().trim());
                },
                () => {
                    this.chargeType = new Array<LookUpModel>();
                }
            );
    }
}