import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "sortForm",
    pure: false
})
export class SortFormPipe implements PipeTransform {
    transform(array: Array<any>, property: string): Array<string> {
        if (array !== undefined) {
            return array.sort((a: any, b: any) => {

                const aValue = a.value[property];
                const bValue = b.value[property];

                if (aValue < bValue) {
                    return -1;
                } else if (aValue > bValue) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }
        return array;
    }
}