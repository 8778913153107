export class PayoutAccount {
    status: number;
    msg: any;
    code: any;
    data: Data;
}
export class Data {
    payoutMerchantId: number;
    uuid: string;
    virtualAccountNumber: string;
    transferableAmount: number;
    balance: number;
    lowBalance: boolean;
    ifsc: string;
    type: string;
    noOfPages: number;
    totalElements: number;
    currentPage: number;
    transactionDetails: Array<TransactionDetail>;
}

export class TransactionDetail {
    txnId: number;
    purpose: string;
    amount: number;
    txnStatus: string;
    txnDate: Date;
    beneficiaryName: string;
    transferType: string;
    totalItems: number;
    payuTransactionRefNo: string;
    merchantRefId: string;
}