import { Injectable } from "@angular/core";
import { HttpService } from "@shared/services";
import { ApiResources } from "@shared/helpers";
import { finalize } from "rxjs/operators";

@Injectable()
export class VideoLinkService {

    isLoading: boolean;

    constructor(
        private readonly httpService: HttpService
    ) {

    }

    send(appointmentNo: string, onSuccess: (response: any) => void) {
        this.httpService
            .post<object>(ApiResources.getURI(ApiResources.webTelemedicine.base, ApiResources.webTelemedicine.sendLink),
                {appointmentNo: appointmentNo})
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(
                (response: object) => {
                    onSuccess(response);
                },
                (error: any) => {
                    console.error(error);
                    onSuccess(null);
                }
            );
    }
    
}
