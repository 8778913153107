export class GenericResponse {
    status: string;
    message: string;
    data: any;
    value: any;
    id: any;
}

export enum GenericStatus {
    Success = 1,
    Info = 2,
    Warning = 3,
    Error = 4
}