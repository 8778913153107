import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { IdentityService, MenuService } from "@shared/services";

@Injectable()
export class AccessGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly identityService: IdentityService,
        private readonly menuService: MenuService

    ) { }

    canActivate(): Observable<boolean> | boolean {
        const userAccountReq = this.identityService.status();
        return userAccountReq
            .pipe(
                map((roleId: number) => {
                    if (roleId) {
                        this.router.navigate([this.menuService.getDefaultRoute]);
                        return false;
                    } else {
                        return true;
                    }
                }),
                catchError(() => {
                    return of(true);
                })
            );
    }

    canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate();
    }
}