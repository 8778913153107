export enum EncounterType {
    InternalMedicine,
    OBEncounter,
    GynEncounter,
    PEncounter,
    EmergencyEncounter,
    DietEncounter,
    PhysiotherapyEncounter,
    GeneticSpecialityEncounter,
    NeoIPEncounter,
    IVFEncounter,
    OphthalmologyEncounter

}
export class EncounterValue {
    encounterType: EncounterType;
    value: any;
    encryptedAppointmentId: string;
    isSubmitandNext: boolean;
    isAdmission: boolean;
    encryptedAdmissionId: string;
    isSubmit: boolean;
}