<ng-template #templateSelectBed>
    <form [formGroup]="mainForm">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-bed mr-1"></i>Select Bed</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body p-0 overflow-auto" style="height:75vh;">
            <ng-container>
                <div class="card-box pb-0">
                    <div class="row">
                        <ng-container formArrayName="slots" *ngFor="let item of slotsHolder; let i = index">
                            <ng-container [formGroupName]="i">
                                <div class="col-lg-12 slot-block">
                                    <div class="d-flex">
                                        <div>
                                            <ng-container *ngIf="!readOnly">
                                                <div class="form-group mb-3">
                                                    <label class="mb-1">Shift<code>*</code></label>
                                                    <div class="form-group">
                                                        <ng-select [items]="shifts"
                                                                   bindLabel="value"
                                                                   [loading]="loadingShifts"
                                                                   bindValue="id"
                                                                   placeholder="Select Shift"
                                                                   (change)="onShiftChange($event, i)"
                                                                   formControlName="shiftId">
                                                        </ng-select>
                                                        <div *ngIf="submitted && formSlots.controls[i]['controls']['shiftId'].errors" class="invalid-feedback show-must">
                                                            <div *ngIf="formSlots.controls[i]['controls']['shiftId'].errors.required"><span class="mdi mdi-circle-medium mr-1"></span>Shift is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group mb-3">
                                                    <ngb-datepicker [markDisabled]="markDisabled" [minDate]="item.minDate" (dateSelect)="onDateSelection($event, i)" [displayMonths]="isSingle ? 1 : 2" [dayTemplate]="t" outsideDays="hidden"></ngb-datepicker>
                                                    <ng-template #t let-date let-focused="focused">
                                                        <span class="custom-day"
                                                              [class.focused]="focused"
                                                              [class.range]="isRange(date, i)"
                                                              [class.faded]="isHovered(date, i) || isInside(date, i)"
                                                              (mouseenter)="onMouseEnter(date, i)"
                                                              (mouseleave)="onMouseLeave(i)">
                                                            <span [textContent]="date.day"></span>
                                                        </span>
                                                    </ng-template>
                                                    <div *ngIf="submitted && formSlots.controls[i]['controls']['toDate'].errors" class="invalid-feedback show-must">
                                                        <div *ngIf="formSlots.controls[i]['controls']['toDate'].errors.required"><span class="mdi mdi-circle-medium mr-1"></span>Date is required</div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <div class="appointment-form pb-0 bg-light bg-pattern">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="form-group w-100 mr-2">
                        <label class="mb-1">Location</label>
                        <ng-select class="ng-select-sm text-uppercase"
                                   [items]="locations"
                                   bindLabel="name"
                                   bindValue="id"
                                   autocomplete="nope"
                                   placeholder="Select location"
                                   formControlName="locationId"
                                   (change)="onfilterlocation()">
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                <div class="ng-option disabled">
                                    No location found for '{{searchTerm}}'
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>

                    <div class="form-group w-100 mr-2">
                        <label class="mb-1">Floors</label>
                        <ng-select [loading]="loadingBeds" placeholder="Enter Floor Name" formControlName="floorName" [items]="floorsFilter"></ng-select>
                    </div>
                    <div class="form-group w-100 mr-2">
                        <label class="mb-1">Wards</label>
                        <ng-select [loading]="loadingBeds" placeholder="Enter Ward Name" formControlName="wardName" [items]="wardsFilter"></ng-select>
                    </div>
                    <div class="form-group w-100 mr-2">
                        <label class="mb-1">Rooms</label>
                        <ng-select [loading]="loadingBeds" placeholder="Enter Room Name" formControlName="roomName" [items]="roomsFilter"></ng-select>
                    </div>
                    <div class="form-group w-100 mr-2">
                        <label class="mb-1">Beds</label>
                        <ng-select [loading]="loadingBeds" placeholder="Enter Bed Name" formControlName="bedName" [items]="bedsFilter"></ng-select>
                    </div>
                    <div class="d-flex align-items-center justify-content-start">
                        <button (click)="resetFilter()" type="button" class="btn btn-secondary btn-sm white-space-nowrap">
                            <i class="fe-x mr-1"></i> Clear
                        </button>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <div class="form-group mb-0 mr-2">
                            <label class="d-flex align-items-center">
                                <input type="checkbox" formControlName="hideAssigned" class="form-control w-20-px mr-1" />
                                Show UnAssigned Beds
                            </label>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="form-group mb-0 mr-2">
                            <label class="d-flex align-items-center">
                                <input type="checkbox" formControlName="selectAll" class="form-control w-20-px mr-1" />
                                Select All
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-2" *ngIf="loadingBeds">
                <span class="spinner-grow spinner-grow-sm mr-1"></span> Please wait while loading beds.
            </div>
            <ng-container *ngIf="beds && beds.length">
                <ng-container formArrayName="slots">
                    <ng-container [formGroupName]="selectedSlotIndex">
                        <div class="d-flex flex-wrap slots-holder mt-2">
                            <ng-container formArrayName="beds" *ngFor="let item of beds">
                                <ng-container [formGroupName]="item.bedIndex">
                                    <div class="mx-2 my-2" *ngIf="formSlotBedIsShow(selectedSlotIndex, item.bedIndex)">
                                        <div class="card bed-slot m-0 h-100">
                                            <div class="card-body py-2" [ngClass]="{'bg-soft-success': formSlotBedStatus(selectedSlotIndex, item.bedIndex)}">
                                                <div class="h-100 d-flex flex-column justify-content-between">
                                                    <ng-container *ngTemplateOutlet="bed; context: {item : item}"></ng-container>
                                                    <div class="nurse-block pt-1 d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <div class="media cursor-pointer" *ngIf="!formSlotBedStatus(selectedSlotIndex, item.bedIndex) && !item.nurseShiftMapId">
                                                                <div class="avatar-sm mr-2">
                                                                    <span class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="9">
                                                                        <i class="mdi mdi-help mdi-24px"></i>
                                                                    </span>
                                                                </div>
                                                                <div class="media-body">
                                                                    <span class="font-16 text-muted">Unnassigned</span>
                                                                </div>
                                                            </div>
                                                            <div class="media cursor-pointer" *ngIf="formSlotBedStatus(selectedSlotIndex, item.bedIndex) || item.nurseShiftMapId">
                                                                <ng-container *ngIf="!item.nurseShiftMapId">
                                                                    <div class="avatar-sm mr-2">
                                                                        <avatar-img *ngIf="nurse.nurseImage" [src]="nurse.nurseImage" [alt]="nurse.fullName" cssClass="img-fluid rounded-circle"></avatar-img>
                                                                        <span *ngIf="!nurse.nurseImage" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="3">
                                                                            <span [textContent]="nurse.fullName | initials"></span>
                                                                        </span>
                                                                    </div>
                                                                    <div class="media-body">
                                                                        <h5 class="mb-0 mt-0 font-weight-normal text-capitalize" [textContent]="nurse.fullName | titlecase"></h5>
                                                                        <span class="d-block font-13">
                                                                            <span [textContent]="nurse.roleName"></span>
                                                                        </span>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container *ngIf="item.nurseShiftMapId">
                                                                    <div class="avatar-sm mr-2">
                                                                        <avatar-img *ngIf="item.nurseImage" [src]="item.nurseImage" [alt]="item.fullName" cssClass="img-fluid rounded-circle"></avatar-img>
                                                                        <span *ngIf="!item.nurseImage" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="3">
                                                                            <span [textContent]="item.fullName | initials"></span>
                                                                        </span>
                                                                    </div>
                                                                    <div class="media-body">
                                                                        <h5 class="mb-0 mt-0 font-weight-normal text-capitalize" [textContent]="item.fullName | titlecase"></h5>
                                                                        <span class="d-block font-13">
                                                                            <span [textContent]="item.roleName"></span>
                                                                        </span>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="form-group mb-0" *ngIf="!item.nurseShiftMapId || item.nurseAccountId === nurse.accountId">
                                                                <input type="checkbox" class="wh-20-px" formControlName="status" />
                                                            </div>
                                                            <div class="form-group mb-0" *ngIf="item.nurseShiftMapId && item.nurseAccountId !== nurse.accountId">
                                                                <div class="d-flex flex-column">
                                                                    <div class="small text-muted" [textContent]="item.fromDate | date:'MMM dd, yyyy'"></div>
                                                                    <div class="small text-muted" [textContent]="item.toDate | date:'MMM dd, yyyy'"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="item.nurseShiftMapId && item.nurseAccountId !== nurse.accountId" class="card-footer p-1 bg-soft-danger">
                                                <div class="d-flex justify-content-center text-danger font-weight-bold">
                                                    <span>Not Available</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
            <button type="button" (click)="onBedSelected()" class="btn btn-primary btn-sm">
                <i class="mdi mdi-check-all mr-1"></i>Done
            </button>
        </div>
    </form>
</ng-template>