<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <!--<button *ngIf="!loading && dietPlan && dietPlan.length" type="button" class="btn btn-primary btn-sm" (click)="onOpenModel(templateWord)"><i class="fe-plus mr-1"></i> Add Diet Plan</button>-->
                        <button *ngIf="!loading && records && records.length" [useExistingCss]="true" printSectionId="diet_plan_print" ngxPrint class="btn btn-primary btn-sm mr-1 d-print-none"><i class="fe-printer mr-1"></i>Print</button>
                        <ng-container>
                            <button type="button" (click)="openModifyModel(templateWord)" class="btn btn-primary btn-sm">
                                <i class="mdi mdi-note-plus-outline mr-1"></i>Add New Diet Plan
                            </button>
                        </ng-container>
                        <a href="javascript:;" class="btn btn-secondary btn-sm ml-1" (click)="onBack()">
                            <span><i class="fe-arrow-left mr-1"></i> Back</span>
                        </a>
                    </div>
                    <h4 class="page-title">Diet Plan</h4>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="loading">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading Diet Plan ...</span>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!loading && records && records.length">
            <div class="col-12" id="diet_plan_print">
                <div class="card mb-0">
                    <div class="card-body p-0">
                        <div class="overflow-auto">
                            <table class="table table-centered table-sm table-striped table-bordered mb-0">
                                <thead class="table-primary">
                                    <tr>
                                        <th>Meal Type</th>
                                        <th>Diet Instructions</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Created By</th>
                                        <th>Modified By</th>
                                        <th class="text-right noprint-column">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let item of records; let i = index;">
                                        <tr [ngClass]="{'table-danger' : !item.active}">
                                            <td [textContent]="item.mealType" [ngClass]="{'text-line-through' : item.active === false}"></td>
                                            <td [textContent]="(item.dietInstructions) || '---'" [ngClass]="{'text-line-through' : item.active === false}"></td>
                                            <td [textContent]="(item.startDate |  date:'MM-dd-yyyy') || '---'"></td>
                                            <td [textContent]="(item.endDate | date:'MM-dd-yyyy') || '---'"></td>
                                            <td>
                                                <div class="media-body">
                                                    <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="(item.createdByName || '---')"></h5>
                                                    <small class="tx-13" [textContent]="item.createdDate | date:'short'"></small>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="media-body">
                                                    <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="(item.modifiedByName || '---')"></h5>
                                                    <small class="tx-13" [textContent]="(item.modifiedDate | date:'dd-MM-yyyy, hh:mm a') || '---'"></small>
                                                </div>
                                            </td>
                                            <td class="noprint-column">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <a *ngIf="item.progressReportDietId" href="javascript:;" (click)="onOpenViewModel(templateViewDiet, item)" class="action-icon text-primary font-20" placement="left" ngbTooltip="View"><i class="mdi mdi-eye"></i></a>
                                                    <!--<a href="javascript:;" *ngIf="item.progressReportDietId && item.active" placement="left" ngbTooltip="Edit" (click)="onOpenUpdateModel(updateDietPlan, item)"><i class="mdi mdi-file-document-edit-outline mr-1"></i></a>-->
                                                    <a href="javascript:;" *ngIf="item.progressReportDietId && item.active" class="action-icon text-danger ml-1" (click)="onOpenStopModel(templateStopDiet, item)" placement="left" ngbTooltip="Stop this Diet"><i class="mdi mdi-octagon font-20"></i></a>
                                                    <!--<a href="javascript:;" class="action-icon text-danger ml-1" placement="left" ngbTooltip="Delete"><i class="mdi mdi-trash-can"></i></a>-->
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<ng-template #updateDietPlan>
    <div class="modal-header">
        <i class="fe-plus mr-1" placement="right"></i>
        Update Diet Plan
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <form [formGroup]="updateForm" (ngSubmit)="onModify()">
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-12">
                    <div class="col-lg-12 mt-2">
                        <table class="table table-centered table-nowrap table-sm table-bordered mb-0">
                            <thead>
                                <tr>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </form>

</ng-template>

<ng-template #templateWord>
    <form [formGroup]="addForm" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-food-outline mr-1"></i>Add Diet Plan</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-12">
                    <div class="col-lg-12" *ngIf="!isMealTypeloading && mealTypes.length > 0">
                        <!--<table class="table table-centered table-nowrap table-sm table-bordered mb-0">
                        <thead>
                            <tr>
                                <th>Meal Type</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of mealTypes;let i = index;">
                                <td>
                                    <span [textContent]="item.mealType"></span>
                                </td>
                                <td>
                                    <button type="button" (click)="onDietSelect(item)" class="btn btn-sm" [ngClass]="(selectedDietPlan && selectedDietPlan.mealTypeId === item.mealTypeId)?'btn-primary':'btn-outline-primary'">-->
                        <!--<i class="mdi mdi-food-outline mr-1"></i> Add-->
                        <!--<div *ngIf="selectedDietPlan && selectedDietPlan.mealTypeId === item.mealTypeId">
                                <i class="fe-check mr-1"></i>Selected
                            </div>
                            <div *ngIf="!selectedDietPlan || selectedDietPlan.mealTypeId !== item.mealTypeId">Select</div>
                            <div *ngIf="submitted && form.mealTypeId.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.mealTypeId.errors.required">Please select meal type</div>
                            </div>
                        </button>
                        <div *ngIf="selectedDietPlan && selectedDietPlan.mealTypeId === item.mealTypeId">
                            <label>Add Diet Plan</label><code>*</code>
                            <textarea *ngIf="resetSelect" placeholder="Diet Instructions (optional)" rows="3" formControlName="dietInstructions" class="form-control"></textarea>
                            <div *ngIf="submitted && form.dietInstructions.errors" class="invalid-feedback show-must">
                                <div *ngIf="form.dietInstructions.errors.required">Diet Plan field is required</div>
                            </div>
                        </div>-->
                        <!--<button type="button" *ngIf="item.isSelected" (click)="onDietRemove(item)" class="btn btn-sm btn-danger mt-1">
                            <i class="mdi mdi-food-empty-minus-outline mr-1"></i> Remove
                        </button>-->
                        <!--</td>
                                </tr>
                            </tbody>
                        </table>-->
                        <label>Meal Type</label>
                        <ng-select [items]="mealType"
                                   [loading]="isMealTypeloading"
                                   [clearable]="true"
                                   bindLabel="value"
                                   bindValue="id"
                                   placeholder="Select Meal Type"
                                   [ngClass]="{ 'is-invalid': (submitted && form.mealTypeId.errors) }"
                                   formControlName="mealTypeId"
                                   (change)="onDietSelect($event)">
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                No Meal Type found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-i="index">
                                <h5 class="mb-1 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                                <div class="media">
                                    <div class="avatar-xs mr-1">
                                        <span class="avatar-title rounded-circle font-11 font-weight-bold bg-soft-primary text-primary"><i class="mdi-food"></i></span>
                                    </div>
                                    <div class="media-body">
                                        <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="item.value"></h5>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-select>
                        <div class="col-sm-12 mb-3">
                            <fieldset>
                                <legend>Frequency</legend>
                                <div *ngIf="submitted && !form.isMorning.value && !form.isAfternoon.value && !form.isNight.value" class="invalid-feedback show-must mb-2">
                                    <div><i class="fe-arrow-down mr-1"></i>please select one of the option below</div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="d-flex align-items-center justify-content-center mb-2">
                                            <div class="custom-control custom-checkbox w-200p">
                                                <input id="morning" class="custom-control-input" type="checkbox" formControlName="isMorning" [ngClass]="{ 'is-invalid': submitted && !form.isMorning.value && !form.isAfternoon.value && !form.isNight.value }" />
                                                <label class="custom-control-label font-weight-bold" for="morning"> Morning</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="d-flex align-items-center justify-content-center mb-2">
                                            <div class="custom-control custom-checkbox w-200p">
                                                <input id="afternoon" class="custom-control-input" type="checkbox" formControlName="isAfternoon" [ngClass]="{ 'is-invalid': submitted && !form.isMorning.value && !form.isAfternoon.value && !form.isNight.value }" />
                                                <label class="custom-control-label font-weight-bold" for="afternoon"> Afternoon</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <div class="custom-control custom-checkbox w-200p">
                                                <input id="night" class="custom-control-input" type="checkbox" formControlName="isNight" [ngClass]="{ 'is-invalid': submitted && !form.isMorning.value && !form.isAfternoon.value && !form.isNight.value }" />
                                                <label class="custom-control-label font-weight-bold" for="night"> Night</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div class="row mt-1">
                            <div class="col-sm-12">
                                <label>Add Diet Instructions</label><code>*</code>
                                <textarea placeholder="Diet Instructions (optional)" rows="3" formControlName="dietInstructions" class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>Duration <span class="text-danger">*</span></label>
                                    <div class="input-group mb-0">
                                        <div class="input-group-prepend">
                                            <select class="input-group-text custom-select" formControlName="medicationDurationTypeId">
                                                <option [ngValue]="1">Days</option>
                                                <option [ngValue]="2">Weeks</option>
                                                <option [ngValue]="3">Months</option>
                                            </select>
                                        </div>
                                        <input class="form-control" maxlength="3" placeholder="###" formControlName="duration" block autocomplete="nope" type="text" numbersOnly [ngClass]="{ 'is-invalid': dietSubmitted && form.duration.errors }" />
                                    </div>
                                    <div *ngIf="submitted && form.duration.errors" class="invalid-feedback show-must">
                                        <div *ngIf="form.duration.errors.required">Duration field is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group position-relative mb-0">
                                    <label>Start Date <span class="text-danger">*</span></label>
                                    <input class="form-control date-picker" type="text" block
                                           formControlName="startDate" autocomplete="nope"
                                           [ngClass]="{ 'is-invalid': submitted && form.startDate.errors }"
                                           ngbDatepicker #startDate="ngbDatepicker"
                                           (click)="startDate.toggle(); $event.stopPropagation();" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-1">
                        <div class="d-flex justify-content-center">
                            <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
                            <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm">
                                <span *ngIf="submitting">
                                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                    Please wait..
                                </span>
                                <span *ngIf="!submitting">Submit</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #templateStopDiet>
    <form [formGroup]="stopDietForm" (submit)="onStopDiet()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-octagon mr-1"></i>Stop Medicine</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm-12">
                    <div class="overflow-auto">
                        <table class="table table-centered table-sm table-striped table-bordered mb-0">
                            <thead class="table-primary">
                                <tr>
                                    <th>Name</th>
                                    <th>Usage</th>
                                    <th>Created By</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div>
                                            <div class="media">
                                                <div class="p-1">
                                                    <ng-container [ngSwitch]="selected.mealType">
                                                    </ng-container>
                                                </div>
                                                <div class="media-body ml-1">
                                                    <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="selected.mealType || '---'"></h5>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <div class="media">
                                                <div class="media-body">
                                                    <h5 class="mb-0 mt-0 font-weight-normal">
                                                        <span class="mr-1" [textContent]="selected.duration"></span>
                                                        <span>
                                                            <ng-container [ngSwitch]="selected.medicationDurationTypeId">
                                                                <span *ngSwitchCase="1">Day<span *ngIf="selected.duration > 1">s</span></span>
                                                                <span *ngSwitchCase="2">Week<span *ngIf="selected.duration > 1">s</span></span>
                                                                <span *ngSwitchCase="3">Month<span *ngIf="selected.duration > 1">s</span></span>
                                                            </ng-container>
                                                        </span>
                                                    </h5>

                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <div class="media">
                                                <div class="media-body">
                                                    <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="selected.createdByName"></h5>
                                                    <span class="d-block font-12 mt-1">
                                                        <small [textContent]="selected.createdDate | date:'dd-MM-yyyy, hh:mm a'"></small>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="font-weight-bold">
                                            <span class="text-success">
                                                <span class="badge badge-success mr-1">Active</span>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-sm-12 mt-3">
                    <div class="form-group">
                        <label class="mb-1">Reason</label>
                        <textarea formControlName="reason" rows="2" class="form-control" placeholder="Give a reason why to stop this diet (optional)"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
            <button type="submit" [disabled]="stopping" class="btn btn-danger btn-sm">
                <span *ngIf="stopping">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!stopping"><i class="fe-check mr-1"></i>Stop</span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #templateViewDiet>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-offer mr-1"></i>View Diet</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12">
                <div class="overflow-auto">
                    <table class="table table-sm table-bordered table-centered table-striped">
                        <thead>
                            <tr class="table-primary">
                                <th colspan="4">
                                    <i class="mdi mdi-information mr-1"></i>Diet Plan Information
                                </th>
                            </tr>
                            <tr>
                                <th class="table-active">Diet Name</th>
                                <td>
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedDiet.mealType"></span>
                                </td>
                                <th class="table-active">Meal Instructions</th>
                                <td>
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedDiet.mealInstruction"></span>
                                </td>
                            </tr>
                            <tr>
                                <th class="table-active">Created By</th>
                                <td>
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedDiet.createdByName"></span>
                                </td>
                                <th class="table-active">Created Date</th>
                                <td>
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedDiet.createdDate | date:'dd-MM-yyyy, hh:mm a'"></span>
                                </td>
                            </tr>
                            <tr>
                                <th class="table-active">Diet Start Date</th>
                                <td>
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedDiet.startDate | date:'dd-MM-yyyy'"></span>
                                </td>
                                <th class="table-active">Diet End Date</th>
                                <td>
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedDiet.endDate | date:'dd-MM-yyyy'"></span>
                                </td>
                            </tr>
                            <!--<tr *ngIf="selectedDiet.activeStatus === activeStatus.Stopped">
                                <th class="table-active">Stopped By</th>
                                <td>
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedDiet.modifiedByName"></span>
                                </td>
                                <th class="table-active">Stopped Date</th>
                                <td>
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedDiet.modifiedDate | date: 'dd-MM-yyyy, h:mm:ss a'"></span>
                                </td>
                            </tr>
                            <tr *ngIf="selectedDiet.activeStatus === activeStatus.Stopped">
                                <th class="table-active">Stopped Reason</th>
                                <td colspan="3">
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedDiet.stopReason"></span>
                                </td>
                            </tr>-->
                            <tr *ngIf="!selectedDiet.active">
                                <th class="table-active">Stopped By</th>
                                <td>
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedDiet.modifiedByName"></span>
                                </td>
                                <th class="table-active">Stopped Date</th>
                                <td>
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedDiet.modifiedDate | date: 'dd-MM-yyyy, h:mm:ss a'"></span>
                                </td>
                            </tr>
                            <tr *ngIf="!selectedDiet.active">
                                <th class="table-active">Stopped Reason</th>
                                <td colspan="3">
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedDiet.stopReason"></span>
                                </td>
                            </tr>
                            <tr *ngIf="selectedDiet.dietInstructions">
                                <th class="table-active">Diet Instructions</th>
                                <td colspan="3">
                                    <span class="mr-1">:</span>
                                    <span [textContent]="selectedDiet.dietInstructions"></span>
                                </td>
                            </tr>
                            <!--<tr>
                                <th class="table-active">Additional Instructions</th>
                                <td colspan="3">
                                    <span class="mr-1"></span>
                                    <span [textContent]="selectedDiet.dietInstructions"></span>
                                </td>
                            </tr>-->
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
    </div>
</ng-template>
