export class PediatricAgeWiseDataModel {
    pediatricAgeWiseDataId: number;
    pediatricChartAuthorityId: number;
    pediatricChartTypeId: number;
    gender: string;
    authorityName: string;
    typeName: string;
    ageInMonth: number;
    sd?: number;
    p1: number;
    p3: number;
    p5: number;
    p15: number;
    p25: number;
    p50: number;
    p75: number;
    p85: number;
    p95: number;
    p97: number;
    p99: number;
    createdBy: number;
    createdDate: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    active?: boolean;
    l?: number;
    m?: number;
    p999?: number;
    p01?: number;
    p10?: number;
    p90?: number;
    p23?: number;
    p27?: number;
    bmi: any;
}