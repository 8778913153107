
import { Component, Input, TemplateRef, OnInit, OnDestroy, Output, EventEmitter, ElementRef, ViewChild } from "@angular/core";
import { HttpService, AppData, NotifyService } from "@src/app/shared/services";
import { IUserAccount, Page, ProgressData } from "@shared/models";
import { ApiResources, UtilHelper, LinqHelper } from "@shared/helpers";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { finalize, takeUntil } from "rxjs/operators";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { LabParameterInputModel, LabTemplateHeaderForInput, LabParameterInputHeaderModel, LabComponentHeaderForInput, LabParameterGroup, LabTransferModel, LabBillDetailModel } from "@admin/labs/pages/models";
import { forkJoin } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
import { LabObservedValueModel } from "../../../areas/admin/labs/pages/models/lab-observedValue.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LabDocumentsDetails } from "../../entities/laboratory/lab-document-details.entity";
import { ModalType } from "@shared/enums";
import { Pagination } from "@shared/models";
import { LookUpValue } from "../../entities";
class TemplateInput {
    newLabBookingDetailId: number;
    labTemplateObservedValueId: number;
    labTemplateHeaderId: number;
    isMethod: boolean;
    methodText: string;
    isInterpretation: boolean;
    interpretationText: string;
    labMainDetailId: number;
}

class TempParameter {
    labParameterObservedValueId?: number;
    labComponentHeaderId: number;
    componentName: string;
    labTemplateHeaderId: number;
    templateName: string;
    labParameterHeaderId: number;
    parameterName: string;
    displayName: string;
    referenceOutput: string;
    observedValue: string;
    labAntibioticsId?: number;
    templateCount: number;
    componentCount: number;
    labParameterDetailId?: number;
}

@Component({
    selector: "parameter-input",
    templateUrl: "./parameter-input.widget.html",
    styleUrls: ['./parameter-input-widget.css']
})
export class ParameterInputWidget implements OnInit, OnDestroy {
    @ViewChild("videoPlayer", { static: false }) videoPlayer: ElementRef;
    @Input() newLabBookingDetailId: number;
    @Input() encryptedNewLabBookingDetailId: string;
    @Input() verifyAndSubmit = false;
    @Input() fromDoc = false;
    @Input() labSampleCollectionDetailId?: number = null;
    @Output() onClose = new EventEmitter<any>();
    @Input() ShowReport: boolean;

    page: Page;
    loading: boolean;
    modalRef: NgbModalRef;
    parameters: LabParameterInputModel;
    reports: LabParameterInputModel;
    selectedLabBookingDetail: LabBillDetailModel;
    uploadedFile: File;
    isUploading: boolean;
    loadingFile: boolean;
    formedUrl: any;
    fileUploadFail: boolean;
    documentError: boolean;
    loadingTimeline: boolean;
    fileName: string;
    showDocument: boolean;
    reason: string;
    reportsDummy: LabParameterInputModel;


    labAntibiotics: Array<LabParameterInputHeaderModel>;
    labAntibioticsResultTypes: Array<LookUpValue>;
    notesEditor = ClassicEditor;
    config = {
        placeholder: "Enter your content here!",
        toolbar: ["heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "blockQuote"]
    };
    selectedParameter: LabParameterInputHeaderModel;
    submitting: boolean;
    verifying: boolean;
    templateInput: Array<TemplateInput>;
    parameterInput: Array<TempParameter>;
    demographData: Array<LabTransferModel>;
    selectedLab: LabTransferModel;

    checkExternal: LabBillDetailModel;
    isExternalLoading: boolean;
    reportDataWithoutAuth: string;
    reportData: any;
    records: LabBillDetailModel;
    documentUrl: string;
    errorMessage: any;


    loadingObservedValues: boolean;
    totalObservedValues: Array<LabObservedValueModel>;
    parameterNames: any;
    totalObservedValues2: any;
    parametersDummy: LabParameterInputModel;
    showButton: boolean = false;
    ischanged: boolean;
    isAbnormal: boolean;
    uploadForm: FormGroup;
    modalRefViewDocument: NgbModalRef;
    patientId: number;
    warningMessage: string;
    files: Array<File>;
    maxFiles = 10;
    submitted: boolean
    modalRefDocument: NgbModalRef;
    documents: Array<LabDocumentsDetails>;
    document: LabDocumentsDetails;
    showPrevious: boolean;
    showNext: boolean;
    loadingDocument: boolean;
    documentName: string;
    pagination: Pagination;
    modifyingContent: string;
    modifying: boolean;
    encryptedPatientId: string;
    modalRefView: NgbModalRef;
    modalReason: NgbModalRef;
    isHaveParameters: boolean;
    selectParameter: string;
    isRemovedAntibiotic: boolean;
    isExternal: boolean = false;
    isPrintLogo: boolean;

    constructor(
        private readonly appData: AppData,
        private readonly modalService: NgbModal,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
        private readonly sanitizer: DomSanitizer,
        private readonly formBuilder: FormBuilder,

    ) {
        this.page = new Page();
        this.parameters = new LabParameterInputModel();
        this.reports = new LabParameterInputModel();
        this.demographData = new Array<LabTransferModel>();
        this.selectedLabBookingDetail = new LabBillDetailModel();
        this.checkExternal = new LabBillDetailModel();
        this.parametersDummy = new LabParameterInputModel();
        this.initPagination();
        this.labAntibiotics = new Array<LabParameterInputHeaderModel>();
        this.labAntibioticsResultTypes = new Array<LookUpValue>();

    }
    private initPagination() {
        this.pagination = new Pagination();
        this.pagination.pageIndex = 1;
        this.pagination.pageSize = 12;
    }

    onOpenModal(content: TemplateRef<any>, size: string,) {
        this.modelfunction(content, size);

    }

    modelfunction(content, size) {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            windowClass: "custom-modal effect-scale",
            size: size
        });
    }

    onOpenLabReportModel(content: TemplateRef<any>, item: LabTransferModel, size: string) {
        this.onOpenModal(content, "xl");
        this.selectedLab = item;
        this.selectedLab.newLabBookingDetailId = this.newLabBookingDetailId;
    }

    onCloseModal() {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = undefined;
        }
    }
    private buildForm(document?: LabDocumentsDetails) {
        this.uploadForm = this.formBuilder.group({
            documentsDetailId: [document && document.documentDetailsId ? document.documentDetailsId : 0, [Validators.required]],
            documentName: [document && document.documentName ? document.documentName : "", [Validators.required]],
            description: [document && document.description ? document.description : null]
        });
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.onFetchInputParameters();
                    this.fetchNewLabBookingDetail();
                    this.fetchLabBookingDetail();
                    this.fetchDummydata();
                    this.findLabAntibiotic();
                    this.fetchAntibioticResultTypes();
                    if (this.page.userAccount.roleId === 3) {
                        this.showButton = false;
                    }
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }

    onClosePopup() {
        this.onClose.emit();
    }

    private onFetchInputParameters() {
        const request = {
            newLabBookingDetailId: this.newLabBookingDetailId,
            encryptedNewLabBookingDetailId: this.encryptedNewLabBookingDetailId,
            labSampleCollectionDetailId: this.labSampleCollectionDetailId,
            fromTech: "tech"
        };

        let requestApi = new Array<any>();
        let demographApi = this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchReceivedLabs), request);
        requestApi.push(demographApi);

        let inputApi = this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.labParameterForInput), request);
        requestApi.push(inputApi);
        this.loading = true;

        forkJoin([...requestApi])
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<any>) => {
                if (response[0].length > 0) {
                    this.demographData = response[0] as Array<LabTransferModel>;
                    this.fetchDocuments();
                }
                if (response[1]) {
                    this.parameters = response[1] as LabParameterInputModel;
                    this.reports = new LabParameterInputModel();
                    this.reports = UtilHelper.clone(this.parameters);
                    this.reports.templates = new Array<LabTemplateHeaderForInput>();
                    this.parameters.templates.forEach((template: LabTemplateHeaderForInput) => {
                        let parameterList = new Array<LabParameterInputHeaderModel>();
                        template.components.forEach((component: LabComponentHeaderForInput) => {
                            component.parameters.forEach((param: LabParameterInputHeaderModel) => {
                                if (param.observedValue != null) {
                                    this.isHaveParameters = true;
                                }
                                let observedValue = +param.observedValue;
                                let range = param.selected;
                                if (range != null) {
                                    let exactRange = range;
                                    if (exactRange.minValue > observedValue) {
                                        param.showText = "L";
                                        this.isAbnormal = true;
                                        param.isBold = true;
                                    }
                                    else if (observedValue > +exactRange.maxValue) {
                                        param.showText = "H";
                                        this.isAbnormal = true;
                                        param.isBold = true;
                                    }
                                }
                                if (param.type == "Regular") {
                                    parameterList.push(param);
                                }
                                else if (param.type == "Microbiology") {
                                    var resultAddedAntibiotics = param.antibiotics.filter(x => x.observedValue != null);
                                    if (resultAddedAntibiotics.length > 0) {
                                        param.antibiotics = resultAddedAntibiotics;
                                        parameterList.push(param);
                                    } else {
                                        parameterList.push(param);
                                    }

                                }
                            });
                        });
                        template.parameters.forEach((param: LabParameterInputHeaderModel) => {
                            if (param.observedValue != null) {
                                this.isHaveParameters = true;
                            }
                            let observedValue = +param.observedValue;
                            let range = param.selected;
                            if (range != null) {
                                let exactRange = range;
                                if (exactRange.minValue > observedValue) {
                                    param.showText = "L";
                                    this.isAbnormal = true;
                                    param.isBold = true;
                                }
                                else if (observedValue > +exactRange.maxValue) {
                                    param.showText = "H";
                                    this.isAbnormal = true;
                                    param.isBold = true;
                                }
                            }
                            if (param.type == "Regular") {
                                parameterList.push(param);
                            }
                            else if (param.type == "Microbiology") {
                                var resultAddedAntibiotics = param.antibiotics.filter(x => x.observedValue != null);
                                if (resultAddedAntibiotics.length > 0) {
                                    param.antibiotics = resultAddedAntibiotics;
                                    parameterList.push(param);
                                } else {
                                    parameterList.push(param);
                                }

                            }
                        });
                        let templateToAssign = UtilHelper.clone(template) as LabTemplateHeaderForInput;

                        templateToAssign.components = new Array<LabComponentHeaderForInput>();
                        templateToAssign.parameters = new Array<LabParameterInputHeaderModel>();
                        LinqHelper.sort(parameterList, "displayOrder");
                        templateToAssign.parameters = parameterList;
                        this.reports.templates.push(templateToAssign);
                    });
                }
            });
    }

    onOpenForParameterSelection(content: TemplateRef<any>, data: LabParameterInputHeaderModel) {
        this.selectedParameter = data;
        this.onOpenModal(content, "xl");
    }

    onSelectParameter(selectedValue: LabParameterGroup) {
        this.selectedParameter.labParameterDetailId = selectedValue.labParameterDetailId;
        this.selectedParameter.selected = selectedValue;
        this.onCloseModal();
    }
    onSubmitparameters() {
        this.onChange();
        if (this.page.userAccount.roleId === 3) {
            if (this.isRemovedAntibiotic) {
                this.ischanged = false;
            }
            if (!this.ischanged) {
                this.onSubmit()
            } else {
                this.onDoctorVerified();
            }
        } else {
            this.onSubmit()
        }
    }
    onSubmit() {
        const BreakError = {};
        const req2 = {
            newLabBookingDetailId: this.newLabBookingDetailId,
        };

        req2["patientId"] = this.demographData[0].patientId;
        req2["urlLink"] = window.location.href;
        req2["testName"] = this.demographData[0].testName;
        let sampleNum = 0;
        if (this.demographData[0].status != this.demographData[0].subStatus) {
            let sampleNumber = this.demographData[0] && UtilHelper.isEmpty(this.demographData[0].sampleName) ? this.demographData[0].sampleName.split(" ") : new Array<string>();
            if (sampleNumber.length > 0) {
                sampleNum = +sampleNumber[1];
            }
        }
        try {
            const templates = this.parameters.templates;
            this.parameterInput = new Array<TempParameter>();
            this.templateInput = new Array<TemplateInput>();
            templates.forEach((template) => {
                if (this.demographData[0].status === this.demographData[0].subStatus) {
                    if (template.isMethod) {
                        if (UtilHelper.isEmpty(template.methodText) && UtilHelper.isEmpty(template.methodText.trim())) {
                            // ignore
                        } else {
                            this.notifyService.warning(`Please enter method text for template ${template.templateName}`);
                            throw BreakError;
                        }
                    }
                    if (template.isInterpretation && !UtilHelper.isEmpty(template.interpretationText)) {
                        if (UtilHelper.isEmpty(template.interpretationText) && UtilHelper.isEmpty(template.interpretationText.trim())) {
                            // ignore
                        }
                    }
                }
                let param = 0;
                template.components.forEach((component: LabComponentHeaderForInput) => {
                    var abnormalIndex = component.parameters.findIndex(param => param.showText == "H" || param.showText == "L");
                    if (abnormalIndex > -1) {
                        this.isAbnormal = true;
                    } else {
                        this.isAbnormal = false;
                    }
                    component.parameters.forEach((parameter: LabParameterInputHeaderModel) => {
                        let model = new TempParameter();
                        model.labComponentHeaderId = component.labComponentHeaderId;
                        model.labTemplateHeaderId = template.labTemplateHeaderId;
                        model.templateName = template.templateName;
                        model.labParameterHeaderId = parameter.labParameterHeaderId;
                        model.parameterName = parameter.parameterName;
                        model.referenceOutput = parameter.referenceOutput;
                        param = param + 1;
                        var roleId = this.page.userAccount.roleId
                        if (roleId === 3) {
                            if (parameter.type === "Regular") {
                                if (this.demographData[0].status != this.demographData[0].subStatus && sampleNum > 0 && sampleNum >= param) {
                                    if (!UtilHelper.isEmpty(parameter.observedValue)) {
                                        this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> ${component.componentName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                        throw BreakError;
                                    }
                                    else if (!UtilHelper.isEmpty(parameter.observedValue.trim())) {
                                        this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> ${component.componentName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                        throw BreakError;
                                    }
                                }
                                else if (this.demographData[0].status === this.demographData[0].subStatus) {
                                    if (!UtilHelper.isEmpty(parameter.observedValue)) {
                                        this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> ${component.componentName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                        throw BreakError;
                                    }
                                    else if (!UtilHelper.isEmpty(parameter.observedValue.trim())) {
                                        this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> ${component.componentName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                        throw BreakError;
                                    }
                                }
                            }
                            else {
                                parameter.antibiotics.forEach((antibiotic: LabParameterInputHeaderModel) => {
                                    if (this.demographData[0].status != this.demographData[0].subStatus && sampleNum > 0 && sampleNum >= param) {
                                        if (!UtilHelper.isEmpty(antibiotic.observedValue)) {
                                            this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> ${component.componentName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${antibiotic.antibioticName}`);
                                            throw BreakError;
                                        }
                                        else if (!UtilHelper.isEmpty(antibiotic.observedValue.trim())) {
                                            this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> ${component.componentName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${antibiotic.antibioticName}`);
                                            throw BreakError;
                                        }
                                    }
                                    else if (this.demographData[0].status === this.demographData[0].subStatus) {
                                        if (!UtilHelper.isEmpty(antibiotic.observedValue)) {
                                            this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> ${component.componentName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${antibiotic.antibioticName}`);
                                            throw BreakError;
                                        }
                                        else if (!UtilHelper.isEmpty(antibiotic.observedValue.trim())) {
                                            this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> ${component.componentName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName} <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${antibiotic.antibioticName}`);
                                            throw BreakError;
                                        }
                                    }
                                });
                            }

                        }

                        model.labParameterObservedValueId = parameter.labParameterObservedValueId;

                        if (parameter.type != "Regular") {
                            parameter.antibiotics.forEach((antibiotic: LabParameterInputHeaderModel) => {
                                let tempModel = new TempParameter();
                                tempModel.labComponentHeaderId = component.labComponentHeaderId;
                                tempModel.labTemplateHeaderId = template.labTemplateHeaderId;
                                tempModel.templateName = template.templateName;
                                tempModel.labParameterHeaderId = parameter.labParameterHeaderId;
                                tempModel.parameterName = parameter.parameterName;
                                tempModel.observedValue = antibiotic.observedValue;
                                tempModel.labAntibioticsId = antibiotic.labAntibioticsId;
                                tempModel.labComponentHeaderId = component.labComponentHeaderId;
                                tempModel.labParameterObservedValueId = antibiotic.labParameterObservedValueId;
                                if (UtilHelper.isEmpty(antibiotic.observedValue)) {
                                    if (UtilHelper.isEmpty(antibiotic.observedValue.trim())) {
                                        this.parameterInput.push(tempModel);
                                    }
                                }


                            });
                        } else {
                            model.observedValue = parameter.observedValue;
                            model.labParameterDetailId = parameter.labParameterDetailId;
                            this.parameterInput.push(model);
                        }


                    });
                });
                param = 0;
                var abnormalIndex = template.parameters.findIndex(param => param.showText == "H" || param.showText == "L");
                if (abnormalIndex > -1) {
                    this.isAbnormal = true;
                } else {
                    this.isAbnormal = false;
                }
                template.parameters.forEach((parameter: LabParameterInputHeaderModel) => {
                    let model = new TempParameter();
                    model.labComponentHeaderId = 0;
                    model.labTemplateHeaderId = template.labTemplateHeaderId;
                    model.templateName = template.templateName;
                    model.labParameterHeaderId = parameter.labParameterHeaderId;
                    model.parameterName = parameter.parameterName;
                    model.referenceOutput = parameter.referenceOutput;
                    param = param + 1;
                    if (this.page.userAccount.roleId == 3) {
                        if (parameter.type === "Regular") {
                            if (this.demographData[0].status != this.demographData[0].subStatus && sampleNum > 0 && sampleNum >= param) {
                                if (!UtilHelper.isEmpty(parameter.observedValue)) {
                                    this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> Direct Parameter <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                    throw BreakError;
                                }
                                else if (!UtilHelper.isEmpty(parameter.observedValue.trim())) {
                                    this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> Direct Parameter <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                    throw BreakError;
                                }
                            }
                            else if (this.demographData[0].status === this.demographData[0].subStatus) {
                                if (!UtilHelper.isEmpty(parameter.observedValue)) {
                                    this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> Direct Parameter <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                    throw BreakError;
                                }
                                else if (!UtilHelper.isEmpty(parameter.observedValue.trim())) {
                                    this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> Direct Parameter <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName}`);
                                    throw BreakError;
                                }
                            }

                        }
                        else {
                            parameter.antibiotics.forEach((antibiotic: LabParameterInputHeaderModel) => {
                                if (this.demographData[0].status != this.demographData[0].subStatus && sampleNum > 0 && sampleNum >= param) {
                                    if (!UtilHelper.isEmpty(antibiotic.observedValue)) {
                                        this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> Direct Parameter <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName} <i class="mdi mdi-arrow-right"></i> <b>Antibiotic</b> ${antibiotic.antibioticName}`);
                                        throw BreakError;
                                    }
                                    else if (!UtilHelper.isEmpty(antibiotic.observedValue.trim())) {
                                        this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> Direct Parameter <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName} <i class="mdi mdi-arrow-right"></i> <b>Antibiotic</b> ${antibiotic.antibioticName}`);
                                        throw BreakError;
                                    }
                                }
                                else if (this.demographData[0].status === this.demographData[0].subStatus) {
                                    if (!UtilHelper.isEmpty(antibiotic.observedValue)) {
                                        this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> Direct Parameter <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName} <i class="mdi mdi-arrow-right"></i> <b>Antibiotic</b> ${antibiotic.antibioticName}`);
                                        throw BreakError;
                                    }
                                    else if (!UtilHelper.isEmpty(antibiotic.observedValue.trim())) {
                                        this.notifyService.warning(`Please enter value of  ${template.templateName} <i class="mdi mdi-arrow-right"></i> Direct Parameter <i class="mdi mdi-arrow-right"></i> <b>parameter</b> ${parameter.parameterName} <i class="mdi mdi-arrow-right"></i> <b>Antibiotic</b> ${antibiotic.antibioticName}`);
                                        throw BreakError;
                                    }
                                }
                            });

                        }
                    }

                    if (parameter.type != "Regular") {
                        parameter.antibiotics.forEach((antibiotic: LabParameterInputHeaderModel) => {
                            let tempModel = new TempParameter();
                            tempModel.labComponentHeaderId = 0;
                            tempModel.labTemplateHeaderId = template.labTemplateHeaderId;
                            tempModel.templateName = template.templateName;
                            tempModel.labParameterHeaderId = parameter.labParameterHeaderId;
                            tempModel.parameterName = parameter.parameterName;
                            tempModel.observedValue = antibiotic.observedValue;
                            tempModel.labAntibioticsId = antibiotic.labAntibioticsId;
                            tempModel.labParameterObservedValueId = antibiotic.labParameterObservedValueId;
                            if (UtilHelper.isEmpty(antibiotic.observedValue)) {
                                if (UtilHelper.isEmpty(antibiotic.observedValue.trim())) {
                                    this.parameterInput.push(tempModel);
                                }
                            }
                        });
                    } else {
                        model.labParameterObservedValueId = parameter.labParameterObservedValueId;
                        model.observedValue = parameter.observedValue;
                        model.labParameterDetailId = parameter.labParameterDetailId;
                        this.parameterInput.push(model);
                    }

                });

                const templateToPush = {
                    labTemplateObservedValueId: template.labTemplateObservedValueId || 0,
                    newLabBookingDetailId: this.newLabBookingDetailId,
                    labTemplateHeaderId: template.labTemplateHeaderId,
                    isMethod: template.isMethod,
                    methodText: template.methodText,
                    isInterpretation: template.isInterpretation,
                    interpretationText: template.interpretationText,
                    labMainDetailId: this.parameters.labMainDetailId
                };
                if (template.isInterpretation || template.isMethod) {
                    this.templateInput.push(templateToPush);
                }
            });
            this.submitting = true;
            let requestApi = new Array<any>();
            if (this.parameterInput.length > 0) {
                const parameterRequest = {
                    newLabBookingDetailId: this.newLabBookingDetailId,
                    parameters: this.parameterInput,
                    createdBy: this.page.userAccount.accountId,
                    labSampleCollectionDetailId: this.labSampleCollectionDetailId,
                    locationId: this.page.userAccount.locationId,
                    isAbnormal: this.isAbnormal
                }
                let parameterApi = this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.addLabParameterInput), parameterRequest);
                requestApi.push(parameterApi);
            }
            if (this.templateInput.length > 0) {
                const templateRequest = {
                    newLabBookingDetailId: this.newLabBookingDetailId,
                    templates: this.templateInput,
                    createdBy: this.page.userAccount.accountId,
                    labSampleCollectionDetailId: this.labSampleCollectionDetailId,
                    locationId: this.page.userAccount.locationId,
                }
                let templateApi = this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.addTemplateInput), templateRequest);
                requestApi.push(templateApi);
            }

            forkJoin([...requestApi])
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => this.submitting = false))
                .subscribe((response: Array<any>) => {
                    if (response[0] as number > 0 && response[1] as number > 0) {
                        this.notifyService.successToast("Labs Parameters and Templates added successfully.");
                    }
                    else if (response[0] as number > 0) {
                        this.notifyService.successToast("Labs Parameters modified successfully.");
                    }
                    else if (response[1] as number > 0) {
                        this.notifyService.successToast("Labs Templates modified successfully.");
                    }
                    if (!this.verifyAndSubmit) {
                        this.onClosePopup();
                    } else {
                        if (this.page.userAccount.roleId === 3) {
                            this.onDoctorVerified();
                        }
                    }

                }, () => {
                    this.notifyService.defaultErrorToast();
                });
        } catch (err) {
            if (err !== BreakError) throw err;
        }

    }

    onDoctorVerified() {
        this.verifying = true;
        const request = {
            newLabBookingDetailId: this.newLabBookingDetailId
        };
        if (this.page.userAccount.roleId == 3) {
            request["doctorId"] = this.page.userAccount.referenceId;
            request["accountId"] = this.page.userAccount.accountId;
        }
        request["urlLink"] = window.location.href;
        request["patientId"] = this.demographData[0].patientId;
        request["testName"] = this.demographData[0].testName;
        request["roleId"] = this.page.userAccount.roleId;
        request["createdByName"] = this.page.userAccount.fullName;
        request["requisitionNumber"] = this.demographData[0].requisitionNumber;
        request["paymentStatus"] = this.demographData[0].paymentStatus;
        request["paymentType"] = this.demographData[0].paymentType;
        request["locationId"] = this.page.userAccount.locationId;

        this.httpService.post(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.doctorVerifyReport), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.verifying = false))
            .subscribe((response: number) => {
                if (response > 0) {
                    this.notifyService.successToast(`Test Report Verified Successfully.`);
                    this.onClosePopup();
                }
                if (response < 0) {
                    this.notifyService.successToast(`error in Test Verification`);
                }

            }, () => {
                this.notifyService.warningToast("Unable to Test Verification.");
            });

    }

    changeTextColor(param: LabParameterInputHeaderModel) {
        let observedValue = +param.observedValue;
        let range = param.selected;
        if (range != null) {
            let exactRange = range;
            param.showText = null;
            param.isBold = false;
            if (exactRange.minValue > observedValue) {
                param.showText = "L";
                param.isBold = true;
            }
            else if (observedValue > +exactRange.maxValue) {
                param.showText = "H";
                param.isBold = true;
            }
        }
        this.onChange();
    }
    private fetchNewLabBookingDetail() {
        this.loadingTimeline = true;
        this.httpService.get(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchNewLabBookingDetail), {
            newLabBookingDetailId: this.newLabBookingDetailId,
            encryptedNewLabBookingDetailId: this.encryptedNewLabBookingDetailId
        })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingTimeline = false))
            .subscribe((response: LabBillDetailModel) => {
                this.selectedLabBookingDetail = response;
            }, () => {
                this.notifyService.warningToast("Unable to fetch lab Booking detail.");
            });
    }
    onFileUpload(event) {
        this.loadingFile = true;
        this.uploadedFile = event.target.files[0];
        this.fileUploadFail = false;
        this.fileName = this.uploadedFile.name;
        let extension = this.uploadedFile.name.split(".").pop();
        if (extension === "pdf") {
            this.fileChangeEvent();
        }
        else {
            this.loadingFile = false;
            this.uploadedFile = null;
            this.fileUploadFail = true;
        }
    }

    fileChangeEvent() {
        function getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }

        getBase64(this.uploadedFile).then((data: any) => {
            this.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data);
            this.loadingFile = false;
        }, () => {
            this.uploadedFile = null;
            this.fileUploadFail = true;
            this.loadingFile = false;
            this.formedUrl = null;
        });
    }

    onProgress(progressData: ProgressData) {
        if (progressData.loaded === progressData.total) {
            this.loadingFile = false;
        }
    }
    onProgressPdf(progressData: ProgressData) {
        this.isExternalLoading = true;
        if (progressData.loaded === progressData.total) {
            this.isExternalLoading = false;
        }
    }
    onUploadReport() {
        const request = {
            ...this.selectedLabBookingDetail,
            uploadedBy: this.page.userAccount.accountId
        };
        request["roleId"] = this.page.userAccount.roleId;
        request["createdByName"] = this.page.userAccount.fullName;
        request["requisitionNumber"] = this.demographData[0].requisitionNumber;
        request["locationId"] = this.page.userAccount.locationId;
        const formData = UtilHelper.prepareFormData(UtilHelper.clone(request));
        formData.append(`File${1}`, this.uploadedFile, this.uploadedFile.name);
        this.isUploading = true;
        this.httpService.postFile(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.uploadLabData), formData)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.isUploading = false;
                this.onCloseModal();
            }))
            .subscribe((res: string) => {
                this.notifyService.successToast(res);
                this.fetchLabBookingDetail();

            }, (error: HttpErrorResponse) => {
                const errorMessage = UtilHelper.handleError(error);

                if (errorMessage) {
                    this.notifyService.warningToast(errorMessage);
                } else {
                    this.notifyService.defaultError();
                }
            });
    }
    onOpenModel(content: TemplateRef<any>, size: string) {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: size,
            windowClass: "custom-modal effect-scale"
        });
    }

    onDocumentError() {
        this.loadingFile = false;
        this.documentError = true;
    }
    onOpenUploadFile(content: TemplateRef<any>) {
        this.onOpenModel(content, "xl");
        this.onResetFileUploader()
    }
    onResetFileUploader() {
        this.loadingFile = false;
        this.documentError = false;
        this.fileName = null;
        this.formedUrl = null;
        this.uploadedFile = null;
        this.fileUploadFail = false;
    }


    fetchLabBookingDetail() {
        const request = {
            newLabBookingDetailId: this.newLabBookingDetailId,
            fromTech: "tech",
        };
        this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchLabBookingDetail), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: LabBillDetailModel) => {
                    this.records = response;
                    this.documentUrl = this.records?.reportUrl;
                });
    }

    onViewDocument(content: TemplateRef<any>) {
        this.onOpenLabReportModelForDoc(content, "xl");
    }
    onOpenLabReportModelForDoc(content: TemplateRef<any>, size: string) {
        this.modalRefView = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: size,
            windowClass: "custom-modal effect-scale"
        });
    }
    onPrintPdf() {
        let printIframe;
        let blobUrls = [];
        let data = this.reportDataWithoutAuth;

        async function printMethod() {
            const resp = await fetch(data);
            const blob = await resp.blob();
            const url = URL.createObjectURL(blob);
            blobUrls.push(url);
            if (!printIframe) {
                printIframe = document.createElement('iframe');
                printIframe.setAttribute("id", "myDOC");
                document.body.appendChild(printIframe);

                printIframe.style.position = 'absolute';
                printIframe.style.top = '0';
                printIframe.style.left = '-1000px';

                printIframe.onload = () => {
                    setTimeout(() => {
                        if (printIframe) {
                            printIframe.focus();
                            printIframe.contentWindow.print();
                        }
                    }, 100)
                }
            }
            printIframe.src = url;
        }

        printMethod();
    }

    private inactiveDocument() {
        const request = {
            newLabBookingDetailId: this.newLabBookingDetailId,
            reportUrl: this.documentUrl,

        };
        this.notifyService.confirm("Do you really want to delete this Document? This process can not be undone.", () => {
            this.httpService
                .post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.inactiveLabDocument), request)

                .pipe(takeUntil(this.page.unSubscribe))
                .subscribe({
                    next: () => {
                        this.notifyService.successToast("The document got deleted sucessfully");
                        this.fetchLabBookingDetail();
                        this.onCloseModal();
                    },
                    error: (error: HttpErrorResponse) => {
                        this.errorMessage = UtilHelper.handleError(error);
                        this.notifyService.warning(this.errorMessage);
                    }
                });
        });
    }
    onDeleteDocument() {
        this.inactiveDocument();
    }
    viewPreviousResultsAdded(content: TemplateRef<any>, newLabBookingDetailId: number) {
        var start = 0;
        var arr2 = [];
        this.loadingObservedValues = true;
        const request = {
            newLabBookingDetailId: newLabBookingDetailId
        };
        this.httpService
            .post(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchallobservedvalues), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingObservedValues = false))
            .subscribe(
                (response: Array<LabObservedValueModel>) => {
                    this.totalObservedValues = response;
                    const distinctThings = this.totalObservedValues.filter(
                        (x, i, arr) => arr.findIndex(t => t.parameterName === x.parameterName) === i
                    );
                    this.parameterNames = distinctThings;
                    var end = this.totalObservedValues[0].count;
                    var count = this.totalObservedValues[0].count;
                    for (let i = 0; i < this.totalObservedValues.length / count; i++) {
                        arr2.push(this.totalObservedValues.slice(start, end))
                        start = end;
                        end = end + count;
                    }
                    this.totalObservedValues2 = arr2;
                },
                () => {
                    this.totalObservedValues = new Array<LabObservedValueModel>();
                    this.loadingObservedValues = false
                }
            );
        this.onOpenModel(content, "xl");
    }
    onChange() {
        this.showButton = true;
        this.ischanged = true;
        this.parameters.templates.forEach((item) => {
            item.parameters.forEach((param) => {
                this.parametersDummy.templates.forEach((m) => {
                    var index = m.parameters.findIndex(n => n.labParameterHeaderId == param.labParameterHeaderId && n.observedValue != param.observedValue && param.observedValue != "" && param.observedValue);
                    m.parameters?.forEach((y) => {
                        param.antibiotics?.forEach((antibio) => {
                            var subindex1 = y.antibiotics?.findIndex(bio => bio.labAntibioticsId == antibio.labAntibioticsId && bio.labParameterHeaderId == antibio.labParameterHeaderId);
                            if (subindex1 == -1) {
                                this.showButton = false;
                                this.ischanged = false;
                                return;
                            }
                        })
                        y.antibiotics?.forEach((anti) => {
                            var subIndex = param.antibiotics?.findIndex(x => x.observedValue != anti.observedValue && x.labParameterHeaderId == anti.labParameterHeaderId && x.labAntibioticsId == anti.labAntibioticsId);
                            if (subIndex > -1) {
                                this.showButton = false;
                                this.ischanged = false;
                                return;
                            }
                        })
                    })
                    if (index > -1) {
                        this.showButton = false;
                        this.ischanged = false;
                        return;
                    }
                })
            })
            item?.components?.forEach((component) => {
                this.parametersDummy?.templates?.forEach((m) => {
                    m.components.forEach((data) => {
                        data.parameters.forEach((params) => {
                            var index = component.parameters.findIndex(n => n.labParameterHeaderId == params.labParameterHeaderId && n.observedValue != params.observedValue && params.observedValue != "" && params.observedValue);
                            params.antibiotics?.forEach((anti) => {
                                component?.parameters?.forEach((param) => {
                                    var subIndex = param.antibiotics?.findIndex((x => x.observedValue != anti.observedValue && x.labParameterHeaderId == anti.labParameterHeaderId && x.labAntibioticsId == anti.labAntibioticsId));
                                    if (subIndex > -1) {
                                        this.showButton = false;
                                        this.ischanged = false;
                                        return;
                                    }
                                })
                            })
                            if (index > -1) {
                                this.showButton = false;
                                this.ischanged = false;
                                return;
                            }
                        })
                    })
                })
            })
            var index = this.parametersDummy?.templates.findIndex(Y => (Y.methodText != item.methodText && Y.labTemplateHeaderId == item.labTemplateHeaderId) || (Y.interpretationText != item.interpretationText && Y.labTemplateHeaderId == item.labTemplateHeaderId));
            if (index > -1) {
                this.showButton = false;
                this.ischanged = false;
                return;
            }
        })
        if (this.page.userAccount.roleId === 3) {
            this.showButton = false;
        }
    }
    fetchDummydata() {
        const request = {
            newLabBookingDetailId: this.newLabBookingDetailId,
            labSampleCollectionDetailId: this.labSampleCollectionDetailId,
            fromTech: this.fromDoc ? "tech" : ""
        };
        this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.labParameterForInput), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: any) => {
                this.parametersDummy = response as LabParameterInputModel;
                this.reportsDummy = new LabParameterInputModel();
                this.reportsDummy = UtilHelper.clone(this.parametersDummy);
                this.reportsDummy.templates = new Array<LabTemplateHeaderForInput>();
                this.parametersDummy.templates.forEach((template: LabTemplateHeaderForInput) => {
                    let parameterList = new Array<LabParameterInputHeaderModel>();
                    template.components.forEach((component: LabComponentHeaderForInput) => {
                        component.parameters.forEach((param: LabParameterInputHeaderModel) => {
                            if (param.observedValue != null) {
                                this.isHaveParameters = true;
                            }
                            let observedValue = +param.observedValue;
                            let range = param.selected;
                            if (range != null) {
                                let exactRange = range;
                                if (exactRange.minValue > observedValue) {
                                    param.showText = "L";
                                    param.isBold = true;
                                }
                                else if (observedValue > +exactRange.maxValue) {
                                    param.showText = "H";
                                    param.isBold = true;
                                }
                            }
                            parameterList.push(param);
                        });
                    });
                    template.parameters.forEach((param: LabParameterInputHeaderModel) => {
                        let observedValue = +param.observedValue;
                        let range = param.selected;
                        if (range != null) {
                            let exactRange = range;
                            if (exactRange.minValue > observedValue) {
                                param.showText = "L";
                                param.isBold = true;
                            }
                            else if (observedValue > +exactRange.maxValue) {
                                param.showText = "H";
                                param.isBold = true;
                            }
                        }
                        parameterList.push(param);
                    });
                    let templateToAssign = UtilHelper.clone(template) as LabTemplateHeaderForInput;

                    templateToAssign.components = new Array<LabComponentHeaderForInput>();
                    templateToAssign.parameters = new Array<LabParameterInputHeaderModel>();
                    LinqHelper.sort(parameterList, "displayOrder");
                    templateToAssign.parameters = parameterList;
                    this.reportsDummy.templates.push(templateToAssign);
                });
            })
    }
    onRemoveParameters() {
        if (!UtilHelper.isEmpty(this.reason)) {
            return this.notifyService.warning(`Please enter Reason`);
        }
        else if (!UtilHelper.isEmpty(this.reason.trim())) {
            return this.notifyService.warning(`Please enter Reason`);
        }
        const request = {
            encryptedNewLabBookingDetailId: this.encryptedNewLabBookingDetailId
        };
        request["reason"] = this.reason;
        this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.removeparametervalues), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: number) => {
                if (response > 0) {
                    this.notifyService.successToast("The Parameters Removed sucessfully");
                    this.onCloseReasonView();
                    this.onClosePopup();
                }

            })
    }
    onOpenModalViewDocument(content: TemplateRef<any>, size: string) {
        this.modalRefViewDocument = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            windowClass: "custom-modal effect-scale",
            size: size
        });
    }

    onCloseModalViewDocument() {
        if (this.modalRefViewDocument) {
            this.modalRefViewDocument.close();
            this.modalRefViewDocument = undefined;
        }
    }
    private fetchDocuments() {

        this.loading = true;
        const request = Object.assign(UtilHelper.clone(this.pagination));
        request["PatientId"] = this.demographData[0].patientId;
        request["encryptedPatientId"] = this.encryptedPatientId ? this.encryptedPatientId : null;
        request["labMainDetailId"] = this.demographData[0].labMainDetailId;
        this.httpService.post(ApiResources.getURI(ApiResources.labDocumentDetails.base, ApiResources.labDocumentDetails.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false }))
            .subscribe((response: Array<LabDocumentsDetails>) => {
                this.documents = response.filter(doc =>
                    doc.patientId === this.demographData[0].patientId &&
                    doc.newLabBookingDetailId === this.demographData[0].newLabBookingDetailId &&
                    doc.labMainDetailId === this.demographData[0].labMainDetailId

                );
                if (this.documents.length == 0) {
                    this.showDocument = false;
                } else {
                    this.showDocument = true;
                }
            });
    }
    onViewMultiplDocument(content: TemplateRef<any>) {
        this.onOpenLabMultipleReportModelForDoc(content, "xl")
    }
    onOpenLabMultipleReportModelForDoc(content: TemplateRef<any>, size: string) {
        this.modalRefViewDocument = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: size,
            windowClass: "custom-modal effect-scale"
        });
    }
    onUploadDocument() { 
        this.submitted = true;
        if (!this.uploadForm.valid || this.warningMessage) {
            this.notifyService.info("Please Enter the document name ");
            return;
        }
        this.errorMessage = undefined;
        this.submitting = true;
        const model = UtilHelper.clone(this.uploadForm.value);

        if (model) {
            const formData = new FormData();

            formData.append("PatientId", this.demographData[0].patientId.toString());
            formData.append("UploadedBy", this.page.userAccount.accountId.toString());
            formData.append("DocumentName", model["documentName"]);
            formData.append("NewLabBookingDetailId", this.demographData[0].newLabBookingDetailId.toString());
            formData.append("Description", model["description"]);
            formData.append("LabMainDetailId", this.demographData[0].labMainDetailId.toString());

        
            const files = this.files;

            if (!files || !files.length) {
                this.submitting = false;
                this.notifyService.info("Please select at least one file");
                return;
            }

            const n = files.length;
            if (n > this.maxFiles) {
                files.splice(this.maxFiles, files.length);
            }

            files.forEach((file: File, index: number) => {
                formData.append(`File${index + 1}`, file, file.name);
            });
  
            this.httpService
                .postFile(ApiResources.getURI(ApiResources.labDocumentDetails.base, ApiResources.labDocumentDetails.upload), formData)

                .pipe(finalize(() => { this.submitted = false; this.submitting = false; }))
                .pipe(takeUntil(this.page.unSubscribe))
                .subscribe(
                    () => {
                        this.files = [];
                        this.notifyService.successToast("Document uploaded successfully.");
                        this.onCloseModal();
                        this.onCloseModalDocument();
                        this.fetchDocuments();
                        this.onMutipleDocumetCloseModel();
                    },
                    (error: HttpErrorResponse) => {
                        this.errorMessage = UtilHelper.handleError(error);
                        this.notifyService.warning(this.errorMessage);
                    }
                );
        }
    }
    onOpenModalMultiple(content: TemplateRef<any>, type: ModalType, size: string, document: LabDocumentsDetails) {
        this.modalRefViewDocument = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            windowClass: "custom-modal effect-scale",
            size: size
        });
        this.buildForm(document);
    }
    onMutipleDocumetCloseModel() {
        if (this.modalRefViewDocument) {
            this.closeModelFunction(this.modalRefViewDocument);
        }
    }
    closeModelFunction(model) {
        model.close();
        model = undefined;
    }
    onFileSelect(files: Array<File>) {
        this.files = files;
    }
    onCloseModalDocument() {
        if (this.modalRefDocument) {
            this.modalRefDocument.close();
            this.modalRefDocument = undefined;
        }
    }
    onPrevious() {
        let index = this.documents.findIndex((m => m.documentDetailId === this.document.documentDetailsId) as any);
        index = index - 1;
        this.showPrevious = index !== 0;
        this.showNext = true;
        this.document = undefined;
        this.prepareDocument(this.documents[index]);
    }
    onNext() {
        let index = this.documents.findIndex((m => m.documentDetailsId === this.document.documentDetailsId) as any);
        index = index + 1;
        this.showNext = index !== this.documents.length - 1;
        this.showPrevious = true;
        this.document = undefined;
        this.prepareDocument(this.documents[index]);
    }
    private prepareDocument(document: LabDocumentsDetails) {
        this.loadingDocument = true;
        this.document = document;
        this.documentError = false;
        this.document.isImage = this.document.contentType.indexOf("pdf") < 0 && this.document.contentType.indexOf("video") < 0;
        this.document.isVideo = this.document.contentType.indexOf("video") >= 0;
        if (this.document.isImage || this.document.isVideo) {
            this.loadingDocument = true;
            this.document.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${ApiResources.getURI(ApiResources.labDocumentDetails.base, ApiResources.labDocumentDetails.downloadFileGet)}?id=${this.document.documentDetailsId}&url=${this.document.documentUrl}`);
            if (this.document.formedUrl) {
                this.loadingDocument = true;
            }
        }
        if (!this.document.isImage && !this.document.isVideo) {
            this.httpService
                .post(ApiResources.getURI(ApiResources.labDocumentDetails.base, ApiResources.labDocumentDetails.downloadFile), { ...document })
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => { this.loadingDocument = false; }))
                .subscribe(
                    (response: LabDocumentsDetails) => {
                        this.document.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64, ${response.base64}`);
                    }
                );

        } else {
            this.loadingDocument = false;
        }
    }
    toggleVideo() {
        this.videoPlayer.nativeElement.play();
    }
    onDocumentLoad() {
        this.loadingDocument = false;
    }
    onOpenModalView(content: TemplateRef<any>, document?: LabDocumentsDetails) {

        this.prepareDocument(document);
        const index = this.documents.findIndex((m => m.documentDetailsId === this.document.documentDetailsId) as any);
        if (index === 0 && this.documents.length === 1) {
            this.showPrevious = false;
            this.showNext = false;
        } else if (index === 0) {
            this.showPrevious = false;
            this.showNext = true;
        } else if (index === this.documents.length - 1) {
            this.showPrevious = true;
            this.showNext = false;
        } else {
            this.showPrevious = true;
            this.showNext = true;
        }
        this.modalRef = this.modalService.open(content, {
            keyboard: false,
            centered: true,
            windowClass: "document-view-modal custom-modal effect-scale"
        });
    }
    onDelete(document: LabDocumentsDetails) {
        const request =
        {
            documentDetailsId: document.documentDetailsId,
            patientId: document.patientId,
            documentUrl: document.documentUrl,
            newLabBookingDetailId: document.newLabBookingDetailId,
            uploadedBy: this.page.userAccount.accountId,
            modifiedBy: this.page.userAccount.accountId,
            loginRoleId: this.page.userAccount.roleId,
            loginAccountId: this.page.userAccount.accountId,
        }
        this.document = document;
        this.modifyingContent = " Deleting the selected document";
        this.notifyService.delete(() => {
            this.modifying = true;
            this.httpService
                .post(ApiResources.getURI(ApiResources.labDocumentDetails.base, ApiResources.labDocumentDetails.delete), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => { this.modifying = false; this.document = undefined }))
                .subscribe(
                    () => {
                        this.notifyService.successToast("Document deleted successfully.");
                        this.fetchDocuments();
                    }
                );
        });
    }
    onCloseModalView() {
        if (this.modalRefView) {
            this.modalRefView.close();
            this.modalRefView = undefined;
        }
    }
    OpenView(content, size) {
        this.modalRefView = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            windowClass: "custom-modal effect-scale",
            size: size
        });
    }

    removeAntibiotic(parameter: LabParameterInputHeaderModel, index: number, TemplateIndex: number, paramIndex: number, componentParamIndex?: number) {

        if (parameter.antibiotics[index].observedValue) {
            this.isRemovedAntibiotic = true;
            var oldAntibiotic;
            if (componentParamIndex == undefined) {
                oldAntibiotic = this.parametersDummy.templates[TemplateIndex].parameters[paramIndex].antibiotics?.find(x => x.labAntibioticsId == parameter.antibiotics[index].labAntibioticsId);
                if (oldAntibiotic) {
                    if (oldAntibiotic.observedValue) {
                        this.showButton = false;
                        this.ischanged = false;
                    }
                }
            } else {
                oldAntibiotic = this.parametersDummy.templates[TemplateIndex].components[componentParamIndex].parameters[paramIndex].antibiotics?.find(x => x.labAntibioticsId == parameter.antibiotics[index].labAntibioticsId);
                if (oldAntibiotic) {
                    if (oldAntibiotic.observedValue) {
                        this.showButton = false;
                        this.ischanged = false;
                    }
                }
            }
        }
        if (parameter == null || parameter == undefined) {
            return this.notifyService.warningToast('unable to remove this Antibiotic');
        }
        if (parameter.labParameterObservedValueId != 0) {
            parameter.antibiotics.splice(index, 1);
            this.notifyService.warningToast('Antibiotic Deleted Succesfully.');
        } else {
            parameter.antibiotics.splice(index, 1);
            this.notifyService.warningToast('Antibiotic Removed Succesfully.');
        }



    }
    OpenReasonView(content) {
        this.reason = null;
        this.modalReason = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            windowClass: "custom-modal effect-scale",
            size: 'm'
        });
    }
    onCloseReasonView() {
        if (this.modalReason) {
            this.modalReason.close();
            this.modalReason = undefined;
        }
    }
    onClickMicroParameter(data: LabParameterInputHeaderModel, index: number, paramIndex: number, componentParamIndex?: number) {
        if (data) {
            this.selectParameter = data.antibioticName;
            var indexvalue;
            if (componentParamIndex == undefined) {
                indexvalue = this.parameters.templates[index].parameters[paramIndex].antibiotics.findIndex(x => x.antibioticName == data.antibioticName);
            } else {
                indexvalue = this.parameters.templates[index].components[componentParamIndex].parameters[paramIndex].antibiotics.findIndex(x => x.antibioticName == data.antibioticName);
            }
            if (indexvalue > -1) {
                this.notifyService.warningToast('Antibiotic Already Exist.');
            } else {
                data.observedValue = null;
                if (componentParamIndex == undefined) {
                    this.parameters.templates[index].parameters[paramIndex].antibiotics.push(data);
                } else {
                    this.parameters.templates[index].components[componentParamIndex].parameters[paramIndex].antibiotics.push(data);
                }
                this.notifyService.successToast('Antibiotic Added Succesfully.');
            }
            var oldAntibiotic;
            if (componentParamIndex == undefined && indexvalue == -1) {
                oldAntibiotic = this.parametersDummy.templates[index].parameters[paramIndex].antibiotics?.find(x => x.labAntibioticsId == data.labAntibioticsId);
                if (oldAntibiotic) {
                    if (oldAntibiotic.observedValue) {
                        this.showButton = false;
                        this.ischanged = false;
                    }
                } else {
                    this.showButton = false;
                    this.ischanged = false;
                }
            } else if (indexvalue == -1) {
                oldAntibiotic = this.parametersDummy.templates[index].components[componentParamIndex].parameters[paramIndex].antibiotics?.find(x => x.labAntibioticsId == data.labAntibioticsId);
                if (oldAntibiotic) {
                    if (oldAntibiotic.observedValue) {
                        this.showButton = false;
                        this.ischanged = false;
                    }
                } else {
                    this.showButton = false;
                    this.ischanged = false;
                }
            }
        }
    }
    onChangeAntibiotics(templateIndex: number, parameterIndex: number, antibioticsIndex: number, componentParamIndex?: number) {
        var newValue;
        var oldValue
        if (componentParamIndex == undefined) {
            newValue = this.parameters.templates[templateIndex].parameters[parameterIndex].antibiotics[antibioticsIndex].observedValue;
            oldValue = this.parametersDummy.templates[templateIndex].parameters[parameterIndex].antibiotics[antibioticsIndex].observedValue;
        } else {
            newValue = this.parameters.templates[templateIndex].components[componentParamIndex].parameters[parameterIndex].antibiotics[antibioticsIndex].observedValue;
            oldValue = this.parametersDummy.templates[templateIndex].components[componentParamIndex].parameters[parameterIndex].antibiotics[antibioticsIndex].observedValue;
        }
        if (newValue != oldValue && newValue != "") {
            this.ischanged = false;
        } else {
            this.ischanged = true;
        }
    }

    private findLabAntibiotic() {
        const request = {};
        this.httpService.post<any>(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchLabAntiBiotic), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Array<LookUpValue>) => {
                if (response.length > 0) {
                    this.labAntibiotics = new Array<LabParameterInputHeaderModel>();
                    response.forEach((antibiotic) => {
                        var loadAntibiotic = new LabParameterInputHeaderModel();
                        loadAntibiotic.antibioticName = antibiotic.name;
                        loadAntibiotic.labAntibioticsId = antibiotic.lookupValueId;
                        this.labAntibiotics.push(loadAntibiotic);
                    });
                }

            });
    }

    private fetchAntibioticResultTypes() {
        const request = {};
        this.httpService.post<any>(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchLabAntiBioticResultTypes), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Array<LookUpValue>) => {
                this.labAntibioticsResultTypes = new Array<LookUpValue>();
                this.labAntibioticsResultTypes = response;
                if (response.length == 0) {
                    return this.notifyService.warningToast('Antibiotic Result Types not Found');
                }

            });
    }
    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }
    OnCheckIsExternal(event: boolean) {
        this.isExternal = event
    }
    OnPdf(data: any) {
        if (data) {
            this.reportData = data["reportData"];
            this.reportDataWithoutAuth = data?.reportDataWithoutAuth;
        }
    }
    onPrintPdf2() {
        if (!this.isExternal) {
            return;
        }
        let printIframe;
        let blobUrls = [];
        let data = this.reportDataWithoutAuth;

        async function printMethod() {
            const resp = await fetch(data);
            const blob = await resp.blob();
            const url = URL.createObjectURL(blob);
            blobUrls.push(url);
            if (!printIframe) {
                printIframe = document.createElement('iframe');
                printIframe.setAttribute("id", "myDOC");
                document.body.appendChild(printIframe);

                printIframe.style.position = 'absolute';
                printIframe.style.top = '0';
                printIframe.style.left = '-1000px';

                printIframe.onload = () => {
                    setTimeout(() => {
                        if (printIframe) {
                            printIframe.focus();
                            printIframe.contentWindow.print();
                        }
                    }, 100)
                }
            }
            printIframe.src = url;
        }

        printMethod();
    }

}