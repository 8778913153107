export class ReceiptReports {
    receiptCreatedBy: string;
    roleName: string;
    receiptDate: Date;
    receiptId: string;
    admissionNo: string;
    providerName: string;
    patientName: string;
    paidAmount: number;
    refundAmount: number;
    balanceAmount: number;
    admissionDate: Date;
    appointmentDate: Date;
    appointmentTime: string;
    admissionTime: string;
    appointmentNo: string;
    appointmentId: string;
    isAppointmentReceipt: string;
    receiptNo: string;
    followUpForAppointmentId: number;
    departmentName: string;
    areaType: string;
    receiptTypeId: number;
    ancCardGeneration: string;
    totalItems: number;
    finalrow: boolean;
    createdDate?: Date;
    createdByName: string;
    receiptType: string;
    receipt: string;
    isHealthCard?: boolean = false;
    refId?: string;
    payTypeName: string;
    testName: string
    umrNo: string
    remarks: string
    active: boolean
    date: string;
    serviceReceiptIds: Array<number>;
    paymentDetails: string;
    serviceReceiptNos: Array<number>;
    receiptNumbers: string;
    constructor() {
        this.serviceReceiptIds = new Array<number>();
        this.serviceReceiptNos = new Array<number>();

    }
}

export class BillDailyReport {
    payTypeName: string;
    payTypeCount: number;
    payTypeAmount: number;
    visitorName: string;
    visitTypeCount: number;
}