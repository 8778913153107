import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router, RouterEvent, NavigationEnd, Params } from "@angular/router";
import { Page, IMenuModel, IUserAccount } from "@shared/models";
import { takeUntil } from "rxjs/operators";

import { GenericResponse, GenericStatus } from "@shared/models";
import { HttpService, MenuService, AppData } from "@shared/services";
import { ApiResources } from "../../../shared/helpers";
import { MenuType } from "../../../shared/enums";
import { IAdmissionModel } from "../services/models/admission.model";
import { AdmissionFetchHelper, ICountsModel, TimelineCommunicationService } from "./shared/helper";
import { Subscription } from "rxjs";

@Component({
    selector: 'progress-report-all',
    templateUrl: "./progress-report.html",
    styleUrls: ['./progress-report.css'],
    encapsulation: ViewEncapsulation.None
})
export class ProgressReportPage implements OnInit, OnDestroy {
    page: Page;
    activeRoute: string;
    admissionId: string;
    loading: boolean;
    record: IAdmissionModel;

    countsLoading: boolean;
    isAdmission: boolean;
    menus: Array<IMenuModel>;
    counts: ICountsModel;

    communicationSubscription: Subscription;

    constructor(
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly httpService: HttpService,
        private readonly menuService: MenuService,
        private readonly admissionFetchHelper: AdmissionFetchHelper,
        private readonly timelineCommunicationService: TimelineCommunicationService,
    ) {
        this.menus = this.menuService.menus(MenuType.SubMenu, "Progress Report");
        this.page = new Page();

        this.counts = { labCount: 0, medicationCount: 0, notesCount: 0 } as ICountsModel;
    }

    ngOnInit() {
        if (window.innerWidth >= 992) {
            document.getElementById('content-page').style.setProperty('margin-left', 'calc(80px + 220px)', 'important');
        }
        if (window.innerWidth <= 992) {
            document.getElementById('content-page').style.setProperty('margin-left', '0px', 'important');
        }

        this.communicationSubscription = this.timelineCommunicationService.get.subscribe((value: boolean) => {
            if (value) {
                this.fetchCounts(false);
            }
        });

        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((params: Params) => {
                            this.admissionId = params["id"];
                            this.isAdmission = params["type"] === "a";

                            if (!this.isAdmission) {
                                this.menus = this.menus.filter(x => x.subPage !== 'discharge');
                            }

                            const url = this.router.url;
                            this.activeRoute = url.split("/")[url.split("/").length - 1];

                            this.fetch();
                            this.fetchCounts();
                        });

                    this.router.events
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe((event:any= RouterEvent) => {
                            if (event instanceof NavigationEnd) {
                                const url = this.router.url;
                                this.activeRoute = url.split("/")[url.split("/").length - 1];
                            }
                        });
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    fetch = () => {
        this.admissionFetchHelper.admissionFetch(+this.admissionId, this.isAdmission, (data: IAdmissionModel) => {
            this.record = data;
        });
    }

    fetchCounts = (showLoader = true) => {
        if (showLoader) {
            this.countsLoading = true;
        }
        
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.progressReport.base, ApiResources.progressReport.fetchCounts),
                { admissionId: +this.admissionId })
            .subscribe(
                (response: GenericResponse) => {
                    this.countsLoading = false;
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.counts = response.data as ICountsModel;
                    }
                },
                () => {}
            );
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.communicationSubscription.unsubscribe();
        document.getElementById('content-page').style.removeProperty('margin-left');
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        event.target.innerWidth;
        if (event.target.innerWidth >= 992) {
            document.getElementById('content-page').style.setProperty('margin-left', 'calc(80px + 220px)', 'important');
        }
        if (event.target.innerWidth <= 992) {
            document.getElementById('content-page').style.setProperty('margin-left', '0px', 'important');
        }
    }
}