import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class JitsiService {

    isRoomActive = false;
    ipAddress: string;

    private jitsiSource = new Subject();
    jitsiAccount: Observable<any> = this.jitsiSource.asObservable();
    call(accountId: {}) {
        this.jitsiSource.next(accountId);
    }

    private callSource = new Subject();
    callAccount: Observable<any> = this.callSource.asObservable();
    joinCall(roomName: {}) {
        this.callSource.next(roomName);
    }

    private closeSource = new Subject<boolean>();
    closeAccount: Observable<boolean> = this.closeSource.asObservable();
    closeCall(is: boolean) {
        this.closeSource.next(is);
    }

    private emergencySource = new Subject<object>();
    emergencyAccount: Observable<object> = this.emergencySource.asObservable();
    emergency(is: object) {
        this.emergencySource.next(is);
    }

    private endSource = new Subject<string>();
    endAccount: Observable<string> = this.endSource.asObservable();
    endCall(roomName: string) {
        this.endSource.next(roomName);
    }
}
