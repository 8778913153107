import { FormGroup } from "@angular/forms";

export class CompareValidator {
    static compare(lhsKey: string, rhsKey: string) {
        return (group: FormGroup) => {
            const lhsControl = group.controls[lhsKey];
            const rhsControl = group.controls[rhsKey];

            const errors = $.extend(true, {}, rhsControl.errors);
            const conditions = [];
            if (errors) {
                delete errors["notEquivalent"];
            }
            const keys = Object.keys(errors);
            keys.forEach(key => {
                conditions.push(errors[key]);
            });
            const condition = eval(conditions.join(" &&"));

            if (!condition) {
                if (rhsControl.value && lhsControl.value !== rhsControl.value) {
                    return rhsControl.setErrors({ "notEquivalent": true });
                } else {
                    return rhsControl.setErrors(null);
                }
            }
        }
    }
}