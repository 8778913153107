import { Component, Input, OnDestroy, OnInit, TemplateRef, } from '@angular/core';
import { GenericResponse, IUserAccount, Page } from '../../models';
import { Router } from '@angular/router';
import { AppData, EncounterCommunication, HttpService, NotifyService } from '../../services';
import { finalize, takeUntil } from 'rxjs';
import { Appointment } from '../../entities';
import { ApiResources } from '../../helpers';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IAdmissionModel } from '../../../areas/admin/services/models/admission.model';


@Component({
    selector: 'nurse-header',
    templateUrl: './nurse-header.html',
})

export class NurseHeaderComponent implements OnDestroy, OnInit {
    @Input() patientId: string;
    @Input() appointmentId: string;
    page: Page;
    isAdmission: boolean;
    /*  appointmentId: string;*/
    roleId: number;
    encounterCommunicationSubscription: any;
    appointment: Appointment;
    encryptedAppointmentId: string;
    loading: boolean;
    modalRef: NgbModalRef;
    comments: string;
    isPharmacyPendingIndentRecords=false;
    constructor(
        private readonly httpService: HttpService,
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly modalService: NgbModal,
        private readonly notifyService: NotifyService,

    ) {
        this.page = new Page();
      
    }
   
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.encryptedAppointmentId = url.split("/")[3];
                    this.findAppointment(this.encryptedAppointmentId);

                } else {
                    this.page.userAccount = undefined;
                }
            });     
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();

    }
    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = true;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe({
                next: (response: Appointment) => {
                    this.appointment = response;

                },
                error: () => {
                    this.notifyService.warningToast("some error occured");         
                }
            });
    }
    onback() {
        this.router.navigate(['app/nurse-module/actions/:id']);
    }

    onSubmitReadyToDischarge() {
        this.loading = true
        const request = {
            admissionId: this.appointment.appointmentId,
            roleDepartmentName: this.page.userAccount.roleDepartments[0],
            comments: this.comments,
            createdBy : this.page.userAccount.accountId
        }
        this.httpService.post(ApiResources.getURI(ApiResources.dischargeAlert.base, ApiResources.dischargeAlert.addReadyToDischarge), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: (response: number) => {
                    if (response) {
                        this.findAppointment(this.encryptedAppointmentId);
                        this.notifyService.success("You've successfully initiated the discharge process.");
                        this.onCloseModal();
                    }
                },
                error:() => {
            }
            }               
            );
    }

    onOpenModel(content: TemplateRef<any>) {      
        if (this.isPharmacyPendingIndentRecords) {
            this.notifyService.warning("There are pharmacy pending indents...");
        }
        else {
            this.onCloseModal();
            this.modalRef = this.modalService.open(content, {
                backdrop: "static",
                keyboard: false,
                centered: true,
                windowClass: "custom-modal effect-scale"
            });
        }
    }


    onCloseModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            // ignored;
        }

        this.loading = undefined;
    }

    onCancelReadyToDischarge() {
        this.loading = true
        const request = {
            admissionId: this.appointment.appointmentId,
            roleDepartmentName: this.page.userAccount.roleDepartments[0],
            createdBy: this.page.userAccount.accountId
        }
        this.httpService.post(ApiResources.getURI(ApiResources.dischargeAlert.base, ApiResources.dischargeAlert.cancelReadyToDischarge), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                {
                    next: (response: number) => {
                        if (response) {
                            this.comments = null;
                            this.findAppointment(this.encryptedAppointmentId);
                            this.notifyService.success("You've successfully cancelled the discharge process.");
                        }
                    },
                    error: () => {
                        this.notifyService.warningToast("some error occured");                    }
                }
            );
    }

    fetchPendingPharmacyIndentRecords(content: TemplateRef<any>) {
        if (!(this.appointment.appointmentId > 0)) {
            this.notifyService.warningToast("some error occured while fetching admission");
            return;
        }
        this.loading = true;
        const request = {
            admissionId: this.appointment.appointmentId
        }
        this.httpService.post(ApiResources.getURI(ApiResources.serviceOrder.base, ApiResources.serviceOrder.fetchPharmacyPendingIndents), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loading = false;
                this.onOpenModel(content)
            }))
            .subscribe(
                {
                    next: (response: boolean) => {                   
                            this.isPharmacyPendingIndentRecords = response;                                                 
                    },
                    error: () => {
                        this.notifyService.warningToast("some error occured");
                    }
                }
            );
    }
}