import { DOCUMENT } from "@angular/common";
import { Component, Inject } from "@angular/core";

@Component({
    selector: "full-screen",
    template: `
    <a class="nav-link dropdown-toggle waves-effect waves-light" [hidden]="maximized" (click)="open();" data-toggle="fullscreen" href="javascript:;">
        <i class="fe-maximize noti-icon"></i>
    </a>
    <a class="nav-link dropdown-toggle waves-effect waves-light" [hidden]="!maximized" (click)="close();" data-toggle="fullscreen" href="javascript:;">
        <i class="fe-minimize noti-icon"></i>
    </a>
    `
})
export class FullScreenComponent {
    elem: any;
    maximized: boolean;

    constructor(@Inject(DOCUMENT) private readonly document: any) {
        this.elem = document.documentElement;
        this.maximized = false;
    }

    private makeFullScreenOpen() {
        this.maximized = true;
    }

    private makeFullScreenClose() {
        this.maximized = false;
    }

    open() {
        if (this.elem.requestFullscreen) {
            this.makeFullScreenOpen();
            this.elem.requestFullscreen();
        } else if (this.elem.mozRequestFullScreen) {
            this.makeFullScreenOpen();
            this.elem.mozRequestFullScreen();
        } else if (this.elem.webkitRequestFullscreen) {
            this.makeFullScreenOpen();
            this.elem.webkitRequestFullscreen();
        } else if (this.elem.msRequestFullscreen) {
            this.makeFullScreenOpen();
            this.elem.msRequestFullscreen();
        }
    }

    close() {
        if (this.document.exitFullscreen) {
            this.makeFullScreenClose();
            this.document.exitFullscreen();
        } else if (this.document.mozCancelFullScreen) {
            this.makeFullScreenClose();
            this.document.mozCancelFullScreen();
        } else if (this.document.webkitExitFullscreen) {
            this.makeFullScreenClose();
            this.document.webkitExitFullscreen();
        } else if (this.document.msExitFullscreen) {
            this.makeFullScreenClose();
            this.document.msExitFullscreen();
        }
    }
}