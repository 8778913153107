import { ViewEncapsulation, Component, OnInit, OnDestroy, EventEmitter, Input, Output,} from "@angular/core";
import { IUserAccount, Page } from "@shared/models";
import { AppData, HttpService, NotifyService } from "@shared/services";
import { takeUntil, finalize } from "rxjs/operators";
import { ApiResources} from "@shared/helpers";
import { AdmissionTimeLine } from "../../entities";

@Component({
    templateUrl: "./admission-timeline.html",
    selector: "admission-timeline",
    styleUrls: ['./admission-timeline.css'],
    encapsulation: ViewEncapsulation.None
})



export class AdmissionTimelineWidget implements OnInit, OnDestroy {
    page: Page;
    @Input() admissionId: number;
    @Input() encryptedAdmissionId: string;
    @Input() isCloseHeader: boolean;
    loadingTimeline: boolean;
    @Output() onClose = new EventEmitter<any>();

   
    admissionTimeLine: Array<AdmissionTimeLine>
    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
    ) {
        this.page = new Page();
  
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchTimeline();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
  
    private fetchTimeline() {
        const request = {
            encryptedAdmissionId:  this.encryptedAdmissionId
        };
        this.loadingTimeline = true;
        this.httpService.get(ApiResources.getURI(ApiResources.admissionsTimeLine.base, ApiResources.admissionsTimeLine.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingTimeline = false))
            .subscribe((response: Array<AdmissionTimeLine>) => {
                this.admissionTimeLine = response;
               
            }, () => {
                this.notifyService.warningToast("Unable to fetch lab timeline.");
            });
    }

    onCloseModel() {
        this.onClose.emit();
    }
}