import { Injectable } from "@angular/core";
import { ControlType } from "../enums/control-type.enum";

@Injectable()
export class DashboardService {

    getControlType = (dataType: string) => {
        switch (dataType) {
            case "boolean":
            case "bool":
                return ControlType.Boolean;
            case "date":
                return ControlType.Date;
            case "varchar":
            case "text":
                return ControlType.Text;
            default:
                return ControlType.Number;
        }
    }
}
