import { Component, OnInit, OnDestroy, ViewEncapsulation, TemplateRef } from "@angular/core";
import { Page, IUserAccount, GenericResponse, GenericStatus, IResource } from "@shared/models";
import { MealTypes } from "@shared/entities";
import { AppData } from "../../../../../app.data";
import { takeUntil, finalize } from "rxjs/operators";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IDietPlan } from "./helpers/helper";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IAdmissionModel } from "../../../services/models/admission.model";
import { Location, DatePipe } from '@angular/common';
import { HttpService, NotifyService, ResourceService } from "../../../../../shared/services";
import { ApiResources, DateHelper } from "../../../../../shared/helpers";
import { ActivatedRoute, Params } from "@angular/router";
import { AdmissionFetchHelper, ActiveStatus } from "../../shared/helper";
import { Observable, Subject } from "rxjs";
import { ProgressReportDiet } from "./local.helper";
import { HttpErrorResponse } from "@angular/common/http";
//import { ProgressReportDiet } from "./local.helper";


@Component({
    templateUrl: "./diet-plan.html",
    encapsulation: ViewEncapsulation.None
})
export class ProgressReportDietPlanPage implements OnInit, OnDestroy {
    page: Page;
    dietPlan: Array<MealTypes>;
    loading: boolean;
    addForm: FormGroup;
    updateForm: FormGroup;
    modalRef: NgbModalRef;
    admission: IAdmissionModel;
    selected: IDietPlan;
    isMealTypeloading: boolean;
    mealTypes: Array<MealTypes>;
    dietSelected: Array<MealTypes>;
    submitted: boolean;
    submitting: boolean;
    admissionId: number;
    isAdmission: boolean;
    records: Array<IDietPlan>;
    data: Array<IDietPlan>;
    dietSubmitted: boolean;
    //dietPlans: Array<ProgressReportDiet>;
    loadingMealType: boolean;
    mealTypesAsync: Observable<Array<IDietPlan>>;
    mealTypeInput = new Subject<string>();
    protected datePipe: DatePipe = new DatePipe('en-US');
    resetSelect: boolean;
    resetRemoveDiet: boolean;
    stopDietForm: FormGroup;
    stopping: boolean;
    selectedDiet: ProgressReportDiet;
    activeStatus: ActiveStatus;
    selectedDietPlan: MealTypes;
    mealType: Array<IResource>;

    constructor(
        private readonly appData: AppData,
        private readonly modalService: NgbModal,
        private readonly formBuilder: FormBuilder,
        private readonly locationService: Location,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
        private readonly route: ActivatedRoute,
        private readonly admissionFetchHelper: AdmissionFetchHelper,
        private readonly resourceService: ResourceService

    ) {
        this.page = new Page();
        this.dietPlan = new Array<MealTypes>();
        this.dietSelected = new Array<MealTypes>();
        this.records = new Array<any>();
        //this.dietPlans = new Array<ProgressReportDiet>();
        this.buildAddForm();
        this.buildUpdateForm();
        this.buildStopDietForm();

    }

    get form() {
        return this.addForm.controls;
    }

    private buildAddForm() {
        this.addForm = this.formBuilder.group({
            mealTypeId: [null, [Validators.required]],
            startDate: [DateHelper.todayInFormat, [Validators.required]],
            medicationDurationTypeId: [1],
            duration: [null, [Validators.required]],
            dietInstructions: [null],
            isMorning: [false],
            isAfternoon: [false],
            isNight: [false]
        });
        this.addForm.get("mealTypeId").valueChanges.subscribe(() => {

            this.selectedDietPlan = undefined;

        });
    }

    private buildUpdateForm() {
        this.updateForm = this.formBuilder.group({

        })
    }

    private buildStopDietForm() {
        this.stopDietForm = this.formBuilder.group({
            reason: [null]
        });
    }

    fetchMealType = () => {
        this.isMealTypeloading = true;
        this.httpService
            .post<Array<MealTypes>>(ApiResources.getURI(ApiResources.mealTypes.base, ApiResources.mealTypes.fetchActive), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.isMealTypeloading = false))
            .subscribe(
                (response: Array<MealTypes>) => {
                    this.mealTypes = response;
                    if (this.dietSelected.length > 0) {
                        this.dietSelected.forEach((existing) => {
                            const diet = this.mealTypes.filter((item) => { return item.mealTypeId === existing.mealTypeId });
                            if (diet.length > 0) {
                                diet[0].isSelected = true;
                            }
                        });
                    }
                },
                () => {
                    this.mealTypes = new Array<MealTypes>();
                }
            );
    }

    private fetchMealTypes() {
        this.isMealTypeloading = true;
        this.resourceService.mealType()
            .pipe(finalize(() => { this.isMealTypeloading = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.mealType = response;
            });
    }

    resetAddDietPlanForm = () => {
        this.addForm.reset();
        this.resetSelect = false;
        //this.onDietRemove();
        this.addForm.patchValue({
            medicationDurationTypeId: 1,
            startDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
        });
    }

    openModifyModel = (content: TemplateRef<any>, record?: IDietPlan) => {
        if (record) {
            this.selected = record;
            this.mealTypes = record.dietplan;
        } else {
            this.fetchMealType();
            this.resetAddDietPlanForm();
        }
        this.openModelHelper(content);
    }

    openModelHelper = (content: TemplateRef<any>) => {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: "lg",
            windowClass: "custom-modal effect-scale"
        });
    }

    modelOpenHelperLarge = (content: TemplateRef<any>) => {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: "lg",
            windowClass: "custom-modal extra-large-modal effect-scale"
        });
    }

    onOpenStopModel(content: TemplateRef<any>, item: IDietPlan) {
        this.selected = item;
        this.stopDietForm.reset();
        this.openModelHelper(content);
    }

    onOpenViewModel(content: TemplateRef<any>, item: ProgressReportDiet) {
        this.selectedDiet = item;
        this.modelOpenHelperLarge(content);
    }

    onOpenUpdateModel(content: TemplateRef<any>, item: ProgressReportDiet) {
        this.selectedDiet = item;
        this.openModelHelper(content);

    }

    onCloseModal() {
        try {
            this.submitted = false;
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            // ignored;
        }
    }

    private fetch() {
        this.loading = true;
        this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.progressReportDiet.base, ApiResources.progressReportDiet.fetch), { admissionId: this.admissionId })
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: GenericResponse) => {
                if (response.status === GenericStatus[GenericStatus.Success]) {
                    var data = response.data as Array<IDietPlan>;
                    this.records = data;
                }
                else {
                    this.notifyService.warning(response.message)
                }
            }, () => {
                this.notifyService.defaultError();
            }
            );
    }

    //private fetchmealTypeAsync() {
    //    this.mealTypesAsync = this.mealTypeInput.pipe(
    //        debounceTime(500),
    //        distinctUntilChanged(),
    //        switchMap((term: string) =>
    //            term && term.length >= 2
    //                ? this.resourceService.mealTypeAsync(term).pipe(
    //                    tap(() => this.loadingMealType = true),
    //                    catchError(() => { return of([]) }),
    //                    finalize(() => this.loadingMealType = false)
    //                )
    //                : of([])
    //        )
    //    );
    //}

    onSubmit() {
        this.submitted = true;
        if (!this.addForm.valid || (!this.addForm.value.isMorning && !this.addForm.value.isAfternoon && !this.addForm.value.isNight)) {
            return;
        }
        this.submitting = true;
        const data = {
            admissionId: this.admissionId,
            createdBy: this.page.userAccount.accountId,
            records: []
        };

        const record = new ProgressReportDiet();
        record.medicationDurationTypeId = this.addForm.value.medicationDurationTypeId;
        record.duration = this.addForm.value.duration;
        var dummyStartDate = new Date(this.addForm.value.startDate);
        record.medicationDurationTypeId === 1 && dummyStartDate.setDate(dummyStartDate.getDate() + (record.duration - 1));
        record.medicationDurationTypeId === 2 && dummyStartDate.setDate(dummyStartDate.getDate() + ((record.duration * 7) - 1));
        record.medicationDurationTypeId === 3 && dummyStartDate.setDate(dummyStartDate.getDate() + ((record.duration * 30) - 1));
        record.endDate = new Date(dummyStartDate);

        data.records.push({
            //mealTypeId: this.selectedDietPlan.mealTypeId,
            mealTypeId: this.addForm.value.mealTypeId,
            startDate: this.addForm.value.startDate,
            duration: +this.addForm.value.duration,
            medicationDurationTypeId: this.addForm.value.medicationDurationTypeId,
            dietInstructions: this.addForm.value.dietInstructions,
            endDate: record.endDate,
            isMorning: this.addForm.value.isMorning ? true : false,
            isAfternoon: this.addForm.value.isAfternoon ? true : false,
            isNight: this.addForm.value.isNight ? true : false
        })

        if (this.addForm.value.duration == 0) {
            this.notifyService.warning("Duration can't be zero.");
            return;
        }
        this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.progressReportDiet.base, ApiResources.progressReportDiet.insert), data)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.submitting = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        this.onCloseModal();
                        this.fetch();
                        this.resetSelect = null;
                    }
                    else {
                        this.notifyService.warning(response.message);
                    }
                }, () => {
                    this.notifyService.defaultError();
                }
            );
    }

    onModify() {

    }

    onStopDiet = () => {
        this.stopping = true;
        const id = this.selected.progressReportDietId;
        const data = {
            id,
            by: this.page.userAccount.accountId,
            admissionId: this.admissionId,
            createdBy: this.page.userAccount.fullName,
            stopReason: this.stopDietForm.value.reason
        }

        this.httpService
            .post(ApiResources.getURI(ApiResources.progressReportDiet.base, ApiResources.progressReportDiet.stopDiet), data)
            .pipe(finalize(() => this.stopping = false))
            .subscribe(
                (response: number) => {
                    if (response > 1) {
                        this.notifyService.infoToast("Diet Plan Stopped")
                    }
                    this.fetch();
                    this.onCloseModal();

                },
                (error: HttpErrorResponse) => {
                    if (error) {
                        this.notifyService.warning(error.error);
                    } else {
                        this.notifyService.error("Error occured.");
                    }
                }
            );
    }

    onDietSelect(diet?: MealTypes) {
        this.selectedDietPlan = this.selectedDietPlan && this.selectedDietPlan.mealTypeId === diet.mealTypeId ? undefined : diet;

        diet.isSelected = true;
        this.resetSelect = true
        this.dietSelected.push(diet);

    }

    onDietRemove(diet?: MealTypes) {
        diet.isSelected = false;
        // this.resetRemoveDiet = diet.isSelected
        this.dietSelected.push(diet);
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;

                    this.route.parent.paramMap
                        .subscribe((params: Params) => {
                            this.admissionId = +params["params"]["id"];
                            this.isAdmission = params["params"]["type"] === "a";
                            this.loading = true;
                            this.admissionFetchHelper.admissionFetch(this.admissionId, this.isAdmission, (data: IAdmissionModel) => {
                                this.admission = data;
                                //this.fetchmealTypeAsync();
                            });
                            this.fetch();
                            this.fetchMealTypes();
                        });
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }

    onBack = () => {
        this.locationService.back();
    }
}