export class PhysiotherapyEncounterFullTranscript {
    appointmentId: number;
    appointmentNo: string;
    patientId: number;
    encryptedPatientId: string;
    encounterId?: number;
    patientName: string;
    patientAge?: number;
    patientDateOfBirth?: Date;
    patientGender: string;
    patientMobile: string;
    patientThumbnailUrl: string;
    patientCountryCode: string;
    appointmentDate: Date;
    appointmentTime: object;
    providerName: string;
    appointmentTimeString: string;
    problemList: string;
    briefRos: string;
    geneticScreening: string;
    infectionHistory: string;
    followUp: string;
    medications: string;
    notes: string;
    physiotherapyEncounterId: number;
    nextVisitOrder: string;
    signature: string;
    clinicPicture: string;
    practiceLocation: string;
    practiceName: string;
    specializationName: string;
    providerNo: string;
    educations: string;
    complaints: string;
    encounterDate: Date;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
    orderPrescription: string;
    refferalOrder: string;
    specialFeature: string;
    umrNo: string;
    email: string;
    birthHistory: string;
    familyHistory: string;
    reminder: string;
    physioTherapyTemplate: string;
    outsideTest: string;
    referralForm: string;
    friendlyName: string;
    measure: string;
    providerMobile: string;
}