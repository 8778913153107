export class ExceptionLog {
    //exceptionLogId: number;
    //description: string;
    //logDate: Date;
    //logTime: Date;
    //logTimestamp: Date;
    //logFrom: string;
    //logRoute: string;
    //message: string;
    //totalItems: number;
    //logPath?: string;

    exceptionLogId: number;
    description?: string;
    logDate: Date;
    logTime: Date;
    logTimestamp: Date;
    logFrom: string;
    logRoute?: string;
    message?: string;
    totalItems: number;
    logPath?: string;
   
}
