
<form [formGroup]="uploadDocumentForm" (ngSubmit)="onUploadDocument()">
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-file-multiple mr-1"></i>Upload Documents</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <div class="row appointment-form bg-pattern bg-light mb-3">
            <div class="col-md-4">
                <div class="form-group mb-0">
                    <label class="mb-1">Document Name <code>*</code></label>
                    <input type="text" autofocus formControlName="documentName" maxlength="100" block autocomplete="nope" class="form-control" [ngClass]="{ 'is-invalid': (submitted && form.documentName.errors) }" placeholder="Enter document name" />
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group mb-0">
                    <label class="mb-1">Document Type <code>*</code></label>
                    <input type="text" class="form-control" formControlName="documentType" [ngClass]="{ 'is-invalid': submitted && form.documentType.errors }" placeholder="Enter document type" />

                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group mb-0">
                    <label class="mb-1">Description</label>
                    <textarea rows="1" cols="20" formControlName="description" block maxlength="250" autocomplete="nope" class="form-control" placeholder="Enter document description"></textarea>
                </div>
            </div>
        </div>
        <file-dropper [expandable]="true"
                      [maxFiles]="maxFiles"
                      [maxFileSize]="10000"
                      [accept]="'application/pdf, image/jpeg, image/pjpeg, image/png, image/gif, video/mp4'"
                      (onSelectEmitter)="onFileSelect($event)">
        </file-dropper>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-sm" (click)="onCloseModal();">Cancel</button>
        <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm ml-1">
            <span *ngIf="submitting">
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                Please wait..
            </span>
            <span *ngIf="!submitting">Submit</span>
        </button>
    </div>
</form>
