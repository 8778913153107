export class ProviderLocation {
    providerLocationId: number;
    providerLocationOperationId: number;
    providerId: number;
    locationId: number;
    practiceLocationName: string;
    practiceId: number;
    practiceName: string;
    consultationDuration: number;
    consultationCharges: number;
    telemedicineDuration: number;
    inPatientDuration: number;
    outPatientDuration: number;
    casualtyDuration: number;
    followUpDays: number;
    telemedicineCharges: number;
    availableDays: string;
    availability: string;
    currencySymbol: string;
    followUpDaysForIp: number;
    durations: Array<duration>;
    duration: string;
    isTelemedicine: boolean;
    isOnlineConsultation: boolean;

}
class duration {
    typeId: number;
    value: string;
}