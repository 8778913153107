import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit, TemplateRef, ViewEncapsulation, Input, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { PatientDocument } from "@shared/entities";
import { ModalType } from "@shared/enums";
import { ApiResources, UtilHelper } from "@shared/helpers";
import { IResource, IUserAccount, Page, ProgressData, Pagination, InternalMedicineEncounter } from "@shared/models";
import { HttpService, NotifyService, ResourceService, AppData } from "@shared/services";
import { finalize, takeUntil } from "rxjs/operators";
import { PatientDocumentView } from "@shared/entities/patient-view-document.entity";
import { DomSanitizer } from '@angular/platform-browser';

class TrackBy {
    document(item: PatientDocument) {
        return item.patientDocumentId;
    }

    resource(item: IResource) {
        return item.id;
    }
}

@Component({
    templateUrl: "./documents.html",
    selector: "documents",
    encapsulation: ViewEncapsulation.None
})
export class PatientDocumentsWidget implements OnInit, OnDestroy {
    //@Input() account: IUserAccount;
    @Input() isAdmission: boolean;
    @Input() encryptedPatientId: string;
    @Input() encryptedAppointmentId: string;

    @ViewChild("videoPlayer", { static: false }) videoPlayer: ElementRef;
    @ViewChild('templateViewDocument') public templateViewDocument: TemplateRef<any>;
    page: Page;
    modalType = ModalType;
    trackBy: TrackBy;
    pagination: Pagination;

    loading: boolean;
    documents: Array<PatientDocument>;
    document: PatientDocument;

    downloadDocument: PatientDocument;
    viewDocument: PatientDocumentView;
    loadingCategories: boolean;
    categories: Array<IResource>;
    message: boolean;
    modalRef: NgbModalRef;
    uploadForm: FormGroup;
    maxFiles = 10;
    files: Array<File>;
    submitting: boolean;
    submitted: boolean;
    errorMessage: string;
    warningMessage: string;
    modifyingContent: string;

    patientId: number;
    loadingDocument: boolean;
    documentError: boolean;
    showPrevious: boolean;
    showNext: boolean;

    modifying: boolean;
    showDocument: boolean;
    index: number;
    isDocumentView: boolean;
    enLargeScreen: boolean;
    documenttype: any;
    filterdocuments: Array<PatientDocument>;
    dummyDocuments: Array<PatientDocument>;
    name: any
    dummydataone: Array<PatientDocument>;
    constructor(
        private readonly httpService: HttpService,
        private readonly modalService: NgbModal,
        private readonly notifyService: NotifyService,
        private readonly formBuilder: FormBuilder,
        private readonly resourceService: ResourceService,
        private readonly appData: AppData,
        private readonly sanitizer: DomSanitizer
    ) {
        this.page = new Page();
        this.trackBy = new TrackBy();
        this.documents = [];
        this.filterdocuments = new Array<PatientDocument>;
        this.initPagination();
    }

    private initPagination() {
        this.pagination = new Pagination();
        this.pagination.pageIndex = 1;
        this.pagination.pageSize = 12;

    }

    toggleVideo() {
        this.videoPlayer.nativeElement.play();
    }

    onFilter() {
        this.pagination = new Pagination();
        this.pagination.pageIndex = 1;
        this.pagination.pageSize = 12;
        this.filterdocuments = []
        this.dummyDocuments.forEach(x => {
            if ((this.name ? x.documentName.includes(this.name) : true) && (this.documenttype ? x.documentType == this.documenttype : true)) {
                this.filterdocuments.push(x);
            }
        })
        if (this.filterdocuments.length > 0) {
            this.documents = this.filterdocuments

        } if ((!this.name || this.name == "") && (!this.documenttype || this.documenttype == null)) {
            this.documents = this.dummyDocuments
            UtilHelper.applyPagination(this.documents, this.pagination);
            this.onNextPage();
        }
    }

    private fetchDocuments() {
        this.loading = true;
        const request = Object.assign(UtilHelper.clone({ encryptedPatientId: this.encryptedPatientId }));
        this.httpService.post(ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false }))
            .subscribe((response: Array<PatientDocument>) => {
                this.documents = response;
                this.dummyDocuments = response;
                this.dummydataone = response;
                this.patientId = this.documents[0].patientId;
                UtilHelper.applyPagination(this.documents, this.pagination);
                this.onNextPage();
            });
    }

    private fetchCategories() {
        this.loadingCategories = true;
        this.loading = true;
        this.resourceService.documentCategories()
            .pipe(finalize(() => { this.loadingCategories = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.categories = response;
            });
    }

    private find(appointmentId: string) {
        this.loading = true;
        const request = { encryptedAppointmentId: appointmentId, isAdmission: this.isAdmission };
        this.httpService.post<InternalMedicineEncounter>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: InternalMedicineEncounter) => {
                this.encryptedPatientId = response.encryptedPatientId;
                this.fetchDocuments();
            });
    }

    private buildForm(document?: PatientDocument) {
        this.uploadForm = this.formBuilder.group({
            patientDocumentId: [document && document.patientDocumentId ? document.patientDocumentId : 0, [Validators.required]],
            documentName: [document && document.documentName ? document.documentName : "", [Validators.required]],
            documentType: [document && document.documentType ? document.documentType : null, [Validators.required]],
            description: [document && document.description ? document.description : null]
        });

        if (document && document != null) {
            const getTypeControl = this.uploadForm.controls["documentType"];
            getTypeControl.disable();
            getTypeControl.updateValueAndValidity();
        }
    }

    get form() { return this.uploadForm.controls }

    private prepareDocument(document: PatientDocument) {
        this.loadingDocument = true;
        this.document = document;
        this.documentError = false;
        this.document.isImage = this.document.contentType.indexOf("pdf") < 0 && this.document.contentType.indexOf("video") < 0;
        this.document.isVideo = this.document.contentType.indexOf("video") >= 0;

        if (this.document.isImage || this.document.isVideo) {
            this.document.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.downloadFileGet)}?id=${this.document.patientDocumentId}&url=${this.document.documentUrl}`);
        }

        if (!this.document.isImage && !this.document.isVideo) {
            this.httpService
                .post(ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.downloadFile), { ...document })
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => { this.loadingDocument = false; }))
                .subscribe(
                    (response: PatientDocument) => {
                        this.document.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64, ${response.base64}`);
                    }
                );

        } else {
            this.loadingDocument = false;
        }
    }

    onDocumentLoad() {
        this.loadingDocument = false;
    }

    onDocumentError() {
        this.loadingDocument = false;
        this.documentError = true;
    }

    onOpenModal(content: TemplateRef<any>, type: ModalType, document?: PatientDocument) {
        if (type === ModalType.View) {
            this.prepareDocument(document);
            const index = this.documents.findIndex((m => m.patientDocumentId === this.document.patientDocumentId) as any);
            if (index === 0 && this.documents.length === 1) {
                this.showPrevious = false;
                this.showNext = false;
            } else if (index === 0) {
                this.showPrevious = false;
                this.showNext = true;
            } else if (index === this.documents.length - 1) {
                this.showPrevious = true;
                this.showNext = false;
            } else {
                this.showPrevious = true;
                this.showNext = true;
            }

            this.modalRef = this.modalService.open(content, {
                keyboard: false,
                centered: true,
                windowClass: "document-view-modal custom-modal effect-scale"
            });
        } else if (type === ModalType.Edit) {
            this.document = document;
            this.buildForm(document);

            this.modalRef = this.modalService.open(content, {
                keyboard: false,
                centered: true,
                windowClass: "custom-modal effect-scale",
                backdrop: "static"
            });
        } else {
            this.buildForm();

            this.modalRef = this.modalService.open(content, {
                keyboard: false,
                centered: true,
                size: "lg",
                windowClass: "custom-modal effect-scale",
                backdrop: "static"
            });
        }
    }
    onDelete(document: PatientDocument) {

        this.document = document;
        this.modifyingContent = " Deleting the selected document";

        this.notifyService.delete(() => {
            this.modifying = true;
            this.httpService
                .post(ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.delete), { patientDocumentId: document.patientDocumentId, patientId: document.patientId, loginRoleId: this.page.userAccount.roleId, loginAccountId: this.page.userAccount.accountId, documentUrl: document.documentUrl })
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => { this.modifying = false; this.document = undefined }))
                .subscribe(
                    () => {
                        this.notifyService.successToast("Document deleted successfully.");
                        this.fetchDocuments();
                    }
                );
        }, () => { this.document = undefined });
    }



    onPrevious() {
        let index = this.documents.findIndex((m => m.patientDocumentId === this.document.patientDocumentId) as any);
        index = index - 1;
        this.showPrevious = index !== 0;
        this.showNext = true;
        this.document = undefined;
        this.prepareDocument(this.documents[index]);
    }
    onNext() {
        let index = this.documents.findIndex((m => m.patientDocumentId === this.document.patientDocumentId) as any);
        index = index + 1;
        this.showNext = index !== this.documents.length - 1;
        this.showPrevious = true;
        this.document = undefined;
        this.prepareDocument(this.documents[index]);
    }

    onProgress(progressData: ProgressData) {
        if (progressData.loaded === progressData.total) {
            this.loadingDocument = false;
        }
    }

    onCloseModal() {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = undefined;
            this.submitted = undefined;
            this.submitting = undefined;
            this.errorMessage = undefined;
            this.warningMessage = undefined;
            this.files = [];
            this.document = undefined;
            this.loadingDocument = undefined;
            this.documentError = undefined;
            this.showNext = undefined;
            this.showPrevious = undefined;
            this.modifying = undefined;
            this.modifyingContent = undefined;
            this.showDocument = false;
        }
    }

    onNextPage() {
        $("body,html").animate({ scrollTop: 0 });
        this.documents = [];
        this.pagination.currentPage = this.pagination.pageIndex;
        for (let i = 0; i < this.dummydataone.length; i++) {
            var N = this.pagination.pageIndex * this.pagination.pageSize - 12;
            if (i >= N && i < (N + 12)) {
                this.documents.push(this.dummydataone[i])
                this.pagination.currentItems = i + 1;

            }
        }
    }

    onFileSelect(files: Array<File>) {
        this.files = files;
    }

    onSubmit() {
        this.submitted = true;
        if (!this.uploadForm.valid || this.warningMessage) {
            return;
        }

        this.errorMessage = undefined;
        this.submitting = true;
        const model = UtilHelper.clone(this.uploadForm.value);

        if (model["patientDocumentId"] === 0) {
            const formData = new FormData();
            formData.append("PatientId", this.patientId.toString());
            formData.append("UploadedBy", this.page.userAccount.accountId.toString());
            formData.append("DocumentName", model["documentName"]);
            formData.append("DocumentType", model["documentType"]);
            formData.append("Description", model["description"]);

            const files = this.files;

            if (!files || !files.length) {
                this.submitting = false;
                this.notifyService.info("Please select at least one file");
                return;
            }

            const n = files.length;
            if (n > this.maxFiles) {
                files.splice(this.maxFiles, files.length);
            }

            files.forEach((file: File, index: number) => {
                formData.append(`File${index + 1}`, file, file.name);
            });

            this.httpService
                .postFile(ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.upload), formData)
                .pipe(finalize(() => { this.submitted = false, this.submitting = false; }))
                .pipe(takeUntil(this.page.unSubscribe))
                .subscribe(
                    () => {
                        this.files = [];
                        this.notifyService.successToast("Document uploaded successfully.");
                        this.onCloseModal();
                        this.fetchDocuments();
                    },
                    (error: HttpErrorResponse) => {
                        this.errorMessage = UtilHelper.handleError(error);
                        this.notifyService.warning(this.errorMessage);
                    }
                );
        } else {

            model["modifiedBy"] = this.page.userAccount.accountId;

            model["patientId"] = this.patientId;
            delete model["file"];
            this.httpService
                .put(ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.update), model)
                .pipe(finalize(() => { this.submitted = false, this.submitting = false; }))
                .pipe(takeUntil(this.page.unSubscribe))
                .subscribe(
                    () => {
                        this.notifyService.successToast("Document details updated successfully.");
                        this.onCloseModal();
                        this.fetchDocuments();
                    },
                    (error: HttpErrorResponse) => {
                        this.errorMessage = UtilHelper.handleError(error);
                        this.notifyService.warning(this.errorMessage);
                    }
                );
        }
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((account: IUserAccount) => {
                if (account) {
                    this.page.userAccount = account;
                    this.fetchCategories();
                    if (this.encryptedAppointmentId) {
                        this.find(this.encryptedAppointmentId);
                    } else {
                        this.fetchDocuments();
                    }
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
}