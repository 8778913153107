export class ModuleChargeModel {
    locationId: number;
    modulesMasterId: number;
    moduleName: string;
    moduleIcon: string;
    referenceId: number;
    chargeName: string;
    chargeGroupId?: number;
    chargeGroupName: string;
    departmentId?: number;
    departmentName: string;
    repeatTypeId?: number;
    repeatTypeName: string;

    quantity: number;
    isFree: boolean;
    amount?: number;
    isSelected: boolean;
    packageModuleDetailId: number;
}