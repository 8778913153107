<div class="upload-container">
    <label class="mb-1">Upload Instructions</label>
    <ol class="upload-instructions">
        <li>&nbsp;-&nbsp;Only<code class="text-primary">&nbsp;pdf, image and video (.jpg, .jpeg, .png, .gif and .mp4)</code>&nbsp;files are acceptable.</li>
        <li>&nbsp;-&nbsp;Maximum size of a file should be<code class="text-primary">&nbsp;10 MB</code>.</li>
        <li>&nbsp;-&nbsp;Maximum of<code class="text-primary">&nbsp;{{maxFiles}}&nbsp;</code>files can be allowed at a time.</li>
        <li>&nbsp;-&nbsp;If you upload more than {{maxFiles}} files then<code class="text-primary">&nbsp;first {{maxFiles}}&nbsp;</code>will be considered.</li>
    </ol>
</div>
<ngx-dropzone class="file-dropper" [expandable]="expandable" [disableClick]="files.length >= maxFiles" [maxFileSize]="maxFileSize*1024" [accept]="accept" (change)="onSelect($event)">
    <ngx-dropzone-label>
        <div class="d-flex justify-content-center flex-column align-items-center">
            <i class="fe-upload-cloud upload-icon"></i>
            <p class="upload-text">Drag and drop files or click here</p>
        </div>
    </ngx-dropzone-label>
    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label>
            <div class="label-container">
                <h4 class="file-name mb-0 mt-0 font-weight-normal font-13" [textContent]="f.name"></h4>
                <div>
                    <span class="file-type" [textContent]="f.type"></span>
                    <span class="file-size" [textContent]="'(' + (f.size | formatBytes) + ')'"></span>
                </div>
            </div>
        </ngx-dropzone-label>
    </ngx-dropzone-preview>
</ngx-dropzone>
<p class="invalid-feedback show-must" role="alert" *ngIf="rejectedReason">
    {{rejectedFiles.length}} {{rejectedFiles.length > 0 ? 'files' : 'file'}} has been rejected because of invalid {{rejectedReason}}.
</p>