
export class PharmacyProductSubType {
   
    pharmacyProductSubTypeId: number;
    subTypeName: string;
    pharmacyProductTypeId: number;
    typeName: string;
    active: boolean;
    isGeneralItem: boolean;
    createdBy: number;
    createdByName: string;
    createdDate: Date;
    modifiedBy: number | null;
    modifiedByName: string;
    modifiedDate: Date | null;
    modifiedByRole: string;
    createdByRole: string;

}