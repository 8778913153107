import { Directive, HostListener } from "@angular/core";
import { KeyCodesHelper, RegExHelper } from "@shared/helpers";

@Directive({
    selector: "[titleOnly]"
})
export class TitleOnlyDirective {
    @HostListener("keydown", ["$event"])
    onKeyDown(e: KeyboardEvent) {
        const keys = KeyCodesHelper.default.concat(KeyCodesHelper.alphabets).concat(KeyCodesHelper.specialChars);
        if (keys.indexOf(e.keyCode) === -1) {
            event.preventDefault();
        }

        if (RegExHelper.titleOnly.test(e.key))
            return;
        else
            event.preventDefault();
    }
}