
export class CommonEncounter {
    commonEncounterId?: number;
    admissionId: number;
    admissionNo: string;
    admissionDate: string;
    admssionTime: string;
    encryptedPatientId: string;
    appointmentId: number;
    encounterDate: Date;
    birthHistory: string;
    gpla: string;
    measureData: string;    
    active: boolean;
    createdBy: number;
    createdDate: Date
    modifiedBy: number;
    modifiedDate: Date;   
    providerNo: string;
    educations: string;
    patientName: string;
    patientAge?: number;
    patientDateOfBirth?: Date;
    patientGender: string;
    patientMobile: string;
    patientThumbnailUrl: string;
    patientCountryCode: string;
    appointmentSymptoms: string;
    providerName: string;
    signature: string;
    appointmentTimeString: string;
    appointmentDate: string;
    appointmentNo: number;   
    patientId: number;
    partner: string;   
    patientNo: string;
    providerEmail: string;
    umrNo: string;
    emergencyTriage: string;
    nursingERForm: string;    
    telePhoneTriageAssessment: string;
    encryptedAppointmentId: string;
    anaesthesiaRecordPostOP: string;
}