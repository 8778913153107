export enum DietEncounterType {
    dietPlanPrescribed = 1, 
    opNutritionScreeningForm,
    opNutritionalAssessmentForm,   
    ipNutritionalScreeningForm,
    pediatricCDCScreeningForm,
    dietGuideLines,   
    syndromesFormP,
    ordersPrescription,
    referralForm,
    speacialFeature,
    AddendumForm,
    ReferralOrder,
    Confidential,
    Reminder,
    Measures,  
    antinantalRisk,
    FutureAppointments,
    OutSideTests,
    TelePhoneTriageAssessment
}