export class Specialization {
    specializationUnitId: number;
    specializationId: number;
    specializationIconId: number;
    specializationName: string;
    specializationNames: string;
    specializationDescription: string;
    createdDate: Date;
    createdBy: number;
    active: boolean;
    modifiedBy: number;
    modifiedDate: Date;
    url: string;
    iconName: string;
    priority: number;
    locationIds: string;
    locationNames: string;
    encounterTypeId: number;
    encounterName: string;
    consultationTypeId?: number;
    consultationTypeName: string;
    specializationIds: string;
    modifiedByRole: string;
    createdByRole: string;
    loginRoleId: number;
    locationId: number;

} 