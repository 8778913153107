import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@src/app/shared/guards';
import { ConsentFromPage } from './consent-forms.page';
import { ConsentFormsMainPage } from './page/consent-froms-main-page';
const routes: Routes = [
    {
        path: "",
        data: { title: "ConsentFrom", showSidebar: true },
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: ConsentFromPage,
        children: [
           { path: "", redirectTo: "consent-forms", pathMatch: "full" },
            { path: "consent-form-main", component: ConsentFormsMainPage, data: { title: "Consent Form Main" } },

        ]
           
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ConsentFromRoutingModule { }
