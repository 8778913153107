export class Reasons {
    reasonsId: number;
    reason: string;
    createdByName: string;
    createdDate: Date;
    modifiedByName: string;
    modifiedDate: Date;
    active: boolean;
    createdByRole: string;
    modifiedByRole: string;
}