export class OTRoomAvailability {
    otRoomAvailabilityId: number;
    otRoomId: number;
    locationId: number;
    roomName: string;
    practiceName: string;
    createdBy: number;
    createdByName: string;
    modifiedBy: number;
    ModifiedByName: string;
    availableDays: string;
    availability: string;
    machineName: string;

    durations: Array<duration>;
    duration: string;
    fromDate: string;
    toDate: string;
    endDate: string;
    startDate: string;
    fromTime: any;
    toTime: any;
    scanAvailabilityStatus: number;
    scanAvailabilityReason: number;   
}
class duration {
    typeId: number;
    value: string;
}