import { Component, Input, OnDestroy, OnInit, } from '@angular/core';

import { Appointment, } from '../../entities';
import { ApiResources } from '../../helpers';
import { IEncounterResource, IUserAccount,  Page } from '../../models';
import { AppData, EncounterCommunication, HttpService, TimelineToggleService } from '../../services';
import { Subscription, finalize, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { OB } from '../../../areas/admin/full-transcript/pages/ob-report/ob.namespace';
import { OBEncounterType } from '../../enums';

@Component({
    selector: 'genetic-header',
    templateUrl: './genetic-header.html',
    styleUrls: ['./genetic-header.css']
})
export class GeneticHeaderComponent implements OnDestroy, OnInit {
    @Input() patientId: string;
    @Input() appointmentId: string;
    appointment: Appointment;
    page: Page;
    isAdmission: any;
    roleId: number;
    specialFeature: OB.SpecialFeature;   
    encounterCommunicationSubscription: Subscription;
    obEncounter: IEncounterResource;
    appointments: any;
    appointmentIds: string;
    sideNav: boolean;
    name: string;
    constructor(
        private readonly httpService: HttpService,
        private readonly appData: AppData,
        private readonly router: Router,
        private readonly encounterCommunication: EncounterCommunication,      
        public timelineService: TimelineToggleService
    ) {

        this.page = new Page();
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.isAdmission = url.split("/")[4] === "a";
                    this.appointmentId = url.split("/")[url.split("/").length - 3];
                    this.roleId = this.page.userAccount.roleId;
                    this.encounterCommunicationSubscription = this.encounterCommunication.geneticEncounter.subscribe(() => {
                        if (this.appointmentId == "b" || this.appointmentId == "a") {
                            var currentUrl = this.router.url;
                            this.appointmentIds = currentUrl.split("/")[currentUrl.split("/").length - 4];
                            this.findAppointment(this.appointmentIds);
                        }
                      
                    });
                    this.name = this.page.userAccount.fullName;
                } else {
                    this.page.userAccount = undefined;
                }
                this.findAppointment(this.appointmentId);
                this.findDashboard();
            });
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.encounterCommunicationSubscription.unsubscribe();
    }
    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointment = response;
                this.findDashboard();
            });
      
            
    }

    private findDashboard() {
        const request = { encryptedAppointmentId: this.appointmentId, isAdmission: this.isAdmission, type: OBEncounterType.SpeacialFeature };

        request["isAdmission"] = this.isAdmission;
        this.httpService.post<IEncounterResource>(ApiResources.getURI(ApiResources.gynEncounter.base, ApiResources.gynEncounter.fetchCommonEncounter), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: IEncounterResource) => {
                this.obEncounter = response;
                this.specialFeature = JSON.parse(this.obEncounter.jsonString);

            });
    }
    toggleIt() {
        this.timelineService.toggle();
        if (this.timelineService.showBox == false) {
            $("body")[0].setAttribute("data-timeline-size", "timelineabsent");
        } else {
            $("body")[0].setAttribute("data-timeline-size", "timeline");
        }
    }
    toggleSideNav() {
        $(".sidebar-main-menu")[0].classList.toggle("removeLeft");
        if ($("body")[0].getAttribute('data-sidebar-size') === "default") {
            $("body")[0].setAttribute("data-sidebar-size", "condensed");
            this.sideNav = true;
        } else if ($("body")[0].getAttribute('data-sidebar-size') === "condensed") {
            $("body")[0].setAttribute("data-sidebar-size", "default");
            this.sideNav = false;
        }
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }
}