<div class="row pt-3 topHeading">
    <div class="col-12" *ngIf="loading">
        <div class="d-flex align-items-center">
            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
            <span class="ml-2">Please wait while loading ....</span>
        </div>
    </div>
    <div class="col-12" *ngIf="!loading && store">
        <table width="100%">
            <tr>
                <th style="color: #000;padding: 0;text-align: center; vertical-align: middle;">
                    {{store.name}}
                </th>
            </tr>
            <tr *ngIf="store.hospitalName">
                <th style="color: #000;padding: 0;text-align: center; vertical-align: middle;">
                    {{store.hospitalName}}
                </th>
            </tr>
            <tr>
                <th style="color: #000;padding: 0;text-align: center; vertical-align: middle;">
                    {{store.location}}, PIN {{store.pinCode}}
                </th>
            </tr>
            <tr>
                <th style="color: #000;padding: 0;text-align: center; vertical-align: middle;">
                    <b>GSTIN</b>:{{store.gstNumber}}
                </th>
            </tr>
        </table>
    </div>
</div>
