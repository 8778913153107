export interface ISlot {
    [x: string]: any;
    slotValue: string;
    slotName: string;
    status: string;
    charge: number;
    duration: number;
    providerAvailabilityId: number;
    value: string;
    id?: string;
    doctorSpecializationChargeModuleDetailsId: number;
    slotType: string;
    scanMachineMasterId?: number;
    endTime?: string;
    availableDate?: Date;
    otRoomId?: number;
    chargeTypesId: number;
    slotValue24HoursEnd: string;
    isSlotSelected?: boolean;
    tokenNumber: number;
    sessionId: number;
    appointmentId: number;
    isFreeSlot: boolean;
    sessionTypeId: number;
}

export interface IProviderCharges {
    consultationCharges?: number;
    telemedicineCharges?: number;
    inPatientCharges?: number;
    outPatientCharges?: number;
    casualtyCharges?: number;
}

export enum SlotType {
    generalSLots = 'G',
    newPatientSlots = 'N',
    offlinePatientSlots = 'O',
    breakSlots = 'B',
}
export interface IRoomSlot {
    slots: Array<ISlot>;
    otRoom: string;
}
