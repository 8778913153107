import { Injectable, inject } from "@angular/core";
import { Database, ref, onValue } from '@angular/fire/database';

type PreviousValue = {
    name: string;
    value: any
}
type PreviousRoleAlertValue = {
    name: string;
    value: any
}
type PreviousAlertValue = {
    id: number;
    value: any
}
type PreviousLabHodValue = {
    name: string;
    value: any
}
@Injectable()
export class RealTimeNodeService {
    private database: Database = inject(Database);
    private previousValues: Array<PreviousValue> = [];
    private previousAlertValues: Array<PreviousAlertValue> = [];
    private previousRoleAlertValues: Array<PreviousRoleAlertValue> = [];
    private previousLabHodValue: Array<PreviousLabHodValue> = [];
  
    subscribe = (roleDepartmentName: string, callback: Function, errorCallback: Function) => {
        if (!this.previousValues.find(x => x.name == roleDepartmentName)) {
            this.previousValues.push({ name: roleDepartmentName, value: null } as PreviousValue);
        }
        onValue(
            ref(this.database, 'alerts/' + roleDepartmentName),
            (snapshot) => {
            if (!snapshot.exists()) {
                errorCallback();
                }
                const data = snapshot.val();
                const found = this.previousValues.find(x => x.name == roleDepartmentName);
                if (!found || found?.value !== data) {
                    callback && callback(data);
                }
            },
            (error) => {
                errorCallback();
                console.error("Error in subscribePage:", error);
            }
        );
    }

    subscribeRoleAlerts = (roleName: string, callback: Function, errorCallback: Function) => {
        if (!this.previousRoleAlertValues.find(x => x.name == roleName)) {
            this.previousRoleAlertValues.push({ name: roleName, value: null } as PreviousRoleAlertValue);
        }
        onValue(
            ref(this.database, 'roleAlerts/' + roleName),
            (snapshot) => {
                if (!snapshot.exists()) {
                    errorCallback();
                }
                const data = snapshot.val();
                const found = this.previousRoleAlertValues.find(x => x.name == roleName);
                if (!found || found?.value !== data) {
                    callback && callback(data);
                }
            },
            (error) => {
                errorCallback();
                console.error("Error in subscribePage:", error);
            }
        );
    }

    subscribeUserAlerts = (AccountId: number, callback: Function, errorCallback: Function) => {
        if (!this.previousAlertValues.find(x => x.id == AccountId)) {
            this.previousAlertValues.push({ id: AccountId, value: null } as PreviousAlertValue);
        }
        onValue(
            ref(this.database, 'userAlerts/' + AccountId),
            (snapshot) => {
                if (!snapshot.exists()) {
                    errorCallback();
                }
                const data = snapshot.val();
                const found = this.previousAlertValues.find(x => x.id == AccountId);
                if (!found || found?.value !== data) {
                    callback && callback(data);
                }
            },
            (error) => {
                errorCallback();
                console.error("Error in subscribeUserAlertPage:", error);
            }
        );
    }

    subscribePage = (pageName: string, callback: Function) => {
        onValue(
            ref(this.database, 'pages/' + pageName),
            (snapshot) => {
                const data = snapshot.val();
                 callback && callback(data);
            },
            (error) => {
                    console.error("Error in subscribePage:", error);
            }
        );
    }

    refreshQueue = (node: string, callback: Function) => {
        onValue(
            ref(this.database, node),
            (snapshot) => {
                const data = snapshot.val();
                callback && callback(data);
            },
            (error) => {
                console.error("Error in refreshQueue", error);
            }
        );
    }
    subscribeToLabHodAlerts = (roleName: string, callback: Function, errorCallback: Function) => {
        if (!this.previousLabHodValue.find(x => x.name == roleName)) {
            this.previousLabHodValue.push({ name: roleName, value: null } as PreviousValue);
        }
        onValue(
            ref(this.database, 'alerts/' + roleName),
            (snapshot) => {
                if (!snapshot.exists()) {
                    errorCallback();
                }
                const data = snapshot.val();
                const found = this.previousLabHodValue.find(x => x.name == roleName);
                if (!found || found?.value !== data) {
                    callback && callback(data);
                }
            },
            (error) => {
                errorCallback();
                console.error("Error in subscribeToLabHodAlertsPage:", error);
            }
        );
    }
    
}
