export class InsuranceDocument {
    insuranceDocumentsId: number;
    admissionId: number;
    insuranceManagementId: number;
    patientId: number;
    documentName: string;
    documentType: string;
    contentType: string;
    size: number;
    description?: string;
    documentUrl: string;
    thumbnailUrl: boolean;
    uploadedDate: Date;
    active: boolean;
    isImage: boolean;
    isVideo: boolean;
    modifiedBy: number;
    modifiedByName: string;
    modifiedDate?: Date;
    totalItems: number;
    maxFileSize: string;
    base64: string;
    formedUrl: any;
}
