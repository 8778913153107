import { Component, OnInit, OnDestroy, Input, ViewChild, TemplateRef } from "@angular/core";
import { IUserAccount, Page, IResource } from "@shared/models";
import { HttpService, PrintOptionService, BannerService, ResourceService, AppData, NotifyService  } from "@shared/services";
import { ImageReports, Appointment } from "@shared/entities";
import { ApiResources, DateHelper } from "@shared/helpers";
import { finalize, takeUntil } from "rxjs/operators";
import { PharmacySaleBill, RetailStore } from "@shared/entities";
import { ProviderSpecialization } from "@shared/entities/provider-specialization.entity";
import * as converter from "number-to-words";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

class TrackBy {
    resource(item: IResource) {
        return item.id;
    }
}

class GST {
    gst: number;
    taxableAmount: number;
    cgst: number;
    sgst: number;
    igst: number;
    discount?: number;
}

@Component({
    templateUrl: "./pharmacy-invoice.html",
    styleUrls: ['./pharmacy-invoice.widget.css'],
    selector: "pharmacy-invoice",
})
   
export class PharmacyInvoiceWidget implements OnInit, OnDestroy {
    @ViewChild("templatePharmacyReceipt", { static: true }) templatePharmacyReceipt: TemplateRef<any>;
    @Input() appointmentId: number;
    @Input() isFooter: boolean;
    @Input() isPrintLogo: boolean;
    page: Page;
    loading: boolean;
    appointments: Array<Appointment>;
    selectedAppointment: Appointment;
    noReceipt = false;
    currentDate: Date;
    loadingRepotName: boolean;
    loadingBill: boolean;
    
    bills: Array<PharmacySaleBill>;

    t = 0;
    totalCgst = 0;
    totalSgst = 0;
    totalIgst = 0;

    totalTaxableAmt = 0;
    amtDecimalInWords: string;
    amtInWords: string;
    totalDiscount = 0;
    totalNetAmount = 0;
    sendpatientId: string;
    bulkDiscount: number;
    gstCalculation: Array<GST>;
    providers: Array<IResource>;
    submitting: boolean;
    today = DateHelper.ngbToday;
    date: Date;
    modalRef: NgbModalRef;
    selectedStore: RetailStore;
    constructor(
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
        private readonly bannerService: BannerService,
        private readonly appData: AppData,
        private readonly resourceService: ResourceService,
        private readonly modalService: NgbModal,
        private readonly notifyService: NotifyService,
    ) {
        this.page = new Page();
        this.appointments = new Array<Appointment>();
        this.selectedAppointment = new Appointment();
        this.currentDate = new Date();
        this.gstCalculation = new Array<GST>();
        this.bills = new Array<PharmacySaleBill>();
        this.selectedStore = new RetailStore(); 
    }


    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                                    
                    this.currentDate = new Date();
                } else {
                    this.page.userAccount = undefined;
                }
                this.fetchBill(this.appointmentId);
            });
        
    }
    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
    fetchBill(id: number) {
        this.loadingBill = true;
        const request = {
            pharmacySaleHeaderId: id
        };
        this.httpService.post(ApiResources.getURI(ApiResources.pharmacy.base, ApiResources.pharmacy.fetchAddedSaleBill), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => {
                this.loadingBill = false;
            }))
            .subscribe((response: Array<PharmacySaleBill>) => {
                this.gstCalculation = new Array<GST>();
                this.bills = response;
                this.bills.forEach((item) => {
                    let checkPrevious = this.gstCalculation.findIndex(m => m.gst == parseInt(item.taxPercentage));
                    let gst = new GST();
                    gst.gst = +item.taxPercentage;
                    gst.cgst = gst.sgst = gst.igst = parseFloat((item.taxAmount / 2).toFixed(2));
                    gst.taxableAmount = item.taxableAmount = item.netAmount - item.taxAmount;
                    gst.discount = item.discount ? item.discount : 0;
                    item.cgst = item.sgst = item.igst = gst.cgst;
                    if (item.isIGST) {
                        item.sgst = 0;
                        gst.sgst = 0;
                    } else {
                        item.igst = 0;
                        gst.igst = 0;
                    }
                    this.t = this.t + item.total;
                    if (checkPrevious >= 0) {
                        let previousGST = this.gstCalculation[checkPrevious];
                        previousGST.cgst += gst.cgst;
                        previousGST.sgst += gst.sgst;
                        previousGST.igst += gst.igst;
                        previousGST.taxableAmount += gst.taxableAmount;
                        previousGST.discount += gst.discount;
                    } else {
                        this.gstCalculation.push(gst);
                    }
                });
                this.totalGST(); 
            }, () => {
                this.bills = new Array<PharmacySaleBill>();
            });
    }
    totalGST() {
        if (this.gstCalculation.length > 0) {
            let gst = new GST();
            gst.gst = -1;
            gst.cgst = 0;
            gst.sgst = 0;
            gst.igst = 0;
            gst.taxableAmount = 0;
            gst.discount = 0;
            let disCnt = 0;
            this.gstCalculation.forEach((item) => {
                gst.cgst += item.cgst;
                gst.sgst += item.sgst;
                gst.igst += item.igst;
                this.totalCgst = this.totalCgst + item.cgst;
                this.totalSgst = this.totalSgst + item.sgst;
                this.totalIgst = this.totalIgst + item.igst;
                disCnt = disCnt + item.discount;
                this.totalTaxableAmt = this.totalTaxableAmt + item.taxableAmount;
                gst.taxableAmount += item.taxableAmount;
                gst.discount += item.discount;
            });
            let floatNumber = this.totalTaxableAmt;
            let x = floatNumber - Math.trunc(floatNumber);
            this.amtDecimalInWords = converter.toWords(x);
            this.totalNetAmount = this.t - disCnt;
            this.bulkDiscount = Math.round(disCnt);
            this.amtInWords = converter.toWords(this.bills[0].overallNetAmount);
            this.gstCalculation.push(gst);
        }
    }
    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
}
