﻿export class PharmaPurchaseBill {  
     pharmacyProductId: number;
     billNumber: string;
     billType: string;
     productName: string;
     genericName: string;
     batchNumber: string;
     barcode: string;
     createdDate: Date;
     billDate: Date;
     categoryName: string;
     companyName: string;
     supplierId: number;
     supplierName: string;
     quantity: number;
     free: number;
     mrp: number;
     purchaseRate: number;
     locationName: string;

     total: number;
     taxAmount: number;
     discountAmount: number;
     netAmount: number;

     billTotal: number;
     billTaxes: number;
     billDiscount: number;
     billNetAmount: number;

     returnQuantity: number;
     returnDate: number;
     returnTotal: number;
     returnNetAmount: number;

     overallBillAmount: number;
     overallTaxes: number;
     overallDiscount: number;
     overallNetamount: number;
     returnTaxAmount: number;
}