
export class ChatModel {
    MsgId: string;
    ChatBotMsg: Array<string>;
    OptionsType: string;
    Type: string;
    ResponseType: string;
    CheckResponseInDatabase: string;
    SaveResponseInDatabase: string;
    Title: string;
    UserResponseOptions: Array<ChatModelOptions>;
}

export class ChatModelOptions {
    Option: string;
    IfSelected: Array<ChatModel>;
}

export class ChatConversation {
    id: string;
    order: number;
    messageFrom: string;
    message: string;
    messageDateTime: string;
    isOptions: boolean;
    options: Array<ChatModelOptions>;
}

export class SupportChatBot {
    id: number;
    optionId: number;
    type: string;
    supportMsg: string;
    response: Array<SupportChatBot>;
}

export class UserChatOptions {
    returnType: string;
    value: string;
}

export class UserChat {
    optionId: number;
    type: string;
    options: Array<UserChatOptions>;
}

export class UserUploadFiles {
    type: string;
    file: File;
    fileName: string;
    fileRead: string | ArrayBuffer;
}

export class PatientInfo {
    fullName: string;
    age: string;
    weight: string;
    height: string;
    relationWithUser: string;
}

export class DoctorChatSymptomTypeData {
    request: string;
    response: string;
}

export class DoctorChatSymptomsDetails {
    type: string;
    data: Array<DoctorChatSymptomTypeData>;
}

export class DoctorChatReviewDetails {
    patient: PatientInfo;
    symptoms: Array<DoctorChatSymptomsDetails>;
}