export class AntibioticResultReceipt {
    fullName: string;
    requisitionNumber:string
    testCode: string;
    testName: string;
    antibioticName: string;
    observedValue: string;
    createdDate: string;
    patientId: number;
    fromDate: string;
    toDate: string;
    templateName: string;
}