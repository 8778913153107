<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">

                        <div class="btn-group mr-1">

                            <button *ngIf="!isPatient" class="btn btn-sm" [class.btn-light]="currentViewType !== viewType.Patients" [class.btn-primary]="currentViewType == viewType.Patients" type="button" (click)="changeViewType(viewType.Patients)">
                                <i *ngIf="currentViewType != viewType.Patients || !contentLoading" class="mdi mdi-account-group mr-1"></i>
                                <i *ngIf="currentViewType == viewType.Patients && contentLoading" class="mdi mdi-spin mdi-loading mr-1"></i>
                                Patients View
                            </button>
                            <button *ngIf="!isPatient" class="btn btn-sm" [class.btn-light]="currentViewType !== viewType.Scroll" [class.btn-primary]="currentViewType == viewType.Scroll" type="button" (click)="changeViewType(viewType.Scroll)">
                                <i *ngIf="currentViewType != viewType.Scroll || !contentLoading" class="mdi mdi-script-text mr-1"></i>
                                <i *ngIf="currentViewType == viewType.Scroll && contentLoading" class="mdi mdi-spin mdi-loading mr-1"></i>
                                Scroll View
                            </button>
                            <button class="btn btn-sm" [class.btn-light]="currentViewType !== viewType.Time" [class.btn-primary]="currentViewType == viewType.Time" type="button" (click)="changeViewType(viewType.Time)">
                                <i *ngIf="currentViewType != viewType.Time || !contentLoading" class="mdi mdi-clock-time-five mr-1"></i>
                                <i *ngIf="currentViewType == viewType.Time && contentLoading" class="mdi mdi-spin mdi-loading mr-1"></i>
                                Time View
                            </button>
                            <button *ngIf="!isPatient" class="btn btn-sm" [class.btn-light]="currentViewType !== viewType.Grid" [class.btn-primary]="currentViewType === viewType.Grid" type="button" (click)="changeViewType(viewType.Grid)">
                                <i *ngIf="currentViewType != viewType.Grid || !contentLoading" class="mdi mdi-view-grid mr-1"></i>
                                <i *ngIf="currentViewType == viewType.Grid && contentLoading" class="mdi mdi-spin mdi-loading mr-1"></i>
                                Grid View
                            </button>
                        </div>

                        <a href="javascript:;" class="btn btn-secondary btn-sm ml-1" (click)="onBack()">
                            <span><i class="fe-arrow-left mr-1"></i> Back</span>
                        </a>
                    </div>
                    <h4 *ngIf="!isNurseMode" class="page-title">Actions</h4>
                    <a class="btn btn-sm btn-primary" *ngIf="isNurseMode && !isPatient" href="javascript:;" [routerLink]="['/app/nurse-module/view']"><i class="mdi mdi-eye mr-1"></i> View my shift</a>
                </div>
            </div>
        </div>

        <form [formGroup]="filterForm" class="mb-2" *ngIf="page && page.userAccount && page.userAccount.roleId === 1 && !isAdmissionMode">
            <div class="d-flex align-items-center justify-content-start">
                <div class="d-flex">
                    <div class="form-group w-250p mb-0 mr-3" *ngIf="!encryptId">
                        <label class="mb-1">Select Nurse</label>
                        <ng-select [items]="nurses"
                                   bindLabel="value"
                                   [loading]="loading"
                                   bindValue="id"
                                   placeholder="Select Nurse"
                                   formControlName="nurseId">
                        </ng-select>
                    </div>
                    <div class="form-group w-250p mb-0">
                        <label class="mb-1">Patient</label>
                        <ng-select [items]="patients"
                                   bindLabel="value"
                                   [loading]="loading"
                                   bindValue="value"
                                   placeholder="Select Patient"
                                   formControlName="patientId">
                        </ng-select>
                    </div>
                </div>
                <div></div>
            </div>
        </form>

        <div class="row">
            <div class="col-sm-12" [ngClass]="{'mb-2': viewType.Grid === currentViewType}" *ngIf="viewType.Grid === currentViewType || viewType.Time === currentViewType">
                <div class="d-flex align-items-center justify-content-between" *ngIf="!isNurseMode">
                    <div class="text-muted">
                        <small><span [textContent]="dates.min | date: 'd MMMM, y'"></span>&nbsp;<i class="mdi mdi-arrow-left-thin"></i> start</small>
                    </div>
                    <div>
                        <span (click)="onPrevious()" class="mr-2">
                            <i *ngIf="!previousLoading" [ngClass]="{'text-muted' : !dates.showMin, 'cursor-pointer text-primary' : dates.showMin}" class="mdi mdi-arrow-left-bold mdi-18px"></i>
                            <span *ngIf="previousLoading" class="spinner-grow spinner-grow-sm"></span>
                        </span>
                        <span [textContent]="allDates[dates.currentIndex] | date: 'd MMMM, y'"></span>
                        <span (click)="onNext()" class="ml-2">
                            <i *ngIf="!nextLoading" [ngClass]="{'text-muted' : !dates.showMax, 'cursor-pointer text-primary' : dates.showMax}" class="mdi mdi-arrow-right-bold mdi-18px"></i>
                            <span *ngIf="nextLoading" class="spinner-grow spinner-grow-sm"></span>
                        </span>
                    </div>
                    <div class="text-muted">
                        <small>end <i class="mdi mdi-arrow-right-thin"></i>&nbsp;<span [textContent]="dates.max | date: 'd MMMM, y'"></span></small>
                    </div>
                </div>
                <div *ngIf="isNurseMode">
                    <div class="ml-2 mt-3" *ngIf=" viewType.Time === currentViewType">
                        <!-- Color boxes with light shades -->
                        <div class="color-box mr-1 light-red"></div> <!-- Light Red -->
                        <span class="legend-text">Missed</span> <!-- Text for "Missed" -->
                        <span class="legend-space"></span> <!-- Space between color box and text -->
                        <div class="color-box mr-1 light-green"></div> <!-- Light Green -->
                        <span class="legend-text">Completed</span> <!-- Text for "Completed" -->
                        <span class="legend-space"></span> <!-- Space between color box and text -->
                        <div class="color-box mr-1 light-sky-blue"></div> <!-- Light Sky Blue -->
                        <span class="legend-text">Pending</span> <!-- Text for "Pending" -->
                        <span class="legend-space"></span> <!-- Space between color box and text -->
                        <div class="color-box mr-1 light-yellow"></div> <!-- Light Yellow -->
                        <span class="legend-text">Take now   </span> <!-- Text for "Take now" -->
                        <span class="legend-space"></span>
                        <div class="color-box mr-1 light-pink"></div> <!-- Light Yellow -->
                        <span class="legend-text">Abnormal</span> <!-- Text for "Take now" -->

                    </div>

                    <div class="d-flex align-items-center justify-content-center">
                        <!-- Navigation elements -->
                        <span (click)="onPrevious()" class="mr-2">
                            <i *ngIf="!previousLoading" class="mdi mdi-arrow-left-bold mdi-18px cursor-pointer text-primary"></i>
                            <span *ngIf="previousLoading" class="spinner-grow spinner-grow-sm"></span>
                        </span>
                        <span [textContent]="dateHolder.fDate | date: 'd MMMM, y'"></span>
                        <span (click)="onNext()" class="ml-2">
                            <i *ngIf="!nextLoading" class="mdi mdi-arrow-right-bold mdi-18px cursor-pointer text-primary"></i>
                            <span *ngIf="nextLoading" class="spinner-grow spinner-grow-sm"></span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 mb-4" *ngIf="!contentLoading">
                <ng-container *ngIf="currentViewType === viewType.Patients">
                    <inpatients-view></inpatients-view>
                </ng-container>
                <ng-container *ngIf="currentViewType !== viewType.Patients">
                    <div #scrollDiv class="overflow-auto" [ngClass]="{'time-overflow pb-2 pt-5': viewType.Time === currentViewType}">
                        <table class="table table-centered table-sm table-striped table-bordered mb-0" [ngClass]="{'sticky-table-header sticky-table time-view-boundary' : viewType.Time === currentViewType}">
                            <thead *ngIf="currentViewType == viewType.Time">
                                <tr>
                                    <th></th>
                                    <ng-container *ngFor="let item of hours; let i = index">
                                        <th [id]="'header_' + i" class="position-relative" [ngbTooltip]="item.name" placement="left">
                                            <ng-container *ngIf="item.hour === markDetails.hour">
                                                <div class="time-mark" [ngClass]="{'mark-height': setHeight}" [ngStyle]="{'left.%': markDetails.left}">
                                                    <i class="mdi mdi-tag mdi-rotate-225"></i>
                                                    <span class="ml-1" [textContent]="markDetails.time | date: 'h:mm a'"></span>
                                                </div>
                                            </ng-container>
                                            <div class="d-flex align-items-center justify-content-center flex-column">
                                                <i [class]="'mr-1 mdi mdi-' + item.icon"></i>
                                                <span class="white-space-nowrap" [textContent]="item.meridiemHour + item.meridiem"></span>
                                            </div>
                                        </th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let record of records; let i = index;">
                                    <tr class="table-secondary" *ngIf="currentViewType === viewType.Scroll">
                                        <td>
                                            <div class="d-flex align-items-center justify-content-center">
                                                <span class="font-weight-bold" [textContent]="record.date | date: 'd MMMM, y'"></span>
                                            </div>
                                        </td>
                                    </tr>
                                    <ng-container *ngFor="let item of record.medications; let j = index;">
                                        <tr [ngClass]="{'table-danger' : viewType.Time !== currentViewType && item.activeStatus === activeStatus[activeStatus.Stopped], 'table-success' : viewType.Time !== currentViewType && item.status, 'table-warning' : viewType.Time !== currentViewType && item.status === false && item.when !== whenType[whenType.NotStarted], 'table-info' : viewType.Time !== currentViewType && item.status === false && item.when === whenType[whenType.NotStarted]}">
                                            <td class="p-0" [ngClass]="{'now-animate' : item.when === whenType[whenType.Now]}">
                                                <div class="d-flex align-items-center justify-content-between p-1">
                                                    <div class="d-flex align-items-center">
                                                        <ng-container *ngTemplateOutlet="snoTemplate; context: {number : (j + 1)}"></ng-container>
                                                        <ng-container *ngIf="isNurseMode"><ng-container *ngTemplateOutlet="userTemplate; context: {item : item, index : i}"></ng-container></ng-container>
                                                        <ng-container *ngTemplateOutlet="medicationProductTemplate; context: {item : item}"></ng-container>
                                                    </div>
                                                    <ng-container *ngIf="currentViewType != viewType.Time">
                                                        <ng-container *ngTemplateOutlet="medicationStatusTemplate; context: {item : item}"></ng-container>
                                                    </ng-container>
                                                </div>

                                            </td>
                                            <ng-container *ngIf="currentViewType == viewType.Time">
                                                <ng-container *ngFor="let subItem of hours; let k = index">
                                                    <ng-container *ngIf="{matchedItem: matchedItem(subItem.hour, j), matchedIndex: matchedIndex(subItem.hour, j), actionDetails: getActionDetails(subItem.hour, j)} as meta">
                                                        <ng-container *ngIf="{isExists: !!(item.timeSlots && item.timeSlots.length > 0 && meta.matchedItem)} as subMeta">

                                                            <ng-container *ngIf="subMeta.isExists">
                                                                <td (mousedown)="onMouseDown(j, meta.matchedIndex)" (mouseup)="onMouseUp(j, meta.matchedIndex)" class="cursor-pointer"
                                                                    (dblclick)="takeQuickMedicine(item, meta.matchedItem.progressReportMedicationFrequencyId, meta.matchedItem)"
                                                                    [ngbTooltip]="meta.matchedItem.name" placement="left" [ngClass]="{'shake': movingObjectIndex === meta.matchedIndex && movingIndex === j && !movingObject,
                                                                    'table-danger' : meta.matchedItem.status === false && meta.matchedItem.when !== whenType[whenType.NotStarted], 'table-success' : meta.matchedItem.status,
                                                                    'table-warning' : meta.matchedItem.when === whenType[whenType.Pending] || meta.matchedItem.when === whenType[whenType.Now],
                                                                    'table-info' : meta.matchedItem.status === false && meta.matchedItem.when === whenType[whenType.NotStarted]}">
                                                                    <p *ngIf="item.timeSlots[meta.matchedIndex].showBar"><ngb-progressbar animated="false" type="warning" [value]="item.timeSlots[meta.matchedIndex].barWidth || 0" height=".3rem"></ngb-progressbar></p>
                                                                    <i (click)="fetchMoves(j, meta.matchedIndex, moveViewTemplate)" *ngIf="meta.matchedItem.hasMove && !meta.matchedItem.hasMoveLoading" class="mdi mdi-dots-circle has-move"></i>
                                                                    <i *ngIf="meta.matchedItem.hasMove && meta.matchedItem.hasMoveLoading" class="mdi mdi-spin mdi-loading"></i>
                                                                    <ng-container *ngTemplateOutlet="medicationTimeHelperTemplate; context: {item : item, subItem: subItem, actionDetails: meta.actionDetails, matchedItem: meta.matchedItem}"></ng-container>
                                                                </td>
                                                            </ng-container>

                                                            <ng-container *ngIf="!subMeta.isExists">
                                                                <td [ngClass]="{'available-slot cursor-pointer': movingIndex === j && !movingObject}" *ngIf="meta.actionDetails" [ngbTooltip]="meta.actionDetails.long" placement="left">
                                                                    <div class="d-flex align-items-center flex-column justify-content-center">
                                                                        <span class="action-block" [textContent]="meta.actionDetails.short"></span>
                                                                    </div>
                                                                </td>
                                                                <td (click)="onMoveComplete(subItem, moveTemplate)" [ngClass]="{'available-slot cursor-pointer': movingIndex === j && !movingObject}" *ngIf="!meta.actionDetails"></td>
                                                            </ng-container>

                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                    </ng-container>

                                    <ng-container *ngFor="let item of record.vitals; let k = index;">
                                        <tr>
                                            <td>
                                                <div class="d-flex align-items-center justify-content-between p-1 ">
                                                    <div class="d-flex align-items-center">
                                                        <ng-container *ngTemplateOutlet="snoTemplate; context: {number : (k + 1 + record.medications.length)}"></ng-container>
                                                        <ng-container *ngIf="isNurseMode"><ng-container *ngTemplateOutlet="userTemplate; context: {item : item, index : k}"></ng-container></ng-container>
                                                        <ng-container *ngTemplateOutlet="vitalstemplate; context: {item : item}"></ng-container>
                                                    </div>
                                                    <ng-container *ngIf="currentViewType != viewType.Time">
                                                        <ng-container *ngTemplateOutlet="vitalstatustemplate; context: {item : item}"></ng-container>
                                                    </ng-container>
                                                </div>
                                            </td>

                                            <ng-container *ngIf="currentViewType == viewType.Time">
                                                <ng-container *ngFor="let subItem of hours; let p = index">
                                                    <ng-container *ngIf="{matchedItem1: matchedItem1(subItem.hour, k), actionDetails: getActionDetails1(subItem.hour)} as meta">
                                                        <ng-container *ngIf="{isExists: (item.vitalsStatuses && item.vitalsStatuses.length > 0 && meta.matchedItem1) } as subMeta">
                                                            <ng-container *ngIf="subMeta.isExists">
                                                                <td *ngIf="!meta.matchedItem1.takenValue && meta.matchedItem1.missed !== 'missed' &&  meta.matchedItem1.missed !== 'now' " class="cursor-pointer table-info " placement="left" (click)="takeQuickVitals(item, meta.matchedItem1.time , templateAdd)">

                                                                    <p>{{meta.matchedItem1.time }}:00 Pending..</p>
                                                                </td>

                                                                <td *ngIf="!meta.matchedItem1.takenValue && meta.matchedItem1.missed !== 'missed' &&  meta.matchedItem1.missed === 'now' " class="cursor-pointer table-warning blink " placement="left" (click)="takeQuickVitals(item, meta.matchedItem1.time , templateAdd)">

                                                                    <p>{{meta.matchedItem1.time }}:00 Take Now..</p>
                                                                </td>
                                                                <td *ngIf="!meta.matchedItem1.takenValue && meta.matchedItem1.missed === 'missed'" class="cursor-pointer  table-danger " placement="left" (click)="takeQuickVitals(item, meta.matchedItem1.time , templateAdd)">

                                                                    <p>{{meta.matchedItem1.time }}:00  missed..</p>
                                                                    <i class="mdi mdi-close-thick text-danger"></i>
                                                                </td>

                                                                <td *ngIf=" meta.matchedItem1.takenValue && meta.matchedItem1.missed =='taken' " class="cursor-pointer table-success " placement="left">
                                                                    <p> {{ meta.matchedItem1.time}}:00 Taken..</p>
                                                                    <i class="mdi mdi-check-bold text-success"></i>
                                                                </td>
                                                                <td *ngIf=" meta.matchedItem1.takenValue && meta.matchedItem1.missed =='Abnormal' " class="cursor-pointer table light-pink  " placement="left">
                                                                    <p> {{ meta.matchedItem1.time}}:00 Taken..</p>
                                                                    <i class="mdi mdi-check-bold text-success"></i>
                                                                </td>

                                                            </ng-container>

                                                            <ng-container *ngIf="!subMeta.isExists">
                                                                <td [ngClass]="{'available-slot cursor-pointer': movingIndex === j && !movingObject}" *ngIf="meta.actionDetails" [ngbTooltip]="meta.actionDetails.long" placement="left">
                                                                    <div class="d-flex align-items-center flex-column justify-content-center">
                                                                        <span class="action-block" [textContent]="meta.actionDetails.short"></span>
                                                                    </div>
                                                                </td>
                                                                <td [ngClass]="{'available-slot cursor-pointer': movingIndex === j && !movingObject}" *ngIf="!meta.actionDetails"></td>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                    </ng-container>


                                    <ng-container *ngFor="let item of record.infusions; let k = index;">
                                        <tr>
                                            <td>
                                                <div class="d-flex align-items-center justify-content-between p-1 ">
                                                    <div class="d-flex align-items-center">
                                                        <ng-container *ngTemplateOutlet="snoTemplate; context: {number : (k + 1 + record.medications.length+record.vitals.length)}"></ng-container>
                                                        <ng-container *ngIf="isNurseMode"><ng-container *ngTemplateOutlet="userTemplate; context: {item : item, index : k}"></ng-container></ng-container>
                                                        <ng-container *ngTemplateOutlet="InfusionTemplate; context: {item : item}"></ng-container>
                                                    </div>
                                                   
                                                </div>
                                            </td>

                                            <ng-container *ngIf="currentViewType == viewType.Time">
                                                <ng-container *ngFor="let subItem of hours; let p = index">
                                                    <ng-container *ngIf="{matchedItem2: matchedItem2(subItem.hour, k), actionDetails: getActionDetails1(subItem.hour)} as meta">
                                                        <ng-container *ngIf="{isExists: (item.hour && item.hour.length > 0 && meta.matchedItem2) } as subMeta">
                                                            <ng-container *ngIf="subMeta.isExists">
                                                                <td *ngIf="meta.matchedItem2.hour && meta.matchedItem2.status=='NotStarted'  " class="cursor-pointer table-info " (dblclick)="takeQuickInfusion(item,meta.matchedItem2.hour, meta.matchedItem2)" placement="left">

                                                                    <p>
                                                                        <span class="small">Next..</span>
                                                                    </p>
                                                                </td>

                                                                <td *ngIf="meta.matchedItem2.hour && meta.matchedItem2.status=='Pending'" class="cursor-pointer  table-danger " placement="left" (dblclick)="takeQuickInfusion(item,meta.matchedItem2.hour, meta.matchedItem2)">

                                                                    <i class="mdi mdi-close-thick text-danger"></i>
                                                                </td>

                                                                <td *ngIf="meta.matchedItem2.hour && meta.matchedItem2.status=='Taken'" class="cursor-pointer table-success " placement="left">
                                                                    <p>  Taken..</p>
                                                                    <i class="mdi mdi-check-bold text-success"></i>
                                                                </td>
                                                                <td *ngIf="meta.matchedItem2.hour && meta.matchedItem2.status=='TakeNow'" class="cursor-pointer table-warning blink " placement="left" (dblclick)="takeQuickInfusion(item ,meta.matchedItem2.hour,meta.matchedItem2)">

                                                                    <p> Take Now..</p>
                                                                </td>



                                                            </ng-container>

                                                            <ng-container *ngIf="!subMeta.isExists">
                                                                <td [ngClass]="{'available-slot cursor-pointer': movingIndex === j && !movingObject}" *ngIf="meta.actionDetails" [ngbTooltip]="meta.actionDetails.long" placement="left">
                                                                    <div class="d-flex align-items-center flex-column justify-content-center">
                                                                        <span class="action-block" [textContent]="meta.actionDetails.short"></span>
                                                                    </div>
                                                                </td>
                                                                <td [ngClass]="{'available-slot cursor-pointer': movingIndex === j && !movingObject}" *ngIf="!meta.actionDetails"></td>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                    </ng-container>

                                    <ng-container *ngFor="let item of record.labs; let k = index;">
                                        <tr [ngClass]="{'table-warning' : item.when === whenType[whenType.Later] && item.status === labStatusType[labStatusType.NotCollected], 'table-blue' : item.status === labStatusType[labStatusType.NotVerified], 'table-success' : item.status === labStatusType[labStatusType.Verified], 'table-info' : item.status === labStatusType[labStatusType.Collected]}">
                                            <td [ngClass]="{'now-animate' : item.when === whenType[whenType.Now]}">
                                                <div class="d-flex align-items-center justify-content-between p-1 ">
                                                    <div class="d-flex align-items-center">
                                                        <ng-container *ngTemplateOutlet="snoTemplate; context: {number : (k + 1 + record.medications.length+record.vitals.length)}"></ng-container>
                                                        <ng-container *ngIf="isNurseMode"><ng-container *ngTemplateOutlet="userTemplate; context: {item : item, index : k}"></ng-container></ng-container>

                                                        <ng-container *ngTemplateOutlet="labProductTemplate; context: {item : item}"></ng-container>
                                                    </div>
                                                    <ng-container *ngIf="currentViewType != viewType.Time">
                                                        <ng-container *ngTemplateOutlet="labStatusTemplate; context: {item : item}"></ng-container>
                                                    </ng-container>
                                                </div>
                                            </td>
                                            <td *ngIf="currentViewType == viewType.Time" [attr.colspan]="25" placement="right">
                                                <ng-container *ngTemplateOutlet="labStatusTemplate; context: {item : item}"></ng-container>

                                            </td>
                                        </tr>
                                    </ng-container>

                                    <ng-container *ngFor="let item of record.notes; let l = index;">
                                        <tr>
                                            <td [ngClass]="{'now-animate' : item.when === whenType[whenType.Now]}" [attr.colspan]="25">
                                                <div class="d-flex align-items-center justify-content-between p-1">
                                                    <div class="d-flex align-items-center">
                                                        <ng-container *ngTemplateOutlet="snoTemplate; context: {number : (l + 1 + record.medications.length + record.labs.length)}"></ng-container>
                                                        <ng-container *ngIf="isNurseMode"><ng-container *ngTemplateOutlet="userTemplate; context: {item : item, index : l}"></ng-container></ng-container>
                                                        <ng-container *ngTemplateOutlet="noteProductTemplate; context: {item : item, index : l}"></ng-container>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                    <div class="flex-center-vertical mt-5" *ngIf="records && ((records.length > 0 && records[0].medications.length <= 0 && records[0].labs.length <= 0 && records[0].notes.length <= 0 && records[0].vitals.length <=0 && records[0].infusions.length <=0) || records.length <= 0) && !loading">
                        <div><img alt="no content" [src]="imgOrigin + 'assets/images/days/no-content.png'" width="96" /></div>
                        <div><h3>Timeline does not exists</h3></div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #snoTemplate let-number="number">
    <div [ngStyle]="{'width.px': snoWidth}">#<span [textContent]="number"></span></div>
</ng-template>

<ng-template #userTemplate let-item="item" let-index="index">
    <div class="media" [ngClass]="{'ml-1': viewType.Time === currentViewType, 'ml-3': viewType.Time !== currentViewType}" [ngStyle]="{'width.px': userWidth}">
        <div class="avatar-sm" [ngClass]="{'mr-2': viewType.Time !== currentViewType}">
            <avatar-img *ngIf="item.patientImage" [src]="item.patientImage" [alt]="item.patientName" cssClass="img-fluid rounded-circle"></avatar-img>
            <span *ngIf="!item.patientImage" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="index">
                <span [textContent]="item.fullName | initials"></span>
            </span>
        </div>
        <div class="media-body ml-1">
            <h5 class="mb-0 mt-0 font-weight-normal">
                <span *ngIf="item.salutation!=null" class="mr-1" [textContent]="item.salutation + '.'"></span><span [textContent]="item.fullName | titlecase"></span>
            </h5>
            <span class="d-block font-13">
                <span [textContent]="item.gender | gender"></span>
                <span class="content-breaker" [textContent]="item.age + ' yrs'"></span>
            </span>
        </div>
    </div>
</ng-template>
<!--MEDICATION TEMPLATES-->
<ng-template #medicationProductTemplate let-item="item">
    <div class="media" [ngClass]="{'ml-1': viewType.Time === currentViewType, 'ml-3': viewType.Time !== currentViewType}" [ngStyle]="{'width.px': productWidth}">
        <div>

            <ng-container [ngSwitch]="(item.productTypeName | lowercase)">
                <span *ngSwitchCase="'injection'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-injection.png'" width="28" /></span>
                <span *ngSwitchCase="'tablet'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-tablet.png'" width="28" /></span>
                <span *ngSwitchCase="'analgesics'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-tablet.png'" width="28" /></span>
                <span *ngSwitchCase="'gel'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-hair-gel.png'" width="28" /></span>
                <span *ngSwitchCase="'syrup'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-syrup.png'" width="28" /></span>
                <span *ngSwitchCase="'ointment'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-ointment.png'" width="28" /></span>
                <span *ngSwitchCase="'respules'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-respules.png'" width="28" /></span>
                <span *ngSwitchCase="'syringe'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-syringe.png'" width="28" /></span>
                <span *ngSwitchCase="'surgical'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-scissors.png'" width="28" /></span>
                <span *ngSwitchCase="'capsules'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-capsule.png'" width="28" /></span>
                <span *ngSwitchCase="'sachets'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-sachet.png'" width="28" /></span>
                <span *ngSwitchCase="'drops'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-drop.png'" width="28" /></span>
                <span *ngSwitchCase="'sprays'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-spray.png'" width="28" /></span>
                <span *ngSwitchCase="'suppository'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-suppository.png'" width="28" /></span>
                <span *ngSwitchCase="'gloves'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-gloves.png'" width="28" /></span>
                <span *ngSwitchCase="'lotion'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-lotion.png'" width="28" /></span>
                <span *ngSwitchCase="'powder'"><img alt="product type" [src]="imgOrigin + 'assets/images/pharmacy/pp-powder.png'" width="28" /></span>
                <span *ngSwitchCase="'others'"><i class="mdi mdi-help font-28"></i></span>
                <span *ngSwitchDefault><i class="mdi mdi-help font-28"></i></span>
            </ng-container>
        </div>
        <div class="media-body ml-1">
            <h5 [ngClass]="{'text-line-through' : item.activeStatus === activeStatus[activeStatus.Stopped]}" class="mb-0 mt-0 font-weight-normal">
                <span [textContent]="item.productName || '---'"></span>
            </h5>
            <div class="d-block font-12 text-capitalize" [ngClass]="{'text-line-through' : item.activeStatus === activeStatus[activeStatus.Stopped]}">
                <small [textContent]="item.productTypeName || '---'"></small>
            </div>
        </div>
    </div>
    <div class="d-flex" [ngClass]="{'ml-1': viewType.Time === currentViewType, 'ml-3': viewType.Time !== currentViewType}">
        <h3 [textContent]="item.unit"></h3>
        <div class="unit-display unit-display-start ml-1" [ngClass]="{'text-line-through' : item.activeStatus === activeStatus[activeStatus.Stopped]}" [ngStyle]="{'width.px': unitWidth}">
            <span>Take</span>
            <span class="text-capitalize">
                <span [textContent]="item.productTypeName || '---'"></span>
                <span *ngIf="item.productTypeName && item.unit > 1">s</span>
            </span>
        </div>
    </div>
</ng-template>

<ng-template #medicationStatusTemplate let-item="item">
    <div class="d-flex">
        <div class="unit-display unit-display-end ml-1">
            <div class="d-flex align-items-center justify-content-end">
                <ng-container *ngIf="item.activeStatus === activeStatus[activeStatus.Active]">
                    <span *ngIf="item.when === whenType[whenType.Now]" class="text-warning font-weight-bold blink">Take Now</span>
                    <span *ngIf="item.when === whenType[whenType.Pending]" class="text-warning font-weight-bold">Pending Next</span>
                    <span *ngIf="item.status" class="text-success font-weight-bold">Taken</span>
                    <span *ngIf="item.status === false && item.when !== whenType[whenType.NotStarted]" class="text-m font-weight-bold">Missed</span>
                    <span *ngIf="item.status === false && item.when === whenType[whenType.NotStarted]" class="text-info font-weight-bold">Not Started</span>
                </ng-container>
                <ng-container *ngIf="item.activeStatus === activeStatus[activeStatus.Stopped]">
                    <span class="text-danger font-weight-bold text-line-through">Stopped</span>
                </ng-container>
                <span class="ml-1 font-weight-bold font-16" [ngClass]="{'text-line-through' : item.activeStatus === activeStatus[activeStatus.Stopped]}">
                    <span *ngIf="1 === item.medicationInstructionDeepTypeId">Morning</span>
                    <span *ngIf="2 === item.medicationInstructionDeepTypeId">Afternoon</span>
                    <span *ngIf="3 === item.medicationInstructionDeepTypeId">Night</span>
                </span>
            </div>
            <span class="text-capitalize" [ngClass]="{'text-line-through' : item.activeStatus === activeStatus[activeStatus.Stopped]}">
                <span [textContent]="item.medicationInstructionName"></span>
            </span>
        </div>
        <span class="ml-2">
            <img *ngIf="1 === item.medicationInstructionDeepTypeId" alt="day" [src]="imgOrigin + 'assets/images/days/morning.png'" width="32" />
            <img *ngIf="2 === item.medicationInstructionDeepTypeId" alt="day" [src]="imgOrigin + 'assets/images/days/afternoon.png'" width="32" />
            <img *ngIf="3 === item.medicationInstructionDeepTypeId" alt="day" [src]="imgOrigin + 'assets/images/days/night.png'" width="32" />
            <img *ngIf="4 === item.medicationInstructionDeepTypeId" alt="day" [src]="imgOrigin + 'assets/images/days/all-day.png'" width="32" />
        </span>
        <span *ngIf="whenType[whenType.Now] === item.when" class="ml-3 d-flex align-items-center">
            <span *ngIf="!item.isMedicineTaking">
                <i ngbTooltip="Take Medicine" placement="left" (click)="takeQuickMedicine(item, item.progressReportMedicationFrequencyId)" class="mdi mdi-hand-extended text-primary mdi-24px take-btn"></i>
            </span>
            <span *ngIf="item.isMedicineTaking" class="take-btn-border">
                <span class="spinner-grow spinner-grow-sm"></span>
            </span>
        </span>
    </div>
</ng-template>

<ng-template #medicationTimeHelperTemplate let-item="item" let-matchedItem="matchedItem" let-subItem="subItem" let-actionDetails="actionDetails">
    <div class="d-flex align-items-center justify-content-center">
        <div class="d-flex align-items-center flex-column justify-content-center" [ngClass]="{'action-block-right': actionDetails}">
            <ng-container *ngIf="matchedItem.status === false && matchedItem.when !== whenType[whenType.NotStarted]">
                <i *ngIf="!matchedItem.isTaking" class="mdi mdi-close-thick text-danger"></i>
                <i *ngIf="matchedItem.isTaking" class="mdi mdi-loading mdi-spin"></i>
            </ng-container>
            <ng-container *ngIf="matchedItem.status">
                <i class="mdi mdi-check-bold text-success"></i>
            </ng-container>
            <ng-container *ngIf="matchedItem.when === whenType[whenType.Pending] || matchedItem.when === whenType[whenType.Now]">
                <i *ngIf="!matchedItem.isTaking" class="mdi mdi-flash text-warning"></i>
                <i *ngIf="matchedItem.isTaking" class="mdi mdi-loading mdi-spin"></i>
            </ng-container>
            <ng-container *ngIf="matchedItem.status === false && matchedItem.when === whenType[whenType.NotStarted]">
                <i class="mdi mdi-flag-checkered"></i>
            </ng-container>
            <span class="small" [textContent]="matchedItem.meridiemHour + ':' + matchedItem.minute + subItem.meridiem"></span>
        </div>
        <div *ngIf="actionDetails" class="action-block">
            <span [textContent]="actionDetails.short"></span>
        </div>
    </div>
</ng-template>
<!--LAB TEMPLATES-->
<ng-template #labProductTemplate let-item="item">
    <div class="media" [ngClass]="{'ml-1': viewType.Time === currentViewType, 'ml-3': viewType.Time !== currentViewType}" [ngStyle]="{'width.px': productWidth}">
        <div>
            <img alt="lab service" [src]="imgOrigin + 'assets/images/days/lab.png'" width="28" />
        </div>
        <div class="media-body ml-1">
            <h5 class="mb-0 mt-0 font-weight-normal">
                <span [textContent]="item.testName || '---'"></span>
            </h5>
            <span class="d-block font-12 text-capitalize">
                <small [textContent]="item.testCode"></small>
            </span>
        </div>
    </div>
    <div class="d-flex" [ngClass]="{'ml-1': viewType.Time === currentViewType, 'ml-3': viewType.Time !== currentViewType}">
        <h3>1</h3>
        <div class="unit-display unit-display-start ml-1" [ngStyle]="{'width.px': unitWidth}">
            <span>Take</span>
            <span class="text-capitalize">
                <span>Lab Test </span>
            </span>
        </div>
    </div>
</ng-template>

<ng-template #labStatusTemplate let-item="item">
    <div class="d-flex">
        <div class="unit-display unit-display-end ml-1">
            <div class="d-flex align-items-center justify-content-end">

                <span *ngIf="item.when === whenType[whenType.Later] && item.status === labStatusType[labStatusType.NotCollected]" class="text-danger mr-1 font-weight-bold font-16">
                    <span>Missed</span>
                </span>
                <ng-container [ngSwitch]="item.status">
                    <span *ngSwitchCase="labStatusType[labStatusType.Verified]" class="text-success font-weight-bold">Verified</span>
                    <span *ngSwitchCase="labStatusType[labStatusType.NotVerified]" class="text-warning font-weight-bold">Not Verified</span>
                    <span *ngSwitchCase="labStatusType[labStatusType.Collected]" class="text-info font-weight-bold">Sample Collected</span>
                    <span *ngSwitchCase="labStatusType[labStatusType.NotCollected]" class="text-danger font-weight-bold">Sample Not Collected</span>
                    <span *ngSwitchDefault>---</span>
                </ng-container>

                <span *ngIf="item.when === whenType[whenType.Now]" class="ml-1 font-weight-bold font-16">
                    <span>All Day</span>
                </span>
            </div>
            <span class="text-capitalize">
                <span>Take any time in a day</span>
            </span>
        </div>
        <span class="ml-2">
            <img alt="all day" [src]="imgOrigin + 'assets/images/days/all-day.png'" width="32" />
        </span>
    </div>
</ng-template>
<!--NOTE TEMPLATES-->
<ng-template #noteProductTemplate let-item="item">
    <div [ngClass]="{'ml-1': viewType.Time === currentViewType, 'ml-3': viewType.Time !== currentViewType}">
        <img alt="lab service" [src]="imgOrigin + 'assets/images/days/note.png'" width="28" />
    </div>
    <div class="ml-2" [textContent]="item.note"></div>
</ng-template>

<ng-template #moveTemplate>
    <form [formGroup]="moveForm" (ngSubmit)="onMoveSubmit()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-cursor-move mr-1"></i>Move</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="mb-1">Time</label>
                        <input type="time" formControlName="time" class="form-control" [ngClass]="{ 'is-invalid': moveSubmitted && moveFormControls.time.errors }" />
                        <div *ngIf="moveSubmitted && moveFormControls.time.errors" class="invalid-feedback show-must">
                            <div *ngIf="moveFormControls.time.errors.required">Time is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="mb-1">Reason</label>
                        <textarea rows="2" formControlName="reason" class="form-control" [ngClass]="{ 'is-invalid': moveSubmitted && moveFormControls.reason.errors }" placeholder="Reason, why are you moving into different time"></textarea>
                        <div *ngIf="moveSubmitted && moveFormControls.reason.errors" class="invalid-feedback show-must">
                            <div *ngIf="moveFormControls.reason.errors.required">Reason is required</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
            <button type="submit" class="btn btn-primary btn-sm">
                <ng-container *ngIf="!moveSubmitting"><i class="mdi mdi-check-bold mr-1"></i>Submit</ng-container>
                <ng-container *ngIf="moveSubmitting"><span class="spinner-grow spinner-grow-sm mr-1"></span> please wait</ng-container>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #moveViewTemplate>
    <div class="modal-header">
        <h4 class="modal-title"><i class="mdi mdi-cursor-move mr-1"></i>View Moves</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <div class="table-responsive mb-2" *ngFor="let item of moves; let i = index">
            <table class="table table-centered table-sm table-striped table-bordered mb-0">
                <thead>
                    <tr>
                        <th width="100" [textContent]="'#' + (1 + i)"></th>
                        <td>
                            <span *ngIf="0 === i">Original Time</span>
                            <span *ngIf="0 !== i" [textContent]="moves[i - 1].time | date:'h:mm a'"></span>
                            <span><i class="mdi mdi-arrow-right-thin mx-1"></i></span>
                            <span [textContent]="item.time | date:'h:mm a'"></span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" [textContent]="item.reason"></td>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Close</button>
    </div>
</ng-template>
<!--VITALS TEMPLATE-->
<ng-template #vitalstemplate let-item="item">
    <div class="media" [ngClass]="{'ml-1': viewType.Time === currentViewType, 'ml-3': viewType.Time !== currentViewType}" [ngStyle]="{'width.px': productWidth}">
        <div>
            <img [src]="imgOrigin + 'assets/images/Vitals/Bp.jpg'" width="28" />
        </div>
        <div class="media-body ml-1">
            <h5 class="mb-0 mt-0 font-weight-normal">
                <span [textContent]="item.name || '---'"></span>
            </h5>
        </div>
    </div>
    <div class="d-flex" [ngClass]="{'ml-1': viewType.Time === currentViewType, 'ml-3': viewType.Time !== currentViewType}">
        <h3>{{item.frequency}}</h3>
        <div class="unit-display unit-display-start ml-1" [ngStyle]="{'width.px': unitWidth}">
            <span>Times</span>
            <span class="text-capitalize">
                <span>  Take Vital </span>
            </span>
        </div>
    </div>
</ng-template>

<ng-template #vitalstatustemplate let-item="item">
    <div class="d-flex">
        <div class="unit-display unit-display-end ml-1">
            <div class="d-flex align-items-center justify-content-end">

                <div class="d-flex">
                    <div class="unit-display unit-display-end ml-1">
                        <div class="d-flex align-items-center justify-content-end">

                            <span *ngIf="item.Freq<=0" class="text-danger mr-1 font-weight-bold font-16">
                                <span>Missed</span>
                            </span>

                            <ng-container [ngSwitch]="true">
                                <span *ngSwitchCase="item.vitalFrequencyRecordId > 0" class="text-success font-weight-bold">Taken</span>
                                <span *ngSwitchDefault class="text-warning font-weight-bold">Not Taken</span>
                            </ng-container>


                            <span *ngIf="item.when === whenType[whenType.Now]" class="ml-1 font-weight-bold font-16">
                                <span>All Day</span>
                            </span>

                        </div>
                        <span class="text-capitalize">
                            <h5 class="mb-0 mt-0 font-weight-normal">
                                <span [textContent]="item.givenTime || '---'"></span>
                            </h5>
                        </span>
                    </div>
                    <span class="ml-2">
                        <img alt="all day" [src]="imgOrigin + 'assets/images/days/all-day.png'" width="32" />
                    </span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #templateAdd>
    <div class="modal-header">
        <h4 class="modal-title">
            <span>Add Vital Value</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="onCloseModal1()">×</button>
    </div>

    <div class="modal-body">
        <div class="row">

            <div class="col-lg-12 mt-2" *ngIf="!isVitalTypeLoading && vitalTypes.length > 0">
                <table class="table table-centered table-nowrap table-sm table-bordered mb-0">
                    <thead>
                        <tr>
                            <th>Vital Type</th>
                            <th>Value</th>
                            <th>Unit</th>
                            <th>Ranges</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of vitalArray; let i = index;">
                            <td>
                                <span>{{ item.name }}</span>
                            </td>
                            <td>
                                <input class="form-control" type="text" maxlength="3" [(ngModel)]="takenValue" placeholder="Enter measured value." />
                            </td>
                            <td>
                                <span>{{ item.unitType }}</span>
                            </td>
                            <td>
                                <span>{{ item.min }}</span> <b>-</b><span>{{ item.max }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal1()">Cancel</button>
        <button type="submit" class="btn btn-primary btn-sm" (click)="modify()">
            <span *ngIf="submitting">
                <span class="spinner-grow spinner-grow-sm mr-1"></span> please wait ...
            </span>
            <span *ngIf="!submitting"><i class="fe-plus mr-1" placement="right"></i>Submit</span>
        </button>
    </div>
</ng-template>

<!--Infusion TEMPLATE-->
<ng-template #InfusionTemplate let-item="item">
    <div class="media" [ngClass]="{'ml-1': viewType.Time === currentViewType, 'ml-3': viewType.Time !== currentViewType}" [ngStyle]="{'width.px': productWidth}">
        <div>
            <img [src]="imgOrigin + 'assets/images/Vitals/Bp.jpg'" width="28" />
        </div>
        <div class="media-body ml-1">
            <h5 class="mb-0 mt-0 font-weight-normal">
                <span [textContent]="item.infusionName || '---'"></span>
            </h5>
        </div>
    </div>
    <div class="d-flex" [ngClass]="{'ml-1': viewType.Time === currentViewType, 'ml-3': viewType.Time !== currentViewType}">
        <div class="unit-display unit-display-start ml-1" [ngStyle]="{'width.px': unitWidth}">
            <span>Infusion</span>
            <span class="text-capitalize">
                <span>  Take Infusion </span>
            </span>
        </div>
    </div>
</ng-template>

