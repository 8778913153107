import { IResource } from "@shared/models";
import { ModuleModel } from "./module.model";
import { PackageModuleDetailModel } from "./package-module-detail.model";
import { PackageModuleModel } from "./package-module.model";

export class PackageModuleViewModel {
    packageModule: PackageModuleModel;
    modules: Array<ModuleModel>;
    packageModuleDetails: Array<PackageModuleDetailModel>;
    chargeCategory: IResource;
    packageModules: Array<PackageModuleModel>;
    subRecords :Array<PackageModuleDetailModel> 
    chargeCategories:Array<IResource>;
    totalQuantity: number;
    freeQuantity: number;
    freeQuantityTotal: number;
    subTotal: number;
    discount: number;
    total: number;
    admissionPackageDate: Date;

    constructor() {
        this.modules = [];
        this.packageModuleDetails = [];
    }
}