export class LabPastReportsModel {
    newLabBookingHeaderId: number;
    patientId: number;
    newLabBookingDetailId: number;
    labMainDetailId: number;
    testName: string;
    TestCode: string;
    isExternalLab: boolean;
    isInternalLab: boolean;
    createdDate: Date;
    status: string;
    totalItems: number;
    encryptedNewLabBookingDetailId: string;
}

