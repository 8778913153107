export class PatientInsurance {
    patientInsuranceId: number;
    patientId: number;
    insuranceCompanyId: number;
    insuranceTypeId: number;
    validity?: Date;
    cardNo: string;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate?: Date;
    insuranceCompanyName: string;
}
