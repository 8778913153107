<div class="content">
    <div class="container-fluid">
        <div class="d-flex justify-content-between align-items-center mt-2">
            <div></div>
            <div>
                <div class="btn-group mr-1">
                    <ng-container *menuButton="'nurse_bed_dayview'">
                        <button class="btn btn-sm" [class.btn-light]="currentViewType !== viewType.Day" [class.btn-primary]="currentViewType == viewType.Day" type="button" (click)="changeViewType(viewType.Day)">
                            <i *ngIf="currentViewType != viewType.Day || !contentLoading" class="mdi mdi-view-day mr-1"></i>
                            <i *ngIf="currentViewType == viewType.Day && contentLoading" class="mdi mdi-spin mdi-loading mr-1"></i>
                            Day View
                        </button>
                    </ng-container>

                    <ng-container *menuButton="'nurse_bed_weekview'">
                        <button class="btn btn-sm" [class.btn-light]="currentViewType !== viewType.Week" [class.btn-primary]="currentViewType == viewType.Week" type="button" (click)="changeViewType(viewType.Week)">
                            <i *ngIf="currentViewType != viewType.Week || !contentLoading" class="mdi mdi-view-week mr-1"></i>
                            <i *ngIf="currentViewType == viewType.Week && contentLoading" class="mdi mdi-spin mdi-loading mr-1"></i>
                            Week View
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <form [formGroup]="mainForm" class="d-flex">
                    <div class="form-group w-250-px mr-2">
                        <label class="mb-1">Location</label>
                        <ng-select class="ng-select-sm text-uppercase" [items]="locations"
                                   bindLabel="name"
                                   bindValue="id"
                                   autocomplete="nope"
                                   placeholder="Select location"
                                   formControlName="locationId">
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                <div class="ng-option disabled">
                                    No location found for '{{searchTerm}}'
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="form-group w-250-px mr-2">
                        <label class="mb-1">Floors</label>
                        <ng-select [loading]="loading" placeholder="Enter Floor Name" formControlName="floorName" [items]="floorsFilter"></ng-select>
                    </div>
                    <div class="form-group w-250-px mr-2">
                        <label class="mb-1">Wards</label>
                        <ng-select [loading]="loading" placeholder="Enter Ward Name" formControlName="wardName" [items]="wardsFilter"></ng-select>
                    </div>
                    <div class="form-group w-250-px mr-2">
                        <label class="mb-1">Rooms</label>
                        <ng-select [loading]="loading" placeholder="Enter Room Name" formControlName="roomName" [items]="roomsFilter"></ng-select>
                    </div>
                    <div class="d-flex align-items-center justify-content-start">
                        <div class="mt-2">
                            <button (click)="fetch()" type="button" class="btn btn-primary white-space-nowrap mr-2">
                                <i class="fe-search mr-1"></i> Search
                            </button>
                            <button (click)="resetFilter()" type="button" class="btn btn-secondary white-space-nowrap">
                                <i class="fe-x mr-1"></i> Clear
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="d-flex justify-content-end align-items-center mb-2" *ngIf="currentViewType == viewType.Week">
            <div class="d-flex align-items-center mr-2" *ngFor="let item of shifts">
                <ng-container *ngIf="item.shiftName">
                    <div class="time-view-head" [textContent]="item.shiftCode"></div>
                    <div class="time-view-body ml-1" [textContent]="item.shiftName"></div>
                </ng-container>
            </div>
        </div>
        <div class="row" *ngIf="loading">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                    <span class="ml-2">Please wait while loading Nurse ...</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12" *ngIf="globalLoading">
                <div class="alert alert-info">
                    <span class="spinner-grow spinner-grow-sm mr-1"></span> please wait while loading view...
                </div>
            </div>
            <div class="col-12" *ngIf="!globalLoading">
                <div class="card mb-0" *ngIf="currentViewType == viewType.Week">
                    <div class="card-body p-0">
                        <div class="table-responsive-sm table-responsive-md table-responsive-lg">
                            <table class="table table-centered table-sm table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th width="200" rowspan="2">
                                            <div class="d-flex justify-content-center align-items-center">
                                                Bed Details
                                            </div>
                                        </th>
                                        <th [attr.colspan]="dates.length">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <div class="d-flex align-items-center">
                                                    <div (click)="previous()" class="d-flex align-items-center next-btn text-primary mr-2"><span class="mr-1">Previous Week</span><i class="mdi mdi-24px mdi-arrow-left-bold"></i></div>
                                                    <ng-container *menuButton="'nurse_bed_todayview'">
                                                        <button class="btn btn-sm btn-light mx-3" (click)="goToToday()"><i class="mdi mdi-calendar-month mr-1"></i> Today</button>
                                                    </ng-container>
                                                    <div (click)="next()" class="d-flex align-items-center next-btn text-primary ml-2"><i class="mdi mdi-arrow-right-bold mdi-24px"></i><span class="ml-1">Next Week</span></div>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th *ngFor="let date of dates" [class.table-warning]="date.isToday">
                                            <div class="d-flex justify-content-center text-primary" [textContent]="date.fDate | date:'MMM d, y, EEE'"></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of dataRecords; let i = index">
                                        <td>
                                            <ng-container *ngTemplateOutlet="bedDetails; context: {item : item}"></ng-container>
                                        </td>
                                        <td *ngFor="let subItem of item.dates; let j = index">
                                            <ng-container *ngFor="let token of subItem.details; let k = index">
                                                <div class="d-flex align-items-center mb-1" *ngIf="token.nurseName && token.nurseName !== 'NA'">
                                                    <div class="time-view-head" [textContent]="token.shiftCode"></div>
                                                    <div class="time-view-body bg-success text-white d-flex align-items-center"><span [textContent]="token.nurseName" class="ml-1 text-capitalize"></span></div>
                                                    <div class="time-view-tail bg-success"><i class="mdi mdi-check"></i></div>
                                                </div>
                                                <div class="d-flex align-items-center mb-1 cursor-pointer week-shift-slot" (click)="assignNurse(templateAssignNurse, i, j, k)" *ngIf="!token.nurseName || token.nurseName === 'NA'">
                                                    <div class="time-view-head" [textContent]="token.shiftCode"></div>
                                                    <div class="time-view-body bg-soft-danger text-dark d-flex align-items-center"><span [textContent]="'Unassigned'" class="ml-1"></span></div>
                                                    <div class="time-view-tail bg-soft-danger"><i class="mdi mdi-record-circle text-dark"></i></div>
                                                </div>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card" *ngIf="currentViewType == viewType.Day">
                    <div class="card-body p-0">
                        <div class="table-responsive-sm table-responsive-md table-responsive-lg">
                            <table class="table table-centered table-sm table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th width="200" rowspan="3">
                                            <div class="d-flex justify-content-center align-items-center">
                                                Bed Details
                                            </div>
                                        </th>
                                        <th colspan="24">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <div class="d-flex align-items-center">
                                                    <div (click)="previous()" class="d-flex align-items-center next-btn mr-2"><span class="mr-1">Previous Day</span><i class="mdi mdi-24px mdi-arrow-left-bold"></i></div>
                                                    <div class="ml-2 mr-2" [textContent]="formattedFilterToday | date:'MMM d, y, EEE'"></div>
                                                    <div (click)="next()" class="d-flex align-items-center next-btn ml-2"><i class="mdi mdi-arrow-right-bold mdi-24px"></i><span class="ml-1">Next Day</span></div>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th [attr.colspan]="shift.colspan" *ngFor="let shift of shiftsOnly">
                                            <div class="d-flex justify-content-center align-items-center" [textContent]="shift.shiftName"></div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th *ngFor="let shift of shiftMocks">
                                            <div class="d-flex justify-content-center align-items-center text-primary"
                                                 [textContent]="(shift > 12 && shift < 24) ? ((shift - 12) + ' PM') : shift >= 24 ? (((shift - 24) === 0 ? '12' : (shift - 24)) + ' AM') : (shift + ' AM') "></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of dataRecords; let i = index">
                                        <td>
                                            <ng-container *ngTemplateOutlet="bedDetails; context: {item : item}"></ng-container>
                                        </td>
                                        <ng-container *ngFor="let date of item.dates">
                                            <td [attr.colspan]="token.colspan"
                                                *ngFor="let token of date.details">
                                                <div class="time-block bg-success" *ngIf="token.shiftName !== 'NA'">
                                                    <div class="d-flex justify-content-center text-white text-capitalize" [textContent]="'Nurse | ' + token.nurseName"></div>
                                                </div>
                                                <div class="time-block bg-light" placement="left" ngbTooltip="Click to assign nurse" *ngIf="token.shiftName === 'NA'">
                                                    <div class="d-flex justify-content-center">
                                                        Unassigned
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #bedDetails let-item="item">
    <div>
        <div class="d-flex justify-content-center align-items-center">
            <span class="text-capitalize" [textContent]="item.roomName"></span>
        </div>
        <div class="d-flex align-items-center flex-column">
            <div class="d-flex justify-content-center align-items-center mb-1">
                <span class="text-primary font-weight-bold text-capitalize" [textContent]="item.bedName"></span>
            </div>
            <h6 class="text-black-50 m-0" [textContent]="item.chargeCategoryName"></h6>
        </div>
    </div>
</ng-template>


<ng-template #templateAssignNurse>
    <form [formGroup]="availableForm"  (submit)="onSubmit()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-bed mr-1"></i>Assign Nurse</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="close();">×</button>
        </div>
        <div class="modal-body p-0">
            <div class="table-responsive p-1">
                <table class="table-sm w-100-per table-bordered">
                    <tbody>
                        <tr>
                            <th class="table-active">Bed Name</th>
                            <td [textContent]="selected.bedName"></td>
                            <th class="table-active">Date</th>
                            <td [textContent]="(dateDisplay | date) || '---'"></td>
                        </tr>
                        <tr>
                            <th class="table-active">Shift Name</th>
                            <td [textContent]="selected.shiftName"></td>
                            <td colspan="2">
                                <div class="d-flex justify-content-center">---</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-box mb-0">
                <h4 class="box-title">
                    <i class="mdi mdi-account-box-outline mr-1"></i>Select Nurse
                </h4>
                <div *ngIf="loadingNurse" class="d-flex align-items-center">
                    <span class="spinner-grow spinner-grow-sm mr-2"></span> please wait while loading records
                </div>
                <div class="d-flex flex-wrap align-items-center" *ngIf="!loadingNurse">
                    <div formArrayName="accounts" class="form-group mb-0" *ngFor="let account of availableNurses; let i = index">
                        <ng-container [formGroupName]="i">
                            <div class="alert alert-info d-flex align-items-center mr-2">
                                <input type="radio" name="nurse" class="form-control-md" formControlName="is" (change)="onNurseSelected(account)" />
                                <div [textContent]="account.fullName" class="text-capitalize ml-1 white-space-nowrap"></div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary btn-sm mr-1"> Submit</button>
            <button type="button" class="btn btn-light btn-sm mr-1" (click)="close();">Close</button>
        </div>
    </form>
</ng-template>
