<ng-container *ngIf="!showOnlyModal">
    <div class="content mb-5">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <div class="page-title-right">
                            <a href="javascript:;" class="btn btn-secondary btn-sm ml-1" (click)="onBack()">
                                <span><i class="fe-arrow-left mr-1"></i> Back</span>
                            </a>
                        </div>
                        <h4 class="page-title">Assign Shift</h4>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <form [formGroup]="mainForm" (ngSubmit)="onSubmit()">

                        <!-- <div class="card-box">
                          <div class="box-title">
                            <div class="d-flex align-items-center justify-content-between">
                              <div>
                                <h5><i class="mdi mdi-timer-settings-outline mr-1"></i>Nurse Details</h5>
                              </div>
                              <div>
                                <i *ngIf="loadingNurse" class="mdi mdi-24px mdi-spin mdi-loading"></i>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex" *ngIf="nurse">
                            <div>
                              <div class="media">
                                <div class="avatar-sm mr-2">
                                  <avatar-img *ngIf="nurse.nurseImage" [src]="nurse.nurseImage" [alt]="nurse.fullName" cssClass="img-fluid rounded-circle"></avatar-img>
                                  <span *ngIf="!nurse.nurseImage" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="3">
                                    <span [textContent]="nurse.fullName | initials"></span>
                                  </span>
                                </div>
                                <div class="media-body">
                                  <h5 class="mb-0 mt-0 font-weight-normal text-capitalize" [textContent]="nurse.fullName | titlecase"></h5>
                                  <span class="d-block font-13">
                                    <span [textContent]="nurse.roleName"></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> -->

                        <div class="d-flex my-3" *ngIf="loading">
                            <span class="spinner-grow spinner-grow-sm mr-1"></span> Please wait while loading shifts
                        </div>

                        <ng-container *ngIf="!loading">
                            <div class="card-box pb-0">
                                <div class="box-title d-flex justify-content-between align-items-center">
                                    <h5 class="m-0"><i class="mdi mdi-bed-outline mr-1"></i>Bed Assignment</h5>
                                    <div class="text-capitalize text-primary font-weight-bold" [textContent]="nurse.fullName"></div>
                                </div>
                                <div class="row">
                                    <ng-container formArrayName="slots" *ngFor="let item of slotsHolder; let i = index">
                                        <ng-container [formGroupName]="i">
                                            <div class="col-lg-12 slot-block">
                                                <div class="d-flex">
                                                    <div>
                                                        <ng-container *ngIf="!readOnly">
                                                            <div class="form-group mb-3">
                                                                <label class="mb-1">Shift<code>*</code></label>
                                                                <div class="form-group">
                                                                    <ng-select [items]="shifts"
                                                                               bindLabel="value"
                                                                               [loading]="loadingShifts"
                                                                               bindValue="id"
                                                                               placeholder="Select Shift"
                                                                               (change)="onShiftChange($event, i)"
                                                                               formControlName="shiftId">
                                                                    </ng-select>
                                                                    <div *ngIf="submitted && formSlots.controls[i]['controls']['shiftId'].errors" class="invalid-feedback show-must">
                                                                        <div *ngIf="formSlots.controls[i]['controls']['shiftId'].errors.required"><span class="mdi mdi-circle-medium mr-1"></span>Shift is required</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="form-group mb-3">
                                                                <ngb-datepicker [markDisabled]="markDisabled" [minDate]="item.minDate" (dateSelect)="onDateSelection($event, i)" [displayMonths]="isSingle ? 1 : 2" [dayTemplate]="t" outsideDays="hidden"></ngb-datepicker>

                                                                <ng-template #t let-date let-focused="focused">
                                                                    <span class="custom-day"
                                                                          [class.focused]="focused"
                                                                          [class.range]="isRange(date, i)"
                                                                          [class.faded]="isHovered(date, i) || isInside(date, i)"
                                                                          (mouseenter)="onMouseEnter(date, i)"
                                                                          (mouseleave)="onMouseLeave(i)">
                                                                        <span [textContent]="date.day"></span>
                                                                    </span>
                                                                </ng-template>

                                                                <div *ngIf="submitted && formSlots.controls[i]['controls']['toDate'].errors" class="invalid-feedback show-must">
                                                                    <div *ngIf="formSlots.controls[i]['controls']['toDate'].errors.required"><span class="mdi mdi-circle-medium mr-1"></span>Date is required</div>
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                    </div>
                                                    <div class="flex-grow-1 d-flex flex-column">
                                                        <div class="d-flex align-items-center justify-content-center">
                                                            <div class="d-flex ml-auto flex-column align-items-center justify-content-center">
                                                                <div *ngIf="item.toDate" class="d-flex-center mb-1 mt-2">
                                                                    <i class="mdi mdi-calendar mr-1"></i>
                                                                    <span class="font-14 font-weight-bold">
                                                                        <span [textContent]="item.fromDate | date:'MMMM d, y'"></span>
                                                                        <ng-container *ngIf="!item.isSameDate">
                                                                            <span><i class="mdi mdi-minus mx-1"></i></span>
                                                                            <span [textContent]="item.toDate | date:'MMMM d, y'"></span>
                                                                        </ng-container>
                                                                    </span>
                                                                </div>
                                                                <div class="mb-1" *ngIf="readOnly && item.shiftName">
                                                                    <div class="d-flex">
                                                                        <i class="mdi mdi-image-filter-tilt-shift mr-1"></i>
                                                                        <span class="font-14 font-weight-bold mr-2" [textContent]="item.shiftName"></span>
                                                                    </div>
                                                                </div>
                                                                <div class="mb-2" *ngIf="item.selectedSlots && item.selectedSlots.length">
                                                                    <div class="d-flex">
                                                                        <i class="mdi mdi-clock-outline mr-1"></i>
                                                                        <span *ngFor="let slot of item.selectedSlots" class="font-14 font-weight-bold mr-2" [textContent]="slot.optionalText"></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex align-self-end mb-auto ml-auto">
                                                                <div *ngIf="slotsHolder[i].selectedBeds && slotsHolder[i].selectedBeds.length && !readOnly" (click)="onOpenModel(templateSelectBed, i)"
                                                                     ngbTooltip="Add/Remove Beds" placement="left" class="form-group remove-shift mb-0 mr-2">
                                                                    <i class="mdi mdi-pencil mdi-24px text-primary"></i>
                                                                </div>
                                                                <div *ngIf="!isSingle && !readOnly" (click)="removeShift(i)" ngbTooltip="Remove Shift" placement="left" class="form-group remove-shift mb-0">
                                                                    <i class="mdi mdi-close-thick mdi-24px text-danger"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex flex-grow-1" *ngIf="!item.selectedBeds || (item.selectedBeds && !item.selectedBeds.length)">
                                                            <div class="no-data">
                                                                <img src="assets/images/no-data.png" alt="No data" />
                                                                <p class="sub-title">There is no data to show you right now.</p>
                                                                <div *ngIf="submitted && formSlots.controls[i]['controls']['beds'].errors" class="invalid-feedback show-must mb-3 font-16 text-center">
                                                                    <div *ngIf="formSlots.controls[i]['controls']['beds'].errors.required"><span class="mdi mdi-circle-medium mr-1"></span>At least 1 bed is required</div>
                                                                </div>
                                                                <button *ngIf="!readOnly" type="button" (click)="onOpenModel(templateSelectBed, i)" class="btn btn-sm btn-light">
                                                                    <ng-container *ngIf="!item.loadingBeds"><i class="mdi mdi-plus-thick mr-1"></i> Assign Beds</ng-container>
                                                                    <ng-container *ngIf="item.loadingBeds"><i class="mdi mdi-spin mdi-loading mr-1"></i> Please wait</ng-container>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center mb-3 flex-column ml-3" *ngIf="item.selectedBeds">
                                                            <div class="d-flex flex-wrap">
                                                                <div class="card bed-slot m-1" *ngFor="let subItem of item.selectedBeds; let j = index">
                                                                    <div class="card-body py-2 slot-min-height">
                                                                        <div class="h-100 d-flex flex-column justify-content-between">
                                                                            <ng-container *ngTemplateOutlet="bed; context: {item : subItem}"></ng-container>
                                                                            <div class="nurse-block pt-1 d-flex align-items-center justify-content-between">
                                                                                <div>
                                                                                    <div class="media cursor-pointer" *ngIf="nurse">
                                                                                        <div class="avatar-sm mr-2">
                                                                                            <avatar-img *ngIf="nurse.nurseImage" [src]="nurse.nurseImage" [alt]="nurse.fullName" cssClass="img-fluid rounded-circle"></avatar-img>
                                                                                            <span *ngIf="!nurse.nurseImage" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="3">
                                                                                                <span [textContent]="nurse.fullName | initials"></span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="media-body">
                                                                                            <h5 class="mb-0 mt-0 font-weight-normal text-capitalize" [textContent]="nurse.fullName | titlecase"></h5>
                                                                                            <span class="d-block font-13">
                                                                                                <span [textContent]="nurse.roleName"></span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div *ngIf="!readOnly" ngbTooltip="Remove Bed" placement="left" class="form-group mb-0" (click)="removeBed(i, j)">
                                                                                        <i class="mdi mdi-close mdi-24px text-danger"></i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="subItem.isUnavailable" class="card-footer p-1 bg-soft-danger">
                                                                        <ng-template #assignedTo>
                                                                            <div class="media cursor-pointer" *ngIf="nurse">
                                                                                <div class="avatar-sm mr-2">
                                                                                    <span class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="4">
                                                                                        <span [textContent]="subItem.assignedFullName | initials"></span>
                                                                                    </span>
                                                                                </div>
                                                                                <div class="media-body">
                                                                                    <h5 class="mb-0 mt-0 font-weight-normal text-capitalize" [textContent]="subItem.assignedFullName | titlecase"></h5>
                                                                                    <span class="d-block font-13">
                                                                                        <span [textContent]="subItem.assignedRoleName"></span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </ng-template>

                                                                        <div class="d-flex justify-content-center text-danger font-weight-bold">
                                                                            <i placement="left" [ngbPopover]="assignedTo" triggers="mouseenter:mouseleave" popoverTitle="Already Assigned To" class="mdi mdi-information mr-1"></i><span>Not Available</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <div *ngIf="!isSingle && !readOnly" class="box-title mt-2 mb-0">
                                    <div class="d-flex justify-content-end">
                                        <button (click)="addSlot()" type="button" class="btn btn-sm btn-primary"><i class="mdi mdi-playlist-plus mr-1"></i> Add New</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="fixed-bottom" *ngIf="!readOnly">
        <div class="d-flex justify-content-end">
            <button (click)="reset()" type="button" class="btn my-2 mr-2 btn-sm reset-btn">
                <i class="mdi mdi-check-bold mr-1"></i><span>Reset</span>
            </button>
            <button type="submit" class="btn my-2 mr-3 btn-sm btn-success" (click)="onSubmit()">
                <ng-container *ngIf="!submitting">
                    <i class="mdi mdi-check-bold mr-1"></i>
                    <span *ngIf="mode.New === currentMode">Submit</span>
                    <span *ngIf="mode.New !== currentMode">Update</span>
                </ng-container>
                <ng-container *ngIf="submitting">
                    <span class="spinner-grow spinner-grow-sm mr-1"></span> Please wait
                </ng-container>
            </button>
        </div>
    </div>
</ng-container>

<ng-template #templateSelectBed>
    <form [formGroup]="mainForm">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-bed mr-1"></i>Select Bed</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body p-0 overflow-auto" style="height:75vh;">
            <div class="appointment-form pb-0 bg-light bg-pattern">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="form-group w-100 mr-2">
                        <label class="mb-1">Location</label>
                        <ng-select class="ng-select-sm text-uppercase"
                                   [items]="locations"
                                   bindLabel="name"
                                   bindValue="id"
                                   autocomplete="nope"
                                   placeholder="Select location"
                                   formControlName="locationId"
                                   (change)="onfilterlocation()">
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                <div class="ng-option disabled">
                                    No location found for '{{searchTerm}}'
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>

                    <div class="form-group w-100 mr-2">
                        <label class="mb-1">Floors</label>
                        <ng-select [loading]="loadingBeds" placeholder="Enter Floor Name" formControlName="floorName" [items]="floorsFilter"></ng-select>
                    </div>
                    <div class="form-group w-100 mr-2">
                        <label class="mb-1">Wards</label>
                        <ng-select [loading]="loadingBeds" placeholder="Enter Ward Name" formControlName="wardName" [items]="wardsFilter"></ng-select>
                    </div>
                    <div class="form-group w-100 mr-2">
                        <label class="mb-1">Rooms</label>
                        <ng-select [loading]="loadingBeds" placeholder="Enter Room Name" formControlName="roomName" [items]="roomsFilter"></ng-select>
                    </div>
                    <div class="form-group w-100 mr-2">
                        <label class="mb-1">Beds</label>
                        <ng-select [loading]="loadingBeds" placeholder="Enter Bed Name" formControlName="bedName" [items]="bedsFilter"></ng-select>
                    </div>
                    <div class="d-flex align-items-center justify-content-start">
                        <button (click)="resetFilter()" type="button" class="btn btn-secondary btn-sm white-space-nowrap">
                            <i class="fe-x mr-1"></i> Clear
                        </button>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <div class="form-group mb-0 mr-2">
                            <label class="d-flex align-items-center">
                                <input type="checkbox" formControlName="hideAssigned" class="form-control w-20-px mr-1" />
                                Show UnAssigned Beds
                            </label>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="form-group mb-0 mr-2">
                            <label class="d-flex align-items-center">
                                <input type="checkbox" formControlName="selectAll" class="form-control w-20-px mr-1" />
                                Select All
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-2" *ngIf="loadingBeds">
                <span class="spinner-grow spinner-grow-sm mr-1"></span> Please wait while loading beds.
            </div>
            <ng-container *ngIf="beds.length">
                <ng-container formArrayName="slots">
                    <ng-container [formGroupName]="selectedSlotIndex">
                        <div class="d-flex flex-wrap slots-holder mt-2">
                            <ng-container formArrayName="beds" *ngFor="let item of beds">
                                <ng-container [formGroupName]="item.bedIndex">
                                    <div class="mx-2 my-2" *ngIf="formSlotBedIsShow(selectedSlotIndex, item.bedIndex)">
                                        <div class="card bed-slot m-0 h-100">
                                            <div class="card-body py-2" [ngClass]="{'bg-soft-success': formSlotBedStatus(selectedSlotIndex, item.bedIndex)}">
                                                <div class="h-100 d-flex flex-column justify-content-between">
                                                    <ng-container *ngTemplateOutlet="bed; context: {item : item}"></ng-container>
                                                    <div class="nurse-block pt-1 d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <div class="media cursor-pointer" *ngIf="!formSlotBedStatus(selectedSlotIndex, item.bedIndex) && !item.nurseShiftMapId">
                                                                <div class="avatar-sm mr-2">
                                                                    <span class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="9">
                                                                        <i class="mdi mdi-help mdi-24px"></i>
                                                                    </span>
                                                                </div>
                                                                <div class="media-body">
                                                                    <span class="font-16 text-muted">Unnassigned</span>
                                                                </div>
                                                            </div>
                                                            <div class="media cursor-pointer" *ngIf="formSlotBedStatus(selectedSlotIndex, item.bedIndex) || item.nurseShiftMapId">
                                                                <ng-container *ngIf="!item.nurseShiftMapId">
                                                                    <div class="avatar-sm mr-2">
                                                                        <avatar-img *ngIf="nurse.nurseImage" [src]="nurse.nurseImage" [alt]="nurse.fullName" cssClass="img-fluid rounded-circle"></avatar-img>
                                                                        <span *ngIf="!nurse.nurseImage" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="3">
                                                                            <span [textContent]="nurse.fullName | initials"></span>
                                                                        </span>
                                                                    </div>
                                                                    <div class="media-body">
                                                                        <h5 class="mb-0 mt-0 font-weight-normal text-capitalize" [textContent]="nurse.fullName | titlecase"></h5>
                                                                        <span class="d-block font-13">
                                                                            <span [textContent]="nurse.roleName"></span>
                                                                        </span>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container *ngIf="item.nurseShiftMapId">
                                                                    <div class="avatar-sm mr-2">
                                                                        <avatar-img *ngIf="item.nurseImage" [src]="item.nurseImage" [alt]="item.fullName" cssClass="img-fluid rounded-circle"></avatar-img>
                                                                        <span *ngIf="!item.nurseImage" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="3">
                                                                            <span [textContent]="item.fullName | initials"></span>
                                                                        </span>
                                                                    </div>
                                                                    <div class="media-body">
                                                                        <h5 class="mb-0 mt-0 font-weight-normal text-capitalize" [textContent]="item.fullName | titlecase"></h5>
                                                                        <span class="d-block font-13">
                                                                            <span [textContent]="item.roleName"></span>
                                                                        </span>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="form-group mb-0" *ngIf="!item.nurseShiftMapId || item.nurseAccountId === nurse.accountId">
                                                                <input type="checkbox" class="wh-20-px" formControlName="status" />
                                                            </div>
                                                            <div class="form-group mb-0" *ngIf="item.nurseShiftMapId && item.nurseAccountId !== nurse.accountId">
                                                                <div class="d-flex flex-column">
                                                                    <div class="small text-muted" [textContent]="item.fromDate | date:'MMM dd, yyyy'"></div>
                                                                    <div class="small text-muted" [textContent]="item.toDate | date:'MMM dd, yyyy'"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="item.nurseShiftMapId && item.nurseAccountId !== nurse.accountId" class="card-footer p-1 bg-soft-danger">
                                                <div class="d-flex justify-content-center text-danger font-weight-bold">
                                                    <span>Not Available</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
            <button type="button" (click)="onBedSelected()" [disabled]="submitting" class="btn btn-primary btn-sm">
                <i class="mdi mdi-check-all mr-1"></i> <span *ngIf="submitting">submitting</span><span *ngIf="!submitting">Update</span>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #bed let-item="item">
    <div class="bed-block">
        <div class="d-flex align-items-center justify-content-between">
            <h4 class="mb-0 text-capitalize">
                <i class="mdi mdi-bed mr-1"></i><span [textContent]="item.bedName"></span>
            </h4>
            <div *ngIf="item.priorityName">
                <i [class]="'mdi mr-1 mdi-' + item.priorityIcon + ' ' + item.priorityColor"></i>
                <span class="text-muted" [textContent]="item.priorityName"></span>
            </div>
        </div>
        <div>
            <span class="text-black-50 small">
                <ng-container *ngIf="item.floorName">
                    <span [textContent]="item.floorName" class="mr-1"></span>
                    <span class="mdi mdi-circle-medium mr-1"></span>
                </ng-container>
                <span [textContent]="item.wardName" class="mr-1"></span>
                <span class="mdi mdi-circle-medium mr-1"></span>
                <span [textContent]="item.roomName" class="mr-1"></span>
            </span>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-center" *ngIf="!item.admissionNo">
        <h3 class="text-muted empty-bed">Empty Bed</h3>
    </div>
    <div class="admission-block my-2 d-flex justify-content-between" *ngIf="item.admissionNo">
        <div class="patient-block">
            <h6>Patient</h6>
            <div class="media cursor-pointer">
                <div class="avatar-sm mr-2">
                    <avatar-img *ngIf="item.patientImage" [src]="item.patientImage" [alt]="item.patientName" cssClass="img-fluid rounded-circle"></avatar-img>
                    <span *ngIf="!item.patientImage" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="2">
                        <span class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="item.patientName"></span>
                    </span>
                </div>
                <div class="media-body">
                    <h5 class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="item.patientName | titlecase"></h5>
                    <span class="d-block font-13">
                        <span [textContent]="item.patientGender | gender"></span>
                        <span class="ml-1" [textContent]="item.patientAge + ' yrs'"></span>
                    </span>
                    <!--<span class="d-block font-13">
                      <span [textContent]="item.umrno"></span>
                    </span>-->
                </div>
            </div>
        </div>
        <div>
            <h6>Doctor</h6>
            <div class="media cursor-pointer">
                <div class="avatar-sm mr-2">
                    <avatar-img *ngIf="item.doctorImage" [src]="item.doctorImage" [alt]="item.doctorName" cssClass="img-fluid rounded-circle"></avatar-img>
                    <span *ngIf="!item.doctorImage" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="8">
                        <span [textContent]="item.doctorName | initials"></span>
                    </span>
                </div>
                <div class="media-body">
                    <h5 class="mb-0 mt-0 font-weight-normal text-capitalize" [textContent]="item.doctorName | titlecase"></h5>
                    <span class="d-block font-13">
                        <span [textContent]="item.doctorGender | gender"></span>
                        <span class="ml-1" [textContent]="item.departmentName"></span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</ng-template>