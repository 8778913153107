import { Directive, OnInit, Input, ElementRef } from "@angular/core";

@Directive({
    selector: "[avatar-bg]"
})
export class AvatarBgDirective implements OnInit {
    @Input() index: number;

    backgrounds: Array<string> = [
        "bg-primary",
        "bg-secondary",
        "bg-success",
        "bg-danger",
        "bg-warning",
        "bg-info",
        "bg-pink",
        "bg-blue"
    ];

    constructor(private readonly el: ElementRef) { }

    ngOnInit() {
        this.el.nativeElement.classList.add(this.backgrounds[this.index % this.backgrounds.length]);
    }
}