import { CommunicationType } from '../enums/communication-type.enum';

export class CommunicationMessageModel {
    type: CommunicationType;
    accountId: number;
    roleId: number;
    message: string;

    telemedicineId: number;
    templateName: string;
    startAudioOnly: boolean;

    content?: object;

    uniqueId: string;
    userUniqueId: string;

    mainId: number;
    notifyToId: number;
}
