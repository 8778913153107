import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

export interface IAppConfig {
    maintenance: boolean;
    baseURI: string;
    baseWeb: string;
    env: string;
    envTitle: string;
    version: string;
    hostingUrl: string;
    signalR: string;
    whatsappServiceBase: string;
    whatsappOPDRegistrationUrl: string;
}

@Injectable()

export class AppConfig {
    static settings: IAppConfig;

    constructor(private readonly http: HttpClient) {
    }

    load() {
        const url = location.origin + location.pathname;
        return this.http.get<IAppConfig>(url + "assets/settings.json", { headers: { ignoreLoadingBar: "", "Auth": "False" } })
            .subscribe({
                next: (response: IAppConfig) => {
                    localStorage.removeItem("settings");
                    AppConfig.settings = response;
                    sessionStorage.setItem("settingsUrls", JSON.stringify(response));
                    localStorage.setItem("settings", JSON.stringify(response));
                },
                error: () => {
                    AppConfig.settings = {
                        maintenance: false, baseURI: "", env: "", envTitle: "", version: "", baseWeb: "", hostingUrl: "", signalR: "",
                        whatsappServiceBase: "", whatsappOPDRegistrationUrl:""
                    };
                }
            });
    }

    getURI(base: string, endPoint: string): string {
        const localSettings = localStorage.getItem("settings");
        const localSettingsFromSession = sessionStorage.getItem("settingsUrls");
        if (localSettings) {
            const setting = (JSON.parse(localSettings) as IAppConfig);
            return setting.baseURI + base + "/" + endPoint;
        }
        else if (localSettingsFromSession) {
            const setting = (JSON.parse(localSettingsFromSession) as IAppConfig);
            return setting.baseURI + base + "/" + endPoint;
        } else {
            return AppConfig.settings.baseURI + base + "/" + endPoint;
        }
    }

    returnUrl() {
        return AppConfig.settings.baseURI;
    }
}