export class LabTechModel {
    newLabBookingHeaderId: number | null;
    newLabBookingDetailId: number | null;
    labMainDetailId: number | null;
    labBookingStatusId: number | null;
    holdBy: number | null;
    unHoldBy: number | null;
    technicianId: number | null;
    comment: string;
    holdComments: string;
    unHoldComments: string;
    technicianComment: string;
    requisitionNumber: string;
    billedLocation: string;
    sampleCollectionLocation: string;
    sampleCollectedBy: string;
    status: string;
    testName: string;
    testCode: string;
    patientName: string;
    mobile: string;
    doctorName: string;
    employeeName: string;
    departmentName: string;
    technicianName: string;
    emergency: boolean;
    technicianVerificationDate: Date | null;
    bookedDate: Date | null;
    collectionDate: Date | null;
    dateOfBirth: Date | null;
    isSelected: boolean;
    patientId: number;
    bookingType: string;
}