import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "maritalStatus"
})
export class MaritalStatusPipe implements PipeTransform {
    transform(val: string) {
        switch (val) {
            case "M":
                return "Married";
            case "S":
                return "Single";
            case "D":
                return "Divorced";
            case "W":
                return "Widowed";
            case "O":
                return "Other";
            default:
                return "N/A";
        }
    }
}