<div class="sidebar-main-menu lgSubMenu addedLeft bg_size">
    <div id="two-col-menu" class="h-100" data-simplebar>
        <div class="twocolumn-menu-item d-block">
            <ng-container *ngIf="record">
                <div class="d-flex align-items-center justify-content-center flex-column">
                    <div class="avatar-md">
                        <avatar-img *ngIf="record.thumbnailUrl" [src]="record.thumbnailUrl" [alt]="record.fullName" cssClass="img-fluid rounded-circle"></avatar-img>
                        <span *ngIf="!record.thumbnailUrl" class="avatar-title rounded-circle bg-primary font-24 text-white" [textContent]="record.fullName | initials"></span>
                    </div>
                    <h5 class="mb-0">
                        <a href="javascript:;" class="text-dark">
                            <span class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="record.fullName"></span>
                        </a>
                    </h5>
                    <small class="d-block font-13 mt-1x" [textContent]="record.umrNo"></small>
                    <h6 class="mb-0">
                        <a href="javascript:;" class="text-dark">
                            <span [textContent]="'(' + record.gender+ ') '"></span>
                            <span [textContent]="record.age + 'yrs'"></span>
                        </a>
                    </h6>
                    <h6 class="mb-0">
                        <a href="javascript:;" class="text-dark">
                            <span class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="'Dr. ' + record.providerName + ', '"></span>
                            <span [textContent]="'(' + record.providerGender+ ')'"></span>
                        </a>
                    </h6>
                    <h6 class="mt-2 mb-0 text-muted" [textContent]="record.departmentName"></h6>
                    <div class="mb-2">
                        <span class="badge badge-success" *ngIf="isAdmission">In Patient</span>
                        <span class="badge badge-warning" *ngIf="!isAdmission">Out Patient</span>
                    </div>
                </div>
                <div>
                    <div class="overflow-auto">
                        <table class="table table-centered table-bordered table-sm mb-0">
                            <tbody>
                                <tr>
                                    <th class="small">
                                        <span *ngIf="isAdmission">Admission No</span>
                                        <span *ngIf="!isAdmission">Appointment No</span>
                                    </th>
                                    <td class="small" [textContent]="record.admissionNo"></td>
                                </tr>
                                <tr>
                                    <th class="small">Date</th>
                                    <td class="small" [textContent]="record.admissionDate | date: 'dd-MM-yyyy'"></td>
                                </tr>
                                <tr>
                                    <th class="small">Time</th>
                                    <td class="small">
                                        <span *ngIf="isAdmission" [textContent]="record.admissionDate | date: 'h:mm:ss a'"></span>
                                        <span *ngIf="!isAdmission" [textContent]="record.timeString"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="small">Room Name</th>
                                    <td class="small" [textContent]="record.roomName"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
            <h5 class="menu-title mb-2 text-primary text-center">Navigation</h5>

            <ul class="nav flex-column encounter-menu">
                <ng-container *ngFor="let menu of menus">
                    <ng-container *ngIf="menuType.SubMenu === menu.menuTypeId">
                        <li class="nav-item">
                            <a class="dropdown-item"
                               (click)="change(menu.subPage)"
                               [routerLink]="menu.subPage"
                               [class.active]="activeRoute === menu.subPage"
                               href="javascript:;">
                                <i *ngIf="menu.iconClasses" [class]="'mr-1 ' + menu.iconClasses"></i>
                                <span class="text-capitalize" [textContent]="menu.displayName"></span>
                            </a>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="menuType.CategoryMenu === menu.menuTypeId">
                        <li class="nav-item category-menu">
                            <a [href]="'#sidebarMultilevel' + '_' + menu.id" data-toggle="collapse" class="dropdown-item" aria-expanded="true">
                                <i [class]="'mr-1 ' + menu.iconClasses"></i>
                                <span [textContent]="menu.displayName"></span>
                                <span class="menu-arrow"></span>
                            </a>
                            <div class="collapse show" [id]="'sidebarMultilevel' + '_' + menu.id">
                                <ul class="nav-second-level">
                                    <li class="nav-item" *ngFor="let item of menu.categoryMenus">
                                        <a class="dropdown-item"
                                           (click)="change(menu.subPage, item.subPage)"
                                           [routerLink]="item.subPage"
                                           [class.active]="activeRoute === item.subPage"
                                           href="javascript:;">
                                            <i [class]="'mr-1 ' + item.iconClasses"></i>
                                            <span class="text-capitalize" [textContent]="item.displayName"></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
