import { Time } from "@angular/common";

export class Report {
    providerId: string;
    departmentId: number;
    fromDate: Date;
    toDate: Date;
    departmentName: string;
    gender: number;
    admissionId: string;
    admissionNo: string;
    admissionDate: Date | string;
    admissionTime: Time;
    patientName: string;
    patientAge: number;
    patientGender: string;
    providerAge: number;
    providerGender: string;
    providerName: string;
    wardName: string;
    roomName: string;
    bedNumber: string;
    attendantName: string;
    attendantRelationWithPatient: string;
    attendantContactNo: string;
    isDischarged: boolean;
    dischargeDate: Date | string;
    dischargeTime: Time;
    dischargeStatus: string;
    isMaternity: boolean;
    encounterId: number;
    surgeryName: string;
    providerThumbnailUrl: string;
    patientThumbnailUrl: string;
    totalAdmissions: number;
    totalItems: number;
    totalAmount: number;
    admissionTimeString: string;
    patientMobile: string;
    umrNo: string;
    createdBy: string;
    roleName: string;
    referredBy: string;
    referredByName: string;
    salutation: string;
    dateOfBirth: Date | string;
    caseTypeName: string;
    nationality: string;
    city: string;
    zipcode: string;
    religion: string;
    floorName: string;
    relationMobile: string;
    area: string;
    address: string;
    relativeDOB: Date | string;
    referBy: string;
    admissionPackageId: string;

}