import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, OnChanges } from "@angular/core";
import { IUserAccount, Page, PatientProfile } from "@shared/models";
import { AppData, HttpService, AppointmentRefreshService, NotifyService } from "@shared/services";
import { ApiResources, DateHelper } from "@shared/helpers";
import { takeUntil, finalize } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { Admission, HealthCard, Setting } from "../../entities";
import { DatePipe } from "@angular/common";

@Component({
    templateUrl: "./lab-header.html",
    selector: "lab-header",
    styleUrls: ['./lab-header.css'],
    encapsulation: ViewEncapsulation.None
})
export class LabHeader implements OnInit, OnDestroy, OnChanges {
    page: Page;
    loading: boolean;
    patient: PatientProfile;
    patId: any;

    @Input() commonid: any;
    @Input() isFromCallCentre = false;
    relativeMobile: string;
    yearsfordisplay: number;
    monthsfordisplay: number;
    daysfordisplay: number;
    cardDetails: Array<HealthCard>;
    isCardSelected = false;
    isSettingHealthCard: boolean;
    loadingSettings: boolean;
    selectedCard: HealthCard;
    myDate: any;
    validCardDetails: Array<HealthCard>;
    routingValue: string;
    admissions: Admission[];
    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly appointmentRefresh: AppointmentRefreshService,
        private readonly datePipe: DatePipe,
        private readonly notifyService: NotifyService,
    ) {
        this.page = new Page();
        this.patient = new PatientProfile();
        this.validCardDetails = new Array<HealthCard>();
        this.cardDetails = new Array<HealthCard>();
        this.myDate = this.datePipe.transform(new Date().setHours(0, 0), 'yyyy-MM-dd');
    }

    safe = (url: any) => {
        return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`;
    }

    onSelectCard(item: HealthCard) {
        this.isCardSelected = true;
        this.selectedCard = item;
        var singleItem = this.cardDetails.find(x => x.healthCardId == item.healthCardId);
        if (singleItem != null) {
            this.cardDetails[0]
            this.cardDetails.forEach(x => (!x.healthCardId || x.healthCardId === singleItem.healthCardId) ? singleItem : x.healthCardId);
        }
        this.cardDetails.forEach(item => {
            item.isSelected = false;
        });
        const foundElementIndex = this.cardDetails.findIndex(element => element.healthCardId === singleItem.healthCardId);

        singleItem.isSelected = true;
        this.cardDetails[foundElementIndex] = singleItem;
        this.appointmentRefresh.onClickHealthCard(item);

    }


    private getSettingsHealthCardData() {
        this.loadingSettings = true;
        this.httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), { name: "IsHealthCardShow" })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loadingSettings = false))
            .subscribe((response: Array<Setting>) => {
                if (response.length > 0) {
                    this.isSettingHealthCard = response[0].active;
                }
            });
    }
    private findPatient(id: string) {
        this.page.loading = true;
        this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profileCommonWidget), { encryptedPatientId: id })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe({
                next: (response: PatientProfile) => {
                    this.patient = response;
                    this.fetchAdmissions(this.patient.patient.patientId);
                    this.relativeMobile = this.patient.relativeDetails[0] == null ? 'unavailable' : response.relativeDetails[0].contactNo;
                    const dateOfBirth = DateHelper.toNgbDateStruct(String(this.patient.patient.dateOfBirth));
                    if (dateOfBirth != null) {
                        let mdob = moment(String(this.patient.patient.dateOfBirth), "YYYY-MM-DD");
                        let age = moment().diff(mdob, "months");
                        let yrs = (age / 12).toFixed(1);
                        this.yearsfordisplay = parseInt(yrs, 10);
                        let months = age % 12;
                        let days = moment().diff(mdob.add(yrs, 'years'), 'days', false);
                        this.yearsfordisplay = days;
                        this.yearsfordisplay = parseInt(yrs);
                        this.monthsfordisplay = months;
                        let date1 = moment(String(this.patient.patient.dateOfBirth), "YYYY-MM-DD");
                        let monthsa = moment().diff(date1, 'months');
                        date1.add(monthsa, 'months');
                        let daysa = moment().diff(date1, 'days');
                        monthsa + ' ' + 'Month(s)' + ' ' + daysa + " Day(s)"
                        this.daysfordisplay = daysa;
                        this.patient.patient.age = this.patient.patient.dateOfBirth ? this.yearsfordisplay + 'Y ' + this.monthsfordisplay + 'M ' + this.daysfordisplay + 'D' : null;
                    }
                    this.findHealthCard(this.patient.patient.patientId);
                },
                error: (error: any) => {
                    this.notifyService.warningToast(error.error);
                }
            });
    }

    private findHealthCard(patientId: number) {
        this.httpService.post<Array<HealthCard>>(ApiResources.getURI(ApiResources.healthCard.base, ApiResources.healthCard.fetchCardId), { patientId: patientId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Array<HealthCard>) => {
                this.cardDetails = response;
                this.validCardDetails = [];

                if (this.cardDetails && this.cardDetails.length > 0) {
                    this.cardDetails.forEach(item => {
                        if (item.validTill >= this.myDate) {
                            this.validCardDetails.push(item);
                        }
                    })
                }
                if (this.validCardDetails && this.validCardDetails.length == 1) {
                    this.validCardDetails[0].isSelected = true;
                    this.isCardSelected = true;
                    this.appointmentRefresh.onClickHealthCard(this.validCardDetails[0]);
                }
            });
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.routingValue = url.split("/")[url.split("/").length - 1];
                    this.route.params
                        .pipe(takeUntil(this.page.unSubscribe))
                        .subscribe(() => {
                            //const patientId = params["id"];

                        });
                    this.findPatient(this.commonid);
                    this.getSettingsHealthCardData();
                    //  this.fetchPatients(id);//
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }

    onViewPatientProfile(commonId: string) {
        this.router.navigateByUrl(`app/patient/${commonId}`);
    }

    ngOnChanges() {
        this.findPatient(this.commonid);
    }
    private fetchAdmissions(patientId: number) {
        const request = {};
        request["patientId"] = patientId;
        this.httpService
            .post<Array<Admission>>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<Admission>) => {
                    this.admissions = response;
                });
    }
}