<div class="content">
    <div class="container-fluid">
        <div class="page-title-box">
            <div class="page-title-right" *menuButton="'masters_package_modules_add_package'">
                <a role="button" class="btn btn-primary btn-sm" href="javascript:;" (click)="onAddPackage()">
                    <i class="fe-plus mr-1"></i> Add Package
                </a>
                <button class="btn btn-secondary btn-sm ml-1" type="button" (click)="filters.hidden = !filters.hidden"><i class="fe-filter mr-1"></i> {{filters.hidden ? 'Show' : 'Hide'}} Filters</button>
            </div>
            <h4 class="page-title">Packages</h4>
        </div>
        <div class="card-box filters-box" *ngIf="!filters.hidden">
            <h4 class="filters-title text-primary font-weight-bold"><i class="fe-filter mr-1"></i>Filters</h4>
            <div class="row row-sm p-1">
                <div class="col-3 col">
                    <div class="form-group font-13 mb-0">
                        <label class="mb-0 font-weight-semibold">Package Name</label>
                        <input type="text" class="form-control" maxlength="100" block [(ngModel)]="filters.options.packageName" placeholder="Enter package name" autocomplete="off" />
                    </div>
                </div>
                <div class="col-2 col">
                    <div class="form-group mb-0">
                        <label class="mb-0 font-13 font-weight-semibold">Package Type</label>
                        <ng-select placeholder="Select package type" [(ngModel)]="filters.options.packageType" [items]="packageTypes" bindLabel="name" bindValue="value">
                            <ng-template ng-option-tmp let-item="item">
                                <span class="font-13" [textContent]="item.name"></span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <span class="font-13" [textContent]="item.name"></span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="col-2 col">
                    <div class="form-group mb-0">
                        <label class="mb-0 font-13 font-weight-semibold">Pay Type</label>
                        <ng-select placeholder="Select pay type" [(ngModel)]="filters.options.payType" [items]="payTypes" bindLabel="name" bindValue="value">
                            <ng-template ng-option-tmp let-item="item">
                                <span class="font-13" [textContent]="item.name"></span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <span class="font-13" [textContent]="item.name"></span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="col-3 col">
                    <div class="form-group mb-0">
                        <label class="mb-0 font-13 font-weight-semibold">Location</label>
                        <ng-select [items]="locations" [loading]="loadingLocations" [clearable]="true" bindLabel="name" bindValue="id" placeholder="Select location" [(ngModel)]="filters.options.locationId">
                            <ng-template ng-option-tmp let-item="item">
                                <span class="font-13" [textContent]="item.name"></span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <span class="font-13" [textContent]="item.name"></span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="col-2">
                    <div class="d-flex form-group mb-0 lessThan1468">
                        <button type="button" (click)="onApplyFilters()" class="btn btn-sm btn-primary rounded-pill">
                            <i class="mdi mdi-magnify mr-1"></i>Search
                        </button>
                        <button type="button" (click)="onResetFilters()" class="btn btn-sm reset-btn rounded-pill ml-1">
                            <i class="mdi mdi-backup-restore mr-1"></i>Reset
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center" *ngIf="page.loading">
            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
            <span class="ml-2">Please wait while loading Packages ...</span>
        </div>
        <div class="mh-300 position-relative" *ngIf="!page.loading && !packages?.length">
            <no-data [applied]="filters.applied" [title]="'Packages'"></no-data>
        </div>
        <div class="card mb-0" *ngIf="!page.loading && packages?.length">
            <div class="card-body p-0">
                <div class="overflow-auto">
                    <table class="table table-centered table-sm table-striped table-bordered mb-0">
                        <thead>
                            <tr>
                                <th>Package</th>
                                <th>Location</th>
                                <th>Doctor</th>
                                <th class="text-right">Quantity</th>
                                <th class="text-right">Discount</th>
                                <th class="text-right">Expires In</th>
                                <th>Status</th>
                                <th class="text-right" style="width: 100px;">Actions</th>
                            </tr>
                        </thead>
                        <tbody class="font-13">
                            <tr *ngFor="let item of packages; let i = index; trackBy: trackBy.package" [class.selected]="selectedPackage?.packageModuleId === item.packageModuleId">
                                <td>
                                    <p class="title mb-0 font-weight-semibold text-capitalize" [textContent]="item.packageName"></p>
                                    <small class="text-mute">
                                        <span [textContent]="(item.payType | payType) + ' / '"></span>
                                        <span [textContent]="item.packageType | packageType"></span>
                                    </small>
                                </td>
                                <td>
                                    <p class="mb-0 text-capitalize" [textContent]="item.locationName"></p>
                                    <small class="text-mute" [textContent]="item.templateName"></small>
                                </td>
                                <td>
                                    <ng-container *ngIf="item.providerName">
                                        <p class="mb-0 text-capitalize" [textContent]="item.providerName"></p>
                                        <small class="text-mute" [textContent]="item.departmentName"></small>
                                    </ng-container>
                                    <span *ngIf="!item.providerName">-</span>
                                </td>
                                <td class="text-right" [textContent]="item.quantity"></td>
                                <td class="text-right">
                                    <ng-container *ngIf="item.discountType">
                                        <span *ngIf="item.discountPercentage" [textContent]="item.discountPercentage + '%'"></span>
                                        <span *ngIf="item.discountAmount" [textContent]="item.discountAmount | currency :'₹ ':'symbol':'1.2-2'"></span>
                                    </ng-container>
                                    <span *ngIf="!item.discountType">-</span>
                                </td>
                                <td class="text-right">
                                    <ng-container *ngIf="item.expiresIn">
                                        <span [textContent]="item.expiresIn"></span>
                                        <small class="ml-1 font-11 text-mute" [textContent]="'days'"></small>
                                    </ng-container>
                                    <span *ngIf="!item.expiresIn">-</span>
                                </td>
                                <td>
                                    <ng-template #modifiedBy>
                                        <label class="font-13 font-weight-bold">
                                            <span *ngIf="item.active">Last Modified By</span>
                                            <span *ngIf="!item.active">Disabled By</span>
                                        </label>
                                        <div class="d-block">
                                            <p class="mb-0 text-capitalize font-weight-semibold font-13 text-dark" [textContent]="item.modifiedByName || item.createdByName"></p>
                                            <small class="font-11 text-mute" [textContent]="(item.modifiedDate || item.createdDate) | date: 'dd-MM-yyyy, hh:mm a'"></small>
                                        </div>
                                    </ng-template>
                                    <span class="badge badge-success" *ngIf="item.active && !item.isInUse" placement="left" [ngbPopover]="modifiedBy" triggers="mouseenter:mouseleave">Active</span>
                                    <span class="badge badge-danger" *ngIf="!item.active && !item.isInUse" placement="left" [ngbPopover]="modifiedBy" triggers="mouseenter:mouseleave">Disabled</span>
                                    <span class="badge badge-primary" *ngIf="item.isInUse" placement="left" [ngbPopover]="modifiedBy" triggers="mouseenter:mouseleave">Is In Use</span>
                                </td>
                                <td>
                                    <div class="d-flex align-items-center justify-content-end">
                                        <a href="javascript:;" *menuButton="'masters_package_modules_uplode_document'" class="action-icon text-info" (click)="onOpenDocumentModal(templateUploadDocument, item)" placement="left" ngbTooltip="Upload Document"><i class="fe-arrow-up"></i></a>
                                        <div *menuButton="'masters_package_module_view_document'">
                                            <a href="javascript:;" *ngIf="item.packageDocumentId >0" class="action-icon text-info" (click)="onOpenViewDocumentModal(templateViewDocument, item)" placement="left" ngbTooltip="View Document"><i class="fe-download"></i></a>
                                        </div>
                                        <a href="javascript:;" *menuButton="'masters_package_module_view_package'" (click)="onOpenModal(templateViewPackage, 0, item)" class="action-icon text-info" placement="left" ngbTooltip="View Package"><i class="fe-eye"></i></a>
                                        <div *menuButton="'masters_package_module_edit_package'">
                                            <a href="javascript:;" *ngIf="item.active && !item.isInUse" (click)="onEditPackage(item.encryptedPackageModuleId)" class="action-icon text-primary ml-1" placement="left" ngbTooltip="Edit Package"><i class="mdi mdi-pencil"></i></a>
                                        </div>
                                        <div *menuButton="'masters_package_module_copy_package'">
                                            <a href="javascript:;" *ngIf="item.active" (click)="onCopyPackage(item.encryptedPackageModuleId)" class="action-icon text-primary ml-1" placement="left" ngbTooltip="Copy Package"><i class="mdi mdi-content-copy"></i></a>
                                        </div>
                                        <div *menuButton="'masters_package_module_enable'">
                                            <a href="javascript:;" *ngIf="item.active && !item.isInUse" class="action-icon text-danger ml-1" placement="left" ngbTooltip="Disable" (click)="onModifyStatus(item, false)">
                                                <i *ngIf="item.isModifying" class="mdi mdi-spin mdi-loading"></i>
                                                <i *ngIf="!item.isModifying" class="fe-x-circle"></i>
                                            </a>
                                            <a href="javascript:;" *ngIf="!item.active && !item.isInUse" class="action-icon text-success ml-1" placement="left" ngbTooltip="Enable" (click)="onModifyStatus(item, true)">
                                                <i *ngIf="item.isModifying" class="mdi mdi-spin mdi-loading"></i>
                                                <i *ngIf="!item.isModifying" class="fe-check-circle"></i>
                                            </a>
                                        </div>
                                        <div *menuButton="'masters_package_module_delete'">
                                            <a href="javascript:;" *ngIf="!item.isInUse" class="action-icon text-warning ml-1" placement="left" ngbTooltip="Delete" (click)="onDelete(item)">
                                                <i *ngIf="item.isDeleting" class="mdi mdi-spin mdi-loading"></i>
                                                <i *ngIf="!item.isDeleting" class="fe-trash-2"></i>
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="pagination.totalPages > 1">
                            <tr>
                                <td colspan="8">
                                    <nav class="d-flex align-items-center justify-content-between">
                                        <p class="mb-0 font-13">
                                            <span class="text-dark">Page <span [textContent]="pagination.currentPage"></span> of <span [textContent]="pagination.totalPages"></span></span>
                                            <span class="ml-1">
                                                <span>(Showing <span [textContent]="pagination.currentItems - packages.length + 1"></span> - <span [textContent]="pagination.currentItems"></span> of <span [textContent]="pagination.totalItems"></span> Packages)</span>
                                            </span>
                                        </p>
                                        <ngb-pagination class="pagination justify-content-end" [maxSize]="5" [rotate]="true" [(page)]="pagination.pageIndex" [pageSize]="pagination.pageSize" (pageChange)="onNextPage()" [collectionSize]="pagination.totalItems">
                                            <ng-template ngbPaginationPrevious><i class="fe-arrow-left"></i></ng-template>
                                            <ng-template ngbPaginationNext><i class="fe-arrow-right"></i></ng-template>
                                        </ngb-pagination>
                                    </nav>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #templateViewPackage>
    <div class="modal-header d-block">
        <div class="d-flex align-items-center justify-content-between">
            <h4 class="modal-title">
                <i class="mdi mdi-package-variant mr-1"></i>View Package
            </h4>
            <div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
            </div>
        </div>
    </div>
    <div class="modal-body p-0" style="min-height: 400px">
        <view-package-module *ngIf="selectedPackage && selectedPackage.packageModuleId" [packageModuleId]="selectedPackage.packageModuleId"></view-package-module>
    </div>
</ng-template>


<ng-template #templateUploadDocument>
    <form [formGroup]="uploadDocumentForm" (ngSubmit)="onSubmitDocument()">
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-file-multiple mr-1"></i>Upload Documents</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row appointment-form bg-pattern bg-light mb-3">
                <div class="col-md-4">
                    <div class="form-group mb-0">
                        <label class="mb-1">Document Name <code>*</code></label>
                        <input type="text" autofocus formControlName="documentName" maxlength="100" block autocomplete="nope" class="form-control" [ngClass]="{ 'is-invalid': (submitted && form.documentName.errors) }" placeholder="Enter document name" />
                        <div *ngIf="form.documentName.errors" class="invalid-feedback show-must">
                            <p *ngIf="form.documentName.errors.whiteSpaces">Please remove white spaces.</p>
                        </div>
                    </div>
                </div>
            </div>
            <file-dropper [expandable]="true" [maxFiles]="maxFiles" [maxFileSize]="10000" [accept]="'application/pdf, image/jpeg, image/pjpeg, image/png, image/gif, video/mp4'" (onSelectEmitter)="onFileSelect($event)">
            </file-dropper>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default btn-sm" (click)="onCloseModal();">Cancel</button>
            <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm ml-1">
                <span *ngIf="submitting">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Please wait..
                </span>
                <span *ngIf="!submitting">Submit</span>
            </button>
        </div>
    </form>
</ng-template>


<ng-template #templateViewDocument>
    <ng-container *ngIf="document">
        <div class="modal-header">
            <h4 class="modal-title"><span [textContent]="document.documentName">{{document | json}}</span><small class="ml-2"></small></h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <a href="javascript:;" (click)="onPrevious()" class="previous-document" placement="left" ngbTooltip="Previous document" *ngIf="showPrevious"><i class="mdi mdi-arrow-left-bold"></i></a>
            <a href="javascript:;" (click)="onNext()" class="next-document" placement="left" ngbTooltip="Next document" *ngIf="showNext"><i class="mdi mdi-arrow-right-bold"></i></a>
            <div class="p-3" *ngIf="loadingDocument">
                <div class="d-flex align-items-center">
                    <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                    <span class="ml-3">Please wait while loading Document ...</span>
                </div>
            </div>
            <div *ngIf="!loadingDocument && documentError">
                <div class="position-relative mh-300">
                    <no-data title="Document"></no-data>
                </div>
            </div>
            <ng-container *ngIf="document && document.isImage">
                <img [src]="document.formedUrl" [hidden]="loadingDocument || documentError" (load)="onDocumentLoad()" (error)="onDocumentError()" [alt]="document.documentName" />
            </ng-container>
            <ng-container *ngIf="document && document.isVideo">
                <video style="width: 100%; height: 100%" controls (click)="toggleVideo()" #videoPlayer autoplay>
                    <source [src]="document.formedUrl" (load)="onDocumentLoad()" [type]="document.contentType">
                </video>
            </ng-container>
            <ng-container *ngIf="document && !document.isImage && !document.isVideo">
                <pdf-viewer [src]="document.formedUrl['changingThisBreaksApplicationSecurity']" [render-text]="true" [autoresize]="true" [original-size]="false" [fit-to-page]="false" (on-progress)="onProgress($event)" (error)="onDocumentError()" style="width: 100%; height: 500px">
                </pdf-viewer>
            </ng-container>
        </div>
    </ng-container>
</ng-template>