export class PediatricAgeWiseZScoreDataModel {
    pediatricAgeWiseDataId: number;
    pediatricChartAuthorityId: number | null;
    pediatricChartTypeId: number | null;
    gender: string;
    ageInMonth: number;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number | null;
    modifiedDate: Date | null;
    active: boolean | null;
    authorityName: string;
    typeName: string;
    l: number | null;
    m: number | null;
    sd: number | null;
    s: number | null;
    minus3SD: number | null;
    minus2SD: number | null;
    minus1SD: number | null;
    median: number | null;
    oneSD: number | null;
    twoSD: number | null;
    threeSD: number | null;
}