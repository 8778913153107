export * from "./lab-parameter-group.model";
export * from "./lab-parameter-header.model";
export * from "./lab-component-header.model";
export * from "./lab-component-detail.model";
export * from "./lab-template-header.model";
export * from "./lab-template-detail.model";
export * from "./lab-parameter-method.model";
export * from "./lab-detail-model";
export * from "./lab-booking-model";
export * from "./lab-sample-handler-model";
export * from "./lab-transfer-model";
export * from "./lab-receive-model";
export * from "./lab-parameter-input-model";
export * from "./lab-status-model";
export * from "./lab-past-reports-view.model";
export * from "./lab-tech-model";
export * from "./external-lab-angency-model";
export * from "./lab-sample-handler-newmodel";
export * from "./external-lab-transfer-model";
export * from "./antibiotic-result-receipt";