export class EmergencyEncounter {
    emergencyEncounterId?: number;
    encryptedPatientId: string;
    appointmentId: number;
    encounterDate: Date;
    active: boolean;
    createdBy: number;
    createdDate: Date
    modifiedBy: number;
    modifiedDate: Date;
    practiceLocation: string;
    practiceName: string;
    specializationName: string;
    providerNo: string;
    educations: string;
    patientName: string;
    patientAge?: number;
    patientDateOfBirth?: Date;
    patientGender: string;
    patientMobile: string;
    patientThumbnailUrl: string;
    patientCountryCode: string;
    appointmentSymptoms: string;
    providerName: string;
    signature: string;
    appointmentTimeString: string;
    appointmentDate: string;
    appointmentNo: number;
    patientId: number;
    partner: string;
    friendlyName: string;
    email: string;
    patientNo: string;
    providerEmail: string;
    umrNo: string;
    emergencyTriage: string;
    nursingERForm: string;
    appointmentTime: string;
    encryptedAppointmentId: string;
    encounterName: string;
    gyneEncounterClosed: boolean;
    obEncounterClosed: boolean;
    dietEncounterClosed: boolean;
    peadatricEncounterClosed: boolean;
    geneticEncounterClosed: boolean;
    physiothrapyEncounterClosed: boolean;
    isEncouterClosed: boolean;
} 