        <!-- Appointment Invoice -->
<div *ngIf="!isCancelInvoice" Id="invoiceId2">
    <div class="modal-body report">
        <div class="table-responsive-lg table-responsive-sm table-responsive-md text-black">
            <table class="border-dark" width="894px" border="0" cellspacing="0" cellpadding="0" style="margin: 0px auto;
            border: 1px solid #333; border-bottom: 1px solid #333; border-collapse: unset;width: 100%">
                <tbody>
                    <!--<tr style="background: #f3f3f3;">
                <td colspan="2" *ngIf="isPrintLogo">-->
                    <!--<img src="assets/images/header.jpg" alt="" style="width: 100%">-->
                    <!--<img [src]="bannerBasics != null ? bannerBasics.imagePath : myImgUrl" [hidden]="loading" alt="Careaxes" style="width: 100%" />
                </td>
            </tr>-->
                    <tr style="background: #f3f3f3;" *ngIf="isPrintLogo">
                        <td colspan="2">
                            <banner-setting [reportName]='"Appointment Invoive"'> </banner-setting>
                        </td>
                    </tr>

                    <tr>
                        <td colspan="2">
                            <h4 style="font-family: 'Open Sans'; font-size: 15px; color: #06C; margin: 0px; padding: 5px; text-align: center; border-top: 1px solid #000; font-weight: bolder">
                                {{ selectedAppointment.paymentType === 'P' ? 'RECEIPT' : 'INVOICE' }}
                            </h4>
                        </td>
                    </tr>

                    <tr>
                        <td colspan="2">
                            <table class="border-dark" width="100%" border="0" cellspacing="0" cellpadding="5" style="border-top: 1px solid #333;
                        border-bottom: 1px solid #333; font-family: 'Open Sans'; font-size: 14px;">
                                <tbody>
                                    <tr>
                                        <td style="font-weight: bold">
                                            Patient Name
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td class="text-uppercase">
                                            {{selectedAppointment.patientName}}
                                        </td>
                                        <td style="width: 10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold">
                                            Father/Husband Name
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {{selectedAppointment.fatherOrHusband}}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold">
                                            Age / Gender
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            <!--{{selectedAppointment.patientAge}} (Yrs) / {{selectedAppointment.patientGender | gender}}-->
                                            {{selectedAppointment.patientDateOfBirth ? (selectedAppointment.patientDateOfBirth | age:'days' ): selectedAppointment.patientAge ? (selectedAppointment.patientAge +' yrs '): 'N/A'}}  / {{selectedAppointment.patientGender | gender}}
                                        </td>

                                        <td style="width: 10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold">
                                            UMR No
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {{selectedAppointment.umrNo}}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold">
                                            Mobile No
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {{selectedAppointment.patientMobile}}
                                        </td>

                                        <td style="width: 10%">
                                            &nbsp;
                                        </td>
                                        <td *ngIf="selectedAppointment.fullAddress" style="font-weight: bold">
                                            Address
                                        </td>
                                        <td *ngIf="selectedAppointment.fullAddress">
                                            :
                                        </td>
                                        <td *ngIf="selectedAppointment.fullAddress">
                                            {{selectedAppointment.fullAddress}}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td *ngIf="selectedAppointment.idProofName" style="font-weight: bold">
                                            {{selectedAppointment.idProofName}}
                                        </td>
                                        <td *ngIf="selectedAppointment.idProofName">
                                            :
                                        </td>
                                        <td *ngIf="selectedAppointment.idProofName">
                                            {{selectedAppointment.idProofValue}}
                                        </td>

                                        <td *ngIf="selectedAppointment.idProofName" style="width: 10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold">
                                            Referred By
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            {{selectedAppointment.referredByName || 'Self'}}
                                        </td>


                                    </tr>
                                    <!--<tr>
                                <td style="font-weight: bold" *ngIf="selectedAppointment.referredBy !== null && selectedAppointment.referredBy !== 'Self'">
                                    Referred {{selectedAppointment.referredBy}} Name
                                </td>
                                <td *ngIf="selectedAppointment.referredBy !== null && selectedAppointment.referredBy !== 'Self'">
                                    :
                                </td>
                                <td *ngIf="selectedAppointment.referredBy !== null && selectedAppointment.referredBy !== 'Self'">
                                    {{selectedAppointment.referredByName}}
                                </td>
                            </tr>-->
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <table class="border-dark" border="0" cellpadding="5" cellspacing="0" style="border-bottom: 1px solid #333;font-family: 'Open Sans';font-size: 16px;font-weight: bolder;" width="100%">

                                <!--<tbody><tr><td style="text-align: center;padding: 0px;font-size: 14px;text-transform: uppercase;">{{(selectedAppointment.followUpForAppointmentId || selectedAppointment.followUpForAdmissionId) ? 'Follow up': 'New'}} Appointment</td></tr></tbody>-->
                                <tbody><tr><td style="text-align: center;padding: 0px;font-size: 14px;text-transform: uppercase;">{{selectedAppointment.appointmentTypeName}}</td></tr></tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="8">
                            <table class="border-dark" width="100%" cellpadding="5" style="font-family: 'Open sans'; font-size: 14px;
                        border-collapse: collapse;">
                                <tbody>
                                    <tr style="border-bottom: 1px solid">
                                        <td style="font-weight: bold; width: 13%">
                                            Appointment No
                                        </td>
                                        <td style="font-weight: bold">
                                            :
                                        </td>
                                        <td style="font-weight: bold">
                                            {{selectedAppointment.appointmentNo}}
                                        </td>
                                        <td width="10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold; width: 13%">
                                            Appointment Date
                                        </td>
                                        <td style="font-weight: bold">
                                            :
                                        </td>
                                        <td style="font-weight: bold">
                                            {{selectedAppointment.appointmentDate | amDateFormat:'DD-MMM-YYYY'}} {{selectedAppointment.appointmentTimeString}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold; vertical-align: top">
                                            Doctor Name
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            :
                                        </td>
                                        <td style="font-weight: bold">

                                            <span class="text-uppercase" [textContent]="'Dr. '+ selectedAppointment.providerName"></span>&nbsp;
                                            <span [textContent]="' ('+ selectedAppointment.specializationName +')'"></span>
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            Department
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            :
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            {{selectedAppointment.departmentName}}
                                        </td>
                                    </tr>
                                    <tr>                                   
                                        <td *ngIf="duplicateInvoiceId" class="justify-content-center d-flex">     
                                            <div id="backgroundOfwaterMark" class="waterMarkRotate"> 
                                            <span class="text-danger font">Duplicate Invoice</span></div>
                                        </td>
                                    </tr>
                                    <!--<tr>
                            <td style="font-weight: bold">
                                Type
                            </td>
                            <td>
                                :
                            </td>
                            <td>-->
                                    <!--<span class="d-block font-13" *ngIf="selectedAppointment.visitType === 'T'">Telemedicine</span>
                            <span class="d-block font-13" *ngIf="selectedAppointment.visitType === 'P'">Personal Visit</span>-->
                                    <!--<span class="d-block font-13" *ngIf="selectedAppointment.visitType === 'O'">Out Patient</span>
                                <span class="d-block font-13" *ngIf="selectedAppointment.visitType === 'C'">Casualty</span>
                            </td>
                            <td style="width: 10%">
                                &nbsp;
                            </td>-->
                                    <!--<td style="font-weight: bold">
                                Reason
                            </td>
                            <td>
                                :
                            </td>
                            <td *ngIf="selectedAppointment.status === 'C'" [textContent]="selectedAppointment.reason"></td>-->
                                    <!--</tr>-->
                                    <tr>
                                        <td style="font-weight: bold">
                                            Payment Modes

                                        </td>
                                        <td>
                                            <!--:-->
                                        </td>
                                        <td style="font-weight: bold">
                                            <!--<div *ngIf="selectedAppointment.isHealthCard && selectedAppointment.isHealthCard===true">
                                        <span [textContent]="selectedAppointment.isHealthCard ? 'HC' : '--'"></span><br />
                                    </div>
                                    <div *ngIf="!selectedAppointment.isHealthCard">
                                        <span [textContent]="selectedAppointment.payTypeName || '--'"></span><br />
                                        <span *ngIf="selectedAppointment.payTypeId > 1" [textContent]="selectedAppointment.paymentNumber"></span>
                                    </div>-->

                                        </td>
                                        <td style="width: 10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold">
                                            Status
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <td>
                                            <span *ngIf="selectedAppointment.status === 'B'">Booked</span>
                                            <span *ngIf="selectedAppointment.status === 'R'">Rescheduled</span>
                                            <span *ngIf="selectedAppointment.status === 'C'">Cancelled</span>
                                            <span *ngIf="selectedAppointment.status === 'CP'">Closed</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td *ngIf="selectedAppointment.paymentType === 'P'" class="justify-content-center d-flex">

                                            <small id="backgroundOfwaterMark" style=" -webkit-transform: rotate(-65deg);"> <span>Partial Paid</span></small>

                                        </td>
                                    </tr>

                                    <tr *ngIf="selectedAppointment.payTypes[0]">
                                        <td style="font-weight: bold">
                                            <small class="backgroundOfwaterMark rotate30" *ngIf="selectedAppointment.paymentType === 'F'">Full Paid</small>
                                        </td>
                                    </tr>

                                    <tr *ngFor="let payType of selectedAppointment.payTypes">
                                        <!--<td style="font-weight: bold" [textContent]="payType.payTypeName">
                                            <span [textContent]="payType.paymentDetails"></span>
                                        </td>-->
                                        <td style="font-weight: bold">
                                            {{ payType.payTypeName }}
                                            <div *ngIf="payType.paymentDetails">
                                                (<span>{{ payType.paymentDetails }}</span>)
                                            </div>
                                        </td>

                                        <td style="font-weight: bold">
                                            <span>:</span>
                                        </td>
                                        <td style="font-weight: bold">

                                            <span [textContent]="payType.amountPaid  || 0 | currency: 'INR'"></span>
                                            <span>&nbsp; &nbsp;</span>
                                            <span [textContent]="payType.createdDate | date:'dd-MM-yyyy, hh:mm a'"></span>
                                        </td>

                                    </tr>


                                    <tr style="border-top: 1px solid">
                                        <!--<td style="font-weight: bold; width: 13%">
                                </td>
                                <td style="font-weight: bold">
                                </td>
                                <td style="font-weight: bold">
                                </td>-->
                                        <td style="font-weight: bold">
                                            Charge Type
                                        </td>
                                        <td>
                                            :
                                        </td>
                                        <!--<td>
                                    <span [textContent]="selectedAppointment.chargeType === 'G' ? 'General':selectedAppointment.chargeType === 'C'?'Covid':selectedAppointment.chargeType === 'S'?'Special':'General' "></span><br />
                                </td>-->
                                        <td>
                                            {{selectedAppointment.chargeTypesName}}
                                        </td>
                                        <td width="10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top" *ngIf="selectedAppointment.referenceId != null">
                                            Patient Registration Charges
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top" *ngIf="selectedAppointment.referenceId != null">
                                            :
                                        </td>
                                        <td style="font-weight: bold" *ngIf="selectedAppointment.referenceId != null">
                                            <span *ngIf="selectedAppointment.patientPaymentStatus" [textContent]="selectedAppointment.patAmount | currency: 'INR'"></span>
                                            <span *ngIf="selectedAppointment.patientPaymentStatus!=true" [textContent]="selectedAppointment.patientPaymentStatus== false? 'Not Paid' : 'No Registration Charges' "></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold; width: 13%">
                                            Consultation Fee
                                        </td>
                                        <td style="font-weight: bold">
                                            :
                                        </td>
                                        <td style="font-weight: bold">
                                            <span [textContent]="selectedAppointment.aptAmount || 0 | currency: 'INR'"></span>
                                        </td>
                                        <td width="10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold; width: 13%">
                                            Discount
                                        </td>
                                        <td style="font-weight: bold">
                                            :
                                        </td>
                                        <td style="font-weight: bold">
                                            <span *ngIf="selectedAppointment.referenceId != null" [textContent]="selectedAppointment.aptDiscount+selectedAppointment.patDiscount | currency: 'INR'"></span>
                                            <span *ngIf="selectedAppointment.referenceId == null" [textContent]="selectedAppointment.aptDiscount | currency: 'INR'"></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold; vertical-align: top">
                                            Gross Total
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            :
                                        </td>
                                        <td style="font-weight: bold">
                                            <div *ngIf="selectedAppointment.referenceId != null">
                                                <span [textContent]="selectedAppointment.aptAmount + selectedAppointment.patAmount | currency: 'INR'"></span>
                                            </div>
                                            <div *ngIf="selectedAppointment.referenceId == null">
                                                <span [textContent]="selectedAppointment.aptAmount | currency: 'INR'"></span>
                                            </div>
                                        </td>
                                        <td width="10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            {{ selectedAppointment.paymentType === 'P' ? 'Paid Amount' : 'Net Total' }}
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            :
                                        </td>
                                        <td style="font-weight: bold">
                                            <div *ngIf="selectedAppointment.referenceId != null && selectedAppointment.discountInPercentage==0 ">
                                                <span [textContent]="selectedAppointment.aptTotal+selectedAppointment.patAmount | currency: 'INR'"></span>
                                            </div>
                                            <div *ngIf="selectedAppointment.referenceId != null && selectedAppointment.discountInPercentage >0">
                                                <span [textContent]="selectedAppointment.aptTotal+selectedAppointment.patTotal | currency: 'INR'"></span>
                                            </div>

                                            <div *ngIf="selectedAppointment.referenceId == null">
                                                <span [textContent]="selectedAppointment.aptTotal | currency: 'INR'"></span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="selectedAppointment.paymentType === 'P'">
                                        <td style="font-weight: bold; vertical-align: top">
                                            Net Total
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            :
                                        </td>
                                        <td style="font-weight: bold">
                                            <div *ngIf="selectedAppointment.referenceId != null">
                                                <span [textContent]="(selectedAppointment.aptAmount+selectedAppointment.patAmount)-(selectedAppointment.aptDiscount+selectedAppointment.patDiscount) | currency: 'INR'"></span>
                                            </div>
                                            <div *ngIf="selectedAppointment.referenceId == null">
                                                <span [textContent]="(selectedAppointment.aptAmount)-(selectedAppointment.aptDiscount) | currency: 'INR'"></span>
                                            </div>
                                        </td>
                                        <td width="10%">
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            Due Amount
                                        </td>
                                        <td style="font-weight: bold; vertical-align: top">
                                            :
                                        </td>
                                        <td style="font-weight: bold">
                                            <div *ngIf="selectedAppointment.referenceId != null">
                                                <span [textContent]="(selectedAppointment.aptAmount+selectedAppointment.patAmount)-(selectedAppointment.aptTotal+selectedAppointment.patTotal) | currency: 'INR'"></span>
                                            </div>
                                            <div *ngIf="selectedAppointment.referenceId == null && selectedAppointment.discountInPercentage==0">
                                                <span [textContent]="(selectedAppointment.aptAmount)-(selectedAppointment.aptTotal) | currency: 'INR'"></span>
                                            </div>
                                            <div *ngIf="selectedAppointment.referenceId == null && selectedAppointment.discountInPercentage>0">
                                                <span [textContent]="(selectedAppointment.aptAmount)-(selectedAppointment.aptTotal)-(selectedAppointment.discount) | currency: 'INR'"></span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold; width: 19%">
                                            For Appointment Contact
                                        </td>
                                        <td style="font-weight: bold">
                                            :
                                        </td>
                                        <td>
                                            _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td style="font-weight: bold; width: 19%">
                                            For Emergency Contact
                                        </td>
                                        <td style="font-weight: bold">
                                            :
                                        </td>
                                        <td width="20%">
                                            _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
                                        </td>
                                    </tr>
                                    <tr style="border-bottom: 1px solid #000; border-top: 1px solid #000;">
                                        <td colspan="8">
                                            <strong>Note:</strong> If there are any side effects with the medicine, stop the
                                            medicine and report immediately to the hospital.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="8" style="text-align: center">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="11" style="font-family: 'Open Sans'; font-size: 12px; color: #000;">
                            <div class="d-flex justify-content-between flex-wrap column-gap10px">
                                <table class="border-dark" width="100%">
                                    <tbody>
                                        <tr>
                                            <td style="text-align: left; font-size: 12px;">
                                                Suggest Clinical Correlation * If neccessary, Please discuss<br>
                                                Verified By : <br>
                                                Test results related only to the item tested<br>
                                                No part of the report can be reproduced without written permission of the laboratory.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="column-gap10px d-flex flex-grow-1 justify-content-between">
                                    <div>
                                        <ngx-barcode [bc-value]="selectedAppointment.umrNo" [bc-height]="30" [bc-width]="2"></ngx-barcode>
                                    </div>
                                    <div>
                                        <span [textContent]="'Printed By : ' +page.userAccount.fullName  "></span> &nbsp;&nbsp;
                                        <span> Printed Date </span> <span [textContent]="currentDate | date :'dd/MM/yyyy, h:mm a'"></span>
                                    </div>
                                </div>

                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="isFooter">
                <banner-setting [reportName]='"Appointment Invoice Footer"'> </banner-setting>
            </div>
        </div>
    </div>
</div>

<!--Cancel/Appointment Invoice-->
<div *ngIf="isCancelInvoice" class="modal-body" id="receipt-section">
    <div class="card">
        <div class="card-body p-0">
            <div class="card-box m-0">
                <div *ngIf="isPrintLogo" class="col-12" width="100%">
                    <img [src]="bannerBasics != null ? bannerBasics.imagePath : myImgUrl" [hidden]="loading" alt="Careaxes" style="width: 100%" />
                </div>
                <div>
                    <div colspan="4" align="center" *ngIf="isPrintLogo">
                        <banner-setting [reportName]='"Cancel Appointments Report Header"'></banner-setting>
                    </div>
                </div>

                <div class="col-lg-12">
                    <h4 class="box-title mt-3 " style="text-align:center;">CANCEL RECEIPT</h4>
                </div>
                <ng-container *ngIf="noReceipt">
                    <div class="row">
                        <span>Oops!!! </span>&nbsp;
                        <span>Sorry We are Unable to Show you the Cancel Appointment Receipt, As Receipt not generated, because of the following reasons..</span>
                        <ul>
                            <li>There is <b>no Appointment Charges</b>, Charged from you. <b>Or</b> Appointment charges is Zero</li>
                            <li>You Did not Paid any appointment charge.(Amount is still <b>Pending</b>)</li>
                            <li>The Appointment Amount is <b>Not Refunded</b> to You Back after Cancellation.</li>
                            <li>Might, Cancelled the Appointment for <b>Follow ups</b>.</li>
                        </ul>
                    </div>
                </ng-container>
                <ng-container *ngIf="selectedAppointment">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="table-responsive table-responsive-lg">
                                <table class="border-dark" class="table table-sm table-borderless table-centered">
                                    <tbody>
                                        <tr>
                                            <th>Receipt No</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="'RN-' + selectedAppointment.receiptId"></span>
                                            </td>
                                            <th>
                                                Appointment No
                                            </th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="selectedAppointment.appointmentNo"></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Receipt Date</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="selectedAppointment.receiptDate | date:'dd-MM-yyyy, hh:mm:ss a'"></span>
                                            </td>
                                            <th>
                                                Appointment Date
                                            </th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="selectedAppointment.appointmentDate | date: 'dd-MM-yyyy'"></span>
                                                <span [textContent]="' ' + selectedAppointment.appointmentTimeString"> </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Patient Name</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span>
                                                    <span [textContent]="selectedAppointment.patientName"></span>
                                                    <span [textContent]="', '+(selectedAppointment.patientGender | gender)"></span>
                                                    <!--<span [textContent]="selectedReceipt.patientAge + 'yrs'"></span>-->
                                                    <span [textContent]="selectedAppointment.patientDateOfBirth ? ', '+(selectedAppointment.patientDateOfBirth | age:'days' ): selectedAppointment.patientAge ? (', '+selectedAppointment.patientAge +' yrs '): 'N/A'"></span>
                                                </span>
                                            </td>
                                            <th>Father/Husband Name</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="selectedAppointment.fatherOrHusband"></span>
                                            </td>

                                        </tr>
                                        <tr>
                                            <th>Doctor Name</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span class="text-uppercase" [textContent]="selectedAppointment.providerName"></span>&nbsp;
                                                <span [textContent]="' ('+ selectedAppointment.specializationName +')'"></span>
                                            </td>

                                            <th>Department</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="selectedAppointment.departmentName"></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Address</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="selectedAppointment.fullAddress">--------------</span>
                                            </td>
                                            <th>UMR No</th>
                                            <td>
                                                <span class="mr-1">:</span>
                                                <span [textContent]="selectedAppointment.umrNo || '--'"></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <div class="table-responsive table-responsive-lg">
                                <table class="border-dark" class="table table-bordered table-sm">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Description</th>
                                            <th>Appointment Amount</th>
                                            <th>Cancel Refunded Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td [textContent]="selectedAppointment.payTypeName"></td>
                                            <!--<div *ngIf="selectedReceipt.receiptTypeId == receiptType.Refund">
                                    <div *ngIf="selectedReceipt.isRefunded">Refund</div>
                                </div>
                                <div *ngIf="selectedReceipt.receiptTypeId == receiptType.Cash">
                                    <div *ngIf="selectedReceipt.isAdvance">Advance Paid</div>
                                    <div *ngIf="!selectedAppointment.isAdvance">Paid</div>
                                </div>-->
                                            <td [textContent]="selectedAppointment.aptTotal | currency:'INR'"></td>
                                            <td [textContent]="selectedAppointment.aptRefundAmt | currency:'INR'"></td>
                                        </tr>
                                    </tbody>
                                    <thead>
                                        <tr>
                                            <th colspan="3" class="text-right">TOTAL</th>
                                            <th [textContent]="selectedAppointment.aptRefundAmt | currency:'INR'"></th>
                                        </tr>
                                    </thead>
                                </table>
                                <div class="mb-1">
                                    <span class="font-weight-bold">Reason :</span>
                                    <span [textContent]="selectedAppointment.reason"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-lg-6">
                            <div class="mb-1">
                                <span class="font-weight-bold">Created By: </span>
                                <span [textContent]="selectedAppointment.receiptCreatedByName"></span>
                            </div>
                            <div>
                                <span class="font-weight-bold">Created Date: </span>
                                <span [textContent]="selectedAppointment.receiptDate | date:'dd-MM-yyyy, hh:mm:ss a'"></span>
                            </div>
                            <div>
                                <span class="font-weight-bold">Printed By: </span>
                                <span [textContent]="page.userAccount.fullName"></span>
                            </div>
                            <div>
                                <span class="font-weight-bold">Printed Date: </span>
                                <span [textContent]="currentDate| date:'dd-MM-yyyy, hh:mm:ss a'"></span>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="isFooter">
                    <banner-setting [reportName]='"Cancel Appointments Report Footer"'> </banner-setting>
                </div>
            </div>
        </div>
    </div>
</div>