import { FormControl } from "@angular/forms";
import { RegExHelper } from "@shared/helpers";

export class EmailValidator {
    static isValid(control: FormControl) {
        const value = control.value;

        if (!value) {
            return null;
        }

        if (!RegExHelper.email.test(value) && !RegExHelper.emailFormat.test(value)) {
            return { 'invalidEmail': true };
        } else {
            return null;
        }
    }
}