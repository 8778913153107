export class SurgeryLinkModel {
    modulesMasterId: number;
    moduleName: string;
    moduleIcon: string;
    packageType: string;
    isChargeCategoryApplicable: boolean;
    modelValue: number;
    isPackageApplicable: boolean;
    surgeryId?: number;
    surgeryName: string;
    serviceTotal?: number;
    amount?: number;
}