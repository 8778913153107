import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { ApiResources } from "@shared/helpers";
import { IUserAccount, IAuthToken } from "@shared/models";

export const appUrls = {
    login: "/login",
    home: "/app/dashboard",
    notFound: "/not-found",
    serverError: "/server-error",
    forbidden: "/forbidden"
}

@Injectable()
export class IdentityService {
    headers: Object = { headers: { ignoreLoadingBar: "", "Auth": "False" } };
    constructor(
        private readonly http: HttpClient,
        private readonly modalService: NgbModal
    ) {
    }

    signIn(account: IUserAccount): Observable<Response> {
        return this.http.post<Response>(location.origin + location.pathname + "api/identity/sign-in", account, this.headers);
    }

    signOut(): Observable<Response> {
        if (this.modalService.hasOpenModals()) {
            this.modalService.dismissAll();
        }

        return this.http.post<Response>(location.origin + location.pathname + "api/identity/sign-out", {}, this.headers);
    }

    status(): Observable<number> {
        return this.http.post<number>(location.origin + location.pathname + "api/identity/status", {}, this.headers);
    }

    get(): Observable<IUserAccount> {
        return this.http.post<IUserAccount>(location.origin + location.pathname + "api/identity/get", {}, this.headers);
    }

    update(authToken: IAuthToken): Observable<Response> {
        return this.http.post<Response>(location.origin + location.pathname + "api/identity/update", authToken, this.headers);
    }

    refreshToken(reference: string): Observable<IAuthToken> {
        const request = { token: reference };
        const apiEndPoint = ApiResources.getURI(ApiResources.account.base, ApiResources.account.refresh);
        return this.http.put<IAuthToken>(apiEndPoint, request);
    }
}