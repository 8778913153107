<div class="auth-fluid flex-container">
    <div class="auth-fluid-right text-center"></div>
    <div class="auth-fluid-form-box">
        <div class="align-items-center d-flex h-100">
            <div class="card-body">
                <div class="auth-brand text-center text-lg-left">
                    <div class="auth-logo">
                        <a href="javascript:;" class="logo logo-dark">
                            <span class="logo-lg">
                                <img [src]="logoBasics && logoBasics.imageUrl ? logoBasics.imageUrl : 'assets/images/fernandez.png'" [hidden]="loading" alt="Careaxes" height="40" />
                            </span>
                        </a>
                        <a href="javascript:;" class="logo logo-light">
                            <span class="logo-lg">
                                <!--<img src="assets/images/fernandez.png" alt="Careaxes" width="200">-->
                                <img [src]="logoBasics && logoBasics.imageUrl ? logoBasics.imageUrl : 'assets/images/fernandez.png'" [hidden]="loading" alt="Careaxes" width="200" />
                            </span>
                        </a>
                    </div>
                </div>
                <ng-container *ngIf="successMessage">
                    <div class="d-flex align-content-center align-items-center justify-content-center flex-column">
                        <div class="icon--success mb-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72">
                                <g fill="none" stroke="#3283f6" stroke-width="2">
                                    <circle cx="36" cy="36" r="35" style="stroke-dasharray: 240px, 240px; stroke-dashoffset: 480px;"></circle>
                                    <path d="M17.417,37.778l9.93,9.909l25.444-25.393" style="stroke-dasharray: 50px, 50px; stroke-dashoffset: 0;"></path>
                                </g>
                            </svg>
                        </div>
                        <h4 class="mt-0 mb-4 font-22 text-left">Password Changed!</h4>
                        <div class="d-flex align-items-center justify-content-center flex-column">
                            <p class="mb-0 text-center">Your password has been changed successfully.</p>
                            <a role="button" href="javascript:;" routerLink="/login" class="btn btn-primary btn-sm mt-4"><i class="fe-arrow-left mr-1"></i>Go to Login</a>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!successMessage">
                    <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
                        <ng-container *ngIf="step === 1">
                            <h4 class="mt-0 font-22">Forgot your password?</h4>
                            <p class="text-muted mb-4 font-15">Don't worry! We can help to reset your password.</p>
                            <div class="alert d-flex align-items-center mb-3 alert-warning" role="alert">
                                <i class="mdi mdi-alert-circle-outline mr-2"></i> Please enter your registered mobile number or email address below to reset your password.
                            </div>
                            <div class="form-group mb-4" [ngClass]="{ 'is-invalid': submitted && (form.username.errors || form.countryId.errors) }">
                                <label class="mb-1">Email/Mobile number</label>
                                <div class="input-group mb-0">
                                    <div class="input-group-prepend" *ngIf="form.type.value && form.type.value === 'M'">
                                        <select tabindex="1" class="form-control country-control" formControlName="countryId">
                                            <option selected *ngIf="loadingCountries">...</option>
                                            <option *ngFor="let item of countries" [textContent]="'+' + item.optionalText" [ngValue]="item.id"></option>
                                        </select>
                                    </div>
                                    <input type="text" block [class.border-left-0]="form.type.value && form.type.value === 'M'" [attr.maxlength]="form.type.value && form.type.value === 'M' ? 10 : 100" tabindex="2" formControlName="username" autocomplete="nope" class="form-control" placeholder="Enter mobile number or email address" [ngClass]="{ 'is-invalid': (submitted && form.username.errors) }" />
                                </div>
                            </div>
                            <button type="submit" [disabled]="submitting" class="btn btn-primary btn-block">
                                <span *ngIf="submitting">
                                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                    Please wait..
                                </span>
                                <span *ngIf="!submitting">Submit</span>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="step === 2">
                            <h4 class="mt-0 font-22">Forgot your password?</h4>
                            <p class="text-muted mb-4 font-15">Don't worry! We can help to reset your password.</p>
                            <otp [model]="getModel()" [otp]="otp" [otpExpiresIn]="otpExpiresIn" (validateOTPEmitter)="onValidateOTP($event)"></otp>
                        </ng-container>
                        <ng-container *ngIf="step === 3">
                            <h4 class="mt-0 font-22">Reset your password</h4>
                            <p class="text-muted mb-4 font-15">Enter new password to continue.</p>
                            <div class="form-group mb-3">
                                <div class="media">
                                    <div class="avatar-sm mr-2">
                                        <span class="avatar-title rounded-circle font-12 font-weight-bold text-white bg-warning" [textContent]="form.fullName.value | initials"></span>
                                    </div>
                                    <div class="media-body">
                                        <h5 class="mb-0 mt-0 font-weight-normal" [textContent]="form.fullName.value"></h5>
                                        <span *ngIf="form.type.value && form.type.value === 'M'" class="d-block text-mute font-13" [textContent]="'(+' + countryCode + ') ' + form.username.value"></span>
                                        <span *ngIf="form.type.value && form.type.value === 'E'" class="d-block text-mute font-13" [textContent]="form.username.value"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <label class="mb-1">New Password</label>
                                <div class="input-group mb-0">
                                    <input type="password" formControlName="password" block autocomplete="nope" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.password.errors }" placeholder="Your new password" />
                                    <div class="input-group-append cursor-pointer" password>
                                        <div class="input-group-text"><span class="password-eye"></span></div>
                                    </div>
                                </div>
                                <div *ngIf="submitted && form.password.errors" class="invalid-feedback show-must">
                                    <div *ngIf="form.password.errors.minLength">
                                        Password must contain minimum of 4 characters.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-4">
                                <label class="mb-1">Re-enter Password</label>
                                <div class="input-group mb-0">
                                    <input type="password" formControlName="confirmPassword" block autocomplete="nope" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.confirmPassword.errors }" placeholder="Your password again" />
                                    <div class="input-group-append cursor-pointer" password>
                                        <div class="input-group-text"><span class="password-eye"></span></div>
                                    </div>
                                </div>
                                <div *ngIf="submitted && form.confirmPassword.errors" class="invalid-feedback show-must">
                                    <div *ngIf="form.confirmPassword.errors.minLength || form.confirmPassword.errors.notEquivalent">
                                        Passwords are not matched
                                    </div>
                                </div>
                            </div>
                            <button type="submit" [disabled]="submitting" class="btn btn-primary btn-block">
                                <span *ngIf="submitting">
                                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                    Please wait..
                                </span>
                                <span *ngIf="!submitting">Submit</span>
                            </button>
                        </ng-container>
                    </form>
                </ng-container>
                <footer class="footer footer-alt" *ngIf="!successMessage">
                    <p>Remember your password? <a routerLink="/login" href="javascript:;" class="ml-1"><b>Login</b></a></p>
                </footer>
            </div>
        </div>
    </div>
</div>