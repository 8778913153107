import { Component, ViewEncapsulation, OnInit, OnDestroy, TemplateRef, Input, Output, EventEmitter } from "@angular/core";
import { GenericResponse, IUserAccount, Page } from "@shared/models";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AppData, HttpService, NotifyService } from "@shared/services";
import { PatientChatBoxModel } from "../../entities/chat-box.entity";
import { ApiResources } from "@shared/helpers";
import { takeUntil, finalize } from "rxjs/operators";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WhiteSpaceValidator } from "../../validators";


@Component({
    templateUrl: "./chat-box.html",
    selector: "chat-box",
    encapsulation: ViewEncapsulation.None
})
export class ChatBoxWidget implements OnInit, OnDestroy {
    @Input() appointmentId: number;
    @Output() isClose = new EventEmitter<boolean>();
    @Input() isAdmission: boolean;
    @Output() countChanged: EventEmitter<number> = new EventEmitter<number>();
    page: Page;
    chatBoxForm: FormGroup;
    loading: boolean;
    records: Array<PatientChatBoxModel>;
    submitted: boolean;
    modalRef: NgbModalRef;
    submitting: boolean;

    constructor(
        private readonly appData: AppData,
        private readonly formBuilder: FormBuilder,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
        private readonly modalService: NgbModal,

    ) {
        this.page = new Page();
        this.buildForm();
        this.records = new Array<PatientChatBoxModel>();
    }
    private buildForm() {
        this.chatBoxForm = this.formBuilder.group({
            PatientChatBoxId: 0,
            message: [null, [Validators.required, WhiteSpaceValidator.isValid]]
        });
    }
    get form() {
        return this.chatBoxForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        if (this.chatBoxForm.invalid) {
            return;
        }
        this.submitting = true;
        const request = {
            ...this.chatBoxForm.getRawValue(),
            createdBy: this.page.userAccount.accountId,
            createdByName: this.page.userAccount.fullName,
            appointmentId: this.appointmentId,
            isAdmission: this.isAdmission
        };
        var url = ApiResources.getURI(ApiResources.patientChatBox.base, ApiResources.patientChatBox.add);
        this.httpService.post<GenericResponse>(url, request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.submitted = this.submitting = false))
            .subscribe(
                (response: GenericResponse) => {
                    if (response.status == "Success") {
                        this.notifyService.successToast(this.isAdmission ? "Admission Comments Saved Successfully" : "Appointment Comments Saved Successfully");
                    }
                    else {
                        this.notifyService.warningToast("Unable to Save.");
                    }
                    this.fetchAll(() => {
                        setTimeout(() => {
                            if (this.isAdmission) {
                                this.countchangedHandler();
                            }
                            this.onCloseModal();
                        })
                    })


                })
                }
    

     fetchAll=(callback?:Function)=> {
        this.loading = true;
        const request = {
            appointmentId: this.appointmentId,
            isAdmission: this.isAdmission
        };
        this.httpService.post<Array<PatientChatBoxModel>>(ApiResources.getURI(ApiResources.patientChatBox.base, ApiResources.patientChatBox.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<PatientChatBoxModel>) => {
                    this.records = response;
                    callback && callback(this.records);
                });
    }

    onOpenModel(content: TemplateRef<any>, record?: PatientChatBoxModel) {
        this.buildForm();
        if (record) {
            this.chatBoxForm.patchValue({
                patientChatBoxId: record.patientChatBoxId,
                message: record.message,
            });
        }
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            windowClass: "custom-modal effect-scale"
        });
    }
   
    onCloseModal = () => {
        this.isClose.emit();
    }
    countchangedHandler = () => {
        this.countChanged.emit(this.records.length);
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchAll();
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }

    ngOnDestroy() {
        this.onCloseModal();
        this.page.unsubscribeAll();
        this.page.unSubscribe.complete();
    }
}