export class InternalMedicinePrescription {
    appointmentId: number;
    appointmentDate: Date;
    appointmentTime: Date;
    appointmentTimeString: string;
    encounterId: number;
    medications: string;
    followUp: string;
    providerName: string;
    specializationName: string;
    practiceName: string;
    practiceLocation: string;
    patientName: string;
    patientAge: number;
    patientGender: string;
    patientDateOfBirth?: Date;
    patientMobile: string;
    behavioralHealthId: string;
    dentalEncounterId: number;
    providerNo: string;
    appointmentNo: string;
    educations: string;
    medicationComment: string;
    signature: string;
    clinicPicture: string;
    umrNo: string;
}
