import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { takeUntil, finalize } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from "@angular/forms";
import { HttpService, AppData, ResourceService, NotifyService } from "@shared/services";
import { NgbDate, NgbCalendar, NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SlotHolder, Bed, WordHelper, NurseDetails, BedFilter, FetchBedMode, ViewModel, Mode } from "../../../areas/admin/nurse-module/helpers/helper";
import { ApiResources, LinqHelper } from "../../../shared/helpers";
import { Page, IUserAccount, IResource, GenericResponse, GenericStatus } from "@shared/models";


@Component({
    selector: 'nurse-select-bed',
    templateUrl: './nurse-select-bed.html',
    styleUrls: ['./nurse-select-bed.css']
})

export class NurseSelectBedComponent implements OnDestroy, OnInit {    
    @Input() nurseAccountEncryptId: string;
    @ViewChild('templateSelectBed') templateSelectBed: TemplateRef<any>;
    page: Page;
    mainForm: FormGroup;
    locations: Array<any>;
    locationId: number;
    loadingBeds: boolean;
    slotsHolder: Array<SlotHolder>;
    originalBeds: Array<Bed>;
    beds: Array<Bed>;
    selectedSlotIndex: number;
    hideEmpty: FormControl;
    hideAssigned: FormControl;
    nurse: NurseDetails;
    loading: boolean;
    shifts: Array<IResource>;
    loadingShifts: boolean;
    isSingle: boolean;
    floorsFilter: Array<string>;
    wardsFilter: Array<string>;
    roomsFilter: Array<string>;
    bedsFilter: Array<string>;
    loadingNurse: boolean;
    mode = Mode;
    currentMode: Mode;
    modalRef: NgbModalRef;

    constructor(
        private readonly appData: AppData,
        private readonly resourceService: ResourceService,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
        private readonly formBuilder: FormBuilder,
        private readonly modalService: NgbModal,
        private readonly calendar: NgbCalendar,
    ) {
        this.page = new Page();
        this.locations = new Array<IResource>();
        this.slotsHolder = new Array<SlotHolder>();
        this.hideEmpty = new FormControl(false);
        this.hideAssigned = new FormControl(false);
        this.buildForm();
    }

    onCloseModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            //ignored;
        }
    }

    private fetchLocations() {
        this.resourceService.locations()
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.locations = response;
            });
    }

    onfilterlocation() {
        this.locationId = this.mainForm.get("locationId").value;
        this.fetchlocationfilter()
    }

    resetFilter = () => {
        this.locations = this.originalBeds.map(x => x.locationId).filter(LinqHelper.uniqueOnly);
        this.floorsFilter = this.originalBeds.map(x => x.floorName).filter(LinqHelper.uniqueOnly);
        this.wardsFilter = this.originalBeds.map(x => x.wardName).filter(LinqHelper.uniqueOnly);
        this.roomsFilter = this.originalBeds.map(x => x.roomName).filter(LinqHelper.uniqueOnly);
        this.bedsFilter = this.originalBeds.map(x => x.bedName).filter(LinqHelper.uniqueOnly);
        this.mainForm.patchValue({
            floorName: null, wardName: null, roomName: null, bedName: null, hideEmpty: false, hideAssigned: false, locationId: this.page.userAccount.locationId,
        }, { emitEvent: false });
        this.beds = LinqHelper.cloneDeepArray<Bed>(this.originalBeds);
        this.showFormBedsHelper();
    }

    get formSlots() { return this.mainForm.get("slots") as FormArray; }

    formSlotBeds(index: number) {
        let value = (this.mainForm.get("slots") as FormArray)?.at(index)?.get("beds") as FormArray;
        return value;
    }

    formSlotBedStatus(index: number, subIndex: number) {
        let value = ((this.mainForm.get("slots") as FormArray)?.at(index)?.get("beds") as FormArray).at(subIndex)?.get("status").value;
        return value;
    }

    formSlotBedIsShow(index: number, subIndex: number) {
        let value = ((this.mainForm.get("slots") as FormArray)?.at(index)?.get("beds") as FormArray).at(subIndex)?.get("isShow").value;
        return value;
    }

    formSlotBedValue(index: number, subIndex: number) {
        let value = ((this.mainForm.get("slots") as FormArray)?.at(index)?.get("beds") as FormArray).at(subIndex).value;
        return value;
    }

    showFormBedsHelper = () => {
        this.formSlotBeds(this.selectedSlotIndex).controls.forEach(x => {
            x.get("isShow").setValue(!!this.beds.find(y => y.bedId === x.get("bedId").value));
            this.hideEmpty.value && x.get("isShow").setValue(!!this.beds.find(y => y.bedId === x.get("bedId").value).admissionNo);
            this.hideAssigned.value && x.get("isShow").setValue(!this.beds.find(y => y.bedId === x.get("bedId").value).nurseShiftMapId);
        });
    }

    fetchlocationfilter() {
        const index = 0
        this.fetchBeds(index, {
            nurseShiftMapId: this.slotsHolder[index].nurseShiftMapId,
            fromDate: this.mainForm.get("slots").value[index].fromDate,
            shiftId: this.mainForm.get("slots").value[index].shiftId,
            toDate: this.mainForm.get("slots").value[index].toDate,
            locationId: this.page.userAccount.locationId
        } as BedFilter, FetchBedMode.OnModalOpen);
    }

    fetchBeds = (index: number, filterData: BedFilter, fetchMode: FetchBedMode) => {
        this.loadingBeds = true;
        this.slotsHolder[index].loadingBeds = true;
        filterData.nurseAccountId = this.nurse ? this.nurse.accountId : null;
        filterData.locationId = this.locationId;

        this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.nurseShift.base, ApiResources.nurseShift.fetchBeds), filterData)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.slotsHolder[index].loadingBeds = false; this.loadingBeds = false; }))
            .subscribe((response: GenericResponse) => {
                if (response.status === GenericStatus[GenericStatus.Success]) {
                    var data = response.data as Array<Bed>;
                    if (FetchBedMode.OnInitial === fetchMode || FetchBedMode.OnModalOpen === fetchMode) {
                        var array = this.formSlotBeds(index) as FormArray;
                        array.clear();
                        data.forEach((x: Bed, bedIndex: number) => {
                            x.floorName = LinqHelper.stuffIn(x.floorName, WordHelper.floorName);
                            x.wardName = LinqHelper.stuffIn(x.wardName, WordHelper.wardName);
                            x.roomName = LinqHelper.stuffIn(x.roomName, WordHelper.roomName);
                            x.bedIndex = bedIndex;
                            if (x.admissionNo) {
                                x.patientName = x.patientName.toLowerCase();
                                x.doctorName = x.doctorName.toLowerCase();
                            }
                            if (x.nurseShiftMapId) {
                                x.fromDate = new Date(x.fromDate);
                                x.toDate = new Date(x.toDate);
                            }
                            var status = FetchBedMode.OnInitial === fetchMode
                                ? new FormControl(x.nurseShiftMapId && x.nurseShiftMapId === filterData.nurseShiftMapId)
                                : this.slotsHolder[index].selectedBeds && this.slotsHolder[index].selectedBeds.length > 0
                                    ? new FormControl(!!this.slotsHolder[index].selectedBeds.find(y => y.bedId === x.bedId))
                                    : new FormControl(false);
                            array.push(new FormGroup({
                                bedId: new FormControl(x.bedId),
                                status: status,
                                isShow: new FormControl(true)
                            }));
                        })
                        this.floorsFilter = data.map(x => x.floorName).filter(LinqHelper.uniqueOnly);
                        this.wardsFilter = data.map(x => x.wardName).filter(LinqHelper.uniqueOnly);
                        this.roomsFilter = data.map(x => x.roomName).filter(LinqHelper.uniqueOnly);
                        this.bedsFilter = data.map(x => x.bedName).filter(LinqHelper.uniqueOnly);
                        this.originalBeds = data;
                        this.beds = data;
                        if (FetchBedMode.OnInitial === fetchMode) {
                            this.slotsHolder[index].selectedBeds = LinqHelper.cloneDeepArray<Bed>(this.originalBeds.filter(x => x.nurseShiftMapId && x.nurseShiftMapId === filterData.nurseShiftMapId))
                            this.slotsHolder[index].dbBeds = LinqHelper.cloneDeepArray<Bed>(this.slotsHolder[index].selectedBeds)
                        }
                    } else {
                        data.length > 0 && this.slotsHolder[index].selectedBeds.forEach(x => {
                            var found = data.find(y => y.bedId === x.bedId);
                            if (found && found.nurseShiftMapId && x.nurseShiftMapId && x.nurseShiftMapId !== found.nurseShiftMapId) {
                                x.isUnavailable = true;
                                x.assignedFullName = found.fullName;
                                x.assignedRoleName = found.roleName;
                            } else {
                                x.isUnavailable = false;
                            }
                        });
                    }
                } else {
                    this.notifyService.defaultError();
                }
            });
    }

    onBedSelected = () => {
        var selectedBedIds = (this.formSlotBeds(this.selectedSlotIndex).value as Array<any>).filter(x => x.status).map(x => x.bedId);
        this.slotsHolder[this.selectedSlotIndex].selectedBeds = LinqHelper.cloneDeepArray<Bed>(this.originalBeds.filter(x => selectedBedIds.indexOf(x.bedId) !== -1));
        this.onCloseModal();
    }

    setFiltersHelper = () => {
        this.floorsFilter = this.beds.map(x => x.floorName).filter(LinqHelper.uniqueOnly);
        this.wardsFilter = this.beds.map(x => x.wardName).filter(LinqHelper.uniqueOnly);
        this.roomsFilter = this.beds.map(x => x.roomName).filter(LinqHelper.uniqueOnly);
        this.bedsFilter = this.beds.map(x => x.bedName).filter(LinqHelper.uniqueOnly);
    }

    private buildForm() {
        var floorName = new FormControl(null);
        var wardName = new FormControl(null);
        var roomName = new FormControl(null);
        var bedName = new FormControl(null);
        var selectAll = new FormControl(false);
        this.mainForm = this.formBuilder.group({
            locationId: [],
            floorName, wardName, roomName, bedName, hideEmpty: this.hideEmpty, hideAssigned: this.hideAssigned, selectAll,
            slots: new FormArray([], [Validators.required])
        });
        selectAll.valueChanges.subscribe(val => {
            if (val !== null || val !== undefined) {
                this.formSlotBeds(this.selectedSlotIndex).controls.filter(x => x.get("isShow").value).forEach(x => {
                    let bedId = x.get("bedId").value;
                    let found = this.beds.find(y => y.bedId === bedId);
                    if (!found.nurseShiftMapId || (found && found.nurseAccountId === this.nurse.accountId)) {
                        x.get("status").setValue(val);
                    }
                });
            }
        })
        floorName.valueChanges.subscribe(z => {
            if (!z) {
                this.mainForm.patchValue({
                    wardName: null, roomName: null, bedName: null,
                }, { emitEvent: false });
                this.beds = LinqHelper.cloneDeepArray<Bed>(this.originalBeds);
                this.showFormBedsHelper();
                this.setFiltersHelper();
                return;
            }
            this.wardsFilter = this.originalBeds.filter(x => x.floorName === z).map(x => x.wardName).filter(LinqHelper.uniqueOnly);
            this.roomsFilter = this.originalBeds.filter(x => x.floorName === z).map(x => x.roomName).filter(LinqHelper.uniqueOnly);
            this.bedsFilter = this.originalBeds.filter(x => x.floorName === z).map(x => x.bedName).filter(LinqHelper.uniqueOnly);
            this.beds = LinqHelper.cloneDeepArray<Bed>(this.originalBeds.filter(x => x.floorName === z));
            this.showFormBedsHelper();
        });
        wardName.valueChanges.subscribe(z => {
            if (!z) {
                this.mainForm.patchValue({
                    roomName: null, bedName: null,
                }, { emitEvent: false });
                this.beds = LinqHelper.cloneDeepArray<Bed>(this.originalBeds.filter(x =>
                    x.floorName === (floorName.value || x.floorName)
                ));
                this.showFormBedsHelper();
                this.setFiltersHelper();
                return;
            }
            this.floorsFilter = this.originalBeds.filter(x => x.wardName === z).map(x => x.floorName).filter(LinqHelper.uniqueOnly);
            this.roomsFilter = this.originalBeds.filter(x => x.wardName === z).map(x => x.roomName).filter(LinqHelper.uniqueOnly);
            this.bedsFilter = this.originalBeds.filter(x => x.wardName === z).map(x => x.bedName).filter(LinqHelper.uniqueOnly);
            this.beds = LinqHelper.cloneDeepArray<Bed>(this.beds.filter(x => x.wardName === z));
            this.showFormBedsHelper();
        });
        roomName.valueChanges.subscribe(z => {
            if (!z) {
                this.mainForm.patchValue({
                    bedName: null,
                }, { emitEvent: false });
                this.beds = LinqHelper.cloneDeepArray<Bed>(this.originalBeds.filter(x =>
                    x.floorName === (floorName.value || x.floorName) &&
                    x.wardName === (wardName.value || x.wardName)
                ));
                this.showFormBedsHelper();
                this.setFiltersHelper();
                return;
            }
            this.floorsFilter = this.originalBeds.filter(x => x.roomName === z).map(x => x.floorName).filter(LinqHelper.uniqueOnly);
            this.wardsFilter = this.originalBeds.filter(x => x.roomName === z).map(x => x.wardName).filter(LinqHelper.uniqueOnly);
            this.bedsFilter = this.originalBeds.filter(x => x.roomName === z).map(x => x.bedName).filter(LinqHelper.uniqueOnly);
            this.beds = LinqHelper.cloneDeepArray<Bed>(this.beds.filter(x => x.roomName === z));
            this.showFormBedsHelper();
        });
        bedName.valueChanges.subscribe(z => {
            if (!z) {
                this.beds = LinqHelper.cloneDeepArray<Bed>(this.originalBeds.filter(x =>
                    x.floorName === (floorName.value || x.floorName) &&
                    x.wardName === (wardName.value || x.wardName) &&
                    x.roomName === (roomName.value || x.roomName)
                ));
                this.showFormBedsHelper();
                this.setFiltersHelper();
                return;
            }
            this.floorsFilter = this.originalBeds.filter(x => x.bedName === z).map(x => x.floorName).filter(LinqHelper.uniqueOnly);
            this.wardsFilter = this.originalBeds.filter(x => x.bedName === z).map(x => x.wardName).filter(LinqHelper.uniqueOnly);
            this.roomsFilter = this.originalBeds.filter(x => x.bedName === z).map(x => x.roomName).filter(LinqHelper.uniqueOnly);
            this.beds = LinqHelper.cloneDeepArray<Bed>(this.beds.filter(x => x.bedName === z));
            this.showFormBedsHelper();
        });
        this.hideEmpty.valueChanges.subscribe(flag => {
            flag && this.formSlotBeds(this.selectedSlotIndex).controls.filter(x => x.get("isShow").value).forEach(x => {
                x.get("isShow").setValue(!!this.beds.find(y => y.bedId === x.get("bedId").value).admissionNo);
            });
            if (!flag) {
                this.beds = LinqHelper.cloneDeepArray<Bed>(this.originalBeds.filter(x =>
                    x.floorName === (floorName.value || x.floorName) &&
                    x.wardName === (wardName.value || x.wardName) &&
                    x.roomName === (roomName.value || x.roomName) &&
                    x.bedName === (bedName.value || x.bedName)
                ));
                this.showFormBedsHelper();
            }
        });
        this.hideAssigned.valueChanges.subscribe(flag => {
            flag && this.formSlotBeds(this.selectedSlotIndex).controls.filter(x => x.get("isShow").value).forEach(x => {
                x.get("isShow").setValue(!this.beds.find(y => y.bedId === x.get("bedId").value).nurseShiftMapId);
            });
            if (!flag) {
                this.beds = LinqHelper.cloneDeepArray<Bed>(this.originalBeds.filter(x =>
                    x.floorName === (floorName.value || x.floorName) &&
                    x.wardName === (wardName.value || x.wardName) &&
                    x.roomName === (roomName.value || x.roomName) &&
                    x.bedName === (bedName.value || x.bedName)
                ));
                this.showFormBedsHelper();
            }
        });
    }

    addSlot = (model?: ViewModel, callback: Function = () => { }) => {
        var array = this.mainForm.get("slots") as FormArray;
        if (this.slotsHolder.length && !array.at(this.slotsHolder.length - 1).value.toDate) {
            this.notifyService.info("Please complete current assignment to add another");
            return;
        }
        var today = new Date();
        var fromDate = model ? { year: model.fromDate.getFullYear(), month: model.fromDate.getMonth() + 1, day: model.fromDate.getDate() } as NgbDate : null;
        var minDate = model && !this.slotsHolder.length
            ? model.fromDate.getTime() > new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime()
                ? this.calendar.getToday()
                : fromDate
            : !this.slotsHolder.length
                ? this.calendar.getToday()
                : this.calendar.getToday();
        var holder = {
            minDate: minDate,
            selectedSlots: new Array<IResource>()
        } as SlotHolder;
        if (model) {
            holder.fromDate = model.fromDate;
            holder.toDate = model.toDate;
            holder.isSameDate = model.fromDate.getTime() === model.toDate.getTime();
            holder.nurseShiftMapId = model.nurseShiftMapId || null;
        }
        this.slotsHolder.push(holder);
        var toDate = model ? { year: model.toDate.getFullYear(), month: model.toDate.getMonth() + 1, day: model.toDate.getDate() } as NgbDate : null;
        var shiftId = model ? model.shiftId || null : null;
        array.push(new FormGroup({
            fromDate: new FormControl(fromDate, [Validators.required]),
            toDate: new FormControl(toDate, [Validators.required]),
            beds: new FormArray([], [Validators.required]),
            shiftId: new FormControl(shiftId, [Validators.required]),
        }));
        shiftId && this.onShiftChange(this.shifts.find(x => x.id === shiftId) as IResource, this.slotsHolder.length - 1);
        callback(this.slotsHolder.length - 1);
    }

    onMouseEnter = (date: NgbDate, index: number) => {
        this.slotsHolder[index].hoveredDate = date;
    }

    onMouseLeave = (index: number) => {
        this.slotsHolder[index].hoveredDate = null;
    }

    isHovered(date: NgbDate, index: number) {
        var array = this.mainForm.get("slots") as FormArray;
        return array.at(index).value.fromDate && !array.at(index).value.toDate && this.slotsHolder[index].hoveredDate && date.after(array.at(index).value.fromDate) && date.before(this.slotsHolder[index].hoveredDate);
    }

    isInside(date: NgbDate, index: number) {
        var array = this.mainForm.get("slots") as FormArray;
        return array.at(index).value.toDate && date.after(array.at(index).value.fromDate) && date.before(array.at(index).value.toDate);
    }

    isRange(date: NgbDate, index: number) {
        var array = this.mainForm.get("slots") as FormArray;
        return date.equals(array.at(index).value.fromDate) || (array.at(index).value.toDate && date.equals(array.at(index).value.toDate)) || this.isInside(date, index) || this.isHovered(date, index);
    }

    markDisabled = (_date: NgbDate, _current?: any) => {
        return this.isSingle;
    }

    fetch(callBack: Function) {
        if (!this.nurseAccountEncryptId) {
            return;
        }
        this.slotsHolder = [];
        this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.nurseShift.base, ApiResources.nurseShift.fetch), { id: this.nurseAccountEncryptId, date: null })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false }))
            .subscribe((response: GenericResponse) => {
                if (response.status === GenericStatus[GenericStatus.Success]) {
                    var records = response.data as Array<ViewModel>;
                    if (records.length > 0) {
                        this.currentMode = Mode.Edit;
                        records.forEach(x => {
                            x.fromDate = new Date(x.fromDate);
                            x.toDate = new Date(x.toDate);

                            this.addSlot(x, (index: number) => {
                                this.fetchBeds(index, {
                                    shiftId: x.shiftId,
                                    nurseShiftMapId: x.nurseShiftMapId
                                } as BedFilter, FetchBedMode.OnInitial)
                            });
                        });
                    } else {
                        this.currentMode = Mode.New;
                        this.addSlot(null);
                    }
                    callBack && callBack();
                } else {
                    this.notifyService.defaultError();
                }
            });
    }

    onShiftChange = (shift: IResource, index: number) => {
        this.slotsHolder[index].shiftName = shift.value;
    }

    private fetchShifts(callback?: Function) {
        this.loadingShifts = true;
        this.resourceService.fetchShifts()
            .pipe(finalize(() => { this.loadingShifts = false }))
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                this.shifts = response;
                callback();
            });
    }

    getNurseDetails = () => {
        this.loadingNurse = true;
        this.httpService.post<GenericResponse>(ApiResources.getURI(ApiResources.nurseShift.base, ApiResources.nurseShift.getNurse), { id: this.nurseAccountEncryptId })
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loadingNurse = false }))
            .subscribe((response: GenericResponse) => {
                if (response.status === GenericStatus[GenericStatus.Success]) {
                    this.nurse = response.data as NurseDetails;
                } else {
                    this.notifyService.defaultError();
                }
            });
    }

    selectBed(index:number) {
        if (this.slotsHolder[index].loadingBeds || this.loadingBeds) return;

        var toDate = this.mainForm.get("slots").value[index].toDate;
        var shiftId = this.mainForm.get("slots").value[index].shiftId;
        if (!toDate && !shiftId) {
            this.notifyService.warning("Please select shift and dates.");
            return;
        }

        if (!shiftId) {
            this.notifyService.warning("Please select shift.");
            return;
        }

        if (!toDate) {
            this.notifyService.warning("Please select dates.");
            return;
        }

        this.mainForm.patchValue({ selectAll: false }, { emitEvent: false });
        this.selectedSlotIndex = index;
        this.originalBeds = new Array<Bed>();
        this.beds = new Array<Bed>();

        this.fetchBeds(index, {
            nurseShiftMapId: this.slotsHolder[index].nurseShiftMapId,
            fromDate: this.mainForm.get("slots").value[index].fromDate,
            shiftId: shiftId,
            toDate: toDate,
            locationId: this.page.userAccount.locationId
        } as BedFilter, FetchBedMode.OnModalOpen);
    } 

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchLocations();
                    this.getNurseDetails();
                    this.fetchShifts(() => {
                        this.fetch(() => {
                            const model = new ViewModel;
                            this.addSlot(model, (slotIndex: number) => {
                                this.selectBed(slotIndex);
                            });
                        });
                    });
                } else {
                    this.page.userAccount = undefined;
                }
            });
        this.mainForm.patchValue({
            locationId: this.page.userAccount.locationId
        });
        this.locationId = this.page.userAccount.locationId;
    }

    onOpenModel(content: TemplateRef<any>, index: number) {
        if (this.slotsHolder[index].loadingBeds || this.loadingBeds) return;

        var toDate = this.mainForm.get("slots").value[index].toDate;
        var shiftId = this.mainForm.get("slots").value[index].shiftId;
        if (!toDate && !shiftId) {
            this.notifyService.warning("Please select shift and dates.");
            return;
        }

        if (!shiftId) {
            this.notifyService.warning("Please select shift.");
            return;
        }

        if (!toDate) {
            this.notifyService.warning("Please select dates.");
            return;
        }

        this.mainForm.patchValue({ selectAll: false }, { emitEvent: false });
        this.selectedSlotIndex = index;
        this.originalBeds = new Array<Bed>();
        this.beds = new Array<Bed>();

        this.fetchBeds(index, {
            nurseShiftMapId: this.slotsHolder[index].nurseShiftMapId,
            fromDate: this.mainForm.get("slots").value[index].fromDate,
            shiftId: shiftId,
            toDate: toDate,
            locationId: this.page.userAccount.locationId
        } as BedFilter, FetchBedMode.OnModalOpen);

        setTimeout(() => {
            this.modalRef = this.modalService.open(content, {
                backdrop: "static",
                keyboard: false,
                centered: true,
                size: "xl",
                windowClass: "custom-modal slots-modal effect-scale"
            });
        });
    }

    triggerModal() {
        this.onOpenModel(this.templateSelectBed, 0);
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
}