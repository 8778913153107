import { Component, OnInit, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from "@angular/core";
import { Page, IUserAccount } from "../../models";
import { takeUntil, finalize } from "rxjs/operators";
import { LabBillDetailModel, LabBillHeaderModel } from "../../../areas/admin/labs/pages/models";
import { ApiResources } from "../../helpers";
import { AppData, HttpService, PrintOptionService } from "../../services";
import { ItemsList } from "@ng-select/ng-select/lib/items-list";

@Component({
    templateUrl: "./new-lab-cancelled-invoice.html",
    selector: "new-lab-cancelled-invoice",
    encapsulation: ViewEncapsulation.None
})

export class NewLabCancelledInvoiceWidget implements OnInit, OnDestroy {
    @Input() newLabBookingHeaderId: number;
    @Input() showFinanceandPrintByDetails: boolean;
    @Input() isNewlabinvoiceId: boolean;
    @Input() isPrintLogo: boolean;
    page: Page;
    loading: boolean;
    QrCode: string = null;
    currentDate: Date;
    bill: Array<LabBillHeaderModel>;
    sendpatientId: string;
    isRecordSelected: boolean;
    isRefund: boolean;
    refundAmount: number;
    removedAmount: number;
    labsArray: Array<LabBillDetailModel>;
    isFooter: boolean;
    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
    ) {
        this.page = new Page();
        this.bill = new Array<LabBillHeaderModel>();
        this.currentDate = new Date();
    }
    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                   this.fetchInvoice();
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                } else {
                    this.page.userAccount = undefined;
                      this.fetchInvoice();
                   
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }
    private fetchInvoice() {
        const request = {
            newLabBookingHeaderId: this.newLabBookingHeaderId
        };
        if (this.showFinanceandPrintByDetails == null && this.showFinanceandPrintByDetails == undefined) {
            this.showFinanceandPrintByDetails == false;
        }
        this.loading = true;
        this.httpService.post(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchSelectedInvoiceLabData), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<LabBillHeaderModel>) => {
                    this.bill = response;
                    const arr = this.bill[0] ? this.bill[0].payTypes : null;
                    if (this.bill[0])
                        this.removedAmount = arr[0]?arr[0].removedAmount:null;    
                    this.QrCode = `${location.origin}${location.pathname}#/new-lab-webreports/${this.bill[0].requisitionNumber}`;
                });
        this.currentDate = new Date();
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
}