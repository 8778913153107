export enum RegisterType {
    FirstFloorAD = 1,
    SecondFloorAD,
    ThirdFloorAD,
    FFSemiPrivateAD,
    GroundFloorAD,
    NICURegister,
    NICUDeliveryRegister,
    MidWifeRegister,
    DayCareAD,
    NICUOpRegister,
    EmergencyRegister,
    LabourWardRegister,
    LabourWardAllRegister,
    OTRegister,
    OTAllRegister,
    ICNRegister,
    ThirdFloorLW,
    ThirdFloorAllLW,
    KmcAdmissionRegister,
    QualityIndicatorRegister,
    FeedingScheduleRegister,
    FirstFloorQualityIndicatorsRegister,
    GfcQualityIndicatorRegister,
    kpiQualityIndicatorRegister,
    SecondFloorQualityIndicatorsRegister,
    ThirdFloorQualityIndicatorsRegister, 
    IcuPowQualityIndicatorRegister,
    DayCareQualityIndicatorRegister,
    LabourWardQualityIndicatorRegister,
    FFSemiPrivateWardQualityIndicatorRegister,
    OTQualityIndicatorRegister,
    EmergencyQualityIndicatorRegister,
    DetailsOfEBirthRegister,
    DischargeRegister,
}