<div class="modal-body" *ngIf="loading">
    <div class="d-flex align-items-center">
        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
        <span class="ml-2">Please wait while loading bill details....</span>
    </div>
</div>
<div class="modal-body" *ngIf="!loading && bill.length === 0">
    <div class="d-flex justify-content-center">
        <div class="no-data-container">
            <img class="mt-1" width="350" src="assets/images/no-data-2.png" alt="No Data" />
            <h4 class="mt-1">No Bill Data found.Nothing to show you.</h4>
        </div>
    </div>
</div>
<div class="modal-body ml-2" Id="invoiceId" *ngIf="!loading && bill.length > 0">
    <div class="row" *ngFor="let bill of bill;index as i;">
        <div class="col-12">
            <table width="100%" class="heading1">
                <tr>
                    <td colspan="4" align="center" *ngIf="isPrintLogo">
                        <banner-setting [reportName]='"Labs Cancellation Report Headre"'></banner-setting>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        <h4 class="text-center">Laboratory Receipt</h4>
                    </td>

                </tr>
                <tr class="heading2">
                    <th class="table-heding">
                        <span *ngIf="bill.patientName">Patient Name</span>
                    </th>
                    <td>
                        <span class="colon-space">: </span>
                        <span class="mb-0 mt-0 font-weight-normal text-uppercase" *ngIf="bill.patientName" [textContent]="bill.patientName"></span>
                    </td>
                    <th class="table-heding">
                        UMR No
                    </th>
                    <td class="table-style">
                        <span class="colon-space">: </span>
                        <span [textContent]="bill.umrNo || '-----'"></span>
                    </td>
                </tr>
                <tr>
                    <th class="table-heding">
                        Age/Gender
                    </th>
                    <td class="table-heding">
                        <span style="margin-right: 5px">: </span>
                        <span [textContent]="bill.age || '----'"></span>
                        <span *ngIf="bill.gender">/<span [textContent]="bill.gender | gender"></span></span>
                    </td>
                    <th class="table-heding">
                        Doctor Name
                    </th>
                    <td class="table-heding">
                        <span class="colon-space">: </span>
                        <span class="mb-0 mt-0 font-weight-normal text-uppercase" [textContent]="bill.doctorName || '------'"></span>
                    </td>
                </tr>
                <tr>
                    <th class="table-heding">
                        <span>Invoice Date</span>
                    </th>
                    <td class="table-style">
                        <span class="colon-space">: </span>
                        <span [textContent]="bill.createdDate | date:'dd/MM/yyyy, h:mm a'"></span>
                    </td>
                    <th class="table-heding">
                        Requisition No
                    </th>
                    <td class="table-heding">
                        <span class="colon-space">: </span>
                        <span [textContent]="bill.requisitionNumber"></span>
                    </td>
                </tr>
            </table>
        </div>
        <div class="col-12 pr-4">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>TestName</th>
                        <th>Status</th>
                        <th *ngIf="showFinanceandPrintByDetails">Total(&#8377;)</th>
                        <th *ngIf="showFinanceandPrintByDetails">Discount(&#8377;)</th>
                        <th *ngIf="showFinanceandPrintByDetails">NetAmnt(&#8377;)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let lab of bill.labs;index as j;">
                        <td>
                            <h6 class="mb-0 mt-0" [textContent]="j+1"></h6>
                        </td>
                        <td>
                            <h6 class="mb-0 mt-0" [textContent]="lab.testName"></h6>
                            <small class="d-block" [textContent]="lab.departmentName || '--'"></small>
                        </td>
                        <td>
                            <h6 class="mb-0 mt-0" [textContent]="lab.status"></h6>
                        </td>
                        <td *ngIf="showFinanceandPrintByDetails">
                            <h6 class="mb-0 mt-0" [textContent]="lab.totalAmount || 0 | currency :'INR'"></h6>
                        </td>
                        <td *ngIf="showFinanceandPrintByDetails">
                            <h6 class="mb-0 mt-0" [textContent]="lab.discountAmount || 0 | currency :'INR'"></h6>
                        </td>
                        <td *ngIf="showFinanceandPrintByDetails">
                            <h6 class="mb-0 mt-0" [textContent]="lab.netAmount || 0 | currency :'INR'"></h6>
                        </td>
                    </tr>
                    <tr>
                        <td *ngIf="isNewlabinvoiceId" class="justify-content-center d-flex">
                            <div id="backgroundOfwaterMark">
                                <span class=" text-danger"> Duplicate Receipt</span>
                            </div>

                        </td>
                    </tr>
                </tbody>
                <tfoot *ngIf="showFinanceandPrintByDetails">
                    <tr>
                        <td colspan="2">
                            <strong>Amount : {{bill.overallTotalAmount || 0 | currency:'INR' }}</strong>
                        </td>
                        <td>
                            <strong>Disc : {{bill.overallDiscount || 0 | currency:'INR'}}</strong>
                        </td>
                        <td colspan="2">
                            <strong>Refund Amount : {{removedAmount || 0 | currency:'INR' }}</strong>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <div class="col-12 row" *ngIf="showFinanceandPrintByDetails">
            <div class="col-8 mt-1">
                <table width="100%" class="heading1">
                    <tr>
                        <th *ngIf="bill.paymentType === 'P'" class="justify-content-center d-flex">
                            <div id="backgroundOfwaterMark" style=" -webkit-transform: rotate(-30deg); color:grey"> <span>Partial Paid</span></div>
                        </th>
                    </tr>
                    <tr class="heading2">
                        <th>
                            Prepared By
                        </th>
                        <td colspan="3">
                            <span>: </span>
                            <span [textContent]="bill.createdByName"></span>
                        </td>

                    </tr>
                    <tr>
                        <th colspan="4" *ngIf="bill.payTypes[0]">
                            <span *ngIf="bill.paymentType === 'F'">Full Paid</span>
                        </th>
                    </tr>
                    <tr class="heading2" *ngFor="let paytype of bill.payTypes">
                        <th [textContent]="paytype.payTypeName">
                        </th>
                        <td>
                            :
                        </td>
                        <td [textContent]="paytype.amountPaid || 0 | currency :'INR'">
                        </td>
                        <td [textContent]="paytype.receiptDate | date:'dd/MM/yyyy, h:mm a'">
                        </td>
                    </tr>
                    <tr class="heading2">
                        <th>
                            Printed By
                        </th>
                        <td colspan="3">
                            <span>: </span>
                            <span [textContent]="page.userAccount.fullName"></span>
                        </td>
                    </tr>
                    <tr class="heading2">
                        <th>
                            Printed Date
                        </th>
                        <td colspan="3">
                            <span>: </span>
                            <span [textContent]="currentDate | date:'dd/MM/yyyy, h:mm a'"></span>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-4">
                <qrcode [qrdata]="QrCode" [width]="200" [errorCorrectionLevel]="'M'" [elementType]="'img'"></qrcode>
            </div>
        </div>
        <div *ngIf="isFooter">
            <banner-setting [reportName]='"Transfer Lab Report Footer"'></banner-setting>
        </div>
    </div>
</div>