import { NgModule } from '@angular/core';
import { SharedModule } from '@src/app/shared/shared.module';
import { ConsentFromRoutingModule } from './consent-forms.routing.module';
import { ConsentFormsMainPage, ConsentFromPage } from './index';


@NgModule({
    declarations: [
        ConsentFromPage, ConsentFormsMainPage
       ],
    imports: [
        ConsentFromRoutingModule,
        SharedModule
    ]
})
export class ConsentFromModule { }
