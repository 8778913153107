
export class PharmacyProductType {
    pharmacyProductTypeId: number;
    typeName: string;
    active: boolean;
    isGeneralItem: boolean;
    createdBy: number;
    createdByName: string;
    createdDate: Date;
    modifiedBy: number | null;
    modifiedByName: string;
    modifiedDate: Date | null;
    createdByRole: string;
    modifiedByRole: string;
}