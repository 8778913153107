<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-12 col-sm-12 col-md-12 mb-1" *ngFor="let item of records;let j = index;">
                        <div class="document-container" style="background-color: #e6e6e7 !important;">
                            <div class="document-body">
                                <h4>
                                    <i class="mdi mdi-comment-outline mr-1"></i> 
                                    <a href="javaScript:;" class="text-reset">
                                            {{item.createdByName}}{{item.roleName ? ('(' + item.roleName + ')') : ''}}
                                    </a> at <span [textContent]="item.createdDate |date:'dd/MM/yyyy ,hh:mm a'"></span>
                                </h4>
                                <p class="text-justify">
                                    {{item.message}}
                                </p>

                            </div>
                            <div class="document-actions">
                                <!--<a href="javascript:;" (click)="commentsAction('Edit',j,timeline)" placement="left" ngbTooltip="Edit"><i class="mdi mdi-folder-edit-outline"></i></a>
    <a href="javascript:;" (click)="commentsAction('Delete',j,timeline)" placement="left" ngbTooltip="Delete"><i class="mdi mdi-trash-can-outline"></i></a>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <form [formGroup]="chatBoxForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-12">
                            <label class="mb-1">Type Your Message Here <code>*</code></label>
                            <input type="text" class="form-control" placeholder="Enter Your Message Here." formControlName="message" trimSpace
                                   [ngClass]="{ 'is-invalid': submitted && form.message.errors }" />
                            <div class="text-danger" *ngIf="submitted && form.message.errors">
                                <p *ngIf="form.message.errors.whiteSpaces">Please remove white spaces...</p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" [disabled]="submitting" class="btn btn-primary btn-sm">
                            <span *ngIf="submitting">
                                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                Please wait..
                            </span>
                            <span *ngIf="!submitting">Submit</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>