import { FormControl } from "@angular/forms";
import { RegExHelper } from "@shared/helpers";

export class MobileValidator {
    static isValid(control: FormControl): object | null {
        const value = control.value;
        if (!value || !control.parent) {
            return null;
        }

        const countryControl = control.parent.get("countryId");
        if (countryControl) {
            const countryId = countryControl.value;
            if (countryId) {
                if (!RegExHelper.numbersOnly.test(value) || (countryId === 3 ? !/^\b\d{8}?\b$/.test(value) : !/^\b\d{10}?\b$/.test(value))) {
                    return { 'invalidMobile': true };
                }
            }

            return null;
        } else {
            if (!RegExHelper.numbersOnly.test(value) || !/^\b\d{10}(-\d{8})?\b$/.test(value)) {
                return { 'invalidMobile': true };
            }

            return null;
        }
    }
}