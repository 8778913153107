import { ColorTemplate } from "../../../areas/admin/labs/pages/models/lab-color-template-model";
import { Component, OnInit, Input } from '@angular/core';
import { LinqHelper } from '../../helpers';

@Component({
    templateUrl: "./lab-status-identification.html",
    selector: "lab-status-identification",
})
export class LabStatusIdentification implements OnInit {
    arrayOfColor: Array<ColorTemplate>;    
    @Input() detailOfColor: Array<ColorTemplate>;

    constructor() {
        this.arrayOfColor = new Array<ColorTemplate>();//meaning of this
    }   
    
    private buildColorPalates() {
        this.arrayOfColor = LinqHelper.cloneDeepArray(this.detailOfColor);
    }

    ngOnInit() {
        this.buildColorPalates();
       
    }


}