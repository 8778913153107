export class DietIndentReport{
    patientDietId: number;
    admissionId: number;
    mealTypeId: number;
    dietPlanTypeId: number;
    locationId: number;
    approvedBy: number;
    approvedDate: Date;
    createdDate: Date;
    requestType: number;
    patientId: number;
    patientName: string;
    umrNo: string;
    mobile: string;
    dietplaneId: number;
    status: string;
    dietPlanTypeName: string;
    createdByName: string;
    createdByRole: string;
    approvedByName: string;
    approvedByRole: string;
    admissionNo: number;
    mealType: string;
    orderDate: any;
    indententName: any;
    indentRoleName: any;
    indentDate: any;
    approvedName: any;
    approvedRoleName: any;
}