import { FormControl } from "@angular/forms";
import { RegExHelper } from "@shared/helpers";

export class SpValidator {
    static isValid(control: FormControl) {
        const value = control.value;

        if (!value) {
            return null;
        }

        if (!RegExHelper.sp.test(value)) {
            return { 'invalidSp': true };
        } else {
            return null;
        }
    }
}