import { Component, Input, OnInit, HostListener, OnDestroy } from '@angular/core';
import { takeUntil, finalize } from "rxjs/operators";

import { OB } from "@admin/full-transcript/pages/ob-report/ob.namespace";
import { PediatricEncounter } from '../../../areas/admin/pediatric-encounter/pediatric-encounter.namespace';
import { PEncounter } from '../../models/pediatric-encounter.model';
import { Appointment, PreviousAppointment } from '../../entities';
import { ApiResources, DateHelper } from "@shared/helpers";
import { HttpService, AppData, EncounterCommunication, TimelineToggleService } from "@shared/services";
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { Page, IUserAccount, PatientProfile, IEncounterResource } from "@shared/models";
import moment from 'moment';

@Component({
    selector: 'pediatrics-header',
    templateUrl: './pediatrics-header.html',
    styleUrls: ['./pediatric-header.css']
})
export class PediatricsHeaderComponent implements OnInit, OnDestroy {

    page: Page;



    //services end

    patient: PatientProfile;
    measurements: PediatricEncounter.Measurements;
    isAdmission: boolean = null;
    appointmentId: string = null;
    appointmentIdr: string = null;
    routingValue: string = null;
    pediatricEncounter: any = null;
    specialFeature: OB.SpecialFeature;
    allergies: OB.Allergies;
    allergiesdata: IEncounterResource;
    appointment: any = null;
    encounterCommunicationSubscription: Subscription;
    fatherName: string;
    commonData: IEncounterResource;
    patientIdd: any;
    roleId: number;
    appointments: any;
    oldAppointment: any;
    sideNav: boolean;
    neonatalRisk: PediatricEncounter.NeoNatalRisk;
    yearsfordisplay: number;
    daysfordisplay: number;
    monthsfordisplay: number;
    visit: number
    visitnumber: number;
    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly encounterCommunication: EncounterCommunication,
        private readonly router: Router,
        public timelineService: TimelineToggleService
    ) {
        this.page = new Page();

    }


    @Input() patientId: string;
    @Input() appointmentIdd: string;
    @Input() encryptedPatientId: string;
    @Input() encryptedAppointmentId: string;

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.isAdmission = url.split("/")[4] === "a";
                    this.appointmentId = url.split("/")[url.split("/").length - 3];
                    this.roleId = this.page.userAccount.roleId;
                    //this.findAppointment(appointmentId);
                    const appointmentId = decodeURIComponent(url.split("/")[url.split("/").length - 3]);
                    this.roleId = this.page.userAccount.roleId;
                    this.isAdmission = url.split("/")[4] === "a";
                    this.encounterCommunicationSubscription = this.encounterCommunication.pEncounter.subscribe(() => {
                        this.findPatient();
                        this.findAppointment(appointmentId);
                        this.findDashboard();
                        this.findCommonData();
                    })
                }
            })
    }

    @HostListener('window:resize')
    onResize() {
        this.paddingTop();
    }
    @HostListener('window:load')
    onLoad() {
        setTimeout(() => { this.paddingTop(); }, 1500)
    }
    @HostListener('window:hashchange')
    onhashchange() {
        setTimeout(() => { this.paddingTop(); }, 500)
    }
    paddingTop() {
        if (document.getElementById("header") != null && document.getElementsByClassName("content")[0] != null) {
            var paddingTopValue = document.getElementById("header").offsetHeight;
            var content = document.getElementsByClassName("content")[0];
            content.setAttribute('style', `padding-top:${paddingTopValue}px`);
        }
    }

    private findAppointment(appointmentId: string) {
        const request = {
            encryptedAppointmentId: appointmentId
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: Appointment) => {
                this.appointment = response;
                if (this.appointment.patientDateOfBirth != null) {
                    const dateOfBirth = DateHelper.toNgbDateStruct(String(this.appointment.patientDateOfBirth))
                    if (dateOfBirth != null) {
                        let mdob = moment(String(this.appointment.patientDateOfBirth), "YYYY-MM-DD");
                        let age = moment().diff(mdob, "months");
                        let yrs = (age / 12).toFixed(1);
                        this.yearsfordisplay = parseInt(yrs, 10);
                        let months = age % 12;
                        var days = moment().diff(mdob.add(yrs, 'years'), 'days', false);
                        this.daysfordisplay = days;
                        this.monthsfordisplay = months;
                        var date1 = moment(String(this.appointment.patientDateOfBirth), "YYYY-MM-DD");
                        var monthsa = moment().diff(date1, 'months');
                        date1.add(monthsa, 'months');
                        var daysa = moment().diff(date1, 'days');
                        monthsa + ' ' + 'Month(s)' + ' ' + daysa + " Day(s)"
                        this.daysfordisplay = daysa;
                    }

                }

                this.findPatient();

                this.paddingTop();
            });
    }
    private findPatient() {

        this.page.loading = true;
        const request = { encryptedAppointmentId: this.appointmentId };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<PEncounter>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: PEncounter) => {
                this.patientId = response.encryptedPatientId;

                this.findPatientDetails();
                 this.findDashboard();
            });
    }

    private fetchAppointmentCounts() {
        const request = {
            patientId: this.appointment.patientId
        };
        this.httpService.get<number>(ApiResources.getURI(ApiResources.pediatricEncounter.base, ApiResources.pediatricEncounter.findVisitNo), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: number) => {
                if (response == 0) {
                    this.visit = 1;
                }
                else {
                    this.visit = response;
                }
            });
        this.paddingTop();
    }



    private findPatientDetails() {
        this.page.loading = true;
        if (this.appointment && this.appointment.encryptedPatientId) {
            const request = { encryptedPatientId: this.appointment.encryptedPatientId };
            this.httpService.post<PatientProfile>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.profile), request)
                .pipe(takeUntil(this.page.unSubscribe))
                .pipe(finalize(() => this.page.loading = false))
                .subscribe((response: PatientProfile) => {
                    if (response) {
                        this.patient = response;
                        var detaills = this.patient.relativeDetails?.[0];
                        if (detaills && detaills.relation == "Father") {
                            this.fatherName = this.patient.relativeDetails[0].fullName
                        }
                    }

                });
        }
    }



    private findDashboard() {
        const request = {
            encryptedAppointmentId: this.appointmentId,
            encryptedProviderId: this.page.userAccount && this.page.userAccount.encryptedReferenceId ? this.page.userAccount.encryptedReferenceId : null,
            encryptedPatientId: this.patientId,
            isAdmission: this.isAdmission
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<[PEncounter, Appointment, PreviousAppointment]>(ApiResources.getURI(ApiResources.pediatricEncounter.base, ApiResources.pediatricEncounter.findData), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: [PEncounter, Appointment, PreviousAppointment]) => {
                this.pediatricEncounter = response["dashboard"];
                this.appointments = response["appointments"];
                this.oldAppointment = response["oldAppointment"];
                if (this.pediatricEncounter ) {
                    this.measurements = this.pediatricEncounter.measurements ? JSON.parse(this.pediatricEncounter.measurements) : null;
                    this.allergies = this.pediatricEncounter.allergies ? JSON.parse(this.pediatricEncounter.allergies) : null;
                    this.specialFeature = this.pediatricEncounter.specialFeature ? JSON.parse(this.pediatricEncounter.specialFeature) : null;
                    this.neonatalRisk = this.pediatricEncounter.neonatalRisk ? JSON.parse(this.pediatricEncounter.neonatalRisk) : null;
                }
                this.fetchAppointmentCounts();
            })

    }


    private findCommonData() {
        const request = {
            encryptedappointmentid: this.appointmentId,
            encryptedpatientid: this.patientId,
        };
        request["isAdmission"] = this.isAdmission;
        this.httpService.post<IEncounterResource>(ApiResources.getURI(ApiResources.pediatricEncounter.base, ApiResources.pediatricEncounter.findData), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: IEncounterResource) => {
                this.commonData = response;
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
        this.encounterCommunicationSubscription.unsubscribe();
    }
    toggleIt() {
        this.timelineService.toggle();
        if (this.timelineService.showBox == false) {
            $("body")[0].setAttribute("data-timeline-size", "timelineabsent");
        } else {
            $("body")[0].setAttribute("data-timeline-size", "timeline");
        }
    }
    toggleSideNav() {
        $(".sidebar-main-menu")[0].classList.toggle("removeLeft");
        if ($("body")[0].getAttribute('data-sidebar-size') === "default") {
            $("body")[0].setAttribute("data-sidebar-size", "condensed");
            this.sideNav = true;
        } else if ($("body")[0].getAttribute('data-sidebar-size') === "condensed") {
            $("body")[0].setAttribute("data-sidebar-size", "default");
            this.sideNav = false;
        }
    }
    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }
}



