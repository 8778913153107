import { Injectable } from "@angular/core";
import { HttpErrorInfo } from '../models';

@Injectable()
export class HttpErrorService {

    messages: Array<HttpErrorInfo>;
    timeout: any;

    constructor() {
        this.messages = new Array<HttpErrorInfo>();
    }

    add(model: HttpErrorInfo) {
        this.messages = new Array<HttpErrorInfo>();
        this.messages.push(model);
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            this.messages = new Array<HttpErrorInfo>();
        }, 3000);
    }

    close = () => {
        clearTimeout(this.timeout);
        this.messages = new Array<HttpErrorInfo>();
    }
}
