import { AsyncValidatorFn, FormControl, ValidationErrors } from "@angular/forms";
import { Observable, timer } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ValidatorService } from "@shared/services";

export function uniqNpiValidator(validatorService: ValidatorService): AsyncValidatorFn {
    return (control: FormControl): Promise<ValidationErrors | (Object | null)> | Observable<ValidationErrors | (Object | null)> => {
        return timer(500).pipe(
            switchMap(() => {
                const referenceId: number = (control.parent.controls as any)["providerId"] ? (control.parent.controls as any)["providerId"].value : 0;
                return validatorService.validateNpi(control.value, referenceId)
                    .pipe(
                        map((res: boolean) => {
                            return res ? { npiExists: true } : null;
                        })
                    );
            })
        );
    };
}
