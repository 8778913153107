export class StatusReport {   
    providerName: string;
    patientId: number;
    patientName: string;  
    appointmentDate: string;
    umrNo: number;
    patientMobile: number;
    appointmentId: number;
    accountId?: number;
    consultationName: string
    consultataionTypeId:string
    admissionVisitType: string;
    admissionType: string;
    locationId: number;
    createdByName: string;
}

