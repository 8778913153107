export class PharmacySaleReturn {
    saleReturnHeaderId: number;
    returnDate: Date;
    createdDate: Date;
    billType: string;
    patientId?: number;
    patientName: string;
    overallTotal: number;
    overallNetAmount: number;
    createdBy: number;
    pharmacySaleHeaderId: number;
    saleReturnDetailId: number;
    pharmacyProductId: number;
    quantity: number;
    total: number;
    taxPercentage?: number;
    taxAmount?: number;
    discountPercentage?: number;
    discountAmount?: number;
    netAmount: number;
    productName: string;
    expiryDate?: Date;
    mrp: number;
    createdByName: string;
    payTypeId: number;
    paymentNumber: string;
    totalReturnAmount: number;
} 