export class PaymentDocument {
    paymentDocumentId: number;
    providerId: number;
    providerName: string;
    uploadedBy: number;
    uploadedByName: string;
    uploadedByRole: string;
    documentName: string;
    documentType: string;
    size: number;
    description?: string;
    documentUrl: string;
    thumbnailUrl: string;
    uploadedDate?: Date;
    active: boolean;
    totalItems: number;
    month: string;
    year: string;
    contentType: string;
    isImage: boolean;
    isVideo: boolean;

}