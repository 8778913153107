import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { HealthCard } from "../entities";

@Injectable()
export class AppointmentRefreshService {
    private jitsiSource = new BehaviorSubject(null);
    jitsiAccount: Observable<any> = this.jitsiSource.asObservable();
    refresh(isRefreshed: boolean) {
        this.jitsiSource.next(isRefreshed);
    }

    private source = new BehaviorSubject(null);
    appointment: Observable<any> = this.source.asObservable();
    refreshAppointment(isRefreshed: boolean) {
        this.source.next(isRefreshed);
    }

    private conditionalRefreshSource = new BehaviorSubject(null);
    conditionalRefresh: Observable<any> = this.conditionalRefreshSource.asObservable();
    conditionalRefreshAppointment(model: {}) {
        this.conditionalRefreshSource.next(model);
    }

    private healthCard = new Subject();
    healthCardDetail: Observable<any> = this.healthCard.asObservable();
    onClickHealthCard(item:HealthCard) {
        this.healthCard.next(item);
    }
}
