export class DemandBook {
    demandBookId: number;
    patientId: number;
    productName: string;
    requiredQty: number;
    categoryName: string;
    active: boolean;
    createdBy: number;
    createdDate?: Date;
    modifiedBy?: number;
   // modifiedDate?: Date;
    fullName: string;
    patientName: string;
    mobile: string;
    createdByName: string;
    umrNo: string;
    modifiedByName: string;
    totalItems: number;

}
