<ng-container *ngFor="let room of activeRooms; let i = index;">
    <!-- ROOMS RINGER-->
    <!--<div class="hide" [class.show]="activeRooms[i].isRinging">-->
    <div [id]="'ringerBox_' + i" class="incoming-call-container room{{i}}-ringer ringer-hide">
        <div class="incoming-call-body">
            <div class="incoming-icon-box">
                <img src="assets/images/caller.png" alt="Caller" />
            </div>
            <h4>Incoming Call</h4>
            <div class="caller-info">Call with <span [textContent]="activeRooms[i].current?.message"></span></div>
            <ul class="call-actions">
                <li><a href="javascript:;" (click)="onAnswer(i)" placement="left" ngbTooltip="Answer" class="call-answer"><i class="fi-phone-call mr-2"></i><span>Answer</span></a></li>
                <li><a href="javascript:;" (click)="onHangup(i)" placement="left" ngbTooltip="Reject" class="call-reject"><i class="fi-phone-call mr-2"></i><span>Reject</span></a></li>
            </ul>
        </div>
    </div>
</ng-container>

<!-- ROOMS CONTENT ngDraggable -->
<div class="row">
    <div class="col-12 video-container p-0" [ngClass]="{'corner-position' : activeRooms[i].position === 1 && isRoomActive, 'center-position' : activeRooms[i].position === 2 && isRoomActive, 'hide-index' : !isRoomActive}" *ngFor="let room of activeRooms; let i = index;">
        <div class="jitsi-meet-div hide" [class.show]="activeRooms[i].isShow">
            <div class="jitsi-container-div">
                <div class="row">
                    <div class="col-12 text-center" [ngClass]="{'text-center' : activeRooms[i].position === 2}">
                        <span class="text-left pl-2 room-name" *ngIf="activeRooms[i].position === 2">
                            Room <span [textContent]="1 + i"></span> &nbsp;<span class="font-weight-bold text-capitalize" [textContent]="activeRooms[i].appointmentDetails?.patientFullName"></span>
                        </span>
                        <span class="text-left pl-2 room-name text-center" *ngIf="activeRooms[i].position === 1">
                            <span class="font-weight-bold text-capitalize" [textContent]="activeRooms[i].appointmentDetails?.patientShortName"></span>
                        </span>
                        <div class="btn-toolbar float-left pt-1 pb-1 pl-1" role="toolbar" aria-label="Toolbar with button groups">
                            <div class="btn-group" role="group">
                                <!--<button *ngIf="!activeRooms[i].jitsiApi || activeRooms[i].jitsiApi._numberOfParticipants > 2" (click)="closeRoom(i)" [title]="'Leave'" type="button" class="btn reset-btn"><i class="fe-zap-off"></i></button>-->
                                <button (click)="getNurseOrReceptionists(i)" type="button" class="btn btn-primary"><i class="fe-user-plus"></i></button>
                            </div>
                        </div>
                        <div class="btn-toolbar float-right p-1" role="toolbar" aria-label="Toolbar with button groups">
                            <div *ngIf="activeRooms[i].jitsiApi?._numberOfParticipants > 0" class="btn-group" role="group">
                                <button *ngIf="activeRooms[i].isShowPatientBtn && totalParticipants<2" [disabled]="activeRooms[i].isNotifyingPatient " (click)="intimatePatient(i)" type="button" class="btn btn-primary mb-1 btn-xs">
                                    <span *ngIf="activeRooms[i].isNotifyingPatient">
                                        <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> Calling ...
                                    </span>
                                    <span *ngIf="!activeRooms[i].isNotifyingPatient">
                                        <i class="fe-video mr-1"></i> <span [textContent]="activeRooms[i].isWaiting ? 'Call Again' : 'Call Patient'"></span>
                                    </span>
                                </button>

                                <!--<button *ngIf="activeRooms[i].isNotifyingPatient" (click)="cancelIntimatePatient(i)" type="button" class="btn btn-danger m-auto btn-xs d-block">
                                    <i class="fe-x mr-1"></i> <span> Cancel</span>
                                </button>-->
                                <button (click)="toggleVideo(i)" [title]="'Minimize'" type="button" class="btn btn-light"><i class="fe-minus"></i></button>
                                <button (click)="togglePosition(i)" type="button" class="btn btn-light"><i [ngClass]="{'fe-minimize' : activeRooms[i].position === 2, 'fe-maximize' : activeRooms[i].position === 1}"></i></button>
                                <!--<button *ngIf="!activeRooms[i].jitsiApi || activeRooms[i].jitsiApi._numberOfParticipants > 2" (click)="closeRoom(i)" [title]="'Leave'" type="button" class="btn btn-light"><i class="fe-zap-off"></i></button>-->
                                <button (click)="closeRoomAll(i)" [title]="'End Call'" type="button" class="btn btn-danger"><i class="fe-x"></i></button>
                            </div>
                            <div *ngIf="!activeRooms[i].jitsiApi || activeRooms[i].jitsiApi?._numberOfParticipants <= 0" class="btn-group" role="group">
                                <button type="button" class="btn btn-danger">
                                    <img src="assets/images/typing1.gif" class="doc-typing-loader">
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="activeRooms[i].infoMessages.length" class="col-12">
                        <div class="py-1 d-flex align-items-center justify-content-between"
                             [ngClass]="{'bg-warning' : activeRooms[i].infoMessages[0].type !== infoMessageType.Template, 'bg-primary': activeRooms[i].infoMessages[0].type === infoMessageType.Template}">
                            <span class="ml-auto">
                                <span class="mr-1" *ngIf="activeRooms[i].infoMessages[0].type !== infoMessageType.Template">
                                    <img width="15" src="assets/images/typing1.gif" class="doc-typing-loader">
                                </span>
                                <!--<span *ngIf="activeRooms[i].infoMessages[0].type === infoMessageType.Connecting">
                                  <span [textContent]="activeRooms[i].infoMessages[0].message" class="mr-1"></span>is connecting ...
                                </span>-->
                                <span class="text-white" *ngIf="activeRooms[i].infoMessages[0].type === infoMessageType.Template">
                                    <i class="fe-info mr-1"></i><span [textContent]="activeRooms[i].infoMessages[0].message"></span>
                                </span>
                                <span *ngIf="activeRooms[i].infoMessages[0].type === infoMessageType.Others">
                                    <span [textContent]="activeRooms[i].infoMessages[0].message"></span>
                                </span>
                            </span>
                            <span class="ml-auto">
                                <i class="fe-x text-black-50 mr-1" (click)="removeInfoMessage(i)"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <!--<ng-container *ngIf="activeRooms[i].isShowPatientBtn">

                <div class="special-call-patient-btn text-center background-size-auto" [ngClass]="{'center-position' : activeRooms[i].position === 2, 'corner-position' : activeRooms[i].position === 1}">
                    <span (click)="closeCover(i)" class="cover-close-btn"><i class="fe-x"></i></span>
                    <div class="media" [ngClass]="{'mt-3' : activeRooms[i].position === 2, 'mt-1': activeRooms[i].position === 1}">
                        <div class="m-auto" [ngClass]="{'avatar-lg' : activeRooms[i].position === 2, 'avatar-md': activeRooms[i].position === 1}">
                            <avatar-img *ngIf="activeRooms[i].patientThumbnailUrl" [src]="activeRooms[i].patientThumbnailUrl" cssClass="img-fluid rounded-circle" alt="patientName"></avatar-img>
                            <span *ngIf="!activeRooms[i].patientThumbnailUrl" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="i" [textContent]="activeRooms[i].appointmentDetails?.patientFullName | initials"></span>
                        </div>
                    </div>
                    <ng-container *ngIf="activeRooms[i].isWaiting">
                        <h6 class="mb-0 d-block mt-0 font-weight-normal mb-1">
                            ///<span class="spinner-border spinner-border-sm mt-1 mr-1" role="status" aria-hidden="true"></span>
                            Waiting for patient
                        </h6>
                    </ng-container>
                    <ng-container *ngIf="!activeRooms[i].isWaiting">
                        <h5 *ngIf="activeRooms[i].position === 2" class="mb-0 d-block mt-0 font-weight-normal mt-1 mb-1" [textContent]="activeRooms[i].appointmentDetails?.patientFullName"></h5>
                        <h6 *ngIf="activeRooms[i].position === 1" class="mb-0 d-block mt-0 font-weight-normal mt-1 mb-1" [textContent]="activeRooms[i].appointmentDetails?.patientShortName"></h6>
                    </ng-container>

                </div>
            </ng-container>-->

            <ng-container *ngIf="i === 0">
                <div class="jitsi-container-div jitsi-loader" #jitsiMeetContainer0 id="jitsi-meet-0"></div>
            </ng-container>
            <ng-container *ngIf="i === 1">
                <div class="jitsi-container-div jitsi-loader" #jitsiMeetContainer1 id="jitsi-meet-1"></div>
            </ng-container>
            <ng-container *ngIf="i === 2">
                <div class="jitsi-container-div jitsi-loader" #jitsiMeetContainer2 id="jitsi-meet-2"></div>
            </ng-container>
            <ng-container *ngIf="i === 3">
                <div class="jitsi-container-div jitsi-loader" #jitsiMeetContainer3 id="jitsi-meet-3"></div>
            </ng-container>

            <!--<div class="card call-patient-container" *ngIf="activeRooms[i].isLoaded">
                <div class="card-header">
                    <span>ready to call patient</span>
                    <div class="btn-toolbar float-right" role="toolbar" aria-label="Toolbar with button groups">
                        <div class="btn-group" role="group">
                            <button (click)="hideUsersContainer(i)" [title]="'Close'" type="button" class="btn btn-danger"><i class="fe-x"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="media py-1 px-1 users-block" (click)="intimatePatient(i)">
                        <div class="avatar-md mr-2">
                            <span class="avatar-title bg-soft-secondary text-secondary font-20 rounded-circle cursor-pointer">
                                <span *ngIf="activeRooms[i].isNotifyingPatient">
                                    <span class="spinner-border spinner-border-md" role="status" aria-hidden="true"></span>
                                </span>
                                <i *ngIf="!activeRooms[i].isNotifyingPatient" class="fe-phone-call"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>-->

            <div class="card users-container" *ngIf="activeRooms[i].showUsersContainer">
                <div class="card-header">
                    <div class="btn-toolbar float-right" role="toolbar" aria-label="Toolbar with button groups">
                        <div class="btn-group" role="group">
                            <button (click)="hideUsersContainer(i)" [title]="'Close'" type="button" class="btn btn-danger"><i class="fe-x"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row" *ngIf="activeRooms[i].isUsersLoading">
                        <div class="col-12 text-center">
                            <div class="card">
                                <div class="card-title"> <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> Loading... </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="!activeRooms[i].telemedicineUsers">
                        <div class="col-12 text-center">
                            <div class="card">
                                <div class="card-title"> No Users found </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="activeRooms[i].telemedicineUsers && activeRooms[i].telemedicineUsers.length > 0">
                        <!--<div class="col-4" *ngIf="userAccount.roleId !== 3">
                            <div class="card">
                                <div class="p-1">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="media py-1 px-1 users-block" (click)="intimateDoctor(i)">
                                                <div class="avatar-md mr-2">
                                                    <span class="avatar-title bg-soft-secondary text-secondary font-20 rounded-circle cursor-pointer">
                                                        <span *ngIf="activeRooms[i].isNotifyingDoctor">
                                                            <span class="spinner-border spinner-border-md" role="status" aria-hidden="true"></span>
                                                        </span>
                                                        <i *ngIf="!activeRooms[i].isNotifyingDoctor" class="fe-video"></i>
                                                    </span>
                                                </div>
                                                <div class="media-body">
                                                    <h5 class="mt-0 mb-0 font-15">
                                                        <span class="text-reset" [textContent]="activeRooms[i].appointmentDetails?.providerFullName"></span>
                                                    </h5>
                                                    <p class="mb-0 text-muted font-12">
                                                        <span class="card-text text-black-50">Doctor</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>-->
                        <ng-container *ngFor="let user of activeRooms[i].telemedicineUsers; let j = index;">
                            <div class="col-6" *ngIf="user.accountId !== userAccount.accountId">
                                <div class="card p-1">
                                    <div class="media py-1 px-1 users-block" (click)="user.roleId === 3 ? intimateDoctor(i, j) : user.roleId === 4 ? intimatePatient(i, j) : intimateNurseOrReceptionist(i,j)">
                                        <div class="avatar-md mr-2">
                                            <span class="avatar-title bg-soft-secondary text-secondary font-20 rounded-circle cursor-pointer">
                                                <span *ngIf="user.isNotifying">
                                                    <span class="spinner-border spinner-border-md" role="status" aria-hidden="true"></span>
                                                </span>
                                                <i *ngIf="!user.isNotifying" class="fe-video"></i>
                                            </span>
                                        </div>
                                        <div class="media-body">
                                            <h5 class="mt-0 mb-0 font-15">
                                                <span [textContent]="user.fullName" class="text-reset"></span>
                                                <!--<span *ngIf="user.roleId !== 3" [textContent]="user.fullName" class="text-reset"></span>-->
                                            </h5>
                                            <p class="mb-0 text-muted font-12">
                                                <span class="card-text text-black-50" [textContent]="user.roleId === 3 ? 'Doctor' : user.roleId === 4 ? 'Patient' : user.roleId === 6 ? 'Nurse' : 'Receptionist'"></span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<audio loop muted #audioContainer>
    <source src="assets/audio/phone_ring.wav" type="audio/mp3" />
</audio>

<audio loop muted #callAudioContainer>
    <source src="assets/audio/ringback.wav" type="audio/mp3" />
</audio>

<!--<div class="toggle-video" *ngIf="isAnyRoomActive">
    <ng-container *ngFor="let room of activeRooms; let i = index;">
        <div [class.highlight]="activeEncounterRoom === i" *ngIf="isSingleRoomActive(i)" (click)="toggleVideo(i)" placement="bottom" [ngbTooltip]="!activeRooms[i].isShow ? 'Show Video' : 'Hide Video'" class="text-center toggle-box">
            <div class="d-inline-block">
                <i [ngClass]="{'fe-video': activeRooms[i].isShow, 'fe-video-off': !activeRooms[i].isShow}"></i>
            </div>
            <div class="d-inline-block ml-2 lh08 text-left">
                <small class="d-block">Room <span [textContent]="i + 1"></span></small>
                <small><span class="text-capitalize" [textContent]="activeRooms[i].appointmentDetails?.patientFirstName"></span></small>
            </div>
        </div>
    </ng-container>
</div>-->

<div [ngClass]="{'center-position': isRoomActive && activeEncounterRoom >= 0 && activeRooms[activeEncounterRoom].position === 2, 'corner-position' : isRoomActive && activeEncounterRoom >= 0 && activeRooms[activeEncounterRoom].position === 1, 'right-position' : !isRoomActive}" class="toggle-video" *ngIf="isAnyRoomActive">
    <ng-container *ngFor="let room of activeRooms; let i = index;">
        <div class="card m-0 ground-box" *ngIf="isSingleRoomActive(i) && !activeRooms[i].isShow" [class.highlight]="activeEncounterRoom === i">
            <div class="card-header p-0">
                <div class="row">
                    <div class="col-9" (click)="toggleVideo(i)">
                        <span class="text-left pl-2 room-name">
                            <span *ngIf="activeRooms[i].jitsiApi?._numberOfParticipants <= 0" class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                            <span class="font-weight-bold text-capitalize">
                                <i *ngIf="activeRooms[i].startAudioOnly" class="fe-headphones mr-1"></i>
                                <i *ngIf="!activeRooms[i].startAudioOnly" class="fe-video mr-1"></i>
                                <span [textContent]="activeRooms[i].appointmentDetails?.patientFullName"></span>
                            </span>
                        </span>
                    </div>
                    <div class="col-3">
                        <div class="btn-toolbar float-right p-1" role="toolbar" aria-label="Toolbar with button groups">
                            <div class="btn-group" role="group">
                                <!--<button *ngIf="!activeRooms[i].jitsiApi || activeRooms[i].jitsiApi._numberOfParticipants > 2" (click)="closeRoom(i)" [title]="'Leave'" type="button" class="btn btn-light"><i class="fe-zap-off"></i></button>-->
                                <button (click)="closeRoomAll(i)" [title]="'End Call'" type="button" class="btn btn-danger"><i class="fe-x"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<!--<ng-template #templateTelemedicineTemplate>
  <form [formGroup]="templateForm" (submit)="onTemplateSelect()">
    <div class="modal-header">
      <h4 class="modal-title"><i class="mdi mdi-mother-nurse mr-1"></i>Intimate patient before leaving</h4>
      <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="closeModal();">×</button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="mb-1" *ngFor="let item of telemedicineTemplates">
              <input class="form-control" name="template" formControlName="id" [value]="item.id" type="radio"/>
              <span class="ml-1" [textContent]="item.name"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light btn-sm mr-1" (click)="closeModal();">Cancel</button>
      <button type="submit" class="btn btn-primary btn-sm">
        <span>Submit</span>
      </button>
    </div>
  </form>
</ng-template>-->
