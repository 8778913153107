<div class="error-container bg-pattern">
    <div class="error-box">
        <img src="assets/images/logo.png" alt="Careaxes" height="25">
        <div class="error-text-box">
            <svg viewBox="0 0 600 200">
                <symbol id="s-text">
                    <text text-anchor="middle" x="50%" y="50%" dy=".35em">404!</text>
                </symbol>
                <use class="text" xlink:href="#s-text"></use>
                <use class="text" xlink:href="#s-text"></use>
                <use class="text" xlink:href="#s-text"></use>
                <use class="text" xlink:href="#s-text"></use>
                <use class="text" xlink:href="#s-text"></use>
            </svg>
        </div>
        <div class="text-center">
            <h3 class="mt-0 mb-2">Whoops! Page not found </h3>
            <p class="font-15 mb-3">
                We're sorry, but the page you were looking for doesn't exist. You may have mistyped the address or the page may have moved.
            </p>
            <a href="javascript:;" routerLink="/app/dashboard" class="btn btn-primary waves-effect waves-light">Back to Dashboard</a>
        </div>
    </div>
</div>