<div class="row">
    <div class="col-12">
        <div class="card mb-0">
            <div class="card-body p-2 border-bottom border-light">
                <div class="d-flex align-content-center justify-content-between">
                    <div class="media">
                        <div class="avatar-xs mr-2">
                            <avatar-img *ngIf="appointment.patientThumbnailUrl" [src]="appointment.patientThumbnailUrl" cssClass="img-fluid rounded-circle" [alt]="appointment.patientName"></avatar-img>
                            <span *ngIf="!appointment.patientThumbnailUrl" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="0">
                                <span [textContent]="appointment.patientName | initials"></span>
                            </span>
                        </div>
                        <div class="media-body">
                            <h5 class="mt-0 mb-0 font-15">
                                <a href="javaScript:;" class="text-reset" [textContent]="appointment.patientName"></a>
                            </h5>
                        </div>
                    </div>
                    <button (click)="onCloseClick()" class="text-right btn btn-sm btn-primary">x</button>
                </div>
            </div>
            <div class="card-body">
                <div class="conversation-list" data-simplebar>
                    <div class="simplebar-wrapper">
                        <div class="simplebar-height-auto-observer-wrapper">
                            <div class="simplebar-height-auto-observer">
                            </div>
                        </div>
                        <div class="simplebar-mask">
                            <div class="simplebar-offset">
                                <div class="simplebar-content-wrapper" #chatContainer>
                                    <div class="row" *ngIf="loadingMessages">
                                        <div class="col-12">
                                            <div class="d-flex align-items-center">
                                                <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
                                                <span class="ml-2">Please wait while loading messages...</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="simplebar-content">
                                        <div *ngIf="allMessages.length === 0">
                                            <div class="card mb-1 shadow-none border">
                                                <div class="p-2">
                                                    <div class="row align-items-center">
                                                        <div class="col-auto">
                                                            <div class="avatar-sm">
                                                                <span class="mdi mdi-comment-outline mdi-36px text-primary rounded">
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col pl-0">
                                                            <a href="javascript:void(0);" class="text-muted font-weight-bold">No Messages here.</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngFor="let message of allMessages;let i = index;" #chatMessage>
                                            <div class="clearfix conversation-list-item" *ngIf="message.accountId !== page.userAccount.accountId">
                                                <div class="chat-avatar">
                                                    <div class="avatar-xs">
                                                        <avatar-img *ngIf="appointment.patientThumbnailUrl" [src]="appointment.patientThumbnailUrl" cssClass="img-fluid rounded-circle" [alt]="appointment.patientThumbnailUrl"></avatar-img>
                                                        <span *ngIf="!appointment.patientThumbnailUrl" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="1">
                                                            {{appointment.patientName | initials}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="conversation-text">
                                                    <div class="ctext-wrap">
                                                        <i>
                                                          <span class="text-uppercase">  {{appointment.patientName}}</span> <small [textContent]="message.date | utcToLocal:false"></small>&nbsp;
                                                            <small [textContent]="message.date | utcToLocal:false:'hh:mm a'"></small>
                                                        </i>
                                                        <p [textContent]="message.message"></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clearfix conversation-list-item odd" *ngIf="message.accountId === page.userAccount.accountId">
                                                <div class="chat-avatar">
                                                    <div class="avatar-xs">
                                                        <avatar-img *ngIf="page.userAccount.thumbnailUrl" [src]="safe(page.userAccount.thumbnailUrl)" cssClass="img-fluid rounded-circle" [alt]="page.userAccount.fullName"></avatar-img>
                                                        <span *ngIf="!page.userAccount.thumbnailUrl" class="avatar-title rounded-circle font-12 font-weight-bold text-white" avatar-bg [index]="0">
                                                            {{page.userAccount.fullName | initials}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="conversation-text">
                                                    <div class="ctext-wrap">
                                                        <i>
                                                            <span [textContent]="message.date | utcToLocal:false"></span> &nbsp;
                                                            <span [textContent]="message.date | utcToLocal:false:'hh:mm a'"></span>
                                                        </i>
                                                        <p class="text-justify" [textContent]="message.message"></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="simplebar-placeholder"></div>
                    </div>
                    <div class="simplebar-track simplebar-vertical">
                        <div class="simplebar-scrollbar"></div>
                    </div>
                </div>
                <div class="row" *ngIf="!loadingMessages">
                    <div class="col" *ngIf="!disableMessageBox">
                        <div class="mt-2 bg-light p-3 rounded">
                            <div class="row">
                                <div class="col mb-2 mb-sm-0">
                                    <textarea type="text" [(ngModel)]="messageBox" (keyup.enter)="onMessaging()" rows="2" class="form-control border-0" placeholder="Enter your Message"></textarea>
                                </div>
                                <div class="col-sm-auto">
                                    <div class="btn-group">
                                        <button (click)="onMessaging()" type="button" class="btn btn-success btn-block">
                                            <i class="fe-send" *ngIf="!isMessageSending"></i>
                                            <span *ngIf="isMessageSending" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                        </button>
                                        <!--<button [disabled]="messageBox === null" (click)="messageBox = null" type="button" class="btn btn-danger"><i class="fe-x"></i></button>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="disableMessageBox" style="margin-top: 54px;">
                        <div class="alert alert-warning" role="alert">
                            Follow up date <a href="javaScript:;" class="alert-link">ended for this appointment</a>. Can't send further messages.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>