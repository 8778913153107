export class Transaction {
    creditAmount
    transactionDate: Date;

    appointmentTransactionId: number;
    paymentId: number;
    appointmentId: number;
    receiptTypeName: string;
    receiptAreaType: string;
    receiptAreaTypeId?: number;
    cost: number;

    voucherType: string;
    debitLedger: string;
    creditLedger: string;
   /* costCenter: string;*/

    //s: string;
    //instrumentDate: string;
    //instrumentNo: string;
    //bankName: string;
    areaId: number;
    areaAppend: string;
    voucherNumber: string
   
    receivedIn: string;
    sendedVia: string;
    voucherNumberExcel: string;
    appDiscount?: number;
    appAmount: number;
    receiptNo: string;
    receiptId: number;
    s: string;
    payStatus: string;
   


}