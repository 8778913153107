export class ChargeModuleDetailsModel {
    chargeModuleDetailsId: number;
    locationId: number;
    chargeModuleCategoryId?: number;
    modulesMasterId?: number;
    moduleName: string;
    moduleIcon: string;
    amount: number;
    referenceId?: number;
    chargeName: string;
    chargeGroupId?: number;
    chargeGroupName: string;
    departmentId?: number;
    departmentName: string;
    repeatTypeId?: number;
    repeatTypeName: string;

    quantity: number;
    isFree: boolean;
    isSelected: boolean;
    packageModuleDetailId: number;
}