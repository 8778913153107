<script src="https://www.gstatic.com/charts/loader.js"></script>


<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <!-- Add content here if needed -->
                    </div>
                    <h4 class="page-title">Vitals</h4>
                </div>
            </div>
        </div>
        <div class="form-group mb-3 d-flex align-items-end">
            <label class="mr-2">Vitals</label>
            <ng-select class="ng-select-sm col-6 col-sm-4 mr-2" [items]="vitalTypes"
                       bindLabel="name"
                       bindValue="vitalTypeId"
                       typeToSearch="Search Vital type"
                       placeholder="Enter Vital Name"
                       [(ngModel)]="SelectedVital">
                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                    No Vital type found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                </ng-template>
            </ng-select>

            <div style="margin-top: 25px;" class="mr-auto">
                <button type="button"
                        (click)="onApplyFilters()"
                        class="btn btn-sm btn-primary rounded-pill">
                    <i class="mdi mdi-magnify mr-1"></i>Search
                </button>

                <button type="button"
                        (click)="onResetFilters()"
                        class="btn btn-sm reset-btn rounded-pill ml-1">
                    <i class="mdi mdi-backup-restore mr-1"></i>Reset
                </button>
            </div>
            <div class="mr-5" *ngIf="records && records.length">
                <button *ngIf="!admission.isDischarged" (click)="openGraphModel(templateGraph)" class="btn btn-sm btn-primary rounded-pill">
                    <i class="mdi mdi-note-plus-outline mr-1"></i>Graphs
                </button>
            </div>
        </div>


        <div class="container-fluid">


            <div class="d-flex align-items-center justify-content-center" *ngIf="records.length <= 0">
                <div class="container p-3 bg-soft-pink">
                    <h4 class="fw-bold fs-5">No Records Found...</h4>
                </div>
            </div>
            <div class="container-fluid"  *ngIf="records.length > 0">
                <div class="page-title-box d-flex justify-content-between align-items-center">
                    <h4 class="page-title"></h4>
                    <div class="d-flex">
                       
                        <div class="mr-3 d-flex align-items-center">
                            <i class="mdi mdi-circle mdi-18px mr-2 text-success"></i>
                            <span> Normal </span>
                        </div>
                        <div class="mr-3 d-flex align-items-center">
                            <i class="mdi mdi-circle mdi-18px mr-2 text-danger"></i>
                            <span>Abnormal </span>
                        </div>
                        
                       
                    </div>
                </div>

                <div class="row" *ngIf="records.length > 0">
                    <div class="col-12">

                        <table class="table table-bordered table-striped table-hover">
                            <!-- Table content -->
                            <tr>
                            </tr>

                            <thead class="table-primary">

                                <tr>


                                    <th scope="col">SNo</th>
                                    <th scope="col">Vitals Name</th>
                                    <th scope="col">Taken Value</th>
                                    <th scope="col">Status </th>
                                    <th scope="col">Taken Hour</th>

                                    <th scope="col">Ranges</th>
                                    <th scope="col">Created Date</th>
                                    <th scope="col">Created By</th>



                                    <th scope="col" class="noprint-column">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of records; let i = index;">
                                    <td><span class="text-xl">{{i+1}}</span></td>
                                    <td>
                                        <div>
                                            <span class="text-xl"><h5>{{item.name}}</h5></span>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <span class="text-xl">
                                                <h4>{{item.takenValue}}</h4> {{item.unitType}} <br />

                                            </span>
                                        </div>
                                    </td>

                                    <td [style.color]="(item.takenValue > item.max || item.takenValue < item.min) ? 'red' : 'inherit'" *ngIf="item.takenValue > item.max || item.takenValue < item.min">
                                        <div>
                                            <span class="text-xl">
                                                <i class="mdi mdi-circle mdi-18px mr-1 text-danger"></i>
                                            </span>
                                        </div>
                                    </td>
                                    <td *ngIf="item.takenValue < item.max && item.takenValue > item.min">
                                        <div>
                                            <span class="text-xl">
                                                <i class="mdi mdi-circle mdi-18px mr-1 text-success"></i>
                                            </span>
                                        </div>
                                    </td>
                                    <td *ngIf="item.takenValue === item.max || item.takenValue === item.min">
                                        <div>
                                            <span class="text-xl">
                                                <i class="mdi mdi-circle mdi-18px mr-1 text-success"></i>
                                            </span>
                                        </div>
                                    </td>

                                    <td>
                                        <div>
                                            <p><span class="text-xl">{{item.takenHour}}:00</span></p>
                                        </div>
                                    </td>

                                    <td>
                                        <div>
                                            <p><span class="text-xl">{{item.min}} to {{item.max}}</span></p>
                                        </div>
                                    </td>

                                    <td><span class="text-xl">{{item.createdDate | date:'dd-MM-yyyy'}}</span></td>
                                    <td><span class="text-xl">{{item.createdByName}}</span></td>




                                    <td>
                                        <a href="javascript:;" placement="left" ngbTooltip="Edit" (click)="openModifyModel(templateAdd, item)"><i class="mdi mdi-file-document-edit-outline mr-1"></i></a>
                                        <!--<a href="javascript:;" placement="left" ngbTooltip="Delete" (click)="delete(item)"><i class="mdi mdi-trash-can-outline"></i></a>-->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
</div>



    <ng-template #templateAdd>

        <div class="modal-header">
            <h4 class="modal-title">
                <i class="mdi mdi-note-plus-outline mr-1"></i>
                <span>Update Vitals</span>
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-6">

                        </div>
                    </div>
                </div>
                <div class="col-lg-12 mt-2">
                    <table class="table table-centered table-nowrap table-sm table-bordered mb-0">
                        <thead>
                            <tr>
                                <th>Vital Type</th>
                                <th>Value</th>
                                <th>Unit</th>
                                <th>Ranges</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of vitalarray;let i = index;">
                                <td>
                                    <span [textContent]="item.name"></span>
                                </td>
                                <td>
                                    <input class="form-control" type="text" decimalsOnly maxlength="3" [(ngModel)]="item.givenValue" placeholder="Enter measured value." />
                                </td>
                                <td>
                                    <span [textContent]="item.unitType"></span>
                                </td>
                                <td>
                                    <span [textContent]="item.min"></span> <b>-</b><span [textContent]="item.max"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-light btn-sm mr-1" (click)="onCloseModal();">Cancel</button>
            <button type="submit" (click)="modify()" class="btn btn-primary btn-sm">
                <span *ngIf="submitting"><span class="spinner-grow spinner-grow-sm mr-1"></span> please wait ...</span>
                <span *ngIf="!submitting"><i class="fe-plus mr-1" placement="right"></i>Submit</span>
            </button>
        </div>
    </ng-template>
    <ng-template #templateGraph>
        <div class="modal-header">
            <h4 class="modal-title"><i class="mdi mdi-finance mr-1"></i>Charts</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
        </div>

          
            <div class="ml-1 mt-2">
                <div class="ml-lg-2 d-flex justify-content-center ">
                    <label class="white-space-nowrap mt-1 " [textContent]="'Select Vital Type'"></label> &nbsp;&nbsp;&nbsp;
                    <ng-select class="ng-select-sm col-6 col-sm-4 mr-2 w-500p" [items]="graphVitals"
                               
                               typeToSearch="Search Vital type"
                               placeholder="Enter Vital Name"
                               [(ngModel)]="selectedVitalValue">
                        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                            No Vital type found<span *ngIf="searchTerm"> for '{{searchTerm}}'</span>.
                        </ng-template>
                    </ng-select>

                    <button type="submit" (click)="onSubmitGraphTemplate()" class="btn btn-primary btn-sm"> Submit </button>
                </div>
                <div >
                    <div id="container" >
                        <div *ngIf="!displayGraphRecord" class="text-center pt-5">
                            <h3 class="pt-5">Select Vital</h3>
                        </div>
                    </div>
                </div>              
            </div>
    </ng-template>

   

