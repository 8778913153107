export class PatientEmergency {
    patientEmergencyId: number;
    patientId: number;
    fullName: string;
    relation: string;
    mobile: string;
    address: string;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate?: Date;
}
