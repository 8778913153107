export class NurseNotesReport {
    nurseNoteId?: number;
    bedNumber?: string;
    note?: string;
    admissionId?: number;
    admissionNo?: string;
    umrNo?: string;
    createdByName?: string;
    nurseName?: string;
    createdDate?: Date;   
    fullName?: string;
    
}
