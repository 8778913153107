<div class="row" *ngIf="showWebcam && !webcamError">
    <div class="col-md-9">
        <div class="camera-container">
            <webcam [imageType]="'image/jpeg'" [mirrorImage]="'never'" [videoOptions]="videoOptions" [captureImageData]="true" [trigger]="triggerObservable" [imageQuality]="1" (initError)="handleInitError($event)" (imageCapture)="onCapture($event)"></webcam>
            <a href="javascript:;" class="btn btn-sm btn-primary" (click)="triggerSnapshot()"><i class="fe-camera mr-1"></i> Capture</a>
        </div>
    </div>
    <div class="col-md-3" *ngIf="webcamImage">
        <img class="img-fluid rounded" [src]="webcamImage.imageAsDataUrl" alt="Profile image" />
    </div>
</div>
<div *ngIf="webcamError">
    <div class="webcam-error">
        <i class="fe-camera-off text-danger mb-2" style="font-size: 50px"></i>
        <h3 class="text-danger mb-3">Webcam Error</h3>
        <p class="mb-0">
            Apparently we're experiencing an error with your webcam. <br />Please <b>allow full permissions</b> to your webcam and try again.
        </p>
    </div>
</div>