<div>
    <span >
        <span>{{Location.streetAddress + ' '}}</span>
        <span>{{Location.locationName + ' '}}</span>
        <span>{{Location.cityName + ' '}}</span>
        <span>{{Location.zipcode + ' '}}</span>
        <span>{{Location.stateName + ' '}}</span>
        <span>{{Location.countryName + ' '}}</span>
    </span>
    <br />
    <span>
        <b>Fernandez Hospitals <i class="mdi mdi-heart"></i> </b>
        <span *ngIf="page.userAccount.locationId == 1"><b>BOGULKUNTA</b> | </span>
        <span *ngIf="page.userAccount.locationId != 1">BOGULKUNTA | </span>
        <span *ngIf="page.userAccount.locationId == 3"><b>HYDERGUDA</b> | </span>
        <span *ngIf="page.userAccount.locationId != 3">HYDERGUDA | </span>
        <span *ngIf="page.userAccount.locationId == 2"><b>BANJARA HILLS (Stork Home)</b> </span>
        <span *ngIf="page.userAccount.locationId != 2">BANJARA HILLS (Stork Home) </span>
    </span>
    <br />
    <span>
        <b>Fernandez Clinics <i class="mdi mdi-checkbox-blank-circle-outline"></i> </b> HYDERGUDA | MIYAPUR | NECKLACE ROAD
    </span>
</div>