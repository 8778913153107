export class ProviderDocument {
    providerDocumentId: number;
    providerId: number;
    documentName: string;
    documentType: string;
    contentType: string;
    size: number;
    description?: string;
    documentUrl: string;
    thumbnailUrl: boolean;
    uploadedDate: Date;
    active: boolean;
    isImage: boolean;
    modifiedBy: number;
    modifiedByName: string;
    modifiedDate?: Date;
    totalItems: number;
    providerName:string;
}
