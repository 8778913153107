import { FormControl } from "@angular/forms";
import { RegExHelper } from "@shared/helpers";

export class PasswordValidator {
    static isValid(control: FormControl): object | null {
        const value = control.value;

        // Empty Value
        if (!value) {
            return null;
        }

        // Min Length
        if (!RegExHelper.length10.test(value)) {
            return { 'minLength': true };
        }

        // One UpperCase
        if (!RegExHelper.oneUpperCase.test(value)) {
            return { 'upperCase': true };
        }

        // One Number
        if (!RegExHelper.oneNumber.test(value)) {
            return { 'number': true };
        }

        // One Special Character
        if (!RegExHelper.oneSpecialChar.test(value)) {
            return { 'specialChar': true };
        }
        return null;
    }
}